import React, { useState, useRef } from 'react';
import { QrReader } from 'react-qr-reader';

const QRCodeScanner = () => {
  // const [data, setData] = useState('');
  const [error, setError] = useState('');
  const scannerContainerRef = useRef(null);

  const [data, setData] = useState('No result');

  const handleScan = (result) => {
    console.log(result)
    if (result) {
      setData(result);
    } else {
      setError('No QR code detected');
    }
  };

  return (
    <>
      <QrReader
        onResult={(result, error) => {
          if (!!result) {
            setData(result?.text);
          }

          if (!!error) {
            console.info(error);
          }
        }}
        style={{ width: '100%' }}
      />
      <p>{data}</p>
    </>
  );
};

export default QRCodeScanner;