import { Button, Grid, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../components/FormikControl";
import swal from "sweetalert";
import { makePostRequest } from "../../utils/utils";
import LockResetIcon from "@mui/icons-material/LockReset";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function ResetPassword() {

  const navigate = useNavigate();
  const [showLoader, setShowLoader] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  //const [loginErrMsg, setLoginErrMsg] = React.useState("");

  const initialValues = {
    employee_code: "",
    password: "",
    old_password: "",
    is_password_reset: false,
  };

  const validationSchema = Yup.object({
    employee_code: Yup.string().required("Required"),
    old_password: Yup.string().required("Required"),
    password: Yup.string()
      .matches(
        /^(?=.*[0-9])(?=.*[- ?!@#$%^&*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9- ?!@#$%^&*\/\\]{8,30}$/,
        "Password should be minimum 8 character long and a combination of uppercase letters, lowercase letters, numbers, and symbols!"
      )
      .required("Required"),
    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref("password"), null], "Password not matched")
    //   .required("Required"),
  });

  const changePassword = (values) => {

    let inputData = {
      employee_code: values.employee_code,
      old_password: values.old_password,
      password: values.password,
      is_password_reset: true,
    };

    //setLoginErrMsg('');
    makePostRequest("employee/changepassword", inputData)
      .then((response) => {
        if (response.data.data) {
          console.log(response.data.data);
          swal(
            "Password changed successfully",
            "Please login again",
            "success",
            {
              buttons: false,
              timer: 3000,
            }
          );
          localStorage.removeItem("forgotUserId");
          navigate("/LoginMobile");
          setShowLoader(false);
        }
      })
      .catch((err) => {
        swal({
          title: "Error",
          text: err.response.data.message,
          icon: "warning",
        });
        setShowLoader(false);
      });
  };

  return (
    <>
      <div className="mobileCssMain">
        <div className="container">
          <div className="wrapper">
            <div className="screenTitle">
              <Button onClick={() => navigate(-1)}>
                <IconButton className="TouchableIconBtn">
                  <ArrowBackIosIcon />
                </IconButton>
              </Button>
              <h2>Reset Password</h2>
            </div>
            <div className="loginMain">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <h1 className="resrPas">
                    <LockResetIcon /> Reset Your Password
                  </h1>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="loginCont">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={changePassword}
                    >
                      {(formik) => (
                        <Form>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              <FormikControl
                                control="input"
                                type="employee_code"
                                label="Employee Code"
                                labelClass="loginLable"
                                name="employee_code"
                                className="loginInput"
                                placeholder="Employee Code"
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className="forPassEye">
                                <FormikControl
                                  control="input"
                                  type={showOldPassword ? "text" : "password"}
                                  label="Old Password"
                                  labelClass="loginLable"
                                  name="old_password"
                                  className="loginInput"
                                  placeholder="Old Password"
                                />
                                {showOldPassword == true ? (
                                  <RemoveRedEyeIcon
                                    onClick={() => setShowOldPassword(false)}
                                  />
                                ) : (
                                  <VisibilityOffIcon
                                    onClick={() => setShowOldPassword(true)}
                                  />
                                )}
                              </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className="forPassEye posiMins eyeWithError">
                                <FormikControl
                                  control="input"
                                  type={showPassword ? "text" : "password"}
                                  label="New Password"
                                  labelClass="loginLable"
                                  name="password"
                                  className="loginInput"
                                  placeholder="New Password"
                                />
                                {showPassword == true ? (
                                  <RemoveRedEyeIcon
                                    onClick={() => setShowPassword(false)}
                                  />
                                ) : (
                                  <VisibilityOffIcon
                                    onClick={() => setShowPassword(true)}
                                  />
                                )}
                              </div>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={12}
                              style={{ textAlign: "centers" }}
                            >
                              <Button className="loginBt" type="submit">
                                Reset Password
                              </Button>
                            </Grid>

                            {/* <Grid
                              item
                              xs={12}
                              md={12}
                              style={{ textAlign: "center" }}
                            >
                              <p className="donttHavAc">
                                <b onClick={() => navigate("/LoginMobile")}>
                                  Login Here
                                </b>
                              </p>
                            </Grid> */}
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  {/* {loginErrMsg ? (
                    <div>
                      <span className="logError">{loginErrMsg}</span>
                    </div>
                  ) : (
                    null
                  )} */}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
