import React from "react";
import { Button, Grid, colors } from "@mui/material";
import { useNavigate } from "react-router-dom";
import loginLogo from "../../../assets/images/logo.png";
import loginLeftImg from "../../../assets/images/loginLeftImg.png";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import { makePostRequest } from "../../../utils/utils";
import swal from "sweetalert";
import loaderImg from "../../../assets/images/logo.png";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function ChangePassword() {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .matches(
        /^(?=.*[0-9])(?=.*[- ?!@#$%^&*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9- ?!@#$%^&*\/\\]{8,30}$/,
        "Password should be minimum 8 character long and a combination of uppercase letters, lowercase letters, numbers, and symbols!"
      )
      .required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password not matched")
      .required("Required"),
  });

  const changePassword = (values) => {
    let inputData = {
      email_id: localStorage.getItem("forgotuseEmail"),
      password: values.password,
      is_password_reset: true
    };
    makePostRequest("employee/resetPassword", inputData,localStorage.getItem("schemaName"))
      .then((response) => {
        if (response.data.data) {
          console.log(response.data.data);
          swal(
            "Password changed successfully",
            "Please login again",
            "success",
            {
              buttons: false,
              timer: 3000,
            }
          );
          localStorage.removeItem("forgotUserId");
          localStorage.removeItem("schemaName");
          navigate("/");
          setShowLoader(false);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  function test(password) {
    let regex =
      /^(?=.*[0-9])(?=.*[- ?!@#$%^&*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9- ?!@#$%^&*\/\\]{8,30}$/;
    console.log(password);
    console.log(password.match(regex));
  }

  return (
    <>
      <div className="loginMainBody">
        <div className="loginLeft">
          <div className="loginInnerMain">
            <img src={loginLogo} className="loginLogoSt" />
            <h1 className="logoHeading">Star Labels</h1>
            <div className="loginHeadiCont">
              <h4>Reset Your Password</h4>
              {/* <p>Lorem ipsum dolor sit amet consectetur.</p> */}
            </div>
            <div className="loginInpOuter">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={changePassword}
              >
                {(formik) => (
                  <Form>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                      <div className="forPassEye posiMins eyeWithError">
                        <FormikControl
                          control="input"
                          type={showPassword ? "text" : "password"}
                          label="New Password"
                          placeholder="Enter New Password"
                          labelClass="loginLabel"
                          name="password"
                          className="loginInput"
                          // onBlur={(e)=>test(e.target.value)}
                        />
                        {showPassword == true ? (
                          <RemoveRedEyeIcon
                            onClick={() => setShowPassword(false)}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={() => setShowPassword(true)}
                          />
                        )}
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                      <div className="forPassEye posiMins eyeWithError">
                        <FormikControl
                          control="input"
                          type={showConfirmPassword ? "text" : "password"}
                          label="Confirm Password"
                          placeholder="Confirm Password"
                          labelClass="loginLabel"
                          name="confirmPassword"
                          className="loginInput"
                        />
                        {showConfirmPassword == true ? (
                            <RemoveRedEyeIcon
                              onClick={() => setShowConfirmPassword(false)}
                            />
                          ) : (
                            <VisibilityOffIcon
                              onClick={() => setShowConfirmPassword(true)}
                            />
                        )}
                      </div>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <Button className="signInBt" type="submit">
                          Reset Password
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="loginRight">
          <img src={loginLeftImg} className="loginRightImg" />
        </div>
        {/* <Button onClick={() => navigate("/Home")}>Go to Home</Button> */}
      </div>

      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default ChangePassword;
