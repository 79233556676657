import React, { useState, useRef, useEffect } from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import avtarPic from "../../../assets/images/avtarPic.png";
import ModeSharpIcon from "@mui/icons-material/ModeSharp";

import editIco from "../../../assets/images/edit-icon.svg";
import deletIco from "../../../assets/images/delete-icon.svg";
import avtar from "../../../assets/images/avtarPic.png";
import SendIcon from "@mui/icons-material/Send";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import wordImage from '../../../assets/images/doc.png';
import pdfImage from '../../../assets/images/pdf.png';
import excelImage from '../../../assets/images/exel.png';
import txtImage from '../../../assets/images/text.png';


// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { makeGetRequest, makePostRequest, uploadFileRequest } from "../../../utils/utils";
// End
import moment from "moment";
import swal from "sweetalert";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

function SupportTickets() {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = React.useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [imageErrMsg, setImageErrMsg] = React.useState("");
  const [uploadImg, setUploadImg] = React.useState([]);
  const [bindurls, setbindurls] = React.useState([]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    console.log("Status", e.currentTarget.dataset.myValue)
    if (e.currentTarget.dataset.myValue !== undefined) {
      setSortBy(e.currentTarget.dataset.myValue);
    }
    setAnchorEl(null);
  };

  // Create New Ticket
  const [newTicket, setOpennewTicket] = React.useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleClicknewTicket = (scrollType) => () => {
    setOpennewTicket(true);
    setScroll(scrollType);
  };

  const closenewTicket = () => {
    setOpennewTicket(false);
    setUploadImg([]);
    setFormValues(null);
    setSelectedId();
    setMemberId([]);
    setIsEditTicket(false);
  };

  const descriptionElementRef = useRef(null);
  // End

  // Image Upload Start
  async function uploadImageOrFile(fileData) {
    var fileUrl = "";
    try {
      setShowLoader(true);
      const formData = new FormData();
      formData.append("image", fileData);
      await uploadFileRequest("superadmin/upload", formData).then(
        (response) => {
          console.log(response);
          fileUrl = response.data.imagepath;
        }
      );
      setShowLoader(false);
    } catch (error) {
      console.log(error);
    }
    return fileUrl;
  }

  async function validateImage(fileData) {
    if (
      fileData.name.match(
        /.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|docx|DOCX|doc|DOC|pdf|PDF|xls|XLS|txt|TXT|xlsx|XLSX)$/i
      )
    )
      return true;
    else return false;
  }

  async function uploadImageAndFile(e) {
    setImageErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];
    let validateRes = false;

    if (await validateImage(file)) {
      var imageUrl = await uploadImageOrFile(file);
      setUploadImg([...uploadImg, imageUrl]);
      console.log("img arr", uploadImg);
    } else {
      setImageErrMsg("Invalid file format");
    }
    setShowLoader(false);
  }
  // Image Upload End
  const deleteImage = async(index) => {
    await uploadImg.splice(index, 1);
    setUploadImg([...uploadImg]);
  }

  const [formValues, setFormValues] = React.useState(null);
  const initialValues = {
    ticket_title: "",
    description: "",
  }
  const validationSchema = Yup.object({
    ticket_title: Yup.string()
      .required("Required")
      .matches(
        /^([a-z.A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Numbers/Special Characters Not Allowed!!"
      ),
    description: Yup.string()
      .required("Required")
      .min(2, 'Too Short!')
      .max(200  , 'Too Long!')
  });
  const [selectedErrorMsg, setSelectedErrorMsg] = useState("");
  const [memberId, setMemberId] = useState([]);
  const [isEditTicket, setIsEditTicket] = useState(false);
  const addOrEditTicket = async (values) => {
    setSelectedErrorMsg("");
    if (selectedId) {
      if (isEditTicket) {
        /* let inputdata = {
          ticket_title : values.ticket_title,
          description : values.description,
          member_id : [memberId],
          sender_id : selectedId,
          fileuploaded : uploadImg,
        } */
        console.log(values);
        delete values.first_name;
        delete values.last_name;
        values.member_id = [memberId];
        values.sender_id = selectedId;
        values.fileuploaded = uploadImg;
        console.log("UpdateTicket", values);
        makePostRequest("/employee/updateTicket", values)
          .then((response) => {
            if (response.data) {
              console.log(response.data);
            }
            setFormValues();
            closenewTicket();
            getTicketList(null);
          })
          .catch((err) => {
            console.log(err);
            let errMsg = err.response.data.message;
          });
      } else {
        console.log(values);
        values.member_id = [memberId];
        values.sender_id = selectedId;
        values.fileuploaded = uploadImg;
        values.ticket_visible_flag = false;
        console.log("CreateTicket", values);
        makePostRequest("/employee/createTicket", values)
          .then((response) => {
            if (response.data) {
              console.log(response.data);
            }
            setFormValues();
            closenewTicket();
            getTicketList(null);
          })
          .catch((err) => {
            console.log(err);
            let errMsg = err.response.data.message;
          });
      }
      } else {
        setSelectedErrorMsg('Please Select Employee')
      }
  }

  //getallticketreply/:id
  const [selectedTicketId, setSelectedTicketId] = useState();
  
  const selectedTicket = (ticketObj) => {
    setSelectedTicketId(ticketObj.ticketid);
    getReplyTicketList(ticketObj.ticketid);
    setSelectedTicketDetails(ticketObj);
  }

  const [ticketReplyList, setTicketReplyList] = useState([]);
  const getReplyTicketList = async(id) => {
    await makeGetRequest("employee/getallticketreply/"+ id)
      .then((response) => {
        if (response.data.data) {
          console.log('TicketReply', response.data.data);
          setTicketReplyList(response.data.data[0].json_agg);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }
  const [errmsg, seterrmsg] = React.useState(false);
  const [msgtitle, setmsgTittle] = useState("");
  const handleInputChange = (e) => {
    const inputValue = e.target.value.slice(0, 255);
    if (inputValue.trim() === "") {
      seterrmsg(true);
    } else if (inputValue.trim() !== inputValue) {
      seterrmsg(true);
    } else {
      seterrmsg(false);
    }
    setmsgTittle(inputValue);
    // seterrmsg(false);
  };

  const addReply = async(ticketId) => {
    let reply = document.getElementById("mainReply").value;
    if (msgtitle) {
      seterrmsg(false);
      var inputdata = "";
      inputdata = {
        ticketid: ticketId,
        reply_title: msgtitle,
        member_id: localStorage.getItem("userId"),
      };
      setShowLoader(true);
      await makePostRequest("employee/addTicketreply", inputdata)
        .then((response) => {
          if (response.status == "201") {
            setmsgTittle("");
            getReplyTicketList(ticketId);
            setShowLoader(false);
          }
        })
        .catch((err) => {
          setShowLoader(false);
          //swal("There was an error!", "more error details", "warning");
        });
    } else {
      seterrmsg(true);
      // swal("Empty Reply!", "Unable to send empty reply", "warning");
    }
  }

  const getEmployeeList = async (searchStr) => {
    // /employeesearch/:column/:orderby/:search/:offsetValue/:limit
    setShowLoader(true);
    await makeGetRequest("/employee/employeesearch/"+sortColumnName+"/"+sortBy+"/"+searchStr+"/"+offsetvalue+"/"+itemsPerPage+"/null")
      .then((response) => {
        if (response.data.data) {
          console.log('employee',response.data.data.res);
          setEmployeeList(response.data.data.res);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }
  const [selectedId, setSelectedId] = useState();
  const onSelectEmployee = (empId) => {
    setSelectedId(empId);
    setMemberId(empId);
    console.log("id", empId);
  }

  const statusChangeFun = (status) => {
    setIsResolveTicket(status.target.value);
  }

  const [ticketList, setTicketList] = useState([]);
  const [isResolveTicketList, setIsResoveTicketList] = useState(false);
  const [selectedTicketDetails, setSelectedTicketDetails] = useState([]);
  var userid = parseInt(localStorage.getItem("userId"));

  const [sortColumnName, setSortColumnName] = React.useState("created_at");
  const [sortBy, setSortBy] = React.useState("desc");
  const [offsetvalue, setoffsetvalue] = React.useState(0);
  const [itemsPerPage] = React.useState(10)
  const [isResolveTicket, setIsResolveTicket] = useState(false);

  const [searchstr, setSearchStr] = useState();
  const getTicketList = async (searchstr) => {
    console.log("Search", searchstr);
    setShowLoader(true);
    if (!searchstr) searchstr = null;
    // /employee/getallticket/:id/:is_new/:is_resolve
    // "/employee/getallticket/" + searchstr
    // /getallticket/:column/:orderby/:search/:is_resolve/:offsetValue/:limit
    await makeGetRequest("/employee/getallticket/" + sortColumnName + "/" + sortBy + "/" + searchstr +
      "/"+isResolveTicket+ "/" + offsetvalue + "/" + itemsPerPage)
      .then((response) => {
        
        if (response.data.data) {
          //alert("Sagar")
          console.log('TicketList',response.data.data.res);
          setTicketList(response.data.data.res);
          setSelectedTicketId(response.data.data.res[0].ticketid);
          setSelectedTicketDetails(response.data.data.res[0]);
          getReplyTicketList(response.data.data.res[0].ticketid);
          //console.log(selectedTicketDetails);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  const editTicket = (ticketObj) => {
    setFormValues(ticketObj);
    setIsEditTicket(true);
    setUploadImg(ticketObj.fileuploaded);
    setOpennewTicket(true);
    onSelectEmployee(ticketObj.sender_id);
  }

  let clkindex = null;
  const urls = [];
  const viewDocs = (nId, index, fileuploaded) => {
    setbindurls([]);
    urls.length = 0;
    bindurls.length = 0;
    const btn = document.getElementById("noticeSlideID" + index);
    if (btn) {
      if (clkindex !== null) {
        const prevDivindex = document.getElementById(
          "noticeSlideID" + clkindex
        );
        if (prevDivindex) {
          prevDivindex.classList.remove("expandfilesDiv");
        }
      }
      btn.classList.toggle("expandfilesDiv");
      clkindex = index;
    }

    fileuploaded.forEach((item) => {
      try {
        console.log(typeof item);
        const parsedItem = JSON.parse(item);

        if (parsedItem && parsedItem.path) {
          urls.push(parsedItem.path);
        } else {
          urls.push(item);
        }
      } catch (error) {
        urls.push(item);
        console.error("Error:", error);
      }
    });
    setbindurls(urls);
  };

  const deleteTicket = async(id) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Ticket Deleted!",
          text: "Ticket is successfully deleted!",
          icon: "success",
          timer: 3000,
        }).then(function () {
          let inputdata = {
            ticketid : id,
            is_deleted : true,
          };
          makePostRequest("/employee/updateTicket", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                getTicketList(null);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        //swal("Cancelled", "Your Category is safe :)", "error");
      }
    });
  }
  const closeTicket = async(id) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to update this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Ticket Updated!",
          text: "Ticket is successfully Updated!",
          icon: "success",
          timer: 3000,
        }).then(function () {
          let inputdata = {
            ticketid : id,
            is_resolve : true,
          };
          makePostRequest("/employee/updateTicket", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                getTicketList(null);
              }
              setSearchStr(null);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        //swal("Cancelled", "Your Category is safe :)", "error");
      }
    });
  }

  useEffect(() => {
    localStorage.setItem('selectedModule','SupportTicket');
    getEmployeeList(null);
    getTicketList(null);
  }, [uploadImg, isResolveTicket, sortBy])
  
  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1 className="forBackArrowAlign">Support Tickets</h1>
              <Button
                className="pageTopMainBt"
                onClick={handleClicknewTicket("body")}
              >
                Create New Ticket
              </Button>
            </div>
          </Grid>
        </Grid>
        <div className="pageContDivMain">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <h1 class="panelHeading panelSubHeadingSt">Open Tickets</h1>
            </Grid>
            <Grid item xs={12} md={6}>
              <label className="pageLabel">Search Tickets</label>
              <div className="prodSearchDiv">
                <input
                  type="text"
                  value={searchstr}
                  className="prodSearchInpt"
                  placeholder="Search by ticket title or description or ticket no. or users"
                  onChange={(e) => getTicketList(e.target.value)}
                />
                <div>
                  <FilterListOutlinedIcon
                    className="filterIconSearch"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  />
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={handleClose}
                      data-my-value={"desc"}>
                      Newest to Oldest
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      data-my-value={"asc"}>
                      Oldest to Newest
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel className="pageLabel">Tickets Type</InputLabel>
              <Select className="prodSearchInpt"
              value={isResolveTicket}
              onChange={statusChangeFun}
              label="Ticket"
              >
                {/* <option onClick={() => statusChangeFun(false)}>Open Tickets</option>
                <option onClick={() => statusChangeFun(true)}>Close Tickets</option> */}
                <MenuItem value={false}>Open Tickets</MenuItem>
                <MenuItem value={true}>Close Tickets</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <div className="suportTicListMain">
                    {ticketList.length>0?
                    <>
                    {ticketList.map((ticketObj, index)=>
                    
                    <div className={selectedTicketId === ticketObj.ticketid ? "suportTicList withTicket activeTicket":"suportTicList withTicket"} onClick={() => selectedTicket(ticketObj)}>
                      <span className="tickLabl">Ticket No. T {ticketObj.ticketid}</span>
                      <em className="ticketDatNtime">{moment(ticketObj.created_at).format('DD/MM/YYYY hh:mm a')}</em>
                      <div className="suportListTop">
                        <div className="suportListProOut">
                          <div className="suportListPro">
                            {ticketObj.profile_pic == null ?
                              <img src={avtarPic} /> :
                              <img src={ticketObj.profile_pic} />}
                          </div>
                          <div className="proNameNMob">
                            <h2>{ticketObj.first_name} {ticketObj.last_name}</h2>
                            <p>9881789836</p>
                          </div>
                        </div>
                        <div className="ediDelMain">
                            {ticketObj.is_resolve === false ?
                              <div onClick={() => editTicket(ticketObj)}>
                                <img src={editIco} />
                              </div> : null}
                          <div onClick={()=>deleteTicket(ticketObj.ticketid)}>
                            <img src={deletIco} />
                          </div>
                        </div>
                      </div>
                      <div className="suportListBot">
                        <div className="suportListBotLft">
                          <h2>{ticketObj.ticket_title}</h2>
                            {ticketObj.description.length > 50 ?<p>{ticketObj.description.substring(0, 60)}
                            <span id={`dots`}>...{" "}</span></p>
                            :
                            <p>{ticketObj.description}</p>  
                            }
                        </div>
                        <div className="suportListBotRight">
                          {ticketObj.is_resolve === false?
                            <Button className="ticketClose" onClick={() => closeTicket(ticketObj.ticketid)}>Close</Button> : null
                          }
                        </div>
                      </div>
                      <div className="boxActions">
                        {ticketObj?.fileuploaded?.length > 0 && (
                          <span onClick={() =>
                                        viewDocs(
                                          ticketObj.tech_support_id,
                                          index,
                                          ticketObj.fileuploaded
                                        )
                                      } className="downoadYourFile" >
                            <FileDownloadIcon />
                            <em>Show Documents To Download </em>
                          </span>)}
                      </div>
                      <div
                        className="expandfilesDiv"
                        id={"noticeSlideID" + index}
                      >
                        <div className="doqumentDiv1">
                          {ticketObj?.fileuploaded?.length > 0 && (
                            <>
                              {bindurls.map((opt, index) => (
                                <div
                                  id="documentDiv"
                                  className="doqumentDiv1"
                                >
                                  <a href={opt} target="_blank">
                                    <div className="doquView">
                                      {opt.split(".").pop() ==
                                        "txt" ? (
                                        <img src={txtImage} />
                                      ) : opt.split(".").pop() ==
                                        "pdf" ? (
                                        <img src={pdfImage} />
                                      ) : opt.split(".").pop() ==
                                        "docx" ||
                                        opt.split(".").pop() ==
                                        "doc" ? (
                                        <img src={wordImage} />
                                      ) : opt.split(".").pop() ==
                                        "xls" ||
                                        opt.split(".").pop() ==
                                        "xlsx" ? (
                                        <img src={excelImage} />
                                      ) : (
                                        <img src={opt} />
                                      )}
                                    </div>
                                  </a>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>)}</>:<>No Data Found</>}
                  </div>
                </Grid>
                {ticketList.length > 0 ?
                <Grid item xs={12} md={6}>
                  <div className="ticketDetailsMain">
                    <div className="tickekDeailsTop">
                      <div className="suportTicList withTicket">
                        <span className="tickLabl">Ticket No. T {selectedTicketDetails.ticketid}</span>
                        <em className="ticketDatNtime">
                        {moment(selectedTicketDetails.created_at).format("DD/MM/YYYY hh:mm a")}
                        </em>
                        <div className="suportListTop">
                          <div className="suportListProOut">
                            <div className="suportListPro">
                              {selectedTicketDetails.profile_pic == null ?<img src={avtarPic} />
                              :  <img src={selectedTicketDetails.profile_pic} />}

                            </div>
                            <div className="proNameNMob">
                                  <h2>{selectedTicketDetails.first_name} {selectedTicketDetails.last_name}</h2>
                              <p>9881789836</p>
                            </div>
                          </div>
                        </div>
                        <div className="suportListBot">
                          <div className="suportListBotLft">
                            <h2>{selectedTicketDetails.ticket_title}</h2>
                            <p className="ticketDetailsTxt">{selectedTicketDetails.description}</p>
                          </div>
                        </div>
                      </div>
                      </div>
                    <div className="disctionBottomContMain">
                    <div className="chatDivOuterScroll">
                    {ticketReplyList ?<>
                    {ticketReplyList.map((replyListObj, index) => (
                      <div className="disctionComntScrol ticketChatMain">
                      <>
                        {replyListObj.member_id != userid ?
                        <div className="ticketChatLeft">
                          <div className="parkSocityInner parkSocityInnerTicket">
                            <div className="parkSocityTop">
                              <div className="parkSocityTopPro">
                                    {replyListObj.profile_pic === null ?<img alt="Remy Sharp" src={avtarPic} /> : replyListObj.profile_pic === "" ? <img alt="Remy Sharp" src={avtarPic} /> : <img alt="Remy Sharp" src={replyListObj.profile_pic} />}
                              </div>
                              <h4>{replyListObj.first_name} {replyListObj.last_name}</h4>
                            </div>
                            <p className="parContTx">
                              {replyListObj.reply_title}
                            </p>
                            <p className="parContTx parContTxDate">
                              {moment(replyListObj.created_at).format("DD/MM/YYYY hh:mm a")}
                            </p>
                          </div>
                        </div>    
                        :
                        <div className="ticketChatRight">
                          <div className="parkSocityInner parkSocityInnerTicket">
                            <div className="parkSocityTop">
                              <div className="parkSocityTopPro">
                              {replyListObj.profile_pic == null?<img alt="Remy Sharp" src={avtarPic} />:
                              <img alt="Remy Sharp" src={replyListObj.profile_pic} />}
                              </div>
                              <h4>{replyListObj.first_name} {replyListObj.last_name}</h4>
                            </div>
                            <div className="titlNDate">
                              <p className="parContTx">
                              {replyListObj.reply_title}
                              </p>
                              <p className="parContTx parContTxDate">
                              {moment(replyListObj.created_at).format("DD/MM/YYYY hh:mm a")}
                              </p>
                            </div>
                          </div>
                        </div>
                        }
                      </>
                      </div>
                    ))}</>:null}
                    </div>
                      <div className="typeComentOut">
                        <div className="typeComentOutIco">
                            <SendIcon onClick={() => addReply(selectedTicketDetails.ticketid)} />
                          <textarea
                            className="textAriaChat"
                            type="text"
                            value={msgtitle}
                            id="mainReply"
                            placeholder={`Send a message to ${selectedTicketDetails.first_name + " " + selectedTicketDetails.last_name
                              }...`}
                            onChange={handleInputChange}
                          />
                        </div>
                        {errmsg ? (
                          <div className="errorSt">
                            {"Required and white spaces not allow"}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Grid>
                :null}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* Create New Ticket */}
      <div>
        <Dialog
          open={newTicket}
          // onClose={closeCartonOrSingle}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
          {isEditTicket? <>Edit Ticket</> : <>Create New Ticket</>}
            <CloseIcon className="modalCloseBt" onClick={closenewTicket} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
              <Formik
                initialValues={formValues || initialValues}
                validationSchema={validationSchema}
                onSubmit={addOrEditTicket}
                enableReinitialize
              >
                {(formik) => (
                  <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <label className="pageLabel">Select By Keywords</label>
                      <input className="prodSearchInpt" placeholder="Search" onChange={(e)=>getEmployeeList(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div className="popListingMain">
                              {employeeList.map((employeeObj, index) =>(
                          <>
                          
                          <div className={selectedId === employeeObj.employee_id ? "popListingInr activeTicket": "popListingInr"} key={index} onClick={() => onSelectEmployee(employeeObj.employee_id)}>
                              <div className="popListPro">
                                {employeeObj.profile_pic == "" || null ? <img src={avtar}/>: <img src={employeeObj.profile_pic}/>}
                              </div>
                              <div className="popDetailS">
                              <h1>{employeeObj.first_name} {employeeObj.last_name}</h1>
                              <b>{employeeObj.contact_no}</b>
                                <span>{employeeObj.email_id}</span>
                              </div>
                              <div class="mainListCont mainListInrCont">
                                <label>Designation</label>
                                <span class="mainListSubTx">{employeeObj.designation_name}</span>
                              </div>
                              <div class="mainListCont mainListInrCont">
                                <label>Department</label>
                                <span class="mainListSubTx">{employeeObj.department_name}</span>
                              </div>
                          </div>
                          </>))}
                          <span className="errorSt">{selectedErrorMsg}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormikControl
                        control="input"
                        type="text"
                        label="Title Of Message"
                        placeholder="Title Of Message"
                        labelClass="pageLabel"
                        name="ticket_title"
                        isRequired="true"
                        className="prodSearchInpt"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormikControl
                        control="textarea"
                        type="textarea"
                        label="Enter Message Here"
                        placeholder="Type your Message"
                        labelClass="pageLabel"
                        name="description"
                        isRequired="true"
                        className="modalTexArea"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="myNewAttach">
                        <div className="atchmentMain">
                          <div className="atchMain" onChange={(e)=>uploadImageAndFile(e)}>
                            <AttachmentOutlinedIcon />
                            <input type="file" id="attachmentid" />
                            <span>Add Attachment</span>
                            <span className="formatNot">
                              Note: format allowed for document-
                              (pdf,image,docs,xls,text)
                            </span>
                          </div>
                        </div>
                        {uploadImg ? (
                        <>
                        {uploadImg.map((img, index) => (
                          <div class="fileName" key={index}>
                            <span>{img}</span>
                            <CloseIcon onClick={() => deleteImage(index)} />
                          </div>))}
                        </>
                        ) : <div class="fileName">
                          <span>No File Uploaded</span>
                          </div>}
                      </div>
                      <span className="errorSt">{imageErrMsg}</span>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        style={{ textAlign: "center" }}
                      >
                        <span className="lineBtns">
                          <Button
                            className="cancelEditBtn"
                            onClick={closenewTicket}
                          >
                            <span>Cancel</span>
                          </Button>

                          <Button className="modalBtSmallWidth" type="submit">
                                {isEditTicket ? <span>Update</span> : <span>Save</span>}
                          </Button>
                        </span>
                      </Grid>
                  </Grid>
                  </Form>
                )}
              </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default SupportTickets;
