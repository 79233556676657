import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// import * as firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyDy61aBqMm-8IGh2nGBVuO9XCddNbRA1Pw",
  authDomain: "starkingdom-9def1.firebaseapp.com",
  projectId: "starkingdom-9def1",
  storageBucket: "starkingdom-9def1.appspot.com",
  messagingSenderId: "512545625023",
  appId: "1:512545625023:web:0bb8e484d1f5d85e5b870a",
  measurementId: "G-EBML7FJME2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging
const messaging = getMessaging(app);

export { messaging };

// export const messaging = getMessaging(app);

// export const generateToken = async () => {
//   // const permission = await Notification.requestPermission();
//   // console.log("permission===>"+permission);
//   // if(permission === "granted")
//   //     {
//   // const token = await getToken(messaging)

//   // // ,{
//   // //     vapidKey:"BDHVWE6Iraow3JQbntfYYckfxjpHbNpNsf2vE_aKr_ze6BZkpWinyI5JOpUOvH-wl2Vu52W0-Gwr6uaCCZ-I_FQ"

//   // console.log("token==>"+token);
//   // return token;
//   // }
//   // else
//   // {
//   //   console.log('Notification permission denied.');
//   // }

//   // onMessage(messaging, (payload) => {
//   //   console.log('Foreground Message:', payload);
//   //   // Handle the notification or update your UI
//   // });

//   return getToken(messaging, { vapidKey: 'BDHVWE6Iraow3JQbntfYYckfxjpHbNpNsf2vE_aKr_ze6BZkpWinyI5JOpUOvH-wl2Vu52W0-Gwr6uaCCZ-I_FQ' }).then((currentToken) => {
//     if (currentToken) {
//       console.log('current token for client: ', currentToken);
//       // Perform any other neccessary action with the token
//       return currentToken;
//     } else {
//       // Show permission request UI
//       console.log('No registration token available. Request permission to generate one.');
//     }
//   })
//     .catch((err) => {
//       console.log('An error occurred while retrieving token. ', err);
//     });

// }

initializeApp(firebaseConfig);