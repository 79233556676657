import React from "react";
import { Button, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PrintIcon from "@mui/icons-material/Print";
import qrImg from "../../../assets/images/qrCode.svg";
import WestIcon from "@mui/icons-material/West";
import printLogo from "../../../assets/images/logo.png";

function NoticePrintFormat() {
  const navigate = useNavigate();

  const Print = () => {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  const onButtonClick = () => {
    const pdfUrl = "Sample.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="warningPrintMain">
      <div id="printablediv">
        <div className="scanTopBar"></div>
        <div className="scanOrderMain">
          <div className="printHeadOter">
            <h1 className="printHeadTop">Print Warning</h1>
            <div className="warningInnerDiv">
              <div className="warningConentDiv">
                <div className="printLogoHead">
                  <img src={printLogo} />
                  <h1>Star Labels</h1>
                </div>
                <div className="contPrint">
                  <span className="totxt">To</span>
                  <b className="toSubtxt">Arlene McCoy</b>
                  <span className="toDesigtxt">Senior Manager</span>
                  <p className="subLine">
                    <span className="subTex">Subject:</span>
                    <b className="subLineName">Misuse Of Office Premises</b>
                  </p>
                  <p className="MainContentPrint">
                    Lorem ipsum dolor sit amet consectetur. Pulvinar ultrices
                    tincidunt tempor ac rhoncus donec. Neque nullam fringilla
                    purus suscipit. Feugiat blandit integer ridiculus in cursus
                    viverra mi donec. Quam eget quam at risus diam in a. Eu
                    blandit ut molestie egestas venenatis malesuada. Maecenas
                    ornare volutpat ornare vel morbi sit habitant ac. Ut justo
                    tellus donec enim. Dictum dolor feugiat magna convallis
                    vestibulum. Non malesuada lectus pellentesque semper commodo
                    velit nunc arcu. Scelerisque convallis non donec malesuada
                    vitae. Etiam etiam cursus viverra massa eu. Dapibus
                    vestibulum nec pharetra ornare vel nec at rhoncus. Vitae
                    elit proin tincidunt blandit enim eget nulla etiam mauris.
                    Quam massa aenean pretium at.
                  </p>
                  <div className="reardext">
                    <span className="toDesigtxt">Regards,</span>
                    <b className="toSubtxt">Office Management</b>
                  </div>
                  <div className="authoriDiv">
                    <span>Warning letter for verbal Altertation</span>
                    <b>Authority Person</b>
                  </div>
                </div>
              </div>
              <div className="printBotBts">
                <Button className="pageTopMainBt blueBt" onClick={Print}>
                  Print
                </Button>
                <Button
                  className="pageTopMainBt blueBt"
                  onClick={onButtonClick}
                >
                  Download
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoticePrintFormat;
