import React, { useState, useEffect, useRef } from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate } from "react-router-dom";
import setEx from "../../../assets/images/setEx.png";
import { Button, Grid } from "@mui/material";
import exchaneBlack from "../../../assets/images/exchaneBlack.png";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import swal from "sweetalert";

// End

function Dashboard() {
  const [showLoader, setShowLoader] = React.useState(false);
  const [masterStar, setMasterStar] = React.useState(null);
  const [starValues, setStarValues] = useState(null);
  const [starId, setStarId] = useState(null);

  async function getStarAmount() {
    setShowLoader(true);
    await makeGetRequest("employee/getmasterstarcount/")
      .then((response) => {
        if (response.data) {
          console.log(response.data.data[0]);
          if (response.data.data[0]) {
            setMasterStar(response.data.data[0]);
            setStarId(response.data.data[0].star_id);
            setStarValues(response.data.data[0]);
          }
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
      });
  }

  //   For Validation
  const initialValues = {
    dollar_amount: 1,
    start_count: 1,
  };

  const validationSchema = Yup.object({
    dollar_amount: Yup.string()
      .matches(/^\d+$/, "Invalid value")
      .required("Required Dollar Amount"),
    start_count: Yup.string()
      .matches(/^\d+$/, "Invalid value")
      .required("Required Star Amount"),
  });

  const onSubmit = async (values,{resetForm}) => {
    
    const willUpdate = await swal({
      title: "Are you sure?",
      text: "Do you really want to update star exchange rate?",
      icon: "warning",
      buttons: ["No", "Yes"],
    });
    console.log(values)
    if (willUpdate) {
      if (starId !== null) {
        values.star_id = starId;
        makePostRequest("employee/updatestar", values)
          .then((response) => {
            if (response.data) {
              getStarAmount();
            }
            resetForm();
            // values.dollar_amount = 1;
            // values.start_count = 1;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        makePostRequest("employee/insertstar", values)
          .then((response) => {
            if (response.data) {
              console.log(response);
              getStarAmount();
            }
            // values.dollar_amount = 1;
            // values.start_count = 1;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      values.start_count = 0;
    }
  };

  // For Single Unit Only
  const [SetExchangeRate, setOpenSetExchangeRate] = React.useState(false);

  const [scroll, setScroll] = useState("paper");

  const handleClickSetExchangeRate = (scrollType) => () => {
    setOpenSetExchangeRate(true);
    setScroll(scrollType);
  };

  const closeSetExchangeRate = () => {
    setOpenSetExchangeRate(false);
  };

  const descriptionElementRef = useRef(null);
  // End

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('selectedModule','Dashboard');
    getStarAmount();
  }, []);

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <div className="setExchangeMain">
          <div className="exchangeLeft">
            <div className="setExchImgDiv">
              <img src={setEx} />
            </div>
            <div>
              <h1>Set Exchange Rate</h1><>(Only CFO can set exchange rate)</>
              <p>Set the exchange rate from Stars to Dollars and vise versa</p>
            </div>
          </div>
          {JSON.parse(localStorage.getItem('accessRights')!=null)?
                          <>
                          {JSON.parse(localStorage.getItem('accessRights')).map((module) => (
                            <>
                              {module.moduleName == 'CFOAccess' && module.status == true ?
                            <>
          <div className="exchangeRight">
            <Button
              className="exchangeSetBt"
              onClick={handleClickSetExchangeRate("body")}
            >
              Set Exchange Rate
            </Button>
          </div>
          </>:<></>}</>))}</>:<></>}
        </div>

        <div className="underConstruction">
          <div className="workers">
            <div className="left_hand"></div>
            <div className="right_hand"></div>
          </div>
          <h3 class="title">Our Website Is Under Construction</h3>
        </div>
      </div>

      {/* For Single Unit Only */}
      <div>
        <Dialog
          open={SetExchangeRate}
          // onClose={closeSetExchangeRate}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Set Exchange Rate
            <CloseIcon
              className="modalCloseBt"
              onClick={closeSetExchangeRate}
            />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <p className="selectSubTx">Current Exchange Rate</p>

                      <div className="popUpExchImgOuter">
                        {masterStar != null ? (
                          <>
                            <div className="setExchImgDiv">
                              <img src={exchaneBlack} />
                            </div>
                            <h2>
                            <b style={{ color: "#c82d33" }}>
                                {masterStar.start_count}
                              </b>{" "}
                              Star
                              {" "}
                              =<b style={{ color: "#c82d33" }}>
                                ₹{masterStar.dollar_amount}
                              </b>{" "}
                             
                            </h2>
                          </>
                        ) : (
                          <div className="reloadStarError">
                            Please Reload Star Amount
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  <Formik
                    initialValues={starValues || initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize
                  >
                    {(formik) => (
                      <Form>
                        <Grid container spacing={3}>
                          
                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter New Star Amount"
                              placeholder="Star Quantity"
                              labelClass="reggistraLabel"
                              name="start_count"
                              className="prodSearchInpt"
                              disabled
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter New Rupees Amount"
                              placeholder="$"
                              labelClass="reggistraLabel"
                              name="dollar_amount"
                              className="prodSearchInpt"
                              
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ textAlign: "center" }}
                          >
                            <Button type="submit" className="modalBtSmallWidth">
                              Set Exchange Rate
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default Dashboard;
