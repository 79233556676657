// import React, { useEffect } from "react";
// import { Button, Grid, Checkbox, Alert } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import TextField from "@mui/material/TextField";
// import InputAdornment from "@mui/material/InputAdornment";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import IconButton from "@mui/material/IconButton";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import MailOutlineIcon from "@mui/icons-material/MailOutline";
// import LockOpenIcon from "@mui/icons-material/LockOpen";
// import DraftsIcon from "@mui/icons-material/Drafts";

// import PropTypes from "prop-types";
// import { Global } from "@emotion/react";
// import { styled } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
// import { grey } from "@mui/material/colors";
// import Box from "@mui/material/Box";
// import Skeleton from "@mui/material/Skeleton";
// import Typography from "@mui/material/Typography";
// import SwipeableDrawer from "@mui/material/SwipeableDrawer";

// import { Formik, Form } from "formik";
// import * as Yup from "yup";
// import FormikControl from "../../../components/FormikControl";
// import { makePostRequest } from "../../../utils/utils";

// import AOS from "aos";
// import "aos/dist/aos.css";

// const drawerBleeding = 50;

// const Root = styled("div")(({ theme }) => ({
//   height: "100%",
//   backgroundColor:
//     theme.palette.mode === "light"
//       ? grey[100]
//       : theme.palette.background.default,
// }));

// const StyledBox = styled("div")(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
// }));

// const Puller = styled("div")(({ theme }) => ({
//   width: 30,
//   height: 6,
//   backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
//   borderRadius: 3,
//   position: "absolute",
//   top: 8,
//   left: "calc(50% - 15px)",
// }));

// const employeeDetail = {
//   employee_id: 0,
//   fcmtoken: "",
// };

// function LoginMobile(props) {
//   useEffect(() => {
//     AOS.init();
//   }, []);

//   const [loginErrMsg, setLoginErrMsg] = React.useState("");
//   const [showLoader, setShowLoader] = React.useState(false);
//   const [upload, setUpload] = React.useState(false);
//   const [fcmToken, setFcmToken] = React.useState(null);

//   const initialValues = {
//     email_id: "",
//     password: "",
//     //selectOption: "",
//   };

//   const validationSchema = Yup.object({
//     email_id: Yup.string().email().required("Email address is required"),
//     password: Yup.string().required("Password should not be empty."),
//     //selectOption: Yup.string().required("Required"),
//   });

//   const initialValuesForgotPass = {
//     email_id: "",
//     //password: "",
//     //selectOption: "",
//   };

//   const validationSchemaForgotPass = Yup.object({
//     email_id: Yup.string().email().required("Email address is required"),
//   });

//   const onSubmit = async (values) => {

//     setShowLoader(true);
//     setLoginErrMsg("");

//     await makePostRequest("employee/employeelogin", values)
//       .then((response) => {
//         if (response.data.data) {
//           if (response.data.data.user_type == "SuperAdmin") {
//             navigate("/");
//           } else {
//             sessionStorage.setItem("schema", response.data.data.schemaname);
//             sessionStorage.setItem("accessToken", response.data.data.token_id);
//             localStorage.setItem("userType", response.data.data.user_type);
//             localStorage.setItem("userId", response.data.data.employee_id);
//             localStorage.setItem("companyId", response.data.data.company_id);
//             localStorage.setItem(
//               "username",
//               response.data.data.first_name + " " + response.data.data.last_name
//             );

//             sendMessageToReactNative(response.data.data);

//             if (fcmToken) {
//               updateFcmToken(response.data.data.employee_id);
//             }

//             navigate("/DashboardPage");
//           }
//           setShowLoader(false);
//         }
//       })
//       .catch((err) => {
//         console.log("Error", err);
//         setLoginErrMsg("Invalid Username/Password");
//         setShowLoader(false);
//       });
//   };

//   const sendMessageToReactNative = (data) => {
//     const message = { type: 'FROM_REACT_JS', data: data };
//     window.ReactNativeWebView.postMessage(JSON.stringify(message));
//   };

//   const [emailErrMsg, setEmailErrMsg] = React.useState("");
//   const onSubmitForgotPass = (values) => {
//     console.log("email", values);
//     setShowLoader(true);
//     setEmailErrMsg("");
//     makePostRequest("superadmin/forgotPassword", values)
//       .then((response) => {
//         if (response.data.data) {
//           console.log(response.data.data);
//           navigate("/OtpScreen");
//           setShowLoader(false);
//         }
//       })
//       .catch((err) => {
//         setEmailErrMsg("Invalid Email");
//         setShowLoader(false);
//       });
//   };

//   const updateFcmToken = async (Id) => {
//     employeeDetail.employee_id = Id;
//     employeeDetail.fcmtoken = fcmToken;
//     await makePostRequest("employee/updateemployee", employeeDetail)
//       .then((response) => {
//         navigate("/DashboardPage");
//       })
//       .catch((err) => {
//         console.log("updating fcm token error", err);
//       });
//   };

//   const navigate = useNavigate();

//   const [showPassword, setShowPassword] = React.useState(false);
//   const handleClickShowPassword = () => setShowPassword((show) => !show);

//   const handleMouseDownPassword = (event) => {
//     event.preventDefault();
//   };

//   const { window1 } = props;
//   const [open, setOpen] = React.useState(false);

//   const toggleDrawer = (newOpen) => () => {
//     setOpen(newOpen);
//   };

//   // This is used only for the example
//   const container =
//     window1 !== undefined ? () => window1().document.body : undefined;

//     const handleMessage = (event) => {
//       alert("in handle message");
//       if (event.origin !== 'https://tutorvox.com/splash') return;

//       try {
//         const message = JSON.parse(event.data);

//         alert('Message received:', message);
//         if (message.type === 'FROM_REACT_NATIVE') {
//           setFcmToken(message.data);
//           alert("Receive Data from React Native: " + message.data);
//           console.log('Message received from React Native:', message.data);
//         }
//       } catch (error) {
//         console.error('Error parsing message:', error);
//       }
//     };

//   React.useEffect(() => {

//     // Handler for receiving messages from React Native

//     window.addEventListener('message', handleMessage);

//     return () => {
//       window.removeEventListener('message', handleMessage);
//     };

//   }, []);

//   return (
//     <>
//       <div className="mobileCssMain">
//         <div className="container">
//           <div className="wrapper">
//             <div className="loginMain">
//               <Grid container spacing={3}>
//                 <Grid item xs={12} md={12} data-aos="zoom-in">
//                   <h1>Login to your company account</h1>
//                 </Grid>
//                 <Grid item xs={12} md={12} data-aos="zoom-in">
//                   <p>
//                     Please sign in to your account which is provided by your
//                     company
//                   </p>
//                 </Grid>
//                 {loginErrMsg ? (
//                   <div>
//                     <span className="logError">{loginErrMsg}</span>
//                   </div>
//                 ) : (
//                   <></>
//                 )}
//                 <Grid item xs={12} md={12}>
//                   <div className="loginCont">
//                     <Formik
//                       initialValues={initialValues}
//                       validationSchema={validationSchema}
//                       onSubmit={onSubmit}
//                     >
//                       {(formik) => (
//                         <Form>
//                           <Grid container spacing={3} data-aos="zoom-in">
//                             <Grid item xs={12} md={12}>
//                               {/* <label className="loginLable">Email Address</label>
//                             <TextField
//                               id="outlined-start-adornment"
//                               placeholder="Email"
//                               InputProps={{
//                                 startAdornment: (
//                                   <InputAdornment position="start">
//                                     <MailOutlineIcon />
//                                   </InputAdornment>
//                                 ),
//                               }}
//                             /> */}
//                               <FormikControl
//                                 control="input"
//                                 type="email"
//                                 label="Email Address"
//                                 labelClass="loginLable"
//                                 name="email_id"
//                                 className="loginInput"
//                                 placeholder="Email address"
//                               />
//                             </Grid>

//                             <Grid item xs={12} md={12}>
//                               {/* <label className="loginLable">Password</label>
//                             <OutlinedInput
//                               id="outlined-adornment-password"
//                               placeholder="Password"
//                               type={showPassword ? "text" : "password"}
//                               startAdornment={
//                                 <InputAdornment position="start">
//                                   <LockOpenIcon />
//                                 </InputAdornment>
//                               }
//                               endAdornment={
//                                 <InputAdornment position="end">
//                                   <IconButton
//                                     aria-label="toggle password visibility"
//                                     onClick={handleClickShowPassword}
//                                     onMouseDown={handleMouseDownPassword}
//                                     edge="end"
//                                   >
//                                     {showPassword ? (
//                                       <VisibilityOff />
//                                     ) : (
//                                       <Visibility />
//                                     )}
//                                   </IconButton>
//                                 </InputAdornment>
//                               }
//                             /> */}
//                               <FormikControl
//                                 control="input"
//                                 type="password"
//                                 label="Password"
//                                 labelClass="loginLable"
//                                 name="password"
//                                 className="loginInput"
//                                 placeholder="Password"
//                               />
//                             </Grid>

//                             <Grid item xs={12} md={12}>
//                               <h3
//                                 className="forgotTex"
//                                 onClick={toggleDrawer(true)}
//                               >
//                                 Forgot Password?
//                               </h3>
//                             </Grid>

//                             <Grid
//                               item
//                               xs={12}
//                               md={12}
//                               style={{ textAlign: "centers" }}
//                             >
//                               <Button className="loginBt" type="submit">
//                                 Sign In
//                               </Button>
//                             </Grid>
//                           </Grid>
//                         </Form>
//                       )}
//                     </Formik>
//                     <Root>
//                       <CssBaseline />
//                       <Global
//                         styles={{
//                           ".MuiDrawer-root > .MuiPaper-root": {
//                             height: `calc(400px - ${drawerBleeding}px)`,
//                             overflow: "visible",
//                           },
//                         }}
//                       />
//                       <SwipeableDrawer
//                         container={container}
//                         anchor="bottom"
//                         open={open}
//                         onClose={toggleDrawer(false)}
//                         onOpen={toggleDrawer(true)}
//                         swipeAreaWidth={drawerBleeding}
//                         disableSwipeToOpen={false}
//                         ModalProps={{
//                           keepMounted: true,
//                         }}
//                       >
//                         <StyledBox
//                           sx={{
//                             position: "absolute",
//                             top: -drawerBleeding,
//                             borderTopLeftRadius: 8,
//                             borderTopRightRadius: 8,
//                             visibility: "visible",
//                             right: 0,
//                             left: 0,
//                           }}
//                         >
//                           <Puller />
//                           {/* <Typography sx={{ p: 2, color: "text.secondary" }}>
//                             51 results
//                           </Typography> */}
//                         </StyledBox>
//                         <StyledBox
//                           sx={{
//                             height: "100%",
//                             overflow: "auto",
//                           }}
//                         >
//                           <div className="mobileCssMain">
//                             <div className="forgotOter">
//                               <div className="wrapper">
//                                 <Grid container spacing={2}>
//                                   <Grid item xs={12} md={12}>
//                                     <h1>Forgot password?</h1>
//                                   </Grid>
//                                   <Grid item xs={12} md={12}>
//                                     <p>
//                                       Select your email address and we will send
//                                       you confirmation code to reset your
//                                       password.
//                                     </p>
//                                   </Grid>
//                                   <Grid item xs={12} md={12}>
//                                     <Formik
//                                       initialValues={initialValuesForgotPass}
//                                       validationSchema={
//                                         validationSchemaForgotPass
//                                       }
//                                       enableReinitialize
//                                       onSubmit={onSubmitForgotPass}
//                                     >
//                                       {(formik) => (
//                                         <Form>
//                                           <div className="forotInput">
//                                             <div className="forGmail">
//                                               <DraftsIcon />
//                                             </div>
//                                             <FormikControl
//                                               control="input"
//                                               type="email"
//                                               label="Send Via Email"
//                                               labelClass="loginLable"
//                                               name="email_id"
//                                               placeholder="Email address"
//                                             />
//                                             <div className="errorSt">
//                                               {emailErrMsg}
//                                             </div>
//                                           </div>

//                                           <Button
//                                             className="loginBt"
//                                             type="submit"
//                                           >
//                                             Continue
//                                           </Button>
//                                         </Form>
//                                       )}
//                                     </Formik>
//                                   </Grid>
//                                 </Grid>
//                               </div>
//                             </div>
//                           </div>
//                         </StyledBox>
//                       </SwipeableDrawer>
//                     </Root>
//                   </div>
//                 </Grid>
//               </Grid>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default LoginMobile;

import React, { useEffect, useContext } from "react";
import { Button, Grid, Checkbox, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import DraftsIcon from "@mui/icons-material/Drafts";

import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../../components/FormikControl";
import { makePostRequest } from "../../../utils/utils";

import AOS from "aos";
import "aos/dist/aos.css";
import { NotificationContext } from "../../componentsMobile/NotificationContext";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";


const drawerBleeding = 50;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const employeeDetail = {
  employee_id: 0,
  fcmtoken: "",
};

function LoginMobile(props) {

  useEffect(() => {
    AOS.init();
  }, []);

  // //const socket = new WebSocket('wss://api.tutorvox.com/ws');//wss://api.tutorvox.com
  // const socket = new WebSocket('wss://geekholic.in/ws');//

  // socket.onOpen = function (event) {
  //   //alert(event.data); // Show notification when breakfast is open or closed
  //   console.log(event.data)
  // }
  // socket.onmessage = function (event) {
  //   alert(event.data); // Show notification when breakfast is open or closed
  //   console.log(event.data)
  // };

  // socket.onerror = (error) => {
  //   console.error('WebSocket error:', error.message);
  // };


  const { addNotification } = useContext(NotificationContext);
  const [loginErrMsg, setLoginErrMsg] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const [upload, setUpload] = React.useState(false);
  const [fcmToken, setFcmToken] = React.useState(null);
  const [enteremployeecode, setEnteremployeecode] = React.useState("");
  const [enterpassword, setEnterpassword] = React.useState("");


  const [initialValues, setIntialValues] = React.useState({
    employee_code: "",
    password: "",
    //selectOption: "",
  });

  const validationSchema = Yup.object({
    employee_code: Yup.string().required("Employee Code is required"),
    password: Yup.string().required("Password should not be empty."),
    //selectOption: Yup.string().required("Required"),
  });

  const initialValuesForgotPass = {
    email_id: "",
    //password: "",
    //selectOption: "",
  };

  const validationSchemaForgotPass = Yup.object({
    email_id: Yup.string().email().required("Email address is required"),
  });

  const onSubmit = async (values) => {

    setShowLoader(true);
    setLoginErrMsg("");

    await makePostRequest("employee/employeelogin", values)
      .then((response) => {

        if (response.data.data) {
          if (response.data.data.user_type == "SuperAdmin") {
            navigate("/");
          } else {

            if (response.data.data.is_password_reset == false) {
              sessionStorage.setItem("accessToken", response.data.data.token_id);
              sessionStorage.setItem("schema", response.data.data.schemaname);
              navigate("/ResetPassword");
            } else {
              sessionStorage.setItem("schema", response.data.data.schemaname);
              sessionStorage.setItem(
                "accessToken",
                response.data.data.token_id
              );
              // console.log(values.employee_code);
              // setEnteremployeecode(values.employee_code);
              // setEnterpassword(values.password);
              localStorage.setItem("employeecode", values.employee_code);
              localStorage.setItem("password", values.password);
              localStorage.setItem("userType", response.data.data.user_type);
              localStorage.setItem("userId", response.data.data.employee_id);
              localStorage.setItem("companyId", response.data.data.company_id);
              localStorage.setItem(
                "username",
                response.data.data.first_name +
                " " +
                response.data.data.last_name
              );
              localStorage.setItem(
                "is_employee_directory",
                response.data.data.is_employee_directory
              );
              console.log("CFO access" + response.data.data.is_cfo_warning)
              localStorage.setItem(
                "is_cfo_warning",
                response.data.data.is_cfo_warning
              );
              localStorage.setItem(
                "document",
                JSON.stringify(response.data.data.documents)
              );
              localStorage.setItem(
                "reporting_to",
                response.data.data.reporting_to
              );

              localStorage.setItem(
                "company_name",
                response.data.data.company_name
              ); //

              localStorage.setItem(
                "company_logo",
                response.data.data.company_logo
              );
              localStorage.setItem(
                "profilepicture",
                response.data.data.profile_pic
              );

              sendMessageToReactNative(response.data.data);

              if (fcmToken) {
                updateFcmToken(response.data.data.employee_id);
              }
              navigate("/DashboardPage");
            }
            setShowLoader(false);
          }
        }
      })
      .catch((err) => {
        console.log("Error", err);
        setLoginErrMsg("Invalid Username/Password");
        setShowLoader(false);
      });
  };

  const sendMessageToReactNative = (data) => {
    const message = { type: "FROM_REACT_JS", data: data };
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    } else {
      console.log("Not in a React Native WebView environment");
    }
  };

  const [emailErrMsg, setEmailErrMsg] = React.useState("");

  const onSubmitForgotPass = (values) => {

    setShowLoader(true);
    setEmailErrMsg("");
    makePostRequest("superadmin/forgotPassword", values)
      .then((response) => {
        if (response.data.data) {
          console.log(response.data.data);
          sessionStorage.setItem("accessToken", null);
          sessionStorage.setItem("schema", response.data.data.schemaname);
          navigate("/OtpScreen");
          setShowLoader(false);
        }
      })
      .catch((err) => {
        setEmailErrMsg("Invalid Email");
        setShowLoader(false);
      });
  };

  const updateFcmToken = async (Id) => {

    employeeDetail.employee_id = Id;
    employeeDetail.fcmtoken = fcmToken;
    await makePostRequest("employee/updateemployee/status", employeeDetail)
      .then((response) => {
        navigate("/DashboardPage");
      })
      .catch((err) => {
        alert(err.message.toString());
        navigate("/DashboardPage");
        console.log("updating fcm token error", err);
      });
  };

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { window1 } = props;
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container =
    window1 !== undefined ? () => window1().document.body : undefined;

  // const handleMessage = (event) => {
  //   alert(event.origin);
  //   if (event.origin !== "https://tutorvox.com/splash") return;
  //   try {
  //     const message = JSON.parse(event.data);
  //     alert(message);
  //     if (message.type === "FROM_REACT_NATIVE") {
  //       setFcmToken(message.data);
  //       console.log("Message received from React Native:", message.data);
  //     }
  //   } catch (error) {
  //     alert("in catch", error);
  //     console.error("Error parsing message:", error);
  //   }
  // };
  const loadlogindetails = () => {

    const employeecode = localStorage.getItem('employeecode');
    const password = localStorage.getItem('password');
    console.log(employeecode)
    if (employeecode != null && password != null) {
      setIntialValues({
        employee_code: employeecode,
        password: password
      })

      onSubmit({
        employee_code: employeecode,
        password: password
      });
    }
    // setEnteremployeecode(employeecode);
    // setEnterpassword(password);
    // console.log("in load login details",employeecode)
  };

  React.useEffect(() => {

    loadlogindetails();

    //alert("in use effect for login")
    window.receiveFCMToken = (token) => {
      // alert('FCM Token received:')
      // alert(token)
      setFcmToken(token);
      console.log("FCM Token received:", token);
      // Store the token or send it to your server as needed
    };

    window.receiveNotification = (notification) => {
      // alert('Notification received:')
      // alert(JSON.stringify(notification))
      console.log("Notification received:", notification);
      addNotification(notification);
      // Handle the notification data
    };

  }, [addNotification]);

  return (
    <>
      <div className="mobileCssMain">
        <div className="container">
          <div className="wrapper">
            <div className="loginMain">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} data-aos="zoom-in">
                  <h1 className="resrPas">Login to your company account</h1>
                </Grid>
                <Grid item xs={12} md={12} data-aos="zoom-in">
                  <p>
                    Please sign in to your account which is provided by your
                    company
                  </p>
                </Grid>
                {loginErrMsg ? (
                  <div>
                    <span className="logError">{loginErrMsg}</span>
                  </div>
                ) : (
                  <></>
                )}
                <Grid item xs={12} md={12}>
                  <div className="loginCont">
                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {(formik) => (
                        <Form>
                          <Grid container spacing={3} data-aos="zoom-in">
                            <Grid item xs={12} md={12}>
                              <FormikControl
                                control="input"
                                type="text"
                                label="Employee Code"
                                labelClass="loginLable"
                                name="employee_code"
                                className="loginInput"
                                placeholder="Employee Code"
                              />
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <div className="forPassEye">
                                <FormikControl
                                  control="input"
                                  type={showPassword ? "text" : "password"}
                                  label="Password"
                                  labelClass="loginLable"
                                  name="password"
                                  className="loginInput"
                                  placeholder="Password"
                                />
                                {showPassword == true ? (
                                  <RemoveRedEyeIcon
                                    onClick={() => setShowPassword(false)}
                                  />
                                ) : (
                                  <VisibilityOffIcon
                                    onClick={() => setShowPassword(true)}
                                  />
                                )}
                              </div>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <Button
                                className="loginBtnOuter"
                                onClick={toggleDrawer(true)}
                              >
                                <h3 className="forgotTex">Forgot Password?</h3>
                              </Button>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={12}
                              style={{ textAlign: "centers" }}
                            >
                              <Button className="loginBt" type="submit">
                                Sign In
                              </Button>
                            </Grid>

                          </Grid>
                        </Form>
                      )}
                    </Formik>
                    <Root>
                      <CssBaseline />
                      <Global
                        styles={{
                          ".MuiDrawer-root > .MuiPaper-root": {
                            height: `calc(400px - ${drawerBleeding}px)`,
                            overflow: "visible",
                          },
                        }}
                      />
                      <SwipeableDrawer
                        container={container}
                        anchor="bottom"
                        open={open}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                        swipeAreaWidth={drawerBleeding}
                        disableSwipeToOpen={false}
                        ModalProps={{
                          keepMounted: true,
                        }}
                      >
                        <StyledBox
                          sx={{
                            position: "absolute",
                            top: -drawerBleeding,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            visibility: "visible",
                            right: 0,
                            left: 0,
                          }}
                        >
                          <Puller />
                        </StyledBox>
                        <StyledBox
                          sx={{
                            height: "100%",
                            overflow: "auto",
                          }}
                        >
                          <div className="mobileCssMain">
                            <div className="forgotOter">
                              <div className="wrapper">
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={12}>
                                    <h1>Forgot password?</h1>
                                  </Grid>
                                  {/* <Grid item xs={12} md={12}>
                                    <p>Please Contact to Administrator.</p>
                                  </Grid> */}
                                  <Grid item xs={12} md={12}>
                                    <Formik
                                      initialValues={initialValuesForgotPass}
                                      validationSchema={
                                        validationSchemaForgotPass
                                      }
                                      enableReinitialize
                                      onSubmit={onSubmitForgotPass}
                                    >
                                      {(formik) => (
                                        <Form>
                                          <div className="forotInput forgotError">
                                            <div className="forGmail">
                                              <DraftsIcon />
                                            </div>
                                            <FormikControl
                                              control="input"
                                              type="email"
                                              label="Send Via Email"
                                              labelClass="loginLable"
                                              name="email_id"
                                              placeholder="Email address"
                                            />
                                          </div>
                                          <div className="errorSt">
                                            {emailErrMsg}
                                          </div>

                                          <Button
                                            className="loginBt"
                                            type="submit"
                                          >
                                            Continue
                                          </Button>
                                        </Form>
                                      )}
                                    </Formik>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </div>
                        </StyledBox>
                      </SwipeableDrawer>
                    </Root>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginMobile;
