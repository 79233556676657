import React from "react";
import { Button, Grid } from "@mui/material";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import all from "../../assets/images/all.svg";
import veggies from "../../assets/images/veggies.svg";
import nonveg from "../../assets/images/nonveg.svg";
import canned from "../../assets/images/canned.svg";
import household from "../../assets/images/household.svg";
import snacks from "../../assets/images/snacks.svg";
import StarIcon from "@mui/icons-material/Star";
import milkshake from "../../assets/images/milkshake.svg";
import berry from "../../assets/images/berry.svg";
import orangejuice from "../../assets/images/orangejuice.svg";
import pepsi from "../../assets/images/pepsi.svg";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarLogoSmall from "../../assets/images/StarLogoSmall.svg";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import IconButton from '@mui/material/IconButton';
function Category() {
  const navigate = useNavigate();

  return (
    <>
      <div className="mobileCssMain">
        <div>
          <div>
            <div className="container">
              <div className="headerData">
                <div className="screenTitle">
                  <Button onClick={() => navigate("/DashboardPage")}>
                  <IconButton className="TouchableIconBtn"> <ArrowBackIosIcon />
                  </IconButton>
                  </Button>
                  <h2>Star Market</h2>
                </div>
                {/* <Button onClick={goBack} className="loginBt"> 
                  <i className="fa fa-arrow-left"></i>
              </Button> */}
              </div>
            </div>
            {/* <div className="topheaderIcons black">
            <div className="searchIcon">
              <SearchOutlinedIcon />
            </div>
            <div className="notiIco">
              <NotificationsOutlinedIcon />
            </div>
            <div>
              <ShoppingCartOutlinedIcon />
            </div>
          </div> */}
          </div>
          <div className="availStarMain topHeaderTypetwo">
            <div className="container">
              <div className="availStar ">
                <h4>Mr John Doe</h4>
                <h1>
                  Available Stars{" "}
                  <b>
                    <StarOutlinedIcon /> 1250
                  </b>
                </h1>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="wrapper">
              <div className="screenTitleOuter newSearchTopSpace"></div>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div
                    onClick={() => navigate("/StarMarketHome")}
                    className="categoryMain"
                  >
                    <div>
                      <img src={all} />
                    </div>
                    <b>All</b>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="categoryMain">
                    <div>
                      <img src={nonveg} />
                    </div>
                    <b>Non-Veg</b>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="categoryMain">
                    <div>
                      <img src={snacks} />
                    </div>
                    <b>Snacks</b>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="categoryMain">
                    <div>
                      <img src={canned} />
                    </div>
                    <b>Canned</b>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="categoryMain">
                    <div>
                      <img src={veggies} />
                    </div>
                    <b>Veggies</b>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="categoryMain">
                    <div>
                      <img src={household} />
                    </div>
                    <b>Household</b>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Category;
