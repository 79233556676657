import React, { useEffect, useState } from "react";
import { json, useNavigate } from "react-router-dom";
import QRCodeComponent from "../componentsMobile/QRCodeComponent";
import StarIcon from "@mui/icons-material/Star";
import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarLogoSmall from "../assets/images/StarLogoSmall.svg";
import { makeGetRequest } from "../../utils/utils";
import { useLocation } from "react-router-dom";
import moment from "moment";
import IconButton from '@mui/material/IconButton';

function ActiveOrderDetailsMarket(props) {

  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [productList, setProductList] = useState([]);
  const [qrdata, setQrdata] = useState([]);
  const [orderid, setOrderId] = useState("");
  const [timing, setTiming] = useState([]);

  const location = useLocation();
  const { id } = location.state || {};
  console.log(id)
  async function getOrder() {

    let arr = [];
    await makeGetRequest("starmarket/getorder/" + id)
      .then((response) => {
        if (response.data.data) {
          console.log(response.data.data)
          setItems(response.data.data);
          console.log(response.data.data[0])
          setQrdata(response.data.data[0].order_id);
          if (response.data.data[0].orderdetails.length > 0) {
            for (
              let i = 0;
              i < response.data.data[0].orderdetails.length;
              i++
            ) {
              arr.push(JSON.parse(response.data.data[0].orderdetails[i]));
            }
            setProductList(arr);
          } else {
            setProductList([]);
          }
        } else {
          setItems([]);
          setProductList([]);
        }
      })
      .catch((err) => {
        setItems([]);
        setProductList([]);
      });
  }

  useEffect(() => {
    getOrder();
    setTiming(JSON.parse(localStorage.getItem("cafetiming")));
  }, []);

  return (
    <>
      <div className="mobileCssMain">
        <div className="container">
          <div className="wrapper">
            <div className="screenTitle">
              <Button onClick={() => navigate(-1)}>
                <IconButton className="TouchableIconBtn"> <ArrowBackIosIcon />
                </IconButton>
              </Button>
              <h2>Active Order Details</h2>
            </div>
            <div className="fixedBottomPadding">
              {items.map((item, index) => (
                <div className="ActiveOrderDetailsData">
                  <div className="orderDetailsBox">
                    <div className="orderSummary orderSummaryOrderDetails">
                      <h3 className="newUserNm">
                        <b>{item.first_name + " " + item.last_name}</b>
                      </h3>
                      <div className="orderSummaryDetailsTop">
                        <div className="OrderIDData">
                          <h4>Order #{item.order_id}</h4>
                          <p>
                            {moment(item.created_at).format("YYYY-MM-DD HH:mm")}
                          </p>
                        </div>
                        <div className="pickUp pickUpOrder">
                          <p>Pickup Location</p>
                          <h4>Star Cafe</h4>
                        </div>
                      </div>
                      <h4>Order Details</h4>
                      <div className="summaryData">
                        <div className="summaryItem">
                          <h6>Order ID</h6>
                          <h3>#{item.order_id}</h3>
                        </div>
                        <div className="summaryItem">
                          <h6>Total Item</h6>
                          <h3>{item.orderdetails.length}</h3>
                        </div>
                        <div className="summaryItem">
                          <h6>Stars Spent</h6>
                          <h3 className="blue">{item.totalstarspend}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="orderTiming orderDetailsPickupSlots">
                    <h4>Pickup Slot</h4>
                    {timing ? (
                      <>
                        {timing.map((cafetiming, index) => (
                          <>
                            {cafetiming.service_type == item.ordertype &&
                              cafetiming.type === "Pickup" ? (
                              <h4>
                                {moment(cafetiming.starttime, "HH:mm").format(
                                  "hh:mm A"
                                ) +
                                  " to " +
                                  moment(cafetiming.endtime, "HH:mm").format(
                                    "hh:mm A"
                                  )}
                              </h4>
                            ) : null}
                          </>
                        ))}
                      </>
                    ) : (
                      <h4> For Timing Please Contact Admin</h4>
                    )}
                  </div>
                </div>
              ))}

              <div className="OrderDetailsQRCode">
                <h3>Scan At Pickup Location</h3>
                {qrdata ? <QRCodeComponent data={JSON.stringify({orderId:qrdata,serviceType:'Starmarket'})} /> : null}
              </div>

              {productList.length > 0 && (
                <div>
                  {productList.map((item, index) => (
                    <div className="orderNotiDiv specialMenu">
                      <div>
                        <div className="orderDetailsProd">
                          {item.menu_name !== undefined ? (
                            <h3>{item.menu_name}</h3>
                          ) : (
                            <h3>{item.product_name}</h3>
                          )}
                          <p>
                            {item.weight}
                            {item.weight_unit}
                          </p>
                        </div>
                        {item.menu_id !== undefined ? (
                          <p>Product Id #{item.menu_id}</p>
                        ) : (
                          <p>Product Id #{item.subcategory_id}</p>
                        )}

                        <div className="starPrice">
                          <StarIcon className="starIcon" />
                          {item.price_in_stars !== undefined ? (
                            <h5> {item.price_in_stars} </h5>
                          ) : (
                            <h5> {item.Star_price} </h5>
                          )}
                        </div>
                      </div>
                      <div className="prodQuantity">
                        <p>
                          <p className="qty">{item.count}</p>Quantity
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div>
                <div className="fixedBtnOuter">
                  <Button
                    className="loginBt"
                    onClick={() => navigate("/ActiveOrders")}
                  >
                    Back To Active Orders
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ActiveOrderDetailsMarket;
