import React, { createContext, useContext, useState, useEffect } from 'react';
import { canAddItemToCart } from '../../utils/canAddItemToCart';
import {
    ORDER_TYPE_BREAKFAST,
    ORDER_TYPE_LUNCH,
    ORDER_TYPE_DINNER,
    ORDER_TYPE_CAFE,
    ORDER_TYPE_MARKET
} from '../../constants/orderTypes';

// Create a context for the cart
const CartContext = createContext();

// Cart provider component
export const CartProvider = ({ children }) => {

    const [cart, setCart] = useState([]);
    const [marketcart, setMarketCart] = useState([]);


    const addToCartBreakFast = (item, isFirstOrder) => {

        setCart((prevCart) => {

            const existingItem = prevCart.find((cartItem) => cartItem.menu_id === item.menu_id);
            if (existingItem) {
                return prevCart.map((cartItem) => {
                    if (cartItem.menu_id === item.menu_id) {
                        if (item.iscomplementory) {
                            cartItem.count += 1;
                        } else if (item.isfree) {
                            cartItem.count += 1;
                        } else {
                            cartItem.count += 1;
                        }
                        return {
                            ...cartItem,
                            count: cartItem.count,
                        };
                    }
                    return cartItem
                });
            }

            // If item doesn't exist, add it to the cart
            let newItem = {
                ...item,
                count: 1,
            };

            if (item.iscomplementory) {
                newItem.count = newItem.complementorycount;
                //newItem.complementorycount = 1;
            } else if (item.isfree) {
                newItem.count = newItem.freecount;
                //newItem.freecount = 1;
            } else {
                newItem.count = 1;
            }

            // if (!isFirstOrder) {

            //     if (item.isfree) {
            //         newItem.isfree = false; //Override isfree flag for subsequent orders
            //         newItem.price_in_stars = item.price_in_stars; // assuming you have original price saved
            //     }
            //     if (item.iscomplementory) {
            //         newItem.iscomplementory = false;
            //         newItem.price_in_stars = item.price_in_stars;
            //     }
            // }
            return [...prevCart, { ...newItem, quantity: 1 }];
        });
    };

    const addToCart = (item, isFirstOrder) => {

        setCart((prevCart) => {

            const existingItem = prevCart.find((cartItem) => cartItem.menu_id === item.menu_id);
            if (existingItem) {
                return prevCart.map((cartItem) => {
                    if (cartItem.menu_id === item.menu_id) {
                        if (item.iscomplementory) {
                            cartItem.count += 1;
                        } else if (item.isfree) {
                            cartItem.count += 1;
                        } else {
                            cartItem.count += 1;
                        }
                        return {
                            ...cartItem,
                            count: cartItem.count,
                        };
                    }
                    return cartItem
                });
            }

            // If item doesn't exist, add it to the cart
            let newItem = {
                ...item,
                count: 1,
            };

            if (item.iscomplementory) {
                newItem.count = newItem.complementorycount;
                //newItem.complementorycount = 1;
            } else if (item.isfree) {
                newItem.count = newItem.freecount;
                //newItem.freecount = 1;
            } else {
                newItem.count = 1;
            }

            if (isFirstOrder) {
                newItem.isfree = true;
                newItem.freecount = 1;
                //newItem.price_in_stars = 0; // Make the first order free
                newItem.wasFirstOrderFree = true; // Mark the item as first order free
            } else {
                // Subsequent orders, even if isfree, should be paid
                if (item.isfree) {
                    newItem.isfree = false; // Override isfree flag for subsequent orders
                    newItem.price_in_stars = item.price_in_stars; // assuming you have original price saved
                }
            }
            return [...prevCart, { ...newItem, quantity: 1 }];
        });
    };

    const addCategoryProduct = (item) => {

        console.log("add to cart");
        setCart((prevCategoryCart) => {

            const existingItem = prevCategoryCart.find((cartItem) => cartItem.subcategory_id === item.subcategory_id);
            if (existingItem) {
                return prevCategoryCart.map((cartItem) =>
                    cartItem.subcategory_id === item.subcategory_id
                        ? { ...cartItem, count: cartItem.count + 1 }
                        : cartItem
                );
            }
            // If item doesn't exist, add it to the cart
            return [...prevCategoryCart, { ...item, quantity: 1 }];
        });
    };

    const addMarketProduct = (item) => {

        console.log("add to cart");
        setMarketCart((prevCategoryCart) => {
            const existingItem = prevCategoryCart.find((cartItem) => cartItem.product_id === item.product_id);
            if (existingItem) {
                return prevCategoryCart.map((cartItem) =>
                    cartItem.product_id === item.product_id
                        ? { ...cartItem, count: cartItem.count + 1 }
                        : cartItem
                );
            }
            // If item doesn't exist, add it to the cart
            return [...prevCategoryCart, { ...item, quantity: 1 }];
        });
    };

    const reduceCount = (item) => {

        setCart((prevCart) => {

            console.log("Reduce count ")
            const existingItem = prevCart.find((cartItem) => cartItem.menu_id === item.menu_id);
            if (existingItem) {
                // If item already exists, decrement the quantity
                return prevCart.map((cartItem) =>
                    cartItem.menu_id === item.menu_id
                        ? {
                            ...cartItem, count: Math.max(
                                item.count - 1,
                                Math.max(0, 0))
                        }
                        : cartItem
                );

                // const newCart = prevCart.map((cartItem) =>
                //     cartItem.menu_id === item.menu_id
                //         ? {
                //             ...cartItem, count: Math.max(cartItem.count - 1, 0)
                //         }
                //         : cartItem
                // );

                // const updatedItem = newCart.find(cartItem => cartItem.menu_id === item.menu_id);
                // if (updatedItem && updatedItem.count === 0) {
                //     console.log("when count is zero" + updatedItem.menu_id)
                //     removeFromCart(updatedItem.menu_id);
                // }
                // return newCart;
            }

            return [...prevCart, { ...item, quantity: 1 }];
        });
    };

    const reduceCountBreakfast = (item) => {

        setCart((prevCart) => {

            const existingItem = prevCart.find((cartItem) => cartItem.menu_id === item.menu_id);
            if (existingItem) {
                // If item already exists, increment the quantity
                return prevCart.map((cartItem) =>
                    cartItem.menu_id === item.menu_id
                        ? {
                            ...cartItem, count: Math.max(
                                item.count - 1,
                                Math.max(item.freecount || 1, item.complementorycount || 1))
                        }
                        : cartItem
                );
            }

            return [...prevCart, { ...item, quantity: 1 }];
        });
    };

    const reduceCategoryCount = (item) => {

        setCart((prevCart) => {

            const existingItem = prevCart.find((cartItem) => cartItem.subcategory_id === item.subcategory_id);
            if (existingItem) {
                console.log('in if')
                return prevCart.map((cartItem) =>
                    cartItem.subcategory_id === item.subcategory_id
                        ? { ...cartItem, count: Math.max(cartItem.count - 1, 0) }
                        : cartItem
                );
            }
            // If item doesn't exist, add it to the cart
            return [...prevCart, { ...item, quantity: 1 }];
        });
    };

    const reduceMarketCategoryCount = (item) => {
        setMarketCart((prevCart) => {
            const existingItem = prevCart.find((cartItem) => cartItem.product_id === item.product_id);
            if (existingItem) {
                console.log('in if')
                return prevCart.map((cartItem) =>
                    cartItem.product_id === item.product_id
                        ? { ...cartItem, count: Math.max(cartItem.count - 1, 1) }
                        : cartItem
                );
            }
            // If item doesn't exist, add it to the cart
            return [...prevCart, { ...item, quantity: 1 }];
        });
    };

    const reduceItemCount = (item, idType) => {
        setCart((prevCart) => {
            const existingItem = prevCart.find((cartItem) => cartItem[idType] === item[idType]);
            if (existingItem) {
                // If item already exists, decrement the quantity
                return prevCart.map((cartItem) =>
                    cartItem[idType] === item[idType]
                        ? { ...cartItem, count: cartItem.count - 1 }
                        : cartItem
                );
            }
            // If item doesn't exist, add it to the cart with quantity 1
            return [...prevCart, { ...item, quantity: 1 }];
        });
    };

    const removeFromCart = (id) => {
        setCart((prevCart) =>
            prevCart.filter((cartItem) => cartItem.menu_id !== id && cartItem.subcategory_id !== id)
        );
    };

    
    const removeMarketItemFromCart = (id) => {
        setMarketCart((prevCart) =>
            prevCart.filter((cartItem) => cartItem.product_id !== id && cartItem.product_id !== id)
        );
    };

    const clearCart = () => {
        setCart([]); // Clear the cart by setting it to an empty array
    };

    const clearMarketCart = () => {
        setMarketCart([]); // Clear the cart by setting it to an empty array
    };

    // Load cart from localStorage when the component mounts
    useEffect(() => {
        const loadCart = () => {
            const savedCart = localStorage.getItem('cart');
            if (savedCart !== null) {
                setCart(JSON.parse(savedCart));
            }
        };
        loadCart();
        console.log('Load cart from localStorage')
    }, []);

    // Save cart to localStorage whenever it changes
    useEffect(() => {
        const saveCart = () => {
            localStorage.setItem('cart', JSON.stringify(cart));
        };
        saveCart();
        // console.log('Save cart to localStorage')
        // console.log("Cart useeffect")
        // console.log(cart)
    }, [cart]);

    return (
        <CartContext.Provider value={{ cart, marketcart, reduceItemCount, reduceCategoryCount, addToCart, reduceCount, reduceCountBreakfast, removeFromCart, clearCart, addCategoryProduct, addToCartBreakFast, addMarketProduct, reduceMarketCategoryCount, removeMarketItemFromCart, clearMarketCart }}>
            {children}
        </CartContext.Provider>
    );
};

// Custom hook to use the cart context
export const useCart = () => {
    return useContext(CartContext);
};
