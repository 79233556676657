import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import avtarPic from "../../src/assets/images/avtarPic.png";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { useNavigate } from "react-router-dom";
import exchaneBlack from "../assets/images/exchaneBlack.png";
import { makePostRequest, makeGetRequest } from "../utils/utils.js";
import { generateToken, messaging } from "../firebase.js";
import { onMessage } from "firebase/messaging";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function Top() {
  const navigate = useNavigate();

  const [anchorElNoti, setAnchorElNoti] = React.useState(null);
  const openNoti = Boolean(anchorElNoti);
  
  const handleClickNoti = (event, notificationcnt) => {
    if (notificationcnt > 0) {
      setAnchorElNoti(event.currentTarget);
    }
  };
  const handleCloseNoti = () => {
    setAnchorElNoti(null);
  };

  const menuSlide = () => {
    var element = document.getElementById("myLeft");
    element.classList.toggle("smoothSlide");
  };

  // const leftPnlClose = () => {
  //   var element = document.getElementById("myLeft");
  //   element.classList.remove("smoothSlide");
  // };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [lstJsonNotiObj, setLstJsonNotiObj] = React.useState([]);
  const [notificationcnt, setNotificationCnt] = React.useState(0);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  function logOutFunction() {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  }

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <span onClick={() => navigate("/ProfileDetails")}> Profile</span>
      </MenuItem>
      <MenuItem onClick={() => logOutFunction()}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const [masterDollarAmount, setMasterDollarAmount] = React.useState(0);

  function getMasterDollarAmount() {
    makeGetRequest("employee/getmasterstarcount/")
      .then((response) => {
        if (response.data) {
          console.log(response);
          if (response.data.data.length > 0) {
            setMasterDollarAmount(response.data.data[0].dollar_amount);

          localStorage.setItem("masterdollarvalue",response.data.data[0].dollar_amount);
  
          } 
        }
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
      });
  }

  //generateToken();
  onMessage(messaging, (payload) => {
    console.log("Payload===>" + JSON.stringify(payload));
    let notification = {};
    notification.title = payload.notification.title;
    notification.body = payload.notification.body;

    lstJsonNotiObj.push(notification);
    setLstJsonNotiObj(lstJsonNotiObj);
    Object.defineProperty(lstJsonNotiObj, "title", { enumerable: false });
    var count = Object.keys(lstJsonNotiObj).length;
    setNotificationCnt(count);
  });

  React.useEffect(() => {
    if(localStorage.getItem('userType')!='SuperAdmin')
        getMasterDollarAmount();
  }, []);

  return (
    <div className="topMain">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 1 }}
              className="togBtTop"
              onClick={menuSlide}
            >
              <MenuIcon />
            </IconButton>
            <p className="topTimeLine">
              <marquee>Today, 10/01/24, 12:07 PM</marquee>
            </p>
            {/* <Search className="topSearch">
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search by services, employee name, product"
                inputProps={{ "aria-label": "search" }}
              />
            </Search> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{ display: { xs: "none", md: "flex" } }}
              style={{ alignItems: "center" }}
            >
              {/* <p className="topTimeLine">Today, 10/01/24, 12:07 PM</p> */}
              {localStorage.getItem("userType") != "SuperAdmin" ? (
                <div className="topExchangeRate">
                  <div class="popUpExchImgOuter">
                    <div class="setExchImgDiv">
                      <img src={exchaneBlack} />
                    </div>
                    {masterDollarAmount ? (
                      <h2>
                        Exchange Star Rate{" "}
                        <b style={{ color: "#c82d33", fontSize: 17 }}>
                        Star 1= ₹ {masterDollarAmount} 
                        </b>
                      </h2>
                    ) : (
                      <h2>No Exchange rate set </h2>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={notificationcnt} color="error">
                  <NotificationsNoneOutlinedIcon
                    id="basic-button"
                    aria-controls={openNoti ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openNoti ? "true" : undefined}
                    onClick={(event) => handleClickNoti(event, notificationcnt)}
                  />
                </Badge>
              </IconButton>
              <div className="notiContDivMain">
                <Menu
                  className="newNotificaionS"
                  id="basic-menu"
                  anchorEl={anchorElNoti}
                  open={openNoti}
                  onClose={handleCloseNoti}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    style: {
                      width: 500,
                    },
                  }}
                >
                  {lstJsonNotiObj.map((option) => (
                    <MenuItem onClick={handleCloseNoti} className="notLineT">
                      <b>{option.title}</b>
                      <p>{option.body}</p>
                    </MenuItem>
                  ))}
                </Menu>
              </div>

              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  {localStorage.getItem('adminProfilePic')?
                  <Avatar alt="Remy Sharp" src={localStorage.getItem('adminProfilePic')} />:
                  <Avatar alt="Remy Sharp" src={avtarPic} />}
                </StyledBadge>
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
    </div>
  );
}

export default Top;
