import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Button, Grid, Box } from "@mui/material";
import mealImg1 from "../../../assets/images/mealImg1.svg";
import mealImg2 from "../../../assets/images/mealImg2.svg";
import mealImg3 from "../../../assets/images/mealImg3.svg";
import mealMainimg1 from "../../../assets/images/mealMainimg1.svg";
import mealMainimg2 from "../../../assets/images/mealMainimg2.svg";
import editIco from "../../../assets/images/edit-icon.svg";
import StarIcon from "@mui/icons-material/Star";
import deletIco from "../../../assets/images/delete-icon.svg";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import UploadIcon from "@mui/icons-material/Upload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import mealAcordingIcon from "../../../assets/images/mealAcordingIcon.svg";
import DataTable from "react-data-table-component";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import RemoveIcon from "@mui/icons-material/Remove";
import Pagination from "@mui/material/Pagination";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import productImg from "../../../assets/images/productImg.svg";
import mealAcordingIcon2 from "../../../assets/images/mealAcordingIcon2.svg";
import mealAcordingIcon3 from "../../../assets/images/mealAcordingIcon3.svg";
import mealAcordingIcon4 from "../../../assets/images/mealAcordingIcon4.svg";
import mealMainimg3 from "../../../assets/images/mealMainimg3.svg";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
// End

// For According
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//

import Checkbox from "@mui/material/Checkbox";
import orderIc from "../../../assets/images/orderIc.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ord1 from "../../../assets/images/ord1.svg";
import ord2 from "../../../assets/images/ord2.svg";
import ord3 from "../../../assets/images/ord3.svg";
import ord4 from "../../../assets/images/ord4.svg";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import ImportExportIcon from "@mui/icons-material/ImportExport";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import GroceryTab from "./GroceryTab";
import VeggiesTab from "./VeggiesTab";
import KitchenSuppliesTab from "./KitchenSuppliesTab";
import CafeInventoryTab from "./CafeInventoryTab";
import CreateMealMenu from "./CreateMealMenu";
import OrderSummaryTab from "./OrderSummaryTab";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Tabs For Weekly Setup
function CustomTabPanelWeekly(props) {
  const { children, Weekly, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={Weekly !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {Weekly === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanelWeekly.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  Weekly: PropTypes.number.isRequired,
};

function a11yPropsWeekly(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// End

function StarCafeTabs() {
  const navigate = useNavigate();

  const [selected, setSelected] = React.useState(false);

  const initialValues = {
    menu_name: "",
    price_name: "",
    price_in_stars: "",

    // Add Grocery (Carton)
    grocery_item_name: "",
    low_stock_limit: "",
    brand_name: "",
    total_number_of_cartons: "",
    packs_per_carton: "",
    carton_weight: "",
    weight_per_unit: "",
    price_per_carton: "",
    price_per_unit: "",
    expiry_date: "",
    origin_name: "",
    date_select: "",

    // Add Grocery (Single Unit)
    brand_name_unit: "",
    units_bought_unit: "",
    weight_per_single_unit: "",
    price_per_single_unit: "",
    expiry_date_sinle_unit: "",
    origin_name_single_unit: "",
    date_single_unit: "",

    // Add Vegetable
    vegetable_name: "",
    price_name_vegitable: "",
    weight_quantity: "",
    price_vegitable: "",
    low_stock_limit_vegi: "",
    weight_unit_vegi: "",

    // Add Kitchen Supplies
    item_name: "",
    product_categories: "",
    brand_name_kich: "",
    total_unit_bought_kich: "",
    weight_per_unit_kich: "",
    price_per_unit_kich: "",
    origin_kich: "",
    stock_limit_kich: "",
  };

  const validationSchema = Yup.object({
    menu_name: Yup.string().required("Required"),
    price_name: Yup.string().required("Required"),
    price_in_stars: Yup.string().required("Required"),

    // Add Grocery (Carton)
    grocery_item_name: Yup.string().required("Required"),
    low_stock_limit: Yup.string().required("Required"),
    brand_name: Yup.string().required("Required"),
    total_number_of_cartons: Yup.string().required("Required"),
    packs_per_carton: Yup.string().required("Required"),
    carton_weight: Yup.string().required("Required"),
    weight_per_unit: Yup.string().required("Required"),
    price_per_carton: Yup.string().required("Required"),
    price_per_unit: Yup.string().required("Required"),
    expiry_date: Yup.string().required("Required"),
    origin_name: Yup.string().required("Required"),
    date_select: Yup.string().required("Required"),

    // Add Grocery (Single Unit)
    brand_name_unit: Yup.string().required("Required"),
    units_bought_unit: Yup.string().required("Required"),
    weight_per_single_unit: Yup.string().required("Required"),
    price_per_single_unit: Yup.string().required("Required"),
    expiry_date_sinle_unit: Yup.string().required("Required"),
    origin_name_single_unit: Yup.string().required("Required"),
    date_single_unit: Yup.string().required("Required"),

    // Add Vegetable
    vegetable_name: Yup.string().required("Required"),
    price_name_vegitable: Yup.string().required("Required"),
    weight_quantity: Yup.string().required("Required"),
    price_vegitable: Yup.string().required("Required"),
    low_stock_limit_vegi: Yup.string().required("Required"),
    weight_unit_vegi: Yup.string().required("Required"),

    // Add Kitchen Supplies
    item_name: Yup.string().required("Required"),
    product_categories: Yup.string().required("Required"),
    brand_name_kich: Yup.string().required("Required"),
    total_unit_bought_kich: Yup.string().required("Required"),
    weight_per_unit_kich: Yup.string().required("Required"),
    price_per_unit_kich: Yup.string().required("Required"),
    origin_kich: Yup.string().required("Required"),
    stock_limit_kich: Yup.string().required("Required"),
  });

  // For DropDown
  const dropdownOptions = [
    { key: "Select an option", value: "" },
    { key: "Option 1", value: "Option 1" },
    { key: "Option 2", value: "Option 2" },
    { key: "Option 3", value: "Option 3" },
  ];

  const dropdownOptionsVegi = [
    { key: "Select an option", value: "" },
    { key: "Option 1", value: "Option 1" },
    { key: "Option 2", value: "Option 2" },
    { key: "Option 3", value: "Option 3" },
  ];

  const prodctOptions = [
    { key: "Select an option", value: "" },
    { key: "Option 1", value: "Option 1" },
    { key: "Option 2", value: "Option 2" },
    { key: "Option 3", value: "Option 3" },
  ];
  // End

  const labelCheck = { inputProps: { "aria-label": "Checkbox demo" } };

  // Modal Data Table
  const columns = [
    {
      name: "Item Names",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Monday",
      selector: (row) => row.Monday,
      sortable: true,
    },
    {
      name: "Tuesday",
      selector: (row) => row.Tuesday,
      sortable: true,
    },
    {
      name: "Wednesday",
      selector: (row) => row.Wednesday,
      sortable: true,
    },
    {
      name: "Thursday",
      selector: (row) => row.Thursday,
      sortable: true,
    },
    {
      name: "Friday",
      selector: (row) => row.Friday,
      sortable: true,
    },
    {
      name: "Saturday",
      selector: (row) => row.Saturday,
      sortable: true,
    },
    {
      name: "Sunday",
      selector: (row) => row.Sunday,
      sortable: true,
    },
  ];

  const data = [
    {
      id: 1,
      title: "Roti",
      Monday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Tuesday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Wednesday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Thursday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Friday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Saturday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Sunday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
    },
    {
      id: 2,
      title: "Tea",
      Monday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Tuesday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Wednesday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Thursday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Friday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Saturday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Sunday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
    },
    {
      id: 3,
      title: "Potato Bhaji",
      Monday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Tuesday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Wednesday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Thursday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Friday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Saturday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Sunday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
    },
    {
      id: 4,
      title: "Paneer Paratha",
      Monday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Tuesday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Wednesday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Thursday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Friday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Saturday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
      Sunday: (
        <>
          <Checkbox {...labelCheck} />
        </>
      ),
    },
  ];
  // End

  const label = { inputProps: { "aria-label": "Switch demo" } };

  // Modal 1
  const [addMenu, setOpenAddMenu] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpenAddMenu = (scrollType) => () => {
    setOpenAddMenu(true);
    setScroll(scrollType);
  };

  const handleCloseAddMenu = () => {
    setOpenAddMenu(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (addMenu) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [addMenu]);
  // End

  // Modal 2
  const [editMenu, setOpenEditMenu] = React.useState(false);

  const handleClickOpenEditMenu = (scrollType) => () => {
    setOpenEditMenu(true);
    setScroll(scrollType);
  };

  const handleCloseEditMenu = () => {
    setOpenEditMenu(false);
  };

  React.useEffect(() => {
    if (editMenu) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [editMenu]);
  // End

  // Modal 2
  const [special, setOpenSpecial] = React.useState(false);

  const handleClickOpenSpecial = (scrollType) => () => {
    setOpenSpecial(true);
    setScroll(scrollType);
  };

  const handleCloseSpecial = () => {
    setOpenSpecial(false);
  };

  React.useEffect(() => {
    if (special) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [special]);
  // End

  // Modal
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  // End

  // Modal
  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = (scrollType) => () => {
    setOpen2(true);
    setScroll(scrollType);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  React.useEffect(() => {
    if (open2) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open2]);
  // End

  // Modal Main
  const [open4, setOpen4] = React.useState(false);

  const handleClickOpen4 = (scrollType) => () => {
    setOpen4(true);
    setScroll(scrollType);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  React.useEffect(() => {
    if (open4) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open4]);
  // End

  // Modal Main
  const [AddInventory, setOpenAddInventory] = React.useState(false);

  const handleClickOpenAddInventory = (scrollType) => () => {
    setOpenAddInventory(true);
    setScroll(scrollType);
  };

  const closeInventory = () => {
    setOpenAddInventory(false);
  };

  React.useEffect(() => {
    if (AddInventory) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [AddInventory]);
  // End

  // Deduct Grocery
  const [DeductProdct, setOpenDeductProdct] = React.useState(false);

  const handleClickOpenDeductProdct = (scrollType) => () => {
    setOpenDeductProdct(true);
    setScroll(scrollType);
  };

  const closeDeductProdct = () => {
    setOpenDeductProdct(false);
  };

  React.useEffect(() => {
    if (DeductProdct) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [DeductProdct]);
  // End

  //Add Kitchen Supplies
  const [AddKitchen, setOpenAddKitchen] = React.useState(false);

  const handleClickOpenAddKitchen = (scrollType) => () => {
    setOpenAddKitchen(true);
    setScroll(scrollType);
  };

  const closeAddKitchen = () => {
    setOpenAddKitchen(false);
  };

  React.useEffect(() => {
    if (AddKitchen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [AddKitchen]);
  // End

  // Deduct Grocery
  const [DeductInventoryKitchen, setOpenDeductInventoryKitchen] =
    React.useState(false);

  const handleClickOpenDeductInventoryKitchen = (scrollType) => () => {
    setOpenDeductInventoryKitchen(true);
    setScroll(scrollType);
  };

  const closeDeductInventoryKitchen = () => {
    setOpenDeductInventoryKitchen(false);
  };

  React.useEffect(() => {
    if (DeductInventoryKitchen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [DeductInventoryKitchen]);
  // End

  // Modal Add Prod In Cafe Inventory
  const [AdProdCafeInventory, setOpenAdProdCafeInventory] =
    React.useState(false);

  const handleClickOpenAdProdCafeInventory = (scrollType) => () => {
    setOpenAdProdCafeInventory(true);
    setScroll(scrollType);
  };

  const CloseAdProdCafeInventory = () => {
    setOpenAdProdCafeInventory(false);
  };

  React.useEffect(() => {
    if (AdProdCafeInventory) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [AdProdCafeInventory]);
  // End

  // Modal
  const [WeeklySetup, setOpenWeeklySetup] = React.useState(false);

  const handleClickOpenWeeklySetup = (scrollType) => () => {
    setOpenWeeklySetup(true);
    setScroll(scrollType);
  };

  const handleCloseWeeklySetup = () => {
    setOpenWeeklySetup(false);
  };

  React.useEffect(() => {
    if (WeeklySetup) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [WeeklySetup]);
  // End

  // Edit Category
  const [openEditCategory, setOpenEditCategory] = React.useState(false);

  const handleClickOpenEditCategory = (scrollType) => () => {
    setOpenEditCategory(true);
    setScroll(scrollType);
  };

  const handleCloseEditCategory = () => {
    setOpenEditCategory(false);
  };

  React.useEffect(() => {
    if (openEditCategory) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openEditCategory]);
  // End

  // Modal
  const [OrderSummary, setOpenOrderSummary] = React.useState(false);

  const handleClickOrderSummary = (scrollType) => () => {
    setOpenOrderSummary(true);
    setScroll(scrollType);
  };

  const handleCloseOrderSummary = () => {
    setOpenOrderSummary(false);
  };

  React.useEffect(() => {
    if (OrderSummary) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [OrderSummary]);
  // End

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const [value, setValue] = React.useState(0);
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  // For According
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // End

  const [alignment, setAlignment] = React.useState("Breakfast");
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  // Tab For Weekly Setup
  const [Weekly, setValueWeekly] = React.useState(0);
  const handleChangeWeekly = (event, newValue) => {
    setValueWeekly(newValue);
  };
  // End

  // For Append
  const { useState } = React;
  const AddedElement = () => (
    <Grid item xs={12} md={12}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <div className="uploadImBox">
            <h1 className="">Upload Image</h1>
            <Button
              className="uploadImBt"
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput type="file" />
            </Button>
            <span>or drag file in here</span>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <label className="pageLabel">Enter Brand Name</label>
          <input
            type="text"
            className="prodSearchInpt"
            placeholder="Brand Name"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label className="pageLabel">Total Unit Bought</label>
          <input
            type="text"
            className="prodSearchInpt"
            placeholder="Total Unit"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label className="pageLabel">Enter Weight Per Unit</label>
          <input
            type="text"
            className="prodSearchInpt"
            placeholder="Weight Per Unit"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label className="pageLabel">Enter Price Per Unit</label>
          <input
            type="text"
            className="prodSearchInpt"
            placeholder="No. of Unit"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <label className="pageLabel">Select Weight Unit</label>
          <div className="unitDivMain">
            <div className="detailsTableFilterSec">
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                className="detailsTableFilterSecActionInr"
              >
                <ToggleButton value="Miligrams" className="filterBtn">
                  Miligrams
                </ToggleButton>
                <ToggleButton value="Grams" className="filterBtn">
                  Grams
                </ToggleButton>
                <ToggleButton value="KiloGrams" className="filterBtn">
                  Kilo Grams
                </ToggleButton>
                <ToggleButton value="Liter" className="filterBtn">
                  Liter
                </ToggleButton>
                <ToggleButton value="Milliliter" className="filterBtn">
                  Milliliter
                </ToggleButton>
                <ToggleButton value="Pounds" className="filterBtn">
                  Pounds
                </ToggleButton>
                <ToggleButton value="N/A" className="filterBtn">
                  N/A
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <label className="pageLabel">Enter Origin</label>
          <input
            type="text"
            className="prodSearchInpt"
            placeholder="Country Of Origin"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label className="pageLabel">Enter Low Stock Limit</label>
          <input
            type="text"
            className="prodSearchInpt"
            placeholder="Stock Limit"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label className="pageLabel">Enter Expiry Date</label>
          <input
            type="text"
            className="prodSearchInpt"
            placeholder="Expiry Date"
          />
        </Grid>

        <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
          <span onClick={closeInventory}>
            <Button
              className="modalBtSmallWidth"
              // onClick={handleClickOpen("body")}

              // onClick={handleClickOpen3("body")}
            >
              Save
            </Button>
          </span>
        </Grid>

        <Grid item xs={12} md={12}>
          <div className="productDetails groceryTableMain">
            <table width="100%">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Brand</th>
                  <th>Weight Per Unit</th>
                  <th>Price Per Unit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tur Daal</td>
                  <td>Brand</td>
                  <td>26Gram</td>
                  <td>$15</td>
                </tr>
                <tr>
                  <td>Tea</td>
                  <td>Tea Brand</td>
                  <td>1Kg</td>
                  <td>$15</td>
                </tr>
                <tr>
                  <td>Potato Bhaji</td>
                  <td>Tea Brand</td>
                  <td>1Kg</td>
                  <td>$15</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
  const [count, setCount2] = useState(0);
  // End

  // For Append Kitchen Supl
  const { useState3 } = React;
  const AddedElement3 = () => (
    <Grid item xs={12} md={12}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <div className="uploadImBox">
            <h1 className="">Upload Image</h1>
            <Button
              className="uploadImBt"
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput type="file" />
            </Button>
            <span>or drag file in here</span>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormikControl
            control="input"
            type="text"
            label="Enter Brand Name"
            placeholder="Enter Brand Name"
            labelClass="pageLabel"
            name="brand_name_kich"
            className="prodSearchInpt"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormikControl
            control="input"
            type="text"
            label="Total Unit Bought"
            placeholder="Total Unit Bought"
            labelClass="pageLabel"
            name="total_unit_bought_kich"
            className="prodSearchInpt"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormikControl
            control="input"
            type="text"
            label="Enter Weight Per Unit"
            placeholder="Enter Weight Per Unit"
            labelClass="pageLabel"
            name="weight_per_unit_kich"
            className="prodSearchInpt"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormikControl
            control="input"
            type="text"
            label="Enter Price Per Unit"
            placeholder="No. of Unit"
            labelClass="pageLabel"
            name="price_per_unit_kich"
            className="prodSearchInpt"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <label className="pageLabel">Select Weight Unit</label>
          <div className="unitDivMain">
            <div className="detailsTableFilterSec">
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                className="detailsTableFilterSecActionInr"
              >
                <ToggleButton value="Grams" className="filterBtn">
                  Grams
                </ToggleButton>
                <ToggleButton value="Kilo Grams" className="filterBtn">
                  Kilo Grams
                </ToggleButton>
                <ToggleButton value="Liter" className="filterBtn">
                  Liter
                </ToggleButton>
                <ToggleButton value="Milliliter" className="filterBtn">
                  Milliliter
                </ToggleButton>
                <ToggleButton value="Tonne" className="filterBtn">
                  Tonne
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormikControl
            control="input"
            type="text"
            label="Enter Origin"
            placeholder="Country Of Origin"
            labelClass="pageLabel"
            name="origin_kich"
            className="prodSearchInpt"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormikControl
            control="input"
            type="text"
            label="Enter Low Stock Limit"
            placeholder="Stock Limit"
            labelClass="pageLabel"
            name="stock_limit_kich"
            className="prodSearchInpt"
          />
        </Grid>

        <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
          <span onClick={closeInventory}>
            <Button
              type="submit"
              className="modalBtSmallWidth"
              // onClick={handleClickOpen("body")}

              // onClick={handleClickOpen3("body")}
            >
              Save
            </Button>
          </span>
        </Grid>

        <Grid item xs={12} md={12}>
          <div className="productDetails groceryTableMain">
            <table width="100%">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Brand</th>
                  <th>Weight Per Unit</th>
                  <th>Price Per Unit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tur Daal</td>
                  <td>Brand</td>
                  <td>26Gram</td>
                  <td>$15</td>
                </tr>
                <tr>
                  <td>Tea</td>
                  <td>Tea Brand</td>
                  <td>1Kg</td>
                  <td>$15</td>
                </tr>
                <tr>
                  <td>Potato Bhaji</td>
                  <td>Tea Brand</td>
                  <td>1Kg</td>
                  <td>$15</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
  const [count3, setCount3] = useState(0);
  // End

  const addFields = () => {
    var element = document.getElementById("addFieldsID");
    element.classList.toggle("addedFields");
  };

  const selectCarton = () => {
    var element = document.getElementById("cartonAndUnitId");
    element.classList.remove("selectedSinglUnit");
  };

  const selectSinglUnit = () => {
    var element = document.getElementById("cartonAndUnitId");
    element.classList.add("selectedSinglUnit");
  };

  const categoryIcoClick = () => {
    var element = document.getElementById("slideCateId");
    element.classList.toggle("slideCateSt");
  };

  return (
    <div className="starCafeTabMain">
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChangeTabs}
            aria-label="basic tabs example"
          >
            <Tab label="Order Summary" {...a11yProps(0)} />
            <Tab label="Create Meal Menu" {...a11yProps(1)} />
            <Tab label="Grocery" {...a11yProps(2)} />
            <Tab label="Vegetables" {...a11yProps(3)} />
            <Tab label="Kitchen Supplies" {...a11yProps(4)} />
            <Tab label="Cafe Inventory" {...a11yProps(5)} />
          </Tabs>
        </Box>

        {/* Custom Panel for Order Summery Start******** */}
        <CustomTabPanel value={value} index={0}>
          <OrderSummaryTab />
        </CustomTabPanel>
        {/* Custom Panel for Order Summery End******** */}

        {/* Custom Panel for Create Meal Menu  Start******** */}
        <CustomTabPanel value={value} index={1}>
          <CreateMealMenu />
        </CustomTabPanel>
        {/* Custom Panel for Create Meal Menu  End******** */}

        {/* Custom Panel for Grocery  Start******** */}
        <CustomTabPanel value={value} index={2}>
          <GroceryTab />
        </CustomTabPanel>
        {/* Custom Panel for Grocery  End******** */}

        {/* Custom Panel for Veggies  Start******** */}
        <CustomTabPanel value={value} index={3}>
          <VeggiesTab />
        </CustomTabPanel>
        {/* Custom Panel for Veggies  End******** */}

        {/* Custom Panel for Kitchen Supplies  Start******** */}
        <CustomTabPanel value={value} index={4}>
          <KitchenSuppliesTab />
        </CustomTabPanel>
        {/* Custom Panel for Kitchen Supplies  End******** */}

        {/* Custom Panel for Cafe Inventory  Start******** */}
        <CustomTabPanel value={value} index={5}>
          <CafeInventoryTab />
        </CustomTabPanel>
        {/* Custom Panel for Cafe Inventory  End******** */}
      </Box>

      {/* Add Today's Special Menu */}
      <div>
        <Dialog
          open={special}
          // onClose={handleCloseSpecial}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain smallModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Todays Special Menu
            <CloseIcon className="modalCloseBt" onClick={handleCloseSpecial} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <label className="pageLabel">Add Dish Name</label>
                      <select className="prodSearchInpt">
                        <option>Dish Name</option>
                        <option>Dish Name 1</option>
                        <option>Dish Name 2</option>
                        <option>Dish Name 3</option>
                      </select>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Price in INR (₹)</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Price in INR (₹)"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Price In Stars</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Price In Stars"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="addedMenuList">
                        <p className="menuOutLine">
                          <div className="menuOutLineInr">
                            <div>
                              <b>Roti</b> Product ID #122645
                            </div>
                            <div className="dollorAmt">$10</div>
                            <span className="mealStar">
                              <StarIcon /> 20
                            </span>
                          </div>
                          <div className="addedMenuActionBt">
                            <Tooltip title="Edit Menu" arrow placement="top">
                              <IconButton>
                                <img src={editIco} />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Delete Menu" arrow placement="top">
                              <IconButton>
                                <img src={deletIco} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </p>

                        <p className="menuOutLine">
                          <div className="menuOutLineInr">
                            <div>
                              <b>Roti</b> Product ID #122645
                            </div>
                            <div className="dollorAmt">₹ 10</div>
                            <span className="mealStar">
                              <StarIcon /> 20
                            </span>
                          </div>
                          <div className="addedMenuActionBt">
                            <Tooltip title="Edit Menu" arrow placement="top">
                              <IconButton>
                                <img src={editIco} />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Delete Menu" arrow placement="top">
                              <IconButton>
                                <img src={deletIco} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Button
                        className="modalBtFullWidth"
                        onClick={handleCloseAddMenu}
                      >
                        Add Menu
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add Menu */}
      <div>
        <Dialog
          open={addMenu}
          // onClose={handleCloseAddMenu}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain smallModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Menu Item 
            <CloseIcon className="modalCloseBt" onClick={handleCloseAddMenu} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    //   onSubmit={loginFun}
                  >
                    {(formik) => (
                      <Form>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12}>
                            <FormikControl
                              control="select"
                              type="text"
                              label="Enter Menu Name"
                              placeholder="Enter Menu Name"
                              labelClass="pageLabel"
                              name="menu_name"
                              options={dropdownOptions}
                              className="prodSearchInpt"
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Price in INR (₹)"
                              placeholder="Enter Price in INR (₹)"
                              labelClass="pageLabel"
                              name="price_name"
                              className="prodSearchInpt"
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Price In Stars"
                              placeholder="Price In Stars"
                              labelClass="pageLabel"
                              name="price_in_stars"
                              className="prodSearchInpt"
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <label className="pageLabel">
                              Select Weight Unit
                            </label>
                            <div className="unitDivMain">
                              <div className="detailsTableFilterSec">
                                <ToggleButtonGroup
                                  value={alignment}
                                  exclusive
                                  onChange={handleAlignment}
                                  className="detailsTableFilterSecActionInr"
                                >
                                  <ToggleButton
                                    value="Breakfast"
                                    className="filterBtn"
                                  >
                                    Breakfast
                                  </ToggleButton>
                                  <ToggleButton
                                    value="Lunch"
                                    className="filterBtn"
                                  >
                                    Lunch
                                  </ToggleButton>
                                  <ToggleButton
                                    value="Dinner"
                                    className="filterBtn"
                                  >
                                    Dinner
                                  </ToggleButton>
                                  <ToggleButton
                                    value="Special"
                                    className="filterBtn"
                                  >
                                    Special
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={12}></Grid>
                          {/* <Grid item xs={12} md={12}>
                      <div className="addedMenuList">
                        <p className="menuOutLine">
                          <div className="menuOutLineInr">
                            <div>
                              <b>Roti</b> Product ID #122645
                            </div>
                            <div className="dollorAmt">$10</div>
                            <span className="mealStar">
                              <StarIcon /> 20
                            </span>
                          </div>
                          <div className="addedMenuActionBt">
                            <Tooltip title="Edit Menu" arrow placement="top">
                              <IconButton>
                                <img src={editIco} />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Delete Menu" arrow placement="top">
                              <IconButton>
                                <img src={deletIco} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </p>
                      </div>
                    </Grid> */}
                          <Grid item xs={12} md={12}>
                            <Button
                              className="modalBtFullWidth"
                              // onClick={handleCloseAddMenu}
                              type="submit"
                            >
                              Add Menu Item
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Edit Menu */}
      <div>
        <Dialog
          open={editMenu}
          // onClose={handleCloseEditMenu}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain smallModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Edit Menu
            <CloseIcon className="modalCloseBt" onClick={handleCloseEditMenu} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} style={{ textAlign: "right" }}>
                      <Button
                        className="addContentBtn"
                        onClick={handleClickOpenAddMenu("body")}
                      >
                        <AddIcon />
                      </Button>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <div className="addedMenuList">
                        <p className="menuOutLine">
                          <div className="menuOutLineInr">
                            <div>
                              <b>Roti</b> Product ID #122645
                            </div>
                            <div className="dollorAmt">₹ 10</div>
                            <span className="mealStar">
                              <StarIcon /> 20
                            </span>
                          </div>
                          <div className="addedMenuActionBt">
                            <Tooltip title="Edit Menu" arrow placement="top">
                              <IconButton>
                                <img src={editIco} />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Delete Menu" arrow placement="top">
                              <IconButton>
                                <img src={deletIco} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </p>

                        <p className="menuOutLine">
                          <div className="menuOutLineInr">
                            <div>
                              <b>Roti</b> Product ID #122645
                            </div>
                            <div className="dollorAmt">₹ 10</div>
                            <span className="mealStar">
                              <StarIcon /> 20
                            </span>
                          </div>
                          <div className="addedMenuActionBt">
                            <Tooltip title="Edit Menu" arrow placement="top">
                              <IconButton>
                                <img src={editIco} />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Delete Menu" arrow placement="top">
                              <IconButton>
                                <img src={deletIco} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </p>

                        <p className="menuOutLine">
                          <div className="menuOutLineInr">
                            <div>
                              <b>Roti</b> Product ID #122645
                            </div>
                            <div className="dollorAmt">₹ 10</div>
                            <span className="mealStar">
                              <StarIcon /> 20
                            </span>
                          </div>
                          <div className="addedMenuActionBt">
                            <Tooltip title="Edit Menu" arrow placement="top">
                              <IconButton>
                                <img src={editIco} />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Delete Menu" arrow placement="top">
                              <IconButton>
                                <img src={deletIco} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Button className="modalBtFullWidth">Update</Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add Category */}
      <div>
        <Dialog
          open={open}
          // onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain smallModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Manage Category{" "}
            <CloseIcon className="modalCloseBt" onClick={handleClose} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} style={{ textAlign: "right" }}>
                      <Button className="pageTopMainBt" onClick={handleClose}>
                        Add Category
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <label className="pageLabel">Enter Category Name</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Category Name"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="productDetails groceryTableMain">
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>
                                Category Name{" "}
                                <em style={{ color: "#878787", fontSize: 12 }}>
                                  (Total 15 Categories)
                                </em>
                              </th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Category One</td>
                              <td>
                                <div className="actionBtnOut">
                                  <Tooltip title="Edit" arrow placement="top">
                                    <IconButton
                                    // onClick={() => navigate("/")}
                                    >
                                      <img src={editIco} />
                                    </IconButton>
                                  </Tooltip>

                                  <Switch
                                    {...label}
                                    defaultChecked
                                    className="swicthMain"
                                  />

                                  <Tooltip title="Delete" arrow placement="top">
                                    <IconButton>
                                      <img src={deletIco} />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Category Two</td>
                              <td>
                                <div className="actionBtnOut">
                                  <Tooltip title="Edit" arrow placement="top">
                                    <IconButton
                                    // onClick={() => navigate("/")}
                                    >
                                      <img src={editIco} />
                                    </IconButton>
                                  </Tooltip>

                                  <Switch
                                    {...label}
                                    defaultChecked
                                    className="swicthMain"
                                  />

                                  <Tooltip title="Delete" arrow placement="top">
                                    <IconButton>
                                      <img src={deletIco} />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add Product kkkk*/}
      <div>
        <Dialog
          open={open2}
          // onClose={handleClose2}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Product
            <CloseIcon className="modalCloseBt" onClick={handleClose2} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="uploadImBox">
                        <h1 className="">Upload Image</h1>
                        <Button
                          className="uploadImBt"
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload file
                          <VisuallyHiddenInput type="file" />
                        </Button>
                        <span>or drag file in here</span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Product Name</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Product Name"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">
                        Select Categories Name
                      </label>
                      <div className="addContentOut">
                        <select className="prodSearchInpt">
                          <option>Product Category</option>
                          <option>Select 1</option>
                          <option>Select 2</option>
                          <option>Select 3</option>
                        </select>
                        <Button
                          className="addContentBtn"
                          onClick={handleClickOpen("body")}
                        >
                          <AddIcon />
                        </Button>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Quantity</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Quantity"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Weight Per Item</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Weight"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Price in INR (₹)</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Price in INR (₹)"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Price In Stars</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Price In Stars"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <span onClick={handleClose2}>
                        <Button
                          className="modalBtSmallWidth"
                          // onClick={handleClickOpen("body")}

                          // onClick={handleClickOpen3("body")}
                        >
                          Add Product
                        </Button>
                      </span>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Edit Product */}
      <div>
        <Dialog
          open={open4}
          // onClose={handleClose4}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Edit Product
            <CloseIcon className="modalCloseBt" onClick={handleClose4} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="uploadImBox">
                        <h1 className="">Upload Image</h1>
                        <Button
                          className="uploadImBt"
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload file
                          <VisuallyHiddenInput type="file" />
                        </Button>
                        <span>or drag file in here</span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Product Name</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Product Name"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">
                        Select Categories Name
                      </label>
                      <div className="addContentOut">
                        <select className="prodSearchInpt">
                          <option>Product Category</option>
                          <option>Select 1</option>
                          <option>Select 2</option>
                          <option>Select 3</option>
                        </select>
                        <Button
                          className="addContentBtn"
                          onClick={handleClickOpen("body")}
                        >
                          <AddIcon />
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Quantity</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Quantity"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter New Quantity</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Quantity"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Weight Per Item</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Weight"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Price in INR (₹)</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Price in INR (₹)"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Price In Stars</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Price In Stars"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <span onClick={handleClose2}>
                        <Button
                          className="modalBtSmallWidth"
                          // onClick={handleClickOpen("body")}

                          // onClick={handleClickOpen3("body")}
                        >
                          Add Product
                        </Button>
                      </span>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add Grocery */}
      <div>
        <Dialog
          open={AddInventory}
          // onClose={closeInventory}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Grocery
            <CloseIcon className="modalCloseBt" onClick={closeInventory} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv" id="cartonAndUnitId">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    //   onSubmit={loginFun}
                  >
                    {(formik) => (
                      <Form>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={4}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Item Name"
                              placeholder="Enter Item Name"
                              labelClass="pageLabel"
                              name="grocery_item_name"
                              className="prodSearchInpt"
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <label className="pageLabel">Select Type</label>
                            <FormControl className="radioMain">
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="Carton"
                                name="radio-buttons-group"
                              >
                                <FormControlLabel
                                  value="Carton"
                                  control={<Radio />}
                                  label="Carton"
                                  onClick={selectCarton}
                                />
                                <FormControlLabel
                                  value="SingleUnit"
                                  control={<Radio />}
                                  label="Single Unit"
                                  onClick={selectSinglUnit}
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Low Stock Limit"
                              placeholder="Enter Low Stock Limit"
                              labelClass="pageLabel"
                              name="low_stock_limit"
                              className="prodSearchInpt"
                            />
                          </Grid>

                          <Grid item xs={12} md={12} className="cartonSt">
                            <Grid container spacing={3}>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                style={{ textAlign: "right" }}
                              >
                                <Button className="pageTopMainBt">
                                  <AddIcon /> Add New
                                </Button>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <div className="uploadImBox">
                                  <h1 className="">Upload Image</h1>
                                  <Button
                                    className="uploadImBt"
                                    component="label"
                                    variant="contained"
                                    startIcon={<CloudUploadIcon />}
                                  >
                                    Upload file
                                    <VisuallyHiddenInput type="file" />
                                  </Button>
                                  <span>or drag file in here</span>
                                </div>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Enter Brand Name"
                                  placeholder="Enter Brand Name"
                                  labelClass="pageLabel"
                                  name="brand_name"
                                  className="prodSearchInpt"
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Total Number Of Cartons"
                                  placeholder="Total Number Of Cartons"
                                  labelClass="pageLabel"
                                  name="total_number_of_cartons"
                                  className="prodSearchInpt"
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Enter Packs Per Carton"
                                  placeholder="Enter Packs Per Carton"
                                  labelClass="pageLabel"
                                  name="packs_per_carton"
                                  className="prodSearchInpt"
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Enter Carton Weight"
                                  placeholder="Enter Carton Weight"
                                  labelClass="pageLabel"
                                  name="carton_weight"
                                  className="prodSearchInpt"
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <label className="pageLabel">
                                  Select Carton Weight Unit
                                </label>
                                <div className="unitDivMain uniTopBotttomSpace">
                                  <div className="detailsTableFilterSec">
                                    <ToggleButtonGroup
                                      value={alignment}
                                      exclusive
                                      onChange={handleAlignment}
                                      className="detailsTableFilterSecActionInr"
                                    >
                                      <ToggleButton
                                        value="Kilograms"
                                        className="filterBtn"
                                      >
                                        Kilograms
                                      </ToggleButton>
                                      <ToggleButton
                                        value="Tonne"
                                        className="filterBtn"
                                      >
                                        Tonne
                                      </ToggleButton>
                                      <ToggleButton
                                        value="Liters"
                                        className="filterBtn"
                                      >
                                        Liters
                                      </ToggleButton>
                                    </ToggleButtonGroup>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Enter Weight Per Unit"
                                  placeholder="Enter Weight Per Unit"
                                  labelClass="pageLabel"
                                  name="weight_per_unit"
                                  className="prodSearchInpt"
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <label className="pageLabel">
                                  Select Weight Per Unit
                                </label>
                                <div className="unitDivMain uniTopBotttomSpace">
                                  <div className="detailsTableFilterSec">
                                    <ToggleButtonGroup
                                      value={alignment}
                                      exclusive
                                      onChange={handleAlignment}
                                      className="detailsTableFilterSecActionInr"
                                    >
                                      <ToggleButton
                                        value="Miligrams"
                                        className="filterBtn"
                                      >
                                        Miligrams
                                      </ToggleButton>
                                      <ToggleButton
                                        value="Gram"
                                        className="filterBtn"
                                      >
                                        Gram
                                      </ToggleButton>
                                      <ToggleButton
                                        value="Liters2"
                                        className="filterBtn"
                                      >
                                        Liters
                                      </ToggleButton>
                                    </ToggleButtonGroup>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Enter Price Per Carton in INR (₹)"
                                  placeholder="Enter Price Per Carton in INR (₹)"
                                  labelClass="pageLabel"
                                  name="price_per_carton"
                                  className="prodSearchInpt"
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Enter Price Per Unit in INR (₹)"
                                  placeholder="Enter Price Per Unit in INR (₹)"
                                  labelClass="pageLabel"
                                  name="price_per_unit"
                                  className="prodSearchInpt"
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="input"
                                  type="date"
                                  label="Enter Expiry Date"
                                  placeholder="Enter Expiry Date"
                                  labelClass="pageLabel"
                                  name="expiry_date"
                                  className="prodSearchInpt"
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Enter Origin"
                                  placeholder="Enter Origin"
                                  labelClass="pageLabel"
                                  name="origin_name"
                                  className="prodSearchInpt"
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="input"
                                  type="date"
                                  label="Select Date"
                                  placeholder="Select Date"
                                  labelClass="pageLabel"
                                  name="date_select"
                                  className="prodSearchInpt"
                                />
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={12}
                                style={{ textAlign: "center" }}
                              >
                                <span
                                // onClick={closeInventory}
                                >
                                  <Button
                                    className="modalBtSmallWidth"
                                    // onClick={handleClickOpen("body")}

                                    // onClick={handleClickOpen3("body")}
                                    type="submit"
                                  >
                                    Save
                                  </Button>
                                </span>
                              </Grid>

                              <Grid item xs={12} md={12}>
                                <div className="probDetBtOut">
                                  <Button className="probDetBt">
                                    <label>Product Name</label>
                                    <b>Tur Daal</b>
                                  </Button>
                                  <Button className="probDetBt">
                                    <label>Type</label>
                                    <b>Carton</b>
                                  </Button>
                                </div>
                              </Grid>

                              <Grid item xs={12} md={12}>
                                <div className="productDetails groceryTableMain">
                                  <table width="100%">
                                    <thead>
                                      <tr>
                                        <th>Brand</th>
                                        <th>Weight Per Carton</th>
                                        <th>Weight Per Unit</th>
                                        <th>Date</th>
                                        <th>Price Per Carton in INR (₹)</th>
                                        <th>Price Per Unit in INR (₹)</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Brand 1</td>
                                        <td>26Gram</td>
                                        <td>26Gram</td>
                                        <td>4/4/18</td>
                                        <td>₹ 15</td>
                                        <td>₹ 15</td>
                                      </tr>
                                      <tr>
                                        <td>Brand 2</td>
                                        <td>1Kg</td>
                                        <td>1Kg</td>
                                        <td>9/4/12</td>
                                        <td>₹ 15</td>
                                        <td>₹ 15</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} md={12} className="singlUnitSt">
                            <Grid container spacing={3}>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                style={{ textAlign: "right" }}
                              >
                                <Button className="pageTopMainBt">
                                  <AddIcon /> Add New
                                </Button>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <div className="uploadImBox">
                                  <h1 className="">Upload Image</h1>
                                  <Button
                                    className="uploadImBt"
                                    component="label"
                                    variant="contained"
                                    startIcon={<CloudUploadIcon />}
                                  >
                                    Upload file
                                    <VisuallyHiddenInput type="file" />
                                  </Button>
                                  <span>or drag file in here</span>
                                </div>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Enter Brand Name"
                                  placeholder="Enter Brand Name"
                                  labelClass="pageLabel"
                                  name="brand_name_unit"
                                  className="prodSearchInpt"
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Total Units Bought"
                                  placeholder="Total Units Bought"
                                  labelClass="pageLabel"
                                  name="units_bought_unit"
                                  className="prodSearchInpt"
                                />
                              </Grid>
                              {/* <Grid item xs={12} md={4}>
                          <label className="pageLabel">
                            Enter Packs Per Carton
                          </label>
                          <input
                            type="text"
                            className="prodSearchInpt"
                            placeholder="Packs Per Carton"
                          />
                        </Grid> */}
                              <Grid item xs={12} md={5}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Enter Weight Per Unit"
                                  placeholder="Enter Weight Per Unit"
                                  labelClass="pageLabel"
                                  name="weight_per_single_unit"
                                  className="prodSearchInpt"
                                />
                              </Grid>
                              <Grid item xs={12} md={7}>
                                <label className="pageLabel">
                                  Select Weight Per Unit
                                </label>
                                <div className="unitDivMain uniTopBotttomSpace">
                                  <div className="detailsTableFilterSec">
                                    <ToggleButtonGroup
                                      value={alignment}
                                      exclusive
                                      onChange={handleAlignment}
                                      className="detailsTableFilterSecActionInr"
                                    >
                                      <ToggleButton
                                        value="Kilograms"
                                        className="filterBtn"
                                      >
                                        Kilograms
                                      </ToggleButton>
                                      <ToggleButton
                                        value="Gram"
                                        className="filterBtn"
                                      >
                                        Gram
                                      </ToggleButton>
                                      <ToggleButton
                                        value="Miligram"
                                        className="filterBtn"
                                      >
                                        Miligram
                                      </ToggleButton>
                                      <ToggleButton
                                        value="Liter"
                                        className="filterBtn"
                                      >
                                        Liter
                                      </ToggleButton>
                                      <ToggleButton
                                        value="Mililiter"
                                        className="filterBtn"
                                      >
                                        Mililiter
                                      </ToggleButton>
                                    </ToggleButtonGroup>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Enter Price Per Unit in INR (₹)"
                                  placeholder="Enter Price Per Unit in INR (₹)"
                                  labelClass="pageLabel"
                                  name="price_per_single_unit"
                                  className="prodSearchInpt"
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="input"
                                  type="date"
                                  label="Enter Expiry Date"
                                  placeholder="Enter Expiry Date"
                                  labelClass="pageLabel"
                                  name="expiry_date_sinle_unit"
                                  className="prodSearchInpt"
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Enter Origin"
                                  placeholder="Enter Origin"
                                  labelClass="pageLabel"
                                  name="origin_name_single_unit"
                                  className="prodSearchInpt"
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormikControl
                                  control="input"
                                  type="date"
                                  label="Select Date"
                                  placeholder="Select Date"
                                  labelClass="pageLabel"
                                  name="date_single_unit"
                                  className="prodSearchInpt"
                                />
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={12}
                                style={{ textAlign: "center" }}
                              >
                                <span
                                // onClick={closeInventory}
                                >
                                  <Button
                                    type="submit"
                                    className="modalBtSmallWidth"
                                    // onClick={handleClickOpen("body")}

                                    // onClick={handleClickOpen3("body")}
                                  >
                                    Save
                                  </Button>
                                </span>
                              </Grid>

                              <Grid item xs={12} md={12}>
                                <div className="probDetBtOut">
                                  <Button className="probDetBt">
                                    <label>Product Name</label>
                                    <b>Tur Daal</b>
                                  </Button>
                                  <Button className="probDetBt">
                                    <label>Type</label>
                                    <b>Single Unit</b>
                                  </Button>
                                </div>
                              </Grid>

                              <Grid item xs={12} md={12}>
                                <div className="productDetails groceryTableMain">
                                  <table width="100%">
                                    <thead>
                                      <tr>
                                        <th>Brand</th>
                                        <th>Weight Per Carton</th>
                                        <th>Weight Per Unit</th>
                                        <th>Date</th>
                                        <th>Price Per Carton in INR (₹)</th>
                                        <th>Price Per Unit in INR (₹)</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Brand 1</td>
                                        <td>26Gram</td>
                                        <td>26Gram</td>
                                        <td>4/4/18</td>
                                        <td>₹ 15</td>
                                        <td>₹ 15</td>
                                      </tr>
                                      <tr>
                                        <td>Brand 2</td>
                                        <td>1Kg</td>
                                        <td>1Kg</td>
                                        <td>9/4/12</td>
                                        <td>₹ 15</td>
                                        <td>₹ 15</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>

                          {/* <Grid
                      item
                      xs={12}
                      md={12}
                      style={{ textAlign: "right" }}
                      id="addFieldsID"
                    >
                      <span onClick={addFields}>
                        <Button
                          className="pageTopMainBt blueBt addFieldsBt"
                          onClick={() => setCount2(count + 1)}
                        >
                          <AddIcon /> Add fields
                        </Button>
                      </span>
                      <Button className="pageTopMainBt AddNewBtn">
                        <AddIcon /> Add New
                      </Button>
                    </Grid> */}

                          {/*For Append */}
                          {[...Array(count)].map((_, i) => (
                            <AddedElement key={i} />
                          ))}
                          {/* End */}
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Deduct Grocery */}
      <div>
        <Dialog
          open={DeductProdct}
          // onClose={closeDeductProdct}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Deduct Product
            <CloseIcon className="modalCloseBt" onClick={closeDeductProdct} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Search"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="productDetails groceryTableMain">
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>Product Name</th>
                              <th>Brand</th>
                              <th>Quantity In Stock</th>
                              <th>Purchase Price in INR (₹)</th>
                              <th>Quantity To Deduct</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Roti</td>
                              <td>Tea Brand</td>
                              <td>50</td>
                              <td>₹ 1500</td>
                              <td>
                                <input
                                  className="qutyInput"
                                  placeholder="Quantity"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Roti</td>
                              <td>Tea Brand</td>
                              <td>50</td>
                              <td>₹ 1500</td>
                              <td>
                                <input
                                  className="qutyInput"
                                  placeholder="Quantity"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Roti</td>
                              <td>Tea Brand</td>
                              <td>50</td>
                              <td>₹ 1500</td>
                              <td>
                                <input
                                  className="qutyInput"
                                  placeholder="Quantity"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Roti</td>
                              <td>Tea Brand</td>
                              <td>50</td>
                              <td>₹ 1500</td>
                              <td>
                                <input
                                  className="qutyInput"
                                  placeholder="Quantity"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Roti</td>
                              <td>Tea Brand</td>
                              <td>50</td>
                              <td>₹ 1500</td>
                              <td>
                                <input
                                  className="qutyInput"
                                  placeholder="Quantity"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Roti</td>
                              <td>Tea Brand</td>
                              <td>50</td>
                              <td>₹ 1500</td>
                              <td>
                                <input
                                  className="qutyInput"
                                  placeholder="Quantity"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <Button className="modalBtSmallWidth">
                        Deduct Grocery
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add Kitchen Supplies */}
      <div>
        <Dialog
          open={AddKitchen}
          // onClose={closeAddKitchen}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Kitchen Supplies
            <CloseIcon className="modalCloseBt" onClick={closeAddKitchen} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    //   onSubmit={loginFun}
                  >
                    {(formik) => (
                      <Form>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Item Name"
                              placeholder="Enter Item Name"
                              labelClass="pageLabel"
                              name="item_name"
                              className="prodSearchInpt"
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <div className="addContentOut">
                              <FormikControl
                                control="select"
                                type="text"
                                label="Product Categories"
                                placeholder="Product Categories"
                                labelClass="pageLabel"
                                name="product_categories"
                                options={prodctOptions}
                                className="prodSearchInpt"
                              />
                              <Button
                                className="addContentBtn"
                                onClick={handleClickOpen("body")}
                              >
                                <AddIcon />
                              </Button>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ textAlign: "right" }}
                            id="addFieldsID"
                          >
                            <span onClick={addFields}>
                              <Button
                                className="pageTopMainBt blueBt addFieldsBt"
                                onClick={() => setCount3(count3 + 1)}
                              >
                                <AddIcon /> Add fields
                              </Button>
                            </span>
                            <Button className="pageTopMainBt AddNewBtn">
                              <AddIcon /> Add New
                            </Button>
                          </Grid>

                          {/*For Append */}
                          {[...Array(count3)].map((_, i) => (
                            <AddedElement3 key={i} />
                          ))}
                          {/* End */}
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Deduct Inventory Kitchen Supl */}
      <div>
        <Dialog
          open={DeductInventoryKitchen}
          // onClose={closeDeductInventoryKitchen}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Deduct Product
            <CloseIcon
              className="modalCloseBt"
              onClick={closeDeductInventoryKitchen}
            />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Search"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="productDetails groceryTableMain">
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>Product Name</th>
                              <th>Brand</th>
                              <th>Quantity In Stock</th>
                              <th>Purchase Price in INR (₹)</th>
                              <th>Quantity To Deduct</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Roti</td>
                              <td>Tea Brand</td>
                              <td>50</td>
                              <td>₹ 1500</td>
                              <td>
                                <input
                                  className="qutyInput"
                                  placeholder="Quantity"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Roti</td>
                              <td>Tea Brand</td>
                              <td>50</td>
                              <td>₹ 1500</td>
                              <td>
                                <input
                                  className="qutyInput"
                                  placeholder="Quantity"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Roti</td>
                              <td>Tea Brand</td>
                              <td>50</td>
                              <td>₹ 1500</td>
                              <td>
                                <input
                                  className="qutyInput"
                                  placeholder="Quantity"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Roti</td>
                              <td>Tea Brand</td>
                              <td>50</td>
                              <td>₹ 1500</td>
                              <td>
                                <input
                                  className="qutyInput"
                                  placeholder="Quantity"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Roti</td>
                              <td>Tea Brand</td>
                              <td>50</td>
                              <td>₹ 1500</td>
                              <td>
                                <input
                                  className="qutyInput"
                                  placeholder="Quantity"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Roti</td>
                              <td>Tea Brand</td>
                              <td>50</td>
                              <td>₹ 1500</td>
                              <td>
                                <input
                                  className="qutyInput"
                                  placeholder="Quantity"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <Button className="modalBtSmallWidth">
                        Deduct Grocery
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add Prod in Cafe Inventory  kkkk*/}
      <div>
        <Dialog
          open={AdProdCafeInventory}
          // onClose={CloseAdProdCafeInventory}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Product
            <CloseIcon
              className="modalCloseBt"
              onClick={CloseAdProdCafeInventory}
            />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    {/* <Grid item xs={12} md={12}>
                      <div className="addRemoveImagesBox">
                        <div className="imageAddedBox">
                          <img src={productImg} />
                          <div className="cloIm">
                            <CloseIcon />
                          </div>
                        </div>
                        <div className="imageAddedBox">
                          <img src={productImg} />
                          <div className="cloIm">
                            <CloseIcon />
                          </div>
                        </div>
                        <div className="imageAddedBox">
                          <img src={productImg} />
                          <div className="cloIm">
                            <CloseIcon />
                          </div>
                        </div>
                        <div className="uploadImBox">
                          <h1 className="">Upload Image</h1>
                          <Button
                            className="uploadImBt"
                            component="label"
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload
                            <VisuallyHiddenInput type="file" />
                          </Button>
                          <span>or drag file in here</span>
                        </div>
                      </div>
                    </Grid> */}

                    <Grid item xs={12} md={12}>
                      <div className="uploadImBox">
                        <h1 className="">Upload Image</h1>
                        <Button
                          className="uploadImBt"
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload file
                          <VisuallyHiddenInput type="file" />
                        </Button>
                        <span>or drag file in here</span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Product Name</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Product Name"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Product Categories</label>
                      <div className="addContentOut eyesMain">
                        <select className="prodSearchInpt">
                          <option>Product Category</option>
                          <option>Select 1</option>
                          <option>Select 2</option>
                          <option>Select 3</option>
                        </select>
                        <div className="eyeCate">
                          <Tooltip
                            title="View Categories"
                            arrow
                            placement="top"
                          >
                            <IconButton>
                              <RemoveRedEyeIcon onClick={categoryIcoClick} />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <Button
                          className="addContentBtn"
                          onClick={handleClickOpen("body")}
                        >
                          <AddIcon />
                        </Button>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className="slideCateFirstSt innerTabsMain"
                      id="slideCateId"
                    >
                      <div className="groceryTableMain">
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>Category Name</th>
                              <th style={{ textAlign: "right" }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Category One</td>
                              <td style={{ textAlign: "right" }}>
                                <div className="actionBtnOut">
                                  <Tooltip
                                    title="Edit Category"
                                    arrow
                                    placement="top"
                                  >
                                    <IconButton>
                                      <img
                                        src={editIco}
                                        onClick={handleClickOpenEditCategory(
                                          "body"
                                        )}
                                      />
                                    </IconButton>
                                  </Tooltip>

                                  <Switch
                                    {...label}
                                    defaultChecked
                                    className="swicthMain"
                                  />

                                  <Tooltip title="Delete" arrow placement="top">
                                    <IconButton>
                                      <img src={deletIco} />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Category One</td>
                              <td style={{ textAlign: "right" }}>
                                <div className="actionBtnOut">
                                  <Tooltip
                                    title="Edit Category"
                                    arrow
                                    placement="top"
                                  >
                                    <IconButton>
                                      <img
                                        src={editIco}
                                        onClick={handleClickOpenEditCategory(
                                          "body"
                                        )}
                                      />
                                    </IconButton>
                                  </Tooltip>

                                  <Switch
                                    {...label}
                                    defaultChecked
                                    className="swicthMain"
                                  />

                                  <Tooltip title="Delete" arrow placement="top">
                                    <IconButton>
                                      <img src={deletIco} />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Category One</td>
                              <td style={{ textAlign: "right" }}>
                                <div className="actionBtnOut">
                                  <Tooltip
                                    title="Edit Category"
                                    arrow
                                    placement="top"
                                  >
                                    <IconButton>
                                      <img
                                        src={editIco}
                                        onClick={handleClickOpenEditCategory(
                                          "body"
                                        )}
                                      />
                                    </IconButton>
                                  </Tooltip>

                                  <Switch
                                    {...label}
                                    defaultChecked
                                    className="swicthMain"
                                  />

                                  <Tooltip title="Delete" arrow placement="top">
                                    <IconButton>
                                      <img src={deletIco} />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Category One</td>
                              <td style={{ textAlign: "right" }}>
                                <div className="actionBtnOut">
                                  <Tooltip
                                    title="Edit Category"
                                    arrow
                                    placement="top"
                                  >
                                    <IconButton>
                                      <img
                                        src={editIco}
                                        onClick={handleClickOpenEditCategory(
                                          "body"
                                        )}
                                      />
                                    </IconButton>
                                  </Tooltip>

                                  <Switch
                                    {...label}
                                    defaultChecked
                                    className="swicthMain"
                                  />

                                  <Tooltip title="Delete" arrow placement="top">
                                    <IconButton>
                                      <img src={deletIco} />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Category One</td>
                              <td style={{ textAlign: "right" }}>
                                <div className="actionBtnOut">
                                  <Tooltip
                                    title="Edit Category"
                                    arrow
                                    placement="top"
                                  >
                                    <IconButton>
                                      <img
                                        src={editIco}
                                        onClick={handleClickOpenEditCategory(
                                          "body"
                                        )}
                                      />
                                    </IconButton>
                                  </Tooltip>

                                  <Switch
                                    {...label}
                                    defaultChecked
                                    className="swicthMain"
                                  />

                                  <Tooltip title="Delete" arrow placement="top">
                                    <IconButton>
                                      <img src={deletIco} />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div
                        className="paginationOuterMain"
                        style={{ marginTop: 20 }}
                      >
                        <Pagination
                          count={10}
                          variant="outlined"
                          shape="rounded"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Current Quantity</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Quantity"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter New Quantity</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="New Quantity"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Weight Per Unit</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Weight"
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={12}>
                      <label className="pageLabel">Description</label>
                      <textarea
                        className="modalTexArea"
                        placeholder="Write here"
                      ></textarea>
                    </Grid> */}
                    <Grid item xs={12} md={12}>
                      <label className="pageLabel">Select Weight Unit</label>
                      <div className="unitDivMain">
                        <div className="detailsTableFilterSec">
                          <ToggleButtonGroup
                            value={alignment}
                            exclusive
                            onChange={handleAlignment}
                            className="detailsTableFilterSecActionInr"
                          >
                            <ToggleButton value="Grams" className="filterBtn">
                              Grams
                            </ToggleButton>
                            <ToggleButton
                              value="KiloGrams"
                              className="filterBtn"
                            >
                              Kilo Grams
                            </ToggleButton>
                            <ToggleButton value="Liter" className="filterBtn">
                              Liter
                            </ToggleButton>
                            <ToggleButton
                              value="Milliliter"
                              className="filterBtn"
                            >
                              Milliliter
                            </ToggleButton>
                            <ToggleButton value="Tonne" className="filterBtn">
                              Tonne
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Price in INR (₹)</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Price In Dollars"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Price In Stars</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Price In Stars"
                      />
                    </Grid>

                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <span onClick={closeInventory}>
                        <Button
                          className="modalBtSmallWidth"
                          // onClick={handleClickOpen("body")}

                          // onClick={handleClickOpen3("body")}
                        >
                          Add Product
                        </Button>
                      </span>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Weekly Setup Menu */}
      <div>
        <Dialog
          open={WeeklySetup}
          // onClose={handleCloseWeeklySetup}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Setup Weekly Menu
            <CloseIcon
              className="modalCloseBt"
              onClick={handleCloseWeeklySetup}
            />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="modalWeeklyTabsMain">
                        <Box sx={{ width: "100%" }}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={Weekly}
                              onChange={handleChangeWeekly}
                              aria-label="basic tabs example"
                            >
                              <Tab label="Breakfast" {...a11yPropsWeekly(0)} />
                              <Tab label="Lunch Menu" {...a11yPropsWeekly(1)} />
                              <Tab
                                label="Dinner Menu"
                                {...a11yPropsWeekly(2)}
                              />
                            </Tabs>
                          </Box>
                          <CustomTabPanel value={Weekly} index={0}>
                            <div className="modalTabsContent checkColor1">
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <h3 className="itemHeadingSt">
                                    Regular Menu Items
                                  </h3>
                                  <DataTable columns={columns} data={data} />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  style={{ textAlign: "center" }}
                                >
                                  <Button className="modalSmallBtSt">
                                    Save Items
                                  </Button>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <h3 className="itemHeadingSt">
                                    Special Menu Items
                                  </h3>
                                  <DataTable columns={columns} data={data} />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  style={{ textAlign: "center" }}
                                >
                                  <Button className="modalSmallBtSt">
                                    Save Items
                                  </Button>
                                </Grid>
                              </Grid>
                            </div>
                          </CustomTabPanel>
                          <CustomTabPanel value={Weekly} index={1}>
                            <div className="modalTabsContent checkColor2">
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <h3 className="itemHeadingSt">
                                    Regular Menu Items
                                  </h3>
                                  <DataTable columns={columns} data={data} />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  style={{ textAlign: "center" }}
                                >
                                  <Button className="modalSmallBtSt">
                                    Save Items
                                  </Button>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <h3 className="itemHeadingSt">
                                    Special Menu Items
                                  </h3>
                                  <DataTable columns={columns} data={data} />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  style={{ textAlign: "center" }}
                                >
                                  <Button className="modalSmallBtSt">
                                    Save Items
                                  </Button>
                                </Grid>
                              </Grid>
                            </div>
                          </CustomTabPanel>
                          <CustomTabPanel value={Weekly} index={2}>
                            <div className="modalTabsContent checkColor3">
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <h3 className="itemHeadingSt">
                                    Regular Menu Items
                                  </h3>
                                  <DataTable columns={columns} data={data} />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  style={{ textAlign: "center" }}
                                >
                                  <Button className="modalSmallBtSt">
                                    Save Items
                                  </Button>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <h3 className="itemHeadingSt">
                                    Special Menu Items
                                  </h3>
                                  <DataTable columns={columns} data={data} />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  style={{ textAlign: "center" }}
                                >
                                  <Button className="modalSmallBtSt">
                                    Save Items
                                  </Button>
                                </Grid>
                              </Grid>
                            </div>
                          </CustomTabPanel>
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Edit Category */}
      <div>
        <Dialog
          open={openEditCategory}
          // onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain smallModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Edit Category{" "}
            <CloseIcon
              className="modalCloseBt"
              onClick={handleCloseEditCategory}
            />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <label className="pageLabel">Edit Category Name</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        value="Category Name"
                        placeholder="Product Name"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Button
                        className="modalBtFullWidth"
                        onClick={handleCloseEditCategory}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Lunch Order Summary */}
      <div>
        <Dialog
          open={OrderSummary}
          // onClose={handleCloseOrderSummary}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Lunch Order Summary
            <CloseIcon
              className="modalCloseBt"
              onClick={handleCloseOrderSummary}
            />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="orderSmryPop">
                        <div className="orderSmryPop1">
                          <img src={ord2} />
                          <b className="AccorddingHeading">Lunch Summary</b>
                        </div>
                        <div className="orderSmryPop2">
                          <div className="accordTotalOrder">
                            <img src={orderIc} /> Total 50 Orders
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="menuDetailsPop">
                        <div>
                          <h1>Lunch Thali</h1>
                          <p>Product ID #122645</p>
                        </div>
                        <div className="accordTotalOrder">
                          <img src={orderIc} /> 30
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <h1 className="otherProdctHead">Other Cafe Products</h1>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="menuDetailsPopOut">
                        <div className="menuDetailsPop">
                          <div>
                            <h1>Lunch Thali</h1>
                            <p>Product ID #122645</p>
                          </div>
                          <div className="accordTotalOrder">
                            <img src={orderIc} /> 30
                          </div>
                        </div>
                      </div>

                      <div className="menuDetailsPopOut">
                        <div className="menuDetailsPop">
                          <div>
                            <h1>Lunch Thali</h1>
                            <p>Product ID #122645</p>
                          </div>
                          <div className="accordTotalOrder">
                            <img src={orderIc} /> 30
                          </div>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={6} className="minuDetailDevideLine">
                      <div className="menuDetailsPopOut">
                        <div className="menuDetailsPop">
                          <div>
                            <h1>Lunch Thali</h1>
                            <p>Product ID #122645</p>
                          </div>
                          <div className="accordTotalOrder">
                            <img src={orderIc} /> 30
                          </div>
                        </div>
                        <div className="menuDetailsPop">
                          <div>
                            <h1>Lunch Thali</h1>
                            <p>Product ID #122645</p>
                          </div>
                          <div className="accordTotalOrder">
                            <img src={orderIc} /> 30
                          </div>
                        </div>
                      </div>
                    </Grid>

                    {/* <Grid item xs={12} md={12}>
                      <Button
                        className="modalBtFullWidth"
                        onClick={handleCloseEditCategory}
                      >
                        Save
                      </Button>
                    </Grid> */}
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

export default StarCafeTabs;
