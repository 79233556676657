import React, { useEffect } from "react";
import splashLogo from "../../assets/images/splashNewLogo2.svg";
import splashlogoupdated from "../../assets/images/splashlogoupdated.png";
import starAniOneNew from "../../assets/images/starAniOneNew.gif";
// import gifStar from "../../assets/images/gifStar.gif";
import OnlyStar from "../../assets/images/OnlyStar.svg";
import starname from "../../assets/images/starname.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import AOS from "aos";
import "aos/dist/aos.css";

import video from "../../assets/Skype_Video.mp4";

function Splash() {
  //const [fcmToken, setFcmToken] = React.useState(null);
  const navigate = useNavigate();

  // const handleMessage = (event) => {
  //   alert("splash screen handlemessage")
  //   alert(event.origin);
  //   if (event.origin !== "https://tutorvox.com/splash") return;

  //   try {
  //     const message = JSON.parse(event.data);
  //     alert(message);
  //     if (message.type === "FROM_REACT_NATIVE") {
  //       setFcmToken(message.data);
  //       console.log("Message received from React Native:", message.data);
  //     }
  //   } catch (error) {
  //     alert("in catch", error);
  //     console.error("Error parsing message:", error);
  //   }
  // };

  const sendMessageToReactNative = () => {
    const message = { type: "FROM_REACT_JS", data: {} };
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    } else {
      console.log("Not in a React Native WebView environment");
    }
    navigate("/LoginMobile");
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="mobileCssMain">
        <div
          className="spashVideoMain"
          onClick={() => navigate("/LoginMobile")}
        >
          <video src={video} autoPlay muted
            onEnded={() => navigate("/LoginMobile")}
          ></video>
        </div>
      </div>
    </>
  );
}

export default Splash;
