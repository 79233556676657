import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PhoneIcon from "@mui/icons-material/Phone";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import HowToRegIcon from "@mui/icons-material/HowToReg";

function BottomTabsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  let usertype = localStorage.getItem("userType");

  const handleTabClick = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/DashboardPage");
        break;
      case 1:
        navigate("/ActiveOrders");
        break;
      case 2:
        navigate("/ReorderTab");
        break;
      case 3:
        if (localStorage.getItem("reporting_to") != null) {
          navigate("/ManagerTab");
        } else {
          navigate("/Profile");
        }
        break;
      case 4:
        navigate("/Profile");
        break;

      default:
        navigate("/DashboardPage");
    }
  };

  React.useEffect(() => {
    switch (location.pathname) {
      case "/DashboardPage":
        setValue(0);
        break;
      case "/ActiveOrders":
        setValue(1);
        break;
      case "/ReorderTab":
        setValue(2);
        break;
      case "/ManagerTab":
        if (localStorage.getItem("reporting_to") != null) {
          setValue(3);
        } else {
          setValue(4);
        }

        break;
      case "/Profile":
        if (localStorage.getItem("reporting_to") != null) {
          setValue(4);
        } else setValue(3);
        break;

      default:
        setValue(0);
    }
  }, [location.pathname]);

  return (
    <div className="mobileCssMain">
      <div className="bottomTabMain">
        <Tabs
          value={value}
          onChange={handleTabClick}
          aria-label="icon label tabs example"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab icon={<DashboardCustomizeOutlinedIcon />} label="Home" />
          <Tab icon={<ShoppingCartOutlinedIcon />} label="Active" />
          <Tab icon={<AddShoppingCartOutlinedIcon />} label="Reorder" />
          {/* <Tab
                  icon={<AddShoppingCartOutlinedIcon />}
                  label="Manager"
                /> */}
          {localStorage.getItem("reporting_to") != null ? (
            <Tab icon={<HowToRegIcon />} label="Manager" />
          ) : null}
          <Tab icon={<PersonOutlineOutlinedIcon />} label="Profile" />
        </Tabs>
      </div>
    </div>
  );
}

export default BottomTabsPage;
