import {
    ORDER_TYPE_BREAKFAST,
    ORDER_TYPE_LUNCH,
    ORDER_TYPE_DINNER
} from '../constants/orderTypes';

const restrictedOrderTypes = [ORDER_TYPE_BREAKFAST, ORDER_TYPE_LUNCH, ORDER_TYPE_DINNER];

// export const canAddItemToCart = (cart, newItems) => {
//     const orderTypesInCart = cart.map(item => item.type);
//     return !newItems.some(newItem => {
//         if (restrictedOrderTypes.includes(newItem.type)) {
//             return orderTypesInCart.some(orderType => restrictedOrderTypes.includes(orderType));
//         }
//         return false;
//     });
// };

export const canAddItemToCart = (cart, newItems) => {
    const orderTypesInCart = cart.map(item => item.type);
    const existingRestrictedType = orderTypesInCart.find(type => restrictedOrderTypes.includes(type));

    return !newItems.some(newItem => {
        if (restrictedOrderTypes.includes(newItem.type)) {
            // If there is an existing restricted type in the cart, only allow if it matches the new item's type
            return existingRestrictedType && existingRestrictedType !== newItem.type;
        }
        return false;
    });
};


// export const canAddSingleItemToCart = (cart, newItem) => {

//     const orderTypesInCart = cart.map(item => item.type);
//     const existingRestrictedType = orderTypesInCart.find(type => restrictedOrderTypes.includes(type));

//     if (restrictedOrderTypes.includes(newItem.type)) {
//         if (existingRestrictedType && existingRestrictedType !== newItem.type) {
//             return false;
//         }
//     }
//     return true;
// };

export const canAddSingleItemToCart = (cart, newItem) => {
    
    const orderTypesInCart = cart.map(item => item.type);
    const existingRestrictedType = orderTypesInCart.find(type => restrictedOrderTypes.includes(type));

    if (restrictedOrderTypes.includes(newItem.type)) {
        if (existingRestrictedType && existingRestrictedType !== newItem.type) {
            return false;
        }
    }

    return true;
};