import React from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import editIco from "../../../assets/images/edit-icon.svg";
import deletIco from "../../../assets/images/delete-icon.svg";
import StarIcon from "@mui/icons-material/Star";
import Pagination from "@mui/material/Pagination";

function MaharajAfterOpeningWindow() {
  const navigate = useNavigate();

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1> For Master Chef</h1>
              <div className="employeeFilter">
                <div className="prodFilter">
                  <input className="prodSearchInpt" placeholder="Search" />
                </div>
                {/* <Button
                  className="pageTopMainBt"
                  onClick={() => navigate("/AddEmployee")}
                >
                  Add Employee
                </Button> */}
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            kkk
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default MaharajAfterOpeningWindow;
