// import React from 'react';
// import { useLocation } from 'react-router-dom';
// import NotificationIcon from './MobileScreens/componentsMobile/NotificationIcon';

// const MobileLayout = ({ children }) => {
//     const location = useLocation();

//     // Define routes that are considered mobile
//     const mobileRoutes = [
//         '/Splash',
//         '/LoginMobile',
//         '/DashboardPage',
//         '/BreakfastOrder',
//         '/OrderSummary',
//         '/OrderSuccess',
//         '/ActiveOrders',
//         '/ActiveOrderDetails',
//         '/ReorderTab',
//         '/Lunch',
//         '/StarMarketHome',
//         '/ResetPassword',
//         '/OtpScreen',
//         '/ProductDetailsMobile',
//         '/SearchPage',
//         '/EmptyCart',
//         '/Profile',
//         '/ProfileDetailsMobile',
//         '/WarningDetails',
//         '/StarCafeHome',
//         '/CafeProductDetails',
//         '/Dinner',
//         '/ReadyOrderDetails',
//     ];

//     const isMobileRoute = mobileRoutes.includes(location.pathname);
//     const isLoginScreen = location.pathname === '/LoginMobile';
//     const isNotificationScreen = location.pathname === '/Notifications';

//     return (
//         <>
//             {isMobileRoute && !isLoginScreen && !isNotificationScreen && <NotificationIcon />}
//             {children}
//         </>
//     );
// };

// export default MobileLayout;

import React from "react";
import { useLocation } from "react-router-dom";
import NotificationIcon from "./MobileScreens/componentsMobile/NotificationIcon";

const MobileLayout = ({ children }) => {
  const location = useLocation();

  // Define routes that are considered mobile
  const mobileRoutes = [
    // "/Splash",
    "/LoginMobile",
    "/DashboardPage",
    "/BreakfastOrder",
    "/OrderSummary",
    "/OrderSummaryMarket",
    "/OrderSuccessMarket",
    "/OrderSuccess",
    "/ActiveOrders",
    "/ActiveOrderDetails",
    "/ActiveOrderDetailsMarket",
    "/ReorderTab",
    "/Lunch",
    "/StarMarketHome",
    // "/ResetPassword",
    "/OtpScreen",
    "/ProductDetailsMobile",
    "/SearchPage",
    "/EmptyCart",
    "/Profile",
    "/ProfileDetailsMobile",
    "/WarningDetails",
    "/StarCafeHome",
    "/CafeProductDetails",
    "/Dinner",
    "/ReadyOrderDetails",
    "/ManagerTab",
    "/ManagerEmpProfDetails",
    "/ManagerEmpProfPersonalDetails",
    "/ManagerEmpWarnDetails",
    "/CFOWarningDetails",
    "/SupportTicketsMobile",
    "/SupportTicketDetails",
    "/LeaveDetails",

  ];

  // Define routes that are considered web
  const webRoutes = [
    "/",
    "/Home",
    "/Dashboard",
    "/Orders",
    "/ScannedOrder",
    "/Employees",
    "/EmployeesDetails",
    "/ServicesConfiguration",
    "/ProductDetails",
    "/NoticePrintFormat",
    "/AddEditEmployee",
    "/AddEmployee",
    "/EditEmployee",
    // "/ForgotPass",
    "/MaharajAfterOpeningWindow",
    "/SuperAdminDashboard",
    "/CompanyAdmins",
    "/ProfileDetails",
    "/EnterOtp",
    "/ChangePassword",
    "/Settings",
    "/AddCompanyAdmin",
    "/EditCompanyAdmin",
    "/AddEditCompany",
    "/AddCompany",
    "/EditCompany",
    "/Help",
    "/GroceryProductDetails",
    "/VegetableProduuctDetails",
    "/FoodProductDetails",
    "/ProductDetailsStarMarket",
    "/Notifications",
    "/CFOWarningList",
  ];

  const isMobileRoute = mobileRoutes.includes(location.pathname);
  const isWebRoute = webRoutes.includes(location.pathname);
  const isLoginScreen = location.pathname === "/LoginMobile";
  const isNotificationScreen = location.pathname === "/Notifications";
  const isLoginScreenWeb = location.pathname === "/";

  return (
    <>
      {isMobileRoute && !isLoginScreen && !isNotificationScreen && (
        <NotificationIcon />
      )}
      {isWebRoute && !isNotificationScreen && !isLoginScreenWeb && (
        <NotificationIcon />
      )}
      {children}
    </>
  );
};

export default MobileLayout;
