import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import cartImg from "../assets/images/cartImg.svg";
import QRCodeComponent from "../../MobileScreens/componentsMobile/QRCodeComponent";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarLogoSmall from "../assets/images/StarLogoSmall.svg";
import { makeGetRequest, makePostRequest } from "../../utils/utils";
import IconButton from "@mui/material/IconButton";
import moment from "moment/moment";
import swal from "sweetalert";
import { Button, Grid } from "@mui/material";
// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";

// End

function OrderSuccess({ route }) {
  const navigate = useNavigate();

  const location = useLocation();
  const { data, Pickup, type } = location.state || {};
  const [userName, setUserName] = useState(0);
  const [timing, setTiming] = useState([]);

  async function getdata() {
    let id = localStorage.getItem("userId");

    await makeGetRequest("starcafe/getorder/" + data)
      .then((response) => {
        if (response.data) {
          if (response.data.data.length > 0) {
            if (response.data.data[0].orderstatus == "Completed") {
              swal("Success", "Thank You for your order", "success", {
                timer: 3000,
                buttons: false,
              });
              navigate("/DashboardPage");
            }
            console.log(response.data.data[0].orderstatus);
          }
        } else {
        }
      })
      .catch((err) => { });
  }

  useEffect(() => {
    setUserName(localStorage.getItem("username"));
    setTiming(JSON.parse(localStorage.getItem("cafetiming")));

    // Set up the interval to call the API every 5 seconds
    const interval = setInterval(() => {
      getdata();
    }, 5000); //5000ms = 5s

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  // Success Modal
  const [SucessMod, setOpenSucessMod] = React.useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleClickSucessMod = (scrollType, type) => () => {
    setOpenSucessMod(true);
    setScroll(scrollType);
  };

  const closeSucessMod = () => {
    setOpenSucessMod(false);
  };

  const descriptionElementRef = useRef(null);
  // End

  return (
    <>
      <div className="mobileCssMain">
        <div className="container">
          <div className="wrapper">
            <div className="screenTitle">
              <Button onClick={() => navigate(-1)}>
                <ArrowBackIosIcon />
                <IconButton className="TouchableIconBtn"></IconButton>
              </Button>
              <h2>{Pickup}</h2>
            </div>
            <div className="fixedBottomPadding">
              <h2 className="ordSucHed">Order Placed Successfully</h2>
              <div className="orderTiming setTimeSt">
                <h4> Star Cafe Time</h4>
                {timing ? (
                  <>
                    {timing.map((cafetiming, index) => (
                      <>
                        {cafetiming.service_type == type &&
                          cafetiming.type === "Pickup" ? (
                          <h4>
                            {moment(cafetiming.starttime, "HH:mm").format(
                              "hh:mm A"
                            ) +
                              " to " +
                              moment(cafetiming.endtime, "HH:mm").format(
                                "hh:mm A"
                              )}
                          </h4>
                        ) : null}
                      </>
                    ))}
                  </>
                ) : (
                  <h4> For Timing Please Contact Admin</h4>
                )}
              </div>
              <div className="summaryContent">
                <div className="cartImgOuter">
                  <img src={cartImg} />
                </div>
                <h1 className="usernamest">{userName}</h1>
                <h1>Star Cafe Order # {data} </h1>
                <p>
                  Thank you for your order. Please pick your order at star cafe.
                  You will receive a notification once your order is ready for
                  pickup. <br></br>
                  <span className="spanbold">
                    Scan the QR code give below At Counter
                  </span>
                </p>
                <div className="qrOuter">
                  {data ? <QRCodeComponent data={JSON.stringify({orderId:data,serviceType:'Starcafe'})} /> : null}
                </div>
                <h1 className="tnkTx"> "Thank You For Your Order" </h1>
                {/* <Button onClick={handleClickSucessMod("body", 1)}>
                  Demo Button
                </Button> */}
                <Button
                  className="secondaryBtn"
                  onClick={() => navigate("/DashboardPage")}
                >
                  Back To Home
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sucess Modal */}
      <div>
        <Dialog
          open={SucessMod}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain mobModalMain"
        >
          <DialogTitle id="scroll-dialog-title" style={{ background: "none" }}>
            <CloseIcon className="modalCloseBt" onClick={closeSucessMod} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
            // tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div className="thnkOuter">
                        <h1>Thank You!</h1>
                        <p>Thank you for your order. Lorem Ipsun Dolor text.</p>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default OrderSuccess;
