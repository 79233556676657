import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import breakfastImg from "../assets/images/breakfastImg.svg";
import cup from "../assets/images/cup.svg";
import mealMenu from "../assets/images/mealMenu.svg";
import mealMenu2 from "../assets/images/mealMenu2.svg";
import StarIcon from "@mui/icons-material/Star";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarLogoSmall from "../assets/images/StarLogoSmall.svg";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import CoffeeIcon from "@mui/icons-material/Coffee";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import BentoIcon from "@mui/icons-material/Bento";
import CookieIcon from "@mui/icons-material/Cookie";
import BlurCircularIcon from "@mui/icons-material/BlurCircular";
import { makeGetRequest, makePostRequest } from "../../utils/utils";
import { useCart } from "../Screens/CartContext";
import ShoppingCartOutlined from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import menuNot from "../assets/images/menNot2.jpg";
import CloseIcon from "@mui/icons-material/Close";
import Roti from "../assets/images/Roti.svg";
import IconButton from '@mui/material/IconButton';
import {
  ORDER_TYPE_BREAKFAST,
  ORDER_TYPE_LUNCH,
  ORDER_TYPE_DINNER,
} from "../../constants/orderTypes";
import {
  canAddItemToCart,
  canAddSingleItemToCart,
} from "../../utils/canAddItemToCart";
import AddIcon from "@mui/icons-material/Add";

import RemoveIcon from "@mui/icons-material/Remove";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";

function BreakfastOrder() {

  const {
    cart,
    addToCart,
    reduceCount,
    clearCart,
    addCategoryProduct,
    addToCartBreakFast,
    removeFromCart,
    reduceCountBreakfast,
  } = useCart();

  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [currentDay, setCurrentDay] = useState("");
  const [isOrdered, setIsOrdered] = useState(false);

  const clickAddBt = (id, item, index) => {

    if (canAddSingleItemToCart(cart, item)) {
      setItems((prevItems) =>
        items.map((item) =>
          item.menu_id === id
            ? { ...item, class: "addedCount" }
            : { ...item, class: "" }
        )
      );

      const updatedItem = { ...item, class: "addedCount" };
      addToCartBreakFast(updatedItem, isOrdered);
      var element = document.getElementById("addAndOuntMain" + index);
      element.classList.toggle("addedCount");
    } else {
      alert(
        "Cannot add breakfast, lunch, or dinner items together in the same order."
      );
    }
  };

  const clickAddBtBreakfast = (id, item, index) => {

    if (canAddSingleItemToCart(cart, item)) {

      const freeItemInCart = cart.find(cartItem => cartItem.isfree);

      if (freeItemInCart) {
        removeFromCart(freeItemInCart.menu_id);
      }

      setItems((prevItems) =>
        displayItems.map((item) =>
          item.menu_id === id
            ? { ...item, class: "addedCount", class1: "activeOrd" }
            : { ...item, class: "", class1: "" }
        )
      );
      const updatedItem = { ...item, class: "addedCount", class1: "activeOrd" };
      addToCartBreakFast(updatedItem, isOrdered);
      var element = document.getElementById("addAndOuntMain" + index);
      element.classList.toggle("addedCount");

    } else {
      alert(
        "Cannot add breakfast, lunch, or dinner items together in the same order."
      );
    }
  };

  const clickAddBtExtra = (id, item, index) => {

    if (canAddSingleItemToCart(cart, item)) {

      // const freeItemInCart = cart.find(cartItem => cartItem.isfree);

      // if (freeItemInCart) {
      //   removeFromCart(freeItemInCart.menu_id);
      // }

      setItems((prevItems) =>
        displayItems.map((item) =>
          item.menu_id === id
            ? { ...item, class: "addedCount", class1: "activeOrd" }
            : { ...item, class: "", class1: "" }
        )
      );
      const updatedItem = { ...item, class: "addedCount", class1: "activeOrd" };
      addToCartBreakFast(updatedItem, isOrdered);
      var element = document.getElementById("addAndOuntMain" + index);
      element.classList.toggle("addedCount");

    } else {
      alert(
        "Cannot add breakfast, lunch, or dinner items together in the same order."
      );
    }
  };

  const increment = (id, item) => {
    setItems((prevItems) =>
      items.map((item) =>
        item.menu_id === id ? { ...item, count: item.count + 1 } : item
      )
    );
    addToCartBreakFast(item);
  };

  const incrementBreakfast = (id, item) => {
    setItems((prevItems) =>
      items.map((item) =>
        item.menu_id === id ? { ...item, count: item.count + 1 } : item
      )
    );
    addToCartBreakFast(item);
  };

  const decrement = (id, item) => {

    setItems((prevItems) =>
      // prevItems.map((item) =>
      //   item.menu_id === id
      //     ? {
      //       ...item,
      //       count: Math.max(
      //         item.count - 1,
      //         Math.max(0, 0)
      //       ),
      //     }
      //     : item
      // )
      displayItems.map((item) => {
        if (item.menu_id === id) {
          const newCount = Math.max(item.count - 1, 0);
          const updatedItem = {
            ...item,
            count: newCount,
            class: "",
          };
          console.log("New Count " + newCount);
          reduceCount(item);
          if (newCount === 0) {
            removeFromCart(item.menu_id);
          }
          return updatedItem;
        }
        return item;
      })
    );
    // reduceCount(item);
    // removeFromCart(item.menu_id)
  };

  const decrementBreakfast = (id, item) => {

    // setItems((prevItems) =>
    //   prevItems.map((item) =>
    //     item.menu_id === id
    //       ? {
    //         ...item,
    //         count: Math.max(
    //           item.count - 1,
    //           Math.max(item.freecount || 1, item.complementorycount || 1)
    //         ),
    //       }
    //       : item
    //   )
    // );
    // reduceCountBreakfast(item);

    setItems((prevItems) =>
      prevItems.map((item) => {
        if (item.menu_id === id) {
          const newCount = Math.max(item.count - 1, 0);
          const updatedItem = {
            ...item,
            count: newCount === 0 ? 1 : newCount,
            class: "",
            class1: "",
          };
          reduceCountBreakfast(item);
          if (newCount === 0) {
            removeFromCart(item.menu_id);
          }
          return updatedItem;
        }
        return item;
      })
    );
  };

  async function getItemsList(day) {

    await makeGetRequest("starcafe/getmenulistbytype/" + day + "/Breakfast")
      .then((response) => {
        if (response.data.data) {
          const updatedData = response.data.data.map((item) => {
            let count = 1;
            if (item.iscomplementory) {
              count = item.complementorycount || 1;
            } else if (item.isfree) {
              count = item.freecount || 1;
            }
            return {
              ...item,
              count,
              class: "",
              class1: "",
              type: ORDER_TYPE_BREAKFAST,
            };
          });
          setItems(updatedData);
        } else {
          setItems([]);
        }
      })
      .catch((err) => {
        setItems([]);
      });
  }

  const getCurrentDay = () => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDate = new Date();
    const dayIndex = currentDate.getDay();
    let dayName;
    if(dayIndex==6)
      dayName = days[0];
    else
      dayName = days[dayIndex + 1];
    getItemsList(dayName);
    //return dayName;
  };

  const totals =
    cart && cart.length > 0
      ? cart.reduce(
        (sums, item) => {
          sums.total_star_price += (item.Star_price || 0) * item.count;
          sums.total_price_in_stars +=
            (item.price_in_stars || 0) * item.count;
          return sums;
        },
        { total_star_price: 0, total_price_in_stars: 0 }
      )
      : { total_star_price: 0, total_price_in_stars: 0 };

  const { total_star_price, total_price_in_stars } = totals;
  console.log(moment().format("YYYY-MM-DD"))
  
  async function gettodayorder() {

    let id = localStorage.getItem("userId");

    await makeGetRequest(
      "starcafe/getorderbydateemployeeid/" +
      id +
      "/" +
      moment().format("YYYY-MM-DD") +
      "/Breakfast"
    )
      .then((response) => {
        if (response.data) {
          if (response.data.data.length > 0) {
            setIsOrdered(false);
          } else {
            setIsOrdered(true);
          }
        } else {
          setIsOrdered(false);
        }
      })
      .catch((err) => { });
  };

  useEffect(() => {
    gettodayorder();
    getCurrentDay();
  }, []);

  const mergeItems = (items, cart) => {
    const cartMap = new Map(cart.map((item) => [item.menu_id, item]));
    const mergedItems = items.map((item) =>
      cartMap.has(item.menu_id) ? cartMap.get(item.menu_id) : item
    );
    return mergedItems;
  };

  // console.log("items")
  // console.log(items)
  const displayItems = mergeItems(items, cart);
  // console.log("displayItems break fast");
  // console.log(displayItems);

  return (
    <>
      <div className="mobileCssMain">
        <div className="fixedBottomPadding">
          <div className="container">
            <div className="wrapper">
              <div className="screenTitle">
                <Button onClick={() => navigate("/DashboardPage")}>
                  <IconButton className="TouchableIconBtn"> <ArrowBackIosIcon />
                  </IconButton>
                </Button>
                <h2>Order Breakfast</h2>
              </div>

              <div className="breakfastData">

                <div className="thaliImg">
                  <img src={breakfastImg} />
                </div>

                <div className="titleBelowImg">
                  <div class="ordIcoDiv cupIcon">
                    <img src={cup} />
                  </div>
                  <h3>Breakfast Booking</h3>
                </div>

                <div className="breakfastP">
                  <p>
                    Along with tea, you will receive one complimentary item for
                    free.
                  </p>
                </div>

                {displayItems.length > 0 ? (
                  <Grid className="counter" container spacing={2}>
                    {displayItems.map((item, index) => (
                      <>
                        {/* && item.menu_type == null temporary this condition is removed [0].includes('Special') */}
                        {!item.category_type[0].includes("Special") ? (
                          <Grid key={index} item xs={12}>
                            {item.isfree === true ? (
                              <>
                                <div
                                  key={index}
                                  className={"breakfastBtn forCartAlign kailasIncreDi " + item.class1}
                                >
                                  {/* <CloseIcon className="closeIcon" /> */}
                                  <h5>{item.menu_name}</h5>
                                  {isOrdered ?
                                    <>
                                      <div className="newContTopSpace">
                                        <CoffeeIcon className="CoffeeIconK" />
                                        <div
                                          className={
                                            "countWithAddBtMain " + item.class
                                          }
                                          id={`addAndOuntMain` + index}
                                        >
                                          <div className="newCountSt">
                                            {/* {(item.check == "true") ? */}
                                            <Button
                                              className="beforeAddBt"
                                              // id={"add_" + index}
                                              onClick={() =>
                                                clickAddBtBreakfast(
                                                  item.menu_id,
                                                  item,
                                                  index
                                                )
                                              }
                                            >
                                              Add
                                            </Button>
                                            {/* : null} */}
                                            <div className="outerIncdec">
                                              {/* <Button
                                            onClick={() =>
                                              decrementBreakfast(item.menu_id, item)
                                            }
                                            className="IncDrcBtn"
                                          >
                                            <RemoveCircleOutlineOutlinedIcon />
                                          </Button> */}
                                              <h2 className="breakFtICo">
                                                <b>{item.count}</b>
                                                <CoffeeIcon />
                                              </h2>
                                              {/*<Button
                                            onClick={() =>
                                              incrementBreakfast(item.menu_id, item)
                                            }
                                            className="IncDrcBtn"
                                          >
                                            <ControlPointOutlinedIcon />
                                          </Button>*/}
                                            </div>
                                          </div>
                                        </div>
                                        <CookieIcon className="CoffeeIconK" />
                                      </div>
                                    </> : <p>Order Placed for the day</p>}
                                </div>
                              </>
                            ) : (
                              null
                            )}
                          </Grid>
                        ) : null}
                      </>
                    ))}
                  </Grid>
                ) : (
                  <div className="menuNotAvailMain">
                    <img src={menuNot} />
                    <p>Menu Not Available</p>
                  </div>
                )}
              </div>

              <div className="breakfastData">
                <div className="breakfastP">
                  <p>
                    Extra Items
                  </p>
                </div>
                {displayItems.length > 0 ? (
                  <Grid className="counter" container spacing={2}>
                    {displayItems.map((item, index) => (
                      <>
                        {!item.category_type[0].includes("Special") ? (
                          <Grid key={index} item xs={12}>
                            {item.isfree !== true ? (
                              <>
                                <div
                                  key={index}
                                  className={"breakfastBtn forCartAlign kailasIncreDi " + item.class1}
                                >
                                  <h5>{item.menu_name}</h5>

                                  <div className="newContTopSpace">
                                    <CoffeeIcon className="CoffeeIconK" />
                                    <div
                                      className={
                                        "countWithAddBtMain " + item.class
                                      }
                                      id={`addAndOuntMain` + index}
                                    >
                                      <div className="newCountSt">
                                        {/* {(item.check == "true") ? */}
                                        <Button
                                          className="beforeAddBt"
                                          // id={"add_" + index}
                                          onClick={() =>
                                            clickAddBtExtra(
                                              item.menu_id,
                                              item,
                                              index
                                            )
                                          }
                                        >
                                          Add
                                        </Button>
                                        <div className="outerIncdec">
                                          <Button
                                            onClick={() =>
                                              decrementBreakfast(item.menu_id, item)
                                            }
                                            className="IncDrcBtn"
                                          >
                                            <RemoveCircleOutlineOutlinedIcon />
                                          </Button>
                                          <h2 className="breakFtICo">
                                            <b>{item.count}</b>
                                            <CoffeeIcon />
                                          </h2>
                                          <Button
                                            onClick={() =>
                                              incrementBreakfast(item.menu_id, item)
                                            }
                                            className="IncDrcBtn"
                                          >
                                            <ControlPointOutlinedIcon />
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                    <CookieIcon className="CoffeeIconK" />
                                  </div>

                                </div>
                              </>
                            ) : (
                              null
                            )}
                          </Grid>
                        ) : null}
                      </>
                    ))}
                  </Grid>
                ) : (
                  null
                )}
              </div>
            </div>
          </div>

          {/* special menu item */}
          <div className="wrapper">
            <div className="todaysSpecial">
              <div className="todaysSpecialData">
                <div class=" ">
                  <img src={mealMenu2} />
                </div>
                <p>Today's Special</p>
              </div>

              {displayItems.length > 0 ? (
                <div>
                  {displayItems.map((item, index) => (
                    <>
                      {item.category_type[0].includes("Special") ? (
                        <div className="orderNotiDiv specialMenu specialMenuNew specialInc">
                          <div className="newSpecialOf">
                            <div>
                              <h3>{item.menu_name}</h3>
                              <p>Product Id #{item.menu_id}</p>
                              <div className="starPrice">
                                <StarIcon className="starIcon" />
                                <h5>{item.price_in_stars} </h5>
                              </div>
                            </div>

                            <div
                              className={"countWithAddBtMain " + item.class}
                              id={`addAndOuntMain` + index}
                            >
                              <div className="newCountSt">
                                <Button
                                  className="beforeAddBt"
                                  onClick={() =>
                                    clickAddBt(item.menu_id, item, index)
                                  }
                                >
                                  <AddIcon />
                                </Button>

                                <div className="outerIncdec">
                                  <Button
                                    onClick={() =>
                                      decrement(item.menu_id, item)
                                    }
                                    className="IncDrcBtn"
                                  >
                                    <RemoveIcon />
                                  </Button>
                                  <b>{item.count}</b>
                                  <Button
                                    onClick={() =>
                                      increment(item.menu_id, item)
                                    }
                                    className="IncDrcBtn"
                                  >
                                    <AddIcon />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ))}
                </div>
              ) : (
                <div className="menuNotAvailMain">
                  <img src={menuNot} />
                  <p>Menu Not Available</p>
                </div>
              )}
            </div>
          </div>

          <div className="container">
            <div className="fixedBtnOuter">
              <Button
                className="loginBt"
                onClick={() =>
                  navigate("/OrderSummary", {
                    state: {
                      items: items,
                      Fromlocation: "Star Cafe",
                      type: "Breakfast",
                    },
                  })
                }
              >
                <p style={{ marginRight: "60px" }}>
                  {cart.length} Item | {total_star_price + total_price_in_stars}{" "}
                  Stars
                </p>
                <p>View Cart</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BreakfastOrder;
