import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import { Button, Grid, Box } from "@mui/material";
import mealImg1 from "../../../assets/images/mealImg1.svg";
import mealImg2 from "../../../assets/images/mealImg2.svg";
import mealImg3 from "../../../assets/images/mealImg3.svg";
import mealMainimg1 from "../../../assets/images/mealMainimg1.svg";
import mealMainimg2 from "../../../assets/images/mealMainimg2.svg";
import editIco from "../../../assets/images/edit-icon.svg";
import StarIcon from "@mui/icons-material/Star";
import deletIco from "../../../assets/images/delete-icon.svg";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import UploadIcon from "@mui/icons-material/Upload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import mealAcordingIcon from "../../../assets/images/mealAcordingIcon.svg";
import DataTable from "react-data-table-component";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import RemoveIcon from "@mui/icons-material/Remove";
import Pagination from "@mui/material/Pagination";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import productImg from "../../../assets/images/productImg.svg";
import mealAcordingIcon2 from "../../../assets/images/mealAcordingIcon2.svg";
import mealAcordingIcon3 from "../../../assets/images/mealAcordingIcon3.svg";
import mealAcordingIcon4 from "../../../assets/images/mealAcordingIcon4.svg";
import mealMainimg3 from "../../../assets/images/mealMainimg3.svg";
import ImportExportIcon from "@mui/icons-material/ImportExport";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import vegiPro from "../../../MobileScreens/assets/images/breakfastImg.svg";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
// End

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import {
  makeGetRequest,
  makePostRequest,
  uploadFileRequest,
} from "../../../utils/utils";
import { Upload } from "@mui/icons-material";
import moment from "moment";
import swal from "sweetalert";
import loaderImg from "../../../assets/images/logo.png";

// For Filter
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// For Filter End

import TaskAltIcon from '@mui/icons-material/TaskAlt';


function VeggiesTab() {
  const navigate = useNavigate();

  // ScanPop
  const [ScanPop, setOpenScanPop] = React.useState(false);

  const handleClickScanPop = (scrollType) => () => {
    setOpenScanPop(true);
    setScroll(scrollType);
  };

  const closeScanPop = () => {
    setOpenScanPop(false);
  };
  // End

  // For Filter
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // For Filter End

  const [selected, setSelected] = useState(false);
  const [vegitableObjectList, setVegitableObjectList] = useState([]);
  const [DeductVeggies, setOpenDeductVeggies] = useState(false);
  const [AddVegetable, setOpenAddVegetable] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [sortBy, setSortBy] = useState("desc");
  const [pageNo, setPageNo] = useState(1);
  const [sortColumnName, setSortColumnName] = useState("created_at");
  const [showLoader, setShowLoader] = useState(false);
  const [veggiesList, setVeggiesList] = useState([]);
  const [alignment, setAlignment] = useState("");
  const [vegitableErrMsg, setVegitableErrMsg] = useState("");
  const [vegitableImage, setVegitableImage] = useState([]);
  const [upload, setUpload] = useState(false);
  const [openEditVegetable, setOpenEditVegetable] = useState(false);
  const [lstVegitableNames, setLstVegitableNames] = useState([]);
  const [isVegListVerified, setIsVegListVerified] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [uploadImg, setUploadImg] = React.useState([]);
  const [imageErrMsg, setImageErrMsg] = React.useState("");

  const [offsetvalue, setoffsetvalue] = React.useState(0);
  const [itemsPerPage] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState(0);
  const [duplicateNameErrMsg, setDuplicateNameErrMsg] = React.useState("");

  const [inputGrossaryData, setInputGrossaryData] = React.useState('');
  const [grossaryItems, setGrossaryItems] = React.useState([]);
  const[qrVal,setQrVal]=useState("")


  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleCloseEditVegetable = () => {
    setOpenEditVegetable(false);
    setDuplicateNameErrMsg("");
    setVegitableImage([]);
    setDuplicateNameErrMsg("");
  };
  // Edit Vegetable End

  // Deduct Veggies
  const [isDeductVeggies, setIsDeductVeggies] = useState(null);
  const handleClickOpenDeductVeggies = (scrollType, isDeduct) => {
    setOpenDeductVeggies(true);
    setScroll(scrollType);
    setIsDeductVeggies(isDeduct);
  };

  const closeDeductVeggies = () => {
    setOpenDeductVeggies(false);
  };

  const descriptionElementRef = useRef(null);
  // End

  // Add Items to Kitchen
  const [AddToKitchen, setOpenAddToKitchen] = React.useState(false);

  const handleClickAddToKitchen = (scrollType) => () => {
    setOpenAddToKitchen(true);
    setScroll(scrollType);
  };

  const closeAddToKitchen = () => {
    setOpenAddToKitchen(false);
  };
  // End

  //Modal AddVegetable
  const handleClickOpenAddVegetable = (scrollType) => () => {
    setOpenAddVegetable(true);
    setScroll(scrollType);
  };

  const closeAddVegetable = () => {
    setOpenAddVegetable(false);
    setVegitableObjectList([]);
    setVegitableImage([]);
    setDuplicateNameErrMsg("");
  };
  // End

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const initialValues = {
    // Add Vegetable
    vegitable_name: "",
    price_per_kg: 0,
    total_qty: 0,
    total_price: 0,
    low_stock_limit: 0,
    wt_unit: "",
  };

  const validationSchema = Yup.object({
    // Add Vegetable
    vegitable_name: Yup.string().required("Required"),
    price_per_kg: Yup.number().typeError("Invalid Number").required("Required"),
    total_qty: Yup.number()
      .typeError("Invalid Number")
      .moreThan(
        Yup.ref("low_stock_limit"),
        "value must be greater than low stock limit"
      )
      .required("Required"),
    total_price: Yup.number().typeError("Invalid Number").required("Required"),
    low_stock_limit: Yup.number()
      .typeError("Invalid Number")
      .required("Required"),
    wt_unit: Yup.string().required("Required"),
  });

  const dropdownOptionsVegi = [
    { key: "Select an option", value: "" },
    { key: "Kilograms", value: "Kilograms" },
    { key: "Miligrams", value: "Miligrams" },
    { key: "Gram", value: "Gram" },
    { key: "Tonne", value: "Tonne" },
  ];

  // Delete Image Start
  const deleteImg = (vegitable_id) => {
    setShowLoader(true);
    vegitableImage.splice(vegitable_id, 1);
    setVegitableImage(vegitableImage);
    setShowLoader(false);
  };

  // Delete Image End

  // upload image start
  async function uploadImageOrFile(fileData) {
    var fileUrl = "";
    try {
      setShowLoader(true);
      const formData = new FormData();
      formData.append("image", fileData);

      await uploadFileRequest("superadmin/upload", formData).then(
        (response) => {
          console.log(response);
          fileUrl = response.data.imagepath;
        }
      );
      setShowLoader(false);
    } catch (error) {
      console.log(error);
    }
    return fileUrl;
  }

  async function validateImage(fileData) {
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|jfif|JFIF)$/i))
      return true;
    else return false;
  }

  async function uploadVegitableImage(e) {
    setVegitableErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];
    if (await validateImage(file)) {
      var imageUrl = await uploadImageOrFile(file);
      vegitableImage.push(imageUrl);
      setVegitableImage(vegitableImage);
    } else {
      setVegitableErrMsg("Invalid file format");
    }
    setShowLoader(false);
  }

  // upload image end

  // update vegetable api start
  const updateVeggies = (values) => {
    console.log(values);

    let dupRes = veggiesList.find(
      (o) =>
        o.vegitable_name.toLowerCase() === values.vegitable_name.toLowerCase()
    );

    console.log(dupRes);
    if (dupRes && dupRes.vegitable_id != values.vegitable_id) {
      setDuplicateNameErrMsg("Already Exist");
    } else {
      values.wt_unit = alignment;
      makePostRequest("starcafe/updateveggies", values)
        .then((response) => {
          if (response.data) {
            console.log(response);
            console.log("Success");
            handleCloseEditVegetable(true);
          }
          getVeggiesList(null);
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
          swal("Veggies", err.data.message, "Veggies", {
            timer: 3000,
            buttons: false,
          });
        });
      setDuplicateNameErrMsg("");
    }
  };
  // update vegetable api end

  // edit vagetable start
  const handleClickEditVegetable = (veggiesObj, scrollType) => {
    console.log("data", veggiesObj);
    setAlignment(veggiesObj.wt_unit);
    setFormValues(veggiesObj);
    setOpenEditVegetable(true);
    setScroll(scrollType);
    setUpload(!Upload);
    setVegitableImage(veggiesObj.vegitable_image);
    setVegitableErrMsg("");
  };
  // edit vegetable end

  function handleChangeTextBox(type, index, value) {
    let tempVegList = [...vegitableObjectList];
    if (type == "vegitableName") tempVegList[index].vegitable_name = value;
    else if (type == "price_per_kg") tempVegList[index].price_per_kg = value;
    else if (type == "total_qty") tempVegList[index].total_qty = value;
    else if (type == "total_price") tempVegList[index].total_price = value;
    else if (type == "low_stock_limit")
      tempVegList[index].low_stock_limit = value;
    else if (type == "wt_unit") tempVegList[index].wt_unit = value;

    setVegitableObjectList(tempVegList);
    console.log(vegitableObjectList);

    if (value == "") {
      document.getElementById(index).innerHTML = "Required";
      setIsVegListVerified(false);
    } else {
      var regex = /^[0-9]+$/;
      if (regex.test) {
        document.getElementById(index).innerHTML = "";
        setIsVegListVerified(true);
      } else {
        document.getElementById(index).innerHTML = "Invalid Value";
        setIsVegListVerified(false);
      }
    }
  }

  const addToCartVegitables = (values, { resetForm }) => {
    let tempDupRes = vegitableObjectList.find(
      (o) =>
        o.vegitable_name.toLowerCase() === values.vegitable_name.toLowerCase()
    );
    let dupRes = veggiesList.find(
      (o) =>
        o.vegitable_name.toLowerCase() === values.vegitable_name.toLowerCase()
    );
    if (dupRes || tempDupRes) setDuplicateNameErrMsg("Altready Exist");
    else {
      values.vegitable_image = vegitableImage;
      values.status = true;
      values.wt_unit = values.wt_unit.split("|||")[0];
      console.log(values);
      // console.log(values.wt_unit.split('|||')[0])

      vegitableObjectList.push(values);
      setVegitableObjectList(vegitableObjectList);
      console.log(vegitableObjectList);
      setUpload(!Upload);
      resetForm();
      setVegitableImage([]);
      setVegitableErrMsg("");
      setDuplicateNameErrMsg("");
    }
  };

  function removeFromCart(index) {
    let tempVegList = [...vegitableObjectList];
    tempVegList.splice(index, 1);
    setVegitableObjectList(tempVegList);
    // setTimeout(deleteMissMaintaince, 3000);
  }

  async function saveVegitablesData(values) {
    let addValues = true;
    console.log(values)
    if(vegitableObjectList.length<1 && values.vegitable_name=="")
    {
        
    }
    else
    {
    if (duplicateNameErrMsg) {
      swal(
        "Already Exist!",
        "There are some duplicate vegitable names, please change the name and try to save vegitables again ",
        "warning",
        {
          timer: 10000,
          buttons: false,
        }
      );
    } else {
      let warningMsg=""
      if( values.vegitable_name == "" || values.wt_unit == "")
      { 
        alert("In If")
          addValues=false;
          if(vegitableObjectList.length==0)
            warningMsg="Please fill all vegitable data."
          else
            warningMsg="Last vegitable information is incomplete, Do you really want to skip and continue ?";
      }
      // else if ( values.vegitable_name != "" || values.wt_unit != "" || values.total_qty != "" || values.price_per_kg != "" ||values.total_price != "") 
      // {  
      //     addValues=true;
      //     warningMsg="";
      // }
      // else{
      //   addValues=false;
      //   warningMsg="Last vegitable information is incomplete, Do you really want to skip and continue ?"
      // }

        //  await swal({
        //   title: "Are you sure?",
        //   text: warningMsg,
        //   icon: "warning",
        //   buttons: ["No", "Yes"],
        // }).then(function (isConfirm) {
        //   if (isConfirm) {
            setShowLoader(true);
            if(addValues==true)
            {
              values.vegitable_image = vegitableImage;
              values.status = true;
              values.wt_unit = values.wt_unit.split("|||")[0];
              vegitableObjectList.push(values);
              setVegitableObjectList(vegitableObjectList);
            }
            
            for (var i = 0; i < vegitableObjectList.length; i++) {
              makePostRequest("starcafe/insertveggies", vegitableObjectList[i])
                .then((response) => {
                  if (response.data.data) {
                    console.log(response.data);
                  }
                })
                .catch((err) => {
                  setShowLoader(false);
                  console.log(err);
                });
            }
            closeAddVegetable();
            setShowLoader(false);
          // }
        // });
      }
    }
  }

  const handlePageChange = (e, page) => {
    let offsetvalue = (page - 1) * itemsPerPage;
    setoffsetvalue(offsetvalue);
    setPageNo(page);
  };

  // Get Veggies List Start
  const getVeggiesList = async (searchString) => {
    // /getveggies/:column/:orderby/:search/:offsetValue
    if (!searchString) searchString = null;
    setShowLoader(true);
    await makeGetRequest(
      "starcafe/getveggies/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        searchString +
        "/" +
        offsetvalue +
        "/" +
        itemsPerPage
    )
      .then((response) => {
        if (response.data) {
          //setShowLoader(true);
          console.log("Veggies List", response.data.data);
          setVeggiesList(response.data.data.res);
          setTotalItems(response.data.data.totalCount);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  // Get Veggies List end

  // Sort By Veggies Start
  const handleChangeSortByVeggies = () => {
    //alert("In ASC")
    setSortColumnName("vegitable_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    //setUpload(!upload);
    getVeggiesList(null);
  };
  // Sort By Veggies End

  //Sort According to column Start
  function handleChangeSortByColumn(columnName) {
    setSortColumnName(columnName);
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    //console.log('columnName '+columnName+ ' selected '+selected +' sortBy '+sortBy);
    getVeggiesList(null);
  }
  //Sort According to column End

  // Status Veggies List Status Start
  const handleVeggiesStatus = (event, vegitable_id) => {
    console.log("status", event);
    let inputdata = {
      vegitable_id: vegitable_id,
      status: event.target.checked,
    };
    console.log("Status", inputdata);

    makePostRequest("starcafe/updateveggies", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          getVeggiesList(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Status Veggies List Status End
  //Delete Delete Veggies List  Start
  const deleteVeggies = async (vegitable_id) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Vegitable Deleted!",
          text: "Vegitable is successfully deleted!",
          icon: "success",
        }).then(function () {
          let inputdata = {
            vegitable_id: vegitable_id,
            is_deleted: true,
          };
          makePostRequest("starcafe/updateveggies", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                getVeggiesList(null);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        //swal("Cancelled", "Your Category is safe :)", "error");
      }
    });
  };
  //Delete Delete Veggies List  End

  // Check Quantity Start
  const checkQuantity = (value, quantity, index) => {
    const regex = /^[0-9\b]+$/;
    document.getElementById("span" + index).innerHTML = "";
    if (isDeductVeggies == true) {
      if (regex.test(value) && quantity >= value) {
        document.getElementById(index).innerHTML = quantity - value;
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    } else {
      if (regex.test(value)) {
        document.getElementById(index).innerHTML =
          parseInt(quantity) + parseInt(value);
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    }
  };
  // Check Quantity End

  // Deduct Quantity Start
  const deductVeggies = async (value, id, quantity, index) => {
    var regex = /^[0-9]+$/;
    document.getElementById("span" + index).innerHTML = "";
    if (value == "") {
      document.getElementById(index).innerHTML = quantity;
    } else {
      if (regex.test(value) && quantity >= value) {
        await swal({
          title: "Are you sure?",
          text: "Do you really want to move given quantity to kitchen?",
          icon: "warning",
          buttons: ["No", "Yes"],
        }).then(function (isConfirm) {
          if (isConfirm) {
            setShowLoader(true);
            let inputData = {
              vegitable_id: id,
            };
            inputData.total_qty = parseInt(
              document.getElementById(index).innerHTML
            );

            console.log("input", inputData);
            makePostRequest("starcafe/updateveggies", inputData)
              .then((response) => {
                if (response.data.data) {
                  swal(
                    "Success",
                    "Quantity moved to kitchen successfully",
                    "success",
                    {
                      timer: 3000,
                      buttons: false,
                    }
                  );
                  getVeggiesList(null);
                }
              })
              .catch((err) => {
                setShowLoader(false);
                console.log(err);
              });
            setShowLoader(false);
          }
        });
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    }
  };


  const updateQty = (sku, current_qty, new_qty, cqty, index) =>
    {

      makePostRequest("starcafe/scanveggies/" ,{sku:sku,current_qty:current_qty,new_qty:new_qty,total_qty:parseInt(current_qty)+parseInt(new_qty)})
        .then((res) => {

          if(res.data.data)
          {

            if(grossaryItems.some(grossary => grossary.sku === res.data.data[0].sku))
              {
                  let xcnt = grossaryItems.findIndex(grossary => grossary.sku === res.data.data[0].sku)
                  const updateItem = grossaryItems.filter((elem, ind)=>{
                  return ind !== xcnt
                  })
                  updateItem.unshift(res.data.data[0])
                  setGrossaryItems(updateItem)
              }
              else
              {
                setGrossaryItems(res.data.data)
                grossaryItems.unshift(res.data.data[0])
                setGrossaryItems(grossaryItems)
              }


            // let data = [...grossaryItems];
            // let resx = parseInt(current_qty) - parseInt(cqty);
            // data[index].new_qty = resx
            // setInputGrossaryData(res.data.data)
            // grossaryItems.unshift(res.data.data[0])
            
            // setGrossaryItems(grossaryItems)
            // setQrVal('')

          }
          else
          {
            swal({
              title: "Grocery Not Available!",
              text: "Grocery ",
              icon: "success",
              timer: 3000,
            })
          }
  

        })



    }

  const handleqrqty = (event, index) =>
    {
      let data = [...grossaryItems];
      data[index].new_qty = event.target.value;
      setGrossaryItems(data);
    }



    async function addByScan()
    {
        let sku=document.getElementById('scanText').value;
        
        if(sku)
        {
            await makeGetRequest("starcafe/getveggiesbysku/" +sku)
          .then((response) => {
          console.log(response)
          if (response.data.data && response.data.data.length > 0) {
  
          makePostRequest("starcafe/scanveggies/" ,{sku:sku,current_qty:response.data.data[0].total_qty,new_qty:1,total_qty:parseInt(response.data.data[0].total_qty)+1})
          .then((res) => {
  
            if(res.data.data){


              setInputGrossaryData(res.data.data)
              if(grossaryItems.some(grossary => grossary.sku === res.data.data[0].sku))
              {
                let xcnt = grossaryItems.findIndex(grossary => grossary.sku === res.data.data[0].sku)
              const updateItem = grossaryItems.filter((elem, ind)=>{
                return ind !== xcnt
              })
              updateItem.unshift(res.data.data[0])
              setGrossaryItems(updateItem)
            
              }
              else
              {
                setGrossaryItems(res.data.data)
                grossaryItems.unshift(res.data.data[0])
                setGrossaryItems(grossaryItems)
              } 
              
              setQrVal('')

              // setInputGrossaryData(res.data.data)
              // grossaryItems.unshift(res.data.data[0])
              // setGrossaryItems(grossaryItems)
              // setQrVal('')
            }
            else
            {
              swal({
                title: "Grocery Not Available!",
                text: "Grocery ",
                icon: "success",
                timer: 3000,
              })
            }
    
  
          })
        
          }
          else
          {

            swal({
              title: "Veggies are Not Available!",
              text: "Please add new veggies ",
              icon: "success",
              timer: 3000,
            })

          }
          setShowLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });
        }
        else
        {
  
        }
      
    }


  // Deduct Quantity End
  // Add Quantity Start
  const addVeggies = async (value, id, quantity, index) => {
    var regex = /^[0-9]+$/;
    document.getElementById("span" + index).innerHTML = "";
    if (value == "") {
      document.getElementById(index).innerHTML = quantity;
    } else {
      if (regex.test(value)) {
        await swal({
          title: "Are you sure?",
          text: "Do you really want to Add given quantity to kitchen?",
          icon: "warning",
          buttons: ["No", "Yes"],
        }).then(function (isConfirm) {
          if (isConfirm) {
            setShowLoader(true);
            let inputData = {
              vegitable_id: id,
            };
            inputData.total_qty = parseInt(
              document.getElementById(index).innerHTML
            );

            console.log("input", inputData);
            makePostRequest("starcafe/updateveggies", inputData)
              .then((response) => {
                if (response.data.data) {
                  swal(
                    "Success",
                    "Quantity Add to kitchen successfully",
                    "success",
                    {
                      timer: 3000,
                      buttons: false,
                    }
                  );
                  getVeggiesList(null);
                }
              })
              .catch((err) => {
                setShowLoader(false);
                console.log(err);
              });
            setShowLoader(false);
          }
        });
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    }
  };
  // Add Quantity End
  useEffect(() => {
 
    getVeggiesList(null);
    if (AddVegetable) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (DeductVeggies) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [AddVegetable, DeductVeggies, upload, sortColumnName, pageNo]);

  return (
    <>
      <div className="starCafeTabInner">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <div className="listingSearchAndFilterMain">
              <div className="prodSearchDiv">
                <input
                  type="text"
                  className="prodSearchInpt"
                  placeholder="Search"
                  onChange={(e) => getVeggiesList(e.target.value)}
                />
              </div>
              <div className="sortingIcoOut">
                <ToggleButton
                  value="check"
                  selected={selected}
                  onChange={() => {
                    handleChangeSortByVeggies();
                  }}
                >
                  <ImportExportIcon
                    className="visitorFilterIco"
                    aria-haspopup="true"
                  />
                </ToggleButton>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} lg={9} className="addImpotBtOuter">

          <Button
              className="pageTopMainBt"
              onClick={handleClickScanPop("body")}
            >
              <AddIcon /> Add Veggies Inventory
            </Button>
            
            <Button
              className="pageTopMainBt"
              onClick={() => handleClickOpenDeductVeggies("body", false)}
            >
              <AddIcon /> Add to Kitchen
            </Button>

            <Button
              className="pageTopMainBt"
              onClick={() => handleClickOpenDeductVeggies("body", true)}
            >
              <RemoveIcon /> Move to Kitchen
            </Button>
            <Button
              className="pageTopMainBt"
              onClick={handleClickOpenAddVegetable("body")}
            >
              <AddIcon /> Add Vegetable
            </Button>
            <Button
              className="pageTopMainBt forImportB"
              component="label"
              variant="contained"
            >
              <UploadIcon />
              <VisuallyHiddenInput type="file" />
            </Button>
          </Grid>
          <Grid item xs={12} lg={12}>
            <div className="serviceistOuter">
              {veggiesList.map((veggiesObj) => (
                <div className="mainListDiv" key={veggiesObj.vegitable_id}>
                  <div className="mainListCont">
                    <h1
                      onClick={() =>
                        navigate(
                          "/VegetableProduuctDetails/" + veggiesObj.vegitable_id
                        )
                      }
                    >
                      {veggiesObj.vegitable_name}
                    </h1>
                    <p>ID #{veggiesObj.vegitable_id}</p>
                    <span className="dateStNew">
                      Added on{" "}
                      {moment(veggiesObj.created_at).format("DD/MM/YYYY")}
                    </span>
                  </div>

                  <div className="mainListCont mainListInrCont">
                    <div className="smalShortingOuter">
                      <label>In Stock</label>
                      <ToggleButton
                        value="check"
                        selected={selected}
                        onChange={() => handleChangeSortByColumn("total_qty")}
                        className="smalSho"
                      >
                        <ImportExportIcon
                          className="smalShorting"
                          aria-haspopup="true"
                        />
                      </ToggleButton>
                    </div>
                    <span className="mainListSubTx">
                      {veggiesObj.total_qty}
                    </span>
                  </div>

                  <div className="mainListCont mainListInrCont">
                    <div className="smalShortingOuter">
                      <label>Stock Limit</label>
                      <ToggleButton
                        value="check"
                        selected={selected}
                        onChange={() =>
                          handleChangeSortByColumn("low_stock_limit")
                        }
                        className="smalSho"
                      >
                        <ImportExportIcon
                          className="smalShorting"
                          aria-haspopup="true"
                        />
                      </ToggleButton>
                    </div>
                    <span className="mainListSubTx">
                      {veggiesObj.low_stock_limit}
                    </span>
                  </div>

                  <div className="mainListCont mainListInrCont">
                    <div className="smalShortingOuter">
                      <label>Quantity In kg</label>
                      <ToggleButton
                        value="check"
                        selected={selected}
                        onChange={() => handleChangeSortByColumn("total_qty")}
                        className="smalSho"
                      >
                        <ImportExportIcon
                          className="smalShorting"
                          aria-haspopup="true"
                        />
                      </ToggleButton>
                    </div>
                    <span className="mainListSubTx">
                      {veggiesObj.total_qty}
                      {veggiesObj.wt_unit}
                    </span>
                  </div>

                  <div className="mainListCont mainListInrCont">
                    <div className="smalShortingOuter">
                      <label>Price Per Item in INR (₹)</label>
                      <ToggleButton
                        value="check"
                        selected={selected}
                        onChange={() =>
                          handleChangeSortByColumn("price_per_kg")
                        }
                        className="smalSho"
                      >
                        <ImportExportIcon
                          className="smalShorting"
                          aria-haspopup="true"
                        />
                      </ToggleButton>
                    </div>
                    <span className="mainListSubTx">
                      ₹ {veggiesObj.price_per_kg}
                    </span>
                  </div>

                  <div className="mainListCont mainListInrCont">
                    <div className="smalShortingOuter">
                      <label>Total Price in INR (₹)</label>
                      <ToggleButton
                        value="check"
                        selected={selected}
                        onChange={() => handleChangeSortByColumn("total_price")}
                        className="smalSho"
                      >
                        <ImportExportIcon
                          className="smalShorting"
                          aria-haspopup="true"
                        />
                      </ToggleButton>
                    </div>
                    <span className="mainListSubTx">
                      ₹ {veggiesObj.total_price}
                    </span>
                  </div>

                  <div className="mainListCont mainListInrCont">
                    <div className="actionBtnOut">
                      {/* <Tooltip title="Edit" arrow placement="top">
                          <IconButton>
                            <img src={printIco} />
                          </IconButton>
                        </Tooltip> */}

                      <Tooltip title="Edit" arrow placement="top">
                        <IconButton
                          onClick={() =>
                            handleClickEditVegetable(veggiesObj, "body")
                          }
                        >
                          <img src={editIco} />
                        </IconButton>
                      </Tooltip>

                      <Switch
                        {...label}
                        checked={veggiesObj.status}
                        className="swicthMain"
                        onChange={(event) =>
                          handleVeggiesStatus(event, veggiesObj.vegitable_id)
                        }
                      />

                      <Tooltip title="Delete" arrow placement="top">
                        <IconButton
                          onClick={() => deleteVeggies(veggiesObj.vegitable_id)}
                        >
                          <img src={deletIco} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))}

            </div>
          </Grid>
          <Grid item xs={12} md={12} className="paginationOuterMain">
            <Pagination
              count={Math.ceil(totalItems / itemsPerPage)}
              page={pageNo}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </Grid>
        </Grid>
      </div>

      {/*  Add Vegetable */}
      <div>
        <Dialog
          open={AddVegetable}
          onClose={closeAddVegetable}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryVeryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Vegetable
            <CloseIcon className="modalCloseBt" onClick={closeAddVegetable} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={addToCartVegitables}
                      enableReinitialize
                    >
                      {(formik) => (
                        <Form>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                              <div className="productDetails groceryTableMain forVeggiErrorTx">
                                <table width="100%">
                                  <thead>
                                    <tr>
                                      <th>Upload Image</th>
                                      <th>Enter Vegetable Name</th>
                                      <th>Enter Price Per Kg in INR (₹)</th>
                                      <th>Enter Weight/Qty</th>
                                      <th>Enter Total Price</th>
                                      <th>Enter Low Stock Limit</th>
                                      <th>Select Weight Unit</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {vegitableObjectList.length > 0 ? (
                                      <>
                                        {vegitableObjectList.map(
                                          (vegitableObj, index) => (
                                            <tr>
                                              <td>
                                                <div className="veggiImgPreviewOuter">
                                                  {vegitableObj.vegitable_image.map(
                                                    (option) => (
                                                      <div className="veggiImgPreview">
                                                        <img src={option} />
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                                {vegitableObj.vegitable_image
                                                  .length < 4 ? (
                                                  <Button
                                                    className="pageTopMainBt blueBt"
                                                    component="label"
                                                    variant="contained"
                                                  >
                                                    <UploadIcon /> Choose Image
                                                    <VisuallyHiddenInput
                                                      type="file"
                                                      onChange={(event) =>
                                                        uploadVegitableImage(
                                                          event
                                                        )
                                                      }
                                                    />
                                                  </Button>
                                                ) : (
                                                  <></>
                                                )}
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  placeholder="Vegitable Name"
                                                  className="prodSearchInpt"
                                                  value={
                                                    vegitableObj.vegitable_name
                                                  }
                                                  id="veg1"
                                                  onChange={(e) =>
                                                    handleChangeTextBox(
                                                      "vegitableName",
                                                      index,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                                <span id={index}></span>
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  placeholder="Price per kg"
                                                  className="prodSearchInpt"
                                                  value={
                                                    vegitableObj.price_per_kg
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeTextBox(
                                                      "price_per_kg",
                                                      index,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                                <span id={index}></span>
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  placeholder="Weight/Quantity"
                                                  className="prodSearchInpt"
                                                  value={vegitableObj.total_qty}
                                                  onChange={(e) =>
                                                    handleChangeTextBox(
                                                      "total_qty",
                                                      index,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                                <span id={index}></span>
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  placeholder="Price"
                                                  className="prodSearchInpt"
                                                  value={
                                                    vegitableObj.total_price
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeTextBox(
                                                      "total_price",
                                                      index,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                                <span id={index}></span>
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  placeholder="low_stock_limit"
                                                  className="prodSearchInpt"
                                                  value={
                                                    vegitableObj.low_stock_limit
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeTextBox(
                                                      "low_stock_limit",
                                                      index,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                                <span id={index}></span>
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  placeholder="Wt per unit"
                                                  className="prodSearchInpt"
                                                  value={vegitableObj.wt_unit}
                                                  onChange={(e) =>
                                                    handleChangeTextBox(
                                                      "wt_unit",
                                                      index,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <div className="addVeggiBt">
                                                  <IconButton
                                                    type="submit"
                                                    onClick={() =>
                                                      removeFromCart(index)
                                                    }
                                                  >
                                                    <RemoveCircleOutlineIcon />
                                                  </IconButton>
                                                </div>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    <tr>
                                      <td>
                                        <div className="veggiImgPreviewOuter">
                                          {vegitableImage ? (
                                            <>
                                              {vegitableImage.map((option) => (
                                                <div className="veggiImgPreview">
                                                  <CloseIcon className="imCloIcon" />
                                                  <img src={option} />
                                                </div>
                                              ))}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                        {vegitableImage.length < 4 ? (
                                          <Button
                                            className="pageTopMainBt blueBt"
                                            component="label"
                                            variant="contained"
                                          >
                                            <UploadIcon /> Choose Image
                                            <VisuallyHiddenInput
                                              type="file"
                                              onChange={(event) =>
                                                uploadVegitableImage(event)
                                              }
                                            />
                                          </Button>
                                        ) : (
                                          <></>
                                        )}
                                        <span className="errorSt">
                                          {vegitableErrMsg}
                                        </span>
                                      </td>
                                      <td>
                                        <FormikControl
                                          control="input"
                                          type="text"
                                          placeholder="Vegitable Name"
                                          name="vegitable_name"
                                          className="prodSearchInpt"
                                        />
                                        <span className="errorSt">
                                          {duplicateNameErrMsg}
                                        </span>
                                      </td>
                                      <td>
                                        <FormikControl
                                          control="input"
                                          type="text"
                                          placeholder="Price"
                                          name="price_per_kg"
                                          className="prodSearchInpt"
                                        />
                                      </td>
                                      <td>
                                        <FormikControl
                                          control="input"
                                          type="text"
                                          // label="Enter Item Name"
                                          placeholder="Weight/Quantity"
                                          // labelClass="pageLabel"
                                          name="total_qty"
                                          className="prodSearchInpt"
                                        />
                                      </td>
                                      <td>
                                        <FormikControl
                                          control="input"
                                          type="text"
                                          // label="Enter Item Name"
                                          placeholder="Price"
                                          // labelClass="pageLabel"
                                          name="total_price"
                                          className="prodSearchInpt"
                                        />
                                      </td>
                                      <td>
                                        <FormikControl
                                          control="input"
                                          type="text"
                                          // label="Enter Item Name"
                                          placeholder="Price"
                                          // labelClass="pageLabel"
                                          name="low_stock_limit"
                                          className="prodSearchInpt"
                                        />
                                      </td>
                                      <td>
                                        <FormikControl
                                          control="select"
                                          type="text"
                                          // label="Enter Item Name"
                                          placeholder="Price"
                                          // labelClass="pageLabel"
                                          name="wt_unit"
                                          options={dropdownOptionsVegi}
                                          className="prodSearchInpt"
                                        />
                                      </td>
                                      <td>
                                        <div className="addVeggiBt">
                                          <IconButton type="submit">
                                            <AddCircleOutlineIcon />
                                          </IconButton>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={12}
                              style={{ textAlign: "center" }}
                            >
                              <span>
                                <Button
                                  className="modalBtSmallWidth"
                                  onClick={() =>
                                    saveVegitablesData(formik.values)
                                  }
                                >
                                  {" "}
                                  Add Vegetables
                                </Button>
                              </span>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                    {/* <Grid item xs={12} md={12}>
                      <div className="visitorSearch">
                        <div className="prodFilter">
                          <input
                            className="prodSearchInpt"
                            placeholder="Search"
                          />
                        </div>
                        <div className="sortingIcoOut">
                          <ToggleButton
                            value="check"
                            selected={selected}
                            onChange={() => {
                              setSelected(!selected);
                            }}
                          >
                            <ImportExportIcon
                              className="visitorFilterIco"
                              aria-haspopup="true"
                            />
                          </ToggleButton>
                        </div>
                      </div>
                    </Grid> */}
                    <Grid item xs={12} md={12}>
                      <div className="productDetails groceryTableMain">
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>Vagetable Name</th>
                              <th>Weight</th>
                              <th>Low Stock Limit</th>
                              <th>Price Per Kg in INR (₹)</th>
                              <th>Total Price in INR (₹)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {vegitableObjectList ? (
                              <>
                                {vegitableObjectList.map(
                                  (vegitableObj, index) => (
                                    <tr>
                                      <td>{vegitableObj.vegitable_name}</td>
                                      <td>
                                        {vegitableObj.total_qty}{" "}
                                        {vegitableObj.wt_unit}
                                      </td>
                                      <td>{vegitableObj.low_stock_limit}</td>
                                      <td>₹ {vegitableObj.price_per_kg}</td>
                                      <td>₹ {vegitableObj.total_price}</td>
                                    </tr>
                                  )
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Move To Kitchen */}
      <div>
        <Dialog
          open={DeductVeggies}
          // onClose={closeDeductVeggies}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            {isDeductVeggies ? (
              <>Move Vegitables from Kitchen</>
            ) : (
              <>Add Vegitables to Kitchen</>
            )}
            <CloseIcon className="modalCloseBt" onClick={closeDeductVeggies} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="visitorSearch">
                        <div className="prodFilter">
                          <input
                            type="text"
                            className="prodSearchInpt"
                            placeholder="Search"
                            onChange={(e) => getVeggiesList(e.target.value)}
                          />
                        </div>
                        <div className="sortingIcoOut">
                          <ToggleButton
                            value="check"
                            selected={selected}
                            onChange={() => {
                              handleChangeSortByVeggies();
                            }}
                          >
                            <ImportExportIcon
                              className="visitorFilterIco"
                              aria-haspopup="true"
                            />
                          </ToggleButton>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="productDetails groceryTableMain scrollBd">
                        <table width="100%" className="scrolldown">
                          <thead>
                            <tr>
                              <th>Vegitable Name</th>
                              {/* <th>Brand</th> */}
                              <th>Quantity In Stock</th>
                              <th>Purchase Price in INR (₹)</th>
                              {isDeductVeggies ? (
                                <th>Quantity To Deduct</th>
                              ) : (
                                <th>Quantity To Add</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {veggiesList.map((veggiesObj, index) => (
                              <tr>
                                <td>{veggiesObj.vegitable_name}</td>
                                {/* <td>Tea Brand</td> */}
                                <td id={index}>{veggiesObj.total_qty}</td>
                                <td>₹ {veggiesObj.total_price}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="qutyInput"
                                    placeholder="Quantity"
                                    onChange={(e) =>
                                      checkQuantity(
                                        e.target.value,
                                        veggiesObj.total_qty,
                                        index
                                      )
                                    }
                                    onBlur={(e) =>
                                      isDeductVeggies
                                        ? deductVeggies(
                                            e.target.value,
                                            veggiesObj.vegitable_id,
                                            veggiesObj.total_qty,
                                            index
                                          )
                                        : addVeggies(
                                            e.target.value,
                                            veggiesObj.vegitable_id,
                                            veggiesObj.total_qty,
                                            index
                                          )
                                    }
                                  />
                                  <div style={{ color: "red" }}>
                                    <span id={`span` + index}></span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} md={12} className="paginationOuterMain">
                      <Pagination
                        count={10}
                        variant="outlined"
                        shape="rounded"
                      />
                    </Grid> */}
                    {/* <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <Button className="modalBtSmallWidth">Move Item</Button>
                    </Grid> */}
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Edit Vegetable */}
      <div>
        <Dialog
          open={openEditVegetable}
          onClose={handleCloseEditVegetable}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Edit Vegetable
            <CloseIcon
              className="modalCloseBt"
              onClick={handleCloseEditVegetable}
            />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationSchema}
                    onSubmit={updateVeggies}
                    enableReinitialize
                  >
                    {(formik) => (
                      <Form>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12}>
                            <div className="addRemoveImagesBox">
                              {vegitableImage ? (
                                <>
                                  {vegitableImage.map((img, index) => (
                                    <div className="imageAddedBox" key={index}>
                                      <img src={img} />
                                      <div className="cloIm">
                                        <CloseIcon
                                          onClick={() => deleteImg(index)}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : null}
                              {vegitableImage.length < 4 ? (
                                <div className="uploadImBox">
                                  <h1 className="">Upload Image</h1>
                                  <Button
                                    className="uploadImBt"
                                    component="label"
                                    variant="contained"
                                    startIcon={<CloudUploadIcon />}
                                  >
                                    Upload
                                    <VisuallyHiddenInput
                                      type="file"
                                      onChange={(event) =>
                                        uploadVegitableImage(event)
                                      }
                                    />
                                  </Button>
                                  <span>or drag file in here</span>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              placeholder="Vegetable Name"
                              label="Enter Vegetable Name"
                              labelClass="pageLabel"
                              name="vegitable_name"
                              isRequired="true"
                              className="prodSearchInpt"
                            />
                            <span className="errorSt">
                              {" "}
                              {duplicateNameErrMsg}{" "}
                            </span>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Low Stock Limit"
                              placeholder="Stock Limit"
                              labelClass="pageLabel"
                              name="low_stock_limit"
                              isRequired="true"
                              className="prodSearchInpt"
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Price Per Kg"
                              placeholder="Price"
                              labelClass="pageLabel"
                              name="price_per_kg"
                              isRequired="true"
                              className="prodSearchInpt"
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Total Price"
                              placeholder="Price"
                              labelClass="pageLabel"
                              name="total_price"
                              isRequired="true"
                              className="prodSearchInpt"
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Weight/Quantity"
                              placeholder="Weight/Quantity"
                              labelClass="pageLabel"
                              name="total_qty"
                              isRequired="true"
                              className="prodSearchInpt"
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <label className="pageLabel">
                              Select Weight Unit
                            </label>
                            <div className="unitDivMain">
                              <div className="detailsTableFilterSec">
                                <ToggleButtonGroup
                                  value={alignment}
                                  exclusive
                                  onChange={handleAlignment}
                                  className="detailsTableFilterSecActionInr"
                                >
                                  <ToggleButton
                                    value="Kilograms"
                                    className="filterBtn"
                                  >
                                    KiloGrams
                                  </ToggleButton>

                                  <ToggleButton
                                    value="Miligrams"
                                    className="filterBtn"
                                  >
                                    MiliGrams
                                  </ToggleButton>

                                  <ToggleButton
                                    value="Gram"
                                    className="filterBtn"
                                  >
                                    Gram
                                  </ToggleButton>

                                  <ToggleButton
                                    value="Tonne"
                                    className="filterBtn"
                                  >
                                    Tonne
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </div>
                            </div>
                          </Grid>

                          <Grid item xs={12} md={12} className="lineBtns">
                            <Button
                              type="submit"
                              className="cancelEditBtn"
                              onClick={() => handleCloseEditVegetable()}
                            >
                              Cancel
                            </Button>
                            <Button type="submit" className="modalBtSmallWidth">
                              Update Vegetable
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add Vegetable Inventory */}
      <div>
        <Dialog
          open={ScanPop}
          // onClose={closeDeductProdct}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Vegetables Inventory
            <CloseIcon className="modalCloseBt" onClick={closeScanPop} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              // tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="visitorSearch">
                        <div className="prodFilter">
                          <input
                            type="text"
                            id="scanText"
                            className="prodSearchInpt"
                            placeholder="Scan SKU"
                            value={qrVal}
                            onChange={(e) =>setQrVal(e.target.value)}
                             onBlur={addByScan}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="productDetails groceryTableMain">
                      <table width="100%">
                          <thead>
                            <tr>
                              <th>SKU No.</th>
                              <th>Product Name</th>
                              <th>Existing Quantity</th>
                              <th>New Quantity</th>
                              <th>Total Quantity</th>
                              <th>Manage</th>
                            </tr>
                          </thead>
                          <tbody>
                          
                            {
                            grossaryItems.map((elem, index)=>(
                             
                              <tr key={index}>
                                <td>{elem.sku}</td> 
                                <td>{elem.vegitable_name}</td>
                                <td>{elem.current_qty}</td>
                                <td><input
                                    type="text"
                                    id="skuqty"
                                    className="qutyInput"
                                    value={elem.new_qty}
                                    onChange={(event) =>handleqrqty(event, index)}
                                    /></td>
                                    <td>{elem.total_qty}</td>
                                <td className="manageActIcons">
                                  <TaskAltIcon className="managMarkCheck" onClick={(event) =>updateQty(elem.sku, elem.total_qty, elem.new_qty,elem.current_qty, index)}/>
                                  
                                </td>
                              </tr>

                              
                            ))
                          
                          }

                            
                          </tbody>
                        </table>
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <Button className="modalBtSmallWidth">Add Item</Button>
                    </Grid> */}
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default VeggiesTab;
