import React from "react";
import Input from "./Input";
import Textarea from "./Textarea";
import Select from "./Select";
import AutoComplete from "./AutoComplete";
import RadioButton from "./RadioButton";
import CheckboxGroup from "./CheckboxGroup";
import DatePicker from "./DatePicker";
import * as Yup from "yup";

function FormikControl(props) {
  // console.log(JSON.stringify(props));
  const name = props.name;

  const validationSchema = Yup.object({
    name: Yup.string().required("Required First Name"),
  });

  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "select":
      return <Select {...rest} />;
    case "autocomplete":
        return <AutoComplete {...rest} />;
    case "radio":
      return <RadioButton {...rest} />;
    case "checkbox":
      return <CheckboxGroup {...rest} />;
    case "date":
      return <DatePicker {...rest} />;
   
    default:
      return null;
  }
}

export default FormikControl;
