import React, { useState, useEffect } from "react";
import orangejuice from "../../assets/images/orangejuice.svg";
import pepsi from "../../assets/images/pepsi.svg";
import { Button, Grid } from "@mui/material";
import milkshake from "../../assets/images/milkshake.svg";
import berry from "../../assets/images/berry.svg";
import tomato from "../../assets/images/tomato.svg";
import productimg_placeholder from "../../assets/images/productimg_placeholder.svg";
import StarIcon from "@mui/icons-material/Star";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import all from "../../assets/images/all.svg";
import veggies from "../../assets/images/veggies.svg";
import nonveg from "../../assets/images/nonveg.svg";
import canned from "../../assets/images/canned.svg";
import household from "../../assets/images/household.svg";
import snacks from "../../assets/images/snacks.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarLogoSmall from "../../assets/images/StarLogoSmall.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import BallotRoundedIcon from "@mui/icons-material/BallotRounded";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import SoupKitchenIcon from "@mui/icons-material/SoupKitchen";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import {
  ORDER_TYPE_MARKET
} from "../../../constants/orderTypes";
import SearchComponent from "../Searchmarket";

import { useCart } from "../../Screens/CartContext"; //"../Screens/CartContext";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal vegModal">
        <button className="modal-close" onClick={onClose}>
          Close
        </button>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

function StarMarketHome() {

  const {
    marketcart,
    addMarketProduct,
    reduceMarketCategoryCount,
    removeMarketItemFromCart,
  } = useCart();

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const [alignment, setAlignment] = React.useState("All");
  const [category, setCategory] = useState([]);
  const [count, setCount] = useState(0);
  const [name, setName] = useState("");
  const [star, setStar] = React.useState(0);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [items, setItems] = useState([]);

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const clickAddBt = (id, item, index) => {

    setItems((prevItems) =>
      items.map((item) =>
        item.product_id === id
          ? { ...item, class: "addedCount" }
          : { ...item, class: "" }
      )
    );
    const updatedItem = { ...item, class: "addedCount" };
    addMarketProduct(updatedItem);
    var element = document.getElementById("addAndOuntMain" + index);
    element.classList.toggle("addedCount");
  };

  const increment = (id, item) => {

    setItems((prevItems) =>
      prevItems.map((item) => {
        if (item.product_id === id && item.count < item.current_quantity) {
          const updatedItem = { ...item, count: item.count + 1 };
          addMarketProduct(updatedItem); // Call addMarketProduct only if the condition is satisfied
          return updatedItem;
        }
        return item;
      })
    );
  };

  const decrement = (id, item) => {

    setItems((prevItems) =>
      // items.map((item) =>
      //   item.product_id === id ? { ...item, count: item.count - 1 } : item
      // )
      displayItems.map((item) => {
        if (item.product_id === id) {
          const newCount = Math.max(item.count - 1, 0);
          const updatedItem = {
            ...item,
            count: newCount === 0 ? 1 : newCount,
            class: "",
          };
          reduceMarketCategoryCount(item);
          if (newCount === 0) {
            removeMarketItemFromCart(item.product_id);
          }
          return updatedItem;
        }
        return item;
      })
    );
    //reduceMarketCategoryCount(item);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  async function getCategoryList() {

    await makeGetRequest("starmarket/getcategory/null/true")
      .then((response) => {
        if (response.data.data) {
          setCategory(response.data.data);
        } else {
          setCategory([]);
        }
      })
      .catch((err) => {
        setCategory([]);
      });
  };

  async function getItemListById(Id) {

    setSelectedCategoryId(Id);
    await makeGetRequest("starmarket/getproductlistBycategoryid/" + Id)
      .then((response) => {
        if (response.data.data) {
          const updatedData = response.data.data
            .filter((item) => item.current_quantity
              > 0)
            .map((item) => ({
              ...item,
              count: 1,
              class: "",
              type: ORDER_TYPE_MARKET,
            }));
          setItems(updatedData);
        } else {
          setItems([]);
        }
      })
      .catch((err) => {
        setItems([]);
      });
  };

  async function getItemList() {

    await makeGetRequest("starmarket/getproductlist")
      .then((response) => {
        if (response.data.data) {
          const updatedData = response.data.data
            .filter((item) => item.current_quantity
              > 0)
            .map((item) => ({
              ...item,
              count: 1,
              class: "",
              type: ORDER_TYPE_MARKET,
            }));
          console.log(updatedData)
          setItems(updatedData);
        } else {
          setItems([]);
        }
      })
      .catch((err) => {
        setItems([]);
      });
  };

  const handleButtonClickAll = () => {
    setSelectedCategoryId(0);
    getItemList();
  };

  const handleSearch = (results) => {
    console.log(results)
    if (results.length > 0) {
      setItems(results);
    } else {
      getItemList();
    }
  };

  const handleButtonClick = (categoryId) => {
    setSelectedCategoryId(categoryId);
    getItemListById(categoryId);
  };

  const naviagte = async () => {
    localStorage.setItem("servicetype", "Starmarket");
    navigate("/OrderSummaryMarket", {
      state: { Fromlocation: "Star Market", type: "Star Market" },
    });
  };

  useEffect(() => {
    setName(localStorage.getItem("username"));
    setStar(localStorage.getItem("starcount"));
    getCategoryList();
    getItemList();
  }, []);

  const totals =
    marketcart && marketcart.length > 0
      ? marketcart.reduce(
        (sums, item) => {
          sums.total_star_price += (item.Star_price || 0) * item.count;
          sums.total_price_in_stars +=
            (item.price_in_stars || 0) * item.count;
          return sums;
        },
        { total_star_price: 0, total_price_in_stars: 0 }
      )
      : { total_star_price: 0, total_price_in_stars: 0 };

  const { total_star_price, total_price_in_stars } = totals;

  const mergeItems = (items, cart) => {
    const cartMap = new Map(cart.map((item) => [item.product_id, item]));
    const mergedItems = items.map((item) =>
      cartMap.has(item.product_id) ? cartMap.get(item.product_id) : item
    );
    return mergedItems;
  };

  const displayItems = mergeItems(items, marketcart);

  return (
    <>
      <div className="mobileCssMain">
        <div className="forBottomTabOnly">
          <div className="container">
            <div className="wrapper">
              <div className="screenTitle">
                <Button onClick={() => navigate("/DashboardPage")}>
                  <IconButton className="TouchableIconBtn"> <ArrowBackIosIcon />
                  </IconButton>
                </Button>
                <h2>Star Market</h2>
              </div>
              <div className="headerDataIcons">
                <div className="topheaderIcons headerGreyIcon">
                </div>
              </div>

              <div className="availStarMain topHeaderTypetwo">
                <div className="availStar ">
                  <h4>{name}</h4>
                  <h1>
                    Available Stars{" "}
                    <b>
                      <StarOutlinedIcon /> {star}
                    </b>
                  </h1>
                </div>
                <div className="newSearchOuter newSearchTopSpace">
                  <SearchComponent onSearch={handleSearch} category_id={selectedCategoryId} />
                </div>
              </div>

              <div className="screenTitleOuter">
                <div className="screenTitle">
                  <h2>Star Market</h2>
                </div>
              </div>

              <div className="myToggleMain newMyToggleMain">
                <div className="horiCategory">
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="All" onClick={() => handleButtonClickAll()}>
                      <div className="categoryMain categoryHori">
                        <div>
                          <BallotRoundedIcon />
                        </div>
                        <b>All</b>
                      </div>
                    </ToggleButton>
                    {category.map((item, index) => (
                      <ToggleButton
                        key={index}
                        value="Meat"
                        selected={selectedCategoryId === item.category_id}
                        onClick={() => handleButtonClick(item.category_id)}
                        className={
                          selectedCategoryId === item.category_id
                            ? "Mui-selected"
                            : ""
                        }
                      >
                        <div className={"categoryMain categoryHori"}>
                          <div>
                            <RoomServiceIcon />
                          </div>
                          <b>{item.category_name}</b>
                        </div>
                      </ToggleButton>
                    ))};
                  </ToggleButtonGroup>
                </div>
              </div>

              <div className="marketProductsOuter">
                <Grid container spacing={2}>
                  {displayItems.map((item, index) => (
                    <Grid key={index} item xs={6}>
                      <div className="productOuter newProductOuter">
                        <div
                          className="productImg"
                          onClick={() =>
                            navigate("/ProductDetailsMobile", {
                              state: { Id: item.product_id },
                            })
                          }
                        >
                          {item.product_pic ? (
                            <img src={item.product_pic[0]} />
                          ) : (
                            <img src={productimg_placeholder} />
                          )}
                        </div>
                        <p>Product Id {item.product_id}</p>
                        <h3>{item.product_name}</h3>

                        <div className="productBoxtBottom">
                          <div className="prodBottom">
                            <div className="starPrice">
                              <StarIcon className="starIcon" />
                              <b>{item.price_in_stars
                              }</b>
                            </div>

                            <div
                              key={index}
                              className={"countWithAddBtMain " + item.class}
                              id={`addAndOuntMain` + index}
                            >
                              <div className="newCountSt">
                                <Button
                                  className="beforeAddBt"
                                  onClick={() =>
                                    clickAddBt(item.product_id, item, index)
                                  }
                                >
                                  <AddIcon />
                                </Button>
                                <div className="outerIncdec">
                                  <Button
                                    onClick={() =>
                                      decrement(item.product_id, item)
                                    }
                                    className="IncDrcBtn"
                                  >
                                    <RemoveIcon />
                                  </Button>

                                  <b className="countValue countValue2">
                                    {item.count}
                                  </b>

                                  <Button
                                    onClick={() =>
                                      increment(item.product_id, item)
                                    }
                                    className="IncDrcBtn"
                                  >
                                    <AddIcon />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          </div>

          <div className="fixedBtnOuter">
            <Button className="loginBt" onClick={() => naviagte()}>
              <p style={{ marginRight: "60px" }}>{marketcart.length} Item |{" "}
                {total_star_price + total_price_in_stars} Stars</p>
              <p>View Cart</p>
            </Button>
          </div>

          <Modal isOpen={isOpen}>

            <div className="modal-footer">
              <button className="closeBtn" onClick={closeModal}>
                <CloseOutlinedIcon />
              </button>
            </div>

            <div className="vegProdMainOuter">
              <div className="vegProdMain">
                <img src={tomato} />
                <div className="specialMenu vegProd">
                  <div>
                    <h3>Tomato</h3>
                    <p>Product Id #1001</p>
                    <div className="starPrice">
                      <StarIcon className="starIcon" />
                      <h5> 20 </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="vegProdData">
              <h5>Vegetables required 7 Days for Delivery</h5>
              <p>
                Kindly create separate order for Vegetables to avoid delay in
                delivery for other products
              </p>
            </div>

            <Button
              className="loginBt"
              onClick={() => navigate("/StarMarketHome")}
            >
              Continue Shopping
            </Button>
          </Modal>

        </div>
      </div>
    </>
  );
}

export default StarMarketHome;
