import React, { useState, useEffect, useRef } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button, Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import LabelIcon from "@mui/icons-material/Label";
import avtarPic from "../../../MobileScreens/assets/images/Profile-Picture.jpg";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import SegmentIcon from '@mui/icons-material/Segment';
// For Tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makePostRequest, makeGetRequest } from "../../../utils/utils";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
// End

// Tabs Main
function CustomTabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
// End

function ManagerEmpProfPersonalDetails() {

    const location = useLocation();
    const { id } = location.state || {};

    // For Tabs
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate();
    const [employeeObj, setEmployeeObj] = React.useState({});

    const getProfileDetails = async () => {

        await makeGetRequest(
            "employee/getemployee/" + id
        )
            .then((response) => {
                if (response.data.data[0]) {
                    console.log(response.data.data[0])
                    setEmployeeObj(response.data.data[0]);
                } else {
                    setEmployeeObj([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getProfileDetails();
    }, []);

    return (
        <>
            <div className="mobileCssMain">
                <div className="fixedNewProDetailsBgMain"></div>
                <div className="screenTitle newProDetailsBgMain">
                    <div className="sideProBar">
                        <Button onClick={() => navigate("/ManagerTab")}>
                            <IconButton className="TouchableIconBtn">
                                <ArrowBackIosIcon />
                            </IconButton>
                        </Button>
                        <h2>{employeeObj.first_name + " " + employeeObj.last_name} Personal Details</h2>
                    </div>
                    <div className="newProDetailsPr">
                        <div className="newProDetailsProDivOut">
                            <div className="newProDetailsProDiv">
                                {employeeObj.profile_pic != null ? <img src={employeeObj.profile_pic} /> :
                                    <img src={avtarPic} />}
                            </div>
                        </div>
                        <h1>{employeeObj.first_name + " " + employeeObj.last_name}</h1>
                        <p>{employeeObj.designation_name}</p>
                    </div>
                </div>
                <div className="container">
                    <div className="wrapper">
                        <div className="myProfileNewTbsMain">
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        <Tab label="Basic Information" {...a11yProps(0)} />
                                        <Tab label="Emergency Contacts" {...a11yProps(1)} />
                                        <Tab label="Family Information" {...a11yProps(2)} />
                                    </Tabs>
                                </Box>
                                <CustomTabPanel value={value} index={0}>
                                    <div className="myProfileNewTbsIner">
                                        <div className="profileData">
                                            <div className="personalDetails">
                                                <h3>Personal Details</h3>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <SegmentIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Name</p>
                                                        <p>{employeeObj.first_name + " " + employeeObj.last_name}</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <EmailIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Email Id</p>
                                                        <p>{employeeObj.email_id}</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <LocalPhoneIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Mobile Number</p>
                                                        <p>{employeeObj.contact_no}</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <LabelIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Position</p>
                                                        <p>{employeeObj.designation_name}</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <CalendarMonthIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Date Of Birth</p>
                                                        <p>{moment(employeeObj.birth_date).format(
                                                            "DD/MM/YYYY"
                                                        )}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="profileData">
                                            <div className="personalDetails">
                                                <h3>Employment Details</h3>
                                                <div className="profileRow profileEmp">
                                                    <div className="rowInfo">
                                                        <p>Designation</p>
                                                        <p>{employeeObj.designation_name}</p>
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Department</p>
                                                        <p>{employeeObj.department_name}</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow profileEmp">
                                                    <div className="rowInfo">
                                                        <p>Employee Code</p>
                                                        <p>{employeeObj.employee_code}</p>
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Date of Joining</p>
                                                        <p>{moment(employeeObj.date_of_joining).format(
                                                            "DD/MM/YYYY"
                                                        )}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="profileRow profileEmp">
                                                    <div className="rowInfo">
                                                        <p>Monthly Star Allowance</p>
                                                        <p>{employeeObj.monthly_allowance}</p>
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Contract Terms (Years)</p>
                                                        <p>{employeeObj.contract_years}</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow profileEmp">
                                                    <div className="rowInfo">
                                                        <p>Passport Number</p>
                                                        {employeeObj.passport_no ? (
                                                            <p>{employeeObj.passport_no}</p>
                                                        ) : (
                                                            <p>---</p>
                                                        )}
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Passport Expiry Date</p>
                                                        <p>{moment(employeeObj.paspport_expiry_date).format(
                                                            "DD/MM/YYYY"
                                                        )}</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow profileEmp">
                                                    <div className="rowInfo">
                                                        <p>Visa Number</p>
                                                        <p>{employeeObj.visa_no}</p>
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Visa Expiry Date</p>
                                                        <p>{moment(employeeObj.visa_expiry_date).format(
                                                            "DD/MM/YYYY"
                                                        )}</p>
                                                    </div>
                                                </div>

                                                {/* <div className="profileRow profileEmp">
                                                    <div className="rowInfo">
                                                        <p>Fixed Salary</p>
                                                        {employeeObj.fixed_salary ? (
                                                            <p>{employeeObj.fixed_salary}</p>
                                                        ) : (
                                                            <p>---</p>
                                                        )}
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Reporting To</p>
                                                        <p>{employeeObj.reporting_to_first_name + " " + employeeObj.reporting_to_last_name
                                                        }</p>
                                                    </div>
                                                </div> */}

                                                <div className="profileRow profileEmp">
                                                    {/* <div className="rowInfo">
                                                        <p>Balance Salary</p>
                                                        {employeeObj.balance_salary ? (
                                                            <p>{employeeObj.balance_salary}</p>
                                                        ) : (
                                                            <p>---</p>
                                                        )}
                                                    </div> */}
                                                    <div className="rowInfo">
                                                        <p>Travel Eligibility</p>
                                                        <p>{employeeObj.travel_eligibility
                                                        }</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={1}>
                                    <div className="myProfileNewTbsIner">
                                        <div className="profileData">
                                            <div className="personalDetails">
                                                <h3>Emergency Contact 1</h3>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <LocalPhoneIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Mobile Number</p>
                                                        <p>{employeeObj.emergency_contact_1
                                                        }</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <EmailIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Address</p>
                                                        <p>{employeeObj.emergency_address_1}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="profileData">
                                            <div className="personalDetails">
                                                <h3>Emergency Contact 2</h3>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <LocalPhoneIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Mobile Number</p>
                                                        <p>{employeeObj.emergency_contact_2
                                                        }</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <EmailIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Address</p>
                                                        <p>{employeeObj.emergency_address_2}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={2}>
                                    <div className="myProfileNewTbsIner">
                                        {employeeObj.family_info ?
                                            <>
                                                {employeeObj.family_info.map((item, index) => (
                                                    <div className="profileData">
                                                        <div className="personalDetails">
                                                            <div className="profileRow">
                                                                <div className="rowIcons">
                                                                    <SegmentIcon />
                                                                </div>
                                                                <div className="rowInfo">
                                                                    <p>Name</p>
                                                                    <p>{item.name}</p>
                                                                </div>
                                                            </div>
                                                            <div className="profileRow">
                                                                <div className="rowIcons">
                                                                    <LocalPhoneIcon />
                                                                </div>
                                                                <div className="rowInfo">
                                                                    <p>Mobile Number</p>
                                                                    <p>{item.contact_no
                                                                    }</p>
                                                                </div>
                                                            </div>
                                                            <div className="profileRow">
                                                                <div className="rowIcons">
                                                                    <CalendarMonthIcon />
                                                                </div>
                                                                <div className="rowInfo">
                                                                    <p>Date Of Birth</p>
                                                                    <p>{moment(employeeObj.bith_date).format(
                                                                        "DD/MM/YYYY"
                                                                    )}</p>
                                                                </div>
                                                            </div>
                                                            <div className="profileRow">
                                                                <div className="rowIcons">
                                                                    <EmailIcon />
                                                                </div>
                                                                <div className="rowInfo">
                                                                    <p>Age</p>
                                                                    <p>{item.age
                                                                    } yrs</p>
                                                                </div>
                                                            </div>
                                                            <div className="profileRow">
                                                                <div className="rowIcons">
                                                                    <EmailIcon />
                                                                </div>
                                                                <div className="rowInfo">
                                                                    <p>Relation</p>
                                                                    <p>{item.relation
                                                                    }</p>
                                                                </div>
                                                            </div>
                                                            <div className="profileRow">
                                                                <div className="rowIcons">
                                                                    <EmailIcon />
                                                                </div>
                                                                <div className="rowInfo">
                                                                    <p>Address</p>
                                                                    <p>{item.address
                                                                    }</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <img
                                                            //src={editIco}
                                                            className="newProEdiIco"
                                                        //onClick={handleClickFamilyEdit("body", index, item)}
                                                        />
                                                    </div>
                                                ))}
                                            </>
                                            : null}
                                        {/* <div className="profileData">
                                            <div className="personalDetails">
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <SegmentIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Name</p>
                                                        <p>Leslie Alexander</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <LocalPhoneIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Mobile Number</p>
                                                        <p>+1-123-456-7890</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <CalendarMonthIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Date Of Birth</p>
                                                        <p>24/06/1987</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <EmailIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Age</p>
                                                        <p>32yrs</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <EmailIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Relation</p>
                                                        <p>Wife</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <EmailIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Address</p>
                                                        <p>Lorem ipsum dummy text lorem ipsum dummy text</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                        {/* <div className="profileData">
                                            <div className="personalDetails">
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <SegmentIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Name</p>
                                                        <p>Leslie Alexander</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <LocalPhoneIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Mobile Number</p>
                                                        <p>+1-123-456-7890</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <CalendarMonthIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Date Of Birth</p>
                                                        <p>24/06/1987</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <EmailIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Age</p>
                                                        <p>32yrs</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <EmailIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Relation</p>
                                                        <p>Wife</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <EmailIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Address</p>
                                                        <p>Lorem ipsum dummy text lorem ipsum dummy text</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="profileData">
                                            <div className="personalDetails">
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <SegmentIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Name</p>
                                                        <p>Leslie Alexander</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <LocalPhoneIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Mobile Number</p>
                                                        <p>+1-123-456-7890</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <CalendarMonthIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Date Of Birth</p>
                                                        <p>24/06/1987</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <EmailIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Age</p>
                                                        <p>32yrs</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <EmailIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Relation</p>
                                                        <p>Wife</p>
                                                    </div>
                                                </div>
                                                <div className="profileRow">
                                                    <div className="rowIcons">
                                                        <EmailIcon />
                                                    </div>
                                                    <div className="rowInfo">
                                                        <p>Address</p>
                                                        <p>Lorem ipsum dummy text lorem ipsum dummy text</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </CustomTabPanel>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManagerEmpProfPersonalDetails;
