import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Button, Grid, Box } from "@mui/material";
import mealImg1 from "../../../assets/images/mealImg1.svg";
import mealImg2 from "../../../assets/images/mealImg2.svg";
import mealImg3 from "../../../assets/images/mealImg3.svg";
import mealMainimg1 from "../../../assets/images/mealMainimg1.svg";
import mealMainimg2 from "../../../assets/images/mealMainimg2.svg";
import editIco from "../../../assets/images/edit-icon.svg";
import StarIcon from "@mui/icons-material/Star";
import deletIco from "../../../assets/images/delete-icon.svg";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import UploadIcon from "@mui/icons-material/Upload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import mealAcordingIcon from "../../../assets/images/mealAcordingIcon.svg";
import DataTable from "react-data-table-component";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import RemoveIcon from "@mui/icons-material/Remove";
import Pagination from "@mui/material/Pagination";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import productImg from "../../../assets/images/productImg.svg";
import mealAcordingIcon2 from "../../../assets/images/mealAcordingIcon2.svg";
import mealAcordingIcon3 from "../../../assets/images/mealAcordingIcon3.svg";
import mealAcordingIcon4 from "../../../assets/images/mealAcordingIcon4.svg";
import mealMainimg3 from "../../../assets/images/mealMainimg3.svg";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
// End

// For According
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//

import Checkbox from "@mui/material/Checkbox";
import orderIc from "../../../assets/images/orderIc.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ord1 from "../../../assets/images/ord1.svg";
import ord2 from "../../../assets/images/ord2.svg";
import ord3 from "../../../assets/images/ord3.svg";
import ord4 from "../../../assets/images/ord4.svg";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import ImportExportIcon from "@mui/icons-material/ImportExport";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import GroceryTab from "./GroceryTab";
import VeggiesTab from "./VeggiesTab";
import KitchenSuppliesTab from "./KitchenSuppliesTab";
import CafeInventoryTab from "./CafeInventoryTab";
import CreateMealMenu from "./CreateMealMenu";
import moment from "moment";
import { makeGetRequest } from "../../../utils/utils";

function OrderSummaryTab() {
  // Modal Breakfast
  const [openBreakfast, setOpenBreakfast] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [showLoader, setShowLoader] = useState(false);
  
  let TodaysDate = moment().add(1, 'days').format("YYYY-MM-DD");
  const [todayDate, setTodayDate] = useState(TodaysDate);
  const [orderDetails, setOrderDetails] = useState([]);
  const [popUpTitle, setPopUpTitle] = useState("");
  const [breakfastOrderCount, setBreakfastOrderCount] = useState(0);
  const [lunchThaliOrderCount, setLunchThaliOrderCount] = useState(0);
  const [dinnerThaliOrderCount, setDinnerThaliOrderCount] = useState(0);
  const [cafeOrderCount, setCafeOrderCount] = useState(0);

  const handleClickBreakfast = (scrollType, type) => async () => {
    setShowLoader(true);

    await getOrderListByType(type);
    setOpenBreakfast(true);
    setScroll(scrollType);
  };

  const handleCloseBreakfast = () => {
    setOpenBreakfast(false);
  };
  const descriptionElementRef = useRef(null);
  // End

  // Modal Order Summary
  const [OrderSummary, setOpenOrderSummary] = useState(false);
  const handleClickOrderSummary = (scrollType, type) => async () => {
    await getOrderListByType(type);
    setOpenOrderSummary(true);
    setScroll(scrollType);
  };
  const handleCloseOrderSummary = () => {
    setOpenOrderSummary(false);
  };
  // End

  async function getOrderListByType(type) {

    let tempDate=todayDate
    if(type=='Starcafe')
      tempDate= moment(todayDate).subtract(1,'days').format("YYYY-MM-DD")
    await makeGetRequest(
      "starcafe/getorderdetailcountbytype/" + tempDate + "/" + type
    )
      .then((response) => {
        if (response.data) {
          console.log("Order Details", response.data.data);
          console.log("type", type);
          setOrderDetails(response.data.data);
          setPopUpTitle(type);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  }
  // Modal Dinner Summary
  const [DinnerSummary, setOpenDinner] = useState(false);
  const handleClickDinner = (scrollType, type) => async () => {
    await getOrderListByType(type);
    setOpenDinner(true);
    setScroll(scrollType);
  };

  const handleCloseDinner = () => {
    setOpenDinner(false);
  };
  // End

  // Modal Star Cafe
  const [StarCafe, setOpenStarCafe] = useState(false);
  const handleClickStarCafe = (scrollType, type) => async () => {
    await getOrderListByType(type);
    setOpenStarCafe(true);
    setScroll(scrollType);
  };
  const handleCloseStarCafe = () => {
    setOpenStarCafe(false);
  };
  // End

  const [orderListCount, setOrderListCount] = useState([]);

  async function handleChangeDate(e) {
    setTodayDate(e.target.value);
    await getOrderCount(e.target.value);
  }
  const getOrderCount = async (date) => {
    setShowLoader(true);
    if (!date) date = todayDate;
    
    await makeGetRequest("starcafe/getordercountbytype/" + date)
      .then((response) => {
        if (response.data) {
          console.log("Order Count", response.data.data);
          setOrderListCount(response.data.data);
          if (response.data.data.length > 0) {
            for (let i = 0; i < response.data.data.length; i++) {
              if (response.data.data[i].ordertype == "Breakfast")
                setBreakfastOrderCount(response.data.data[i].order_count);
              else if (response.data.data[i].ordertype == "Lunch")
                setLunchThaliOrderCount(response.data.data[i].order_count);
              else if (response.data.data[i].ordertype == "Dinner")
                setDinnerThaliOrderCount(response.data.data[i].order_count);
              else if (response.data.data[i].ordertype == "Starcafe")
                setCafeOrderCount(response.data.data[i].order_count);
            }
          } else {
            setBreakfastOrderCount(0);
            setLunchThaliOrderCount(0);
            setDinnerThaliOrderCount(0);
            setCafeOrderCount(0);
          }
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };

  const getCafeOrderCount = async (date) => {
    setShowLoader(true);
    
    let todaysDate=moment().format("YYYY-MM-DD")
    
    await makeGetRequest("starcafe/getordercountbytype/" + todaysDate)
      .then((response) => {
        if (response.data) {
          console.log("Order Count", response.data.data);
          setOrderListCount(response.data.data);
          if (response.data.data.length > 0) {
            for (let i = 0; i < response.data.data.length; i++) {
              if (response.data.data[i].ordertype == "Starcafe")
                setCafeOrderCount(response.data.data[i].order_count);
            }
          } else {
            setCafeOrderCount(0);
          }
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    getOrderCount();
    getCafeOrderCount();
    if (openBreakfast) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (OrderSummary) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (DinnerSummary) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (StarCafe) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [StarCafe, DinnerSummary, OrderSummary, openBreakfast]);

  return (
    <>
      <div className="starCafeTabInner orderSumTbOnly">
        <Grid container spacing={3}>
          <>
            <Grid item xs={12} md={8}>
              <h3 className="todyMenHeading">
                Get Order Summary
                {/* <span style={{ color: "#c82d33" }}>{moment(todayDate).format("dddd DD/MM/YYYY")}</span> */}
              </h3>
            </Grid>
            <Grid item xs={12} md={4}>
              <input
                type="date"
                className="prodSearchInpt"
                onChange={(e) => handleChangeDate(e)}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <div
                className="newOrderSumMain"
                onClick={handleClickBreakfast("body", "Breakfast")}
              >
                <span className="ordSmNew" style={{ color: "#c82d33" }}>
                  {moment(todayDate).format("dddd DD/MM/YYYY")}
                </span>
                <div className="newOrdTop">
                  <img src={ord1} />
                  <h1>Breakfast</h1>
                </div>
                <div className="newOrdBot">
                  <div className="accordTotalOrder">
                    <img src={orderIc} />
                    <span>Total {breakfastOrderCount} Orders</span>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={3}>
              <div
                className="newOrderSumMain"
                onClick={handleClickOrderSummary("body", "Lunch")}
              >
                <span className="ordSmNew" style={{ color: "#c82d33" }}>
                  {moment(todayDate).format("dddd DD/MM/YYYY")}
                </span>
                <div className="newOrdTop">
                  <img src={ord2} />
                  <h1>Lunch</h1>
                </div>
                <div className="newOrdBot">
                  <div className="accordTotalOrder">
                    <img src={orderIc} />
                    <span>Total {lunchThaliOrderCount} Orders</span>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={3}>
              <div
                className="newOrderSumMain"
                onClick={handleClickDinner("body", "Dinner")}
              >
                <span className="ordSmNew" style={{ color: "#c82d33" }}>
                  {moment(todayDate).format("dddd DD/MM/YYYY")}
                </span>
                <div className="newOrdTop">
                  <img src={ord3} />
                  <h1>Dinner</h1>
                </div>
                <div className="newOrdBot">
                  <div className="accordTotalOrder">
                    <img src={orderIc} />
                    <span>Total {dinnerThaliOrderCount} Orders</span>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={3}>
              <div
                className="newOrderSumMain"
                onClick={handleClickStarCafe("body", "Starcafe")}
              >
                <span className="ordSmNew" style={{ color: "#c82d33" }}>
                  {moment(todayDate).subtract(1,'days').format("dddd DD/MM/YYYY")}
                </span>
                <div className="newOrdTop">
                  <img src={ord4} />
                  <h1>Star Cafe</h1>
                </div>
                <div className="newOrdBot">
                  <div className="accordTotalOrder">
                    <img src={orderIc} />
                    <span>Total {cafeOrderCount} Orders</span>
                  </div>
                </div>
              </div>
            </Grid>
          </>
        </Grid>
      </div>

      {/* Breakfast Order Summary */}
      <div>
        <Dialog
          open={openBreakfast}
          onClose={handleCloseBreakfast}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Breakfast Order Summary
            <CloseIcon
              className="modalCloseBt"
              onClick={handleCloseBreakfast}
            />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="orderSmryPop">
                        <div className="orderSmryPop1">
                          <img src={ord2} />
                          <b className="AccorddingHeading">
                            {popUpTitle} Summary
                          </b>
                        </div>
                        <div className="orderSmryPop2">
                          <div className="accordTotalOrder">
                            <img src={orderIc} /> Total {breakfastOrderCount}{" "}
                            Orders
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      {orderDetails.map((data, index) => (
                        <div className="menuDetailsPop" key={index}>
                          <div>
                            <h1>{data.item_name}</h1>
                            {/* <p>Product ID #122645</p> */}
                          </div>
                          <div className="accordTotalOrder">
                            <img src={orderIc} /> {data.total_count}
                          </div>
                        </div>
                      ))}
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Lunch Order Summary */}
      <div>
        <Dialog
          open={OrderSummary}
          onClose={handleCloseOrderSummary}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Lunch Order Summary
            <CloseIcon
              className="modalCloseBt"
              onClick={handleCloseOrderSummary}
            />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div className="orderSmryPop">
                        <div className="orderSmryPop1">
                          <img src={ord2} />
                          <b className="AccorddingHeading">Lunch Summary</b>
                        </div>
                        <div className="orderSmryPop2">
                          <div className="accordTotalOrder">
                            <img src={orderIc} /> Total {lunchThaliOrderCount}{" "}
                            Orders
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="menuDetailsPop">
                        <div>
                          <h1>Lunch Thali</h1>
                        </div>
                        <div className="accordTotalOrder">
                          <img src={orderIc} /> {lunchThaliOrderCount}
                        </div>
                      </div>

                      {orderDetails.map((data, index) =>
                        data.item_type == "lunch" ? (
                          <div className="menuDetailsPop underMenu">
                            <div>
                              <h1>{data.item_name}</h1>
                            </div>
                            <div className="accordTotalOrder">
                              <img src={orderIc} /> {data.total_count}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )
                      )}
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <h1 className="otherProdctHeadNew">
                        Other Cafe Products
                      </h1>

                      {orderDetails.map((data, index) =>
                        data.item_type == "cafe" ? (
                          <div className="menuDetailsPop">
                            <div>
                              <h1>{data.item_name}</h1>
                            </div>
                            <div className="accordTotalOrder">
                              <img src={orderIc} /> {data.total_count}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Dinner Summary */}
      <div>
        <Dialog
          open={DinnerSummary}
          // onClose={handleCloseDinner}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Dinner Order Summary
            <CloseIcon className="modalCloseBt" onClick={handleCloseDinner} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="orderSmryPop">
                        <div className="orderSmryPop1">
                          <img src={ord2} />
                          <b className="AccorddingHeading">Dinner Summary</b>
                        </div>
                        <div className="orderSmryPop2">
                          <div className="accordTotalOrder">
                            <img src={orderIc} /> Total {dinnerThaliOrderCount}{" "}
                            Orders
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="menuDetailsPop">
                        <div>
                          <h1>Dinner Thali</h1>
                        </div>
                        <div className="accordTotalOrder">
                          <img src={orderIc} /> {lunchThaliOrderCount}
                        </div>
                      </div>

                      {orderDetails.map((data, index) =>
                        data.item_type == "dinner" ? (
                          <div className="menuDetailsPop underMenu">
                            <div>
                              <h1>{data.item_name}</h1>
                            </div>
                            <div className="accordTotalOrder">
                              <img src={orderIc} /> {data.total_count}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )
                      )}
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <h1 className="otherProdctHeadNew">
                        Other Cafe Products
                      </h1>
                      {orderDetails.map((data, index) =>
                        data.item_type == "cafe" ? (
                          <div className="menuDetailsPop">
                            <div>
                              <h1>{data.item_name}</h1>
                            </div>
                            <div className="accordTotalOrder">
                              <img src={orderIc} /> {data.total_count}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )
                      )}
                    </Grid>

                    {/* <Grid item xs={12} md={12}>
                      <Button
                        className="modalBtFullWidth"
                        onClick={handleCloseEditCategory}
                      >
                        Save
                      </Button>
                    </Grid> */}
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Star Cafe Summary */}
      <div>
        <Dialog
          open={StarCafe}
          // onClose={handleCloseStarCafe}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Star Cafe Summary
            <CloseIcon className="modalCloseBt" onClick={handleCloseStarCafe} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="orderSmryPop">
                        <div className="orderSmryPop1">
                          <img src={ord2} />
                          <b className="AccorddingHeading">Star Cafe Summary</b>
                        </div>
                        <div className="orderSmryPop2">
                          <div className="accordTotalOrder">
                            <img src={orderIc} /> Total {cafeOrderCount}{" "}
                          </div>
                        </div>
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} md={12}>
                      <div className="menuDetailsPop">
                        <div>
                          <h1>Lunch Thali</h1>
                          <p>Product ID #122645</p>
                        </div>
                        <div className="accordTotalOrder">
                          <img src={orderIc} /> 30
                        </div>
                      </div>
                    </Grid> */}
                    <Grid item xs={12} md={12}>
                      <h1 className="otherProdctHeadNew">Cafe Products</h1>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      {orderDetails.map((data, index) => (
                        <div className="menuDetailsPopOut">
                          <div className="menuDetailsPop">
                            <div>
                              <h1>{data.item_name}</h1>
                            </div>
                            <div className="accordTotalOrder">
                              <img src={orderIc} /> {data.total_count}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Grid>

                    {/* <Grid item xs={12} md={6} className="minuDetailDevideLine">
                      <div className="menuDetailsPopOut">
                        <div className="menuDetailsPop">
                          <div>
                            <h1>Lunch Thali</h1>
                            <p>Product ID #122645</p>
                          </div>
                          <div className="accordTotalOrder">
                            <img src={orderIc} /> 30
                          </div>
                        </div>
                        <div className="menuDetailsPop">
                          <div>
                            <h1>Lunch Thali</h1>
                            <p>Product ID #122645</p>
                          </div>
                          <div className="accordTotalOrder">
                            <img src={orderIc} /> 30
                          </div>
                        </div>
                      </div>
                    </Grid> */}

                    {/* <Grid item xs={12} md={12}>
                      <Button
                        className="modalBtFullWidth"
                        onClick={handleCloseEditCategory}
                      >
                        Save
                      </Button>
                    </Grid> */}
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default OrderSummaryTab;
