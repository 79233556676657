import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Grid } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import BottomTabsPage from "../../../MobileScreens/componentsMobile/BottomTabsPage/BottomTabsPage";
import TuneIcon from "@mui/icons-material/Tune";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import * as Yup from "yup";
import { makeGetRequest } from "../../../utils/utils";
import { makePostRequest, uploadFileRequest } from "../../../utils/utils";
import moment from "moment";
import noData from "../../../MobileScreens/assets/images/noData.webp";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FileDownloadOutlined from "@mui/icons-material/FileDownloadOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Formik, Form } from "formik";
import FormikControl from "../../../components/FormikControl";
import { useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
// issue warn modal pop up start

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

const sampleWarning = {
  employee_id: 0,
  warningtype: "",
  subject: "",
  description: "",
  documents: null,
  damagecost: 0,
  reply: null,
  status: "",
};

const initialValues = {
  wtype: "",
  subject: "",
  description: "",
};

const validationSchema = Yup.object({
  wtype: Yup.string().required("Warning type is required"),
  subject: Yup.string().required("Subject is required"),
  description: Yup.string().required("Required"),
});

// issue warn modal pop up end
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// leave tabs

function CustomTabPanel1(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel1.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps1(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const sampleLeave = {
  employee_id: 0,
  fromdate: "",
  todate: "",
  leave_reason: "",
  leave_discription: "",
  comments: "",
  status: "",
  is_deleted: false,
};

const notificationobj = {
  title: "",
  message: "",
  employee_id: "",
};

function ManagerEmpProfDetails() {
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const location = useLocation();
  const { id } = location.state || {};

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [value1, setValue1] = React.useState(0);

  const forTabchnage = (newValue) => {
    setValue(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange1 = (event, newValue1) => {
    setValue1(newValue1);
  };

  const handleChangeInner = (event, newValue1) => {
    setValue1(newValue1);
  };

  const navigate = useNavigate();
  const [employeeObj, setEmployeeObj] = React.useState({});
  const [documentsprofile, setDocuments] = useState([]);
  const [star, setStar] = React.useState(0);
  const [warningCount, setWarningCount] = React.useState(0);
  const [employeeWarning, setEmployeeWarning] = React.useState([]);
  const [employeeLeave, setEmployeeLeave] = React.useState([]);
  const [pendinglist, setPendinglist] = React.useState([]);
  const [approvedlist, setApprovedlist] = React.useState([]);
  const [rejectedlist, setRejectedlist] = React.useState([]);
  const [flagsearch, setflagsearch] = useState(false);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [flagapprovedsearch, setApprovedflagsearch] = useState(false);
  const [filteredApprovedDataSource, setFilteredApprovedDataSource] = useState(
    []
  );
  const [flagrejectedsearch, setRejectedflagsearch] = useState(false);
  const [filteredRjectedDataSource, setFilteredRejectedDataSource] = useState(
    []
  );

  const getProfileDetails = async () => {
    await makeGetRequest("employee/getemployee/" + id)
      .then((response) => {
        if (response.data.data[0]) {
          console.log("Profile", response.data.data[0]);
          setEmployeeObj(response.data.data[0]);
          setDocuments(response.data.data[0].documents);
        } else {
          setEmployeeObj([]);
          setDocuments([]);
        }
      })
      .catch((err) => {
        setEmployeeObj([]);
        setDocuments([]);
        console.log(err);
      });
  };

  async function getEmployeeLeaveHist() {
    await makeGetRequest("employee/getemployeeleave/" + id)
      .then((response) => {
        if (response.data.data) {
          if (response.data.data) {
            console.log("leave hist");
            console.log(response.data.data);
            setEmployeeLeave(response.data.data);
          } else {
            setEmployeeLeave([]);
          }
        }
      })
      .catch((err) => {
        setEmployeeLeave([]);
      });
  }

  async function getEmployeeStar() {
    await makeGetRequest("employee/getstarcount/" + id)
      .then((response) => {
        if (response.data.data) {
          console.log("starcount");
          console.log(response.data);
          if (response.data.data) {
            localStorage.setItem("starcount", response.data.data.total_stars);
            setStar(response.data.data);
          } else {
            localStorage.setItem("starcount", 0);
            setStar(0);
          }
        }
      })
      .catch((err) => {
        setStar(0);
      });
  }

  async function getEmployeeWarningCount() {
    await makeGetRequest("employee/getemployeewarning/" + id)
      .then((response) => {
        if (response.data.data) {
          if (response.data.data) {
            setWarningCount(response.data.data.length);
            setEmployeeWarning(response.data.data);
          } else {
            setWarningCount(0);
            setEmployeeWarning([]);
          }
        }
      })
      .catch((err) => {
        setWarningCount(0);
        setEmployeeWarning([]);
      });
  }

  async function getEmployeeLeaveHist() {
    await makeGetRequest("employee/getemployeeleave/" + id)
      .then((response) => {
        if (response.data.data) {
          if (response.data.data) {
            setEmployeeLeave(response.data.data);
          } else {
            setEmployeeLeave([]);
          }
        }
      })
      .catch((err) => {
        setEmployeeLeave([]);
      });
  }

  async function getEmployeeLeave() {
    let arr = [];
    await makeGetRequest("employee/getreportingemployeeleaves/" + id)
      .then((response) => {
        console.log("groupedData");
        console.log(response.data);
        if (response.data.data[0]) {
          arr.push(response.data.data[0]);
          const groupedData = response.data.data.reduce((acc, item) => {
            if (!acc[item.status]) {
              acc[item.status] = [];
            }
            acc[item.status].push(item);
            return acc;
          }, {});

          if (groupedData) {
            setPendinglist(groupedData.Pending);
            setApprovedlist(groupedData.Approved);
            setRejectedlist(groupedData.Rejected);
          } else {
            setPendinglist([]);
            setApprovedlist([]);
            setRejectedlist([]);
          }
        } else {
          setPendinglist([]);
          setApprovedlist([]);
          setRejectedlist([]);
        }
      })
      .catch((err) => {
        setPendinglist([]);
        setApprovedlist([]);
        setRejectedlist([]);
      });
  }

  const searchPendingData = (e) => {
    let text = e.target.value;
    if (text) {
      setflagsearch(true);
      const newData = pendinglist.filter((item) => {
        const itemData = item.first_name.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.startsWith(textData);
      });

      if (newData.length > 0) {
        console.log(newData);
        setFilteredDataSource(newData);
        //setSearch(text);
      } else {
        const newDataname = pendinglist.filter((item) => {
          const itemData = item.last_name.toUpperCase();
          const textData = text.toUpperCase();
          return itemData.startsWith(textData);
        });
        if (newDataname.length > 0) {
          setFilteredDataSource(newDataname);
          //setSearch(text);
        } else {
          setFilteredDataSource([]);
          //setSearch(text);
        }
      }
    } else {
      setflagsearch(false);
      //setSearch(text);
    }
  };

  const searchApprovedData = (e) => {
    let text = e.target.value;
    if (text) {
      setApprovedflagsearch(true);
      const newData_A = approvedlist.filter((item) => {
        const itemData = item.first_name.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.startsWith(textData);
      });
      if (newData_A.length > 0) {
        setFilteredApprovedDataSource(newData_A);
        //setSearch(text);
      } else {
        const newDataname = approvedlist.filter((item) => {
          const itemData = item.last_name.toUpperCase();
          const textData = text.toUpperCase();
          return itemData.startsWith(textData);
        });
        if (newDataname.length > 0) {
          setFilteredApprovedDataSource(newDataname);
          //setSearch(text);
        } else {
          console.log("in else");
          setFilteredApprovedDataSource([]);
          //setSearch(text);
        }
      }
    } else {
      setApprovedflagsearch(false);
      //setSearch(text);
    }
  };

  const searchRejectedData = (e) => {
    let text = e.target.value;
    if (text) {
      setRejectedflagsearch(true);
      const newData = rejectedlist.filter((item) => {
        const itemData = item.first_name.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.startsWith(textData);
      });

      if (newData.length > 0) {
        console.log(newData);
        setFilteredRejectedDataSource(newData);
        //setSearch(text);
      } else {
        const newDataname = rejectedlist.filter((item) => {
          const itemData = item.last_name.toUpperCase();
          const textData = text.toUpperCase();
          return itemData.startsWith(textData);
        });
        if (newDataname.length > 0) {
          setFilteredRejectedDataSource(newDataname);
          //setSearch(text);
        } else {
          setFilteredRejectedDataSource([]);
          //setSearch(text);
        }
      }
    } else {
      setRejectedflagsearch(false);
      //setSearch(text);
    }
  };

  async function uploadProfilePic(e) {
    let obj;
    // let obj = JSON.parse(e);
    // Parse JSON string to object if necessary (assuming `e` is a JSON string)
    try {
      obj = typeof e === "string" ? JSON.parse(e) : e;
      // alert("Parsed object: " + JSON.stringify(obj)); // Debugging alert
    } catch (error) {
      // alert("Error parsing JSON: " + error.message);
      return; //
    }

    const getfile = obj.file;
    const gettype = obj.type;

    // alert(gettype);
    // alert("getfile");
    // alert(getfile);

    const newDocument = {
      type: gettype,
      date: moment().format("YYYY-MM-DD"),
      file: getfile,
    };

    // alert(JSON.stringify(newDocument));
    //call employee update app
    var employeeid = localStorage.getItem("userId");
    let inputdata = {
      employee_id: employeeid,
      documents: [newDocument],
    };

    await makePostRequest("employee/updateDocument", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          alert("update success ", response.data.data);
        }
      })
      .catch((err) => {
        alert("update document error", err);
      });
  }

  //function to open document picker in react native
  const fileUploadToReactNative = (type) => {
    const message = { type: type, data: "openDocumentPicker" };
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
    //navigate("/LoginMobile")
  };

  const renderDocuments = (type) => {
    console.log(documentsprofile.filter((doc) => doc.type == type));
    const filteredDocs = documentsprofile.filter((doc) => doc.type === type);
    //console.log(doc => doc.type == type)
    console.log(documentsprofile);
    return filteredDocs.length > 0
      ? filteredDocs.map((document, index) => (
          <div className="docListInner" key={index}>
            <div className="docListLeft">
              <div className="docIcon">
                <PictureAsPdfOutlinedIcon />
              </div>
              <div className="docName">
                {/* <p>{document.type}</p> */}
                <p>
                  {document.file.substring(document.file.lastIndexOf("/") + 1)}
                </p>
                <p>Uploaded on {document.date}</p>
              </div>
            </div>
            <div className="red">
              <DownloadIcon onClick={() => fileUploadToReactNative(document)} />
              {/* <DeleteOutlinedIcon key={index} onClick={() => deleteDocument(document.type)} /> */}
              {/*  */}
            </div>
          </div>
        ))
      : null;
    // <div className="docListInner">
    //   <h5 className="docHeading">{type}</h5>
    //   <FileUploadOutlinedIcon onClick={() => fileUploadToReactNative(type)} />
    // </div>
  };

  const onSubmit = (values) => {
    sampleWarning.employee_id = employeeObj.employee_id;
    sampleWarning.warningtype = values.wtype;
    sampleWarning.subject = values.subject;
    sampleWarning.description = values.description;
    sampleWarning.status = "Open";

    makePostRequest("employee/insertemployeewarning", sampleWarning)
      .then((response) => {
        if (response.data) {
          notificationobj.resident_id = employeeObj.employee_id;
          notificationobj.title = "New Warning is Issued";
          notificationobj.message =
            employeeObj.first_name +
            " " +
            employeeObj.last_name +
            " " +
            " has placed a new order.Order id isan warning is issued against You";
          console.log(notificationobj);
          makePostRequest("employee/notificationtoadmin", notificationobj)
            .then((response) => {
              console.log("Notification Placed");
            })
            .catch((err) => {
              console.log(err);
            });

          closeModal();
          getEmployeeWarningCount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEmployeeLeave();
    getEmployeeWarningCount();
    getProfileDetails();
    getEmployeeStar();
    window.uploadfile = function (result) {
      // alert("uploadfile received:");
      // alert(JSON.stringify(result));
      uploadProfilePic(result);
    };
  }, []);

  return (
    <>
      <div className="mobileCssMain">
        <div className="dashMain forBottomTabOnly">
          <div className="">
            <div className="container">
              <div className="wrapper">
                <div className="headerData">
                  <Button onClick={() => navigate(-1)}>
                    <IconButton className="TouchableIconBtn">
                      <ArrowBackIosIcon />
                    </IconButton>
                  </Button>
                  <div className="screenTitle">
                    <h2>Employee Profile Details</h2>
                  </div>
                </div>
                <div className="profileBox">
                  <div className="profileName">
                    <h2>
                      {employeeObj.first_name} {employeeObj.last_name}
                      <span> (E-{employeeObj.employee_code})</span>
                    </h2>
                  </div>
                  <h2 style={{ fontSize: 15, color: "#212121" }}>
                    {employeeObj.designation_name}{" "}
                  </h2>
                  <div
                    onClick={() =>
                      navigate("/ManagerEmpProfPersonalDetails", {
                        state: { id: employeeObj.employee_id },
                      })
                    }
                    className="profileDetailsArrow"
                  >
                    <h4>Personal Details </h4>
                    <div className="profileArrowIcon">
                      <IconButton className="TouchableIconBtn">
                        <ArrowForwardIosSharpIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="wrapper">
              <div className="profileTabs">
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      className="profileTopTab"
                      value={value}
                      onChange={handleChange}
                    >
                      <Tab
                        label="Statistics"
                        className="toptabActive"
                        {...a11yProps(0)}
                      />
                      <Tab label="Leave" {...a11yProps(1)} />
                      <Tab label="Documents" {...a11yProps(2)} />
                      <Tab label="Non Conformance (nc)" {...a11yProps(3)} />
                    </Tabs>
                  </Box>

                  <CustomTabPanel value={value} index={0}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <div className="profileCard">
                          <h2>
                            <span className="starSummaryStar">
                              <StarIcon />
                            </span>{" "}
                            {star.monthly_allowance}
                          </h2>
                          <b>Monthly Allowance</b>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          className="profileCard"
                          onClick={() => forTabchnage(3)}
                        >
                          <h2>{warningCount}</h2>
                          <b>Warnings</b>
                        </div>
                        {/* <div className="warningCount">
                          <h4>2</h4>
                        </div> */}
                      </Grid>
                    </Grid>
                    <div className="screenTitle starTitle">
                      <h2>Star Summary</h2>
                    </div>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <div className="profileCard">
                          <h2>
                            <span className="starSummaryStar">
                              <StarIcon />
                            </span>
                            {star.total_stars}
                          </h2>
                          <b>Stars Available</b>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="profileCard">
                          <h2>
                            <span className="starSummaryStar">
                              <StarIcon />
                            </span>
                            {star.total_star_spent}
                          </h2>
                          <b>Stars Spent</b>
                        </div>
                      </Grid>
                      {/* <Grid item xs={6}>
                        <div className="profileCard">
                          <h2>
                            <span className="starSummaryStar">
                              <StarIcon />
                            </span>
                            150
                          </h2>
                          <b>Requested Stars</b>
                        </div>
                      </Grid> */}
                    </Grid>
                  </CustomTabPanel>

                  {/* LeaveHistory */}
                  <CustomTabPanel value={value} index={1}>
                    <div className="leaveData">
                      <div className="leaveFilter">
                        <h3>Leave History</h3>
                        {/* <TuneIcon /> */}
                      </div>
                      <div className="profileTabs EmpLeaveHistoryTabs">
                        <Box sx={{ width: "100%" }}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              variant="scrollable"
                              scrollButtons="auto"
                              aria-label="scrollable auto tabs example"
                              className="profileTopTab"
                              value={value1}
                              onChange={handleChange1}
                            >
                              <Tab
                                label="Pending"
                                className="toptabActive"
                                {...a11yProps1(0)}
                              />
                              <Tab label="Approved" {...a11yProps1(1)} />
                              <Tab label="Rejected" {...a11yProps1(2)} />
                            </Tabs>
                          </Box>

                          <CustomTabPanel1 value={value1} index={0}>
                            <div className="pendingLeavesOuter">
                              <div className="leaveFilter ">
                                <div className="newSearchOuter leaveListSearch">
                                  <input
                                    type="text"
                                    placeholder="Search"
                                    className="managerListSearch"
                                    onChange={searchPendingData}
                                  />
                                </div>
                                {/* <IconButton className="TouchableIconBtn">
                                  <TuneIcon className="MEmpleaverFilterIcon" />
                                </IconButton> */}
                              </div>

                              {flagsearch ? (
                                <>
                                  {filteredDataSource ? (
                                    <>
                                      {filteredDataSource.map((item, index) => (
                                        <div
                                          onClick={() =>
                                            navigate(
                                              "/ManagerEmpProfLeaveDetails",
                                              {
                                                state: { data: item },
                                              }
                                            )
                                          }
                                          className="leaveDataCard"
                                        >
                                          <div className="leaveDataCard-left">
                                            <h4>
                                              {item.first_name +
                                                " " +
                                                item.last_name}
                                            </h4>
                                            <h4>{item.leave_reason}</h4>
                                            <p>
                                              Applied On{" "}
                                              {moment(item.created_at).format(
                                                "YYYY-MM-DD"
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  ) : null}
                                </>
                              ) : (
                                <>
                                  {pendinglist ? (
                                    <>
                                      {pendinglist.map((item, index) => (
                                        <div
                                          onClick={() =>
                                            navigate(
                                              "/ManagerEmpProfLeaveDetails",
                                              {
                                                state: { data: item },
                                              }
                                            )
                                          }
                                          className="leaveDataCard"
                                        >
                                          <div className="leaveDataCard-left">
                                            <h4>
                                              {item.first_name +
                                                " " +
                                                item.last_name}
                                            </h4>
                                            <h4>{item.leave_reason}</h4>
                                            <p>
                                              Applied On{" "}
                                              {moment(item.created_at).format(
                                                "YYYY-MM-DD"
                                              )}
                                            </p>
                                          </div>
                                          <div className="leaveDataCard-right">
                                            <h4>
                                              <b style={{ color: "#696666" }}>
                                                From -
                                              </b>{" "}
                                              {moment(item.fromdate).format(
                                                "YYYY-MM-DD"
                                              )}{" "}
                                              <br />
                                              <b style={{ color: "#696666" }}>
                                                to -
                                              </b>{" "}
                                              {moment(item.todate).format(
                                                "YYYY-MM-DD"
                                              )}
                                            </h4>
                                            {item.status == "Pending" ? (
                                              <p>{item.status}</p>
                                            ) : (
                                              <p style={{ color: "#008000" }}>
                                                {item.status}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  ) : null}
                                </>
                              )}
                            </div>
                          </CustomTabPanel1>

                          <CustomTabPanel1 value={value1} index={1}>
                            <div className="pendingLeavesOuter">
                              <div className="leaveFilter ">
                                <div className="newSearchOuter leaveListSearch">
                                  <input
                                    type="text"
                                    placeholder="Search"
                                    className="managerListSearch"
                                    onChange={searchApprovedData}
                                  />
                                </div>

                                {/* <IconButton className="TouchableIconBtn">
                                  <TuneIcon className="leaverFilterIcon" />
                                </IconButton> */}
                              </div>
                              {flagapprovedsearch ? (
                                <>
                                  {filteredApprovedDataSource ? (
                                    <>
                                      {filteredApprovedDataSource.map(
                                        (item, index) => (
                                          <div
                                            onClick={() =>
                                              navigate("/ManagerLeaveDetails", {
                                                state: { data: item },
                                              })
                                            }
                                            className="leaveDataCard"
                                          >
                                            <div className="leaveDataCard approvependingLeave">
                                              <div className="leaveDataCard-left">
                                                <h4>
                                                  {item.first_name +
                                                    " " +
                                                    item.last_name}
                                                </h4>
                                                <p>
                                                  Applied On{" "}
                                                  {moment(
                                                    item.created_at
                                                  ).format("YYYY-MM-DD")}
                                                </p>
                                              </div>
                                              <div className="leaveDataCard-right empListDataright">
                                                <p>
                                                  Approved On{" "}
                                                  {moment(
                                                    item.updated_at
                                                  ).format("YYYY-MM-DD")}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </>
                                  ) : null}
                                </>
                              ) : (
                                <>
                                  {approvedlist ? (
                                    <>
                                      {approvedlist.map((item, index) => (
                                        <div
                                          onClick={() =>
                                            navigate("/ManagerLeaveDetails", {
                                              state: { data: item },
                                            })
                                          }
                                          className=""
                                        >
                                          <div className="leaveDataCard approvependingLeave">
                                            <div className="leaveDataCard-left">
                                              <h4>
                                                {item.first_name +
                                                  " " +
                                                  item.last_name}
                                              </h4>
                                              <h4>{item.leave_reason}</h4>
                                              <p>
                                                Applied On{" "}
                                                {moment(item.created_at).format(
                                                  "YYYY-MM-DD"
                                                )}
                                              </p>
                                            </div>
                                            <div className="leaveDataCard-right empListDataright">
                                              <p>
                                                Approved On{" "}
                                                {moment(item.updated_at).format(
                                                  "YYYY-MM-DD"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  ) : null}
                                </>
                              )}
                            </div>
                          </CustomTabPanel1>

                          <CustomTabPanel value={value1} index={2}>
                            <div className="pendingLeavesOuter">
                              <div className="leaveFilter ">
                                <div className="newSearchOuter leaveListSearch">
                                  <input
                                    type="text"
                                    placeholder="Search"
                                    className="managerListSearch"
                                    onChange={searchRejectedData}
                                  />
                                </div>
                                {/* <IconButton className="TouchableIconBtn">
                                  <TuneIcon className="leaverFilterIcon" />
                                </IconButton> */}
                              </div>
                              {flagrejectedsearch ? (
                                <>
                                  {filteredRjectedDataSource ? (
                                    <>
                                      {filteredRjectedDataSource.map(
                                        (item, index) => (
                                          <div
                                            onClick={() =>
                                              navigate("/ManagerLeaveDetails", {
                                                state: { data: item },
                                              })
                                            }
                                            className=""
                                          >
                                            <div className="leaveDataCard approvependingLeave">
                                              <div className="leaveDataCard-left">
                                                <h4>
                                                  {item.first_name +
                                                    " " +
                                                    item.last_name}
                                                </h4>
                                                <h4>{item.leave_reason}</h4>
                                                <p>
                                                  Applied On{" "}
                                                  {moment(
                                                    item.created_at
                                                  ).format("YYYY-MM-DD")}
                                                </p>
                                              </div>
                                              <div className="leaveDataCard-right empListDataright">
                                                <p>
                                                  Rejected On{" "}
                                                  {moment(
                                                    item.updated_at
                                                  ).format("YYYY-MM-DD")}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </>
                                  ) : null}
                                </>
                              ) : (
                                <>
                                  {rejectedlist ? (
                                    <>
                                      {rejectedlist.map((item, index) => (
                                        <div
                                          onClick={() =>
                                            navigate("/ManagerLeaveDetails", {
                                              state: { data: item },
                                            })
                                          }
                                          className=""
                                        >
                                          <div className="leaveDataCard approvependingLeave">
                                            <div className="leaveDataCard-left">
                                              <h4>
                                                {item.first_name +
                                                  " " +
                                                  item.last_name}
                                              </h4>
                                              <h4>{item.leave_reason}</h4>
                                              <p>
                                                Applied On{" "}
                                                {moment(item.created_at).format(
                                                  "YYYY-MM-DD"
                                                )}
                                              </p>
                                            </div>
                                            <div className="leaveDataCard-right empListDataright">
                                              <p>
                                                Rejected On{" "}
                                                {moment(item.updated_at).format(
                                                  "YYYY-MM-DD"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  ) : null}
                                </>
                              )}
                            </div>
                          </CustomTabPanel>
                        </Box>
                      </div>
                    </div>
                  </CustomTabPanel>

                  {/* Document */}
                  <CustomTabPanel value={value} index={2}>
                    <div className="docData  addLeaveBtn">
                      {/* <Button className="loginBt addLeaveBtn">
                      Upload Documents <UploadFileIcon className="addIcon" />{" "}
                    </Button> */}
                      <div className="leaveFilter">
                        <h3>Documents</h3>
                        {/* <p>1 document pending</p> */}
                      </div>
                      {/* <div className="docListOuter">
                        <div className="docListInner">
                          <h5 className="docHeading">Joining Letter</h5>
                          <p className="red">Pending</p>
                        </div>

                        <div className="divider"></div>
                        <div className="docListInner">
                          <h5 className="docHeading">Appointment Letter</h5>
                        </div>
                        <div className="docListInner">
                          <div className="docListLeft">
                            <div className="docIcon">
                              <PictureAsPdfOutlinedIcon />
                            </div>
                            <div className="docName">
                              <p>johndoe_appointment</p>
                              <p>Uploaded on 01/11/2022 04:35pm</p>
                            </div>
                          </div>
                          <FileDownloadOutlined />
                        </div>
                        <div className="divider"></div>
                        <div className="docListInner">
                          <h5 className="docHeading">Passport</h5>
                        </div>
                        <div className="docListInner">
                          <div className="docListLeft">
                            <div className="docIcon">
                              <PictureAsPdfOutlinedIcon />
                            </div>
                            <div className="docName">
                              <p>johndoe_passport</p>
                              <p>Uploaded on 01/11/2022 04:35pm</p>
                            </div>
                          </div>
                          <FileDownloadOutlined />
                        </div>
                        <div className="divider"></div>
                        <div className="docListInner">
                          <h5 className="docHeading">Other Documents</h5>
                        </div>
                        <div className="docListInner">
                          <div className="docListLeft">
                            <div className="docIcon">
                              <PictureAsPdfOutlinedIcon />
                            </div>
                            <div className="docName">
                              <p>other_documents 1</p>
                              <p>Uploaded on 01/11/2022 04:35pm</p>
                            </div>
                          </div>
                          <FileDownloadOutlined />
                        </div>
                        <div className="docListInner">
                          <div className="docListLeft">
                            <div className="docIcon">
                              <PictureAsPdfOutlinedIcon />
                            </div>
                            <div className="docName">
                              <p>other_documents 2</p>
                              <p>Uploaded on 01/11/2022 04:35pm</p>
                            </div>
                          </div>
                          <FileDownloadOutlined />
                        </div>
                      </div> */}
                      {documentsprofile && documentsprofile.length > 0 ? (
                        <div className="docListOuter">
                          <p>Joining Letter</p>
                          {renderDocuments("Joining Letter")}
                          <div className="divider"></div>
                          <p>Appointment Letter</p>
                          {renderDocuments("Appointment Letter")}
                          <div className="divider"></div>
                          <p>Passport</p>
                          {renderDocuments("Passport")}
                          <div className="divider"></div>
                          <p>Visa</p>
                          {renderDocuments("Visa")}
                          <div className="divider"></div>
                          <p>Other Documents</p>
                          {renderDocuments("Other")}
                        </div>
                      ) : null}
                    </div>
                  </CustomTabPanel>

                  {/* Warning */}
                  <CustomTabPanel value={value} index={3}>
                    <div className="warningData">
                      <div>
                        <Button
                          onClick={openModal}
                          className="loginBt addLeaveBtn"
                        >
                          Issue a Warning <AddCircleIcon className="addIcon" />{" "}
                        </Button>
                      </div>

                      {employeeWarning.length > 0 ? (
                        <>
                          {employeeWarning.map((item, index) => (
                            <div
                              key={index}
                              onClick={() =>
                                navigate("/ManagerEmpWarnDetails", {
                                  state: {
                                    data: item,
                                    name:
                                      employeeObj.first_name +
                                      " " +
                                      employeeObj.last_name,
                                    profilepic: employeeObj.profile_pic,
                                  },
                                })
                              }
                              className="warnCard"
                            >
                              <h2>{item.subject}</h2>
                              <h6>Reference No. {item.warning_id}</h6>
                              <p>
                                {moment(item.created_at).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </p>
                            </div>
                          ))}
                        </>
                      ) : null}
                    </div>
                  </CustomTabPanel>

                  <BottomTabsPage />
                </Box>
              </div>
            </div>

            {/* modal start */}
            <div className="mobModalBdScroll">
              <Modal isOpen={isOpen}>
                <div className="modal-footer">
                  <CloseOutlinedIcon
                    className="closeBtn"
                    onClick={closeModal}
                  />
                </div>
                <div className="leaveAppForm">
                  <h3>Issue New Warning</h3>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize
                  >
                    {(formik) => (
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Senior Authority"
                              labelClass="loginLable"
                              name="seniorauthority"
                              className="loginInput"
                              placeholder="CFO"
                              readOnly="true"
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Employee Name"
                              labelClass="loginLable"
                              name="name"
                              className="loginInput"
                              placeholder={
                                employeeObj.first_name +
                                " " +
                                employeeObj.last_name
                              }
                              readOnly="true"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Type Of Warning"
                              labelClass="loginLable"
                              name="wtype"
                              className="loginInput"
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Subject"
                              labelClass="loginLable"
                              name="subject"
                              className="loginInput"
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <FormikControl
                              control="textarea"
                              type="textarea"
                              label="Description"
                              labelClass="loginLable"
                              name="description"
                              className="loginInput  leaveComment"
                            />
                          </Grid>
                        </Grid>
                        <Button className="loginBt" type="submit">
                          Issue
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Modal>
            </div>
            {/* modal end */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ManagerEmpProfDetails;
