import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { makeGetRequest, makePostRequest } from "../../utils/utils";
import {
    ORDER_TYPE_BREAKFAST,
    ORDER_TYPE_LUNCH,
    ORDER_TYPE_DINNER,
    ORDER_TYPE_CAFE
} from '../../constants/orderTypes';

const SearchComponent = ({ onSearch, category_id }) => {

    const [searchQuery, setSearchQuery] = useState('');

    const searchProduct = async (query) => {

        if (query != '') {
            await makeGetRequest("starmarket/productsearchforapp/" + query + "/" + category_id)
                .then((response) => {
                    if (response.data.data) {
                        const updatedData = response.data.data
                            .filter((item) => item.current_quantity
                                > 0)
                            .map((item) => ({
                                ...item,
                                count: 1,
                                class: "",
                                type: ORDER_TYPE_CAFE,
                            }));
                        onSearch(updatedData);
                    } else {
                        onSearch([]);
                    }
                })
                .catch((err) => {
                    onSearch([]);
                });
        } else {
            onSearch([]);
        }
    };

    // Create a debounced version of the search function
    const debouncedSearch = useCallback(debounce((query) => {
        searchProduct(query);
    }, 300), []); // Adjust the delay as needed

    // Use an effect to call the debounced search function whenever searchQuery changes
    useEffect(() => {
        debouncedSearch(searchQuery);
        // Cancel the debounce on component unmount
        return () => {
            debouncedSearch.cancel();
        };
    }, [searchQuery, debouncedSearch]);

    return (
        <div>
            <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
        </div>
    );
};

export default SearchComponent;