import { Button, Grid } from '@mui/material'
import React from 'react'

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../components/FormikControl";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';


const initialValues = {
  reason: "",
};

const validationSchema = Yup.object({
  reason: Yup.string().required("Reason is required"),
});

function ClockOutReasons() {

  const navigate = useNavigate();


  return (
    <>
      <div className="mobileCssMain">
        <div className="container">
          <div className="wrapper">
            <div className='reasonsOuter'>
              <ArrowBackIosIcon onClick={() => navigate('/EmpClockInClockOut')} />
              <h1>Select Reasons</h1>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className="storInner storWarn">
                    <b>Break for Breakfast </b>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="storInner storWarn">
                    
                    <b>Break for Lunch</b>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="storInner storWarn">
                    <b>Break for Dinner</b>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="storInner storWarn">
                    <b>Shift Completed</b>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="storInner storWarn reasonSelected">
                    <DoneIcon />
                    <b>Other</b>
                  </div>
                </Grid>
              </Grid>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}

              >
                {(formik) => (
                  <div className='reasonInput'>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Enter Reason"
                      labelClass="loginLable"
                      name="reason"
                      placeholder="Enter reason"
                    />

                    <Button
                      className="loginBt"
                      type="submit"
                      onClick={() => navigate('/ClockOutThankYou')}
                    >
                      Clock Out
                    </Button>

                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClockOutReasons