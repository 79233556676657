import React, { useState, useEffect } from "react";
import { Button, Grid, Box } from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import PrintIcon from "@mui/icons-material/Print";
import qrImg from "../../../assets/images/qrCode.svg";
import WestIcon from "@mui/icons-material/West";
import { makeGetRequest } from "../../../utils/utils";
import moment from "moment";
import logo from "../../../MobileScreens/assets/images/StarLogoSmall.svg";

function ScannedOrder() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { data } = location.state || {};
  const [showLoader, setShowLoader] = useState(false);
  // Order Details Start
  const [selectedOrderId, setSelectedOrderId] = useState(0);
  const [starCafeDetails, setStarCafeDetails] = useState([]);
  const [cafeListLength, setCafeListLength] = useState([]);
  const [cafeDetailsTableData, setCafeDetailsTableData] = useState([]);
 
  const orderDetails = async () => {
    console.log("Data", data);
    if (data === 'Starcafe' || data === 'Lunch' || data === 'Dinner' || data === 'Breakfast')
    {
      await makeGetRequest("starcafe/getorder/" + id).then((response) => {
        if (response.data) {
          setShowLoader(true);
          console.log("OrderDetails", response.data.data[0]);
          setStarCafeDetails(response.data.data[0]);
          setCafeListLength(response.data.data);
          //console.log(JSON.parse(response.data.data[0].orderdetails));
          setCafeDetailsTableData(response.data.data[0].orderdetails);
          console.log(response.data.data[0].orderdetails);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
    } else if (data === 'Starmarket') {
      await makeGetRequest("starmarket/getorder/" + id).then((response) => {
        if (response.data) {
          setShowLoader(true);
          console.log("OrderDetails", response.data.data[0]);
          setStarCafeDetails(response.data.data[0]);
          setCafeListLength(response.data.data);
          //console.log(JSON.parse(response.data.data[0].orderdetails));
          setCafeDetailsTableData(response.data.data[0].orderdetails);
          console.log(cafeDetailsTableData);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
    }
  };
  // Order Details end

  const Print = () => {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  const handlePrintClick = () => {
    // Create a new div element to hold the warning content
    const printContent = document.createElement("div");
    printContent.innerHTML = `
      <div class="warning-section">
        <p>This is a warning message.</p>
      </div>
    `;

    // Append the warning content to the body
    document.body.appendChild(printContent);

    // Call the browser's print function
    window.print();

    // Remove the warning content after printing
    document.body.removeChild(printContent);
  };
  useEffect(() => {
    orderDetails();
  }, [showLoader]);

  return (
    <div id="printablediv" className="smallPrintMain">
      <div className="scanTopBar"></div>
      <Button className="printIco" onClick={handlePrintClick}>
        <PrintIcon />
      </Button>
      <div className="scanOrderMain">
        <div className="scanOrderContDiv">
          <div className="pintLogoOutMain">
            <div className="webLogoOut">
              {localStorage.getItem("companyLogo") ? (
                <img src={localStorage.getItem("companyLogo")} />
              ) : (
                <img src={logo} />
              )}
            </div>
            <h5>{sessionStorage.getItem("companyName")}</h5>
          </div>
          {cafeListLength.length > 0 ? (
            <div className="orderDetailsSection">
              {/* <h4 className="ordDetaTx">
                <WestIcon onClick={() => navigate("/Orders")} /> Order Details
              </h4> */}
              <div className="orderTop">
                <h1>Order #{starCafeDetails.order_id}</h1>
              </div>
              <p className="nameOfCust">
                By {starCafeDetails.first_name} {starCafeDetails.last_name}
              </p>
              <div className="orderSumry">
                <div>
                  <label>Order Time</label>
                  <p>
                    {moment(starCafeDetails.created_at).format("DD/MM/YYYY hh:mm a")}
                  </p>
                </div>
                <div>
                  <label className="pickpSlot">Pickup Slot</label>
                  <p className="pickpSlotTime">06pm to 07pm</p>
                </div>
                <div>
                  <label>Pickup Location</label>
                  {starCafeDetails.ordertype == 'Starcafe'?
                    <p>Star Cafe</p> : starCafeDetails.ordertype == 'Starmarket' ?
                    <p>Star Market</p> : null}
                </div>
              </div>

              <div className="qrOter">
                <div className="qrDiv">
                  <img src={qrImg} />
                </div>
              </div>
              <div className="orderItemDetails">
                <h1>Order Details</h1>
                <div className="orderItemDiv">
                  <span>
                    <p>Order Id</p>
                    <b>#{starCafeDetails.order_id}</b>
                  </span>
                  <span>
                    <p>Total Items</p>
                    <b>{starCafeDetails.orderdetails.length}</b>
                  </span>
                  <span>
                    <p>Stars Spent</p>
                    <b className="totalAmt">{starCafeDetails.totalstarspend}</b>
                  </span>
                </div>
              </div>
              <div className="productDetails groceryTableMain">
                <table width="100%">
                  <thead>
                    <tr>
                      <th>Product ID</th>
                      <th>Product Name</th>
                      <th>Weight</th>
                      <th>Quantity</th>
                      <th>Stars Spent</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cafeDetailsTableData.map((data, index) => (
                      <tr key={index}>
                        {
                          <td>
                            #
                            {JSON.parse(data).menu_id
                              ? JSON.parse(data).menu_id
                              : JSON.parse(data).subcategory_id}{" "}
                          </td>
                        }

                        {
                          <td>
                            {JSON.parse(data).menu_name
                              ? JSON.parse(data).menu_name
                              : JSON.parse(data).product_name}
                          </td>
                        }
                        {JSON.parse(data).type == "product" ? (
                          <td>
                            {JSON.parse(data).weight}{" "}
                            {JSON.parse(data).weight_unit}
                          </td>
                        ) : (
                          <td>----</td>
                        )}
                        <td>{JSON.parse(data).count}</td>
                        {
                          <td className="starSpen">
                            {JSON.parse(data).Star_price
                              ? JSON.parse(data).Star_price
                              : JSON.parse(data).price_in_stars}
                          </td>
                        }
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ScannedOrder;
