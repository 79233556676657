import React, { useEffect } from "react";
import statLog from "../../../assets/images/statusLogo.png";
import starLLog from "../../../assets/images/starLLog.png";
import bagSho from "../../../assets/images/bagSho.gif";
import preperImg from "../../../assets/images/preperImg.gif";
import Objectimg from "../../../assets/images/Object.png";
import { makeGetRequest } from "../../../utils/utils";
import moment from "moment";
import topLinIn from "../../../assets/images/topLinIn.png";
import proPic from "../../../assets/images/Profile-Picture.jpg";
import blon from "../../../assets/images/blon.png";
import blon2 from "../../../assets/images/blon2.png";
import blon3 from "../../../assets/images/blon3.png";

import AOS from "aos";
import "aos/dist/aos.css";

function OrderStatus() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [ordersList, setOrdersList] = React.useState([]);
  let TodaysDate = moment(Date()).format("YYYY-MM-DD");
  const [prepareCount, setPrepareCount] = React.useState(0);
  const [newCount, setNewCount] = React.useState(0);
  const [readyCount, setReadyCount] = React.useState(0);
  async function getOrdersList() {
    await makeGetRequest("starcafe/getorderbystatusanddate/null/" + TodaysDate)
      .then((response) => {
        if (response.data.data) {
          if (response.data.data[0]) {
            let temp = response.data.data[0];
            setOrdersList(response.data.data);
            let objOrders = Object.assign({}, response.data.data);
            console.log(response.data.data);
            // const filteredResult = response.data.data.filter((e) => e.orderstatus === 'Readyforpickup');
            // console.log(filteredResult)

            const readyForPickupList = response.data.data.filter(
              (o) => o.orderstatus === "Readyforpickup"
            );
            setReadyCount(readyForPickupList.length);

            const preparingOrders = response.data.data.filter(
              (o) =>
                o.orderstatus === "Active" &&
                new Date(o.newtime).getTime() < new Date().getTime()
            );
            setPrepareCount(preparingOrders.length);

            const newOrders = response.data.data.filter(
              (o) =>
                o.orderstatus === "Active" &&
                new Date(o.newtime).getTime() >= new Date().getTime()
            );
            setNewCount(newOrders.length);
          } else {
            ordersList.length = 0;
            setOrdersList([]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  React.useEffect(() => {
    const interval = setInterval(() => {
      getOrdersList();
    }, 5000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <div className="statusWraper">
        <div className="statusTopMain">
          <div className="statusLogLeft">
            <img src={statLog} />
            <div>
              <h1>Order Status</h1>
              <p>Come and get your order</p>
            </div>
          </div>
          <div className="dispOrder">
            <div>
              <h1>
                New <span style={{ color: "#FE9800" }}>{newCount}</span>
              </h1>
            </div>
            <div>
              <h1>
                Preparing{" "}
                <span style={{ color: "#FE9800" }}>{prepareCount}</span>
              </h1>
            </div>
            <div>
              <h1>
                Ready <span style={{ color: "#4AD400" }}>{readyCount}</span>
              </h1>
            </div>
          </div>
          <div className="statusLogRight">
            <img src={starLLog} />
          </div>
        </div>

        <div className="statusContentMain">
          <div className="fixTableHead">
            <table width="100%">
              <thead>
                <tr>
                  <th>Emp Name</th>
                  <th>Order ID</th>
                  <th>Time</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {ordersList.map((option) =>
                  option.orderstatus != "Completed" ? (
                    <tr
                      className={
                        option.orderstatus == "Active" ? (
                          new Date(option.newtime).getTime() >=
                          new Date().getTime() ? (
                            ""
                          ) : new Date(option.newtime).getTime() <
                            new Date().getTime() ? (
                            "preparingColr"
                          ) : (
                            <></>
                          )
                        ) : option.orderstatus == "Readyforpickup" ? (
                          "readyPicColr"
                        ) : (
                          ""
                        )
                      }
                    >
                      <td>
                        {option.first_name} {option.last_name}
                      </td>
                      <td>#{option.order_id}</td>
                      <td>{moment(option.created_at).format("hh:mm a")}</td>
                      <td>
                        <div
                          className={
                            option.orderstatus == "Active" ? (
                              new Date(option.newtime).getTime() >=
                              new Date().getTime() ? (
                                ""
                              ) : new Date(option.newtime).getTime() <
                                new Date().getTime() ? (
                                "preparingImg"
                              ) : (
                                <></>
                              )
                            ) : option.orderstatus == "Readyforpickup" ? (
                              "readyForImg"
                            ) : (
                              ""
                            )
                          }
                        >
                          {option.orderstatus == "Active" ? (
                            new Date(option.newtime).getTime() >=
                            new Date().getTime() ? (
                              <>New Order</>
                            ) : new Date(option.newtime).getTime() <
                              new Date().getTime() ? (
                              <>
                                Preparing <img src={preperImg} />
                              </>
                            ) : (
                              <></>
                            )
                          ) : option.orderstatus == "Readyforpickup" ? (
                            <>
                              Ready For Pickup <img src={bagSho} />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )
                )}
                {/* <tr className="readyPicColr">
                  <td>Jenny Wilson</td>
                  <td>#1002</td>
                  <td>10:41 pm</td>
                  <td>
                    <div className="readyForImg">
                      Ready For Pickup <img src={bagSho} />
                    </div>
                  </td>
                </tr>
               
                <tr className="preparingColr">
                  <td>Jacob Jones</td>
                  <td>#1004</td>
                  <td>02:02 am</td>
                  <td>
                    <div className="preparingImg">
                      Preparing <img src={preperImg} />
                    </div>
                  </td>
                </tr>
                <tr className="preparingColr">
                  <td>Esther Howard</td>
                  <td>#1005</td>
                  <td>05:36 pm</td>
                  <td>
                    <div className="preparingImg">
                      Preparing <img src={preperImg} />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Theresa Webb</td>
                  <td>#1006</td>
                  <td>11:49 pm</td>
                  <td>
                    <span className="newSta">New</span>
                  </td>
                </tr>
                <tr>
                  <td>Savannah Nguyen</td>
                  <td>#1007</td>
                  <td>12:23 pm</td>
                  <td>
                    <span className="newSta">New</span>
                  </td>
                </tr>
                <tr>
                  <td>Kristin Watson</td>
                  <td>#1008</td>
                  <td>01:09 am</td>
                  <td>
                    <span className="newSta">New</span>
                  </td>
                </tr>
                <tr>
                  <td>Esther Howard</td>
                  <td>#1009</td>
                  <td>11:23 pm</td>
                  <td>
                    <span className="newSta">New</span>
                  </td>
                </tr>
                <tr>
                  <td>Esther Howard</td>
                  <td>#1010</td>
                  <td>11:23 pm</td>
                  <td>
                    <span className="newSta">New</span>
                  </td>
                </tr>
                <tr>
                  <td>Esther Howard</td>
                  <td>#1011</td>
                  <td>11:23 pm</td>
                  <td>
                    <span className="newSta">New</span>
                  </td>
                </tr>
                <tr>
                  <td>Esther Howard</td>
                  <td>#1012</td>
                  <td>11:23 pm</td>
                  <td>
                    <span className="newSta">New</span>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
        <div className="ordBannerMain">
          <img src={topLinIn} className="topLinInSt" />
          <img
            src={blon}
            className="blon"
            data-aos="fade-up"
            data-aos-duration="3000"
            data-aos-anchor-placement="center-bottom"
          />
          <img
            src={blon}
            className="blonRight"
            data-aos="fade-up"
            data-aos-duration="3000"
            data-aos-anchor-placement="center-bottom"
          />
          <img
            src={blon2}
            className="blon2"
            data-aos="fade-up"
            data-aos-duration="3000"
            data-aos-anchor-placement="center-bottom"
            data-aos-delay="500"
          />
          <img
            src={blon3}
            className="blon3"
            data-aos="fade-up"
            data-aos-duration="3000"
            data-aos-anchor-placement="center-bottom"
            data-aos-delay="1000"
          />
          <h1>Monday, 17 Aug 2024</h1>
          <h2>Welcoming New Team Members</h2>
          <div className="ordrBannerPicDiv">
            <div
              className="bannerPicOrd"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              data-aos-delay="2000"
              data-aos-anchor-placement="center-bottom"
            >
              <img src={proPic} />
            </div>
            <h1>Mr John Doe</h1>
            <span>Sr Manager</span>
          </div>
          <div className="bneBtCont">
            <b>
              We are delighted to welcome new talent to the Celestria family!
            </b>
            <p>
              Please join us in welcoming Sr Manager, who bring a wealth of
              experience and fresh perspectives to our team. We’re excited about
              the new energy they’ll bring to our projects.
            </p>
          </div>
        </div>
        <div className="tdSpecilTx">
          <img src={Objectimg} />
          <h1>
            Todays Special <span>Paneer Paratha</span>
          </h1>
        </div>
      </div>
    </>
  );
}

export default OrderStatus;
