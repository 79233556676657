import React, { useState, useEffect } from "react";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import dashLogo from "../../assets/images/dashLogo.svg";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { useNavigate } from "react-router-dom";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Grid } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import BottomTabsPage from "../../../MobileScreens/componentsMobile/BottomTabsPage/BottomTabsPage";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TuneIcon from "@mui/icons-material/Tune";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import market from "../../assets/images/market.svg";
import cup from "../../assets/images/cup.svg";
import barberShop from "../../assets/images/barberShop.svg";
import starCafe from "../../assets/images/starCafe.svg";
import starMarket from "../../assets/images/starMarket.svg";
import Sports from "../../assets/images/Sports.svg";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../../components/FormikControl";
import { makeGetRequest } from "../../../utils/utils";
import MobileHeader from "../../componentsMobile/MobileHeader/MobileHeader";
import { makePostRequest, uploadFileRequest } from "../../../utils/utils";
import moment from "moment";
import noData from "../../../MobileScreens/assets/images/noData.webp";
import IconButton from "@mui/material/IconButton";
import swal from "sweetalert";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import exchaneBlack from "../../../assets/images/exchaneBlack.png";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel1(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel1.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps1(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const sampleLeave = {
  employee_id: 0,
  fromdate: "",
  todate: "",
  leave_reason: "",
  leave_discription: "",
  comments: "",
  status: "",
  leavetype: "",
  is_deleted: false,
};

const notificationobj = {
  title: "",
  message: "",
  employee_id: "",
};

function Profile() {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [value, setValue] = React.useState(0);
  const [value1, setValue1] = React.useState(0);

  const forTabchnage = (newValue) => {
    setValue(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeInner = (event, newValue1) => {
    setValue1(newValue1);
  };

  const navigate = useNavigate();

  const dropdownOptions = [
    { key: "Select an option", value: "" },
    { key: "Option 1", value: "Option 1" },
    { key: "Option 2", value: "Option 2" },
    { key: "Option 3", value: "Option 3" },
  ];

  const initialValues = {
    subject: "",
    fromdate: "",
    todate: "",
    // selectOption: "",
    description: "",
  };

  const validationSchema = Yup.object({
    subject: Yup.string().required("Subject is required"),
    fromdate: Yup.date()
      .required("Date required")
      .min(new Date(), "Date cannot be in the past"),
    todate: Yup.date()
      .required("Date required")
      .min(Yup.ref("fromdate"), "To Date cannot be earlier than From Date"),
    todate: Yup.date()
      .required("Date required")
      .min(new Date(), "Date cannot be in the past")
      .min(Yup.ref("fromdate"), "To Date cannot be earlier than From Date"),
    description: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    sampleLeave.employee_id = parseInt(localStorage.getItem("userId"));
    sampleLeave.leave_reason = values.subject;
    sampleLeave.leave_discription = values.description;
    sampleLeave.fromdate = values.fromdate;
    sampleLeave.todate = values.todate;
    sampleLeave.status = "Pending";

    if (is_eligible) {
      sampleLeave.leavetype = "Regular";
    } else {
      sampleLeave.leavetype = "Emergency";
    }
    makePostRequest("employee/insertemployeeleave", sampleLeave)
      .then((response) => {
        if (response.data) {
          if (employeeObj.reporting_to != null) {
            notificationobj.resident_id = employeeObj.reporting_to;
            if (is_eligible) {
              notificationobj.title = "Send Request For Leave";
            } else {
              notificationobj.title = "Send Request For Emergency Leave";
            }

            notificationobj.message =
              employeeObj.first_name +
              " " +
              employeeObj.last_name +
              " " +
              " has placed a request for leave";
            makePostRequest("employee/notificationtoadmin", notificationobj)
              .then((response) => {
                console.log("Notification Placed");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          closeModal();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [employeeObj, setEmployeeObj] = React.useState({});
  const [documentsprofile, setDocuments] = useState([]);
  const [star, setStar] = React.useState(0);
  const [warningCount, setWarningCount] = React.useState(0);
  const [employeeWarning, setEmployeeWarning] = React.useState([]);
  const [employeeLeave, setEmployeeLeave] = React.useState([]);
  const [orderList, setOrderList] = React.useState([]);
  const [orderListMarket, setOrderListMarket] = React.useState([]);
  const [is_eligible, setIs_eligible] = React.useState(false);
  const [selectedDatecafeto, setSelectedDatecafeto] = useState(null);
  const [selectedDatecafefrom, setSelectedDatecafefrom] = useState(null);
  const [totalstarspend, setTotalstarspend] = useState(0);
  const [totalstarspendNew, setTotalstarspendNew] = useState(0);

  const [totalmarketstarspendnew, setTotalmarketstarspendNew] = useState(0);
  const [totalstarrefunded, setTotalstarrefunded] = useState(0);
  const [totalstardeducted, setTotalstardeducted] = useState(0);
  const [totalstarwasted, setTotalstarwasted] = useState(0);

  const [totalmarketstarspend, setTotalmarketstarspend] = useState(0);
  const [masterStarAmount, setMasterStarAmount] = React.useState(0);
  const [masterDollarAmount, setMasterDollarAmount] = React.useState(0);
  const [purchase_star_quantity, setPurchaseStarQuantity] = React.useState(0);
  const [purchase_star_dollar_Amount, setPurchaseStarDollarAmount] =
    React.useState(0);
  const [priceErrMsg, setPriceErrMsg] = React.useState("");

  const getProfileDetails = async () => {
    let joiningdate;
    let type;
    await makeGetRequest(
      "employee/getemployee/" + localStorage.getItem("userId")
    )
      .then((response) => {
        if (response.data.data[0]) {
          setEmployeeObj(response.data.data[0]);
          setDocuments(response.data.data[0].documents);
          joiningdate = response.data.data[0].date_of_joining;
          type = response.data.data[0].travel_eligibility;
        } else {
          setEmployeeObj([]);
          setDocuments([]);
        }
      })
      .catch((err) => {
        setEmployeeObj([]);
        setDocuments([]);
      });

    await makeGetRequest(
      "employee/getemployeeleaveeligibility/" + joiningdate + "/" + type
    )
      .then((response1) => {
        if (response1.data.data[0]) {
          setIs_eligible(false);
        } else {
          setIs_eligible(true);
        }
      })
      .catch((err) => {
        setIs_eligible(false);
      });
  };

  async function getEmployeeStar() {
    var userid = parseInt(localStorage.getItem("userId"));

    await makeGetRequest("employee/getstarcountforapp/" + userid)
      .then((response) => {
        console.log(response)
        if (response.data.data) {
          if (response.data.data) {
            localStorage.setItem("starcount", response.data.data.total_stars);
            setStar(response.data.data.groupedData);
            setTotalstarspendNew(response.data.data.totalSum);
            setTotalstardeducted(response.data.data.totaldeductSum);
            setTotalstarwasted(response.data.data.totalwasteFoodSum);
            setTotalstarrefunded(response.data.data.totalrefundSum)
          } else {
            localStorage.setItem("starcount", 0);
            setStar(0);
          }
        }
      })
      .catch((err) => {
        setStar(0);
      });
  }

  async function getEmployeeWarningCount() {
    var userid = parseInt(localStorage.getItem("userId"));
    await makeGetRequest("employee/getemployeewarning/" + userid)
      .then((response) => {
        if (response.data.data) {
          if (response.data.data) {
            setWarningCount(response.data.data.length);
            setEmployeeWarning(response.data.data);
          } else {
            setWarningCount(0);
            setEmployeeWarning([]);
          }
        }
      })
      .catch((err) => {
        setWarningCount(0);
        setEmployeeWarning([]);
      });
  }

  async function getEmployeeLeaveHist() {
    var userid = parseInt(localStorage.getItem("userId"));
    await makeGetRequest("employee/getemployeeleave/" + userid)
      .then((response) => {
        if (response.data.data) {
          if (response.data.data) {
            setEmployeeLeave(response.data.data);
          } else {
            setEmployeeLeave([]);
          }
        }
      })
      .catch((err) => {
        setEmployeeLeave([]);
      });
  }

  async function getEmployeeActiveOrder(fromdate, todate) {
    let from_date;
    let to_date;
    if (fromdate != null) {
      from_date = fromdate;
    } else {
      from_date = "null";
    }
    if (todate != null) {
      to_date = todate;
    } else {
      to_date = "null";
    }
    var userid = parseInt(localStorage.getItem("userId"));
    await makeGetRequest(
      "starcafe/getorderemployee/null/Completed/" +
        userid +
        "/0/" +
        from_date +
        "/" +
        to_date
    )
      .then((response) => {
        if (response.data.data.groupedData) {
          // let i,
          //   starcafetotalstarspend = 0;
          // for (i = 0; i < response.data.data.groupedData.length; i++) {
          //   starcafetotalstarspend =
          //     starcafetotalstarspend +
          //     response.data.data.groupedData[i].totalstarspend;
          // }
          setTotalstarspend(parseInt(response.data.data.totalSum)+parseInt(response.data.data.totalwasteFoodSum));
          setTotalmarketstarspendNew(parseInt(response.data.data.totalstarmarketSum));
          setTotalstarrefunded(response.data.data.totalrefundSum)
          setTotalstardeducted(response.data.data.totaldeductSum)
          setOrderList(response.data.data.groupedData);
        } else {
          setOrderList([]);
        }
      })
      .catch((err) => {
        setOrderList([]);
      });
  }

  async function getEmployeeMarketCompleteOrder() {
    var userid = parseInt(localStorage.getItem("userId"));
    await makeGetRequest(
      "starmarket/getorderemployee/null/Completed/" + userid + "/0"
    )
      .then((response) => {
        if (response.data.data) {
          let i,
            starmarkettotalstarspend = 0;
          for (i = 0; i < response.data.data.length; i++) {
            console.log(response.data.data[i].totalstarspend);
            starmarkettotalstarspend =
              starmarkettotalstarspend + response.data.data[i].totalstarspend;
          }
          setTotalmarketstarspend(starmarkettotalstarspend);
          setOrderListMarket(response.data.data);
        } else {
          setOrderListMarket([]);
        }
      })
      .catch((err) => {
        setOrderListMarket([]);
      });
  }

  const sendMessageToReactNative = async () => {
    const willUpdate = await swal({
      title: "Log Out",
      text: "Are you sure you want to log out?",
      icon: "warning",
      buttons: ["No", "Yes"],
    });

    if (willUpdate) {
      try {
        const employeecode = localStorage.getItem("employeecode");
        const password = localStorage.getItem("password");

        // Step 2: Clear the localStorage
        localStorage.clear();

        // Step 3: Set the values back into localStorage
        // if (employeecode !== null) {
        //   localStorage.setItem("employeecode", employeecode);
        // }
        // if (password !== null) {
        //   localStorage.setItem("password", password);
        // }
        sessionStorage.clear();
        const message = { type: "FROM_REACT_JS", data: {} };
        window.ReactNativeWebView.postMessage(JSON.stringify(message));
        navigate("/LoginMobile");
      } catch (error) {
        navigate("/LoginMobile");
        console.log(error);
      }
    }
  };

  // async function uploadProfilePic(e) {
  //   // alert("upload function");
  //   // alert(e);
  //   let obj;
  //   // let obj = JSON.parse(e);
  //   // Parse JSON string to object if necessary (assuming `e` is a JSON string)
  //   try {
  //     obj = typeof e === "string" ? JSON.parse(e) : e;
  //     // alert("Parsed object: " + JSON.stringify(obj)); // Debugging alert
  //   } catch (error) {
  //     // alert("Error parsing JSON: " + error.message);
  //     return; //
  //   }

  //   const getfile = obj.file;
  //   const gettype = obj.type;

  //   // alert(gettype);
  //   // alert("getfile");
  //   // alert(getfile);

  //   const newDocument = { "type": gettype, "date": moment().format('YYYY-MM-DD'), "file": getfile };

  //   // alert(JSON.stringify(newDocument));
  //   //call employee update app
  //   var employeeid = localStorage.getItem("userId");
  //   let inputdata = {
  //     employee_id: employeeid,
  //     documents: [newDocument],
  //   };

  //   // alert("inputData", JSON.stringify(inputdata));

  //   await makePostRequest("employee/updateDocument", inputdata)
  //     .then((response) => {
  //       // alert(response.data);
  //       if (response.data.data !== undefined) {
  //         alert("update success ", response.data.data);
  //       }
  //       //setShowLoader(false);
  //     })
  //     .catch((err) => {
  //       alert("update document error", err);
  //     });
  // }

  //setShowLoader(false);

  //function to open document picker in react native

  const fileUploadToReactNative = (type) => {
    const message = { type: type, data: "download" };
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  };

  const handleDateChangecafefrom = (date) => {
    const formattedDate =
      date.$y + "-" + (parseInt(date.$M) + 1) + "-" + date.$D;
    setSelectedDatecafefrom(formattedDate);
  };

  const handleDateChangecafeto = (date) => {
    const formattedDate =
      date.$y + "-" + (parseInt(date.$M) + 1) + "-" + date.$D;
    if (moment(formattedDate).isBefore(selectedDatecafefrom)) {
      swal("Warning", "From Date is earlier than To Date.", "warning", {
        timer: 2000,
        buttons: false,
      });
    } else {
      setSelectedDatecafeto(formattedDate);
      getEmployeeActiveOrder(selectedDatecafefrom, formattedDate);
    }
  };

  function getStarAmount() {
    makeGetRequest("employee/getmasterstarcount/")
      .then((response) => {
        if (response.data) {
          if (response.data.data[0] == undefined) {
            swal(
              "Set Exchange Stars",
              "First set master star rate with dollar",
              "warning",
              {
                timer: 4000,
                buttons: false,
              }
            );
            //navigate("/Dashboard");
          } else {
            setMasterStarAmount(response.data.data[0].start_count);
            setMasterDollarAmount(response.data.data[0].dollar_amount);
            //setUpload(!upload);
          }
          // console.log(response.data.data[0]);
        }
        //setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
      });
  }

  function handleChangeDollarAmount(value) {
    setPriceErrMsg("");
    setPurchaseStarQuantity(value);

    let regEx = /^\d+$/;
    if (regEx.test(value)) {
      if (value * masterDollarAmount <= employeeObj.balance_salary)
        setPurchaseStarDollarAmount(value * masterDollarAmount);
      else {
        setPurchaseStarDollarAmount(value * masterDollarAmount);
        setPriceErrMsg("You don't have enough balance");
      }
    } else {
      if (value) setPriceErrMsg("Invalid Value");
      else {
        setPriceErrMsg("Invalid Value");
        setPurchaseStarDollarAmount(0);
      }
    }
  }

  async function reloadStars() {
    if (priceErrMsg == "") {
      //setShowLoader(true);
      let totalStars =
        parseInt(purchase_star_quantity) + parseInt(star.total_stars);
      let starInputData = {
        employee_id: employeeObj.employee_id,
        total_stars: totalStars,
        total_star_purchase: purchase_star_quantity,
        total_star_spent: star.total_star_spent,
      };
      await makePostRequest("employee/insertemployeestar", starInputData)
        .then((response) => {})
        .catch((err) => {
          console.log(err);
          //setShowLoader(false);
        });
      let inputdata = {
        employee_id: employeeObj.employee_id,
        star_reloaded: parseInt(purchase_star_quantity),
        amount_in_dollars: parseInt(purchase_star_dollar_Amount),
        reloaded_date: moment(),
      };
      await makePostRequest("employee/insertreloadhist/", inputdata)
        .then((response) => {
          if (response.data) {
            console.log(response.data.data[0]);
          }
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
          //setShowLoader(false);
        });

      let balanceSalary =
        parseFloat(employeeObj.balance_salary) -
        parseInt(purchase_star_dollar_Amount);

      await makePostRequest("employee/updateemployeestatus", {
        employee_id: employeeObj.employee_id,
        balance_salary: balanceSalary,
      })
        .then((response) => {
          if (response.data) {
            setPurchaseStarDollarAmount(0);
            setPurchaseStarQuantity(0);
            setPriceErrMsg("");
            swal("Success", "Star Reloaded Successfully", "success", {
              timer: 3000,
              buttons: false,
            });
            //setOpen3(false);
          }
        })
        .catch((err) => {
          console.log(err);
          //setShowLoader(false);
        });
      //getStarActivityDetails();
      //getStarSpendHistory();
      //setShowLoader(false);
    }
  }

  useEffect(() => {
    getEmployeeLeaveHist();
    getEmployeeWarningCount();
    getProfileDetails();
    getEmployeeStar();
    getEmployeeActiveOrder();
    getEmployeeMarketCompleteOrder();
    getStarAmount();
    // window.uploadfile = function (result) {
    //   // alert("uploadfile received:");
    //   // alert(JSON.stringify(result));
    //   // uploadProfilePic(result);
    // };
  }, []);

  // const deleteDocument = async (type) => {
  //   alert("in delete" + type);
  //   const filteredDocuments = documentsprofile.filter(doc => doc.type !== type);
  //   alert(JSON.stringify(filteredDocuments));
  //   var employeeid = localStorage.getItem("userId");
  //   let inputdata = {
  //     employee_id: employeeid,
  //     documents: filteredDocuments,
  //   };
  //   await makePostRequest("employee/removeDocument", inputdata)
  //     .then((response) => {
  //       alert(response.data);
  //       if (response.data.data !== undefined) {
  //         alert("update success ", response.data.data);
  //       }
  //       //setShowLoader(false);
  //     })
  //     .catch((err) => {
  //       alert("update document error", err);
  //     });

  // }

  const renderDocuments = (type) => {
    const filteredDocs = documentsprofile.filter((doc) => doc.type === type);

    return filteredDocs.length > 0
      ? filteredDocs.map((document, index) => (
          <div className="docListInner" key={index}>
            <div className="docListLeft">
              <div className="docIcon">
                <PictureAsPdfOutlinedIcon />
              </div>
              <div className="docName">
                {/* <p>{document.type}</p> */}
                <p>
                  {document.file.substring(document.file.lastIndexOf("/") + 1)}
                </p>
                <p>Uploaded on {document.date}</p>
              </div>
            </div>
            <div className="red">
              <DownloadIcon onClick={() => fileUploadToReactNative(document)} />
              {/* <DeleteOutlinedIcon key={index} onClick={() => deleteDocument(document.type)} /> */}
              {/*  */}
            </div>
          </div>
        ))
      : null;
    // <div className="docListInner">
    //   <h5 className="docHeading">{type}</h5>
    //   <FileUploadOutlinedIcon onClick={() => fileUploadToReactNative(type)} />
    // </div>
  };

  return (
    <>
      <div className="mobileCssMain">
        <div className="dashMain forBottomTabOnly">
          <MobileHeader />
          <div className="">
            <div className="container">
              <div className="wrapper">
                <div className="profileBox" style={{ paddingTop: 0 }}>
                  <div className="profileName">
                    {employeeObj ? (
                      <h2>
                        Hello, {employeeObj.first_name} {employeeObj.last_name}
                      </h2>
                    ) : (
                      <h2>Hello,</h2>
                    )}
                    <IconButton className="TouchableIconBtn">
                      {" "}
                      <div
                        onClick={() => sendMessageToReactNative()}
                        className="logOutIcon"
                      >
                        <LogoutSharpIcon className="logoutIcon" />
                      </div>
                    </IconButton>
                  </div>
                  <div className="profileDetailsArrow addedResetTx">
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={() => navigate("/ProfileDetailsMobile")}
                    >
                      <h4>Personal Details </h4>
                      <div className="profileArrowIcon">
                        <IconButton className="TouchableIconBtn">
                          <ArrowForwardIosSharpIcon />
                        </IconButton>
                      </div>
                    </div>

                    <h2 onClick={() => navigate("/ResetPassword")}>
                      Reset Password
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div className="wrapper">
              <div className="profileTabs">
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      className="profileTopTab"
                      value={value}
                      onChange={handleChange}
                    >
                      <Tab
                        label="Statistics"
                        className="toptabActive"
                        {...a11yProps(0)}
                      />
                      <Tab label="Leave" {...a11yProps(1)} />
                      <Tab label="Documents" {...a11yProps(2)} />
                      <Tab label="Buying History" {...a11yProps(3)} />
                      <Tab label="Non Conformance (nc)" {...a11yProps(4)} />
                      <Tab label="Reload Stars" {...a11yProps(5)} />
                    </Tabs>
                  </Box>

                  <CustomTabPanel value={value} index={0}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <div className="profileCard">
                          <span className="starSummaryStar">
                            <StarIcon />
                          </span>
                          {star.monthly_allowance?
                          <h2>{star.monthly_allowance}</h2>:
                          <h2>0</h2>}
                          
                          <b>Monthly Allowance</b>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          className="profileCard"
                          onClick={() => forTabchnage(4)}
                        >
                          <h2>{warningCount}</h2>
                          <b>Warnings</b>
                        </div>
                        {/* <div className="warningCount">
                          <h4>2</h4>
                        </div> */}
                      </Grid>
                    </Grid>
                    <div className="screenTitle starTitle">
                      <h2>Star Summary</h2>
                    </div>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <div className="profileCard">
                          <h2>
                            <span className="starSummaryStar">
                              <StarIcon />
                            </span>
                            {star.total_stars}
                          </h2>
                          <b>Stars Available</b>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          className="profileCard"
                          onClick={() => forTabchnage(3)}
                        >
                          <h2>
                            <span className="starSummaryStar">
                              <StarIcon />
                            </span>
                            {parseInt(totalstarspendNew)+parseInt(totalstarwasted)}
                          </h2>
                          <b>Stars Spent Cafe </b>
                        </div>
                      </Grid>

                      <Grid item xs={6}>
                        <div
                          className="profileCard"
                          onClick={() => forTabchnage(3)}
                        >
                          <h2>
                            <span className="starSummaryStar">
                              <StarIcon />
                            </span>
                            {parseInt(totalmarketstarspendnew)}
                          </h2>
                          <b>Stars Spent Market </b>
                        </div>
                      </Grid>

                      <Grid item xs={6}>
                        <div className="profileCard">
                          <h2>
                            <span className="starSummaryStar">
                              <StarIcon />
                            </span>
                            {totalstarrefunded}
                          </h2>
                          <b>Stars Refunded</b>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          className="profileCard"
                          onClick={() => forTabchnage(3)}
                        >
                          <h2>
                            <span className="starSummaryStar">
                              <StarIcon />
                            </span>
                            {totalstardeducted}
                          </h2>
                          <b>Stars Deducted</b>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          className="profileCard"
                          onClick={() => forTabchnage(3)}
                        >
                          <h2>
                            <span className="starSummaryStar">
                              <StarIcon />
                            </span>
                            {totalstarwasted}
                          </h2>
                          <b>Stars Wasted</b>
                        </div>
                      </Grid>

                      {/* <Grid item xs={6}>
                        <div className="profileCard">
                          <h2>
                            <span className="starSummaryStar">
                              <StarIcon />
                            </span>
                            {star.total_star_purchase}
                          </h2>
                          <b>Requested Stars</b>
                        </div>
                      </Grid> */}
                    </Grid>
                  </CustomTabPanel>

                  <CustomTabPanel value={value} index={1}>
                    <div className="leaveData">
                      {is_eligible ? (
                        <Button
                          onClick={openModal}
                          className="loginBt addLeaveBtn"
                        >
                          Request a leave <AddCircleIcon className="addIcon" />{" "}
                        </Button>
                      ) : (
                        <Button
                          onClick={openModal}
                          className="loginBt addLeaveBtn"
                        >
                          Request a Emergency leave{" "}
                          <AddCircleIcon className="addIcon" />{" "}
                        </Button>
                      )}
                      <div className="leaveFilter">
                        <h3>Leave History</h3>
                        {/* <IconButton className="TouchableIconBtn">
                          <TuneIcon style={{ color: "#000000" }} />
                        </IconButton> */}
                      </div>
                      {employeeLeave.length > 0 ? (
                        <>
                          {employeeLeave.map((item, index) => (
                            <div
                              onClick={() =>
                                navigate("/LeaveDetails", {
                                  state: { data: item },
                                })
                              }
                              key={index}
                              className="leaveDataCard"
                            >
                              <div className="leaveDataCard-left">
                                <div className="leaveNewLayoutDate">
                                  <div>
                                    <h5 className="leaveNewLayoutTitle">
                                      Leave Dates
                                    </h5>
                                    <h4>
                                      {/* <span style={{ fontSize:12, color: "#333333" }}>From</span>{" "} */}
                                      {moment(item.fromdate).format(
                                        "YYYY-MM-DD"
                                      )}{" "}
                                      <span
                                        style={{
                                          fontSize: 12,
                                          color: "#333333",
                                        }}
                                      >
                                        To
                                      </span>{" "}
                                      {moment(item.todate).format("YYYY-MM-DD")}
                                    </h4>
                                  </div>
                                  <div className="leaveDataCard-right">
                                    <h5 className="leaveNewLayoutTitle">
                                      Status
                                    </h5>
                                    {item.status == "Pending" ? (
                                      <p style={{ color: "#ffb940" }}>
                                        {item.status}
                                      </p>
                                    ) : (
                                      <>
                                        {item.status == "Approved" ? (
                                          <p style={{ color: "#04cf04" }}>
                                            {item.status}
                                          </p>
                                        ) : (
                                          <p style={{ color: "Red" }}>
                                            {item.status}
                                          </p>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                                <h4>{item.leave_reason}</h4>
                                <p>
                                  Applied On{" "}
                                  {moment(item.created_at).format("YYYY-MM-DD")}
                                </p>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                      {/* <div className="leaveDataCard">
                      <div className="leaveDataCard-left">
                        <h4>Medical Reason</h4>
                        <p>Applied On 8/2/19</p>
                      </div>
                      <div className="leaveDataCard-right">
                        <h4>04 Feb 2024</h4>
                        <p className="green">Approved</p>
                      </div>
                    </div> */}
                    </div>
                  </CustomTabPanel>

                  <CustomTabPanel value={value} index={2}>
                    <div className="docData  addLeaveBtn">
                      {/* <Button className="loginBt addLeaveBtn">
                      Upload Documents <UploadFileIcon className="addIcon" />{" "}
                    </Button> */}
                      <div className="leaveFilter">
                        <h3>Documents</h3>
                        {/* <p>1 document pending</p> */}
                      </div>

                      {documentsprofile && documentsprofile.length > 0 ? (
                        <div className="docListOuter">
                          <p>Joining Letter</p>
                          {renderDocuments("Joining Letter")}
                          <div className="divider"></div>
                          <p>Appointment Letter</p>
                          {renderDocuments("Appointment Letter")}
                          <div className="divider"></div>
                          <p>Passport</p>
                          {renderDocuments("Passport")}
                          <div className="divider"></div>
                          <p>Visa</p>
                          {renderDocuments("Visa")}
                          <div className="divider"></div>
                          <p>Other Documents</p>
                          {renderDocuments("Other")}
                        </div>
                      ) : null}

                      {/* <div className="docListOuter">
                        <div className="docListInner">
                          <h5 className="docHeading">Joining Letter</h5>
                          <FileUploadOutlinedIcon
                            onClick={() => fileUploadToReactNative('Joining Letter')}
                          />
                        </div>
                        {documents.map((document, index) => (
                        <div className="docListInner">
                          <div className="docListLeft">
                            <div className="docIcon">
                              <PictureAsPdfOutlinedIcon />
                            </div>
                            {document.type === "Joining Letter" && (
                              
                            <div className="docName">
                              <p>{document.type}</p>
                              <p>{document.file.substring(document.file.lastIndexOf('/') + 1)}</p>
                              <p>Uploaded on {document.date}</p>
                            </div>
                             )}
                          </div>
                          <div className="red">
                            <DeleteOutlinedIcon />
                          </div>
                        </div>
                        ))}

                         <div className="divider"></div>
                        <div className="docListInner">
                          <h5 className="docHeading">Appointment Letter</h5>
                          <FileUploadOutlinedIcon
                            onClick={() => fileUploadToReactNative('Appointment Letter')} />
                        </div>
                        <div className="divider"></div>
                        <div className="docListInner">
                          <h5 className="docHeading">Passport</h5>
                          <FileUploadOutlinedIcon
                            onClick={() => fileUploadToReactNative('Passport')} />
                        </div>
                        <div className="docListInner">
                          <div className="docListLeft">
                            <div className="docIcon">
                              <PictureAsPdfOutlinedIcon />
                            </div>
                            <div className="docName">
                              <p>johndoe_passport</p>
                              <p>Uploaded on 01/11/2022 04:35pm</p>
                            </div>
                          </div>
                          <div className="red">
                            <DeleteOutlinedIcon />
                          </div>
                        </div>
                        <div className="divider"></div>
                        <div className="docListInner">
                          <h5 className="docHeading">Other Documents</h5>
                          <FileUploadOutlinedIcon onClick={() => fileUploadToReactNative('Other')} />
                        </div>
                        <div className="docListInner">
                          <div className="docListLeft">
                            <div className="docIcon">
                              <PictureAsPdfOutlinedIcon />
                            </div>
                            <div className="docName">
                              <p>other_documents 1</p>
                              <p>Uploaded on 01/11/2022 04:35pm</p>
                            </div>
                          </div>
                          <div className="red">
                            <DeleteOutlinedIcon />
                          </div>
                        </div>
                        <div className="docListInner">
                          <div className="docListLeft">
                            <div className="docIcon">
                              <PictureAsPdfOutlinedIcon />
                            </div>
                            <div className="docName">
                              <p>other_documents 2</p>
                              <p>Uploaded on 01/11/2022 04:35pm</p>
                            </div>
                          </div>
                          <div className="red">
                            <DeleteOutlinedIcon />
                          </div>
                        </div> 
                      </div> */}
                    </div>
                  </CustomTabPanel>

                  <CustomTabPanel value={value} index={3}>
                    <div className="buyHistory">
                      <div className="leaveFilter">
                        <h3>Orders</h3>
                      </div>
                      <Box sx={{ width: "100%", float: "left" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={value1}
                            onChange={handleChangeInner}
                            aria-label="basic tabs example"
                          >
                            <Tab
                              icon={
                                <div className="tabsImgOuter">
                                  <img src={starCafe} />
                                </div>
                              }
                              label="Star Market"
                              {...a11yProps1(0)}
                            />
                            <Tab
                              icon={
                                <div className="tabsImgOuter">
                                  <img src={starMarket} />
                                </div>
                              }
                              label="Star Cafe"
                              {...a11yProps1(1)}
                            />
                            <Tab
                              icon={
                                <div className="tabsImgOuter">
                                  <img src={barberShop} />
                                </div>
                              }
                              label="Barber Shop"
                              {...a11yProps1(2)}
                            />
                            <Tab
                              icon={
                                <div className="tabsImgOuter">
                                  <img src={Sports} />
                                </div>
                              }
                              label="Sports"
                              {...a11yProps1(3)}
                            />
                          </Tabs>
                        </Box>

                        {/* Star Market Content Here */}
                        <CustomTabPanel value={value1} index={0}>
                          <div className="activeOrdersList">
                            <p className="spendingSta">
                              Total Star Spend <b>{totalmarketstarspend}</b>
                            </p>
                            <div className="fromDToDateMain">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <DatePicker
                                    label="From Date"
                                    selected={selectedDatecafefrom}
                                    onChange={(date) =>
                                      setSelectedDatecafefrom(date)
                                    }
                                    dateFormat="YYYY-MM-DD"
                                  />
                                </DemoContainer>
                              </LocalizationProvider>

                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <DatePicker
                                    label="To Date"
                                    selected={selectedDatecafeto}
                                    onChange={(date) =>
                                      setSelectedDatecafeto(date)
                                    }
                                    dateFormat="YYYY-MM-DD"
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </div>
                            <div className="orderMenuList">
                              {orderListMarket.length > 0 ? (
                                <>
                                  {/* Starcafe */}
                                  {orderListMarket.map((item, index) => (
                                    <div
                                      key={index}
                                      className="orderNotiDiv specialMenu orderHis"
                                      onClick={() =>
                                        navigate("/ActiveOrderDetailsMarket", {
                                          state: { id: item.order_id },
                                        })
                                      }
                                    >
                                      <div className="orderListLeftData">
                                        <div className="ordIcoDiv orderListImgOuter">
                                          <img src={cup} />
                                        </div>
                                        <div className="orderListOrderIdData profileOrderCard">
                                          <h3>Order #{item.order_id}</h3>
                                          <p>{item.ordertype}</p>
                                          <div className="starPrice">
                                            <StarIcon className="starIcon" />
                                            <h5> {item.totalstarspend} </h5>
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        <div className="orderListRightData">
                                          <p>{item.orderstatus}</p>
                                        </div>
                                        <div className="orderListRightData">
                                          <p>
                                            {moment(item.created_at).format(
                                              "YYYY-MM-DD HH:mm"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div className="nodatDiv">
                                  <img src={noData} />
                                </div>
                              )}
                            </div>
                          </div>
                        </CustomTabPanel>

                        {/* Star Cafe Content Here */}
                        <CustomTabPanel value={value1} index={1}>
                          <div className="activeOrdersList">
                            <div className="totalStarReNDeduc">
                              <p className="spendingSta">
                                Total Spend <b>{totalstarspend}</b>
                              </p>
                              <p className="spendingSta">
                                Total Deduct <b>{totalstardeducted}</b>
                              </p>
                              <p className="spendingSta">
                                Total Refunded <b>{totalstarrefunded}</b>
                              </p>
                            </div>
                            <div className="fromDToDateMain">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <DatePicker
                                    label="From Date"
                                    selected={selectedDatecafefrom}
                                    //onChange={(date) => setSelectedDatecafefrom(date)}
                                    onChange={handleDateChangecafefrom}
                                    dateFormat="YYYY-MM-DD"
                                  />
                                </DemoContainer>
                              </LocalizationProvider>

                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <DatePicker
                                    label="To Date"
                                    selected={selectedDatecafeto}
                                    //onChange={(date) => setSelectedDatecafeto(date)}
                                    onChange={handleDateChangecafeto}
                                    dateFormat="YYYY-MM-DD"
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </div>
                            <div className="orderMenuList">
                              {orderList.length > 0 ? (
                                <>
                                  {/* Starcafe */}
                                  {orderList.map((item, index) => (
                                    <div
                                      key={index}
                                      className="orderNotiDiv specialMenu orderHis tpAlign bottomCon"
                                      onClick={() =>
                                        navigate("/ActiveOrderDetails", {
                                          state: { id: item.order_id },
                                        })
                                      }
                                    >
                                      <div className="orderListLeftData">
                                        <div className="ordIcoDiv orderListImgOuter">
                                          <img src={cup} />
                                        </div>
                                        <div className="orderListOrderIdData  profileOrderCard">
                                          <h3>Order #{item.order_id}</h3>
                                          <p>{item.ordertype}</p>
                                          <div className="starPrice">
                                            <StarIcon className="starIcon" />
                                            <h5> {item.totalstarspend} </h5>
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        <div className="orderListRightData">
                                          <p>{item.orderstatus}</p>
                                        </div>
                                        <div className="orderListRightData">
                                          <p>
                                            {moment(item.created_at).format(
                                              "YYYY-MM-DD HH:mm"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="deducRefnd">
                                        <p class="spendingSta">
                                        Refunded{" "}
                                        <b style={{ color: "#27b727" }}>{item.sum?item.sum:0}</b>
                                        </p>
                                        <p class="spendingSta">
                                        Wasted <b>{item.orderstatus =="NotPickedUp" ?item.totalstarspend:0}</b>
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : null}
                            </div>
                          </div>
                        </CustomTabPanel>

                        <CustomTabPanel value={value1} index={2}>
                          Barber Shop Content Here
                        </CustomTabPanel>

                        <CustomTabPanel value={value1} index={3}>
                          Sports Tab Content Here
                        </CustomTabPanel>
                      </Box>
                    </div>
                  </CustomTabPanel>

                  <CustomTabPanel value={value} index={4}>
                    <div className="warningData">
                      {/* <div className="nodatDiv">
                        <img src={noData} />
                      </div> */}
                      {employeeWarning.length > 0 ? (
                        <>
                          {employeeWarning.map((item, index) => (
                            <div
                              key={index}
                              onClick={() =>
                                navigate("/WarningDetails", {
                                  state: {
                                    data: item,
                                    profilepic: employeeObj.profile_pic,
                                  },
                                })
                              }
                              className="warnCard"
                            >
                              <h2>{item.subject}</h2>
                              <h6>Reference No. {item.warning_id}</h6>
                              <p>
                                {moment(item.created_at).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </p>
                            </div>
                          ))}
                        </>
                      ) : null}

                      {/* <div className="warnCard">
                      <h2>Misuse of Office Premises</h2>
                      <h6>Reference No. 145</h6>
                      <p>Today, 08:20am</p>
                    </div>
                    <div className="warnCard">
                      <h2>Misuse of Office Premises</h2>
                      <h6>Reference No. 145</h6>
                      <p>Today, 08:20am</p>
                    </div> */}
                    </div>
                  </CustomTabPanel>

                  <CustomTabPanel value={value} index={5}>
                    <div className="reloadStarMainMob">
                      <Grid container spacing={2}>
                        <div class="popUpExchImgOuter">
                          <div class="setExchImgDiv">
                            <img src={exchaneBlack} />
                          </div>
                          <h2>
                            Exchange Star Rate{" "}
                            <b style={{ color: "#c82d33", fontSize: 16 }}>
                              {masterStarAmount} Star=₹ {masterDollarAmount}
                            </b>
                          </h2>
                        </div>

                        <Grid item xs={6} md={6}>
                          <div className="addStarContD">
                            <b className="startCount">
                              <StarIcon /> {star.total_star_spent}
                            </b>
                            <p>Stars Spent</p>
                          </div>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <div className="addStarContD">
                            <b className="startCount">
                              <StarIcon /> {star.total_stars}
                            </b>
                            <p>Available Stars</p>
                          </div>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <label className="pageLabel">
                            Enter Star Amount (*)
                          </label>
                          <input
                            type="text"
                            placeholder="Star Quantity"
                            value={purchase_star_quantity}
                            onChange={(e) =>
                              handleChangeDollarAmount(e.target.value)
                            }
                            className="prodSearchInpt"
                          />
                          <span style={{ color: "red", fontSize: 14 }}>
                            {priceErrMsg}
                          </span>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <label className="pageLabel">
                            Enter Rupees Amount in INR (₹)
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Rupees Amount in INR (₹)"
                            value={purchase_star_dollar_Amount}
                            className="prodSearchInpt"
                            disabled
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{ textAlign: "center" }}
                          className="reloadStiBt"
                        >
                          <span>
                            <Button
                              className="modalBtSmallWidth"
                              type="submit"
                              onClick={() => reloadStars()}
                            >
                              Reload Stars
                            </Button>
                          </span>
                        </Grid>
                      </Grid>
                    </div>
                  </CustomTabPanel>

                  <BottomTabsPage />
                </Box>
              </div>
            </div>

            <Modal isOpen={isOpen}>
              <div className="modal-footer">
                <IconButton onClick={closeModal} className="TouchableIconBtn">
                  <CloseOutlinedIcon className="closeBtn" />
                </IconButton>
              </div>
              <div className="leaveAppForm">
                <h3>Leave Application</h3>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  enableReinitialize
                >
                  {(formik) => (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormikControl
                            control="input"
                            type="date"
                            label="From Date"
                            labelClass="loginLable"
                            name="fromdate"
                            className="loginInput"
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <FormikControl
                            control="input"
                            type="date"
                            label="To Date"
                            labelClass="loginLable"
                            name="todate"
                            className="loginInput"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FormikControl
                            control="input"
                            type="text"
                            label="Enter Subject"
                            labelClass="loginLable"
                            name="subject"
                            className="loginInput"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FormikControl
                            control="textarea"
                            type="textarea"
                            label="Description"
                            labelClass="loginLable"
                            name="description"
                            className="txtAreaInput"
                          />
                        </Grid>

                        {/* <Grid item xs={12}>
                          <FormikControl
                            control="select"
                            type="input"
                            label="Reporting To"
                            labelClass="loginLable"
                            name="reporting"
                            options={dropdownOptions}
                            className="loginInput"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikControl
                            control="select"
                            type="input"
                            label="Authority"
                            labelClass="loginLable"
                            name="authority"
                            options={dropdownOptions}
                            className="loginInput"
                          />
                        </Grid> */}
                      </Grid>
                      <Button className="loginBt" type="submit">
                        Send
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
