import React from "react";
import { Button, Grid, colors } from "@mui/material";
import { useNavigate } from "react-router-dom";
import loginLogo from "../../../assets/images/logo.png";
import loginLeftImg from "../../../assets/images/loginLeftImg.png";
import { makePostRequest } from "../../../utils/utils";
import loaderImg from "../../../assets/images/logo.png";

function EnterOtp() {
  const navigate = useNavigate();
  const [otpErrMsg, setOtpErrMsg] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const [otp, setOtp] = React.useState("");

  async function handleChangeOtp(otp) {
    setOtpErrMsg("");
    const regex = /^[0-9\b]+$/;
    // alert(otp.length);
    if (regex.test(otp) && otp.length == 4) setOtp(otp);
    else setOtpErrMsg("Invalid OTP , Please enter correct OTP");
  }

  async function verifyOtp() {
    setShowLoader(true);
    // setOtpErrMsg("");
    let inputData = {
      verification_code: otp,
    };
    makePostRequest("/superadmin/verifyresetPassword", inputData)
      .then((response) => {
        if (response.data.data) {
          console.log(response.data.data);
          localStorage.setItem("forgotUserId", response.data.data.employee_id);
          localStorage.setItem("schemaName", response.data.data.schemaname);
          setShowLoader(false);
          navigate("/ChangePassword");
        }
      })
      .catch((err) => {
        setOtpErrMsg("Please enter valid otp");
        setShowLoader(false);
      });
  }

  return (
    <>
      <div className="loginMainBody">
        <div className="loginLeft">
          <div className="loginInnerMain">
            <img src={loginLogo} className="loginLogoSt" />
            <h1 className="logoHeading">Star Labels</h1>
            <div className="loginHeadiCont">
              <h4>Reset Your Password</h4>
              <p>Lorem ipsum dolor sit amet consectetur.</p>
            </div>
            <div className="loginInpOuter">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <label className="loginLabel">Enter OTP</label>
                  <input
                    className="loginInput"
                    placeholder="Enter OTP"
                    // type="password"
                    maxLength={4}
                    // value={otp}
                    onChange={(e) => handleChangeOtp(e.target.value)}
                  />
                  <span className="errorSt">{otpErrMsg}</span>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Button className="signInBt" onClick={() => verifyOtp()}>
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={12} md={12}>
                  <p className="donttHavAc" onClick={() => navigate("/")}>
                    <b style={{ color: "#2592d2", cursor: "pointer" }}>
                      Login Here
                    </b>
                  </p>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className="loginRight">
          <img src={loginLeftImg} className="loginRightImg" />
        </div>
        {/* <Button onClick={() => navigate("/Home")}>Go to Home</Button> */}
      </div>

      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default EnterOtp;
