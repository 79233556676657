import React from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import editIco from "../../../assets/images/edit-icon.svg";
import deletIco from "../../../assets/images/delete-icon.svg";
import StarIcon from "@mui/icons-material/Star";
import Pagination from "@mui/material/Pagination";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";

import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import swal from "sweetalert";
import loaderImg from "../../../assets/images/logo.png";

function CompanyAdmins() {
  const navigate = useNavigate();

  const [selected, setSelected] = React.useState(false);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [showLoader, setShowLoader] = React.useState(false);
  const [companyAdminList, setCompanyAdminList] = React.useState([]);
  const [upload, setUpload] = React.useState(false);
  // const [searchString,setSearchString]=React.useState(null);
  const [sortBy, setSortBy] = React.useState("desc");

  async function getAllAdminsList(searchString) {
    if (!searchString) searchString = null;
    setShowLoader(true);
    await makeGetRequest(
      "/superadmin/getadminlist/employee_id/" +
        sortBy +
        "/" +
        searchString +
        "/0/1000"
    )
      .then((response) => {
        if (response.data.data) {
          // console.log(response.data.data)
          // alert(response.data.data.length)
          if (response.data.data.length > 0)
            setCompanyAdminList(response.data.data);
          else setCompanyAdminList([]);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  function editAdmin(adminId, companyName) {
    let company_name = companyName.replace(" ", "").toLowerCase();
    sessionStorage.setItem("schema", companyName);
    navigate("/EditCompanyAdmin/" + adminId);
  }

  async function deleteAdmin(adminId, schemaname) {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record? This process cannot be undone.",
      icon: "warning",
      buttons: ["No", "Yes"],
    });

    if (willDelete) {
      var bodyFormData = {
        employee_id: adminId,
        is_deleted: true,
      };
      await makePostRequest(
        "employee/updateemployeestatus",
        bodyFormData,
        schemaname
      )
        .then((response) => {
          if (response.data) {
            swal("Success", "Record deleted successfully", "success", {
              timer: 1000,
              buttons: false,
            });
            getAllAdminsList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const updateStatus = (event, employee_id, schemaname) => {
    // setChecked(event.target.checked);
    setShowLoader(true);

    let inputdata = {
      employee_id: employee_id,
      status: event.target.checked,
    };

    makePostRequest("employee/updateemployeestatus", inputdata, schemaname)
      .then((response) => {
        if (response.data.data !== undefined) {
          getAllAdminsList(null);
        }

        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };

  React.useEffect(() => {
    getAllAdminsList(null);
  }, []);

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1>Admin Listing</h1>
              <div className="employeeFilter">
                <Button
                  className="pageTopMainBt"
                  onClick={() => navigate("/AddCompanyAdmin")}
                >
                  Add Company Admin
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="visitorSearch">
              <div className="prodFilter">
                <input
                  className="prodSearchInpt"
                  placeholder="Search"
                  onChange={(e) => getAllAdminsList(e.target.value)}
                />
              </div>
              <div className="sortingIcoOut">
                <ToggleButton
                  value="check"
                  selected={selected}
                  onChange={() => {
                    setSelected(!selected);
                  }}
                >
                  <ImportExportIcon
                    className="visitorFilterIco"
                    aria-haspopup="true"
                  />
                </ToggleButton>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div>
              {companyAdminList ? (
                <>
                  {companyAdminList.map((adminObj) => (
                    <div className="mainListDiv">
                      <div className="mainListCont">
                        <h1>
                          {adminObj.first_name} {adminObj.last_name}
                        </h1>
                        <p>{adminObj.email_id}</p>
                        <span className="mainListContact">
                          {adminObj.contact_no}
                        </span>
                      </div>

                      <div className="mainListCont mainListInrCont">
                        <label>Company Name</label>
                        <span className="mainListSubTx">
                          {adminObj.company_name}
                        </span>
                      </div>

                      <div className="mainListCont mainListInrCont">
                        <div className="actionBtnOut">
                          {/* <Tooltip title="Edit" arrow placement="top">
                            <IconButton
                              onClick={() =>
                                editAdmin(
                                  adminObj.employee_id,
                                  adminObj.company_name
                                )
                              }
                            >
                              <img src={editIco} />
                            </IconButton>
                          </Tooltip> */}

                          <Switch
                            {...label}
                            checked={adminObj.status}
                            className="swicthMain"
                            onChange={(event) =>
                              updateStatus(
                                event,
                                adminObj.employee_id,
                                adminObj.schemaname
                              )
                            }
                          />

                          <Tooltip title="Delete" arrow placement="top">
                            <IconButton
                              onClick={() =>
                                deleteAdmin(
                                  adminObj.employee_id,
                                  adminObj.schemaname
                                )
                              }
                            >
                              <img src={deletIco} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={12} className="paginationOuterMain">
            <Pagination count={10} variant="outlined" shape="rounded" />
          </Grid>
        </Grid>
      </div>

      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default CompanyAdmins;
