import { Button, Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from 'react-router-dom';
import StarLogoSmall from "../../assets/images/StarLogoSmall.svg";
import { useLocation } from "react-router-dom";
import moment from "moment";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import avtarPic from "../../../assets/images/avtarPic.png";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { makePostRequest } from '../../../utils/utils';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../../components/FormikControl";
import IconButton from "@mui/material/IconButton";

function CFOWarningDetails() {

    const navigate = useNavigate();

    const location = useLocation();
    const { data, name } = location.state || {};

    const validationSchema = Yup.object({

        cost: Yup.string().required("Cost is required"),
    });

    const sampleReply = {
        employee_id: 0,
        employee_name: "",
        reply: "",
        date: "",
    };

    const reply = {
        warning_id: 0,
        status: "",
        damagecost: 0,
        //reply: [],
    };

    const [inputValue, setInputValue] = useState(0);

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };

    async function Submit() {

        reply.employee_id = data.employee_id;
        reply.warning_id = data.warning_id;
        reply.damagecost = parseInt(inputValue);
        reply.status = "Close";

        makePostRequest("employee/updateemployeewarning", reply)
            .then((response) => {
                if (response.data) {
                    navigate(-1);
                    console.log("changes");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {

    }, []);

    return (
        <>
            <div className="mobileCssMain">
                <div className="container">
                    <div className="wrapper">
                        <div className='managerMain'>
                            <div className="headerData">
                                <Button onClick={() => navigate("/CFOWarningList")}>
                                    <IconButton className="TouchableIconBtn"> <ArrowBackIosIcon />
                                    </IconButton>
                                </Button>
                                <div className="screenTitle">
                                    <h2>Employee Warning Details</h2>
                                </div>
                            </div>
                            <div className="warnCard warnDetail">
                                <h4>Warning Type : <span>{data.warningtype}</span></h4>
                                <div className="warnDetailCard">
                                    <div>
                                        <h2>{data.subject}</h2>
                                        <h6>Reference No. {data.warning_id}</h6>
                                        <p>{moment(data.created_at).format(
                                            "YYYY-MM-DD HH:mm"
                                        )}</p>
                                    </div>
                                    <div>
                                        <h2>₹ {data.damagecost}</h2>
                                        <p>Damage Cost</p>
                                    </div>
                                </div>
                            </div>

                            {/* warning comments section */}
                            <Grid item xs={12} md={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <div className="ticketDetailsMain">
                                            <div className="disctionBottomContMain">
                                                <div className="disctionComntScrol ticketChatMain">
                                                    {data.reply ?
                                                        <>
                                                            {data.reply.map((item, index) => (
                                                                <>
                                                                    {item.employee_id == parseInt(localStorage.getItem("userId").toString(
                                                                    )) ?
                                                                        <>
                                                                            {/* chat left side */}
                                                                            <div className="ticketChatLeft">
                                                                                <div className="parkSocityInner parkSocityInnerTicket">
                                                                                    <div className="parkSocityTop">
                                                                                        <div className="parkSocityTopPro">
                                                                                            <img alt="Remy Sharp" src={avtarPic} />
                                                                                        </div>
                                                                                        <h4>{item.employee_name
                                                                                        }</h4>
                                                                                    </div>
                                                                                    <p className="parContTx">
                                                                                        {item.reply
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                <p className="parContTx parContTxDate ">
                                                                                    {item.date}
                                                                                </p>
                                                                            </div></>
                                                                        : <>
                                                                            {/* chat right side */}
                                                                            <div className="ticketChatRight">
                                                                                <div className="parkSocityInner parkSocityInnerTicket">
                                                                                    <div className="parkSocityTop">
                                                                                        <div className="parkSocityTopPro">
                                                                                            <img alt="Remy Sharp" src={avtarPic} />
                                                                                        </div>
                                                                                        <h4>{item.employee_name
                                                                                        }</h4>
                                                                                    </div>
                                                                                    <div className="titlNDate">
                                                                                        <p className="parContTx">
                                                                                            {item.reply
                                                                                            }
                                                                                        </p>
                                                                                    </div>

                                                                                </div>
                                                                                <p className="parContTx parContTxDate timeAlignRight">
                                                                                    {item.date}
                                                                                </p>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </>
                                                            ))}
                                                        </>
                                                        : null
                                                    }
                                                </div>
                                                {/* <div className="typeComentOut">
                                                    <div className="typeComentOutIco">
                                                        <div className='bottomTextAreaOut'>
                                                            <textarea
                                                                className="textAriaChat"
                                                                type="text"
                                                                id="mainReply"
                                                                placeholder="Comments"
                                                                value={inputValue}
                                                                onChange={handleChange}
                                                            />
                                                            <AttachFileIcon />
                                                        </div>
                                                        <SendIcon onClick={() => Submit()} />
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="typeComentOut">
                                            <label
                                                className='loginLable'>
                                                Enter Cost of Damage
                                            </label>
                                            <input
                                                type="text"
                                                value={inputValue}
                                                className="loginInput"
                                                placeholder="Cost"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <Button className='loginBt' onClick={Submit}>Case Close</Button>
                                    </Grid>
                                    {/* <Grid item xs={12}>                                        
                                        <div className="fixedBtnOuter">
                                            <Button className='loginBt'>Case Close</Button>
                                        </div>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CFOWarningDetails
