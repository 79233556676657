import React from 'react'
import TopHeader from '../SyatemComponents/TopHeader'
import Clock from '../assets/images/Clock.svg'

function ClockOutThankYou() {
  return (
    <>
    <div className="mobileCssMain">
                <div className="wrapper">
                    <TopHeader />
                    <div className="container">
                        <div className="thankOuter">
                            <div className="clockImg">
                                <img src={Clock} />
                            </div>
                            <h6>Thank you</h6>
                            <h2>Employee Name</h2>
                            <p>You have successfully clocked out at </p>
                            <h3>06:30pm</h3>
                            <h4>Have a great day!</h4>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default ClockOutThankYou