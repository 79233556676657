import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarLogoSmall from "../../assets/images/StarLogoSmall.svg";
import { useLocation } from "react-router-dom";
import moment from "moment";
import avtarPic from "../../../assets/images/avtarPic.png";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { makePostRequest } from "../../../utils/utils";
import IconButton from '@mui/material/IconButton';

function WarningDetails() {

  const sampleReply = {
    employee_id: 0,
    employee_name: "",
    reply: "",
    date: "",
  };

  const reply = {
    warning_id: 0,
    reply: [],
  };

  const navigate = useNavigate();

  const location = useLocation();
  const { data, profilepic } = location.state || {};

  const [inputValue, setInputValue] = useState('');
  const [submitted, setSubmitted] = useState(false);


  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  async function Submit() {

    if (inputValue != '') {

      if (data.reply != null) {
        sampleReply.employee_id = parseInt(localStorage.getItem("userId").toString());
        sampleReply.employee_name = localStorage.getItem("username");
        sampleReply.date = moment().format('YYYY-MM-DD HH:mm');
        sampleReply.reply = inputValue;
        data.reply.push(sampleReply)
        console.log(data.reply)
        reply.employee_id = data.employee_id;
        reply.warning_id = data.warning_id;
        reply.reply = data.reply;
        console.log(reply)
      } else {
        let arr = [];
        sampleReply.employee_id = parseInt(localStorage.getItem("userId").toString());
        sampleReply.employee_name = localStorage.getItem("username");
        sampleReply.date = moment().format('YYYY-MM-DD HH:mm');
        sampleReply.reply = inputValue;
        arr.push(sampleReply)
        console.log(arr)
        reply.employee_id = data.employee_id;
        reply.warning_id = data.warning_id;
        reply.reply = arr;
        console.log(reply)
      }

      makePostRequest("employee/updateemployeewarning", reply)
        .then((response) => {

          if (response.data) {
            console.log("send");
            setSubmitted(true);
            setInputValue("");
            //window.location.reload();
          }
        })
        .catch((err) => {
          setSubmitted(true);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    setSubmitted(false);
  }, []);

  return (
    <>
      <div className="mobileCssMain">
        <div className="container">
          <div className="wrapper">
            <div className="headerData">
              <Button onClick={() => navigate(-1)}>
                <IconButton className="TouchableIconBtn"> <ArrowBackIosIcon />
                </IconButton>
              </Button>
              <div className="logo">
                <img src={StarLogoSmall} />
              </div>
              <h5>Star Labels</h5>
            </div>
            <div className="screenTitle">
              <h2>Non Conformance</h2>
            </div>

            <div>
              <div className="warnCard warnDetail">
                <h4>Warning Type : <span>{data.warningtype}</span></h4>
                <div className="warnDetailCard">
                  <div>
                    <h2>{data.subject}</h2>
                    <h6>Reference No. {data.warning_id}</h6>
                    <p>{moment(data.created_at).format(
                      "YYYY-MM-DD HH:mm"
                    )}</p>
                  </div>
                  <div>
                    <h2>₹ {data.damagecost}</h2>
                    <p>Damage Cost</p>
                  </div>

                </div>
                <div style={{ marginTop: 12 }}>
                  <h6 style={{ marginBottom: 5, fontSize: 14, color: "#868686" }}>Description</h6>
                  <p style={{ fontSize: 14, color: "#333333" }}>{data.description}</p>
                </div>
              </div>
            </div>
            {/* warning comments section */}
            <div className="managerMain">
              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <div className="ticketDetailsMain">
                      <div className="disctionBottomContMain">
                        <div className="disctionComntScrol ticketChatMain">
                          <div className="disctionComntScrol ticketChatMain">
                            {data.reply ?
                              <>
                                {data.reply.map((item, index) => (
                                  <>
                                    {item.employee_id == parseInt(localStorage.getItem("userId").toString(
                                    )) ?
                                      <>
                                        {/* chat left side */}
                                        <div className="ticketChatLeft">
                                          <div className="parkSocityInner parkSocityInnerTicket">
                                            <div className="parkSocityTop">
                                              <div className="parkSocityTopPro">
                                                {profilepic ?
                                                  <img
                                                    alt="Remy Sharp"
                                                    src={profilepic}
                                                  /> :
                                                  <img
                                                    alt="Remy Sharp"
                                                    src={avtarPic}
                                                  />
                                                }
                                              </div>
                                              <h4>{item.employee_name
                                              }</h4>
                                            </div>
                                            <p className="parContTx">
                                              {item.reply
                                              }
                                            </p>
                                          </div>
                                          <p className="parContTx parContTxDate ">
                                            {item.date}
                                          </p>
                                        </div></>
                                      : <>
                                        {/* chat right side */}
                                        <div className="ticketChatRight">
                                          <div className="parkSocityInner parkSocityInnerTicket">
                                            <div className="parkSocityTop">
                                              <div className="parkSocityTopPro">
                                                {item.profile_pic ?
                                                  <img
                                                    alt="Remy Sharp"
                                                    src={item.profile_pic}
                                                  /> :
                                                  <img
                                                    alt="Remy Sharp"
                                                    src={avtarPic}
                                                  />
                                                }
                                              </div>
                                              <h4>{item.employee_name
                                              }</h4>
                                            </div>
                                            <div className="titlNDate">
                                              <p className="parContTx">
                                                {item.reply
                                                }
                                              </p>
                                            </div>

                                          </div>
                                          <p className="parContTx parContTxDate timeAlignRight">
                                            {item.date}
                                          </p>
                                        </div>
                                      </>
                                    }
                                  </>
                                ))}
                              </>
                              : null
                            }
                          </div>
                        </div>

                        <div className="typeComentOut">
                          <div className="typeComentOutIco">
                            <div className='bottomTextAreaOut'>
                              <textarea
                                className="textAriaChat"
                                type="text"
                                id="mainReply"
                                placeholder="Comments"
                                value={inputValue}
                                onChange={handleChange}
                              />
                              {/* <AttachFileIcon /> */}
                            </div>
                            <SendIcon onClick={() => Submit()} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>

                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WarningDetails;
