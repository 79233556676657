import React, { useState, useEffect } from "react";
import ImageSlider from "../componentsMobile/ImageSlider/ImageSlider";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate, useLocation } from "react-router-dom";
import milkshake from "../assets/images/milkshake.svg";
import berry from "../assets/images/berry.svg";
import { Button, Grid } from "@mui/material";
import orangejuice from "../assets/images/orangejuice.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarLogoSmall from "../assets/images/StarLogoSmall.svg";
import { makePostRequest, makeGetRequest } from "../../utils/utils";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import {
  ORDER_TYPE_MARKET
} from '../../constants/orderTypes';//'../../../constants/orderTypes'
import { useCart } from "./CartContext";
import { canAddSingleItemToCart } from "../../utils/canAddItemToCart";
import AddIcon from "@mui/icons-material/Add";
import productimg_placeholder from "../assets/images/productimg_placeholder.svg";

import IconButton from '@mui/material/IconButton';

function MarketProductDetails() {

  const {
    marketcart,
    addMarketProduct,
    reduceMarketCategoryCount,
    removeMarketItemFromCart,
  } = useCart();

  const location = useLocation();
  const { Id } = location.state || {};
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [images, setImages] = useState([]);
  console.log(Id)

  const increment = (id, item) => {

    // setItems((prevItems) =>
    //   items.map((item) =>
    //     item.product_id === id ? { ...item, count: item.count + 1 } : item
    //   )
    // );
    // addMarketProduct(item);
    setItems((prevItems) =>
      prevItems.map((item) => {
        if (item.product_id === id && item.count < item.current_quantity) {
          const updatedItem = { ...item, count: item.count + 1 };
          addMarketProduct(updatedItem); // Call addMarketProduct only if the condition is satisfied
          return updatedItem;
        }
        return item;
      })
    );
  };

  const decrement = (id, item) => {
    setItems((prevItems) =>
      // items.map((item) =>
      //   item.subcategory_id === id ? { ...item, count: item.count - 1 } : item
      // )
      displayItems.map((item) => {
        if (item.product_id === id) {
          const newCount = Math.max(item.count - 1, 0);
          const updatedItem = {
            ...item,
            count: newCount === 0 ? 1 : newCount,
            class: "",
          };
          reduceMarketCategoryCount(item);
          if (newCount === 0) {
            removeMarketItemFromCart(item.product_id);
          }
          return updatedItem;
        }
        return item;
      })
    );
    //reduceMarketCategoryCount(item);
  };

  const clickAddBt = (id, item, index) => {

    setItems((prevItems) =>
      items.map((item) =>
        item.product_id === id
          ? { ...item, class: "addedCount" }
          : { ...item, class: "" }
      )
    );
    const updatedItem = { ...item, class: "addedCount" };
    addMarketProduct(updatedItem);
    var element = document.getElementById("addAndOuntMain" + index);
    element.classList.toggle("addedCount");
  };

  async function getItemList() {

    await makeGetRequest("starmarket/getproductlistByid/" + Id)
      .then((response) => {
        if (response.data.data) {
          const updatedData = response.data.data
            .filter((item) => item.current_quantity
              > 0)
            .map((item) => ({
              ...item,
              count: 1,
              class: "",
              type: ORDER_TYPE_MARKET,
            }));
          console.log("api res ")
          console.log(updatedData)
          setItems(updatedData);
          setImages(updatedData[0].product_pic)
        } else {
          setItems([]);
        }
      })
      .catch((err) => {
        setItems([]);
      });
  }

  const navigate = useNavigate();

  const totals =
    marketcart && marketcart.length > 0
      ? marketcart.reduce(
        (sums, item) => {
          sums.total_star_price += (item.Star_price || 0) * item.count;
          sums.total_price_in_stars +=
            (item.price_in_stars || 0) * item.count;
          return sums;
        },
        { total_star_price: 0, total_price_in_stars: 0 }
      )
      : { total_star_price: 0, total_price_in_stars: 0 };

  const { total_star_price, total_price_in_stars } = totals;

  useEffect(() => {
    getItemList();
  }, []);

  const mergeItems = (items, cart) => {
    const cartMap = new Map(cart.map((item) => [item.product_id, item]));
    const mergedItems = items.map((item) =>
      cartMap.has(item.product_id) ? cartMap.get(item.product_id) : item
    );

    return mergedItems;
  };

  const displayItems = mergeItems(items, marketcart);

  return (
    <>
      <div className="mobileCssMain">
        <div className="container">
          <div className="headerData">
            <Button onClick={() => navigate(-1)}>
              <IconButton className="TouchableIconBtn"> <ArrowBackIosIcon />
              </IconButton>
            </Button>
            <div className="logo">
              <img src={StarLogoSmall} />
            </div>
            <h5>Star Market</h5>
          </div>
        </div>
        <div className="container">
          <div className="wrapper">
            {displayItems.map((item, index) => (
              <>
                <div className="imageSliderOuter">
                  {item.product_pic ? (
                    <ImageSlider images={images} />
                    // <p>{item.item_image[0]}</p>

                  ) : (
                    //<ImageSlider images={productimg_placeholder} />
                    null
                  )}

                </div>
                <div className="thaliName">
                  <div className="orderNotiDiv specialMenu productDetailInfo">
                    <div>
                      <div className="orderDetailsProd">
                        <h3>{item.product_name}</h3>
                        {/* <p>150Grams</p> */}
                      </div>
                      <p>Product Id {item.product_id}</p>
                      <div className="starPrice orderStarPrice">
                        <StarIcon className="starIcon" />
                        <h5> {item.price_in_stars
                        } </h5>
                      </div>
                    </div>
                    <div
                      key={index}
                      className={"countWithAddBtMain " + item.class}
                      //id="addAndOuntMain"
                      id={`addAndOuntMain` + index}
                    >
                      <div className="newCountSt">
                        <Button
                          className="beforeAddBt"
                          onClick={() =>
                            clickAddBt(item.product_id, item, index)
                          }
                        >
                          <AddIcon />
                        </Button>

                        <div className="outerIncdec">
                          <Button
                            onClick={() =>
                              decrement(item.product_id, item)
                            }
                            className="IncDrcBtn"
                          >
                            <RemoveCircleOutlineOutlinedIcon />
                          </Button>

                          <b className="countValue countValue2">
                            {item.count}
                          </b>

                          <Button
                            onClick={() =>
                              increment(item.product_id, item)
                            }
                            className="IncDrcBtn"
                          >
                            <ControlPointOutlinedIcon />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="productSummary ">
                  <h4>Description</h4>
                  <p>
                    {item.discription}
                  </p>
                </div>
                <div className="productSummary productSummaryTopMinus ">
                  <h4>Product Details</h4>
                  <div className="summaryData">
                    <div className="summaryItem summaryItemp">
                      <h6>Product ID</h6>
                      <h3>{item.product_id}</h3>
                    </div>
                    <div className="summaryItem summaryItemp">
                      <h6>Store Category</h6>
                      <h3>{item.type}</h3>
                    </div>
                    <div className="summaryItem summaryItemp">
                      <h6>Weight</h6>
                      <div className="starPrice starPriceProduct">
                        <h5> {item.wt_per_item + " " + item.item_unit}  </h5>
                      </div>
                    </div>
                    <div className="summaryItem summaryItemp">
                      <h6>Star Price</h6>
                      <div className="starPrice starPriceProduct">
                        <StarIcon className="starIcon" />
                        <h5> {item.price_in_stars}  </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
            <Button className="loginBt"
              onClick={() =>
                navigate("/OrderSummaryMarket", {
                  state: {
                    items: items,
                    Fromlocation: "Star Cafe",
                    type: "Breakfast",
                  },
                })
              }>
              <p style={{ marginRight: "60px" }}>
                {marketcart.length} Item |{" "}
                {total_star_price + total_price_in_stars} Stars
              </p>
              <p>View Cart</p>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarketProductDetails;
