import React, { useState } from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import avtar from "../../../assets/images/avtarPic.png";
import editIco from "../../../assets/images/edit-icon.svg";
import loaderImg from "../../../assets/images/logo.png";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import {
  makePostRequest,
  makeGetRequest,
  uploadFileRequest,
} from "../../../utils/utils";
import swal from "sweetalert";

import { styled } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary, { accordionSummaryClasses } from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import deletIco from "../../../assets/images/delete-icon.svg";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import PhoneIcon from "@mui/icons-material/Phone";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

import Checkbox from "@mui/material/Checkbox";
import { AccountTree } from "@mui/icons-material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddEditEmployee(props) {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    console.log(moduleAccessRights)
    setExpanded(isExpanded ? panel : false);
  };

  const navigate = useNavigate();
  const [upload, setUpload] = React.useState(false);
  const [departmentDropDown, setDepartmentDropDown] = React.useState([]);
  const [allowenceLevelDropDown, setAllowenceLevelDropDown] = React.useState(
    []
  );
  const [designationDropDown, setDesignationDropDown] = React.useState([]);
  const [designationList, setDesignationList] = React.useState([]);
  const [departmentList, setDepartmentList] = React.useState([]);

  const [showLoader, setShowLoader] = React.useState(false);
  const [formValues, setFormValues] = React.useState(null);
  const [emergencyContactValues, setEmergencyContactValues] = React.useState({
    emergency_contact_1: "",
    emergency_address_1: "",
    emergency_contact_2: "",
    emergency_address_2: "",
  });
  const [familyInfoValues, setfamilyInfoValues] = React.useState(null);

  const [monthlyStarAllowence, setMonthlyStarAllowence] = React.useState(0);
  const [profileErrMsg, setProfileErrMsg] = React.useState("");
  const [profilePic, setProfilePic] = React.useState("");
  const [department_id, setDepartmentId] = React.useState();
  const [allowencelevel_id, setAllowenceLevelId] = React.useState();
  const [designation_id, setDesignationId] = React.useState();
  const [reporting_to, setReportingTo] = React.useState();
  const [designation_name, setDesignationName] = React.useState("");
  const [department_name, setDepartmentName] = React.useState("");

  const [masterDollarAmount, setMasterDollarAmount] = React.useState(0);
  const [duplicateEmailErrMsg, setDuplicateEmailErrMsg] = React.useState("");
  const [duplicateContactErrMsg, setDuplicateContactErrMsg] = React.useState("");
  const [previousFixedSalary, setPreviousFixedSalary] = React.useState(0);


  const travelEligibilityDropDown = [
    { key: "Select Travel Eligibility", value: "" },
    { key: "Once in 1 year", value: "Once in 1 year" },
    { key: "Once in 2 year", value: "Once in 2 year" },
    { key: "Once in 3 years", value: "Once in 3 years" },
    { key: "Every 6 months", value: "Every 6 months" },
    { key: "Every 4 months", value: "Every 4 months" },
  ];
  const [familyMembersList, setFamilyMembersList] = React.useState([]);
  const [familyInfoErrMsg, setFamilyInfoErrMsg] = React.useState("");
  const [isEmployeeDirectory, setIsEmployeeDirectory] = React.useState(false);
  const [isCfoWarning, setIsCfoWarning] = React.useState(false);

  const initialValues = {
    first_name: "",
    last_name: "",
    email_id: "",
    contact_no: "",
    employee_code: "",
    date_of_joining: "",
    passport_no: "",
    fixed_salary: 0.0,
    contract_years: 1.0,
    paspport_expiry_date: "",
    visa_no: "",
    visa_expiry_date: "",
    birth_date: "",
    travel_eligibility: "",
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().trim("White spaces not allow").strict(true).required("Required").matches(/^([a-z.A-Za-z]*)$/gi,"Space/Numbers/Special Characters Not Allowed!!"),
    last_name: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required")
      .matches(
        /^([a-z.A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Numbers/Special Characters Not Allowed!!"
      ),
    email_id: Yup.string()
      .matches(
        /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
        "Invalid Email Id"
      )
      .required("Required"),
    contact_no: Yup.string()
      // .length(10, "Phone no should be 10 digits long")
      // .matches(
      //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //   "Phone number is not valid"
      // )
      .required("Required"),
    employee_code: Yup.string().max(8).required("Required"),
    date_of_joining: Yup.string().required("Required"),
    birth_date: Yup.date()
      .max(new Date(), "Inavalid date")
      .required("Required"),
    passport_no: Yup.string().required("Required"),
    // reporting_to: Yup.number().typeError("Invalid Value").required("Required"),
    fixed_salary: Yup.number().typeError("Invalid value").required("Required"),
    contract_years: Yup.number()
      .typeError("Invalid value")
      .required("Required"),
    paspport_expiry_date: Yup.date()
      .min(new Date(), "Date must be greater than todays date")
      .required("Required"),
    visa_no: Yup.string().required("Required"),
    visa_expiry_date: Yup.date()
      .min(new Date(), "Date must be greater than todays date")
      .required("Required"),
    travel_eligibility: Yup.string().required("Required"),
  });

  const emergencyContactInitials = {
    emergency_contact_1: "",
    emergency_contact_2: "",
    emergency_address_1: "",
    emergency_address_2: "",
  };

  const emergencyContactValidations = Yup.object({
    emergency_contact_1: Yup.string().required("Required"),
    // .length(10, "Phone no should be 10 digits long")
    // .matches(
    //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //   "Phone number is not valid"
    // ),
    emergency_contact_2: Yup.string().required("Required"),
    // .length(10, "Phone no should be 10 digits long")
    // .matches(
    //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //   "Phone number is not valid"
    // ),
    emergency_address_1: Yup.string().required("Required"),
    emergency_address_2: Yup.string().required("Required"),
  });

  const familyInfoInitials = {
    name: "",
    contact_no: "",
    relation: "",
    address: "",
    bith_date: "",
    age: 0,
  };

  const familyInfoValidations = Yup.object({
    name: Yup.string().required("Required"),
    contact_no: Yup.string()
      // .length(10, "Phone no should be 10 digits long")
      // .matches(
      //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //   "Phone number is not valid"
      // )
      .required("Required"),
    relation: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    bith_date: Yup.string().required("Required"),
    age: Yup.number().typeError("Invalid number").required("Required"),
  });

  async function uploadImageOrFile(fileData) {
    var fileUrl = "";
    try {
      setShowLoader(true);
      const formData = new FormData();
      formData.append("image", fileData);

      await uploadFileRequest("superadmin/upload", formData).then(
        (response) => {
          console.log(response);
          fileUrl = response.data.imagepath;
        }
      );
      setShowLoader(false);
    } catch (error) {
      console.log(error);
    }
    return fileUrl;
  }

  async function validateImage(fileData) {
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i))
      return true;
    else return false;
  }

  async function uploadProfilePic(e) {
    setProfileErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];
    let validateRes = false;
    if (await validateImage(file)) {
      var imageUrl = await uploadImageOrFile(file);
      setProfilePic(imageUrl);
    } else {
      setProfileErrMsg("Invalid file format");
    }
    setShowLoader(false);
  }

  async function getAllAllowenceList(searchString) {
    setShowLoader(true);
    let tempList = [];
    await makeGetRequest("employee/getallowencelevel/" + searchString)
      .then((response) => {
        if (response.data.data) {
          console.log("Data ", response.data.data);
          tempList = response.data.data;
        }

        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });

    if (tempList.length > 0) setAllowenceLevelDropDown(tempList);
    else {
      await swal(
        "No Allowence Level added",
        "Please set at least one allowence level..",
        "warning",
        {
          timer: 3000,
          buttons: ["Ok"],
        }
      );
      navigate("/Settings");
    }
  }

  const getAllDesignationList = (searchString) => {
    setShowLoader(true);

    if (searchString) searchString = searchString;
    else searchString = null;
    makeGetRequest("/employee/getdesignation/" + searchString)
      .then((response) => {
        if (response.data.data) {
          setDesignationList(response.data.data);
          designationDropDown.length = 0;
          for (var i = 0; i < response.data.data.length; i++) {
            designationDropDown.push(response.data.data[i].designation_name);
          }
          setDesignationDropDown(designationDropDown);
        }

        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const getAllDepartmentList = async (searchString) => {
    setShowLoader(true);

    if (searchString) searchString = searchString;
    else searchString = null;
    await makeGetRequest("/employee/getdepartment/" + searchString)
      .then((response) => {
        if (response.data.data) {
          setDepartmentList(response.data.data);
          departmentDropDown.length = 0;
          for (var i = 0; i < response.data.data.length; i++) {
            departmentDropDown.push(response.data.data[i].department_name);
          }
          setDepartmentDropDown(departmentDropDown);
        }

        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  function handleChangeAllowence(e) {
    if (props.name && new Date().getDate() != 1 && isAllowenceUpdate == false) {
      setAllowenceErrMsg("you can change allowence level on 1st day of month");
    } else {
      setAllowenceLevelId(e.target.value);
      let idx = e.target.selectedIndex;
      let dataset = e.target.options[idx].dataset;
      setMonthlyStarAllowence(dataset.isd);
      setAllowenceErrMsg("");
      console.log(dataset.isd);
    }
  }

  function handleChangeDepartment(department) {
    console.log(department);
    setDepartmentName(department);
    let departmentObj = departmentList.find(
      (o) => o.department_name === department
    );
    console.log(departmentObj);
    if (departmentObj) setDepartmentId(departmentObj.department_id);
  }

  async function handleChangeDesignation(designation) {
    console.log(designation);
    setDesignationName(designation);
    let designationObj = designationList.find(
      (o) => o.designation_name === designation
    );
    console.log(designationObj);
    if (designationObj) setDesignationId(designationObj.designation_id);
  }

  const [employeeList, setEmployeeList] = React.useState([]);
  async function getAllEmployeesList() {
    await makeGetRequest("employee/getemployeelist/")
      .then((response) => {
        if (response.data.data) {
          console.log("Data ", response.data.data.length);
          console.log(response);
          setEmployeeList(response.data.data);
        }

        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  const [designationErrMsg, setDesignationErrMsg] = React.useState("");
  const [departmentErrMsg, setDepartmentErrMsg] = React.useState("");
  const [allowenceErrMsg, setAllowenceErrMsg] = React.useState("");
  const [employeeCodeErrMsg, setEmployeeCodeErrMsg] = React.useState("");
  const [isEditFamInfo, setIsEditFamInfo] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState("");
  const [employeeId, setEmployeeId] = React.useState(props.name);

  const [password, setPassword] = useState("");
  const [passwordLength, setPasswordLength] = useState(8);
  const [useSymbols, setUseSymbols] = useState(true);
  const [useNumbers, setUseNumbers] = useState(true);
  const [useLowerCase, setUseLowerCase] = useState(true);
  const [useUpperCase, setUseUpperCase] = useState(true);


  const [moduleAccessRights, setModuleAccessRights] = useState([{ moduleName: 'AccessRights', status: false }, { moduleName: 'Dashboard', status: false }, { moduleName: 'Employee', status: false }, { moduleName: 'AddEmployee', status: false }, { moduleName: 'EmployeeModification', status: false }, { moduleName: 'Inventory', status: false }, { moduleName: 'Orders', status: false }, { moduleName: 'Finance', status: false }, { moduleName: 'Reports', status: false }, { moduleName: 'SupportTicket', status: false }, { moduleName: 'Settings', status: false }, { moduleName: 'CFOAccess', status: false },{ moduleName: 'Warning', status: false },{ moduleName: 'Breakfast', status: false },{ moduleName: 'Lunch', status: false },{ moduleName: 'Dinner', status: false },{ moduleName: 'Refund', status: false },{ moduleName: 'OrderStatusChange', status: false }])

  const generatePassword = () => {
    let charset = "";
    let newPassword = "";

    if (useSymbols) charset += "!@#$%^&*()";
    if (useNumbers) charset += "0123456789";
    if (useLowerCase) charset += "abcdefghijklmnopqrstuvwxyz";
    if (useUpperCase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (let i = 0; i < passwordLength; i++) {
      newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    return newPassword;
  };

  async function addOrUpdateEmployee(values) {

    console.log(department_id);
    if (designation_name && department_name && allowencelevel_id) {
      let designationId;

      if (!designation_id) {
        let obj = designationList.find((o) => o.designation_name.toLowerCase() === designation_name.toLowerCase())
        if (obj)
          designationId = obj.designation_id;
        else {
          await makePostRequest("employee/insertdesignation/", {
            designation_name: designation_name,
          })
            .then((response) => {
              if (response.data.data) {
                console.log("Data ", response.data.data);
                designationId = response.data.data.designation_id;
                //  setDesignationId(response.data.data[0].designation_id)
              }
              setShowLoader(false);
            })
            .catch((err) => {
              setShowLoader(false);
            });
        }
      } else {
        designationId = designation_id;
      }

      let departmentId;
      if (!department_id) {
        let obj = departmentList.find((o) => o.department_name.toLowerCase() === department_name.toLowerCase())

        if (obj)
          departmentId = obj.department_id;
        else {
          await makePostRequest("employee/insertdepartment/", {
            department_name: department_name,
          })
            .then((response) => {
              if (response.data.data) {
                console.log("Data ", response.data.data);
                departmentId = response.data.data.department_id;
                //  setDesignationId(response.data.data[0].designation_id)
              }
              setShowLoader(false);
            })
            .catch((err) => {
              setShowLoader(false);
            });
        }
      } else {
        departmentId = department_id;
      }

      let isAllowenceChanged;

      if (employeeId) {
        console.log(values);
        // values.
        let previousMonthlyAllowence = values.monthly_allowance;
        let totalStars = values.total_stars;

        if (values.allowence_level_id == allowencelevel_id) {
          isAllowenceChanged = false;
        } else {
          isAllowenceChanged = true;
        }

        let updateData = {
          employee_id: values.employee_id,
          profile_pic: profilePic,
          first_name: values.first_name,
          last_name: values.last_name,
          contact_no: values.contact_no,
          birth_date: values.birth_date,
          email_id: values.email_id,
          travel_eligibility: values.travel_eligibility.split("|||")[0],
          designation_id: parseInt(designationId),
          department_id: parseInt(departmentId),
          allowence_level_id: parseInt(allowencelevel_id),
          employee_code: values.employee_code,
          date_of_joining: values.date_of_joining,
          passport_no: values.passport_no,
          paspport_expiry_date: values.paspport_expiry_date,
          visa_no: values.visa_no,
          visa_expiry_date: values.visa_expiry_date,
          reporting_to: parseInt(reporting_to),
          fixed_salary: parseFloat(values.fixed_salary),
          contract_years: parseFloat(values.contract_years),
          is_employee_directory: isEmployeeDirectory,
          is_cfo_warning: isCfoWarning,
        };

        if(formValues.fixed_salary==0);
          updateData.balance_salary=parseFloat(values.fixed_salary);

        await makePostRequest("employee/updateemployee", updateData)
          .then((response) => {
            if (response.data) {
              swal("Success", "Information updated successfully", "success", {
                timer: 3000,
                buttons: false,
              });
              setDuplicateEmailErrMsg("");
              setDuplicateContactErrMsg("");
              setEmployeeCodeErrMsg("");
              navigate("/Employees");
            }
          })
          .catch((err) => {
            let errMsg = err.response.data.message;
            let errMsgArray = errMsg.split(" ");
            setDuplicateEmailErrMsg("");
            setDuplicateContactErrMsg("");
            setEmployeeCodeErrMsg("");

            if (errMsgArray.includes("emailid:"))
              setDuplicateEmailErrMsg("Email already exist");
            else if (errMsgArray.includes("contactno:"))
              setDuplicateContactErrMsg("Already exist");
          });

        let starInputData = {};

        if (isAllowenceChanged == true) {
          starInputData = {
            employee_id: values.employee_id,
            monthly_allowance: monthlyStarAllowence,
            total_stars:
              parseInt(totalStars) -
              parseInt(previousMonthlyAllowence) +
              parseInt(monthlyStarAllowence),
          };
          await makePostRequest("employee/insertemployeestar", starInputData)
            .then((response) => { })
            .catch((err) => {
              console.log(err);
              let errMsg = err.response.data.message;
            });
        } else if (
          previousMonthlyAllowence == null ||
          previousMonthlyAllowence == 0
        ) {
          starInputData = {
            employee_id: values.employee_id,
            monthly_allowance: monthlyStarAllowence,
            total_stars: monthlyStarAllowence,
          };
          await makePostRequest("employee/insertemployeestar", starInputData)
            .then((response) => { })
            .catch((err) => {
              console.log(err);
              let errMsg = err.response.data.message;
            });
        }
      } else {
        let empId = 0;
        let empInserted;
        values.profile_pic = profilePic;
        if(localStorage.getItem("selectedusertype"))
            values.user_type = localStorage.getItem("selectedusertype");
        else
            values.user_type = "User";
        // values.password = generatePassword();
        values.password ='password'
        values.email_id = values.email_id.toLowerCase();
        values.schemaname = sessionStorage.getItem("schema");
        values.company_id = parseInt(localStorage.getItem("companyId"));
        values.fixed_salary = parseFloat(values.fixed_salary);
        values.balance_salary = parseFloat(values.fixed_salary);
        values.contract_years = parseFloat(values.contract_years);
        values.department_id = parseInt(departmentId);
        values.allowence_level_id = parseInt(allowencelevel_id);
        values.reporting_to = parseInt(reporting_to);
        values.designation_id = parseInt(designationId);
        values.status = true;
        values.is_employee_directory = isEmployeeDirectory;
        values.is_cfo_warning = isCfoWarning;
        values.travel_eligibility = values.travel_eligibility.split("|||")[0];

        // console.log(values)

        await makePostRequest("employee/insertemployee", values)
          .then((response) => {
            if (response.data) {
              console.log(response);
              empId = response.data.data.employee_id;
              setEmployeeId(response.data.data.employee_id);
              empInserted = true;
              // response.data.data.travel_eligibility=response.data.data.travel_eligibility+'|||'+response.data.data.travel_eligibility;
              setDuplicateContactErrMsg("");
              setDuplicateEmailErrMsg("");
              setEmployeeCodeErrMsg("");
            }
          })
          .catch((err) => {
            console.log("Err");
            console.log(err);
            let errMsg = err.response.data.message;
            console.log(err.response);
            let errMsgArray = errMsg.split(" ");

            setDuplicateEmailErrMsg("");
            setDuplicateContactErrMsg("");
            setEmployeeCodeErrMsg("");
            console.log(errMsgArray);
            if (errMsgArray.includes("emailid:"))
              setDuplicateEmailErrMsg("Email already exist");
            else if (errMsgArray.includes("contactno:"))
              setDuplicateContactErrMsg("Already exist");
            else if (errMsgArray.includes("Code:"))
              setEmployeeCodeErrMsg("Already exist");
            empInserted = false;
            console.log("Catch finished");
          });

        if (empInserted == true) {
          let starInputData = {
            employee_id: empId,
            monthly_allowance: monthlyStarAllowence,
            total_stars: monthlyStarAllowence,
            total_star_purchase: 0,
            total_star_spent: 0,
          };

          await makePostRequest("employee/insertemployeestar", starInputData)
            .then((response) => {
              let inputdata = {
                employee_id: empId,
                star_allocated: parseInt(monthlyStarAllowence),
                amount_in_dollars: Math.round(parseInt(monthlyStarAllowence * masterDollarAmount)),
                allowence_date: moment(),
              };
              makePostRequest("employee/insertallowancehist/", inputdata).then(
                (response) => {
                  if (response.data) {
                    console.log(response.data.data[0]);
                  }
                }
              );
            })
            .catch((err) => {
              console.log(err);
            });
          swal(
            "Employee basic information saved successfully",
            "Please add other details",
            "success",
            {
              buttons: false,
              timer: 3000,
            }
          );

          // navigate("/Employees");
        }
      }
    } else {
      console.log(designation_name);
      console.log(department_id);
      if (designation_name == "" || designation_name == null)
        setDesignationErrMsg("Required");
      if (department_id == "" || department_id == null || department_id == 0)
        setDepartmentErrMsg("Required");
      if (
        allowencelevel_id == "" ||
        allowencelevel_id == null ||
        allowencelevel_id == 0
      )
        setAllowenceErrMsg("Required");
    }
  }

  async function updateEmergencyContact(values) {
    values.employee_id = parseInt(employeeId);
    await makePostRequest("employee/updateemployeestatus", values)
      .then((response) => {
        if (response.data) {
          swal("Success", "Information updated successfully", "success", {
            timer: 3000,
            buttons: false,
          });
          // navigate("/Employees");
        }
      })
      .catch((err) => { });
  }

  async function addOrUpdateFamilyMembers(values, { resetForm }) {
    console.log(values);
    if (isEditFamInfo == true) {
      familyMembersList[selectedIndex] = values;

      let inputdata = {
        family_info: familyMembersList,
      };
      // if (props.name) inputdata.employee_id = props.name;
      // else
      inputdata.employee_id = parseInt(employeeId);

      await makePostRequest("employee/updateemployeestatus", inputdata)
        .then((response) => {
          if (response.data) {
            swal("Success", "Information updated successfully", "success", {
              timer: 3000,
              buttons: false,
            });
            setIsEditFamInfo(false);
            setSelectedIndex(0);
            setfamilyInfoValues(null);
            resetForm();
          }
        })
        .catch((err) => { });
    } else {
      if (familyMembersList.length < 5) {
        familyMembersList.push(values);
        setFamilyMembersList(familyMembersList);
        let inputdata = {
          family_info: familyMembersList,
          employee_id: parseInt(employeeId),
        };

        makePostRequest("employee/updateemployeestatus", inputdata)
          .then((response) => {
            if (response.data) {
              swal("Success", "Information saved successfully", "success", {
                timer: 3000,
                buttons: false,
              });
            }
          })
          .catch((err) => { });

        resetForm();
      } else setFamilyInfoErrMsg("You can add only 5 family members");
    }
  }

  async function deleteFamilyMember(index) {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record? This process cannot be undone.",
      icon: "warning",
      buttons: ["No", "Yes"],
    });

    if (willDelete) {
      familyMembersList.splice(index, 1);

      let inputdata = {
        employee_id: formValues.employee_id,
        family_info: familyMembersList,
      };
      await makePostRequest("employee/updateemployeestatus", inputdata)
        .then((response) => {
          if (response.data) {
            swal("Success", "Family member deleted successfully", "success", {
              timer: 3000,
              buttons: false,
            });
            setFamilyMembersList(familyMembersList);
            setUpload(!upload);
          }
        })
        .catch((err) => { });
    }
  }

  async function editFamilyInfo(familyInfo, index) {
    setIsEditFamInfo(true);
    setSelectedIndex(index);
    setfamilyInfoValues(familyInfo);
  }

 

  function getMasterDollarAmount() {
    setShowLoader(true);
    makeGetRequest("employee/getmasterstarcount/")
      .then((response) => {
        if (response.data) {
          if (response.data.data.length > 0)
            setMasterDollarAmount(response.data.data[0].dollar_amount);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
      });
  }

  const [checked, setChecked] = React.useState(true);
  const [isAllowenceUpdate, setIsAllowenceUpdate] = useState(false);

  async function handleChangeAccessRights(value, index) {
    moduleAccessRights[index].status = value;

    const newItems = [...moduleAccessRights];
    newItems[index].status = value;
    setModuleAccessRights(newItems);

  }

  async function saveAccessRights() {
    let inputData = {};
    inputData.employee_id = parseInt(employeeId);
    inputData.access_rights = moduleAccessRights;

    await makePostRequest("employee/updateemployeestatus", inputData)
      .then((response) => {
        if (response.data) {
          swal("Success", "Information updated successfully", "success", {
            timer: 3000,
            buttons: false,
          });
          if (localStorage.getItem('userId') == employeeId)
            localStorage.setItem("accessRights", JSON.stringify(response.data.data.access_rights));
        }
      })
      .catch((err) => { });
  }

  React.useEffect(() => {

    setModuleAccessRights(moduleAccessRights)

  }, [moduleAccessRights]);

  React.useEffect(() => {

    getAllDesignationList(null);
    getAllDepartmentList(null);
    getAllAllowenceList(null);
    getMasterDollarAmount();
    getAllEmployeesList();

    if (employeeId) {
      setShowLoader(true);
      makeGetRequest("employee/getemployee/" + props.name)
        .then((response) => {
          if (response.data) {
            console.log(response.data.data[0]);
            response.data.data[0].travel_eligibility = response.data.data[0].travel_eligibility + '|||' + response.data.data[0].travel_eligibility

            setFormValues(response.data.data[0]);
            setDepartmentName(response.data.data[0].department_name);
            setDepartmentId(response.data.data[0].department_id);
            setDesignationName(response.data.data[0].designation_name);
            setDesignationId(response.data.data[0].designation_id);
            setReportingTo(response.data.data[0].reporting_to);
            setMonthlyStarAllowence(response.data.data[0].monthly_allowance);
            setProfilePic(response.data.data[0].profile_pic);
            setIsEmployeeDirectory(response.data.data[0].is_employee_directory);
            setIsCfoWarning(response.data.data[0].is_cfo_warning);
            setAllowenceLevelId(response.data.data[0].allowence_level_id);

            if (response.data.data[0].access_rights) {
         
              var r = moduleAccessRights.filter(e => !response.data.data[0].access_rights.find(a => e.moduleName === a.moduleName));
              console.log(r)
              
              setModuleAccessRights([...response.data.data[0].access_rights,...r]);
            }

            if (!response.data.data[0].allowence_level_id)
              setIsAllowenceUpdate(true);

            if (response.data.data[0].emergency_contact_1) {
              emergencyContactValues.emergency_contact_1 =
                response.data.data[0].emergency_contact_1;
              emergencyContactValues.emergency_address_1 =
                response.data.data[0].emergency_address_1;
              emergencyContactValues.emergency_contact_2 =
                response.data.data[0].emergency_contact_2;
              emergencyContactValues.emergency_address_2 =
                response.data.data[0].emergency_address_2;
            }
            console.log(response.data.data[0].family_info);
            if (response.data.data[0].family_info) {
              setFamilyMembersList(response.data.data[0].family_info);
            }
            document.getElementById("email_id").setAttribute("disabled", true);

            setShowLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
          setShowLoader(false);
        });
    }
  }, []);



  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <div className="employeeAddMain">
            <div className="addEdiProfDivOut">
              <div className="addEdiProfDivInnrIconAtt">
                <div className="addEdiProfDivInnr">
                  {profilePic ? <img src={profilePic} /> : <img src={avtar} />}
                </div>
                <Button
                  className="profEdiICoDiv"
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                >
                  <img src={editIco} />
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => uploadProfilePic(event)}
                  />
                </Button>
              </div>
              <span className="errorSt">{profileErrMsg}</span>
            </div>

            <div className="addEditformMain">
              <div className="addEmploAccordingMain">
                <div className="settingInr">
                  <div className="accordingMain">
                    <Formik
                      initialValues={formValues || initialValues}
                      validationSchema={validationSchema}
                      onSubmit={addOrUpdateEmployee}
                      enableReinitialize
                    >
                      {(formik) => (
                        <Form>
                          <Accordion
                            expanded={expanded === "panel1"}
                            onChange={handleChange("panel1")}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <h3 class="settheading">
                                <PermContactCalendarIcon /> Basic Information
                              </h3>
                            </AccordionSummary>

                            <AccordionDetails>
                              <div className="allownceFormOutMain NewallownceFormOutMain">
                                <Grid container spacing={3}>
                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="input"
                                      type="text"
                                      label="First Name"
                                      placeholder="First Name"
                                      labelClass="pageLabel"
                                      name="first_name"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="input"
                                      type="text"
                                      label="Last Name"
                                      placeholder="Last Name"
                                      labelClass="pageLabel"
                                      name="last_name"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="input"
                                      id="email_id"
                                      type="text"
                                      label="Email ID"
                                      placeholder="Email ID"
                                      labelClass="pageLabel"
                                      name="email_id"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                    <span className="errorSt">
                                      {duplicateEmailErrMsg}
                                    </span>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="input"
                                      type="text"
                                      label="Mobile Number"
                                      placeholder="Mobile Number"
                                      labelClass="pageLabel"
                                      name="contact_no"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                    <span className="errorSt">
                                      {duplicateContactErrMsg}
                                    </span>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="date"
                                      type="date"
                                      label="Date Of Birth"
                                      placeholder="Date Of Birth"
                                      labelClass="pageLabel"
                                      name="birth_date"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>

                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="select"
                                      type="select"
                                      label="Travel Eligibility"
                                      placeholder="Travel Eligibility"
                                      labelClass="pageLabel"
                                      options={travelEligibilityDropDown}
                                      name="travel_eligibility"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>

                                  <Grid item xs={12} md={12}>
                                    <h1 className="basicHeading">
                                      Employment Details
                                    </h1>
                                  </Grid>

                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="input"
                                      type="text"
                                      label="Employee Code"
                                      placeholder="Employee Code"
                                      labelClass="pageLabel"
                                      name="employee_code"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                    <span className="errorSt">
                                      {employeeCodeErrMsg}
                                    </span>
                                  </Grid>

                                  <Grid item xs={12} md={4}>
                                    <label className="pageLabel">
                                      Designation
                                    </label>
                                    <div className="autocompleteMain">
                                      <Autocomplete
                                        options={designationDropDown}
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                        onChange={(e) =>
                                          getAllDesignationList(e.target.value)
                                        }
                                        onBlur={(e) =>
                                          handleChangeDesignation(
                                            e.target.value
                                          )
                                        }
                                        value={designation_name}
                                      />
                                    </div>
                                    <span className="errorSt">
                                      {designationErrMsg}
                                    </span>
                                  </Grid>

                                  <Grid item xs={12} md={4}>
                                    <label className="pageLabel">
                                      Department
                                    </label>
                                    <div className="autocompleteMain">
                                      <Autocomplete
                                        options={departmentDropDown}
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                        onChange={(e) =>
                                          getAllDepartmentList(e.target.value)
                                        }
                                        onBlur={(e) =>
                                          handleChangeDepartment(e.target.value)
                                        }
                                        value={department_name}
                                      />
                                    </div>
                                    <span className="errorSt">
                                      {departmentErrMsg}
                                    </span>
                                  </Grid>

                                  <Grid item xs={12} md={4}>
                                    <label className="pageLabel">
                                      Allowence Level
                                    </label>

                                    <select
                                      className="prodSearchInpt"
                                      onChange={(e) => handleChangeAllowence(e)}
                                      value={allowencelevel_id}
                                    >
                                      <option value="">
                                        Select Allowence Level
                                      </option>
                                      {allowenceLevelDropDown.map((option) => (
                                        <option
                                          key={option.allowencelevel_id}
                                          value={option.allowencelevel_id}
                                          data-isd={option.star_allowance}
                                        >
                                          {option.allowencelevel_name}
                                        </option>
                                      ))}
                                    </select>
                                    <span className="errorSt">
                                      {allowenceErrMsg}
                                    </span>
                                  </Grid>

                                  <Grid item xs={12} md={4}>
                                    <div className="pageLabel">
                                      Monthly Star Allowance{" "}
                                      <span className="requiredStar">*</span>
                                    </div>
                                    <input
                                      type="text"
                                      placeholder="Monthly Star Allowance"
                                      className="prodSearchInpt"
                                      value={monthlyStarAllowence}
                                      disabled
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="date"
                                      type="date"
                                      label="Date Of Joining/ Contract Start Date"
                                      placeholder="Date Of Joining"
                                      labelClass="pageLabel"
                                      name="date_of_joining"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="input"
                                      type="text"
                                      label="Passport Number"
                                      placeholder="Passport Number"
                                      labelClass="pageLabel"
                                      name="passport_no"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="date"
                                      type="date"
                                      label="Passport Expiry Date"
                                      placeholder="Passport Expiry Date"
                                      labelClass="pageLabel"
                                      name="paspport_expiry_date"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="input"
                                      type="text"
                                      label="Visa Number"
                                      placeholder="Visa Number"
                                      labelClass="pageLabel"
                                      name="visa_no"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="date"
                                      type="date"
                                      label="Visa Expiry Date"
                                      placeholder="Visa Expiry Date"
                                      labelClass="pageLabel"
                                      name="visa_expiry_date"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>

                                  <Grid item xs={12} md={4}>
                                    <label className="pageLabel">
                                      Reporting To
                                    </label>

                                    <select
                                      className="prodSearchInpt"
                                      onChange={(e) =>
                                        setReportingTo(e.target.value)
                                      }
                                      value={reporting_to}
                                    >
                                      <option value="">
                                        Select Reporting to
                                      </option>
                                      {employeeList.map((option) => (
                                        <option
                                          key={option.employee_id}
                                          value={option.employee_id}
                                          data-isd={option.star_allowance}
                                        >
                                          {option.first_name} {option.last_name}
                                        </option>
                                      ))}
                                    </select>
                                    <span className="errorSt">
                                      {departmentErrMsg}
                                    </span>
                                  </Grid>
                                  {JSON.parse(localStorage.getItem('accessRights')) != null ?
                                    <>
                                      {JSON.parse(localStorage.getItem('accessRights')).map((module) => (
                                        <>
                                          {module.moduleName == 'CFOAccess' && module.status == true ?
                                            <Grid item xs={12} md={4}>
                                              {employeeId ? (
                                                <FormikControl
                                                  control="input"
                                                  // type="password"
                                                  label="Fixed Salary in INR (₹)"
                                                  placeholder="Fixed Salary in INR (₹)"
                                                  labelClass="pageLabel"
                                                  name="fixed_salary"
                                                  isRequired="true"
                                                  className="prodSearchInpt"
                                                />
                                              ) : (
                                                <FormikControl
                                                  control="input"
                                                  type="text"
                                                  label="Fixed Salary in INR (₹)"
                                                  placeholder="Fixed Salary in INR (₹)"
                                                  labelClass="pageLabel"
                                                  name="fixed_salary"
                                                  isRequired="true"
                                                  className="prodSearchInpt"
                                                />
                                              )}
                                            </Grid> : <></>}</>))}</> : <></>}

                                  <Grid item xs={12} md={4}>
                                    <FormikControl
                                      control="input"
                                      type="text"
                                      label="Contract Terms (Years)"
                                      placeholder="Contract Years"
                                      labelClass="pageLabel"
                                      name="contract_years"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <div className="detailsTableFilterSec mealMenuCheckbox addEdiCheckDiv newEdiCheckDiv">
                                      <div>
                                        <Checkbox
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                          checked={isEmployeeDirectory}
                                          value={isEmployeeDirectory}
                                          onChange={(e) =>
                                            setIsEmployeeDirectory(
                                              e.target.checked
                                            )
                                          }
                                        />
                                        Employee Directory Access
                                      </div>

                                      <div>
                                        <Checkbox
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                          checked={isCfoWarning}
                                          value={isCfoWarning}
                                          onChange={(e) =>
                                            setIsCfoWarning(e.target.checked)
                                          }
                                        />
                                        CFO Warning Access
                                      </div>
                                    </div>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    style={{ textAlign: "center" }}
                                  >
                                    <span className="lineBtns">
                                      {/* <Button
                                        className="cancelEditBtn"
                                        onClick={() => navigate("/Employees")}
                                      >
                                        <span>Cancel</span>
                                      </Button> */}

                                      <Button
                                        className="modalBtSmallWidth smBt"
                                        type="submit"
                                      >
                                        {employeeId ? (
                                          <span>Update</span>
                                        ) : (
                                          <span>Save</span>
                                        )}
                                      </Button>
                                    </span>
                                  </Grid>
                                </Grid>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </Form>
                      )}
                    </Formik>

                    {employeeId ? (
                      <>

                        <Accordion
                          expanded={expanded === "panel5"}
                          onChange={handleChange("panel5")}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5bh-content"
                            id="panel5bh-header"
                          >
                            <h3 class="settheading">
                              <KeyOutlinedIcon /> Access Rights
                            </h3>
                          </AccordionSummary>
                          <AccordionDetails style={{ textAlign: "center" }}>
                            <div className="allownceFormOutMain">
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                  <div className="detailsTableFilterSec mealMenuCheckbox addEdiCheckDiv newEdiCheckDiv rightsMain">
                                    {moduleAccessRights.map((module, index) => (
                                      <div>
                                        <Checkbox checked={module.status} onChange={(e) => handleChangeAccessRights(e.target.checked, index)} />
                                        {module.moduleName}
                                      </div>
                                    ))}
                                  </div>
                                </Grid>
                                <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                                  <Button
                                    className="modalBtSmallWidth smBt" type="submit" onClick={saveAccessRights}>
                                    <KeyOutlinedIcon /> Save
                                  </Button>
                                </Grid>
                              </Grid>
                            </div>
                          </AccordionDetails>
                        </Accordion>

                        <Formik
                          initialValues={
                            emergencyContactValues || emergencyContactInitials
                          }
                          validationSchema={emergencyContactValidations}
                          onSubmit={updateEmergencyContact}
                          enableReinitialize
                        >
                          {(formik) => (
                            <Form>
                              <Accordion
                                expanded={expanded === "panel2"}
                                onChange={handleChange("panel2")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel2bh-content"
                                  id="panel2bh-header"
                                >
                                  <h3 class="settheading">
                                    <ContactPhoneIcon /> Emergency Contacts
                                  </h3>
                                </AccordionSummary>
                                <AccordionDetails
                                  style={{ textAlign: "center" }}
                                >
                                  <div className="allownceFormOutMain">
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} md={12}>
                                        <h1>Emergency Contact 1</h1>
                                      </Grid>
                                      <Grid item xs={12} md={5}>
                                        <FormikControl
                                          control="input"
                                          type="text"
                                          label="Mobile Number"
                                          placeholder="Mobile Number"
                                          labelClass="pageLabel"
                                          name="emergency_contact_1"
                                          isRequired="true"
                                          className="prodSearchInpt"
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={7}>
                                        <FormikControl
                                          control="input"
                                          type="text"
                                          label="Address"
                                          placeholder="Address"
                                          labelClass="pageLabel"
                                          name="emergency_address_1"
                                          isRequired="true"
                                          className="prodSearchInpt"
                                        />
                                      </Grid>

                                      <Grid item xs={12} md={12}>
                                        <h1>Emergency Contact 2</h1>
                                      </Grid>
                                      <Grid item xs={12} md={5}>
                                        <FormikControl
                                          control="input"
                                          type="text"
                                          label="Mobile Number"
                                          placeholder="Mobile Number"
                                          labelClass="pageLabel"
                                          name="emergency_contact_2"
                                          isRequired="true"
                                          className="prodSearchInpt"
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={7}>
                                        <FormikControl
                                          control="input"
                                          type="text"
                                          label="Address"
                                          placeholder="Address"
                                          labelClass="pageLabel"
                                          name="emergency_address_2"
                                          isRequired="true"
                                          className="prodSearchInpt"
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        style={{ textAlign: "center" }}
                                      >
                                        <Button
                                          className="modalBtSmallWidth smBt"
                                          type="submit"
                                        >
                                          <PhoneIcon /> Update Contact
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </Form>
                          )}
                        </Formik>

                        <Formik
                          initialValues={familyInfoValues || familyInfoInitials}
                          validationSchema={familyInfoValidations}
                          onSubmit={addOrUpdateFamilyMembers}
                          enableReinitialize
                        >
                          {(formik) => (
                            <Form>
                              <Accordion
                                expanded={expanded === "panel3"}
                                onChange={handleChange("panel3")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                                >
                                  <h3 class="settheading">
                                    <PeopleAltIcon /> Family Information
                                  </h3>
                                </AccordionSummary>
                                <AccordionDetails
                                  style={{ textAlign: "center" }}
                                >
                                  <div className="allownceFormOutMain">
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} md={12}>
                                        <h1>Family member Information</h1>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <FormikControl
                                          control="input"
                                          type="text"
                                          label="Enter Name"
                                          placeholder="Enter Name"
                                          labelClass="pageLabel"
                                          name="name"
                                          isRequired="true"
                                          className="prodSearchInpt"
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <FormikControl
                                          control="input"
                                          type="text"
                                          label="Mobile Number"
                                          placeholder="Mobile Number"
                                          labelClass="pageLabel"
                                          name="contact_no"
                                          isRequired="true"
                                          className="prodSearchInpt"
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <FormikControl
                                          control="input"
                                          type="text"
                                          label="Enter Relation"
                                          placeholder="Enter Relation"
                                          labelClass="pageLabel"
                                          name="relation"
                                          isRequired="true"
                                          className="prodSearchInpt"
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <FormikControl
                                          control="input"
                                          type="text"
                                          label="Address"
                                          placeholder="Address"
                                          labelClass="pageLabel"
                                          name="address"
                                          isRequired="true"
                                          className="prodSearchInpt"
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <FormikControl
                                          control="date"
                                          type="date"
                                          label="Date Of Birth"
                                          placeholder="Date Of Birth"
                                          labelClass="pageLabel"
                                          name="bith_date"
                                          isRequired="true"
                                          className="prodSearchInpt"
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <FormikControl
                                          control="input"
                                          type="text"
                                          label="Enter Age"
                                          placeholder="Enter Age"
                                          labelClass="pageLabel"
                                          name="age"
                                          isRequired="true"
                                          className="prodSearchInpt"
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        style={{ textAlign: "center" }}
                                      >
                                        {isEditFamInfo ? (
                                          <Button
                                            className="modalBtSmallWidth smBt"
                                            type="submit"
                                          >
                                            <GroupAddIcon /> Update Member
                                          </Button>
                                        ) : (
                                          <Button
                                            className="modalBtSmallWidth smBt"
                                            type="submit"
                                          >
                                            <GroupAddIcon /> Add Member
                                          </Button>
                                        )}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        style={{ textAlign: "center" }}
                                      >
                                        <span className="errorSt">
                                          {familyInfoErrMsg}
                                        </span>
                                      </Grid>

                                      <Grid item xs={12} md={12}>
                                        <div className="innerTabsMain">
                                          <div className="tableMainEmployee groceryTableMain">
                                            <table width="100%">
                                              <thead>
                                                <tr>
                                                  <th>Name</th>
                                                  <th>Mobile No.</th>
                                                  <th>DOB</th>
                                                  <th>Age</th>
                                                  <th>Relation</th>
                                                  <th>Address</th>
                                                  <th>Action</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {familyMembersList ? (
                                                  <>
                                                    {familyMembersList.map(
                                                      (option, index) => (
                                                        <tr>
                                                          <td>{option.name}</td>
                                                          <td>
                                                            {option.contact_no}
                                                          </td>
                                                          <td>
                                                            {moment(
                                                              option.bith_date
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </td>
                                                          <td>
                                                            {option.age} ys
                                                          </td>
                                                          <td>
                                                            {option.relation}
                                                          </td>
                                                          <td>
                                                            {option.address}
                                                          </td>
                                                          <td>
                                                            <div className="actionBtnOut">
                                                              <Tooltip
                                                                title="Edit"
                                                                arrow
                                                                placement="top"
                                                              >
                                                                <IconButton
                                                                  onClick={() =>
                                                                    editFamilyInfo(
                                                                      option,
                                                                      index
                                                                    )
                                                                  }
                                                                >
                                                                  <img
                                                                    src={
                                                                      editIco
                                                                    }
                                                                  />
                                                                </IconButton>
                                                              </Tooltip>
                                                              <Tooltip
                                                                title="Delete"
                                                                arrow
                                                                placement="top"
                                                              >
                                                                <IconButton
                                                                  onClick={() =>
                                                                    deleteFamilyMember(
                                                                      index
                                                                    )
                                                                  }
                                                                >
                                                                  <img
                                                                    src={
                                                                      deletIco
                                                                    }
                                                                  />
                                                                </IconButton>
                                                              </Tooltip>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </Grid>

                                      {/* <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        style={{
                                          textAlign: "center",
                                          marginTop: 20,
                                        }}
                                      >
                                        <Button
                                          className="modalBtSmallWidth"
                                          onClick={() => saveFamilyMembers()}
                                        >
                                          Save All Member
                                        </Button>
                                      </Grid> */}
                                    </Grid>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </Form>
                          )}
                        </Formik>

                        {/* <Formik
                          initialValues={familyInfoValues || familyInfoInitials}
                          validationSchema={familyInfoValidations}
                          onSubmit={addOrUpdateFamilyMembers}
                          enableReinitialize
                        >
                          {(formik) => (
                            <Form>
                              <Accordion
                                expanded={expanded === "panel4"}
                                onChange={handleChange("panel4")}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel4bh-content"
                                  id="panel4bh-header"
                                >
                                  <h3 class="settheading">
                                    <PeopleAltIcon /> Update Password
                                  </h3>
                                </AccordionSummary>
                                <AccordionDetails
                                  style={{ textAlign: "center" }}
                                >
                                  <div className="emploChangePasOt">
                                    <div className="allownceFormOutMain">
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                          <h1>Change Your Password</h1>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          <FormikControl
                                            control="input"
                                            type="text"
                                            label="Enter New Password"
                                            placeholder="New Password"
                                            labelClass="pageLabel"
                                            name="name"
                                            isRequired="true"
                                            className="prodSearchInpt"
                                          />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          <FormikControl
                                            control="input"
                                            type="text"
                                            label="Confirm Password"
                                            placeholder="Confirm Password"
                                            labelClass="pageLabel"
                                            name="name"
                                            isRequired="true"
                                            className="prodSearchInpt"
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          md={12}
                                          style={{ textAlign: "center" }}
                                        >
                                          <Button
                                            className="modalBtSmallWidth smBt"
                                            type="submit"
                                          >
                                            <LockPersonIcon /> Save Password
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </Form>
                          )}
                        </Formik> */}
                      </>
                    ) : (
                      <></>
                    )}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default AddEditEmployee;
