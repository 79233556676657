import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import StarLogoSmall from "../../assets/images/StarLogoSmall.svg";
import { useLocation } from "react-router-dom";
import moment from "moment";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import avtarPic from "../../../assets/images/avtarPic.png";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { makePostRequest } from "../../../utils/utils";
import IconButton from "@mui/material/IconButton";
import { CompressOutlined } from "@mui/icons-material";

function ManagerEmpWarnDetails() {
  const location = useLocation();
  const { data, name, profilepic } = location.state || {};

  const warningUpdate = {
    employee_id: 0,
    warning_id: 0,
    status: "",
  };

  const sampleReply = {
    employee_id: 0,
    employee_name: "",
    reply: "",
    date: "",
  };

  const reply = {
    warning_id: 0,
    reply: [],
  };

  const [inputValue, setInputValue] = useState("");
  const [resplysend, setResplysend] = useState(false);

  console.log(data.employee_id);
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const navigate = useNavigate();

  async function Submit() {
    if (inputValue != "") {
      if (data.reply != null) {
        sampleReply.employee_id = parseInt(
          localStorage.getItem("userId").toString()
        );
        sampleReply.employee_name = localStorage.getItem("username");
        sampleReply.date = moment().format("YYYY-MM-DD HH:mm");
        sampleReply.reply = inputValue;
        data.reply.push(sampleReply);
        reply.employee_id = data.employee_id;
        reply.warning_id = data.warning_id;
        reply.reply = data.reply;
      } else {
        let arr = [];
        sampleReply.employee_id = parseInt(
          localStorage.getItem("userId").toString()
        );
        sampleReply.employee_name = localStorage.getItem("username");
        sampleReply.date = moment().format("YYYY-MM-DD HH:mm");
        sampleReply.reply = inputValue;
        arr.push(sampleReply);
        reply.employee_id = data.employee_id;
        reply.warning_id = data.warning_id;
        reply.reply = arr;
      }

      makePostRequest("employee/updateemployeewarning", reply)
        .then((response) => {
          if (response.data) {
            console.log("send");
            setResplysend(true);
            setInputValue("");
          }
        })
        .catch((err) => {
          setResplysend(true);
          console.log(err);
        });
    }
  }

  async function UpdateWarning() {
    if (data != null) {
      warningUpdate.warning_id = data.warning_id;
      warningUpdate.employee_id = data.employee_id;
      warningUpdate.status = "Close";
      makePostRequest("employee/updateemployeewarning", warningUpdate)
        .then((response) => {
          if (response.data) {
            navigate(-1);
          }
        })
        .catch((err) => {
          setResplysend(true);
          console.log(err);
        });
    }
  }

  console.log(data);
  useEffect(() => {
    setResplysend(false);
  }, []);

  return (
    <>
      <div className="mobileCssMain">
        <div className="container">
          <div className="wrapper">
            <div className="managerMain">
              <div className="headerData">
                <Button onClick={() => navigate(-1)}>
                  <IconButton className="TouchableIconBtn">
                    <ArrowBackIosIcon />
                  </IconButton>
                </Button>
                <div className="screenTitle">
                  <h2>Employee Warning Details</h2>
                </div>
              </div>
              <div className="warnCard warnDetail stickyDiv">
                <h4>
                  Warning Type : <span>{data.warningtype}</span>
                </h4>
                <div className="warnDetailCard">
                  <div className="warnDetailCardLft">
                    <h2>{data.subject}</h2>
                    <h6>Reference No. {data.warning_id}</h6>
                    <p>{moment(data.created_at).format("YYYY-MM-DD HH:mm")}</p>
                  </div>
                  <div className="warnDetailCardRig">
                    <h2>₹ {data.damagecost}</h2>
                    <p>Damage Cost</p>
                  </div>
                </div>
                <div className="descriSt">
                  <p>Description</p>
                  <h2>{data.description}</h2>
                </div>
              </div>

              {/* warning comments section */}
              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <div
                      className="ticketDetailsMain"
                      style={{ paddingBottom: 70 }}
                    >
                      <div className="disctionBottomContMain">
                        <div className="disctionComntScrol ticketChatMain">
                          {data.reply ? (
                            <>
                              {data.reply.map((item, index) => (
                                <>
                                  {parseInt(item.employee_id) ===
                                  parseInt(
                                    localStorage.getItem("userId").toString()
                                  ) ? (
                                    <>
                                      {/* chat left side */}
                                      <div className="ticketChatLeft">
                                        <div className="parkSocityInner parkSocityInnerTicket">
                                          <div className="parkSocityTop">
                                            <div className="parkSocityTopPro">
                                              {localStorage.getItem(
                                                "profilepicture"
                                              ) ? (
                                                <img
                                                  alt="Remy Sharp"
                                                  src={localStorage.getItem(
                                                    "profilepicture"
                                                  )}
                                                />
                                              ) : (
                                                <img
                                                  alt="Remy Sharp"
                                                  src={avtarPic}
                                                />
                                              )}
                                            </div>
                                            <h4>{item.employee_name}</h4>
                                          </div>
                                          <p className="parContTx">
                                            {item.reply}
                                          </p>
                                        </div>
                                        <p className="parContTx parContTxDate ">
                                          {item.date}
                                        </p>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {/* chat right side */}
                                      <div className="ticketChatRight">
                                        <div className="parkSocityInner parkSocityInnerTicket">
                                          <div className="parkSocityTop">
                                            <div className="parkSocityTopPro">
                                              {data.profile_pic != "" ? (
                                                <>
                                                  <img
                                                    alt="Remy Sharp"
                                                    src={data.profile_pic}
                                                  />
                                                </>
                                              ) : (
                                                <img
                                                  alt="Remy Sharp"
                                                  src={avtarPic}
                                                />
                                              )}
                                            </div>
                                            <h4>{item.employee_name}</h4>
                                          </div>
                                          <div className="titlNDate">
                                            <p className="parContTx">
                                              {item.reply}
                                            </p>
                                          </div>
                                        </div>
                                        <p className="parContTx parContTxDate timeAlignRight">
                                          {item.date}
                                        </p>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </>
                          ) : null}
                        </div>

                        <div className="typeComentOut botSticky">
                          <div className="typeComentOutIco">
                            <div className="bottomTextAreaOut">
                              <textarea
                                className="textAriaChat"
                                type="text"
                                id="mainReply"
                                placeholder="Comments"
                                value={inputValue}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="cmmtIcon">
                              <IconButton
                                onClick={() => Submit()}
                                className="TouchableIconBtn"
                              >
                                <SendIcon />
                              </IconButton>
                            </div>
                          </div>
                        </div>

                        <div
                          className="fixedBtnOuter"
                          style={{ background: "#fff" }}
                        >
                          <Button
                            className="loginBt"
                            onClick={() => UpdateWarning()}
                          >
                            Close Warning
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManagerEmpWarnDetails;
