import React from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import companyLog from "../../../assets/images/companyLog.png";
import companyLog2 from "../../../assets/images/companyLog2.png";
import companyLog3 from "../../../assets/images/companyLog3.png";
import AddIcon from "@mui/icons-material/Add";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import swal from "sweetalert";

import {
  makePostRequest,
  makeGetRequest,
  uploadFileRequest,
} from "../../../utils/utils";

import noLogo from "../../../assets/images/noLogo.jpg";
import loaderImg from "../../../assets/images/logo.png";

function AddEditCompany(props) {
  const navigate = useNavigate();

  const [companyScaleList, setCompanyScaleList] = React.useState([
    "Small Scale Organization",
    "Mid Scale Organization",
    "Large Scale Organization",
  ]);

  const [selectedScale, setSelectedScale] = React.useState(companyScaleList[0]);
  const [serviceTypeList, setServiceTypeList] = React.useState([
    "Star Market",
    "Star Cafe",
    "Barber Shop",
    "Sports",
    "Gym",
  ]);
  const [selectedServiceTypeList, setSelectedServiceTypeList] = React.useState(
    []
  );
  const [serviceTypeErrMsg, setServiceTypeErrMsg] = React.useState("");
  const [Upload, setUpload] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [profileErrMsg, setProfileErrMsg] = React.useState("");
  const [companylogo, setCompanyLogo] = React.useState("");
  const [formValues, setFormValues] = React.useState(null);
  const [duplicateNameErrMsg, setDuplicateNameErrMsg] = React.useState("");
  const [duplicateEmailErrMsg, setDuplicateEmailErrMsg] = React.useState("");
  const [duplicateContactErrMsg, setDuplicateContactErrMsg] =
    React.useState("");

  // const [serviceType]
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  //   For Validation
  const initialValues = {
    company_name: "",
    email_id: "",
    contact_no: "",
    address: "",
    owners_name: "",
    company_type: "",
    company_website: "",
    fax_number: "",
    tax_identification_number: "",
  };

  const validationSchema = Yup.object({
    company_name: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required")
      .matches(
        /^([a-z.A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Numbers/Special Characters Not Allowed!!"
      ),
    email_id: Yup.string()
      .matches(
        /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
        "Invalid Email Id"
      )
      .required("Required"),
    contact_no: Yup.string()
      .length(10, "Phone no should be 10 digits long")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .required("Required"),
    address: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required"),
    owners_name: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required")
      .matches(
        /^([a-z.A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Numbers/Special Characters Not Allowed!!"
      ),
    company_type: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required Type of Company"),
    company_website: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required"),
    fax_number: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required"),
    tax_identification_number: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required"),
  });

  async function uploadImageOrFile(fileData) {
    setShowLoader(true);
    const formData = new FormData();
    formData.append("image", fileData);
    var fileUrl;

    await uploadFileRequest("superadmin/upload", formData).then((response) => {
      fileUrl = response.data.imagepath;
    });
    setShowLoader(false);
    return fileUrl;
  }

  async function validateImage(fileData) {
   
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i))
      return true;
    else return false;
  }

  async function uploadLogo(e) {
    setProfileErrMsg("");

    setShowLoader(true);
    const file = e.target.files[0];
    let validateRes = false;
    if (await validateImage(file)) {
      var logoUrl = await uploadImageOrFile(file);
      setCompanyLogo(logoUrl);
    } else {
      setProfileErrMsg("Invalid file format");
    }
    setShowLoader(false);
  }

  async function handleChangeScale(scale) {
    setSelectedScale(scale);
  }

  async function handleChangeServiceType(service_type) {
    if (selectedServiceTypeList.includes(service_type)) {
      selectedServiceTypeList.splice(
        selectedServiceTypeList.indexOf(service_type),
        1
      );
      setSelectedServiceTypeList(selectedServiceTypeList);
    } else {
      selectedServiceTypeList.push(service_type);
      setSelectedServiceTypeList(selectedServiceTypeList);
    }

    setUpload(!Upload);
  }

  const addOrUpdateCompany = (values) => {
    
    values.organisation_type = selectedScale;
    values.company_logo = companylogo;
    if (selectedServiceTypeList.length > 0) {
      if (isEditCompany) {
        setShowLoader(true);
        values.service_type = selectedServiceTypeList;
        makePostRequest("superadmin/updatecompany", values)
          .then((response) => {
            if (response.data) {
              // console.log(response)
              navigate("/SuperAdminDashboard");
            }
          })
          .catch((err) => {
            if (err.response.status == 409) {
              let errMsg = err.response.data.message;
              let errMsgArray = errMsg.split(" ");
              console.log(errMsgArray);
              if (errMsgArray.includes("companyname:"))
                setDuplicateNameErrMsg("Name already exist");
              else if (errMsgArray.includes("emailid:"))
                setDuplicateEmailErrMsg("Email already exist");
              else if (errMsgArray.includes("contactno:"))
                setDuplicateContactErrMsg("Already exist");
            }
            setShowLoader(false);
          });
      } else {
        setShowLoader(true);
        values.service_type = selectedServiceTypeList;
        makePostRequest("superadmin/insertcompany", values)
          .then((response) => {
            if (response.data) {
              // console.log(response)
              navigate("/SuperAdminDashboard");
            }
          })
          .catch((err) => {
            if (err.response.status == 409) {
              let errMsg = err.response.data.message;
              let errMsgArray = errMsg.split(" ");
              console.log(errMsgArray);

              if (errMsgArray.includes("companyname:"))
                setDuplicateNameErrMsg("Name already exist");
              else if (errMsgArray.includes("emailid:"))
                setDuplicateEmailErrMsg("Email already exist");
              else if (errMsgArray.includes("contactno:"))
                setDuplicateContactErrMsg("Already exist");
            }
            setShowLoader(false);
          });
      }

      setDuplicateNameErrMsg("");
      setDuplicateEmailErrMsg("");
      setDuplicateContactErrMsg("");
    } else {
      setServiceTypeErrMsg("Please select service type");
    }
  };

  const [isEditCompany, setIsEditCompany] = React.useState(false);
  React.useEffect(() => {
    if (props.name) {
      setShowLoader(true);
      makeGetRequest("superadmin/getcompany/" + props.name)
        .then((response) => {
          if (response.data) {
            console.log(response.data.data[0]);
            console.log(serviceTypeList);
            setFormValues(response.data.data[0]);
            setIsEditCompany(true);
            setCompanyLogo(response.data.data[0].company_logo);
            setSelectedScale(response.data.data[0].organisation_type);
            document
              .getElementById("company_name")
              .setAttribute("disabled", true);
            setDuplicateNameErrMsg("Unable to update company name");
            for (
              var i = 0;
              i < response.data.data[0].service_type.length;
              i++
            ) {
              selectedServiceTypeList.push(
                response.data.data[0].service_type[i]
              );
            }
            setSelectedServiceTypeList(selectedServiceTypeList);
            setShowLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <>
      <div className="registraContentMain">
        <div className="registraContentInner">
          <Formik
            initialValues={formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={addOrUpdateCompany}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <div className="pageMainHeading">
                      <h1>Registration Form</h1>
                      {/* <p>Lorem Ipsum Dummy Text</p> */}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="uploadImBox">
                      <div className="logoPrevOuter">
                        <div className="logoPrev">
                          {companylogo ? (
                            <img src={companylogo} />
                          ) : (
                            <img src={noLogo} />
                          )}
                        </div>
                      </div>
                      <h1 className="">Upload Logo Here</h1>
                      <Button
                        className="uploadImBt"
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload file
                        <VisuallyHiddenInput
                          type="file"
                          onChange={(e) => uploadLogo(e)}
                        />
                      </Button>
                      <span>or drag file in here</span>
                      <span className="errorSt">{profileErrMsg}</span>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <label className="reggistraLabel">
                      Type Of Organisation
                    </label>
                    <div className="orgnigDiv">
                      {companyScaleList.map((scaleOption) => (
                        <div
                          className={
                            selectedScale == scaleOption
                              ? "orgniInner activeOrgniInner"
                              : "orgniInner"
                          }
                          onClick={() => {
                            handleChangeScale(scaleOption);
                          }}
                        >
                          <CorporateFareIcon />
                          {scaleOption}
                        </div>
                      ))}
                    </div>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormikControl
                      control="input"
                      label="Company Name"
                      id="company_name"
                      placeholder="Company Name"
                      labelClass="reggistraLabel"
                      name="company_name"
                      className="prodSearchInpt"
                    />
                    <span className="errorSt">{duplicateNameErrMsg}</span>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormikControl
                      control="input"
                      label="Email Address"
                      placeholder="Email Address"
                      labelClass="reggistraLabel"
                      name="email_id"
                      className="prodSearchInpt"
                    />
                    <span className="errorSt">{duplicateEmailErrMsg}</span>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormikControl
                      control="input"
                      label="Company Phone Number"
                      placeholder="Company Phone Number"
                      labelClass="reggistraLabel"
                      name="contact_no"
                      className="prodSearchInpt"
                    />
                    <span className="errorSt">{duplicateContactErrMsg}</span>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="textarea"
                      label="Business Address"
                      placeholder="Business Address"
                      labelClass="reggistraLabel"
                      name="address"
                      className="modalTexArea"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormikControl
                      control="input"
                      type="ownername"
                      label="Owner’s Name"
                      placeholder="Owner’s Name"
                      labelClass="reggistraLabel"
                      name="owners_name"
                      className="prodSearchInpt"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormikControl
                      control="input"
                      label="Type Of Company"
                      placeholder="Type Of Company"
                      labelClass="reggistraLabel"
                      name="company_type"
                      className="prodSearchInpt"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormikControl
                      control="input"
                      label="Company Website"
                      placeholder="Company Website"
                      labelClass="reggistraLabel"
                      name="company_website"
                      className="prodSearchInpt"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormikControl
                      control="input"
                      label="Fax Number"
                      placeholder="Fax Number"
                      labelClass="reggistraLabel"
                      name="fax_number"
                      className="prodSearchInpt"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormikControl
                      control="input"
                      label="Tax Identification Number"
                      placeholder="Tax Identification Number"
                      labelClass="reggistraLabel"
                      name="tax_identification_number"
                      className="prodSearchInpt"
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <label className="reggistraLabel">
                      Select Service Type
                    </label>
                    <div className="serTypeMulti">
                      {serviceTypeList.map((option) => (
                        <div
                          className={
                            selectedServiceTypeList.includes(option)
                              ? "serTypeMultiDiv activeSerTypeMultiDiv"
                              : "serTypeMultiDiv"
                          }
                          onClick={() => handleChangeServiceType(option)}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                    <span>{serviceTypeErrMsg}</span>
                  </Grid>

                  <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                    <Button
                      type="submit"
                      className="modalBtSmallWidth formLongBtTopSpace"
                    >
                      {props.name ? <span>Update</span> : <span>Register</span>}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default AddEditCompany;
