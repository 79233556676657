import { Button, Grid, selectClasses } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TuneIcon from "@mui/icons-material/Tune";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from 'react-router-dom';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../../components/FormikControl";
import { makeGetRequest, makePostRequest } from '../../../utils/utils';
import moment from "moment";
import IconButton from "@mui/material/IconButton";

const Modal = ({ isOpen, onClose, children }) => {

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

const sampleWarning = {
  employee_id: 0,
  subject: "",
  description: "",
  documents: null,
  reply: "",
  status: ""
};

const initialValues = {
  subject: "",
  description: "",
};

const validationSchema = Yup.object({
  subject: Yup.string().required("Subject is required"),
  description: Yup.string().required("Required"),
});


const onSubmit = (values) => {
  sampleWarning.employee_id = 0;
  sampleWarning.subject = values.subject;
  sampleWarning.description = values.description;
};


function CFOWarningList() {

  const navigate = useNavigate();

  const [employeeWarning, setEmployeeWarning] = React.useState([]);
  const [flagsearch, setflagsearch] = useState(false);
  const [filteredDataSource, setFilteredDataSource] = useState([]);

  const searchData = (e) => {

    let text = e.target.value
    if (text) {
      setflagsearch(true);
      const newData = employeeWarning.filter((item) => {
        const itemData = item.first_name.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.startsWith(textData);
      });

      if (newData.length > 0) {
        console.log(newData)
        setFilteredDataSource(newData.sort(sortbyascename));
        //setSearch(text);
      } else {
        const newDataname = employeeWarning.filter((item) => {
          const itemData = item.last_name.toUpperCase();
          const textData = text.toUpperCase();
          return itemData.startsWith(textData);
        });
        if (newDataname.length > 0) {
          setFilteredDataSource(newDataname.sort(sortbyascename));
          //setSearch(text);
        } else {
          setFilteredDataSource([]);
          //setSearch(text);
        }
      }
    } else {
      setflagsearch(false);
      //setSearch(text);
    }
  };

  function sortbyascename(a, b) {
    if (a.first_name < b.first_name) {
      return -1;
    }
    if (a.first_name > b.first_name) {
      return 1;
    }
    if (a.last_name < b.last_name) {
      return -1;
    }
    if (a.last_name > b.last_name) {
      return 1;
    }
    return 0;
  };

  async function getAllWarningList() {

    await makeGetRequest("employee/getallwarninglist")
      .then((response) => {
        if (response.data.data) {
          if (response.data.data) {
            setEmployeeWarning(response.data.data);
          } else {
            setEmployeeWarning([]);
          }
        }
      })
      .catch((err) => {
        setEmployeeWarning([]);
      });
  }

  useEffect(() => {
    getAllWarningList();
  }, []);

  return (
    <>
      <div className="mobileCssMain">
        <div className="dashMain forBottomTabOnly">
          <div className='container'>
            <div className="wrapper">
              <div className="screenTitle">
                <Button onClick={() => navigate("/DashboardPage")}>
                <IconButton className="TouchableIconBtn"> <ArrowBackIosIcon />
                </IconButton>
                </Button>
                <h2>Employees Warning</h2>
              </div>
              <div className='managerMain'>
                <div className="pendingLeavesOuter">
                  <div className="leaveFilter ">
                    <div className="newSearchOuter leaveListSearch">
                      <input
                        type="text"
                        placeholder="Search by employee name"
                        className="managerListSearch" onChange={searchData}
                      />
                    </div>
                    {/* <TuneIcon className="leaverFilterIcon" /> */}
                  </div>
                  {flagsearch ?
                    <>
                      {filteredDataSource ? (
                        <>
                          {filteredDataSource.map((item, index) => (
                            <div className="leaveDataCard" onClick={() => navigate('/CFOWarningDetails', {
                              state: { data: item, name: item.first_name + " " + item.last_name },
                            })} >
                              <div className="leaveDataCard-left empWarnList">
                                <h4>{item.subject}</h4>
                                <h5>{item.first_name + " " + item.last_name}</h5>
                                <h6>Reference/Warning No. {item.warning_id}</h6>
                                <p>Issued On {moment(item.created_at).format(
                                  "YYYY-MM-DD HH:mm"
                                )}</p>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                    </> :
                    <>
                      {
                        employeeWarning.length > 0 ? (
                          <>
                            {employeeWarning.map((item, index) => (
                              <div className="leaveDataCard" onClick={() => navigate('/CFOWarningDetails', {
                                state: { data: item, name: item.first_name + " " + item.last_name },
                              })} >
                                <div className="leaveDataCard-left empWarnList">
                                  <h4>{item.subject}</h4>
                                  <h5>{item.first_name + " " + item.last_name}</h5>
                                  <h6>Reference/Warning No. {item.warning_id}</h6>
                                  <p>Issued On {moment(item.created_at).format(
                                    "YYYY-MM-DD HH:mm"
                                  )}</p>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : null
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CFOWarningList