import { Button, Grid, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../components/FormikControl";
import { makePostRequest } from "../../utils/utils";
import IconButton from "@mui/material/IconButton";
import PasswordIcon from "@mui/icons-material/Password";

function OtpScreen() {
  
  const navigate = useNavigate();
  const [otpErrMsg, setOtpErrMsg] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const [otp, setOtp] = React.useState("");

  const initialValues = {
    verification_code: "",
  };

  const validationSchema = Yup.object({
    verification_code: Yup.string().required("Email address is required"),
  });

  async function handleChangeOtp(otp) {
    setOtpErrMsg("");
    const regex = /^[0-9\b]+$/;
    if (regex.test(otp) && otp.length == 4) setOtp(otp);
    else setOtpErrMsg("Invalid OTP , Please enter correct OTP");
  }

  async function verifyOtp() {
    
    setShowLoader(true);
    let inputData = {
      verification_code: otp,
    };

    makePostRequest("superadmin/verifyresetPassword", inputData)
      .then((response) => {

        if (response.data.data) {
          localStorage.setItem("forgotUserId", response.data.data);
          setShowLoader(false);
          navigate("/ResetNewPassword");
        }
      })
      .catch((err) => {
        setOtpErrMsg("Please enter valid otp");
        setShowLoader(false);
      });
  }

  return (
    <>
      <div className="mobileCssMain">
        <div className="container">
          <div className="wrapper">
            <div className="loginMain">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <h1 className="resrPas">
                    <PasswordIcon /> Enter your 4 digit OTP
                  </h1>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="loginCont">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                    //onSubmit={onSubmit}
                    >
                      {(formik) => (
                        <Form>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                              <label className="loginLable">Enter OTP</label>
                              <input
                                className="loginInput"
                                placeholder="Enter OTP"
                                // type="password"
                                maxLength={4}
                                // value={otp}
                                onChange={(e) =>
                                  handleChangeOtp(e.target.value)
                                }
                              />
                              <div style={{ fontSize: 14, color: "red" }}>
                                {otpErrMsg}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              style={{ textAlign: "centers" }}
                            >
                              <Button
                                className="loginBt"
                                onClick={() => verifyOtp()}
                              >
                                Submit
                              </Button>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OtpScreen;
