import React, { useEffect } from "react";
import Left from "../../Left";
import Top from "../../Top";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

// For Tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ReportOrderTab from "./ReportOrderTab";
import ReportStarAllowanceTab from "./ReportStarAllowanceTab";
import InventoryReportTab from "./InventoryReportTab";
import EmployeesReportTab from "./EmployeesReportTab";
import SalesReportsTab from "./SalesReportsTab";
// Tabs End

// For Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// Tabs End

function ReportsPage() {
  const navigate = useNavigate();

  // For Tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // Tabs End

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1>Reports</h1>
              <div className="employeeFilter">
                <div className="prodFilter">
                  {/* <input
                    className="prodSearchInpt"
                    placeholder="Search"
                    onChange={(e) => getAllEmployeeList(e.target.value)}
                  /> */}
                </div>
                {/* <Button
                  className="pageTopMainBt"
                  onClick={() => navigate("/")}
                >
                  Add Employee
                </Button> */}
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid>
          <div className="starCafeTabMain reportsSecMain">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Orders" {...a11yProps(0)} />
                  <Tab label="Star Allowance" {...a11yProps(1)} />
                  <Tab label="Items Sales Reports" {...a11yProps(2)} />
                  <Tab label="Inventory Report" {...a11yProps(3)} />
                  <Tab label="Employees" {...a11yProps(4)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <ReportOrderTab />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <ReportStarAllowanceTab />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <SalesReportsTab />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <InventoryReportTab />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <EmployeesReportTab />
              </CustomTabPanel>
            </Box>
          </div>
        </Grid>
      </div>
    </>
  );
}

export default ReportsPage;
