import React,{useEffect} from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate } from "react-router-dom";

function Help() {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem('selectedModule','Help');
   
  }, []);
  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <div className="underConstruction">
          <div className="workers">
            <div className="left_hand"></div>
            <div className="right_hand"></div>
          </div>
          <h3 class="title">Our Website Is Under Construction</h3>
        </div>
      </div>
    </>
  );
}

export default Help;
