import React, { useState, useEffect } from 'react'
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import profilePlaceholder from '../assets/images/Profile-Picture.jpg';
import { red, pink, blue } from '@mui/material/colors';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../components/FormikControl";
import { Button, Grid, IconButton } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from "react-router-dom";
import { makeGetRequest, makePostRequest, uploadFileRequest } from '../../utils/utils';
import SendIcon from '@mui/icons-material/Send';
import moment from 'moment';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function SupportTicketDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [uploadFile, setUploadFile] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const [uploadfileErrMsg, setUploadfileErrMsg] = React.useState("");
  var userid = parseInt(localStorage.getItem("userId"));

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-content">{children}</div>
        </div>
      </div>
    );
  };

  async function uploadImageOrFile(fileData) {
    var fileUrl = "";
    try {
      setShowLoader(true);
      const formData = new FormData();
      formData.append("image", fileData);

      await uploadFileRequest("superadmin/upload", formData).then(
        (response) => {
          console.log(response);
          fileUrl = response.data.imagepath;
        }
      );
      setShowLoader(false);
    } catch (error) {
      console.log(error);
    }
    return fileUrl;
  }

  async function validateImage(fileData) {
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i))
      return true;
    else return false;
  }

  async function uploadDocument(e) {
    setUploadfileErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];
    let validateRes = false;
    if (await validateImage(file)) {
      var imageUrl = await uploadImageOrFile(file);
      setUploadFile([...uploadFile, imageUrl]);
    } else {
      setUploadfileErrMsg("Invalid file format");
    }
    console.log("uploadFile", uploadFile)
    setShowLoader(false);
  }

  const deleteImage = (index) => {
    console.log("index", index);
    uploadFile.splice(index, 1);
    setUploadFile(uploadFile);
  }

  const initialValues = {
    TicketTitle: "",
    TicketDescription: "",
  };

  const validationSchema = Yup.object({
    TicketTitle: Yup.string().required("Title is required"),
    TicketDescription: Yup.string().required("Description is required"),
  });

  const [ticketReplyList, setTicketReplyList] = useState([]);
  const getReplyTicketList = async (Tid) => {
    await makeGetRequest("employee/getallticketreply/" + Tid)
      .then((response) => {
        if (response.data.data) {
          console.log('TicketReply', response.data.data);
          setTicketReplyList(response.data.data[0].json_agg);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }
  const [errmsg, seterrmsg] = React.useState(false);
  const [msgtitle, setmsgTittle] = useState("");
  const handleInputChange = (e) => {
    const inputValue = e.target.value.slice(0, 255);
    if (inputValue.trim() === "") {
      seterrmsg(true);
    } else if (inputValue.trim() !== inputValue) {
      seterrmsg(true);
    } else {
      seterrmsg(false);
    }
    setmsgTittle(inputValue);
    // seterrmsg(false);
  };

  const addReply = async (ticketId) => {
    //let reply = document.getElementById("mainReply").value;
    if (msgtitle) {
      seterrmsg(false);
      var inputdata = "";
      inputdata = {
        ticketid: ticketId,
        reply_title: msgtitle,
        member_id: localStorage.getItem("userId"),
      };
      setShowLoader(true);
      await makePostRequest("employee/addTicketreply", inputdata)
        .then((response) => {
          if (response.status == "201") {
            setmsgTittle("");
            getReplyTicketList(ticketId);
            setShowLoader(false);
          }
        })
        .catch((err) => {
          setShowLoader(false);
          //swal("There was an error!", "more error details", "warning");
        });
    } else {
      seterrmsg(true);
      // swal("Empty Reply!", "Unable to send empty reply", "warning");
    }
  }

  useEffect(() => {
    getReplyTicketList(data.ticketid);
  }, [])



  return (
    <>
      <div className="mobileCssMain">
        <div className="container">
          <div className="headerDataIcons">
            <div className="screenTitle">
              <Button onClick={() => navigate(-1)}>
              <IconButton className="TouchableIconBtn"> <ArrowBackIosIcon />
              </IconButton>
              </Button>
              <h2>Support Details</h2>
            </div>

            <div className="topheaderIcons headerGreyIcon"></div>
          </div>
        </div>
        <div className="container">
          <div className="wrapper">
            
            <div className="complainBox margineTopDiv">
              <div className='TicketNumberNIconView'>
                <div className='TicketNumberView'>
                  <p className='TicketNumberTxt'>Ticket no. T {data.ticketid}</p>
                </div>
                {/* <div>
        <EditRoundedIcon onClick={openModal}/>
        <CloseRoundedIcon sx={{ color: red[500] }} />
        </div> */}
              </div>
              <div className="TickeImgNameTimeView">
                <div className='TicketImgNameView'>
                  {data.profile_pic == null ? <img src={profilePlaceholder} alt='img' className='TicketProfileImg' />:<img src={data.profile_pic} alt='img' className='TicketProfileImg' />}
                  <div>
                    <p className='TicketUserName'>{data.first_name} {data.last_name}</p>
                    <p className='TicketUserMoNo'>1234567890</p>
                  </div>
                </div>
                <div className='TicketTimeStatusView'>
                  <p className='TicketTimeStatusTxt'>Time & Date</p>
                  <p className='TicketTimeStatusTxt'>{moment(data.created_at).format("DD/MM/YYYY hh:mm a")}</p>
                  <p className='TicketTimeStatusTxt'>Ticket Status Open</p>
                </div>
              </div>
              <p className='TicketTitle'>{data.ticket_title}</p>
              <p className='TicketDescription'>{data.description}</p>
            </div>
            {ticketReplyList ? <>
              {ticketReplyList.map((replyListObj, index) =>
                <div>
                  {replyListObj.member_id != userid ?
                    <div className='chatMsgView1'>
                      {replyListObj.profile_pic == null ? <img src={profilePlaceholder} alt='img' className='TicketProfileImg' />:
                      <img src={replyListObj.profile_pic} alt='img' className='TicketProfileImg' />}
                      <div className='ChatView1'>
                        <p>{replyListObj.reply_title}</p>
                        <p className='chatNameStamp leftChat'>{replyListObj.first_name} {replyListObj.last_name}</p>
                        <p className='chatTimeStamp leftChat'>{moment(replyListObj.created_at).format("DD/MM/YYYY hh:mm a")}</p>
                      </div>
                    </div>
                    :
                    <div className='chatMsgView2'>
                      <div className='ChatView2'>
                        <p>{replyListObj.reply_title}</p>
                        <p className='chatNameStamp rightChat'>{replyListObj.first_name} {replyListObj.last_name}</p>
                        <p className='chatTimeStamp rightChat'>{moment(replyListObj.created_at).format("DD/MM/YYYY hh:mm a")}</p>
                      </div>
                      {replyListObj.profile_pic == null ?<img src={profilePlaceholder} alt='img' className='TicketProfileImg' />:<img src={replyListObj.profile_pic} alt='img' className='TicketProfileImg' />}
                    </div>
                  }
                </div>)}
            </> : null}
          </div>
        </div>
        <div className='ChatInputView'>
          <div className='inputBottomView'>
            <input
              type="text"
              placeholder="Type Message Here"
              className='inputChatBox'
              onChange={handleInputChange}
              value={msgtitle}
            />
            <IconButton onClick={() => addReply(data.ticketid)}>
            <SendIcon sx={{ color: red[700], fontSize: 30 }}  />
              </IconButton>
          </div>
          {errmsg ? (
            <div className="errorSt">
              {"Required and white spaces not allow"}
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default SupportTicketDetails