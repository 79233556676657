import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import lunchThali from "./../assets/images/lunchThali.svg";
import milkshake from "../assets/images/milkshake.svg";
import berry from "../assets/images/berry.svg";
import success from "../assets/images/success.svg";
import mealMenu2 from "../assets/images/mealMenu2.svg";
import orangejuice from "../assets/images/orangejuice.svg";
import pepsi from "../assets/images/pepsi.svg";
import { Button, Grid } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import CloseIcon from "@mui/icons-material/Close";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import DraftsIcon from "@mui/icons-material/Drafts";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarLogoSmall from "../assets/images/StarLogoSmall.svg";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { makeGetRequest, makePostRequest } from "../../utils/utils";
import { useCart } from "../Screens/CartContext";
import menuNot from "../../MobileScreens/assets/images/menNot2.jpg";
import {
  ORDER_TYPE_BREAKFAST,
  ORDER_TYPE_LUNCH,
  ORDER_TYPE_DINNER,
  ORDER_TYPE_CAFE,
} from "../../constants/orderTypes";
import AddIcon from "@mui/icons-material/Add";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  canAddItemToCart,
  canAddSingleItemToCart,
} from "../../utils/canAddItemToCart";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
// import AddIcon from "@mui/icons-material/Add";

const drawerBleeding = 50;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="mobileCssMain">
      <div className="modal-overlay">
        <div className="modal">
          {/* <button className="modal-close" onClick={onClose}>
          Close
        </button> */}
          <div className="modalCloseIcon">
            <CloseIcon onClick={onClose} />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

function Dinner(props) {
  const [count, setCount] = useState(0);
  const {
    cart,
    addToCart,
    reduceCount,
    clearCart,
    addCategoryProduct,
    reduceCategoryCount,
    removeFromCart,
  } = useCart();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();
  const { window } = props;
  const [open, setOpen] = React.useState(false);

  const [items, setItems] = useState([]);
  const [pitems, setPItems] = useState([]);
  const [isOrdered, setIsOrdered] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  //----------for special order
  const clickAddBtspl = (id, item, index) => {
    if (canAddSingleItemToCart(cart, item)) {
      setItems((prevItems) =>
        items.map((item) =>
          item.menu_id === id
            ? { ...item, class: "addedCount" }
            : { ...item, class: "" }
        )
      );

      const updatedItem = { ...item, class: "addedCount" };
      addToCart(updatedItem);

      var element = document.getElementById("addAndOuntMain" + index);
      element.classList.toggle("addedCount");
    } else {
      alert(
        "Cannot add breakfast, lunch, or dinner items together in the same order."
      );
    }
  };

  const incrementspl = (id, item) => {
    setItems((prevItems) =>
      items.map((item) =>
        item.menu_id === id ? { ...item, count: item.count + 1 } : item
      )
    );
    addToCart(item);
  };

  const decrementspl = (id, item) => {
    setItems((prevItems) =>
      // prevItems.map((item) =>
      //   item.menu_id === id
      //     ? {
      //       ...item,
      //       count: Math.max(
      //         item.count - 1,
      //         Math.max(item.freecount || 1, item.complementorycount || 1)
      //       ),
      //     }
      //     : item
      // )
      displayItems.map((item) => {
        if (item.menu_id === id) {
          const newCount = Math.max(item.count - 1, 0);
          const updatedItem = {
            ...item,
            count: newCount,
            class: "",
          };
          console.log("New Count " + newCount);
          reduceCount(item);
          if (newCount === 0) {
            removeFromCart(item.menu_id);
          }
          return updatedItem;
        }
        return item;
      })
    );

    //reduceCount(item);
  };

  // ---------Fore More Order
  const clickAddBt = (id, item, index) => {
    setPItems((prevItems) =>
      pitems.map((item) =>
        item.subcategory_id === id
          ? { ...item, class: "addedCount" }
          : { ...item }
      )
    );
    const updatedItem = { ...item, class: "addedCount" };
    addCategoryProduct(updatedItem);
    var element = document.getElementById("addAndOuntMainp" + index);
    element.classList.toggle("addedCount");
  };

  const increment = (id, item) => {
    // setPItems((prevItems) =>
    //   pitems.map((item) =>
    //     item.subcategory_id === id ? { ...item, count: item.count + 1 } : item
    //   )
    // );
    // addCategoryProduct(item);
    console.log(item);
    setPItems((prevItems) =>
      prevItems.map((item) => {
        if (
          item.subcategory_id === id &&
          (item.count < item.current_quantity || item.type !== "Product")
        ) {
          const updatedItem = { ...item, count: item.count + 1 };
          addCategoryProduct(updatedItem);
          return updatedItem;
        }
        return item;
      })
    );
  };

  const decrement = (id, item) => {
    setPItems((prevItems) =>
      // pitems.map((item) =>
      //   item.subcategory_id === id ? { ...item, count: Math.max(item.count - 1, 1) } : item
      // )
      pitems.map((item) => {
        if (item.subcategory_id === id) {
          const newCount = Math.max(item.count - 1, 0);
          let updatedItem = {
            ...item,
            count: newCount,
          };
          console.log("New Count " + newCount);
          reduceCategoryCount(item);
          if (newCount === 0) {
            updatedItem = {
              ...item,
              count: newCount === 0 ? 1 : newCount,
              class: "",
            };
            removeFromCart(item.subcategory_id);
          }
          return updatedItem;
        }
        return item;
      })
    );
    //reduceCategoryCount(item);
  };

  async function getItemsList(day) {
    await makeGetRequest("starcafe/getmenulistbytype/" + day + "/Dinner") // Sunday/Dinner
      .then((response) => {
        if (response.data.data) {
          const updatedData = response.data.data.map((item) => {
            let count = 1;
            if (item.iscomplementory) {
              count = item.complementorycount || 1;
            } else if (item.isfree) {
              count = item.freecount || 1;
            }
            return {
              ...item,
              count,
              class: "",
              type: ORDER_TYPE_DINNER,
              Category: "Dinner",
            };
          });
          setItems(updatedData);
        } else {
          setItems([]);
        }
      })
      .catch((err) => {
        setItems([]);
      });
  }

  async function getPriorityItemsList() {
    await makeGetRequest("starcafe/getsubcategoryprioritylist")
      .then((response) => {
        if (response.data.data) {
          const priorityData = response.data.data
            .filter(
              (item) =>
                item.type !== "Product" ||
                (item.type === "Product" && item.current_quantity > 0)
            )
            .map((item) => {
              let count = 1;
              if (item.iscomplementory) {
                count = item.complementorycount || 1;
              } else if (item.isfree) {
                count = item.freecount || 1;
              }
              return {
                ...item,
                count,
                class: "",
                storetype: ORDER_TYPE_CAFE,
              };
            });
          setPItems(priorityData);
        } else {
          setPItems([]);
        }
      })
      .catch((err) => {
        setPItems([]);
      });
  }

  async function addItemsToCart(items) {
    console.log(isOrdered);
    if (canAddItemToCart(cart, items)) {
      items
        .filter((item) => !item.category_type[0].includes("Special"))
        .forEach((item) => addToCart(item, isOrdered));
    } else {
      alert(
        "Cannot add breakfast, lunch, or dinner items together in the same order."
      );
    }
  }

  const totals =
    cart && cart.length > 0
      ? cart.reduce(
        (sums, item) => {
          sums.total_star_price += (item.Star_price || 0) * item.count;
          sums.total_price_in_stars +=
            (item.price_in_stars || 0) * item.count;
          return sums;
        },
        { total_star_price: 0, total_price_in_stars: 0 }
      )
      : { total_star_price: 0, total_price_in_stars: 0 };

  const { total_star_price, total_price_in_stars } = totals;

  const getCurrentDay = () => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDate = new Date();
    const dayIndex = currentDate.getDay();
    let dayName;
    if (dayIndex == 6)
      dayName = days[0];
    else
      dayName = days[dayIndex + 1];
    getItemsList(dayName);
    //return dayName;
  };

  async function gettodayorder() {
    let id = localStorage.getItem("userId");
    await makeGetRequest(
      "starcafe/getorderbydateemployeeid/" +
      id +
      "/" +
      moment().format("YYYY-MM-DD") +
      "/Dinner"
    )
      .then((response) => {
        if (response.data) {
          console.log("check");
          console.log(response.data.data.length);
          if (response.data.data.length > 0) {
            setIsOrdered(false);
          } else {
            setIsOrdered(true);
          }
        } else {
          setIsOrdered(false);
        }
      })
      .catch((err) => { });
  }

  useEffect(() => {
    gettodayorder();
    getCurrentDay();
    getPriorityItemsList();
  }, []);

  const mergeItems = (items, cart) => {
    // Separate cart items from subcategory information
    const cartItems = cart.filter((item) => item.menu_id !== undefined);
    const subcategoryItems = cart.filter((item) => item.menu_id === undefined);

    // Map for quick lookup by menu_id
    const cartMap = new Map(cartItems.map((item) => [item.menu_id, item]));

    // Merge items based on menu_id
    const mergedItems = items.map((item) => {
      if (cartMap.has(item.menu_id)) {
        const cartItem = cartMap.get(item.menu_id);
        return { ...item, ...cartItem }; // Merge item with cartItem
      } else {
        return item;
      }
    });

    // Add cart items that are not in items
    cartItems.forEach((cartItem) => {
      if (!items.some((item) => item.menu_id === cartItem.menu_id)) {
        mergedItems.push(cartItem);
      }
    });

    // Ensure subcategory information is added without duplication
    const subcategoryMap = new Map();
    subcategoryItems.forEach((subcategoryItem) => {
      if (!subcategoryMap.has(subcategoryItem.subcategory_id)) {
        subcategoryMap.set(subcategoryItem.subcategory_id, subcategoryItem);
      }
    });

    return {
      items: mergedItems,
      subcategories: Array.from(subcategoryMap.values()),
    };
  };

  const mergeMenuItems = (items, cart) => {
    const cartMap = new Map(cart.map((item) => [item.menu_id, item]));
    const mergedItems = items.map((item) =>
      cartMap.has(item.menu_id) ? cartMap.get(item.menu_id) : item
    );

    return mergedItems;
  };

  const displayItems = mergeMenuItems(items, cart);

  return (
    <>
      <div className="mobileCssMain">
        <div className="container">
          <div className="wrapper">
            <div className="screenTitle">
              <Button onClick={() => navigate("/DashboardPage")}>
                <IconButton className="TouchableIconBtn">
                  {" "}
                  <ArrowBackIosIcon />
                </IconButton>
              </Button>
              <h2>Order Dinner</h2>
            </div>

            <div className="fixedBottomPadding">
              <div className="LunchData">
                <div className="thaliImg">
                  <img src={lunchThali} />
                </div>
                <div className="thaliName">
                  <div className="thaliData">
                    <h1>Dinner Thali</h1>
                    <h5>For 1 Person</h5>
                  </div>
                  {items.length > 0 ? (
                    <div className="thaliData">
                      {isOrdered ? (
                        <>
                          {cart.length == 0 ? (
                            <Button
                              className="loginBt orderBtn"
                              onClick={() => addItemsToCart(items)}
                            >
                              Order Now
                            </Button>
                          ) : null}
                        </>
                      ) : (
                        <p>Already Placed Order For the Day</p>
                      )}
                    </div>
                  ) : null}
                </div>

                {/* <div className="nonVeg">
                  <h3>Nonveg Menu Available</h3>
                </div> */}

                <div className="menuDetails">
                  {displayItems.length > 0 ? (
                    <ul>
                      <li className="listTitle">Menu Includes</li>
                      {displayItems.map((item, index) => (
                        <>
                          {!item.category_type[0].includes("Special") && !item.category_type[0].includes("NonVeg") ? (
                            <li key={index}>{item.menu_name}</li>
                          ) : null}
                        </>
                      ))}
                    </ul>
                  ) : (
                    <div className="menuNotAvailMain">
                      <img src={menuNot} />
                      <p>Menu Not Available</p>
                    </div>
                  )}
                </div>

                <div className="todaysSpecial borderRadius">
                  <div className="todaysSpecialData">
                    <div class=" ">
                      <img src={mealMenu2} />
                    </div>
                    <p>Tomorrow's Special</p>
                  </div>

                  {displayItems.length > 0 ? (
                    <>
                      {displayItems.map((item, index) => (
                        <>
                          {item.category_type[0].includes("Special") ? (
                            <div className="orderNotiDiv specialMenu specialMenuNew specialInc">
                              <div className="newSpecialOf">
                                <div>
                                  <h3>{item.menu_name}</h3>
                                  <p>Product Id #{item.menu_id}</p>
                                  <div className="starPrice">
                                    <StarIcon className="starIcon" />
                                    <h5> {item.price_in_stars}</h5>
                                  </div>
                                </div>
                                <div
                                  className={"countWithAddBtMain " + item.class}
                                  id={`addAndOuntMain` + index}
                                >
                                  <div className="newCountSt">
                                    <IconButton
                                      onClick={() =>
                                        clickAddBtspl(item.menu_id, item, index)
                                      }
                                      className="TouchableIconBtn"
                                    >
                                      <Button className="beforeAddBt">
                                        <AddIcon />
                                      </Button>
                                    </IconButton>
                                    <div className="outerIncdec">
                                      <IconButton
                                        onClick={() =>
                                          decrementspl(item.menu_id, item)
                                        }
                                        className="TouchableIconBtn"
                                      >
                                        <Button className="IncDrcBtn">
                                          <RemoveIcon />
                                        </Button>
                                      </IconButton>
                                      <b className="countValue countValue2">
                                        {item.count}
                                      </b>
                                      <IconButton
                                        onClick={() =>
                                          incrementspl(item.menu_id, item)
                                        }
                                        className="TouchableIconBtn"
                                      >
                                        <Button className="IncDrcBtn">
                                          <AddIcon />
                                        </Button>
                                      </IconButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </>
                      ))}
                    </>
                  ) : (
                    <div className="menuNotAvailMain">
                      <img src={menuNot} />
                      <p>Menu Not Available</p>
                    </div>
                  )}
                </div>

                {/* <div className="todaysSpecial borderRadius">
                  <div className="todaysSpecialData">
                    <div class=" ">
                      <img src={mealMenu2} />
                    </div>
                    <p>Tomorrow's Nonveg Menu</p>
                  </div>

                  {displayItems.length > 0 ? (
                    <>
                      {displayItems.map((item, index) => (
                        <>
                          {item.category_type[0].includes("NonVeg") && !item.category_type[0].includes("Special") ? (
                            <div className="orderNotiDiv specialMenu specialMenuNew specialInc">
                              <div className="newSpecialOf">
                                <div>
                                  <h3>{item.menu_name}</h3>
                                  <p>Product Id #{item.menu_id}</p>
                                  <div className="starPrice">
                                    <StarIcon className="starIcon" />
                                    <h5> {item.price_in_stars}</h5>
                                  </div>
                                </div>
                                <div
                                  className={"countWithAddBtMain " + item.class}
                                  id={`addAndOuntMain` + index}
                                >
                                  <div className="newCountSt">
                                    <IconButton
                                      onClick={() =>
                                        clickAddBtspl(item.menu_id, item, index)
                                      }
                                      className="TouchableIconBtn"
                                    >
                                      <Button className="beforeAddBt">
                                        <AddIcon />
                                      </Button>
                                    </IconButton>
                                    <div className="outerIncdec">
                                      <IconButton
                                        onClick={() =>
                                          decrementspl(item.menu_id, item)
                                        }
                                        className="TouchableIconBtn"
                                      >
                                        <Button className="IncDrcBtn">
                                          <RemoveIcon />
                                        </Button>
                                      </IconButton>
                                      <b className="countValue countValue2">
                                        {item.count}
                                      </b>
                                      <IconButton
                                        onClick={() =>
                                          incrementspl(item.menu_id, item)
                                        }
                                        className="TouchableIconBtn"
                                      >
                                        <Button className="IncDrcBtn">
                                          <AddIcon />
                                        </Button>
                                      </IconButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </>
                      ))}
                    </>
                  ) : (
                    <div className="menuNotAvailMain">
                      <img src={menuNot} />
                      <p>Menu Not Available</p>
                    </div>
                  )}
                </div> */}

                <div className="fixedBtnOuter">
                  <Button className="loginBt">
                    <p
                      style={{ marginRight: "60px" }}
                      //onClick={() => openModal()}
                      onClick={toggleDrawer(true)}
                    >
                      Order More
                    </p>
                    <p
                      className="bottCartBt posiRele"
                      onClick={() =>
                        navigate("/OrderSummary", {
                          state: { Fromlocation: "Star Cafe", type: "Dinner" },
                        })
                      }
                    >
                      <ShoppingCartIcon />{" "}
                      <em className="countEm">{cart.length}</em>
                    </p>
                  </Button>
                </div>
              </div>

              <Modal isOpen={isOpen} onClose={closeModal}>
                <h4>Would you like to add drinks?</h4>
                <p>Drinks</p>

                <div className="modalInnerData showFullTx">
                  {pitems.length > 0 ? (
                    <Grid container className="modalContainer" spacing={2}>
                      {pitems.map((item, index) => (
                        <Grid item xs={6}>
                          <div className="productOuter">
                            <div className="productImg">
                              {item.item_image !== null ? (
                                <img src={item.item_image} />
                              ) : (
                                <img src={milkshake} />
                              )}
                            </div>
                            <h3>{item.product_name}</h3>
                            <p>Product Id #{item.subcategory_id}</p>
                            <div className="productBoxtBottom">
                              <div className="prodBottom">
                                <div className="starPrice">
                                  <StarIcon className="starIcon" />
                                  <h5> {item.Star_price} </h5>
                                </div>

                                <div
                                  className={"countWithAddBtMain " + item.class}
                                  id={`addAndOuntMainp` + index}
                                >
                                  <div className="newCountSt">
                                    <Button
                                      className="beforeAddBt"
                                      onClick={() =>
                                        clickAddBt(
                                          item.subcategory_id,
                                          item,
                                          index
                                        )
                                      }
                                    >
                                      <AddIcon />
                                    </Button>
                                    <div className="outerIncdec">
                                      <Button
                                        onClick={() =>
                                          decrement(item.subcategory_id, item)
                                        }
                                        className="IncDrcBtn"
                                      >
                                        <RemoveIcon />
                                      </Button>
                                      <b className="countValue countValue2">
                                        {item.count}
                                      </b>
                                      <Button
                                        onClick={() =>
                                          increment(item.subcategory_id, item)
                                        }
                                        className="IncDrcBtn"
                                      >
                                        <AddIcon />
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  ) : null}
                </div>

                <div className="modalFooter">
                  <Button className="loginBt" onClick={toggleDrawer(true)}>
                    Continue
                  </Button>
                </div>
              </Modal>

              <Root>
                <CssBaseline />
                <Global
                  styles={{
                    ".MuiDrawer-root > .MuiPaper-root": {
                      height: `calc(500px - ${drawerBleeding}px)`,
                      overflow: "visible",
                    },
                  }}
                />
                <SwipeableDrawer
                  container={container}
                  anchor="bottom"
                  open={open}
                  onClose={toggleDrawer(false)}
                  onOpen={toggleDrawer(true)}
                  swipeAreaWidth={drawerBleeding}
                  disableSwipeToOpen={false}
                  ModalProps={{
                    keepMounted: true,
                  }}
                >
                  <StyledBox
                    sx={{
                      position: "absolute",
                      top: -drawerBleeding,
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                      visibility: "visible",
                      right: 0,
                      left: 0,
                    }}
                  >
                    <Puller />
                  </StyledBox>
                  <StyledBox
                    sx={{
                      height: "100%",
                      overflow: "auto",
                    }}
                  >
                    <div className="mobileCssMain">
                      <div className="forgotOter">
                        <div className="wrapper">
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              <div className="successImg">
                                <img src={success} />
                              </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <h1>Do you need something more..?</h1>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <p>
                                If yes, you will be redirected to Star Cafe
                                where you can add more items
                              </p>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Button
                                className="loginBt"
                                onClick={() => navigate("/StarCafeHome")}
                              >
                                {" "}
                                Yes{" "}
                              </Button>
                              <Button
                                className="secondaryBtn"
                                onClick={() =>
                                  navigate("/OrderSummary", {
                                    state: {
                                      Fromlocation: "Star Cafe",
                                      type: "Dinner",
                                    },
                                  })
                                }
                              >
                                {" "}
                                No, Continue To Payment{" "}
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </StyledBox>
                </SwipeableDrawer>
              </Root>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dinner;
