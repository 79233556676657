import React from "react";
import { Button, Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarLogoSmall from "../assets/images/StarLogoSmall.svg";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import InputAdornment from "@mui/material/InputAdornment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

function SearchPage() {
  const navigate = useNavigate();

  return (
    <>
      <div className="mobileCssMain">
        <div className="container">
          <div className="wrapper">
            <div className="headerData">
              <Button onClick={() => navigate("/BreakfastOrder")}>
                <ArrowBackIosIcon />
              </Button>
              {/* <Button onClick={goBack} className="loginBt"> 
                  <i className="fa fa-arrow-left"></i>
              </Button> */}
              <div className="logo">
                <img src={StarLogoSmall} />
              </div>
              <h5>Star Labels</h5>
            </div>
            <div className="searchInput">
              <Grid container>
                <Grid xs={12}>
                  <TextField
                    id="outlined-start-adornment"
                    placeholder="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchPage;
