import React, { useState, useRef, useEffect } from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import avtarPic from "../../../assets/images/avtarPic.png";
import ModeSharpIcon from "@mui/icons-material/ModeSharp";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import editIco from "../../../assets/images/edit-icon.svg";
import deletIco from "../../../assets/images/delete-icon.svg";
import avtar from "../../../assets/images/avtarPic.png";
import SendIcon from "@mui/icons-material/Send";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import wordImage from "../../../assets/images/doc.png";
import pdfImage from "../../../assets/images/pdf.png";
import excelImage from "../../../assets/images/exel.png";
import txtImage from "../../../assets/images/text.png";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import {
  makeGetRequest,
  makePostRequest,
  uploadFileRequest,
} from "../../../utils/utils";
// End
import moment from "moment";
import swal from "sweetalert";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

import Tooltip from "@mui/material/Tooltip";
import PrintIcon from "@mui/icons-material/Print";
import IconButton from "@mui/material/IconButton";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";

function WarningsTb() {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = React.useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [imageErrMsg, setImageErrMsg] = React.useState("");
  const [uploadImg, setUploadImg] = React.useState([]);
  const [bindurls, setbindurls] = React.useState([]);
  const [status, setStatus] = React.useState();
  const [amtErrMsg, setAmtErrMsg] = React.useState();
  const [warningList, setWarningList] = useState([]);
  const [selectedWarning, setSelectedWarning] = useState({});
  var userid = parseInt(localStorage.getItem("userId"));

  const [sortColumnName, setSortColumnName] = React.useState("created_at");
  const [sortBy, setSortBy] = React.useState("desc");
  const [offsetvalue, setoffsetvalue] = React.useState(0);
  const [itemsPerPage] = React.useState(10);
  const [isResolveTicket, setIsResolveTicket] = useState(false);

  const [searchstr, setSearchStr] = useState(null);
  const [sort, setSort] = useState(null);
  const [selectedWarningId, setSelectedWarningId] = useState();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState();
  const [damageAmt, setDamageAmt] = useState(0);
  const [selectedErrorMsg, setSelectedErrorMsg] = useState("");
  const [isEditWarning, setIsEditWarning] = useState(false);
  const [formValues, setFormValues] = React.useState(null);
  const [isShowDesc, setIsShowDesc] = React.useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    // alert(e.currentTarget.dataset.myValue)
    if (e.currentTarget.dataset.myValue !== undefined) {
      setStatus(e.currentTarget.dataset.myValue);
      getAllEmployeeWarnings(searchstr, e.currentTarget.dataset.myValue, sort);
    } else {
      setStatus(null);
      getAllEmployeeWarnings(searchstr, null, sort);
    }
    setAnchorEl(null);
  };

  // Create New Ticket
  const [newTicket, setOpennewWarning] = React.useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleClickNewWarning = (scrollType) => () => {
    setOpennewWarning(true);
    setScroll(scrollType);
  };

  const closenewWarning = () => {
    setOpennewWarning(false);
    setUploadImg([]);
    setFormValues(null);
    setSelectedId();
    setIsEditWarning(false);
  };

  const descriptionElementRef = useRef(null);
  // End

  // Add Damage Cost
  const [AddDamageCost, setOpenAddDamageCost] = React.useState(false);

  const handleClickAddDamageCost = (scrollType) => () => {
    setOpenAddDamageCost(true);
    setScroll(scrollType);
  };

  const closeAddDamageCost = () => {
    setOpenAddDamageCost(false);
  };
  // End

  // Image Upload Start
  async function uploadImageOrFile(fileData) {
    var fileUrl = "";
    try {
      setShowLoader(true);
      const formData = new FormData();
      formData.append("image", fileData);
      await uploadFileRequest("superadmin/upload", formData).then(
        (response) => {
          console.log(response);
          fileUrl = response.data.imagepath;
        }
      );
      setShowLoader(false);
    } catch (error) {
      console.log(error);
    }
    return fileUrl;
  }

  async function validateImage(fileData) {
    if (
      fileData.name.match(
        /.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|docx|DOCX|doc|DOC|pdf|PDF|xls|XLS|txt|TXT|xlsx|XLSX)$/i
      )
    )
      return true;
    else return false;
  }

  async function uploadImageAndFile(e) {
    setImageErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];
    let validateRes = false;

    if (await validateImage(file)) {
      var imageUrl = await uploadImageOrFile(file);
      setUploadImg([...uploadImg, imageUrl]);
      console.log("img arr", uploadImg);
    } else {
      setImageErrMsg("Invalid file format");
    }
    setShowLoader(false);
  }
  // Image Upload End
  const deleteImage = async (index) => {
    await uploadImg.splice(index, 1);
    setUploadImg([...uploadImg]);
  };

  const initialValues = {
    subject: "",
    description: "",
    warningtype: "",
  };

  const validationSchema = Yup.object({
    subject: Yup.string()
      .required("Required")
      .matches(
        /^([a-z.A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Numbers/Special Characters Not Allowed!!"
      ),
    description: Yup.string()
      .required("Required")
      .min(10, "Minimum 10 characters required!"),
    warningtype: Yup.string().required("Required"),
  });

  const addOrEditWarning = async (values) => {
    setSelectedErrorMsg("");
    if (selectedId) {
      if (isEditWarning) {
        let inputData = {
          warning_id: values.warning_id,
          employee_id: selectedId,
          subject: values.subject,
          description: values.description,
          warningtype: values.wtype,
        };
        makePostRequest("/employee/updateemployeewarning", inputData)
          .then((response) => {
            if (response.data) {
              console.log(response.data);
            }
            setFormValues();
            closenewWarning();
            // getTicketList(null);
          })
          .catch((err) => {
            console.log(err);
            let errMsg = err.response.data.message;
          });
      } else {
        values.employee_id = selectedId;
        values.status = "Open";
        await makePostRequest("/employee/insertemployeewarning", values)
          .then((response) => {
            if (response.data) {
              console.log(response.data);
            }
            setFormValues();
            closenewWarning();
            // getTicketList(null);
          })
          .catch((err) => {
            console.log(err);
            let errMsg = err.response.data.message;
          });
      }
    } else {
      setSelectedErrorMsg("Please Select Employee");
    }
  };

  const handleChangeWarning = (warningObj) => {
    setSelectedWarningId(warningObj.warning_id);
    setSelectedEmployeeId(warningObj.employee_id);
    setSelectedWarning(warningObj);
  };

  const [errmsg, seterrmsg] = React.useState(false);
  const [msgtitle, setmsgTittle] = useState("");

  const handleInputChange = (e) => {
    const inputValue = e.target.value.slice(0, 255);
    if (inputValue.trim() === "") {
      seterrmsg(true);
    } else if (inputValue.trim() !== inputValue) {
      seterrmsg(true);
    } else {
      seterrmsg(false);
    }
    setmsgTittle(inputValue);
    // seterrmsg(false);
  };

  const addReply = async () => {
    let replyText = document.getElementById("mainReply").value;

    // if (msgtitle) {
    //     seterrmsg(false);
    var inputdata = "";
    let replyArr = selectedWarning.reply;
    if (replyArr == null) replyArr = [];
    let replyObj = {
      date: moment().format("YYYY-MM-DD HH:mm"),
      reply: replyText,
      employee_id: parseInt(localStorage.getItem("userId")),
      employee_name: localStorage.getItem("username"),
    };
    replyArr.push(replyObj);
    inputdata = {
      warning_id: selectedWarning.warning_id,
      reply: replyArr,
      employee_id: selectedWarning.employee_id,
    };
    setShowLoader(true);
    await makePostRequest("employee/updateemployeewarning", inputdata)
      .then((response) => {
        if (response.status == "201") {
          document.getElementById("mainReply").value = "";
          setSelectedWarning(response.data.data);
          setShowLoader(false);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        //swal("There was an error!", "more error details", "warning");
      });
    // } else {
    //   seterrmsg(true);
    // swal("Empty Reply!", "Unable to send empty reply", "warning");
    // }
  };

  const getEmployeeList = async (searchStr) => {
    // /employeesearch/:column/:orderby/:search/:offsetValue/:limit
    setShowLoader(true);
    await makeGetRequest(
      "/employee/employeesearch/" +
      sortColumnName +
      "/" +
      sortBy +
      "/" +
      searchStr +
      "/" +
      offsetvalue +
      "/" +
      itemsPerPage+"/null"
    )
      .then((response) => {
        if (response.data.data) {
          console.log("employee", response.data.data.res);
          setEmployeeList(response.data.data.res);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const [selectedId, setSelectedId] = useState();
  const onSelectEmployee = (empId) => {
    setSelectedId(empId);
    console.log("id", empId);
  };

  const statusChangeFun = (status) => {
    setIsResolveTicket(status.target.value);
  };

  const editWarning = (warningObj) => {
    setFormValues(warningObj);
    setIsEditWarning(true);
    // setUploadImg(ticketObj.fileuploaded);
    setOpennewWarning(true);
    onSelectEmployee(warningObj.employee_id);
  };

  let clkindex = null;
  const urls = [];
  const viewDocs = (nId, index, fileuploaded) => {
    setbindurls([]);
    urls.length = 0;
    bindurls.length = 0;
    const btn = document.getElementById("noticeSlideID" + index);
    if (btn) {
      if (clkindex !== null) {
        const prevDivindex = document.getElementById(
          "noticeSlideID" + clkindex
        );
        if (prevDivindex) {
          prevDivindex.classList.remove("expandfilesDiv");
        }
      }
      btn.classList.toggle("expandfilesDiv");
      clkindex = index;
    }

    fileuploaded.forEach((item) => {
      try {
        console.log(typeof item);
        const parsedItem = JSON.parse(item);

        if (parsedItem && parsedItem.path) {
          urls.push(parsedItem.path);
        } else {
          urls.push(item);
        }
      } catch (error) {
        urls.push(item);
        console.error("Error:", error);
      }
    });
    setbindurls(urls);
  };

  const deleteWarning = async (id, employeeId) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Warning Deleted!",
          text: "Warning is successfully deleted!",
          icon: "success",
          timer: 3000,
        }).then(function () {
          let inputdata = {
            warning_id: id,
            is_deleted: false,
            employee_id: employeeId,
          };
          makePostRequest("/employee/updateemployeewarning", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                handleChangeWarning(response.data.data[0]);
                getAllEmployeeWarnings(searchstr, status, sort);
              }
              setSearchStr(null);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        //swal("Cancelled", "Your Category is safe :)", "error");
      }
    });
  };
  const closeWarning = async (id, employeeId) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to close warning?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Warning Closed!",
          text: "Warning closed successfully!",
          icon: "success",
        }).then(function () {
          let inputdata = {
            warning_id: id,
            status: "Close",
            employee_id: employeeId,
          };
          makePostRequest("/employee/updateemployeewarning", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                handleChangeWarning(response.data.data[0]);
                getAllEmployeeWarnings(searchstr, status, sort);
              }
              setSearchStr(null);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      }
    });
  };

  async function saveDamageCost() {
    let inputdata = {
      warning_id: selectedWarningId,
      employee_id: selectedEmployeeId,
      damagecost: parseInt(damageAmt),
    };
    await makePostRequest("/employee/updateemployeewarning", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          handleChangeWarning(response.data.data);
          setOpenAddDamageCost(false);
          setDamageAmt(0);
          getAllEmployeeWarnings(searchstr, status, sort);
        }
        setSearchStr(null);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getAllEmployeeWarnings = async (searchString, status, sort) => {
    setShowLoader(true);
    setSearchStr(searchString);
    if (!searchString)
      searchString = null;

    if (!status) status = null;
    if (!sort) sort = null;
    await makeGetRequest(
      "/employee/getallemployeewarning/" +
      searchString +
      "/" +
      status +
      "/" +
      sort + '/null'
    )
      .then((response) => {
        if (response.data.data) {
          console.log("employee Warnings", response.data.data);
          setWarningList(response.data.data);
          if (!selectedWarningId) {
            handleChangeWarning(response.data.data[0]);
          }
          //
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  async function validateAmount(amount) {
    const regex = /^[0-9\b]+$/;
    if (regex.test(amount)) {
      setAmtErrMsg("");
      setDamageAmt(amount);
    } else setAmtErrMsg("Invalid amount");
  }
  useEffect(() => {
    localStorage.setItem('selectedModule', 'Warningtab');
    getAllEmployeeWarnings(null, null, null);
    getEmployeeList(null);
  }, [uploadImg, isResolveTicket, sortBy]);

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        {JSON.parse(localStorage.getItem('accessRights') != null) ?
          <>
            {JSON.parse(localStorage.getItem('accessRights')).map((module) => (
              <>
                {module.moduleName == 'CFOAccess' && module.status == true ?
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div className="pageMainHeading">
                        <h1 className="forBackArrowAlign">Warnings</h1>
                        <Button
                          className="pageTopMainBt"
                          onClick={handleClickNewWarning("body")}
                        >
                          Create New Warning
                        </Button>
                      </div>
                    </Grid>
                  </Grid> : <></>}</>
            ))}</> : <></>}

        <div className="pageContDivMain">
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={6}>
              <InputLabel className="pageLabel">Tickets Type</InputLabel>
              <Select
                className="prodSearchInpt"
                value={isResolveTicket}
                onChange={statusChangeFun}
                label="Ticket"
              >
                <MenuItem value={false}>Open Tickets</MenuItem>
                <MenuItem value={true}>Close Tickets</MenuItem>
              </Select>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <label className="pageLabel">Search Warning</label>
                  <div className="visitorSearch">
                    <div className="prodSearchDiv">
                      <input
                        type="text"
                        value={searchstr}
                        className="prodSearchInpt"
                        placeholder="Search by Warning title or description or Warning no. or users"
                        onChange={(e) => getAllEmployeeWarnings(e.target.value, status, sort)}
                      />
                      <div>
                        <FilterListOutlinedIcon
                          className="filterIconSearch"
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                        />
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem onClick={handleClose} data-my-value={null}>
                            All
                          </MenuItem>
                          <MenuItem
                            onClick={handleClose}
                            data-my-value={"Open"}
                          >
                            Open
                          </MenuItem>
                          <MenuItem
                            onClick={handleClose}
                            data-my-value={"Close"}
                          >
                            Close
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                    <div className="sortingIcoOut">
                      <ToggleButton
                        value="check"
                      // selected={selected}
                      // onChange={() => {
                      //   handleChangeSortBy();
                      // }}
                      >
                        <ImportExportIcon
                          className="visitorFilterIco"
                          aria-haspopup="true"
                        // onChange={()=>handleChangeSortBy()}
                        />
                      </ToggleButton>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="suportTicListMain">
                    {warningList.length > 0 ? (
                      <>
                        {warningList.map((warningObj, index) => (
                          <div
                            className={
                              selectedWarningId === warningObj.warning_id
                                ? "suportTicList withTicket activeTicket"
                                : "suportTicList withTicket"
                            }
                            onClick={() => handleChangeWarning(warningObj)}
                          >
                            <span className="tickLabl">
                              Reference No. {warningObj.warning_id}
                            </span>
                            <div className="totDamageCost">
                              Total D. Cost:{" "}
                              <b>
                                <CurrencyRupeeIcon /> {parseFloat(warningObj.total).toFixed(2)}
                              </b>
                            </div>
                            <em className="ticketDatNtime">
                              {moment(warningObj.created_at).format(
                                "DD/MM/YYYY hh:mm a"
                              )}
                            </em>
                            <div className="suportListTop">
                              <div className="suportListProOut">
                                <div className="suportListPro">
                                  {warningObj.profile_pic ? (
                                    <img src={warningObj.profile_pic} />
                                  ) : (
                                    <img src={avtarPic} />
                                  )}
                                </div>
                                <div className="proNameNMob">
                                  <h2>
                                    {warningObj.first_name}{" "}
                                    {warningObj.last_name}
                                  </h2>
                                  <p>{warningObj.contact_no}</p>
                                </div>
                              </div>
                              <div className="dmCost dmCostNew">
                                <p style={{ fontSize: 10 }}>Type Of Warning</p>
                                <h3>{warningObj.warningtype}</h3>
                              </div>
                              <div className="dmCost">
                                <p style={{ fontSize: 10 }}>Damage Cost</p>
                                {warningObj.damagecost == null ? (
                                  <h3>-</h3>
                                ) : (
                                  <h3>
                                    Rs{" "}
                                    {parseFloat(warningObj.damagecost).toFixed(
                                      2
                                    )}
                                  </h3>
                                )}
                              </div>
                              {JSON.parse(localStorage.getItem('accessRights') != null) ?
                                <>
                                  {JSON.parse(localStorage.getItem('accessRights')).map((module) => (
                                    <>
                                      {module.moduleName == 'CFOAccess' && module.status == true ?
                                        <div className="ediDelMain">
                                          <Tooltip
                                            title="Add Damage Cost"
                                            arrow
                                            placement="top"
                                          >
                                            <div
                                              className="addDamaCost"
                                              onClick={handleClickAddDamageCost("body")}
                                            >
                                              <CurrencyRupeeIcon />
                                            </div>
                                          </Tooltip>

                                          {warningObj.status != "Close" ? (
                                            <div onClick={() => editWarning(warningObj)}>
                                              <img src={editIco} />
                                            </div>
                                          ) : null}
                                          <div
                                            onClick={() =>
                                              deleteWarning(
                                                warningObj.warning_id,
                                                warningObj.employee_id
                                              )
                                            }
                                          >
                                            <img src={deletIco} />
                                          </div>
                                        </div> : <></>}</>))}</> : <></>}
                            </div>

                            <div className="suportListBot">
                              <div className="suportListBotLft">
                                <h2 style={{ fontSize: 10 }}>
                                  {warningObj.subject}
                                </h2>
                                {warningObj.description.length > 50 ? (
                                  <p>
                                    {warningObj.description.substring(0, 60)}
                                    <span id={`dots`}>... </span>
                                  </p>
                                ) : (
                                  <p>{warningObj.description}</p>
                                )}
                              </div>
                              <div className="suportListBotRight">
                                {warningObj.status != "Close" ? (
                                  <Button
                                    className="ticketClose"
                                    onClick={() =>
                                      closeWarning(
                                        warningObj.warning_id,
                                        warningObj.employee_id
                                      )
                                    }
                                  >
                                    Close
                                  </Button>
                                ) : (
                                  <p className="closedOnNew">
                                    Closed On{" "}
                                    <span>
                                      {moment(warningObj.updated_at).format(
                                        "DD/MM/YYYY hh:mm a"
                                      )}
                                    </span>
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>No Data Found</>
                    )}
                  </div>
                </Grid>
                {warningList.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <div className="ticketDetailsMain">
                      <div className="warningMain">
                        <div className="mainListDiv">
                          {/* <Tooltip title="Print Warning" arrow placement="top">
                            <Button className="warninPrint">
                              <PrintIcon />
                            </Button>
                          </Tooltip> */}

                          <div className="mainListCont warnNewRightmargin">
                            <div className="typeWarnNew typeWarnNewHeading">
                              <p>
                                Type Of Warning :{" "}
                                <span
                                  style={{
                                    fontWeight: 600,
                                    textWrap: "nowrap",
                                    width: "100%",
                                  }}
                                >
                                  {selectedWarning.warningtype}
                                </span>
                              </p>{" "}
                            </div>

                            <div className="warnDetailsNewTop">
                              <div>
                                <div className="newWarnSubjectOuter warnDetailsNewRefLayout">
                                  <h1>{selectedWarning.subject}</h1>
                                  <p className="refNotx">
                                    Reference No. {selectedWarning.warning_id}
                                  </p>
                                </div>
                                <label>
                                  {moment(selectedWarning.created_at).format(
                                    "DD/MM/YYYY hh:mm a"
                                  )}{" "}
                                </label>
                              </div>
                              <div className="dmCost">
                                <p>Damage Cost</p>
                                {selectedWarning.damagecost == null ? (
                                  <h3>-</h3>
                                ) : (
                                  <h3 className="rightTotCos">
                                    <CurrencyRupeeIcon />{" "}
                                    {parseFloat(
                                      selectedWarning.damagecost
                                    ).toFixed(2)}
                                  </h3>
                                )}
                              </div>
                              <div className="dmCost">
                                <p>Total Damage Cost:</p>
                                <h3 className="rightTotCos">
                                  <CurrencyRupeeIcon /> {parseFloat(selectedWarning.total).toFixed(2)}
                                </h3>
                              </div>
                            </div>
                            {/* <>{warnObj.description}</> */}

                            <div className="boxActions">
                              <span className="downoadYourFile">
                                <AssignmentIcon />
                                {isShowDesc?
                                <em  onClick={()=>setIsShowDesc(false)}>Hide Description</em>:
                                <em onClick={()=>setIsShowDesc(true)}>Show Description</em>}
                              </span>
                            </div>
                            {isShowDesc?
                            <div className="newWarnDescP">
                              <p>{selectedWarning.description}</p>
                            </div>:<></>}
                            {/* <div className="expandfilesDiv">
                              <div className="doqumentDiv1">
                                <div id="documentDiv" className="doqumentDiv1">
                                  {selectedWarning.description}
                                </div>
                              </div>
                            </div> */}

                            {/* warn cmmt start  */}
                            <div className="newWarnComntMain">
                              <div className="ticketDetailsMain">
                                <div className="disctionBottomContMain">
                                  <div className="disctionComntScrol ticketChatMain">
                                    {/* chat left side */}
                                    {selectedWarning.reply ? (
                                      <>
                                        {selectedWarning.reply.map(
                                          (replyObj) => (
                                            <>
                                              {replyObj.employee_id ==
                                                localStorage.getItem("userId") ? (
                                                <div className="ticketChatRight">
                                                  <div className="parkSocityInner parkSocityInnerTicket">
                                                    <div className="parkSocityTop">
                                                      <div className="parkSocityTopPro">
                                                        <img
                                                          alt="Remy Sharp"
                                                          src={avtarPic}
                                                        />
                                                      </div>
                                                      <h4>
                                                        {replyObj.employee_name}
                                                      </h4>
                                                    </div>
                                                    <div className="titlNDate">
                                                      <p className="parContTx">
                                                        {replyObj.reply}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <p className="parContTx parContTxDate timeAlignRight">
                                                    {moment(
                                                      replyObj.date
                                                    ).format(
                                                      "DD/MM/YYYY hh:mm a"
                                                    )}
                                                  </p>
                                                </div>
                                              ) : (
                                                <div className="ticketChatLeft">
                                                  <div className="parkSocityInner parkSocityInnerTicket">
                                                    <div className="parkSocityTop">
                                                      <div className="parkSocityTopPro">
                                                        <img
                                                          alt="Remy Sharp"
                                                          src={avtarPic}
                                                        />
                                                      </div>
                                                      <h4>
                                                        {replyObj.employee_name}
                                                      </h4>
                                                    </div>
                                                    <p className="parContTx">
                                                      {replyObj.reply}
                                                    </p>
                                                  </div>
                                                  <p className="parContTx parContTxDate ">
                                                    {moment(
                                                      replyObj.date
                                                    ).format(
                                                      "DD/MM/YYYY hh:mm a"
                                                    )}
                                                  </p>
                                                </div>
                                              )}
                                            </>
                                          )
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>

                                  {JSON.parse(localStorage.getItem('accessRights') != null) ?
                                    <>
                                      {JSON.parse(localStorage.getItem('accessRights')).map((module) => (
                                        <>
                                          {module.moduleName == 'CFOAccess' && module.status == true ?

                                            <div className="typeComentOut">
                                              <div className="typeComentOutIco">
                                                <div className="bottomTextAreaOut">
                                                  <textarea
                                                    className="textAriaChat"
                                                    type="text"
                                                    id="mainReply"
                                                    placeholder="Comments"
                                                  />
                                                  {/* <AttachFileIcon /> */}
                                                </div>
                                                <SendIcon onClick={() => addReply()} />
                                              </div>
                                            </div> : <></>}</>
                                      ))}</> : <></>}
                                </div>
                              </div>
                            </div>

                            {/* warn cmmt end  */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* Create New Warnings */}
      <div>
        <Dialog
          open={newTicket}
          // onClose={closeCartonOrSingle}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            {isEditWarning ? <>Edit Warning</> : <>Create New Warning</>}
            <CloseIcon className="modalCloseBt" onClick={closenewWarning} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationSchema}
                    onSubmit={addOrEditWarning}
                    enableReinitialize
                  >
                    {(formik) => (
                      <Form>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12}>
                            <label className="pageLabel">
                              Select By Keywords
                            </label>
                            <input
                              className="prodSearchInpt modalSearchBgNew"
                              placeholder="Search"
                              onChange={(e) => getEmployeeList(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <div className="popListingMain">
                              {employeeList.map((employeeObj, index) => (
                                <>
                                  <div
                                    className={
                                      selectedId === employeeObj.employee_id
                                        ? "popListingInr activeTicket"
                                        : "popListingInr"
                                    }
                                    key={index}
                                    onClick={() =>
                                      onSelectEmployee(employeeObj.employee_id)
                                    }
                                  >
                                    <div className="popListPro">
                                      {employeeObj.profile_pic == "" || employeeObj.profile_pic == null ? (
                                        <img src={avtar} />
                                      ) : (
                                        <img src={employeeObj.profile_pic} />
                                      )}
                                    </div>
                                    <div className="popDetailS">
                                      <h1>
                                        {employeeObj.first_name}{" "}
                                        {employeeObj.last_name}
                                      </h1>
                                      <b>{employeeObj.contact_no}</b>
                                      <span>{employeeObj.email_id}</span>
                                    </div>
                                    <div class="mainListCont mainListInrCont">
                                      <label>Designation</label>
                                      <span class="mainListSubTx">
                                        {employeeObj.designation_name}
                                      </span>
                                    </div>
                                    <div class="mainListCont mainListInrCont">
                                      <label>Department</label>
                                      <span class="mainListSubTx">
                                        {employeeObj.department_name}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ))}
                              <span className="errorSt">
                                {selectedErrorMsg}
                              </span>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Type Of Warning"
                              placeholder="Warning Type"
                              labelClass="pageLabel"
                              name="warningtype"
                              isRequired="true"
                              className="prodSearchInpt"
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Warning Subject"
                              placeholder="Subject"
                              labelClass="pageLabel"
                              name="subject"
                              isRequired="true"
                              className="prodSearchInpt"
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <FormikControl
                              control="textarea"
                              type="textarea"
                              label="Enter Description"
                              placeholder="Type here"
                              labelClass="pageLabel"
                              name="description"
                              isRequired="true"
                              className="modalTexArea"
                            />
                          </Grid>
                          {/* <Grid item xs={12} md={12}>
                            <div className="myNewAttach">
                              <div className="atchmentMain">
                                <div
                                  className="atchMain"
                                  onChange={(e) => uploadImageAndFile(e)}
                                >
                                  <AttachmentOutlinedIcon />
                                  <input type="file" id="attachmentid" />
                                  <span>Add Attachment</span>
                                  <span className="formatNot">
                                    Note: format allowed for document-
                                    (pdf,image,docs,xls,text)
                                  </span>
                                </div>
                              </div>
                              {uploadImg ? (
                                <>
                                  {uploadImg.map((img, index) => (
                                    <div class="fileName" key={index}>
                                      <span>{img}</span>
                                      <CloseIcon
                                        onClick={() => deleteImage(index)}
                                      />
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div class="fileName">
                                  <span>No File Uploaded</span>
                                </div>
                              )}
                            </div>
                            <span className="errorSt">{imageErrMsg}</span>
                          </Grid> */}
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ textAlign: "center" }}
                          >
                            <span className="lineBtns">
                              <Button
                                className="cancelEditBtn"
                                onClick={closenewWarning}
                              >
                                <span>Cancel</span>
                              </Button>

                              <Button
                                className="modalBtSmallWidth"
                                type="submit"
                              >
                                {isEditWarning ? (
                                  <span>Update</span>
                                ) : (
                                  <span>Save</span>
                                )}
                              </Button>
                            </span>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add Damage Cost */}
      <div>
        <Dialog
          open={AddDamageCost}
          // onClose={closeCartonOrSingle}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Damage Cost
            <CloseIcon className="modalCloseBt" onClick={closeAddDamageCost} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <label className="pageLabel">Enter Amount</label>
                      <input
                        className="prodSearchInpt modalSearchBgNew"
                        placeholder="Enter Amount"
                        value={damageAmt}
                        onChange={(e) => validateAmount(e.target.value)}
                      />
                      <span className="errorSt">{amtErrMsg}</span>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <span className="lineBtns">
                        <Button
                          className="modalBtSmallWidth"
                          onClick={() => saveDamageCost()}
                        >
                          Add
                        </Button>
                      </span>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default WarningsTb;
