import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import profilePlaceholder from '../assets/images/Profile-Picture.jpg';
import { red, pink, blue } from '@mui/material/colors';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../components/FormikControl";
import { Button, Grid, IconButton } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import { makeGetRequest, makePostRequest, uploadFileRequest } from '../../utils/utils';
import moment from 'moment';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import wordImage from '../../assets/images/doc.png';
import pdfImage from '../../assets/images/pdf.png';
import excelImage from '../../assets/images/exel.png';
import txtImage from '../../assets/images/text.png';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SupportTicketMobile() {

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [uploadFile, setUploadFile] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [uploadfileErrMsg, setUploadfileErrMsg] = useState("");
  const [isEditTicket, setIsEditTicket] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [searchString, setSearchString] = useState(null);
  const [ticketList, setTicketList] = useState([]);
  const [closeTicketList, setCloseTicketList] = useState([]);
  const [flagsearch, setflagsearch] = useState(false);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [flagsearchclose, setflagsearchclose] = useState(false);
  const [filteredDataSourceclose, setFilteredDataSourceclose] = useState([]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsEditTicket(false);
    setUploadFile([]);
    setFormValues(null);
  };

  const Modal = ({ isOpen, onClose, children }) => {

    if (!isOpen) return null;

    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-content">{children}</div>
        </div>
      </div>
    );
  };

  const initialValues = {
    ticket_title: "",
    description: "",
  };

  const validationSchema = Yup.object({
    ticket_title: Yup.string().required("Title is required"),
    description: Yup.string()
      .min(2, 'Too Short!')
      .max(200, 'Too Long!')
      .required("Description is required"),
  });

  async function uploadImageOrFile(fileData) {
    var fileUrl = "";
    try {
      setShowLoader(true);
      const formData = new FormData();
      formData.append("image", fileData);

      await uploadFileRequest("superadmin/upload", formData).then(
        (response) => {
          console.log(response);
          fileUrl = response.data.imagepath;
        }
      );
      setShowLoader(false);
    } catch (error) {
      console.log(error);
    }
    return fileUrl;
  }

  async function validateImage(fileData) {
    if (
      fileData.name.match(
        /.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|docx|DOCX|doc|DOC|pdf|PDF|xls|XLS|txt|TXT|xlsx|XLSX)$/i
      )
    )
      return true;
    else return false;
  }

  async function uploadDocument(e) {
    setUploadfileErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];
    let validateRes = false;
    if (await validateImage(file)) {
      var imageUrl = await uploadImageOrFile(file);
      setUploadFile([...uploadFile, imageUrl]);
      console.log("img arr", uploadFile);
    } else {
      setUploadfileErrMsg("Invalid file format");
    }
    console.log("uploadFile", uploadFile)
    setShowLoader(false);
  }

  const deleteImage = (index) => {
    uploadFile.splice(index, 1);
    setUploadFile([...uploadFile]);
  }

  const addOrEditTicket = (values, { resetForm }) => {
    var userid = parseInt(localStorage.getItem("userId"));
    if (isEditTicket) {
      delete values.first_name;
      delete values.last_name;
      delete values.employee_id;
      delete values.contact_no;
      delete values.profile_pic;
      values.member_id = [userid];
      values.sender_id = userid;
      values.fileuploaded = uploadFile;
      console.log("Update Ticket", values);
      makePostRequest("/employee/updateTicket", values)
        .then((response) => {
          if (response.data) {
            console.log(response.data);
          }
          closeModal()
          getTicketList(null);
          resetForm();
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
        });
    } else {
      console.log(values);
      values.member_id = [userid];
      values.sender_id = userid;
      values.fileuploaded = uploadFile;
      values.ticket_visible_flag = false;
      console.log("CreateTicket", values);
      makePostRequest("/employee/createTicket", values)
        .then((response) => {
          if (response.data) {
            console.log(response.data);
          }
          closeModal();
          getTicketList(null);
          resetForm();
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
        });
    }
  }

  const editTicket = (ticketObj) => {
    setIsEditTicket(true);
    openModal();
    setFormValues(ticketObj);
    setUploadFile(ticketObj.fileuploaded);
  }

  const reOpenTicket = (id) => {
    let inputdata = {
      ticketid: id,
      is_resolve: false,
    };
    makePostRequest("/employee/updateTicket", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          getCloseTicketList(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  var userid = parseInt(localStorage.getItem("userId"));

  const getTicketList = async (searchstr) => {

    setShowLoader(true);
    await makeGetRequest("/employee/getallticket/" + userid + "/" + true + "/" + false + "/" + searchString)
      .then((response) => {
        if (response.data.data) {
          console.log('TicketList');
          console.log(response.data.data[0].json_agg);
          setTicketList(response.data.data[0].json_agg);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  const getCloseTicketList = async (searchstr) => {

    setSearchString(searchstr);
    setShowLoader(true);
    if (!searchstr) searchstr = null;
    await makeGetRequest("/employee/getallticket/" + userid + "/" + true + "/" + true + "/" + searchString)
      .then((response) => {
        if (response.data.data) {
          setCloseTicketList(response.data.data[0].json_agg);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  const ticketDetails = (Tid, Tobj) => {
    // console.log("Tobj",Tobj)
    navigate("/SupportTicketDetails", {
      state: { data: Tobj }
    })
  }

  let clkindex = null;
  const urls = [];
  const [bindurls, setbindurls] = React.useState([]);

  const viewDocs = (nId, index, fileuploaded) => {
    setbindurls([]);
    urls.length = 0;
    bindurls.length = 0;
    const btn = document.getElementById("noticeSlideID" + index);
    if (btn) {
      if (clkindex !== null) {
        const prevDivindex = document.getElementById(
          "noticeSlideID" + clkindex
        );
        if (prevDivindex) {
          prevDivindex.classList.remove("expandfilesDiv");
        }
      }
      btn.classList.toggle("expandfilesDiv");
      clkindex = index;
    }

    fileuploaded.forEach((item) => {
      try {
        const parsedItem = JSON.parse(item);

        if (parsedItem && parsedItem.path) {
          urls.push(parsedItem.path);
        } else {
          urls.push(item);
        }
      } catch (error) {
        urls.push(item);
        console.error("Error:", error);
      }
    });
    setbindurls(urls);
  };

  const searchDataopenticket = (e) => {

    try {

      let text = e.target.value
      if (text) {
        setflagsearch(true);
        let a;
        const ticketdata = ticketList.filter((item) => {
          const itemData = item.ticket_title.toUpperCase();
          const textData = text.toUpperCase();
          return itemData.startsWith(textData);
        });
        console.log(ticketdata)
        if (ticketdata.length > 0) {
          setFilteredDataSource(ticketdata.sort(sortbyascename));
        } else {
          console.log('in else')
          setFilteredDataSource([]);
          //     // const newDataname = reportingemployeelist.filter((item) => {
          //     //   const itemData = item.last_name.toUpperCase();
          //     //   const textData = text.toUpperCase();
          //     //   return itemData.startsWith(textData);
          //     // });
          //     // if (newDataname.length > 0) {
          //     //   setFilteredDataSource(newDataname.sort(sortbyascename));
          //     // } else {
          //     //   setFilteredDataSource([]);  
          //     // }
        }
      } else {
        setflagsearch(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function sortbyascename(a, b) {
    if (a.ticket_title < b.ticket_title) {
      return -1;
    }
    // if (a.ticket_title > b.ticket_title) {
    //   return 1;
    // }
    // if (a.last_name < b.last_name) {
    //   return -1;
    // }
    // if (a.last_name > b.last_name) {
    //   return 1;
    // }
    return 0;
  };

  const searchDatacloseticket = (e) => {

    try {

      let text = e.target.value
      if (text) {
        setflagsearchclose(true);
        let a;
        const closeTicketdata = closeTicketList.filter((item) => {
          const itemData = item.ticket_title.toUpperCase();
          const textData = text.toUpperCase();
          return itemData.startsWith(textData);
        });
        if (closeTicketdata.length > 0) {
          setFilteredDataSourceclose(closeTicketdata.sort(sortbyascename));
        } else {
          setFilteredDataSourceclose([]);
        }
      } else {
        setflagsearch(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTicketList(null);
    getCloseTicketList(null);
  }, [])


  return (
    <div className="mobileCssMain">

      <div className="container">
        <div className="headerDataIcons">
          <div className="screenTitle">
            <Button onClick={() => navigate(-1)}>
              <IconButton className="TouchableIconBtn"> <ArrowBackIosIcon />
              </IconButton>
            </Button>
            <h2>Support Ticket</h2>
          </div>
          {/* <div className="topheaderIcons headerGreyIcon">
            </div> */}
        </div>
      </div>

      <div className="container">
        <div className="wrapper">
          <div className="profileTabs TicketTabView">
            <Box sx={{ width: "100%" }}>

              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  className="profileTopTab"
                  value={value}
                  onChange={handleChange}
                >
                  <Tab
                    label="Open Ticket"
                    className="toptabActive"
                    {...a11yProps(0)}
                  />
                  <Tab label="Close Ticket" {...a11yProps(1)} />
                </Tabs>
              </Box>

              {/* Open Ticket */}

              <CustomTabPanel value={value} index={0}>

                <div className="newSearchOuter">
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={searchDataopenticket}
                  />
                </div>


                {flagsearch ?
                  <>
                    {filteredDataSource ? (
                      <>
                        {filteredDataSource.map((ticketObj, index) =>
                        (<>
                          {ticketObj.is_resolve === false ?
                            <div className="complainBox" key={index}>
                              <div className='TicketNumberNIconView'>
                                <div className='TicketNumberView'>
                                  <p className='TicketNumberTxt'>Ticket no. T {ticketObj.ticketid}</p>
                                </div>
                                <div>
                                  <IconButton className="TouchableIconBtn" onClick={() => editTicket(ticketObj)} >
                                    <EditRoundedIcon />
                                  </IconButton>
                                </div>
                              </div>
                              <div onClick={() => ticketDetails(ticketObj.ticketid, ticketObj)}>
                                <div className="TickeImgNameTimeView">
                                  <div className='TicketImgNameView'>
                                    {ticketObj.profile_pic == null ?
                                      <img src={profilePlaceholder} alt='img' className='TicketProfileImg' /> :
                                      <img src={ticketObj.profile_pic} alt='img' className='TicketProfileImg' />
                                    }
                                    <div >
                                      <p className='TicketUserName'>{ticketObj.first_name} {ticketObj.last_name}</p>
                                      <p className='TicketUserMoNo'>1243432435</p>
                                    </div>
                                  </div>
                                  <div className='TicketTimeStatusView'>
                                    <p className='TicketTimeStatusTxt'>Time & Date</p>
                                    <p className='TicketTimeStatusTxt'>{moment(ticketObj.created_at).format("DD/MM/YYYY hh:mm a")}</p>
                                    {/* <p className='TicketTimeStatusTxt'>Ticket Status Open</p> */}
                                  </div>
                                </div>
                                <p className='TicketTitle'>{ticketObj.ticket_title}</p>
                                {ticketObj.description.length > 65 ? <p className='TicketDescription'>{ticketObj.description.substring(0, 65)} <span id={`dots`}>...{" "}</span></p>
                                  :
                                  <p className='TicketDescription'>{ticketObj.description}</p>}
                              </div>
                              <div className="boxActions">
                                {ticketObj?.fileuploaded?.length > 0 && (
                                  <span onClick={() =>
                                    viewDocs(
                                      ticketObj.ticketid,
                                      index,
                                      ticketObj.fileuploaded
                                    )
                                  } className="downoadYourFile" >
                                    <FileDownloadIcon />
                                    <em>Show Documents To Download </em>
                                  </span>)}
                              </div>
                              <div
                                className="expandfilesDiv"
                                id={"noticeSlideID" + index}
                              >
                                <div className="doqumentDiv1">
                                  {ticketObj?.fileuploaded?.length > 0 && (
                                    <>
                                      {bindurls.map((opt, index) => (
                                        <div
                                          id="documentDiv"
                                          className="doqumentDiv1"
                                        >
                                          <a href={opt} target="_blank">
                                            <div className="doquView">
                                              {opt.split(".").pop() ==
                                                "txt" ? (
                                                <img src={txtImage} />
                                              ) : opt.split(".").pop() ==
                                                "pdf" ? (
                                                <img src={pdfImage} />
                                              ) : opt.split(".").pop() ==
                                                "docx" ||
                                                opt.split(".").pop() ==
                                                "doc" ? (
                                                <img src={wordImage} />
                                              ) : opt.split(".").pop() ==
                                                "xls" ||
                                                opt.split(".").pop() ==
                                                "xlsx" ? (
                                                <img src={excelImage} />
                                              ) : (
                                                <img src={opt} />
                                              )}
                                            </div>
                                          </a>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            : null}
                        </>))}
                      </>
                    )
                      : null
                    }
                  </> :
                  ticketList ?
                    <>
                      {ticketList.map((ticketObj, index) =>
                      (<>
                        {ticketObj.is_resolve === false ? <div className="complainBox" key={index}>
                          <div className='TicketNumberNIconView'>
                            <div className='TicketNumberView'>
                              <p className='TicketNumberTxt'>Ticket no. T {ticketObj.ticketid}</p>
                            </div>
                            <div>
                              <IconButton className="TouchableIconBtn" onClick={() => editTicket(ticketObj)} >
                                <EditRoundedIcon />
                              </IconButton>
                              {/* <CloseRoundedIcon sx={{ color: red[500] }} /> */}
                            </div>
                          </div>
                          <div onClick={() => ticketDetails(ticketObj.ticketid, ticketObj)}>
                            <div className="TickeImgNameTimeView">
                              <div className='TicketImgNameView'>
                                {ticketObj.profile_pic == null ?
                                  <img src={profilePlaceholder} alt='img' className='TicketProfileImg' /> :
                                  <img src={ticketObj.profile_pic} alt='img' className='TicketProfileImg' />
                                }
                                <div >
                                  <p className='TicketUserName'>{ticketObj.first_name} {ticketObj.last_name}</p>
                                  <p className='TicketUserMoNo'>1243432435</p>
                                </div>
                              </div>
                              <div className='TicketTimeStatusView'>
                                <p className='TicketTimeStatusTxt'>Time & Date</p>
                                <p className='TicketTimeStatusTxt'>{moment(ticketObj.created_at).format("DD/MM/YYYY hh:mm a")}</p>
                                {/* <p className='TicketTimeStatusTxt'>Ticket Status Open</p> */}
                              </div>
                            </div>
                            <p className='TicketTitle'>{ticketObj.ticket_title}</p>
                            {ticketObj.description.length > 65 ? <p className='TicketDescription'>{ticketObj.description.substring(0, 65)} <span id={`dots`}>...{" "}</span></p>
                              :
                              <p className='TicketDescription'>{ticketObj.description}</p>}
                          </div>
                          <div className="boxActions">
                            {ticketObj?.fileuploaded?.length > 0 && (
                              <span onClick={() =>
                                viewDocs(
                                  ticketObj.ticketid,
                                  index,
                                  ticketObj.fileuploaded
                                )
                              } className="downoadYourFile" >
                                <FileDownloadIcon />
                                <em>Show Documents To Download </em>
                              </span>)}
                          </div>
                          <div
                            className="expandfilesDiv"
                            id={"noticeSlideID" + index}
                          >
                            <div className="doqumentDiv1">
                              {ticketObj?.fileuploaded?.length > 0 && (
                                <>
                                  {bindurls.map((opt, index) => (
                                    <div
                                      id="documentDiv"
                                      className="doqumentDiv1"
                                    >
                                      <a href={opt} target="_blank">
                                        <div className="doquView">
                                          {opt.split(".").pop() ==
                                            "txt" ? (
                                            <img src={txtImage} />
                                          ) : opt.split(".").pop() ==
                                            "pdf" ? (
                                            <img src={pdfImage} />
                                          ) : opt.split(".").pop() ==
                                            "docx" ||
                                            opt.split(".").pop() ==
                                            "doc" ? (
                                            <img src={wordImage} />
                                          ) : opt.split(".").pop() ==
                                            "xls" ||
                                            opt.split(".").pop() ==
                                            "xlsx" ? (
                                            <img src={excelImage} />
                                          ) : (
                                            <img src={opt} />
                                          )}
                                        </div>
                                      </a>
                                    </div>
                                  ))}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                          : null}
                      </>))}
                    </>
                    :
                    <div>
                      <p>No Ticket Available</p>
                    </div>
                }

                <div className="fixedBtnOuter">
                  <Button className="loginBt" onClick={openModal}>
                    <p>Create New Ticket</p>
                  </Button>
                </div>

                <Modal isOpen={isOpen}>
                  <div className="modal-footer">
                    <CloseOutlinedIcon className="closeBtn" onClick={() => closeModal()} />
                  </div>
                  <div className="leaveAppForm">
                    <h3>{isEditTicket ? <>Edit Ticket</> : <>Create New Ticket</>}</h3>
                    <Formik
                      initialValues={formValues || initialValues}
                      validationSchema={validationSchema}
                      onSubmit={addOrEditTicket}
                      enableReinitialize
                    >
                      {(formik) => (
                        <Form>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <FormikControl
                                control="input"
                                type="text"
                                label="Ticket Title"
                                placeholder="Enter Ticket Title"
                                labelClass="loginLable"
                                name="ticket_title"
                                className="loginInput"
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <FormikControl
                                control="textarea"
                                type="text"
                                label="Ticket Description"
                                placeholder="Enter Ticket Description"
                                labelClass="loginLable"
                                name="description"
                                className="txtAreaInput"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              {/* <div className='FileAttachment' onChange={(e)=>uploadDocument(e)}>
                            <AttachmentIcon />
                            <input type="file" />
                            <p>Add Attachment</p>
                          </div> */}
                              <span style={{ fontSize: 14, color: 'red' }}>{uploadfileErrMsg}</span>
                              {uploadFile.map((uploadImg, index) => <div className='FileView' key={index}>
                                <p>{uploadImg}</p>
                                <CloseIcon onClick={() => deleteImage(index)} />
                              </div>)}
                            </Grid>
                          </Grid>
                          <Button className="loginBt" type="submit">
                            {isEditTicket ? <>Update Ticket</> : <>Create New Ticket</>}
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Modal>

              </CustomTabPanel>

              {/* Close Ticket */}
              <CustomTabPanel value={value} index={1}>
                <>
                  <div className="newSearchOuter">
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={searchDatacloseticket}
                    />
                  </div>

                  {flagsearchclose ?
                    <>
                      {filteredDataSourceclose ? (
                        <>
                          {filteredDataSourceclose.map((ticketObj, index) =>
                          (<>
                            {ticketObj.is_resolve === false ?
                              <div className="complainBox">
                                <div className='TicketNumberNIconView'>
                                  <div className='TicketNumberView'>
                                    <p className='TicketNumberTxt'>Ticket no. T {ticketObj.ticketid}</p>
                                  </div>
                                  <div className='TicketReopenBtn' onClick={() => reOpenTicket(ticketObj.ticketid)}>
                                    <p className='TicketNumberTxt'>Reopen Ticket</p>
                                  </div>
                                </div>
                                <div className="TickeImgNameTimeView">
                                  <div className='TicketImgNameView'>
                                    {ticketObj.profile_pic == null ?
                                      <img src={profilePlaceholder} alt='img' className='TicketProfileImg' /> :
                                      <img src={ticketObj.profile_pic} alt='img' className='TicketProfileImg' />
                                    }
                                    <div>
                                      <p className='TicketUserName'>{ticketObj.first_name} {ticketObj.last_name}</p>
                                      <p className='TicketUserMoNo'>1234567890</p>
                                    </div>
                                  </div>
                                  <div className='TicketTimeStatusView'>
                                    <p className='TicketTimeStatusTxt'>Time & Date</p>
                                    <p className='TicketTimeStatusTxt'>{moment(ticketObj.created_at).format("DD/MM/YYYY hh:mm a")}</p>
                                  </div>
                                </div>
                                <p className='TicketTitle'>{ticketObj.ticket_title}</p>
                                <p className='TicketDescription'>{ticketObj.description}</p>
                              </div>
                              :
                              null
                            }
                          </>))}
                        </>
                      )
                        : null
                      }
                    </> :
                    closeTicketList ?
                      <div>
                        {
                          closeTicketList.map((ticketObj, index) =>
                          (
                            <>
                              {ticketObj.is_resolve == true ?
                                <div className="complainBox">
                                  <div className='TicketNumberNIconView'>
                                    <div className='TicketNumberView'>
                                      <p className='TicketNumberTxt'>Ticket no. T {ticketObj.ticketid}</p>
                                    </div>
                                    <div className='TicketReopenBtn' onClick={() => reOpenTicket(ticketObj.ticketid)}>
                                      <p className='TicketNumberTxt'>Reopen Ticket</p>
                                    </div>
                                  </div>
                                  <div className="TickeImgNameTimeView">
                                    <div className='TicketImgNameView'>
                                      {ticketObj.profile_pic == null ?
                                        <img src={profilePlaceholder} alt='img' className='TicketProfileImg' /> :
                                        <img src={ticketObj.profile_pic} alt='img' className='TicketProfileImg' />
                                      }
                                      <div>
                                        <p className='TicketUserName'>{ticketObj.first_name} {ticketObj.last_name}</p>
                                        <p className='TicketUserMoNo'>1234567890</p>
                                      </div>
                                    </div>
                                    <div className='TicketTimeStatusView'>
                                      <p className='TicketTimeStatusTxt'>Time & Date</p>
                                      <p className='TicketTimeStatusTxt'>{moment(ticketObj.created_at).format("DD/MM/YYYY hh:mm a")}</p>
                                    </div>
                                  </div>
                                  <p className='TicketTitle'>{ticketObj.ticket_title}</p>
                                  <p className='TicketDescription'>{ticketObj.description}</p>
                                </div>
                                : null}
                            </>
                          ))
                        }

                      </div>
                      :
                      <div>
                        <p>No Ticket Available</p>
                      </div>}
                </>
              </CustomTabPanel>

            </Box>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupportTicketMobile