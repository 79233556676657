import React, { useState, useEffect } from "react";
import BottomTabsPage from "../../MobileScreens/componentsMobile/BottomTabsPage/BottomTabsPage";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import barberShop from "../assets/images/barberShop.svg";
import starCafe from "../assets/images/starCafe.svg";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import IconButton from "@mui/material/IconButton";
import Sports from "../assets/images/Sports.svg";
import starMarket from "../assets/images/starMarket.svg";

import MobileHeader from "../componentsMobile/MobileHeader/MobileHeader";
import noData from "../../MobileScreens/assets/images/noData.webp";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { makeGetRequest } from "../../utils/utils";
import { ORDER_TYPE_CAFE, ORDER_TYPE_MARKET } from "../../constants/orderTypes";
import { useCart } from "../Screens/CartContext";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ReorderTab() {

  const {
    cart,
    addCategoryProduct,
    reduceCategoryCount,
    marketcart,
    addMarketProduct,
    reduceMarketCategoryCount,
    removeFromCart,
    removeMarketItemFromCart,
  } = useCart();

  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [count4, setCount4] = useState(0);

  //star cafe
  const clickAddBt = (id, item, index) => {
    setItems((prevItems) =>
      items.map((item) =>
        item.subcategory_id === id
          ? { ...item, class: "addedCount" }
          : { ...item, class: "" }
      )
    );

    const updatedItem = { ...item, class: "addedCount" };
    addCategoryProduct(updatedItem);
    var element = document.getElementById("addAndOuntMain" + index);
    element.classList.toggle("addedCount");
  };

  const increment = (id, item) => {
    setItems((prevItems) =>
      items.map((item) =>
        item.subcategory_id === id ? { ...item, count: item.count + 1 } : item
      )
    );
    addCategoryProduct(item);
  };

  const decrement = (id, item) => {
    setItems((prevItems) =>
      // 
      displayItems.map((item) => {
        if (item.subcategory_id === id) {
          const newCount = Math.max(item.count - 1, 0);
          const updatedItem = {
            ...item,
            count: newCount === 0 ? 1 : newCount,
            class: "",
          };
          reduceCategoryCount(item);
          if (newCount === 0) {
            removeFromCart(item.subcategory_id);
          }
          console.log(updatedItem)
          return updatedItem;
        }
        return item;
      })
    );
    //reduceCategoryCount(item);
  };

  //star market
  const clickAddBtmarket = (id, item, index) => {
    setMarketItems((prevItems) =>
      itemsmarket.map((item) =>
        item.product_id === id
          ? { ...item, class: "addedCount" }
          : { ...item, class: "" }
      )
    );
    const updatedItem = { ...item, class: "addedCount" };
    addMarketProduct(updatedItem);
    var element = document.getElementById("addAndOuntMainM" + index);
    element.classList.toggle("addedCount");
  };

  const incrementmarket = (id, item) => {
    setMarketItems((prevItems) =>
      itemsmarket.map((item) =>
        item.product_id === id ? { ...item, count: item.count + 1 } : item
      )
    );
    addMarketProduct(item);
  };

  const decrementmarket = (id, item) => {
    setMarketItems((prevItems) =>
      //   itemsmarket.map((item) =>
      //     item.product_id === id ? { ...item, count: item.count - 1 || 1 } : item
      //   )
      displayItemsmarket.map((item) => {
        if (item.product_id === id) {
          const newCount = Math.max(item.count - 1, 0);
          const updatedItem = {
            ...item,
            count: newCount === 0 ? 1 : newCount,
            class: "",
          };
          reduceMarketCategoryCount(item);
          if (newCount === 0) {
            removeMarketItemFromCart(item.product_id);
          }
          return updatedItem;
        }
        return item;
      })

    );
    //reduceMarketCategoryCount(item);
  };

  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);
  const [items, setItems] = useState([]);
  const [itemsmarket, setMarketItems] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Star cafe Order List
  async function getItemList() {
    let employee_id = parseInt(localStorage.getItem("userId"));
    await makeGetRequest("starcafe/getreorderitemlist/" + employee_id)
      .then((response) => {
        if (response.data.data) {
          const updatedData = response.data.data.map((item) => ({
            ...item,
            count: 1,
            class: "",
            type: ORDER_TYPE_CAFE,
          }));
          setItems(updatedData);
        } else {
          setItems([]);
        }
      })
      .catch((err) => {
        setItems([]);
      });
  }

  const naviagtestarcafeordersummary = async () => {
    navigate("/OrderSummary", {
      state: { Fromlocation: "Star Cafe", type: "Star Cafe" },
    });
  };

  const mergeItems = (items, cart) => {
    const cartMap = new Map(cart.map((item) => [item.subcategory_id, item]));
    const mergedItems = items.map((item) =>
      cartMap.has(item.subcategory_id) ? cartMap.get(item.subcategory_id) : item
    );
    return mergedItems;
  };

  const displayItems = mergeItems(items, cart);
  const totals =
    cart && cart.length > 0
      ? cart.reduce(
        (sums, item) => {
          sums.total_star_price += (item.Star_price || 0) * item.count;
          sums.total_price_in_stars +=
            (item.price_in_stars || 0) * item.count;
          return sums;
        },
        { total_star_price: 0, total_price_in_stars: 0 }
      )
      : { total_star_price: 0, total_price_in_stars: 0 };

  const { total_star_price, total_price_in_stars } = totals;

  // Star Market order list
  async function getItemListMarket() {
    let employee_id = parseInt(localStorage.getItem("userId"));
    await makeGetRequest("starmarket/getreorderitemlist/" + employee_id)
      .then((response) => {
        if (response.data.data) {
          const updatedData = response.data.data.map((item) => ({
            ...item,
            count: 1,
            class: "",
            type: ORDER_TYPE_MARKET,
          }));
          setMarketItems(updatedData);
        } else {
          setMarketItems([]);
        }
      })
      .catch((err) => {
        setMarketItems([]);
      });
  }

  const naviagtemarketordersummary = async () => {
    localStorage.setItem("servicetype", "Starmarket");
    navigate("/OrderSummaryMarket", {
      state: { Fromlocation: "Star Market", type: "Star Market" },
    });
  };

  const mergeItemsmarket = (items, cart) => {
    const cartMap = new Map(cart.map((item) => [item.product_id, item]));
    const mergedItems = items.map((item) =>
      cartMap.has(item.product_id) ? cartMap.get(item.product_id) : item
    );
    return mergedItems;
  };

  const displayItemsmarket = mergeItemsmarket(itemsmarket, marketcart);

  //

  const Markettotals =
    marketcart && marketcart.length > 0
      ? marketcart.reduce(
        (sums, item) => {
          sums.total_star_price += (item.Star_price || 0) * item.count;
          sums.total_price_in_stars +=
            (item.price_in_stars || 0) * item.count;
          return sums;
        },
        { total_star_price: 0, total_price_in_stars: 0 }
      )
      : { total_star_price: 0, total_price_in_stars: 0 };

  console.log(Markettotals);
  const {
    total_star_price: Markettotal_star_price,
    total_price_in_stars: Markettotal_price_in_stars,
  } = Markettotals;

  useEffect(() => {
    getItemList();
    getItemListMarket();
  }, []);

  return (
    <>
      <div className="mobileCssMain">
        <div className="dashMain forBottomTabOnly">
          <MobileHeader />
          <div className="wrapper">
            <div className="tabMain contentTopSpacing activeOrdersTab">
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      icon={
                        <div className="tabsImgOuter">
                          <img src={starCafe} />
                        </div>
                      }
                      label="Star Cafe"
                      {...a11yProps(0)}
                    />
                    <Tab
                      icon={
                        <div className="tabsImgOuter">
                          <img src={starMarket} />
                        </div>
                      }
                      label="Star Market"
                      {...a11yProps(1)}
                    />

                    <Tab
                      icon={
                        <div className="tabsImgOuter">
                          <img src={barberShop} />
                        </div>
                      }
                      label="Barber Shop"
                      {...a11yProps(2)}
                    />
                    <Tab
                      icon={
                        <div className="tabsImgOuter">
                          <img src={Sports} />
                        </div>
                      }
                      label="Sports"
                      {...a11yProps(3)}
                    />
                  </Tabs>
                </Box>

                {/* StarCafe */}
                <CustomTabPanel value={value} index={0}>
                  {/* Star Cafe Content Here */}
                  <div className="activeOrdersList">
                    <div className="orderMenuList myNewReordTabScroll">
                      {displayItems.map((item, index) => (
                        <div
                          key={item.subcategory_id}
                          className="orderNotiDiv specialMenu"
                        >
                          <div className="reordDi">
                            <div className="orderDetailsProd removeFlex">
                              <h3>{item.product_name}</h3>
                            </div>
                            <p>Product Id {item.subcategory_id}</p>
                            <div className="starPrice orderStarPrice">
                              <StarIcon className="starIcon" />
                              <h5> {item.Star_price} </h5>
                            </div>
                          </div>

                          <div
                            key={index}
                            className={"countWithAddBtMain " + item.class}
                            //id="addAndOuntMain"
                            id={`addAndOuntMain` + index}
                          >
                            <div className="newCountSt">
                              <IconButton
                                onClick={() =>
                                  clickAddBt(item.subcategory_id, item, index)
                                }
                                className="TouchableIconBtn"
                              >
                                <Button className="beforeAddBt">
                                  <AddIcon />
                                </Button>
                              </IconButton>

                              <div className="outerIncdec">
                                <IconButton
                                  onClick={() =>
                                    decrement(item.subcategory_id, item)
                                  }
                                  className="TouchableIconBtn"
                                >
                                  <Button className="IncDrcBtn">
                                    <RemoveIcon />
                                  </Button>
                                </IconButton>

                                <b className="countValue countValue2">
                                  {item.count}
                                </b>

                                <IconButton
                                  onClick={() =>
                                    increment(item.subcategory_id, item)
                                  }
                                  className="TouchableIconBtn"
                                >
                                  <Button className="IncDrcBtn">
                                    <AddIcon />
                                  </Button>
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="fixedBtnOuter fixedBtnWithBotTab">
                    <Button
                      className="loginBt"
                      onClick={() => naviagtestarcafeordersummary()}
                    >
                      <p style={{ marginRight: "60px" }}>
                        {cart.length} Item |{" "}
                        {total_star_price + total_price_in_stars} Stars
                      </p>
                      <p>View Cart</p>
                    </Button>
                  </div>
                </CustomTabPanel>

                {/* StarMarket */}
                <CustomTabPanel value={value} index={1}>
                  {/* Star Market Content Here */}
                  <div className="activeOrdersList">
                    <div className="orderMenuList orderMenuListWithViewBt">
                      {displayItemsmarket.map((item, index) => (
                        <div className="orderNotiDiv specialMenu">
                          <div className="reordDi">
                            <div className="orderDetailsProd removeFlex">
                              <h3>{item.product_name}</h3>
                              {/* <p>150Grams</p> */}
                            </div>
                            <p>Product Id {item.product_id}</p>
                            <div className="starPrice orderStarPrice">
                              <StarIcon className="starIcon" />
                              <h5> {item.price_in_stars} </h5>
                            </div>
                          </div>
                          <div
                            key={index}
                            className={"countWithAddBtMain " + item.class}
                            //id="addAndOuntMain"
                            id={`addAndOuntMainM` + index}
                          >
                            <div className="newCountSt">
                              <IconButton
                                onClick={() =>
                                  clickAddBtmarket(item.product_id, item, index)
                                }
                                className="TouchableIconBtn"
                              >
                                <Button className="beforeAddBt">
                                  <AddIcon />
                                </Button>
                              </IconButton>

                              <div className="outerIncdec">
                                <IconButton
                                  onClick={() =>
                                    decrementmarket(item.product_id, item)
                                  }
                                  className="TouchableIconBtn"
                                >
                                  <Button className="IncDrcBtn">
                                    <RemoveIcon />
                                  </Button>
                                </IconButton>

                                <b className="countValue countValue2">
                                  {item.count}
                                </b>
                                <IconButton
                                  onClick={() =>
                                    incrementmarket(item.product_id, item)
                                  }
                                  className="TouchableIconBtn"
                                >
                                  <Button className="IncDrcBtn">
                                    <AddIcon />
                                  </Button>
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="fixedBtnOuter fixedBtnWithBotTab">
                    <Button
                      className="loginBt"
                      onClick={() => naviagtemarketordersummary()}
                    >
                      {marketcart.length} Item | {Markettotal_price_in_stars}{" "}
                      Stars
                      <p>View Cart</p>
                    </Button>
                  </div>
                </CustomTabPanel>



                <CustomTabPanel value={value} index={2}>
                  <div className="nodatDiv">
                    <img src={noData} />
                  </div>
                  <div className="fixedBtnOuter fixedBtnWithBotTab">
                    <Button className="loginBt">
                      <p style={{ marginRight: "60px" }}>1 Item | 64 Stars</p>
                      <p>View Cart</p>
                    </Button>
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={3}>
                  <div className="nodatDiv">
                    <img src={noData} />
                  </div>
                  <div className="fixedBtnOuter fixedBtnWithBotTab">
                    <Button className="loginBt">
                      <p style={{ marginRight: "60px" }}>1 Item | 64 Stars</p>
                      <p>View Cart</p>
                    </Button>
                  </div>
                </CustomTabPanel>
              </Box>
            </div>
          </div>
          <BottomTabsPage />
        </div>
      </div>
    </>
  );
}

export default ReorderTab;
