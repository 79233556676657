import React from "react";
import IconButton from "@mui/material/IconButton";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";

import { Button, Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarLogoSmall from "../../assets/images/StarLogoSmall.svg";

function LeaveDetails() {
  const navigate = useNavigate();

  const location = useLocation();
  const { data } = location.state || {};

  return (
    <div className="mobileCssMain">
      <div className="container">
        <div className="wrapper">
          <div className="managerMain">
            <div className="headerData">
              <Button onClick={() => navigate("/Profile")}>
                <IconButton className="TouchableIconBtn">
                  {" "}
                  <ArrowBackIosIcon />
                </IconButton>
              </Button>
              <div className="logo">
                <img src={StarLogoSmall} />
              </div>
              <h5>Star Labels</h5>
            </div>
            <div className="screenTitle">
              <h2>Leave Details</h2>
            </div>
            <div className="warnCard warnDetail">
              <div
                className="empLeavedetail"
                style={{ flexDirection: "column" }}
              >
                <>
                  <div
                    className="leaveDetailsNewForTop"
                    style={{ alignItems: "center" }}
                  >
                    <h2>{data.leavetype}</h2>
                    {data.status == "Pending" ? (
                      //  <p>{item.status}</p>
                      <p className="leaveStatus">
                        Status <span>{data.status}</span>
                      </p>
                    ) : (
                      <p className="leaveStatus" style={{ color: "#008000" }}>
                        Status <span>{data.status}</span>
                      </p>
                    )}
                  </div>

                  <h2>{data.leave_reason}</h2>
                  <p>
                    Applied On {moment(data.created_at).format("YYYY-MM-DD")}
                  </p>
                </>
              </div>

              <h5 className="leaveNewLayoutTitle">Rejection Reason</h5>
              {data.status == "Rejected" ? (
                <p className="leaveStatus">
                  <span className="leaveComments">{data.comments}</span>
                </p>
              ) : null}
              <h4 className="LeaveDetailsDate">
                <b style={{ color: "#696666" }}>From -</b>{" "}
                {moment(data.fromdate).format("YYYY-MM-DD")}{" "}
                <b style={{ color: "#696666" }}>To -</b>{" "}
                {moment(data.todate).format("YYYY-MM-DD")}
              </h4>
              <div className="warnDetailInfo">
                <p>{data.leave_discription}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaveDetails;
