import React from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import editIco from "../../../assets/images/edit-icon.svg";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import StarIcon from "@mui/icons-material/Star";
import avtarPic from "../../../assets/images/avtarPic.png";
import loaderImg from "../../../assets/images/logo.png";

import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeGetRequest } from "../../../utils/utils";
import moment from "moment";

function ProfileDetails() {

  const navigate = useNavigate();

  // Modal Main
  const [open3, setOpen3] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [employeeObj, setEmployeeObj] = React.useState({});
  const [upload, setUpload] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);

  const handleClickOpen3 = (scrollType) => () => {
    setOpen3(true);
    setScroll(scrollType);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open3) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }

    makeGetRequest("employee/getemployee/" + localStorage.getItem("userId"))
      .then((response) => {
        if (response.data) {
          console.log(response.data);
          setEmployeeObj(response.data.data[0]);
          setUpload(!upload);
        }
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
      });
  }, [open3]);
  // End

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1 className="forBackArrowAlign">
                <WestIcon onClick={() => navigate("/EmployeesDetails")} />
                Profile Details
              </h1>
              {/* <Button className="pageTopMainBt">Add Employee</Button> */}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            {employeeObj ? (
              <div className="profileDetMain">
                <div className="employeeTopDiv">
                  <div className="profEditIco">
                    <Tooltip title="Edit" arrow placement="top">
                      <IconButton
                        onClick={() =>
                          navigate(
                            "/EmployeesDetails/" + employeeObj.employee_id
                          )
                        }
                      >
                        <img src={editIco} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className="employeePro">
                    <div className="employeePicDiv">
                      {employeeObj.profile_pic ? (
                        <img src={employeeObj.profile_pic} />
                      ) : (
                        <img src={avtarPic} />
                      )}
                    </div>
                  </div>
                  <div className="showDetailsEmployee">
                    <h1>
                      {employeeObj.first_name} {employeeObj.last_name}
                    </h1>
                    <span>{employeeObj.designation_name}</span>
                    <label>
                      Monthly Star Allowance{" "}
                      <b className="listMainStar">
                        <StarIcon /> 150
                      </b>
                    </label>
                    <Button
                      className="proBtMain"
                      onClick={handleClickOpen3("body")}
                    >
                      Add Stars
                    </Button>
                  </div>
                </div>

                <div className="proDetailList">
                  <div className="proDetailListIner">
                    <h1>Basic Details</h1>
                    <div className="proDetailListData">
                      <div>
                        <label className="dataLab">First Name</label>
                        <span className="mainListSubTx">
                          {employeeObj.first_name}
                        </span>
                      </div>
                      <div>
                        <label className="dataLab">Last Name</label>
                        <span className="mainListSubTx">
                          {employeeObj.last_name}
                        </span>
                      </div>
                      <div>
                        <label className="dataLab">Email Id</label>
                        <span className="mainListSubTx">
                          {employeeObj.email_id}
                        </span>
                      </div>
                      <div>
                        <label className="dataLab">Mobile Number</label>
                        <span className="mainListSubTx">
                          {employeeObj.contact_no}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="proDetailListIner">
                    <h1>Employment Details</h1>
                    <div className="proDetailListData">
                      <div>
                        <label className="dataLab">Designation</label>
                        <span className="mainListSubTx">
                          {employeeObj.designation_name}
                        </span>
                      </div>
                      <div>
                        <label className="dataLab">Department</label>
                        <span className="mainListSubTx">
                          {employeeObj.department_name}
                        </span>
                      </div>
                      <div>
                        <label className="dataLab">Date Of Joinging</label>
                        <span className="mainListSubTx">
                          {moment(employeeObj.date_of_joining).format(
                            "DD/MM/YYYY"
                          )}
                        </span>
                      </div>
                      <div>
                        <label className="dataLab">Passport Number</label>
                        {employeeObj.passport_no ? (
                          <span className="mainListSubTx">
                            {employeeObj.passport_no}
                          </span>
                        ) : (
                          <span className="mainListSubTx">-</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </div>

      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Add Stars */}
      <div>
        <Dialog
          open={open3}
          // onClose={handleClose3}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Star
            <CloseIcon className="modalCloseBt" onClick={handleClose3} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <div className="addStarContD">
                        <b className="startCount">
                          <StarIcon /> 1205
                        </b>
                        <p>Spent Stars</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="addStarContD">
                        <b className="startCount">
                          <StarIcon /> 800
                        </b>
                        <p>Available Stars</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Dollar Amount</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="$"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label className="pageLabel">Enter Star Amount</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Star Quantity"
                      />
                    </Grid>

                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <span onClose={handleClose3}>
                        <Button className="modalBtSmallWidth">Add Stars</Button>
                      </span>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default ProfileDetails;
