import React from "react";
import Left from "../../Left";
import Top from "../../Top";
import { Button, Grid, Box } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import { useNavigate ,useParams} from "react-router-dom";

import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import AddEditCompanyAdmin from "./AddEditCompanyAdmin";

function EditCompanyAdmin() {
  const navigate = useNavigate();
  const { adminId } = useParams();

  React.useEffect(() => {
    console.log("Edit Company ")
    console.log(adminId)
  }, []);

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1 className="forBackArrowAlign">
                {/* <WestIcon onClick={() => navigate("/CompanyAdmins")} /> */}
                Edit Company Admin
              </h1>
              {/* <Button className="pageTopMainBt">Add Employee</Button> */}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <AddEditCompanyAdmin name={adminId}/>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default EditCompanyAdmin;
