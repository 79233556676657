import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import breakfastImg from "../assets/images/breakfastImg.svg";
import cup from "../assets/images/cup.svg";
import mealMenu from "../assets/images/mealMenu.svg";
import StarIcon from "@mui/icons-material/Star";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarLogoSmall from "../assets/images/StarLogoSmall.svg";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import DraftsIcon from "@mui/icons-material/Drafts";
import SwipeRightOutlinedIcon from "@mui/icons-material/SwipeRightOutlined";
import { Padding } from "@mui/icons-material";
import { useCart } from "./CartContext";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { makePostRequest } from "../../utils/utils";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import menuNot from "../../MobileScreens/assets/images/menNot2.jpg";
import swal from "sweetalert";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import success from "../../MobileScreens/assets/images/success.svg";
import ReactSwipeButton from "react-swipe-button";

const drawerBleeding = 50;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const sampleOrder = {
  employee_id: 0,
  ordertype: "",
  orderdetails: [],
  totalstarspend: 0,
  orderstatus: "",
  orderdate: '',
};

const notificationobj = {
  title: "",
  message: "",
  employee_id: "",
};

function OrderSummary(props) {

  const location = useLocation();
  const { cart, removeFromCart } = useCart();
  const { items, Fromlocation, type } = location.state || {};
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);

  const {
    addToCart,
    reduceCount,
    clearCart,
    reduceCategoryCount,
    reduceItemCount,
    addCategoryProduct,
  } = useCart();

  const { window } = props;
  const [open, setOpen] = React.useState(false);
  const [starCount, setstarCount] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const navigate = useNavigate();

  const totals =
    cart && cart.length > 0
      ? cart.reduce(
        (sums, item) => {
          sums.total_star_price += (item.Star_price || 0) * item.count;
          sums.total_price_in_stars +=
            (item.price_in_stars || 0) * item.count;
          return sums;
        },
        { total_star_price: 0, total_price_in_stars: 0 }
      )
      : { total_star_price: 0, total_price_in_stars: 0 };

  const { total_star_price, total_price_in_stars } = totals;

  const totalFree = cart
    .filter((item) => item.isfree ?? false) // Ensure isfree is either true or defaults to false
    .reduce(
      (sum, item) => sum + item.price_in_stars * (item.freecount ?? 0),
      0
    ); // Default freecount to 0 if not present

  const totalComplimentary = cart
    .filter((item) => item.iscomplementory ?? false) // Ensure iscomplementory defaults to false if not present
    .reduce(
      (maxItem, currentItem) => {
        if (currentItem.price_in_stars > maxItem.price_in_stars) {
          return currentItem;
        } else if (currentItem.price_in_stars === maxItem.price_in_stars) {
          return currentItem.count > maxItem.count ? currentItem : maxItem;
        } else {
          return maxItem;
        }
      },
      { price_in_stars: 0, count: 0, freecount: 0 }
    );

  const totalComplimentaryStars =
    totalComplimentary.price_in_stars *
    (totalComplimentary.complementorycount ?? 0);

  const insertOrder = async () => {

    sampleOrder.employee_id = parseInt(localStorage.getItem("userId"));
    sampleOrder.ordertype = localStorage.getItem("servicetype");

    if (sampleOrder.ordertype != 'Starcafe') {
      const date = new Date();
      date.setDate(date.getDate() + 1); // Move to the next day
      const formattedDate = date.toISOString().slice(0, 10);
      sampleOrder.orderdate = formattedDate;
    }
    else {
      const date = new Date();
      const formattedDate = date.toISOString().slice(0, 10);
      sampleOrder.orderdate = formattedDate;
    }

    sampleOrder.orderstatus = "Active";
    sampleOrder.orderdetails = cart;
    sampleOrder.totalstarspend =
      total_star_price +
      total_price_in_stars -
      (totalFree + totalComplimentaryStars);
    let starbalance = localStorage.getItem("starcount");

    if (parseInt(starbalance) >= parseInt(sampleOrder.totalstarspend)) {
      makePostRequest("starcafe/insertorder", sampleOrder)
        .then((response) => {
          if (response.data) {
            localStorage.setItem("orderid", response.data.data.order_id);
            makePostRequest("starcafe/updatesubcategorycount", cart)
              .then((response) => {
                if (response.data) {
                  let userName = localStorage.getItem("username");
                  let id = localStorage.getItem("userId");
                  notificationobj.resident_id = id;
                  notificationobj.title = "New Order Placed";
                  notificationobj.message =
                    userName +
                    " has placed a new order.Order id is" +
                    response.data.data.order_id;
                  makePostRequest("employee/notificationtoapp", notificationobj)
                    .then((response) => {
                      console.log("Notification Placed");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              })
              .catch((err) => {
                console.log(err);
              });
            clearCart();
            handleNavigate(response.data.data.order_id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await swal({
        title: "Warning",
        text: "Your available star is less for order",
        icon: "warning",
        buttons: ["Ok"],
      });
    }
  };

  const handleNavigate = (orderdata) => {

    navigate("/OrderSuccess", {
      state: { data: orderdata, Pickup: Fromlocation, type: type },
    });
  };

  const decrement = (id, item) => {
    // prevItems.map((item) =>
    //   item.menu_id === id
    //     ? {
    //       ...item,
    //       count: Math.max(
    //         item.count - 1,
    //         Math.max(0, 0)
    //       ),
    //     }
    //     : item
    // )

    cart.map((item) => {
      if (item.menu_id === id) {
        const newCount = Math.max(item.count - 1, 0);
        const updatedItem = {
          ...item,
          count: newCount,
          class: "",
        };
        console.log("New Count " + newCount);
        reduceCount(item);
        if (newCount === 0) {
          removeFromCart(item.menu_id);
        }
        return updatedItem;
      } else if (item.subcategory_id === id) {
        console.log("call");
        console.log(item.subcategory_id);
        const newCount = Math.max(item.count - 1, 0);
        const updatedItem = {
          ...item,
          count: newCount,
          class: "",
        };
        console.log("New Count " + newCount);
        reduceCategoryCount(item); //reduceCount(item);
        if (newCount === 0) {
          removeFromCart(item.subcategory_id);
        }
        return updatedItem;
      }
      return item;
    });
    // reduceCount(item);
    // removeFromCart(item.menu_id)
  };

  useEffect(() => {
    setstarCount(localStorage.getItem("starcount"));
  }, []);

  return (
    <>
      <div className="mobileCssMain">
        <div className="container">
          <div className="wrapper">
            <div className="screenTitle">
              <Button onClick={() => navigate(-1)}>
                <IconButton className="TouchableIconBtn">
                  <ArrowBackIosIcon />
                </IconButton>
              </Button>
              <h2>{type}</h2>
              <div className="removeCartSt">
                <ShoppingCartIcon onClick={() => clearCart()} className="" />
                <RemoveCircleIcon className="distIco" />
              </div>
            </div>
            <div className="fixedBottomPadding">
              <h2 className="ordSucHed">Order Summary</h2>
              <div className="addMoreProdOuter">
                <div className="pickUp">
                  <h4>Pickup Location</h4>
                  <p>{Fromlocation}</p>
                </div>
                <div>
                  <Button
                    className="loginBt orderBtn addProdBtn"
                    onClick={() => navigate("/StarCafeHome")}
                  >
                    Add More Products
                  </Button>
                </div>
              </div>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="breakfastBtn orderPrice myOrderPrice">
                    <h2>
                      {total_star_price +
                        total_price_in_stars -
                        (totalFree + totalComplimentaryStars)}
                    </h2>
                    <h6>Payable Stars</h6>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="breakfastBtn orderPrice myOrderPrice">
                    <h2 className="blue">{starCount}</h2>
                    <h6>Available Stars</h6>
                  </div>
                </Grid>
              </Grid>

              {cart.map((item, index) => (
                <>
                  {item.isfree && (item.Category == 'Lunch' || item.Category == 'Dinner') ?
                    <>
                      {index === 0 &&
                        <div className="orderMenuList">
                          <div className="orderNotiDiv specialMenu forCartAlign botSpace ">
                            <>
                              <div key={index}>
                                <h3> {item.Category} Thali Added</h3>
                              </div>
                              <div className="outerIncdec forBottomPosition shwoAndHideQut ">
                                <b className="countValue">1</b>
                              </div>
                            </>
                          </div>
                        </div>
                      }
                    </>
                    :
                    <>
                      <div className="orderMenuList">
                        <div className="orderNotiDiv specialMenu forCartAlign botSpace ">
                          {item.menu_id != undefined ? (
                            <RemoveShoppingCartIcon
                              onClick={() => removeFromCart(item.menu_id)}
                              className="cartNew removeFromCart"
                            />
                          ) : (
                            <RemoveShoppingCartIcon
                              onClick={() => removeFromCart(item.subcategory_id)}
                              className="cartNew removeFromCart"
                            />
                          )}
                          <div key={index}>
                            <h3>{item.menu_name || item.product_name}</h3>
                            <p>Product Id #{item.menu_id || item.subcategory_id}</p>
                            <div className="starPrice">
                              <StarIcon className="starIcon" />
                              <h5> {item.price_in_stars || item.Star_price} </h5>
                              {/* <p className="itemCountSt">
                          Count <b>{item.count}</b>
                        </p> */}
                            </div>
                          </div>
                          {item.menu_id != undefined ? (
                            <div>
                              {item.freecount == 0 ? (
                                <div className="outerIncdec forBottomPosition">
                                  {/* {item.freecount == 0 ? */}
                                  <Button
                                    onClick={() => decrement(item.menu_id, item)} //reduceCount(item)
                                    className="IncDrcBtn"
                                  >
                                    <RemoveIcon />
                                  </Button>
                                  {/* : null } */}
                                  <b className="countValue">{item.count}</b>
                                  {/* {item.freecount == 0 ? */}
                                  <Button
                                    onClick={() => addToCart(item)}
                                    className="IncDrcBtn "
                                  >
                                    <AddIcon />
                                  </Button>
                                  {/* : null} */}
                                </div>
                              ) : (
                                <div className="outerIncdec forBottomPosition shwoAndHideQut ">
                                  <Button
                                    onClick={() => decrement(item.menu_id, item)} //reduceCount(item)
                                    className="IncDrcBtn "
                                  >
                                    <RemoveIcon />
                                  </Button>
                                  <b className="countValue">{item.count}</b>
                                  <Button
                                    onClick={() => addToCart(item)}
                                    className="IncDrcBtn "
                                  >
                                    <AddIcon />
                                  </Button>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>
                              <div className="outerIncdec forBottomPosition">
                                <Button
                                  onClick={() => decrement(item.subcategory_id, item)} // reduceCategoryCount(item)
                                  className="IncDrcBtn"
                                >
                                  <RemoveIcon />
                                </Button>
                                <b className="countValue">{item.count}</b>
                                <Button
                                  onClick={() => addCategoryProduct(item)}
                                  className="IncDrcBtn"
                                >
                                  <AddIcon />
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  }
                </>
              ))}

              {cart.length === 0 ? (
                <div className="menuNotAvailMain">
                  <img src={menuNot} />
                  <p>Your cart is empty.</p>
                </div>
              ) : (
                <div className="orderSummary newSummaryMain">
                  <h4>Order Summary</h4>
                  <div className="summaryData">
                    <div className="summaryItem">
                      <h6>Total Item</h6>
                      <h3>{cart.length}</h3>
                    </div>
                    <div className="summaryItem">
                      <h6>Total Stars</h6>
                      <h3>{total_star_price + total_price_in_stars} Stars</h3>
                    </div>
                    <div className="summaryItem">
                      <h6 className="green">Allowances</h6>
                      <h3 className="green">
                        -{totalFree + totalComplimentaryStars} Stars
                      </h3>
                    </div>
                    <div className="summaryItem">
                      <h6>Payable Stars</h6>
                      <h3 className="blue">
                        {total_star_price +
                          total_price_in_stars -
                          (totalFree + totalComplimentaryStars)}
                      </h3>
                    </div>
                  </div>
                </div>
              )}

              {starCount == 0 ||
                starCount <
                total_star_price +
                total_price_in_stars -
                (totalFree + totalComplimentaryStars) ? (
                <div className="insufficientStars">
                  <h3>
                    You do not have sufficient Stars, please visit Reload Zone
                  </h3>
                </div>
              ) : null}

              {starCount >
                total_star_price +
                total_price_in_stars -
                (totalFree + totalComplimentaryStars) && cart.length > 0 ? (
                <div className="fixedBtnOuter">
                  <Button className="loginBt" onClick={toggleDrawer(true)}>
                    Confirm Order
                  </Button>
                </div>
              ) : null}

              <Root>
                <CssBaseline />
                <Global
                  styles={{
                    ".MuiDrawer-root > .MuiPaper-root": {
                      height: `calc(400px - ${drawerBleeding}px)`,
                      overflow: "visible",
                    },
                  }}
                />
                <SwipeableDrawer
                  container={container}
                  anchor="bottom"
                  open={open}
                  onClose={toggleDrawer(false)}
                  onOpen={toggleDrawer(true)}
                  swipeAreaWidth={drawerBleeding}
                  disableSwipeToOpen={false}
                  ModalProps={{
                    keepMounted: true,
                  }}
                >
                  <StyledBox
                    sx={{
                      position: "absolute",
                      top: -drawerBleeding,
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                      visibility: "visible",
                      right: 0,
                      left: 0,
                    }}
                  >
                    <Puller />
                  </StyledBox>
                  <StyledBox
                    sx={{
                      height: "100%",
                      overflow: "auto",
                    }}
                  >
                    <div className="mobileCssMain">
                      <div className="forgotOter confirmSwipeMain">
                        <div className="wrapper">
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              <img src={success} style={{ width: 60 }} />
                              <h1>Order Confirmation</h1>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className="starPrice confirmStar">
                                <StarIcon className="starIcon" />
                                <h5>
                                  {total_star_price +
                                    total_price_in_stars -
                                    (totalFree + totalComplimentaryStars)}{" "}
                                </h5>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <p>
                                Will be deducted from your account. Swipe right
                                to confirm order
                              </p>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div
                                className="newSwipS"
                              //onClick={() => insertOrder()}
                              >
                                <ReactSwipeButton
                                  text="SWIPE Confirm Order"
                                  color="#c82d33"
                                  text_unlocked="Order Confirmed"
                                  //onClick={() => insertOrder()}
                                  onSuccess={insertOrder}
                                />
                              </div>
                            </Grid>
                            {/* <Grid item xs={12} md={12}>
                              <div className="fixedBtnOuter">
                                <Button
                                  onClick={() => insertOrder()}
                                  className="loginBt "
                                >
                                  <SwipeRightOutlinedIcon className="swipeIcon" />{" "}
                                  Swipe Right To Confirm Order
                                </Button>
                              </div>
                            </Grid> */}
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </StyledBox>
                </SwipeableDrawer>
              </Root>
            </div>
          </div>
        </div >
      </div >
    </>
  );
}

export default OrderSummary;
