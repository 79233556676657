import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import { Button, Grid, Box } from "@mui/material";
import mealImg1 from "../../../assets/images/mealImg1.svg";
import mealImg2 from "../../../assets/images/mealImg2.svg";
import mealImg3 from "../../../assets/images/mealImg3.svg";
import mealMainimg1 from "../../../assets/images/mealMainimg1.svg";
import mealMainimg2 from "../../../assets/images/mealMainimg2.svg";
import editIco from "../../../assets/images/edit-icon.svg";
import StarIcon from "@mui/icons-material/Star";
import deletIco from "../../../assets/images/delete-icon.svg";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import UploadIcon from "@mui/icons-material/Upload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import mealAcordingIcon from "../../../assets/images/mealAcordingIcon.svg";
import DataTable from "react-data-table-component";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import RemoveIcon from "@mui/icons-material/Remove";
import Pagination from "@mui/material/Pagination";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import productImg from "../../../assets/images/productImg.svg";
import mealAcordingIcon2 from "../../../assets/images/mealAcordingIcon2.svg";
import mealAcordingIcon3 from "../../../assets/images/mealAcordingIcon3.svg";
import mealAcordingIcon4 from "../../../assets/images/mealAcordingIcon4.svg";
import mealMainimg3 from "../../../assets/images/mealMainimg3.svg";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

import TaskAltIcon from "@mui/icons-material/TaskAlt";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
// End

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import {
  makeGetRequest,
  makePostRequest,
  uploadFileRequest,
} from "../../../utils/utils";
import moment from "moment";
import swal from "sweetalert";
import { Upload } from "@mui/icons-material";

// For Filter
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// For Filter End

import deliveryTruck from "../../../assets/images/delivery-truck.png";
import packageIm from "../../../assets/images/package.png";
import loaderImg from "../../../assets/images/logo.png";

function GroceryTab() {
  const navigate = useNavigate();

  // For Filter
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterType, setFilterType] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilter = (value) => {
    setFilterType(value);
    setUpload(!upload);
    setAnchorEl(null);
    getGroceryData(null);
  };
  // For Filter End
  const [formValuesAddGrocery, setFormValuesAddGrocery] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [sortBy, setSortBy] = useState("desc");
  const [pageNo, setPageNo] = useState(1);
  const [sortColumnName, setSortColumnName] = useState("created_at");
  const [selected, setSelected] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState("Carton");
  const [groceryData, setGroceryData] = useState([]);
  const [isEditGrocery, setIsEditGrocery] = useState(false);
  const [profileErrMsg, setProfileErrMsg] = React.useState("");
  const [Grocery_image, setGrocery_image] = React.useState([]);
  const [tempGroceryList, setTempGroceryList] = React.useState([]);
  const [upload, setUpload] = React.useState(false);

  const [offsetvalue, setoffsetvalue] = React.useState(0);
  const [itemsPerPage] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState(0);
  const [duplicateErrMsg, setDuplicateErrMsg] = React.useState("");

  const [inputGrossaryData, setInputGrossaryData] = React.useState("");
  const [grossaryItems, setGrossaryItems] = React.useState([]);

  const initialValues = {
    // Add Grocery (Carton)
    grocery_name: "",
    low_stock_limit: 0,
    brand_name: "",
    total_cartons: 0,
    packs_per_carton: 0,
    carton_weight: 0,
    single_unit_weight: 0,
    price_per_carton: 0,
    price_per_unit: 0,
    expiry_date: "",
    country_origin: "",
  };

  const validationSchema = Yup.object({
    // Add Grocery (Carton)
    grocery_name: Yup.string()
      .trim("White spaces not allow")
      .required("Required"),
    low_stock_limit: Yup.string()
      .matches(/^\d+$/, "Invalid value")
      .required("Required"),
    brand_name: Yup.string().required("Required"),
    total_cartons: Yup.number()
      .typeError("Invalid Number")
      .moreThan(
        Yup.ref("low_stock_limit"),
        "value must be greater than low stock limit"
      )
      .required("Required"),
    packs_per_carton: Yup.string()
      .matches(/^\d+$/, "Invalid value")
      .required("Required"),
    carton_weight: Yup.string()
      .matches(/^\d+$/, "Invalid value")
      .required("Required"),
    single_unit_weight: Yup.string()
      .matches(/^\d+$/, "Invalid value")
      .required("Required"),
    price_per_carton: Yup.string()
      .matches(/^\d+$/, "Invalid value")
      .required("Required"),
    price_per_unit: Yup.string()
      .matches(/^\d+$/, "Invalid value")
      .required("Required"),
    expiry_date: Yup.date()
      .min(new Date(), "Date must be greater than todays date")
      .required("Required"),
    country_origin: Yup.string().required("Required"),
  });

  const initialValuesSingleUnit = {
    grocery_name: "",
    low_stock_limit: 0,
    brand_name: "",
    total_unit_bought: 0,
    single_unit_weight: 0,
    price_per_unit: 0,
    expiry_date: "",
    country_origin: "",
  };

  const validationSchemaSingleUnit = Yup.object({
    grocery_name: Yup.string().required("Required"),
    low_stock_limit: Yup.string()
      .matches(/^\d+$/, "Invalid value")
      .required("Required"),
    brand_name: Yup.string().required("Required"),
    total_unit_bought: Yup.number()
      .typeError("Invalid Number")
      .moreThan(
        Yup.ref("low_stock_limit"),
        "value must be greater than low stock limit"
      )
      .required("Required"),
    single_unit_weight: Yup.string()
      .matches(/^\d+$/, "Invalid value")
      .required("Required"),
    price_per_unit: Yup.string()
      .matches(/^\d+$/, "Invalid value")
      .required("Required"),
    expiry_date: Yup.date()
      .min(new Date(), "Date must be greater than todays date")
      .required("Required"),
    country_origin: Yup.string().required("Required"),
  });

  async function uploadImageOrFile(fileData) {
    var fileUrl = "";
    try {
      setShowLoader(true);
      const formData = new FormData();
      formData.append("image", fileData);

      await uploadFileRequest("superadmin/upload", formData).then(
        (response) => {
          console.log(response);
          fileUrl = response.data.imagepath;
        }
      );
      setShowLoader(false);
    } catch (error) {
      console.log(error);
    }
    return fileUrl;
  }

  async function validateImage(fileData) {
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|jfif|JFIF)$/i))
      return true;
    else return false;
  }

  async function uploadProfilePic(e) {
    setProfileErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];
    let validateRes = false;
    if (await validateImage(file)) {
      var imageUrl = await uploadImageOrFile(file);
      Grocery_image.push(imageUrl);
      setGrocery_image(Grocery_image);
    } else {
      setProfileErrMsg("Invalid file format");
    }
    setShowLoader(false);
  }

  async function deleteImage(index) {
    setShowLoader(true);
    Grocery_image.splice(index, 1); // 2nd parameter means remove one item only
    setGrocery_image(Grocery_image);
    setUpload(!Upload);
    setShowLoader(false);
  }

  // Add Or Update Grocery Start
  const addOrUpdateGrocery = (values, { resetForm }) => {
    let updateInventory = true;
    let dupRes = groceryData.find(
      (o) =>
        o.grocery_name.toLowerCase() === values.grocery_name.toLowerCase() &&
        o.brand_name.toLowerCase() === values.brand_name.toLowerCase()
    );

    if (isEditGrocery) {
      // delete values.low_stock_limit;
      if (dupRes) {
        if (values.grocery_id == dupRes.grocery_id) {
          updateInventory = true;
        } else {
          updateInventory = false;
        }
      } else {
        updateInventory = true;
      }
      if (updateInventory) {
        values.low_stock_limit = parseInt(values.low_stock_limit);
        values.unit_of_carton_wt = cartonWeight;
        values.unit_of_single_wt = weightUnit;
        values.total_cartons = parseInt(values.total_cartons);
        values.packs_per_carton = parseInt(values.packs_per_carton);
        values.carton_weight = parseInt(values.carton_weight);
        values.single_unit_weight = parseInt(values.single_unit_weight);
        values.price_per_carton = parseInt(values.price_per_carton);
        values.price_per_unit = parseInt(values.price_per_unit);
        values.Grocery_image = Grocery_image;
        values.status = true;
        values.sku = qrVal;
        console.log("Unit", cartonWeight + " " + weightUnit);
        console.log("values", values);
        makePostRequest("starcafe/updategrocery", values)
          .then((response) => {
            if (response.data) {
              //console.log(response)
              getGroceryData(null);
            }
            closeInventory();
            setIsEditGrocery(false);
            setCartonWeight("");
            setWeightUnit("");
            setFormValuesAddGrocery(null);
            if (selectedType == "Carton") setOpenAddInventory(false);
            else setOpenSingleUnitPop(false);
            setOpenCartonOrSingle(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else setDuplicateErrMsg("Duplicate Value");
    } else {
      if (dupRes) {
        setDuplicateErrMsg("Duplicate Value");
      } else {
        let groceryName = "";
        if (selectedType == "Carton") {
          values.isCarton = true;
          values.total_cartons = parseInt(values.total_cartons);
          values.packs_per_carton = parseInt(values.packs_per_carton);
          values.carton_weight = parseInt(values.carton_weight);
          values.unit_of_carton_wt = cartonWeight;
        } else {
          values.isCarton = false;
          values.total_unit_bought = parseInt(values.total_unit_bought);
          values.current_qty = parseInt(values.total_unit_bought);
          values.new_qty = parseInt(values.total_unit_bought);
        }
        values.sku = qrVal;
        values.unit_of_single_wt = weightUnit;
        values.single_unit_weight = parseInt(values.single_unit_weight);
        values.price_per_carton = parseInt(values.price_per_carton);
        values.price_per_unit = parseInt(values.price_per_unit);

        values.Grocery_image = Grocery_image;
        values.status = true;
        console.log("values", values);
        makePostRequest("starcafe/insertgrocery", values)
          .then((response) => {
            if (response.data) {
              console.log(response.data.message);
              console.log(response);
              groceryName = response.data.data.grocery_name;
              tempGroceryList.push(response.data.data);
              setTempGroceryList(tempGroceryList);
            }
            setCartonWeight("");
            setWeightUnit("");
            setGrocery_image([]);
            resetForm();
            document.getElementById("grocery_name").value = groceryName;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  // Add Grocery End

  //Edit Grocery Api start
  const editGrocery = (groceryObj) => {
    console.log("data", groceryObj);
    setShowLoader(true);
    setFormValuesAddGrocery(groceryObj);
    setQrVal(groceryObj.sku);
    // document.getElementById("scanText").value=groceryObj.sku;
    setIsEditGrocery(true);
    setCartonWeight(groceryObj.unit_of_carton_wt);
    setWeightUnit(groceryObj.unit_of_single_wt);
    if (groceryObj.Grocery_image) setGrocery_image(groceryObj.Grocery_image);
    else setGrocery_image([]);
    if (groceryObj.isCarton == true) {
      setSelectedType("Carton");
      setOpenAddInventory(true);
      setScroll("body");
    } else {
      setSelectedType("SingleUnit");
      setOpenSingleUnitPop(true);
      setScroll("body");
    }

    setShowLoader(false);
  };
  // Edit Grocery End

  const cancelEditGrocery = () => {
    setFormValuesAddGrocery(null);
    setIsEditGrocery(false);
    setCartonWeight(false);
    setWeightUnit(false);
    closeInventory();
    // selectCartonType(KitchenSuppliesObj.category_type)
    setSelectedType("Carton");
  };

  // Status Category Start
  const handleStatusChange = (event, grocery_id) => {
    let inputdata = {
      grocery_id: grocery_id,
      status: event.target.checked,
    };
    console.log("Status", inputdata);

    makePostRequest("starcafe/updategrocery", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          getGroceryData(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Status Category end

  // Delete Category Api Start
  const deleteGrocery = async (grocery_id) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Grocery Deleted!",
          text: "Grocery is successfully deleted!",
          icon: "success",
          timer: 3000,
        }).then(function () {
          let inputdata = {
            grocery_id: grocery_id,
            is_deleted: true,
          };
          //console.log("Input Data", inputdata);
          makePostRequest("starcafe/updategrocery", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                getGroceryData(null);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        //swal("Cancelled", "Your Category is safe :)", "error");
      }
    });
  };
  // Delete Category Api End

  // Sort By Start
  const handleChangeSortBy = () => {
    //alert("In ASC")
    setSortColumnName("grocery_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    //setUpload(!upload);
    getGroceryData(null);
  };

  // Sort By End
  //Sort According to column Start
  function handleChangeSortByColumn(columnName) {
    setSortColumnName(columnName);
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    //console.log('columnName '+columnName+ ' selected '+selected +' sortBy '+sortBy);
    getGroceryData(null);
  }
  //Sort According to column End

  // Check Deduct quantity Start
  const [deductData, setDeductData] = useState([]);
  const [isDeduct, setIsDeduct] = useState(true);

  const checkQuantity = (value, quantity, index) => {
    console.log("value " + value);
    console.log("Quantity " + quantity);

    document.getElementById("span" + index).innerHTML = "";
    var regex = /^[0-9]+$/;

    if (isDeductProduct == true) {
      if (regex.test(value) && quantity >= value) {
        document.getElementById(index).innerHTML = quantity - value;
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    } else {
      if (regex.test(value)) {
        document.getElementById(index).innerHTML =
          parseInt(quantity) + parseInt(value);
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    }
  };

  async function deductGrocery(value, groceryId, quantity, index, iscarton) {
    var regex = /^[0-9]+$/;
    document.getElementById("span" + index).innerHTML = "";

    if (value == "") {
      document.getElementById(index).innerHTML = quantity;
    } else {
      if (regex.test(value) && quantity >= value) {
        await swal({
          title: "Are you sure?",
          text: "Do you really want to move given quantity to kitchen?",
          icon: "warning",
          buttons: ["No", "Yes"],
        }).then(function (isConfirm) {
          if (isConfirm) {
            setShowLoader(true);

            let inputData = {
              grocery_id: groceryId,
            };
            if (iscarton == true)
              inputData.total_cartons =
                document.getElementById(index).innerHTML;
            else
              inputData.total_unit_bought =
                document.getElementById(index).innerHTML;

            makePostRequest("starcafe/updategrocery", inputData)
              .then((response) => {
                if (response.data.data) {
                  swal(
                    "Success",
                    "Quantity moved to kitchen successfully",
                    "success",
                    {
                      timer: 3000,
                      buttons: false,
                    }
                  );
                  getGroceryData(null);
                }
              })
              .catch((err) => {
                setShowLoader(false);
                console.log(err);
              });

            setShowLoader(false);
          }
        });
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    }
    console.log("qty", value);
  }

  async function addGrocery(value, groceryId, quantity, index, iscarton) {
    var regex = /^[0-9]+$/;
    document.getElementById("span" + index).innerHTML = "";

    if (value == "") {
      document.getElementById(index).innerHTML = quantity;
    } else {
      if (regex.test(value)) {
        await swal({
          title: "Are you sure?",
          text: "Do you really want to add given quantity of items to kitchen?",
          icon: "warning",
          buttons: ["No", "Yes"],
        }).then(function (isConfirm) {
          if (isConfirm) {
            setShowLoader(true);

            let inputData = {
              grocery_id: groceryId,
            };
            if (iscarton == true)
              inputData.total_cartons =
                document.getElementById(index).innerHTML;
            else
              inputData.total_unit_bought =
                document.getElementById(index).innerHTML;

            makePostRequest("starcafe/updategrocery", inputData)
              .then((response) => {
                if (response.data.data) {
                  swal(
                    "Success",
                    "Quantity added to kitchen successfully",
                    "success",
                    {
                      timer: 3000,
                      buttons: false,
                    }
                  );
                  getGroceryData(null);
                }
              })
              .catch((err) => {
                setShowLoader(false);
                console.log(err);
              });

            setShowLoader(false);
          }
        });
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    }
    console.log("qty", value);
  }

  // Check Deduct Quantity End
  const handlePageChange = (e, page) => {
    let offsetvalue = (page - 1) * itemsPerPage;
    setoffsetvalue(offsetvalue);
    setPageNo(page);
  };

  // get Grocery Data Start
  const getGroceryData = (SearchStr) => {
    console.log("Search", SearchStr);
    setShowLoader(true);
    if (!SearchStr) SearchStr = null;
    makeGetRequest(
      "starcafe/getgrocery/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        SearchStr +
        "/" +
        offsetvalue +
        "/" +
        filterType +
        "/" +
        itemsPerPage
    )
      .then((response) => {
        if (response.data) {
          console.log("Grocery Data", response.data.data);
          setGroceryData(response.data.data.res);
          setTotalItems(response.data.data.totalCount);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };

  const [alignment, setAlignment] = useState("");
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [cartonWeight, setCartonWeight] = useState("");
  const handleCartonWeightUnit = (event, newAlignment) => {
    setCartonWeight(newAlignment);
  };

  const [weightUnit, setWeightUnit] = useState("");
  const handleWeightPerUnit = (event, newAlignment) => {
    setWeightUnit(newAlignment);
  };

  function setFocus() {
    inputRef.current.focus();
  }

  const [count, setCount2] = useState(0);
  // End
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  // Deduct Grocery
  const [DeductProdct, setOpenDeductProdct] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [isDeductProduct, setIsDeductProdct] = useState(true);

  const handleClickOpenDeductProdct = (scrollType, isdeduct) => () => {
    // setFilterType(true);
    setIsDeductProdct(isdeduct);
    setUpload(!upload);
    getGroceryData(null);
    setOpenDeductProdct(true);
    setScroll(scrollType);

    // setOpenAddToKitchen(true);
    // setScroll(scrollType);
  };

  const closeDeductProdct = () => {
    setOpenDeductProdct(false);
  };

  const descriptionElementRef = useRef(null);
  // End

  // Add Items to Kitchen
  const [AddToKitchen, setOpenAddToKitchen] = React.useState(false);

  const handleClickAddToKitchen = (scrollType) => () => {
    setOpenAddToKitchen(true);
    setScroll(scrollType);
  };

  const closeAddToKitchen = () => {
    setOpenAddToKitchen(false);
  };
  // End

  // Edit Scanned
  const [EditScanned, setOpenEditScanned] = React.useState(false);

  const handleClickEditScanned = (scrollType) => () => {
    setOpenEditScanned(true);
    setScroll(scrollType);
  };

  const closeEditScanned = () => {
    setOpenEditScanned(false);
  };
  // End

  // ScanPop
  const [ScanPop, setOpenScanPop] = React.useState(false);

  const handleClickScanPop = (scrollType) => () => {
    setOpenScanPop(true);
    setScroll(scrollType);
  };

  const closeScanPop = () => {
    grossaryItems.length = 0;
    setGrossaryItems([]);
    setExistingQty(0);
    setUpdatedQty(0);
    setIsNew(false);
    setGroceryName("");
    setOpenScanPop(false);
  };
  // End

  // Modal Main
  const [AddInventory, setOpenAddInventory] = useState(false);

  const handleClickOpenAddInventory = (scrollType) => () => {
    setOpenAddInventory(true);
    setScroll(scrollType);
  };

  const closeInventory = () => {
    setOpenAddInventory(false);
    setIsEditGrocery(false);
    setFormValuesAddGrocery(null);
    setGrocery_image([]);
    setTempGroceryList([]);
    setCartonWeight("");
    setWeightUnit("");
    setProfileErrMsg("");
    setOpenCartonOrSingle(false);
    setDuplicateErrMsg("");
  };

  const inputRef = useRef(null);
  const [qrVal, setQrVal] = useState("");

  const updateQty = async (sku, current_qty, new_qty) => {
    if (isNew) {
      let newItem = {
        sku: qrVal,
        grocery_name: groceryName,
        current_qty: 1,
        new_qty: 1,
        total_unit_bought: 1,
      };
      newItem.status = true;
      grossaryItems.unshift(newItem);
      setGrossaryItems(grossaryItems);
      console.log(newItem);
      setUpload(!upload);
      setShowLoader(true);
      await makePostRequest("starcafe/insertgrocerybyscan", newItem)
        .then((response) => {
          if (response.data) {
            console.log(response.data.message);
            console.log(response);

            setIsNew(false);
            setOpenEditScanned(false);
            setGroceryName("");
            setShowLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });
    } else {
      await makePostRequest("starcafe/scangrocery/", {
        sku: sku,
        current_qty: current_qty,
        new_qty: new_qty,
        total_unit_bought: parseInt(current_qty) + parseInt(new_qty),
      }).then((res) => {
        if (res.data.data) {
          if (
            grossaryItems.some(
              (grossary) => grossary.sku === res.data.data[0].sku
            )
          ) {
            let xcnt = grossaryItems.findIndex(
              (grossary) => grossary.sku === res.data.data[0].sku
            );
            const updateItem = grossaryItems.filter((elem, ind) => {
              return ind !== xcnt;
            });
            updateItem.unshift(res.data.data[0]);
            setGrossaryItems(updateItem);
          } else {
            setGrossaryItems(res.data.data);
            grossaryItems.unshift(res.data.data[0]);
            setGrossaryItems(grossaryItems);
          }
        } else {
          swal({
            title: "Grocery Not Available!",
            text: "Grocery ",
            icon: "success",
            timer: 3000,
          });
        }
      });
    }
  };

  const handleqrqty = (event, index) => {
    let data = [...grossaryItems];
    data[index].new_qty = event.target.value;
    setGrossaryItems(data);
  };

  // End
  const [timer, setTimer] = React.useState();
  const timeoutVal = 10;

  function handleKeyUp(e) {
    window.clearTimeout(timer); // prevent errant multiple timeouts from being generated
    setTimer(
      window.setTimeout(() => {
        console.log(e.target.value);
        if (e.target.value != "") {
          setSkuErrMsg("");
          setQrVal(e.target.value);
          addByScan(e.target.value);
          console.log(e.target.value);
        }
      }, timeoutVal)
    );
  }

  function handleKeyPress(e) {
    window.clearTimeout(timer);
    setQrVal(e.target.value);
  }

  const [groceryName, setGroceryName] = React.useState("");
  const [isNew, setIsNew] = React.useState(false);
  const [existingQty, setExistingQty] = React.useState(0);
  const [updatedQty, setUpdatedQty] = React.useState(0);

  async function addByScan(scannedBarcode) {
    let sku = document.getElementById("scanText").value;
    let isGroceryAvailable = false;
    let groceryObj = {};
    let tempExisting = 0;
    let tempUpdated = 0;
    if (sku) {
      // setShowLoader(true);
      await makeGetRequest("starcafe/getgrocerybysku/" + sku)
        .then((response) => {
          console.log(response);

          if (response.data.data && response.data.data.length > 0) {
            isGroceryAvailable = true;
            groceryObj = response.data.data[0];
            // if (existingQty == 0)
            //   setExistingQty(response.data.data[0].total_unit_bought);
            if (existingQty == 0) {
              setExistingQty(response.data.data[0].total_unit_bought);
              tempExisting = response.data.data[0].total_unit_bought;
            } else tempExisting = existingQty;
            setUpdatedQty(updatedQty + 1);
            tempUpdated = updatedQty + 1;
            setUpload(!upload);
          } else {
            isGroceryAvailable = false;
          }
          setShowLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });
      console.log(isGroceryAvailable);

      if (isGroceryAvailable == true) {
        // setShowLoader(true);
        if (isAddGrocery == true) {
          setSkuErrMsg("Already Exist");
        } else {
          await makePostRequest("starcafe/scangrocery/", {
            sku: sku,
            current_qty: tempExisting, //groceryObj.total_unit_bought,
            new_qty: tempUpdated, //1,
            total_unit_bought: parseInt(groceryObj.total_unit_bought) + 1,
          }).then((res) => {
            if (res.data.data) {
              setInputGrossaryData(res.data.data);

              if (
                grossaryItems.some(
                  (grossary) => grossary.sku === res.data.data[0].sku
                )
              ) {
                let xcnt = grossaryItems.findIndex(
                  (grossary) => grossary.sku === res.data.data[0].sku
                );
                const updateItem = grossaryItems.filter((elem, ind) => {
                  return ind !== xcnt;
                });
                updateItem.unshift(res.data.data[0]);
                setGrossaryItems(updateItem);
              } else {
                setGrossaryItems(res.data.data);
                grossaryItems.unshift(res.data.data[0]);
                setGrossaryItems(grossaryItems);
              }

              document.getElementById("scanText").value = "";
              setShowLoader(false);
            }
          });
        }
      } else {
        if (isAddGrocery == false) {
          await swal({
            title: "Scanned item is not in a list.",
            text: "If you would like to save this item please add grocery name",
            icon: "success",
            buttons: ["No", "Yes"],
          }).then(function (isConfirm) {
            if (isConfirm) {
              // onClick={handleClickEditScanned("body")},
              setIsNew(true);
              setOpenEditScanned(true);
              setScroll("body");
              // let newItem = {
              //   sku: scannedBarcode,
              //   current_qty: 1,
              //   new_qty: 1,
              //   total_unit_bought: 1,
              // };
              // // newItem.status=true;
              // grossaryItems.unshift(newItem);
              // setGrossaryItems(grossaryItems);
              // console.log(newItem);
              // setUpload(!upload);

              // makePostRequest("starcafe/insertgrocerybyscan", newItem)
              //   .then((response) => {
              //     if (response.data) {
              //       console.log(response.data.message);
              //       console.log(response);

              //       setIsNew(false);
              //     }
              //   })
              //   .catch((err) => {
              //     console.log(err);
              //   });
            }

            document.getElementById("scanText").value = "";
          });
        }
      }
    }
  }

  useEffect(() => {
    setGrocery_image(Grocery_image);

    console.log("existingQty " + existingQty);
    console.log("updatedQty " + updatedQty);
  }, [upload, Grocery_image, existingQty, updatedQty, grossaryItems]);

  useEffect(() => {
    getGroceryData(null);

    // setTimeout(function () {
    //   document.getElementById("scanText").select();
    // }, 5000);

    if (AddInventory) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (DeductProdct) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [AddInventory, DeductProdct, filterType, sortColumnName, pageNo]);
  // End

  // Open Carton Or Single
  const [CartonOrSingle, setOpenCartonOrSingle] = React.useState(false);
  const [isAddGrocery, setIsAddGrocery] = React.useState(false);
  const [skuErrMsg, setSkuErrMsg] = React.useState("");
  const handleClickCartonOrSingle = (scrollType) => () => {
    setIsAddGrocery(true);
    setOpenCartonOrSingle(true);
    setScroll(scrollType);
  };

  const closeCartonOrSingle = () => {
    setOpenCartonOrSingle(false);
  };
  // End

  // For Single Unit Only
  const [SingleUnitPop, setOpenSingleUnitPop] = React.useState(false);

  const handleClickSingleUnitPop = (scrollType) => () => {
    setSelectedType("SingleUnit");
    setOpenSingleUnitPop(true);
    setScroll(scrollType);
  };

  const closeSingleUnitPop = () => {
    setOpenSingleUnitPop(false);
    setIsEditGrocery(false);
    getGroceryData(null);
    setFormValuesAddGrocery(null);
    setTempGroceryList([]);
    setGrocery_image([]);
    setProfileErrMsg("");
    setWeightUnit("");
    setOpenCartonOrSingle(false);
    setDuplicateErrMsg("");
    setIsAddGrocery(false);
  };
  // End

  return (
    <>
      <div className="starCafeTabInner">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <div className="listingSearchAndFilterMain">
              <div className="prodSearchDiv">
                <input
                  type="text"
                  className="prodSearchInpt"
                  placeholder="Search"
                  //  id="scanText"
                  onChange={(e) => getGroceryData(e.target.value)}
                />

                <div>
                  <FilterListOutlinedIcon
                    className="filterIconSearch"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  />
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleFilter}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={() => handleFilter(null)}>All</MenuItem>
                    <MenuItem onClick={() => handleFilter(true)}>
                      Carton
                    </MenuItem>
                    <MenuItem onClick={() => handleFilter(false)}>
                      Single Unit
                    </MenuItem>
                  </Menu>
                </div>
              </div>

              <div className="sortingIcoOut">
                <ToggleButton
                  value="check"
                  selected={selected}
                  onChange={() => {
                    handleChangeSortBy();
                  }}
                >
                  <ImportExportIcon
                    className="visitorFilterIco"
                    aria-haspopup="true"
                  />
                </ToggleButton>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} lg={9} className="addImpotBtOuter">
            <Button
              className="pageTopMainBt"
              onClick={handleClickScanPop("body")}
            >
              <AddIcon /> Add Grocery Inventory
            </Button>

            <Button
              className="pageTopMainBt"
              onClick={handleClickOpenDeductProdct("body", false)}
            >
              <AddIcon /> Quick add
            </Button>

            <Button
              className="pageTopMainBt"
              onClick={handleClickOpenDeductProdct("body", true)}
            >
              <RemoveIcon /> Move to Kitchen
            </Button>

            <Button
              className="pageTopMainBt"
              // onClick={handleClickOpenAddInventory("body")}
              onClick={handleClickCartonOrSingle("body")}
            >
              <AddIcon /> Add Grocery
            </Button>
            <Button
              className="pageTopMainBt forImportB"
              component="label"
              variant="contained"
            >
              <UploadIcon />
              <VisuallyHiddenInput type="file" />
            </Button>
          </Grid>
          <Grid item xs={12} lg={12}>
            <div className="serviceistOuter">
              {groceryData.map((groceryObj, index) => {
                return (
                  <div className="mainListDiv" key={groceryObj.grocery_id}>
                    {groceryObj.isCarton ? (
                      <div className="flagSt">Carton</div>
                    ) : (
                      <div className="flagSt">Single Unit</div>
                    )}
                    <div className="mainListCont">
                      <h1
                        onClick={() =>
                          navigate(
                            "/GroceryProductDetails/" + groceryObj.grocery_id
                          )
                        }
                      >
                        {groceryObj.grocery_name}
                      </h1>
                      <p>ID #{groceryObj.grocery_id}</p>
                      <span className="dateStNew">
                        Added on{" "}
                        {moment(groceryObj.created_at).format("DD/MM/YYYY")}
                      </span>
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <div className="smalShortingOuter">
                        <label>Brand</label>
                        <ToggleButton
                          value="check"
                          selected={selected}
                          onChange={() =>
                            handleChangeSortByColumn("brand_name")
                          }
                          className="smalSho"
                        >
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                      <span className="mainListSubTx">
                        {groceryObj.brand_name}
                      </span>
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <div className="smalShortingOuter">
                        <label>In Stock</label>
                        <ToggleButton
                          value="check"
                          id="soryBy{index}"
                          selected={selected}
                          onChange={() =>
                            handleChangeSortByColumn("total_cartons")
                          }
                          className="smalSho"
                        >
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                      <span className="mainListSubTx">
                        {groceryObj.isCarton ? (
                          <>{groceryObj.total_cartons}</>
                        ) : (
                          <>{groceryObj.total_unit_bought}</>
                        )}
                      </span>
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <div className="smalShortingOuter">
                        <label>Low Stock Limit</label>
                        <ToggleButton
                          value="check"
                          selected={selected}
                          onChange={() =>
                            handleChangeSortByColumn("low_stock_limit")
                          }
                          className="smalSho"
                        >
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                      <span className="mainListSubTx">
                        {groceryObj.low_stock_limit}
                      </span>
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <div className="smalShortingOuter">
                        <label>Expiry Date</label>
                        <ToggleButton
                          value="check"
                          selected={selected}
                          onChange={() =>
                            handleChangeSortByColumn("expiry_date")
                          }
                          className="smalSho"
                        >
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                      <span className="mainListSubTx">
                        {moment(groceryObj.expiry_date).format("DD/MM/YYYY")}
                      </span>
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <div className="smalShortingOuter">
                        <label>Purchase Price in INR (₹)</label>
                        <ToggleButton
                          value="check"
                          selected={selected}
                          onChange={() =>
                            handleChangeSortByColumn("price_per_unit")
                          }
                          className="smalSho"
                        >
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                      <span className="mainListSubTx">
                        ₹ {groceryObj.price_per_unit}
                      </span>
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <div className="smalShortingOuter">
                        <label>Total Price in INR (₹)</label>
                        <ToggleButton
                          value="check"
                          selected={selected}
                          onChange={() =>
                            handleChangeSortByColumn("price_per_carton")
                          }
                          className="smalSho"
                        >
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                      <span className="mainListSubTx">
                        {groceryObj.isCarton ? (
                          <>₹ {groceryObj.price_per_carton}</>
                        ) : (
                          <>----</>
                        )}
                      </span>
                    </div>

                    <div className="mainListCont mainListInrCont">
                      <div className="actionBtnOut">
                        {/* <Tooltip title="Edit" arrow placement="top">
                          <IconButton>
                            <img src={printIco} />
                          </IconButton>
                        </Tooltip> */}

                        <Tooltip title="Edit" arrow placement="top">
                          <IconButton onClick={() => editGrocery(groceryObj)}>
                            <img
                              src={editIco}
                              // onClick={handleClickOpenAddInventory("body")}
                            />
                          </IconButton>
                        </Tooltip>

                        <Switch
                          {...label}
                          checked={groceryObj.status}
                          className="swicthMain"
                          onChange={(event) =>
                            handleStatusChange(event, groceryObj.grocery_id)
                          }
                        />

                        <Tooltip title="Delete" arrow placement="top">
                          <IconButton
                            onClick={() => deleteGrocery(groceryObj.grocery_id)}
                          >
                            <img src={deletIco} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={12} md={12} className="paginationOuterMain">
            <Pagination
              count={Math.ceil(totalItems / itemsPerPage)}
              page={pageNo}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </Grid>
        </Grid>
      </div>

      {/* Move Items to Kitchen */}
      <div>
        <Dialog
          open={DeductProdct}
          // onClose={closeDeductProdct}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            {isDeductProduct ? (
              <>Move Items to Kitchen </>
            ) : (
              <>Add Items to Kitchen</>
            )}
            <CloseIcon className="modalCloseBt" onClick={closeDeductProdct} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="visitorSearch">
                        <div className="prodFilter">
                          <input
                            type="text"
                            className="prodSearchInpt"
                            placeholder="Search"
                            onChange={(e) => getGroceryData(e.target.value)}
                          />
                        </div>
                        <div className="sortingIcoOut">
                          <ToggleButton
                            value="check"
                            selected={selected}
                            onChange={() => {
                              handleChangeSortBy();
                            }}
                          >
                            <ImportExportIcon
                              className="visitorFilterIco"
                              aria-haspopup="true"
                            />
                          </ToggleButton>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="productDetails groceryTableMain">
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>Product Name</th>
                              <th>Brand</th>
                              <th>Quantity In Stock</th>
                              {/* <th>Purchase Price</th> */}
                              {isDeductProduct ? (
                                <th>Quantity To Deduct</th>
                              ) : (
                                <th>Quantity To Add</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {groceryData.map((groceryObj, index) => (
                              <tr>
                                <td>{groceryObj.grocery_name}</td>
                                <td>{groceryObj.brand_name}</td>
                                <td id={index}>
                                  {groceryObj.isCarton ? (
                                    <> {groceryObj.total_cartons} </>
                                  ) : (
                                    <> {groceryObj.total_unit_bought}</>
                                  )}{" "}
                                </td>

                                {/* {groceryObj.isCarton ? (
                                  <td>₹ {groceryObj.price_per_carton}</td>
                                ) : (
                                  <td>₹ {groceryObj.price_per_unit}</td>
                                )} */}

                                <td>
                                  <input
                                    type="text"
                                    className="qutyInput"
                                    placeholder="Quantity"
                                    onChange={(e) =>
                                      checkQuantity(
                                        e.target.value,
                                        groceryObj.isCarton
                                          ? groceryObj.total_cartons
                                          : groceryObj.total_unit_bought,
                                        index
                                      )
                                    }
                                    onBlur={(e) =>
                                      isDeductProduct
                                        ? deductGrocery(
                                            e.target.value,
                                            groceryObj.grocery_id,
                                            groceryObj.isCarton
                                              ? groceryObj.total_cartons
                                              : groceryObj.total_unit_bought,
                                            index,
                                            groceryObj.isCarton
                                          )
                                        : addGrocery(
                                            e.target.value,
                                            groceryObj.grocery_id,
                                            groceryObj.isCarton
                                              ? groceryObj.total_cartons
                                              : groceryObj.total_unit_bought,
                                            index,
                                            groceryObj.isCarton
                                          )
                                    }
                                  />
                                  <div style={{ color: "red" }}>
                                    <span id={`span` + index}> </span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} md={12} className="paginationOuterMain">
                      <Pagination
                        count={10}
                        variant="outlined"
                        shape="rounded"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <Button className="modalBtSmallWidth">Move Items</Button>
                    </Grid> */}
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add Grocery  carton*/}
      <div>
        <Dialog
          open={AddInventory}
          // onClose={closeInventory}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            {isEditGrocery ? (
              <>Edit Grocery (Carton)</>
            ) : (
              <>Add Grocery (Carton)</>
            )}
            <CloseIcon className="modalCloseBt" onClick={closeInventory} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv" id="cartonAndUnitId">
                  <Formik
                    initialValues={formValuesAddGrocery || initialValues}
                    validationSchema={validationSchema}
                    onSubmit={addOrUpdateGrocery}
                    enableReinitialize
                  >
                    {(formik) => (
                      <Form>
                        <div>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                              <label labelClass="pageLabel">
                                Enter SKU/Scan code
                              </label>
                              <input
                                type="text"
                                id="scanText"
                                placeholder="Enter SKU/Scan SKU"
                                onKeyUp={handleKeyUp}
                                onChange={handleKeyPress}
                                value={qrVal}
                                isRequired="true"
                                className="prodSearchInpt"
                              />
                              <span className="errorSt">{skuErrMsg}</span>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormikControl
                                control="input"
                                type="text"
                                id="grocery_name"
                                label="Enter Grocery Name"
                                placeholder="Grocery Name"
                                labelClass="pageLabel"
                                name="grocery_name"
                                isRequired="true"
                                className="prodSearchInpt"
                              />
                              <span className="errorSt">{duplicateErrMsg}</span>
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <FormikControl
                                control="input"
                                type="text"
                                label="Enter Low Stock Limit"
                                placeholder="Stock Limit"
                                labelClass="pageLabel"
                                name="low_stock_limit"
                                isRequired="true"
                                className="prodSearchInpt"
                              />
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <div className="addRemoveImagesBox">
                                    {Grocery_image ? (
                                      <>
                                        {Grocery_image.map((option, index) => (
                                          <div className="imageAddedBox">
                                            <img src={option} />
                                            <div className="cloIm">
                                              <CloseIcon
                                                onClick={() =>
                                                  deleteImage(index)
                                                }
                                              />
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {Grocery_image.length < 4 ? (
                                      <div className="forUploadImgErrorOuter">
                                        <div className="uploadImBox">
                                          <h1 className="">Upload Image</h1>
                                          <Button
                                            className="uploadImBt"
                                            component="label"
                                            variant="contained"
                                            startIcon={<CloudUploadIcon />}
                                          >
                                            Upload
                                            <VisuallyHiddenInput
                                              type="file"
                                              onChange={(event) =>
                                                uploadProfilePic(event)
                                              }
                                            />
                                          </Button>
                                          <span>or drag file in here</span>
                                        </div>
                                        <span className="errorSt">
                                          {profileErrMsg}
                                        </span>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <FormikControl
                                    control="input"
                                    type="text"
                                    label="Enter Brand Name"
                                    placeholder="Brand Name"
                                    labelClass="pageLabel"
                                    name="brand_name"
                                    isRequired="true"
                                    className="prodSearchInpt"
                                  />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <FormikControl
                                    control="input"
                                    type="text"
                                    label="Total Number Of Cartons"
                                    placeholder="Number Of Cartons"
                                    labelClass="pageLabel"
                                    name="total_cartons"
                                    isRequired="true"
                                    className="prodSearchInpt"
                                  />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <FormikControl
                                    control="input"
                                    type="text"
                                    label="Enter Packs Per Carton"
                                    placeholder="Packs Per Carton"
                                    labelClass="pageLabel"
                                    name="packs_per_carton"
                                    isRequired="true"
                                    className="prodSearchInpt"
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <FormikControl
                                    control="input"
                                    type="text"
                                    label="Enter Carton Weight"
                                    placeholder="Carton Weight"
                                    labelClass="pageLabel"
                                    name="carton_weight"
                                    isRequired="true"
                                    className="prodSearchInpt"
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <label className="pageLabel">
                                    Select Carton Weight Unit
                                    <span className="requiredStar">*</span>
                                  </label>
                                  <div className="unitDivMain uniTopBotttomSpace">
                                    <div className="detailsTableFilterSec">
                                      <ToggleButtonGroup
                                        value={cartonWeight}
                                        exclusive
                                        onChange={handleCartonWeightUnit}
                                        className="detailsTableFilterSecActionInr"
                                      >
                                        <ToggleButton
                                          value="Kilograms"
                                          className="filterBtn"
                                        >
                                          Kilograms
                                        </ToggleButton>
                                        <ToggleButton
                                          value="Tonne"
                                          className="filterBtn"
                                        >
                                          Tonne
                                        </ToggleButton>
                                        <ToggleButton
                                          value="Liter"
                                          className="filterBtn"
                                        >
                                          Liter
                                        </ToggleButton>
                                      </ToggleButtonGroup>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <FormikControl
                                    control="input"
                                    type="text"
                                    label="Enter Weight Per Unit"
                                    placeholder="Weight Per Unit"
                                    labelClass="pageLabel"
                                    name="single_unit_weight"
                                    isRequired="true"
                                    className="prodSearchInpt"
                                  />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                  <label className="pageLabel">
                                    Select Weight Per Unit
                                    <span className="requiredStar">*</span>
                                  </label>
                                  <div className="unitDivMain uniTopBotttomSpace">
                                    <div className="detailsTableFilterSec">
                                      <ToggleButtonGroup
                                        value={weightUnit}
                                        exclusive
                                        onChange={handleWeightPerUnit}
                                        className="detailsTableFilterSecActionInr"
                                      >
                                        <ToggleButton
                                          value="Kilograms"
                                          className="filterBtn"
                                        >
                                          Kilograms
                                        </ToggleButton>
                                        <ToggleButton
                                          value="Miligrams"
                                          className="filterBtn"
                                        >
                                          Miligrams
                                        </ToggleButton>
                                        <ToggleButton
                                          value="Gram"
                                          className="filterBtn"
                                        >
                                          Gram
                                        </ToggleButton>
                                        <ToggleButton
                                          value="Liter"
                                          className="filterBtn"
                                        >
                                          Liters
                                        </ToggleButton>
                                      </ToggleButtonGroup>
                                    </div>
                                  </div>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                  <FormikControl
                                    control="input"
                                    type="text"
                                    label="Enter Price Per Unit in INR (₹)"
                                    placeholder="Price Per Unit in INR (₹)"
                                    labelClass="pageLabel"
                                    name="price_per_unit"
                                    isRequired="true"
                                    className="prodSearchInpt"
                                  />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <FormikControl
                                    control="input"
                                    type="text"
                                    label="Enter Price Per Carton in INR (₹)"
                                    placeholder="Price Per Carton in INR (₹)"
                                    labelClass="pageLabel"
                                    name="price_per_carton"
                                    isRequired="true"
                                    className="prodSearchInpt"
                                  />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                  <div className="forDateIco">
                                    <FormikControl
                                      control="date"
                                      type="date"
                                      label="Enter Expiry Date"
                                      placeholder="Enter Expiry Date"
                                      labelClass="pageLabel"
                                      name="expiry_date"
                                      isRequired="true"
                                      className="prodSearchInpt"
                                    />
                                    <CalendarMonthIcon />
                                  </div>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <FormikControl
                                    control="input"
                                    type="text"
                                    label="Enter Origin"
                                    placeholder="Country Origin"
                                    labelClass="pageLabel"
                                    name="country_origin"
                                    isRequired="true"
                                    className="prodSearchInpt"
                                  />
                                </Grid>

                                <Grid item xs={12} md={12} className="lineBtns">
                                  {isEditGrocery ? (
                                    <Button
                                      className="cancelEditBtn"
                                      onClick={() => cancelEditGrocery()}
                                    >
                                      <>Cancel</>
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                  <Button
                                    className="modalBtSmallWidth"
                                    type="submit"
                                  >
                                    {isEditGrocery ? (
                                      <>Update Grocery</>
                                    ) : (
                                      <>Add Grocery</>
                                    )}
                                  </Button>
                                </Grid>

                                {tempGroceryList.length > 0 ? (
                                  <>
                                    <Grid item xs={12} md={12}>
                                      <div className="probDetBtOut tablTypsOuterMain">
                                        <div className="tablTypsOuter">
                                          <Button className="probDetBt">
                                            <label>Product Name</label>
                                            <b>
                                              {tempGroceryList[0].grocery_name}
                                            </b>
                                          </Button>
                                          <Button className="probDetBt">
                                            <label>Type</label>
                                            <b>Carton</b>
                                          </Button>
                                        </div>

                                        {/* <Button className="pageTopMainBt blueBt">
                                          <AddIcon /> Add New
                                        </Button> */}
                                      </div>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                      <div className="productDetails groceryTableMain">
                                        <table width="100%">
                                          <thead>
                                            <tr>
                                              <th>Brand</th>
                                              <th>Total Carton Bought</th>
                                              <th>Weight Per Carton</th>
                                              <th>Weight Per Unit</th>
                                              <th>Date</th>
                                              <th>Expiry Date</th>
                                              <th>Price Per Carton</th>
                                              <th>Price Per Unit</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {tempGroceryList.map((option) => (
                                              <tr>
                                                <td>{option.brand_name}</td>
                                                <td>{option.total_cartons}</td>
                                                <td>{option.carton_weight}</td>
                                                <td>
                                                  {option.single_unit_weight}{" "}
                                                  {option.unit_of_single_wt}
                                                </td>
                                                <td>
                                                  {moment(
                                                    option.created_at
                                                  ).format("DD/MM/YYYY")}
                                                </td>
                                                <td>
                                                  {moment(
                                                    option.expiry_date
                                                  ).format("DD/MM/YYYY")}
                                                </td>
                                                <td>
                                                  ₹ {option.price_per_carton}
                                                </td>
                                                <td>
                                                  ₹ {option.price_per_unit}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </Grid>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Open Carton Or Single */}
      <div>
        <Dialog
          open={CartonOrSingle}
          // onClose={closeCartonOrSingle}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Select Carton Or Single Unit
            <CloseIcon className="modalCloseBt" onClick={closeCartonOrSingle} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="cartonOrSinleSelect">
                        <div
                          className="typeSelectionDiv"
                          onClick={handleClickOpenAddInventory("body")}
                        >
                          <div className="typeSelectionImg">
                            <img src={deliveryTruck} />
                          </div>
                          <h1>Carton</h1>
                        </div>

                        <div
                          className="typeSelectionDiv"
                          onClick={handleClickSingleUnitPop("body")}
                        >
                          <div className="typeSelectionImg">
                            <img src={packageIm} />
                          </div>
                          <h1>Single Unit</h1>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* For Single Unit Only */}
      <div>
        <Dialog
          open={SingleUnitPop}
          // onClose={closeSingleUnitPop}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            {isEditGrocery ? (
              <>Edit Grocery (Single Unit)</>
            ) : (
              <>Add Grocery (Single Unit)</>
            )}
            <CloseIcon className="modalCloseBt" onClick={closeSingleUnitPop} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Formik
                    initialValues={
                      formValuesAddGrocery || initialValuesSingleUnit
                    }
                    validationSchema={validationSchemaSingleUnit}
                    onSubmit={addOrUpdateGrocery}
                    enableReinitialize
                  >
                    {(formik) => (
                      <Form>
                        <div>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                              <label labelClass="pageLabel">
                                Enter SKU/Scan code
                              </label>
                              <input
                                type="text"
                                id="scanText"
                                placeholder="Enter SKU/Scan SKU"
                                onKeyUp={handleKeyUp}
                                onChange={handleKeyPress}
                                isRequired="true"
                                className="prodSearchInpt"
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormikControl
                                control="input"
                                type="text"
                                label="Enter Item Name"
                                placeholder="Item Name"
                                labelClass="pageLabel"
                                name="grocery_name"
                                className="prodSearchInpt"
                              />
                              <span className="errorSt">{duplicateErrMsg}</span>
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <FormikControl
                                control="input"
                                type="text"
                                label="Enter Low Stock Limit"
                                placeholder="Stock Limit"
                                labelClass="pageLabel"
                                name="low_stock_limit"
                                className="prodSearchInpt"
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className="addRemoveImagesBox">
                                {Grocery_image ? (
                                  <>
                                    {Grocery_image.map((option, index) => (
                                      <div className="imageAddedBox">
                                        <img src={option} />
                                        <div className="cloIm">
                                          <CloseIcon
                                            onClick={() => deleteImage(index)}
                                          />
                                        </div>
                                      </div>
                                    ))}
                                  </>
                                ) : (
                                  <></>
                                )}
                                {Grocery_image.length < 4 ? (
                                  <div className="uploadImBox">
                                    <h1 className="">Upload Image</h1>
                                    <Button
                                      className="uploadImBt"
                                      component="label"
                                      variant="contained"
                                      startIcon={<CloudUploadIcon />}
                                    >
                                      Upload
                                      <VisuallyHiddenInput
                                        type="file"
                                        onChange={(event) =>
                                          uploadProfilePic(event)
                                        }
                                      />
                                    </Button>
                                    <span>or drag file in here</span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <span className="errorSt">{profileErrMsg}</span>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormikControl
                                control="input"
                                type="text"
                                label="Enter Brand Name"
                                placeholder="Brand Name"
                                labelClass="pageLabel"
                                name="brand_name"
                                className="prodSearchInpt"
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormikControl
                                control="input"
                                type="text"
                                label="Total Units Bought"
                                placeholder="Total Unit"
                                labelClass="pageLabel"
                                name="total_unit_bought"
                                className="prodSearchInpt"
                              />
                            </Grid>

                            <Grid item xs={12} md={5}>
                              <FormikControl
                                control="input"
                                type="text"
                                label="Enter Weight Per Unit"
                                placeholder="Weight"
                                labelClass="pageLabel"
                                name="single_unit_weight"
                                className="prodSearchInpt"
                              />
                            </Grid>
                            <Grid item xs={12} md={7}>
                              <label className="pageLabel">
                                Select Weight Per Unit
                              </label>
                              <div className="unitDivMain uniTopBotttomSpace">
                                <div className="detailsTableFilterSec">
                                  <ToggleButtonGroup
                                    value={weightUnit}
                                    exclusive
                                    onChange={handleWeightPerUnit}
                                    className="detailsTableFilterSecActionInr"
                                  >
                                    <ToggleButton
                                      value="Kilograms"
                                      className="filterBtn"
                                    >
                                      Kilograms
                                    </ToggleButton>
                                    <ToggleButton
                                      value="Gram"
                                      className="filterBtn"
                                    >
                                      Gram
                                    </ToggleButton>
                                    <ToggleButton
                                      value="Miligram"
                                      className="filterBtn"
                                    >
                                      Miligram
                                    </ToggleButton>
                                    <ToggleButton
                                      value="Liter"
                                      className="filterBtn"
                                    >
                                      Liter
                                    </ToggleButton>
                                    <ToggleButton
                                      value="Mililiter"
                                      className="filterBtn"
                                    >
                                      Mililiter
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormikControl
                                control="input"
                                type="text"
                                label="Enter Price Per Unit in INR (₹)"
                                placeholder="Price Per Unit"
                                labelClass="pageLabel"
                                name="price_per_unit"
                                className="prodSearchInpt"
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormikControl
                                control="date"
                                type="date"
                                label="Enter Expiry Date"
                                placeholder="Enter Expiry Date"
                                labelClass="pageLabel"
                                name="expiry_date"
                                className="prodSearchInpt"
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormikControl
                                control="input"
                                type="text"
                                label="Enter Origin"
                                placeholder="Country Origin"
                                labelClass="pageLabel"
                                name="country_origin"
                                className="prodSearchInpt"
                              />
                            </Grid>

                            <Grid item xs={12} md={12} className="lineBtns">
                              <Button
                                className="cancelEditBtn"
                                onClick={() => closeSingleUnitPop()}
                              >
                                <>Cancel</>
                              </Button>
                              <Button
                                type="submit"
                                className="modalBtSmallWidth"
                              >
                                Save
                              </Button>
                            </Grid>

                            {tempGroceryList.length > 0 ? (
                              <>
                                <Grid item xs={12} md={12}>
                                  <div className="probDetBtOut tablTypsOuterMain">
                                    <div className="tablTypsOuter">
                                      <Button className="probDetBt">
                                        <label>Product Name</label>
                                        <b>{tempGroceryList[0].grocery_name}</b>
                                      </Button>
                                      <Button className="probDetBt">
                                        <label>Type</label>
                                        <b>Single Unit</b>
                                      </Button>
                                    </div>

                                    {/* <Button className="pageTopMainBt blueBt">
                                      <AddIcon /> Add New
                                    </Button> */}
                                  </div>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                  <div className="productDetails groceryTableMain">
                                    <table width="100%">
                                      <thead>
                                        <tr>
                                          <th>Brand</th>
                                          <th>Total Units Bought</th>
                                          <th>Weight Per Unit</th>
                                          <th>Date</th>
                                          <th>Expiry Date</th>
                                          <th>Price Per Unit</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {tempGroceryList.map((option) => (
                                          <tr>
                                            <td>{option.brand_name}</td>
                                            <td>{option.total_unit_bought}</td>
                                            <td>
                                              {option.single_unit_weight}{" "}
                                              {option.unit_of_single_wt}
                                            </td>
                                            <td>
                                              {moment(option.created_at).format(
                                                "DD/MM/YYYY"
                                              )}
                                            </td>
                                            <td>
                                              {moment(
                                                option.expiry_date
                                              ).format("DD/MM/YYYY")}
                                            </td>
                                            <td>₹ {option.price_per_unit}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </Grid>
                              </>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add Grocery Inventory */}
      <div>
        <Dialog
          open={ScanPop}
          // onClose={closeDeductProdct}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Grocery Inventory
            <CloseIcon className="modalCloseBt" onClick={closeScanPop} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              // tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="visitorSearch">
                        <div className="prodFilter">
                          <input
                            type="text"
                            id="scanText"
                            className="prodSearchInpt"
                            placeholder="Scan SKU"
                            onKeyUp={handleKeyUp}
                            onChange={handleKeyPress}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="productDetails groceryTableMain">
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>SKU No.</th>
                              <th>Product Name</th>
                              <th>Existing Quantity</th>
                              <th>New Quantity</th>
                              <th>Total Quantity</th>
                              <th>Manage</th>
                            </tr>
                          </thead>
                          <tbody>
                            {grossaryItems.map((elem, index) => (
                              <tr
                                key={index}
                                className={index == 0 ? "newAddedTr" : ""}
                              >
                                <td>{elem.sku}</td>
                                <td>{elem.grocery_name}</td>
                                <td>{elem.current_qty}</td>
                                <td>
                                  <input
                                    type="text"
                                    id="skuqty"
                                    className="qutyInput"
                                    value={elem.new_qty}
                                    onChange={(event) =>
                                      handleqrqty(event, index)
                                    }
                                  />
                                </td>
                                <td>{elem.total_unit_bought}</td>
                                <td className="manageActIcons">
                                  <TaskAltIcon
                                    className="managMarkCheck greenMarkCheck"
                                    onClick={(event) =>
                                      updateQty(
                                        elem.sku,
                                        elem.current_qty,
                                        elem.new_qty
                                      )
                                    }
                                  />
                                  {/* <Button
                                    onClick={handleClickEditScanned("body")}
                                  >
                                    Demo
                                  </Button> */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <Button className="modalBtSmallWidth">Add Item</Button>
                    </Grid> */}
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Edit Scanned */}
      <div>
        <Dialog
          open={EditScanned}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Grocery Inventory
            <CloseIcon className="modalCloseBt" onClick={closeEditScanned} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              // tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="visitorSearch">
                        <div className="prodFilter">
                          <label className="pageLabel">
                            Enter Product Name
                          </label>
                          <input
                            type="text"
                            className="qutyInput"
                            value={groceryName}
                            onChange={(event) =>
                              setGroceryName(event.target.value)
                            }
                          />
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <span onClick={closeEditScanned}>
                        <h5
                          className="moreInoAdd"
                          onClick={handleClickCartonOrSingle("body")}
                        >
                          Would you like to add all information?
                        </h5>
                      </span>
                    </Grid>

                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <Button
                        className="modalBtSmallWidth"
                        onClick={() => updateQty()}
                      >
                        Ok
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default GroceryTab;
