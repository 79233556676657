import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Button, Grid, Box } from "@mui/material";
import mealImg1 from "../../../assets/images/mealImg1.svg";
import mealImg2 from "../../../assets/images/mealImg2.svg";
import mealImg3 from "../../../assets/images/mealImg3.svg";
import freeTag from "../../../assets/images/freeTag.svg";
import compTag from "../../../assets/images/compTag.svg";
import mealMainimg1 from "../../../assets/images/mealMainimg1.svg";
import mealMainimg2 from "../../../assets/images/mealMainimg2.svg";
import editIco from "../../../assets/images/edit-icon.svg";
import StarIcon from "@mui/icons-material/Star";
import deletIco from "../../../assets/images/delete-icon.svg";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import veg from "../../../assets/images/veg.png";
import nonveg from "../../../assets/images/nonveg.png";

import Switch from "@mui/material/Switch";
import DiningIcon from "@mui/icons-material/Dining";

import { styled } from "@mui/material/styles";
import mealAcordingIcon from "../../../assets/images/mealAcordingIcon.svg";
import DataTable from "react-data-table-component";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import mealMainimg3 from "../../../assets/images/mealMainimg3.svg";
import FormGroup from "@mui/material/FormGroup";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
// End

// For According
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//

import Checkbox from "@mui/material/Checkbox";

import FormControlLabel from "@mui/material/FormControlLabel";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import ImportExportIcon from "@mui/icons-material/ImportExport";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import GroceryTab from "./GroceryTab";
import VeggiesTab from "./VeggiesTab";
import KitchenSuppliesTab from "./KitchenSuppliesTab";
import CafeInventoryTab from "./CafeInventoryTab";
import {
  makeGetRequest,
  makePostRequest,
  uploadFileRequest,
} from "../../../utils/utils";
import { AxiosError } from "axios";
import swal from "sweetalert";
import moment from "moment";

import productImg from "../../../assets/images/productImg.svg";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LocalDiningIcon from "@mui/icons-material/LocalDining";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import loaderImg from "../../../assets/images/logo.png";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// Tabs For Weekly Setup
function CustomTabPanelWeekly(props) {
  const { children, Weekly, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={Weekly !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {Weekly === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanelWeekly.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  Weekly: PropTypes.number.isRequired,
};

function a11yPropsWeekly(index) {
  // alert("In All Props")
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// End

function CreateMealMenu() {
  // For Accordion
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel, menuType) => (event, isExpanded) => {
    getMenuListByType(menuType, null);
    document.getElementById("search").value = "";
    setExpanded(isExpanded ? panel : false);
  };
  // End

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const [selected, setSelected] = React.useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [searchString, setSearchString] = useState(null);
  const [menuObj, setMenuObj] = useState([]);
  const [isEditMenu, setIsEditMenu] = useState(false);
  const [formValuesAddMenu, setFormValuesAddMenu] = useState(null);
  const [masterDollarAmount, setMasterDollarAmount] = React.useState(0);
  const [selectedMenuType, setSelectedMenuType] = React.useState("");
  const [menuItemList, setMenuItemList] = React.useState([]);
  const [upload, setUpload] = React.useState(false);
  const [isfree, setIsFree] = React.useState(false);
  const [iscomplementory, setIsComplementory] = React.useState(false);
  const [price_in_dollars, setPriceInDollars] = React.useState(0);
  const [price_in_stars, setPriceInStars] = React.useState(0);
  const [imageErrMsg, setImageErrMsg] = React.useState("");
  const [menu_image, setMenuImage] = React.useState("");
  const [showDiv, setShowDiv] = React.useState(false);
  const [duplicateMenuErrMsg, setDuplicateMenuErrMsg] = React.useState("");
  const [freecount, setFreeCount] = React.useState(1);
  const [complementorycount, setComplementoryCount] = React.useState(1);
  const [sortBy, setSortBy] = useState("desc");
  const [sortColumnName, setSortColumnName] = useState("created_at");

  // Image Upload Start
  async function uploadImageOrFile(fileData) {
    var fileUrl = "";
    try {
      setShowLoader(true);
      const formData = new FormData();
      formData.append("image", fileData);
      console.log(fileData);
      await uploadFileRequest("superadmin/upload", formData).then(
        (response) => {
          fileUrl = response.data.imagepath;
        }
      );
      setShowLoader(false);
    } catch (error) {
      console.log(error);
    }
    return fileUrl;
  }

  async function validateImage(fileData) {
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i))
      return true;
    else return false;
  }

  async function uploadProfilePic(e) {
    setImageErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];
    let validateRes = false;
    if (await validateImage(file)) {
      var imageUrl = await uploadImageOrFile(file);

      if (imageUrl) {
        setMenuImage(imageUrl);
      }
    } else {
      setImageErrMsg("Invalid file format");
    }
    setShowLoader(false);
  }
  // Image Upload End
  // Delete Image Start
  const deleteImg = (id) => {
    setShowLoader(true);
    setMenuImage("");
    setShowLoader(false);
  };
  // Delete Image End

  const formInitials = {
    menu_name: "",
    price_in_dollars: 0,
    price_in_stars: 0,
    // freecount: 1,
    // complementorycount: 1,
  };

  const validationSchema = Yup.object({
    menu_name: Yup.string().required("Required"),
    price_in_dollars: Yup.string().required("Required"),
    price_in_stars: Yup.string().required("Required"),
    // freecount: Yup.number().typeError("Invalid Number"),
    // complementorycount: Yup.number().typeError("Invalid Number"),
  });

  const [radioValue, setRadioValue] = React.useState("");
  const [priceErrMsg, setPriceErrMsg] = React.useState("");
  const [countErrMsg, setCountErrMsg] = React.useState("");
  const [alignmentErrMsg, setAlignmentErrMsg] = React.useState("");

  function handleChangeRadioButton(value) {
    setRadioValue(value);
    console.log(value);
    if (value == "freefood") {
      setIsFree(true);
      setIsComplementory(false);
    } else if (value == "complementoryFood") {
      setIsComplementory(true);
      setIsFree(false);
    }
  }

  function handleChangeCount(count, type) {
    setCountErrMsg("");
    if (type == "complementory") setComplementoryCount(count);
    else if (type == "free") setFreeCount(count);

    let regEx = /^\d+$/;
    if (regEx.test(count)) {
      if (count < 1) setCountErrMsg("Value must be greater than or equal to 1");
    } else {
      setCountErrMsg("Invalid value");
    }
  }
  function getMasterDollarAmount() {
    setShowLoader(true);
    makeGetRequest("employee/getmasterstarcount/")
      .then((response) => {
        if (response.data) {
          console.log(response.data.data[0]);
          setMasterDollarAmount(response.data.data[0].dollar_amount);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        //console.log(err);
        let errMsg = err.response.data.message;
      });
  }

  function handleChangeDollarAmount(value) {
    setPriceErrMsg("");
    setPriceInStars(value);
    let regEx = /^\d+$/;
    if (regEx.test(value)) {
      setPriceInDollars(value * masterDollarAmount);
    } else {
      if (value) setPriceErrMsg("Invalid Value");
      else {
        setPriceErrMsg("Invalid Value");
        setPriceInDollars(0);
      }
    }
  }

  const [todaysMenuBreakfast, setTodaysMenuBreakfast] = useState([]);
  const [todaysMenuLunch, setTodaysMenuLunch] = useState([]);
  const [todaysMenuDinner, setTodaysMenuDinner] = useState([]);

  async function getTodaysMenuItems(selectedMenuType) {
    setSelectedMenuType(selectedMenuType);
    setShowLoader(true);
    let weekday = moment().format("dddd");

    await makeGetRequest("starcafe/getmenulistbytype/" + weekday + "/Breakfast")
      .then((response) => {
        if (response.data) {
          //console.log(response.data.data);
          setTodaysMenuBreakfast(response.data.data);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        //console.log(err);
        let errMsg = err.response.data.message;
      });

    await makeGetRequest("starcafe/getmenulistbytype/" + weekday + "/Lunch")
      .then((response) => {
        if (response.data) {
          //console.log(response.data.data);
          setTodaysMenuLunch(response.data.data);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        //console.log(err);
        let errMsg = err.response.data.message;
      });

    await makeGetRequest("starcafe/getmenulistbytype/" + weekday + "/Dinner")
      .then((response) => {
        if (response.data) {
          //console.log(response.data.data);
          setTodaysMenuDinner(response.data.data);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        //console.log(err);
        let errMsg = err.response.data.message;
      });
  }

  const labelCheck = { inputProps: { "aria-label": "Checkbox demo" } };

  const [alignment, setAlignment] = React.useState([]);

  const handleAlignment = async (event, newAlignment) => {
    if (alignment.includes(newAlignment)) {
      if (isEditMenu == true) {
        await swal({
          title: "Are you sure?",
          text:
            "Do you really want to remove this item from " +
            newAlignment +
            "? ",
          icon: "warning",
          buttons: ["No", "Yes"],
        }).then(function (isConfirm) {
          if (isConfirm) {
            setAlignment((prevArr) => [
              ...prevArr.splice(0, prevArr.indexOf(newAlignment)),
              ...prevArr.splice(prevArr.indexOf(newAlignment) + 1),
            ]);
            if (newAlignment == "Breakfast") {
              setShowDiv(false);
              setIsFree(false);
              setIsComplementory(false);
              setRadioValue("");
            }

            let inputData = {
              menu_id: formValuesAddMenu.menu_id,
              category_type: newAlignment,
            };

            makePostRequest("starcafe/updatemenulistcategorytype/", inputData)
              .then((response) => {
                console.log(response);
                if (response.data) {
                }
              })
              .catch((err) => {
                console.log(err);
                setShowLoader(false);
              });
          }
        });
      } else {
        setAlignment((prevArr) => [
          ...prevArr.splice(0, prevArr.indexOf(newAlignment)),
          ...prevArr.splice(prevArr.indexOf(newAlignment) + 1),
        ]);
        if (newAlignment == "Breakfast") {
          setShowDiv(false);
          setIsFree(false);
          setIsComplementory(false);
          setRadioValue("");
        }
      }
      if (alignment.length == 0) setAlignmentErrMsg("Required");
      else if (alignment.length == 1 && alignment.includes("Special"))
        setAlignmentErrMsg("Required");
      else setAlignmentErrMsg("");
    } else {
      setAlignment((prevArr) => [...prevArr, newAlignment]);
      if (newAlignment == "Breakfast") setShowDiv(true);
      setAlignmentErrMsg("");
    }

    console.log("After Functipnality " + alignment);
  };

  const [checked, setChecked] = React.useState(false);
  const handleChangeCheckbox = (e) => {
    if (alignment.length == 0) {
      setAlignmentErrMsg("Required");
    }

    setChecked(e.target.checked);

    if (e.target.checked == true)
      setAlignment((prevArr) => [...prevArr, "Special"]);
    else if (alignment.includes("Special")) {
      setAlignment((prevArr) => [
        ...prevArr.splice(0, prevArr.indexOf("Special")),
        ...prevArr.splice(prevArr.indexOf("Special") + 1),
      ]);
    }
  };

  const [isNonVeg, setIsNonVeg] = React.useState(false);
  const handleChangeNonVegCheckbox = (e) => {
    setIsNonVeg(e.target.checked);

    if (e.target.checked == true)
      setAlignment((prevArr) => [...prevArr, "NonVeg"]);
    else if (alignment.includes("NonVeg")) {
      setAlignment((prevArr) => [
        ...prevArr.splice(0, prevArr.indexOf("NonVeg")),
        ...prevArr.splice(prevArr.indexOf("NonVeg") + 1),
      ]);
    }
  };
  // Tab For Weekly Setup
  const [Weekly, setValueWeekly] = React.useState(0);
  const handleChangeWeekly = (event, newValue) => {
    setValueWeekly(newValue);
  };
  // End

  // Modal 1
  const [addMenu, setOpenAddMenu] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpenAddMenu = (scrollType) => () => {
    // getStarAmount();
    setOpenAddMenu(true);
    setScroll(scrollType);
  };

  const handleCloseAddMenu = () => {
    setOpenAddMenu(false);
    setFormValuesAddMenu(null);
    setIsEditMenu(false);
    setShowDiv(false);
    setAlignment([]);
    setPriceInDollars(0);
    setPriceInStars(0);
    setRadioValue("");
    setPriceErrMsg("");
    setDuplicateMenuErrMsg("");
    setIsFree(false);
    setIsComplementory(false);
    setIsNonVeg(false);
    setCountErrMsg("");
    setFreeCount(1);
    setComplementoryCount(1);
    setAlignmentErrMsg("");
    setChecked(false);
    setMenuImage("");
  };

  const descriptionElementRef = React.useRef(null);

  // End

  // Add Menu Start
  const addMenufunction = (values, { resetForm }) => {
    console.log(alignment);
    if (alignment.length == 0) setAlignmentErrMsg("Required");

    if (alignmentErrMsg == "") {
      if (isEditMenu) {
        let dupRes = menuObj.find((o) => o.menu_name.toLowerCase() === values.menu_name.toLowerCase() );
        console.log(dupRes);
        if (dupRes && dupRes.menu_id != values.menu_id) {
          setDuplicateMenuErrMsg("Already Exist");
        } else {
          values.category_type = [alignment];
          values.isfree = isfree;
          values.iscomplementory = iscomplementory;
          values.price_in_stars = parseInt(price_in_stars);
          values.price_in_dollars = parseInt(price_in_dollars);
          values.isfree = isfree;
          values.iscomplementory = iscomplementory;
          if (isfree) values.freecount = parseInt(freecount);
          else values.freecount = 0;
          if (menu_image) values.menu_image = [menu_image];
          if (iscomplementory)
            values.complementorycount = parseInt(complementorycount);
          else values.complementorycount = 0;
          setShowLoader(true);
          makePostRequest("starcafe/updatemenu", values)
            .then((response) => {
              if (response.data.data) {
                swal("Success", "MenuItem updated successfully", "success", {
                  timer: 3000,
                  buttons: false,
                });
                resetForm();
                setShowLoader(false);
                handleCloseAddMenu();
              }
            })
            .catch((err) => {
              if (err.response.status == 409) {
                setDuplicateMenuErrMsg("Already exist");
              }
              return err;
            });
          setShowLoader(false);
        }
      } else {
        values.category_type = [alignment];
        values.isfree = isfree;
        values.iscomplementory = iscomplementory;
        values.price_in_stars = parseInt(price_in_stars);
        values.price_in_dollars = parseInt(price_in_dollars);
        if (isfree) values.freecount = parseInt(freecount);
        else values.freecount = 0;

        if (iscomplementory)
          values.complementorycount = parseInt(complementorycount);
        else values.complementorycount = 0;
        values.status = true;
        if (menu_image) values.menu_image = [menu_image];
        console.log("values ", values);
        setShowLoader(true);
        makePostRequest("starcafe/insertmenu", values)
          .then((response) => {
            console.log(response);
            if (response.data.data) {
              swal("Success", "MenuItem added successfully", "success", {
                timer: 3000,
                buttons: false,
              });
              resetForm();
              setShowLoader(false);
              handleCloseAddMenu();
            }
          })
          .catch((err) => {
            if (err.response.status == 409) {
              setDuplicateMenuErrMsg("Already exist");
            }
            return err;
          });
        setShowLoader(false);
      }
    }
  };
  // Add Menu End

  const handleSortBy = () => {
    setSortColumnName("menu_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");

    getMenuList(null);
  };

  // get Menu List Start
  const getMenuList = (SearchStr) => {
    setShowLoader(true);
    if (!SearchStr) SearchStr = null;
    makeGetRequest(
      "starcafe/getmenu/" + SearchStr + "/" + sortColumnName + "/" + sortBy
    )
      .then((response) => {
        if (response.data) {
          setMenuObj(response.data.data);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };
  // get Menu List End

  // Edit Menu List Start
  const editMenu = (menuObj) => {
    //handleClickOpenAddMenu("body")
    console.log(menuObj);
    setFormValuesAddMenu(menuObj);
    console.log(menuObj.menu_image);
    if (menuObj.menu_image != null) setMenuImage(menuObj.menu_image[0]);
    else setMenuImage("");
    setAlignment(...alignment, menuObj.category_type[0]);
    setIsEditMenu(true);
    setPriceInDollars(menuObj.price_in_dollars);
    setPriceInStars(menuObj.price_in_stars);
    if (menuObj.category_type[0].includes("Breakfast")) {
      setShowDiv(true);
      setIsComplementory(menuObj.iscomplementory);
      setIsFree(menuObj.isfree);
      if (menuObj.isfree == true) {
        setFreeCount(menuObj.freecount);
        setRadioValue("freefood");
      } else if (menuObj.iscomplementory == true) {
        setComplementoryCount(menuObj.complementorycount);
        setRadioValue("complementoryFood");
      }
    } else setShowDiv(false);

    if (menuObj.category_type[0].includes("Special")) setChecked(true);
    else setChecked(false);

    setOpenAddMenu(true);
  };
  // Edit Menu List End

  const cancelEditMenu = () => {
    handleCloseAddMenu();
  };

  // Status Menu Start
  const handleStatusChange = (event, menu_id) => {
    let inputdata = {
      menu_id: menu_id,
      status: event.target.checked,
    };

    makePostRequest("starcafe/updatemenu", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          makePostRequest("starcafe/UpdateItemInMenuList/", inputdata)
            .then((response) => {
              console.log(response);
              if (response.data) {
              }
            })
            .catch((err) => {
              console.log(err);
              setShowLoader(false);
            });

          getMenuList(null);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  // Status Menu end

  // Delete Menu Start
  const deleteMenu = async (menu_id) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Menu Deleted!",
          text: "Menu is successfully deleted!",
          icon: "success",
          timer: 3000,
        }).then(function () {
          let inputdata = {
            menu_id: menu_id,
            is_deleted: true,
          };
          ////console.log("Input Data", inputdata);
          makePostRequest("starcafe/updatemenu", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                getMenuList(null);
              }
            })
            .catch((err) => {
              //console.log(err);
            });

          makePostRequest("starcafe/UpdateItemInMenuList/", inputdata)
            .then((response) => {
              console.log(response);
              if (response.data) {
              }
            })
            .catch((err) => {
              console.log(err);
              setShowLoader(false);
            });
        });
      }
    });
  };
  // Delete Menu end

  // Modal
  const [WeeklySetup, setOpenWeeklySetup] = React.useState(false);

  // Handle  weekly menu setting
  async function handleClickOpenWeeklySetup(
    scrollType,
    selectedMenuType,
    index
  ) {
    setShowLoader(true);
    setExpanded(false);
    // setTimeout(() => returnFun(index, scrollType, selectedMenuType), 3000);
    await returnFun(index, scrollType, selectedMenuType);
  }

  async function returnFun(index, scrollType, selectedMenuType) {
    await funOfEmptyObj(selectedMenuType);
    setValueWeekly(index);
    setOpenWeeklySetup(true);
    setScroll(scrollType);
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  async function funOfEmptyObj(selectedMenuType) {
    await getWeeklyMenuList(selectedMenuType);
  }

  async function getMenuListByType(menuType, searchString) {
    let search;
    if (searchString) search = searchString;
    else search = null;
    await makeGetRequest("starcafe/getmenubytype/" + menuType + "/" + search)
      .then((menuresponse) => {
        if (menuresponse.data) {
          setMenuItemList(menuresponse.data.data);
          setUpload(!upload);
        }
      })
      .catch((err) => {
        //console.log(err);
        let errMsg = err.response.data.message;
      });
  }
  async function getWeeklyMenuList(selectedMenuType) {
    setSelectedMenuType(selectedMenuType);

    setSelectedMenuOfMonday({
      category_type: "",
      weekday: "",
      menu_item: [],
      status: "",
    });
    setSelectedMenuOfTuesday({
      category_type: "",
      weekday: "",
      menu_item: [],
      status: "",
    });
    setSelectedMenuOfWednesday({
      category_type: "",
      weekday: "",
      menu_item: [],
      status: "",
    });
    setSelectedMenuOfThursday({
      category_type: "",
      weekday: "",
      menu_item: [],
      status: "",
    });
    setSelectedMenuOfFriday({
      category_type: "",
      weekday: "",
      menu_item: [],
      status: "",
    });
    setSelectedMenuOfSaturday({
      category_type: "",
      weekday: "",
      menu_item: [],
      status: "",
    });
    setSelectedMenuOfSunday({
      category_type: "",
      weekday: "",
      menu_item: [],
      status: "",
    });

    await getMenuListByType(selectedMenuType, null);

    await makeGetRequest("starcafe/getmenulisting/" + selectedMenuType)
      .then((response) => {
        if (response.data) {
          //console.log(response.data.data);
          let temp = response.data.data;

          if (temp.length > 0) {
            for (var i = 0; i < temp.length; i++) {
              //console.log(temp[i]);
              if (temp[i].weekday == "Monday") {
                setSelectedMenuOfMonday(temp[i]);
                setUpload(!upload);
              } else if (temp[i].weekday == "Tuesday") {
                setSelectedMenuOfTuesday(temp[i]);
                setUpload(!upload);
              } else if (temp[i].weekday == "Wednesday") {
                let tempVar = selectedMenuOfWednesday;
                tempVar = temp[i];
                setSelectedMenuOfWednesday(temp[i]);
                setUpload(!upload);
                //console.log("WeekDay Wednesday", tempVar);
              } else if (temp[i].weekday == "Thursday") {
                setSelectedMenuOfThursday(temp[i]);
                setUpload(!upload);
              } else if (temp[i].weekday == "Friday") {
                setSelectedMenuOfFriday(temp[i]);
                setUpload(!upload);
                //console.log("WeekDay Friday", selectedMenuOfFriday);
              } else if (temp[i].weekday == "Saturday") {
                setSelectedMenuOfSaturday(temp[i]);
                setUpload(!upload);
              } else if (temp[i].weekday == "Sunday") {
                setSelectedMenuOfSunday(temp[i]);
                setUpload(!upload);
              }
            }
          } else {
            setSelectedMenuOfMonday({
              category_type: "",
              weekday: "",
              menu_item: [],
              status: "",
            });
            setSelectedMenuOfTuesday({
              category_type: "",
              weekday: "",
              menu_item: [],
              status: "",
            });
            setSelectedMenuOfWednesday({
              category_type: "",
              weekday: "",
              menu_item: [],
              status: "",
            });
            setSelectedMenuOfThursday({
              category_type: "",
              weekday: "",
              menu_item: [],
              status: "",
            });
            setSelectedMenuOfFriday({
              category_type: "",
              weekday: "",
              menu_item: [],
              status: "",
            });
            setSelectedMenuOfSaturday({
              category_type: "",
              weekday: "",
              menu_item: [],
              status: "",
            });
            setSelectedMenuOfSunday({
              category_type: "",
              weekday: "",
              menu_item: [],
              status: "",
            });
          }
        }
      })
      .catch((err) => {
        //console.log(err);
        setShowLoader(false);
      });

    setShowLoader(false);
  }

  const handleCloseWeeklySetup = () => {
    setOpenWeeklySetup(false);
  };

  const [selectedMenuOfSunday, setSelectedMenuOfSunday] = React.useState({
    category_type: "",
    weekday: "",
    menu_item: [],
    status: "",
  });

  const [selectedMenuOfMonday, setSelectedMenuOfMonday] = React.useState({
    category_type: "",
    weekday: "",
    menu_item: [],
    status: "",
  });

  const [selectedMenuOfTuesday, setSelectedMenuOfTuesday] = React.useState({
    category_type: "",
    weekday: "",
    menu_item: [],
    status: "",
  });

  const [selectedMenuOfWednesday, setSelectedMenuOfWednesday] = React.useState({
    category_type: "",
    weekday: "",
    menu_item: [],
    status: "",
  });
  const [selectedMenuOfThursday, setSelectedMenuOfThursday] = React.useState({
    category_type: "",
    weekday: "",
    menu_item: [],
    status: "",
  });
  const [selectedMenuOfFriday, setSelectedMenuOfFriday] = React.useState({
    category_type: "",
    weekday: "",
    menu_item: [],
    status: "",
  });
  const [selectedMenuOfSaturday, setSelectedMenuOfSaturday] = React.useState({
    category_type: "",
    weekday: "",
    menu_item: [],
    status: "",
  });

  const [selectedMenuItemList, setSelectedMenuItemList] = React.useState([]);

  const handleChangeWeekDayCheckBox = (
    isChecked,
    index,
    weekDay,
    menuId,
    categoryType
  ) => {
    let tempList = [];
    if (weekDay == "Monday") {
      tempList = [...selectedMenuOfMonday.menu_item];

      if (tempList.length == 0) {
        selectedMenuOfMonday.category_type = categoryType;
        selectedMenuOfMonday.weekday = weekDay;
        selectedMenuOfMonday.status = true;
      }
      if (isChecked == true) {
        tempList.push(menuId);
        document.getElementById("mon" + index).setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(menuId), 1);
        document
          .getElementById("mon" + index)
          .removeAttribute("checked", false);
      }
      selectedMenuOfMonday.menu_item = tempList;
      setSelectedMenuOfMonday(selectedMenuOfMonday);
      setUpload(!upload);
    } else if (weekDay == "Tuesday") {
      tempList = [...selectedMenuOfTuesday.menu_item];

      if (tempList.length == 0) {
        selectedMenuOfTuesday.category_type = categoryType;
        selectedMenuOfTuesday.weekday = weekDay;
        selectedMenuOfTuesday.status = true;
      }
      console.log(tempList);
      if (isChecked == true) {
        tempList.push(menuId);
        document.getElementById("tues" + index).setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(menuId), 1);
        document
          .getElementById("tues" + index)
          .removeAttribute("checked", false);
      }
      console.log(tempList);
      selectedMenuOfTuesday.menu_item = tempList;
      setSelectedMenuOfTuesday(selectedMenuOfTuesday);
      setUpload(!upload);
    } else if (weekDay == "Wednesday") {
      tempList = [...selectedMenuOfWednesday.menu_item];

      if (tempList.length == 0) {
        selectedMenuOfWednesday.category_type = categoryType;
        selectedMenuOfWednesday.weekday = weekDay;
        selectedMenuOfWednesday.status = true;
      }

      if (isChecked == true) {
        tempList.push(menuId);
        document.getElementById("wed" + index).setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(menuId), 1);
        document
          .getElementById("wed" + index)
          .removeAttribute("checked", false);
      }

      selectedMenuOfWednesday.menu_item = tempList;
      setSelectedMenuOfWednesday(selectedMenuOfWednesday);
      setUpload(!upload);
    } else if (weekDay == "Thursday") {
      tempList = [...selectedMenuOfThursday.menu_item];

      if (tempList.length == 0) {
        selectedMenuOfThursday.category_type = categoryType;
        selectedMenuOfThursday.weekday = weekDay;
        selectedMenuOfThursday.status = true;
      }

      if (isChecked == true) {
        tempList.push(menuId);
        document.getElementById("thur" + index).setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(menuId), 1);
        document
          .getElementById("thur" + index)
          .removeAttribute("checked", false);
      }

      selectedMenuOfThursday.menu_item = tempList;
      setSelectedMenuOfThursday(selectedMenuOfThursday);
      setUpload(!upload);
    } else if (weekDay == "Friday") {
      tempList = [...selectedMenuOfFriday.menu_item];

      if (tempList.length == 0) {
        selectedMenuOfFriday.category_type = categoryType;
        selectedMenuOfFriday.weekday = weekDay;
        selectedMenuOfFriday.status = true;
      }
      if (isChecked == true) {
        tempList.push(menuId);
        document.getElementById("fri" + index).setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(menuId), 1);
        document
          .getElementById("fri" + index)
          .removeAttribute("checked", false);
      }

      selectedMenuOfFriday.menu_item = tempList;
      setSelectedMenuOfFriday(selectedMenuOfFriday);
      setUpload(!upload);
    } else if (weekDay == "Saturday") {
      tempList = [...selectedMenuOfSaturday.menu_item];

      if (tempList.length == 0) {
        selectedMenuOfSaturday.category_type = categoryType;
        selectedMenuOfSaturday.weekday = weekDay;
        selectedMenuOfSaturday.status = true;
      }
      if (isChecked == true) {
        tempList.push(menuId);
        document.getElementById("sat" + index).setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(menuId), 1);
        document
          .getElementById("sat" + index)
          .removeAttribute("checked", false);
      }

      selectedMenuOfSaturday.menu_item = tempList;
      setSelectedMenuOfSaturday(selectedMenuOfSaturday);
      setUpload(!upload);
    } else if (weekDay == "Sunday") {
      tempList = [...selectedMenuOfSunday.menu_item];

      if (tempList.length == 0) {
        selectedMenuOfSunday.category_type = categoryType;
        selectedMenuOfSunday.weekday = weekDay;
        selectedMenuOfSunday.status = true;
      }
      if (isChecked == true) {
        tempList.push(menuId);
        document.getElementById("sunday" + index).setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(menuId), 1);
        document
          .getElementById("sunday" + index)
          .removeAttribute("checked".false);
      }

      selectedMenuOfSunday.menu_item = tempList;
      setSelectedMenuOfSunday(selectedMenuOfSunday);
      setUpload(!upload);
    }
  };

  async function saveMealMenuList() {
    setShowLoader(true);
    selectedMenuItemList.length = 0;
    setSelectedMenuItemList([]);
    console.log(selectedMenuOfTuesday);
    if (selectedMenuOfMonday.menu_item.length > 0) {
      selectedMenuItemList.push(selectedMenuOfMonday);
      setSelectedMenuItemList(selectedMenuItemList);
    }
    if (selectedMenuOfTuesday.menu_item.length > 0) {
      console.log(selectedMenuOfTuesday);
      selectedMenuItemList.push(selectedMenuOfTuesday);
      setSelectedMenuItemList(selectedMenuItemList);
      console.log(selectedMenuItemList);
    }
    if (selectedMenuOfWednesday.menu_item.length > 0) {
      selectedMenuItemList.push(selectedMenuOfWednesday);
      setSelectedMenuItemList(selectedMenuItemList);
    }
    if (selectedMenuOfThursday.menu_item.length > 0) {
      selectedMenuItemList.push(selectedMenuOfThursday);
      setSelectedMenuItemList(selectedMenuItemList);
    }
    if (selectedMenuOfFriday.menu_item.length > 0) {
      selectedMenuItemList.push(selectedMenuOfFriday);
      setSelectedMenuItemList(selectedMenuItemList);
    }
    if (selectedMenuOfSaturday.menu_item.length > 0) {
      selectedMenuItemList.push(selectedMenuOfSaturday);
      setSelectedMenuItemList(selectedMenuItemList);
    }
    if (selectedMenuOfSunday.menu_item.length > 0) {
      selectedMenuItemList.push(selectedMenuOfSunday);
      setSelectedMenuItemList(selectedMenuItemList);
    }

    //console.log(selectedMenuItemList);

    for (var i = 0; i < selectedMenuItemList.length; i++) {
      if (selectedMenuItemList[i].menuitem_id) {
        await makePostRequest(
          "starcafe/updatemenulist",
          selectedMenuItemList[i]
        )
          .then((response) => {
            if (response.data.data) {
              //console.log(response.data);
            }
          })
          .catch((err) => {
            setShowLoader(false);
            //console.log(err);
          });
      } else {
        await makePostRequest(
          "starcafe/insertmenulist",
          selectedMenuItemList[i]
        )
          .then((response) => {
            if (response.data.data) {
              //console.log(response.data);
            }
          })
          .catch((err) => {
            setShowLoader(false);
            //console.log(err);
          });
      }
    }
    setShowLoader(false);

    swal("Success", "Menu List added successfully", "success", {
      timer: 3000,
      buttons: false,
    });
    handleCloseWeeklySetup();
  }

  React.useEffect(() => {
    getMasterDollarAmount();
    getMenuList(null);
    if (WeeklySetup) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (addMenu) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }

    getTodaysMenuItems("Breakfast");
  }, [WeeklySetup, addMenu, searchString, setSelectedMenuOfWednesday]);

  React.useEffect(() => {
    setSelectedMenuOfMonday(selectedMenuOfMonday);
    setSelectedMenuOfTuesday(selectedMenuOfTuesday);
    setSelectedMenuOfWednesday(selectedMenuOfWednesday);
    setSelectedMenuOfThursday(selectedMenuOfThursday);
    setSelectedMenuOfFriday(selectedMenuOfFriday);
    setSelectedMenuOfSaturday(selectedMenuOfSaturday);
    setSelectedMenuOfSunday(selectedMenuOfSunday);
  }, [
    selectedMenuOfMonday,
    selectedMenuOfTuesday,
    selectedMenuOfWednesday,
    selectedMenuOfThursday,
    selectedMenuOfFriday,
    selectedMenuOfSaturday,
    selectedMenuOfSunday,
    upload,
  ]);
  // End

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <>
      <div className="starCafeTabInner">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <div className="accordingMain">
              <Button
                className="adMenuItemsBt"
                onClick={handleClickOpenAddMenu("body")}
              >
                Add Menu Items
              </Button>
              <Accordion>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      onClick={() => {
                        getMenuList(null);
                      }}
                    />
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <img src={mealAcordingIcon} />
                  <b className="AccorddingHeading">
                    Menu Items <span>{menuObj.length} Menu Items Added</span>
                  </b>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12} md={12}>
                    <div className="visitorSearch">
                      <div className="prodFilter">
                        <input
                          type="text"
                          className="prodSearchInpt"
                          placeholder="Search"
                          onChange={(e) => getMenuList(e.target.value)}
                        />
                      </div>
                      <div className="sortingIcoOut">
                        <ToggleButton
                          value="check"
                          selected={selected}
                          onChange={() => {
                            handleSortBy();
                          }}
                        >
                          <ImportExportIcon
                            className="visitorFilterIco"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </div>
                  </Grid>
                  <div className="itemViewMain">
                    {menuObj.map((menuData) => (
                      <div className="menuViewLis" key={menuData.menu_id}>
                        <div className="prodViewOuter">
                          <div className="prodView">
                            <b className="outVegNon">
                              
                              <div className="vegNonVegImg">
                                {/* <img src={veg} />
                                <img src={nonveg} /> */}
                              </div>{" "}
                              {menuData.menu_name}
                            </b>{" "}
                            Product ID #{menuData.menu_id}
                            <div className="freeImgOuter">
                              {menuData.isfree == true ? (
                                <img src={freeTag} />
                              ) : (
                                <></>
                              )}
                              {menuData.iscomplementory ? (
                                <img src={compTag} />
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="lunchViewColor">
                          {menuData.category_type}
                        </div>
                        <span className="mealStar">
                          <StarIcon />
                          {menuData.price_in_stars}
                        </span>
                        <div className="menuItemsAc">
                          <div className="actionBtnOut">
                            <Tooltip title="Edit" arrow placement="top">
                              <IconButton
                                //onClick={handleClickOpenAddMenu("body")}
                                onClick={() => editMenu(menuData)}
                              >
                                <img src={editIco} />
                              </IconButton>
                            </Tooltip>

                            <Switch
                              {...label}
                              checked={menuData.status}
                              className="swicthMain"
                              onChange={(event) =>
                                handleStatusChange(event, menuData.menu_id)
                              }
                            />

                            <Tooltip title="Delete" arrow placement="top">
                              <IconButton
                                onClick={() => deleteMenu(menuData.menu_id)}
                              >
                                <img src={deletIco} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
        </Grid>
        <div className="mealBgMain">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <h3 className="todyMenHeading">
                Todays Menu, {moment().format("dddd DD/MM/YYYY")}
              </h3>
              <p className="todyMenSubHeading">It’s a Beautiful Day </p>
            </Grid>
            <Grid item xs={12} md={4} display="flex">
              <div className="mealMenuDiv">
                {todaysMenuBreakfast.length > 0 ? (
                  <img
                    src={editIco}
                    className="mealEditIco"
                    onClick={() =>
                      handleClickOpenWeeklySetup("body", "Breakfast", 0)
                    }
                  />
                ) : (
                  <></>
                )}
                <div className="mealTop">
                  <div className="mealWithImage">
                    <div className="mealImgTop">
                      <img src={mealImg1} />
                    </div>
                    <h1>Breakfast</h1>
                  </div>
                </div>
                <div className="mealMainImgDiv">
                  <div className="mealMainImg">
                    <img src={mealMainimg1} />
                  </div>
                </div>
                {todaysMenuBreakfast.length == 0 ? (
                  <div className="noMenuAddedMain">
                    <h1>No Menu Added</h1>
                    <div className="mealBtnBottomOuter">
                      <Button
                        onClick={() =>
                          handleClickOpenWeeklySetup("body", "Breakfast", 0)
                        }
                      >
                        Setup Weekly Breakfast Menu
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="mealContentDiv">
                    {todaysMenuBreakfast.map((menu, index) => (
                      <>
                        {!menu.category_type[0].includes("Special") ? (
                          <>
                            <p className="menuOutLine" key={index}>
                              <div className="todayMenuTx">
                                <b>{menu.menu_name}</b> Product ID #
                                {menu.menu_id}
                                <div className="freeImgOuter">
                                  {menu.isfree == true ? (
                                    <img src={freeTag} />
                                  ) : (
                                    <></>
                                  )}
                                  {menu.iscomplementory ? (
                                    <img src={compTag} />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                              <span className="mealStar">
                                <StarIcon /> {menu.price_in_stars}
                              </span>
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                    <div className="addedSpecialDiv">
                      <h4 className="todaySpeHead">Todays Special</h4>

                      <div className="specialMenuListDiv">
                        {todaysMenuBreakfast.map((menu, index) => (
                          <>
                            {menu.category_type[0].includes("Special") ? (
                              <>
                                <div className="specialMenuList">
                                  <div className="todayMenuTx">
                                    <h3>{menu.menu_name}</h3>
                                    <p>Product ID #{menu.menu_id}</p>
                                    <div className="freeImgOuter">
                                      {menu.isfree == true ? (
                                        <img src={freeTag} />
                                      ) : (
                                        <></>
                                      )}
                                      {menu.iscomplementory ? (
                                        <img src={compTag} />
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                  <div className="specialStarsDiv">
                                    <span className="mealStar">
                                      <StarIcon /> {menu.price_in_stars}
                                    </span>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={4} display="flex">
              <div className="mealMenuDiv">
                {todaysMenuLunch.length > 0 ? (
                  <img
                    src={editIco}
                    className="mealEditIco"
                    onClick={() =>
                      handleClickOpenWeeklySetup("body", "Lunch", 1)
                    }
                  />
                ) : (
                  <></>
                )}
                <div className="mealTop">
                  <div className="mealWithImage">
                    <div className="mealImgTop">
                      <img src={mealImg2} />
                    </div>
                    <h1>Lunch Menu</h1>
                  </div>
                </div>
                <div className="mealMainImgDiv">
                  <div className="mealMainImg">
                    <img src={mealMainimg2} />
                  </div>
                </div>

                {todaysMenuLunch.length == 0 ? (
                  <div className="noMenuAddedMain">
                    <h1>No Menu Added</h1>
                    <div className="mealBtnBottomOuter">
                      <Button
                        onClick={() =>
                          handleClickOpenWeeklySetup("body", "Lunch", 1)
                        }
                      >
                        Setup Weekly Lunch Menu
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="mealContentDiv">
                    {todaysMenuLunch.map((menu, index) => (
                      <>
                        {!menu.category_type[0].includes("Special") ? (
                          <>
                            <p className="menuOutLine" key={index}>
                              <div className="todayMenuTx">
                                <b>{menu.menu_name}</b> Product ID #
                                {menu.menu_id}
                                {/* <div className="freeImgOuter">
                                  <img src={freeTag} />
                                </div> */}
                              </div>
                              <span className="mealStar">
                                <StarIcon /> {menu.price_in_stars}
                              </span>
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                    <div className="addedSpecialDiv">
                      <h4 className="todaySpeHead">Todays Special</h4>

                      <div className="specialMenuListDiv">
                        {todaysMenuLunch.map((menu, index) => (
                          <>
                            {menu.category_type[0].includes("Special") ? (
                              <>
                                <div className="specialMenuList">
                                  <h3>{menu.menu_name}</h3>
                                  <p>Product ID #{menu.menu_id}</p>
                                  <div className="specialStarsDiv">
                                    <span className="mealStar">
                                      <StarIcon /> {menu.price_in_stars}
                                    </span>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={4} display="flex">
              <div className="mealMenuDiv">
                {todaysMenuDinner.length > 0 ? (
                  <img
                    src={editIco}
                    className="mealEditIco"
                    onClick={() =>
                      handleClickOpenWeeklySetup("body", "Dinner", 2)
                    }
                  />
                ) : (
                  <></>
                )}
                <div className="mealTop">
                  <div className="mealWithImage">
                    <div className="mealImgTop">
                      <img src={mealImg3} />
                    </div>
                    <h1>Dinner Menu</h1>
                  </div>
                </div>
                <div className="mealMainImgDiv">
                  <div className="mealMainImg">
                    <img src={mealMainimg3} />
                  </div>
                </div>
                {todaysMenuDinner.length == 0 ? (
                  <div className="noMenuAddedMain">
                    <h1>No Menu Added</h1>
                    <div className="mealBtnBottomOuter">
                      <Button
                        onClick={() =>
                          handleClickOpenWeeklySetup("body", "Dinner", 2)
                        }
                      >
                        Setup Weekly Dinner Menu
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="mealContentDiv">
                    {todaysMenuDinner.map((menu, index) => (
                      <>
                        {!menu.category_type[0].includes("Special") ? (
                          <>
                            <p className="menuOutLine" key={index}>
                              <div className="todayMenuTx">
                                <b>{menu.menu_name}</b> Product ID #
                                {menu.menu_id}
                                {/* <div className="freeImgOuter">
                                  <img src={compTag} />
                                </div> */}
                              </div>
                              <span className="mealStar">
                                <StarIcon /> {menu.price_in_stars}
                              </span>
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                    <div className="addedSpecialDiv">
                      <h4 className="todaySpeHead">Todays Special</h4>

                      <div className="specialMenuListDiv">
                        {todaysMenuDinner.map((menu, index) => (
                          <>
                            {menu.category_type[0].includes("Special") ? (
                              <>
                                <div className="specialMenuList">
                                  <h3>{menu.menu_name}</h3>
                                  <p>Product ID #{menu.menu_id}</p>
                                  <div className="specialStarsDiv">
                                    <span className="mealStar">
                                      <StarIcon /> {menu.price_in_stars}
                                    </span>
                                  </div>

                                  {/* <p className="menuOutLine" key={index}>
                        <b>{menu.menu_name}</b> Product ID #{menu.menu_id}
                        <span className="mealStar">
                          <StarIcon /> {menu.price_in_stars}
                        </span>
                      </p> */}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* Add Menu */}
      <div>
        <Dialog
          open={addMenu}
          // onClose={handleCloseAddMenu}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            {isEditMenu ? <>Edit Menu Item</> : <>Add Menu Item</>}
            <CloseIcon className="modalCloseBt" onClick={handleCloseAddMenu} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Formik
                    initialValues={formValuesAddMenu || formInitials}
                    validationSchema={validationSchema}
                    handleChange
                    handleBlur
                    values
                    errors
                    onSubmit={addMenufunction}
                    enableReinitialize
                  >
                    {(formik) => (
                      <Form>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12}>
                            <div className="addRemoveImagesBox">
                              {menu_image ? (
                                <div className="imageAddedBox">
                                  <img src={menu_image} />
                                  <div className="cloIm">
                                    <CloseIcon onClick={() => deleteImg()} />
                                  </div>
                                </div>
                              ) : (
                                <div className="uploadImBox">
                                  <h1 className="">Upload Image</h1>
                                  <Button
                                    className="uploadImBt"
                                    component="label"
                                    variant="contained"
                                    startIcon={<CloudUploadIcon />}
                                  >
                                    Upload
                                    <VisuallyHiddenInput
                                      type="file"
                                      onChange={(event) =>
                                        uploadProfilePic(event)
                                      }
                                    />
                                  </Button>
                                  {/* <span>or drag file in here</span> */}
                                </div>
                              )}
                            </div>
                            <span
                              style={{
                                color: "red",
                                fontSize: 14,
                                alignContent: "center",
                              }}
                            >
                              {imageErrMsg}
                            </span>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Menu Name"
                              placeholder="Enter Menu Name"
                              labelClass="pageLabel"
                              name="menu_name"
                              isRequired="true"
                              className="prodSearchInpt"
                            />
                            <span className="errorSt">
                              {duplicateMenuErrMsg}
                            </span>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label className="pageLabel">
                              Price In Stars (*)
                            </label>
                            <input
                              type="text"
                              placeholder="Price In Stars"
                              value={price_in_stars}
                              className="prodSearchInpt"
                              onChange={(e) =>
                                handleChangeDollarAmount(e.target.value)
                              }
                            />
                            <span className="errorSt">{priceErrMsg}</span>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label className="pageLabel">
                              Enter Price in INR (₹)
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Price in INR (₹)"
                              value={price_in_dollars}
                              className="prodSearchInpt"
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label className="pageLabel">
                              Select meal type
                            </label>
                            <div className="unitDivMain">
                              <div className="detailsTableFilterSec">
                                <div className="mealTypeErrorMg">
                                  <ToggleButtonGroup
                                    value={alignment}
                                    exclusive
                                    onChange={handleAlignment}
                                    className=""
                                  >
                                    <ToggleButton
                                      value="Breakfast"
                                      className="filterBtn"
                                    >
                                      Breakfast
                                    </ToggleButton>
                                    <ToggleButton
                                      value="Lunch"
                                      className="filterBtn"
                                    >
                                      Lunch
                                    </ToggleButton>
                                    <ToggleButton
                                      value="Dinner"
                                      className="filterBtn"
                                    >
                                      Dinner
                                    </ToggleButton>
                                    {/* <ToggleButton
                                    value="Special"
                                    className="filterBtn"
                                  >
                                    Special
                                  </ToggleButton> */}
                                  </ToggleButtonGroup>
                                  <span className="errorSt">
                                    {alignmentErrMsg}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <label className="pageLabel">
                              Select meal type
                            </label>
                            <div className="lineCh">
                              <div className="mealMenuCheckbox">
                                {/* <Checkbox {...labelCheck} onChange={(e)=>handleChangeCheckbox(e.target.checked)}/>  Special */}
                                <Checkbox
                                  checked={checked}
                                  onChange={handleChangeCheckbox}
                                  inputProps={{ "aria-label": "controlled" }}
                                />{" "}
                                Special
                              </div>

                              <div className="mealMenuCheckbox">
                                {/* <Checkbox {...labelCheck} onChange={(e)=>handleChangeCheckbox(e.target.checked)}/>  Special */}
                                <Checkbox
                                  checked={isNonVeg}
                                  onChange={handleChangeNonVegCheckbox}
                                  inputProps={{ "aria-label": "controlled" }}
                                />{" "}
                                NonVeg
                              </div>
                            </div>
                          </Grid>

                          {showDiv ? (
                            <Grid item xs={12} md={12}>
                              <div className="complementoryMain">
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  value={radioValue}
                                  name="radio-buttons-group"
                                  onChange={(e) =>
                                    handleChangeRadioButton(e.target.value)
                                  }
                                >
                                  <FormControlLabel
                                    value="freefood"
                                    control={<Radio />}
                                    label="Free Food"
                                  />
                                  <FormControlLabel
                                    value="complementoryFood"
                                    control={<Radio />}
                                    label="Complementory Food"
                                  />
                                </RadioGroup>

                                {isfree ? (
                                  <>
                                    <input
                                      type="text"
                                      placeholder="Enter Free Food"
                                      labelClass="pageLabel"
                                      value={freecount}
                                      className="prodSearchInpt freeTagTextBox"
                                      onChange={(e) =>
                                        handleChangeCount(
                                          e.target.value,
                                          "free"
                                        )
                                      }
                                    />
                                    <span className="errorSt">
                                      {countErrMsg}
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {iscomplementory ? (
                                  <>
                                    <input
                                      type="text"
                                      placeholder="Enter Complementory Food"
                                      labelClass="pageLabel"
                                      value={complementorycount}
                                      onChange={(e) =>
                                        handleChangeCount(
                                          e.target.value,
                                          "complementory"
                                        )
                                      }
                                      className="prodSearchInpt freeTagTextBox"
                                    />
                                    <span className="errorSt">
                                      {countErrMsg}
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </Grid>
                          ) : (
                            <></>
                          )}

                          <Grid item xs={12} md={12} className="lineBtns">
                            {isEditMenu ? (
                              <Button
                                className="cancelEditBtn"
                                onClick={() => cancelEditMenu()}
                              >
                                <>Cancel</>
                              </Button>
                            ) : (
                              <></>
                            )}
                            <Button className="modalBtSmallWidth" type="submit">
                              {isEditMenu ? (
                                <>Update Menu Item</>
                              ) : (
                                <>Add Menu Item</>
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Weekly Setup Menu */}
      <div>
        <Dialog
          open={WeeklySetup}
          // onClose={() => handleCloseWeeklySetup()}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Setup Weekly Menu
            <CloseIcon
              className="modalCloseBt"
              onClick={() => handleCloseWeeklySetup()}
            />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="modalWeeklyTabsMain">
                        <Box sx={{ width: "100%" }}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={Weekly}
                              onChange={() => handleChangeWeekly()}
                              aria-label="basic tabs example"
                            >
                              <Tab
                                label="Breakfast"
                                // {...a11yPropsWeekly(0)}
                                onClick={() =>
                                  handleClickOpenWeeklySetup(
                                    "body",
                                    "Breakfast",
                                    0
                                  )
                                }
                              />
                              <Tab
                                label="Lunch Menu"
                                // {...a11yPropsWeekly(1)}
                                onClick={() =>
                                  handleClickOpenWeeklySetup("body", "Lunch", 1)
                                }
                              />
                              <Tab
                                label="Dinner Menu"
                                // {...a11yPropsWeekly(2)}
                                onClick={() =>
                                  handleClickOpenWeeklySetup(
                                    "body",
                                    "Dinner",
                                    2
                                  )
                                }
                              />
                            </Tabs>
                          </Box>

                          <CustomTabPanel value={Weekly} index={0}>
                            <div className="modalTabsContent checkColor1">
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <div className="settingInr weeklyAccordion">
                                    <div className="accordingMain">
                                      <Accordion
                                        expanded={expanded === "panel1"}
                                        onChange={handleChange(
                                          "panel1",
                                          "Breakfast"
                                        )}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                        >
                                          <h1 className="settheading">
                                            Free Menu Items{" "}
                                            <img src={freeTag} />
                                          </h1>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Typography>
                                            <div className="myNewSer">
                                              <input
                                                type="text"
                                                className="prodSearchInpt"
                                                placeholder="Search"
                                                id="search"
                                                onChange={(e) =>
                                                  getMenuListByType(
                                                    "Breakfast",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="productDetails groceryTableMain forTxCenterAlignTable">
                                              <table width="100%">
                                                <thead>
                                                  <tr>
                                                    <th>Item Names</th>
                                                    <th>Monday</th>
                                                    <th>Tuesday</th>
                                                    <th>Wednesday</th>
                                                    <th>Thursday</th>
                                                    <th>Friday</th>
                                                    <th>Saturday</th>
                                                    <th>Sunday</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {menuItemList ? (
                                                    <>
                                                      {menuItemList.map(
                                                        (option, index) => (
                                                          <>
                                                            {!option.category_type[0].includes(
                                                              "Special"
                                                            ) &&
                                                            option.status ==
                                                              true &&
                                                            option.isfree ==
                                                              true ? (
                                                              <tr>
                                                                <td className="itemNameTbl">
                                                                  <div className="todayMenuTx">
                                                                    {
                                                                      option.menu_name
                                                                    }
                                                                    <div className="freeImgOuter">
                                                                      {option.isfree ==
                                                                      true ? (
                                                                        <img
                                                                          src={
                                                                            freeTag
                                                                          }
                                                                        />
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                      {option.iscomplementory ? (
                                                                        <img
                                                                          src={
                                                                            compTag
                                                                          }
                                                                        />
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfMonday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfTuesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfWednesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfThursday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfFriday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSaturday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSunday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      For {selectedMenuType}{" "}
                                                      menu item not added yet,
                                                      please add menu item
                                                    </>
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </Typography>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        expanded={expanded === "panel2"}
                                        onChange={handleChange(
                                          "panel2",
                                          "Breakfast"
                                        )}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel2bh-content"
                                          id="panel2bh-header"
                                        >
                                          <h1 className="settheading">
                                            Regular Menu Items{" "}
                                            <LocalDiningIcon
                                              style={{ color: "#c82d33" }}
                                            />
                                          </h1>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Typography>
                                            <div className="myNewSer">
                                              <input
                                                type="text"
                                                id="search"
                                                className="prodSearchInpt"
                                                placeholder="Search"
                                                onChange={(e) =>
                                                  getMenuListByType(
                                                    "Breakfast",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="productDetails groceryTableMain forTxCenterAlignTable">
                                              <table width="100%">
                                                <thead>
                                                  <tr>
                                                    <th>Item Names</th>
                                                    <th>Monday</th>
                                                    <th>Tuesday</th>
                                                    <th>Wednesday</th>
                                                    <th>Thursday</th>
                                                    <th>Friday</th>
                                                    <th>Saturday</th>
                                                    <th>Sunday</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {menuItemList ? (
                                                    <>
                                                      {menuItemList.map(
                                                        (option, index) => (
                                                          <>
                                                            {!option.category_type[0].includes(
                                                              "Special"
                                                            ) &&
                                                            option.status ==
                                                              true &&
                                                            option.isfree ==
                                                              false ? (
                                                              <tr>
                                                                <td className="itemNameTbl">
                                                                  <div className="todayMenuTx">
                                                                    {
                                                                      option.menu_name
                                                                    }
                                                                    <div className="freeImgOuter">
                                                                      {option.isfree ==
                                                                      true ? (
                                                                        <img
                                                                          src={
                                                                            freeTag
                                                                          }
                                                                        />
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                      {option.iscomplementory ? (
                                                                        <img
                                                                          src={
                                                                            compTag
                                                                          }
                                                                        />
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfMonday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfTuesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfWednesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfThursday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfFriday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSaturday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSunday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      For {selectedMenuType}{" "}
                                                      menu item not added yet,
                                                      please add menu item
                                                    </>
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </Typography>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        expanded={expanded === "panel3"}
                                        onChange={handleChange(
                                          "panel3",
                                          "Breakfast"
                                        )}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel3bh-content"
                                          id="panel3bh-header"
                                        >
                                          <h1 className="settheading">
                                            Special Menu Items{" "}
                                            <DiningIcon
                                              style={{ color: "#d5570a" }}
                                            />
                                          </h1>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Typography>
                                            <div className="myNewSer">
                                              <input
                                                type="text"
                                                id="search"
                                                className="prodSearchInpt"
                                                placeholder="Search"
                                                onChange={(e) =>
                                                  getMenuListByType(
                                                    "Breakfast",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="productDetails groceryTableMain forTxCenterAlignTable">
                                              <table width="100%">
                                                <thead>
                                                  <tr>
                                                    <th>Item Names</th>
                                                    <th>Monday</th>
                                                    <th>Tuesday</th>
                                                    <th>Wednesday</th>
                                                    <th>Thursday</th>
                                                    <th>Friday</th>
                                                    <th>Saturday</th>
                                                    <th>Sunday</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {menuItemList ? (
                                                    <>
                                                      {menuItemList.map(
                                                        (option, index) => (
                                                          <>
                                                            {option.category_type[0].includes(
                                                              "Special"
                                                            ) &&
                                                            option.status ==
                                                              true ? (
                                                              <tr>
                                                                <td className="itemNameTbl">
                                                                  <div className="todayMenuTx">
                                                                    {
                                                                      option.menu_name
                                                                    }
                                                                    <div className="freeImgOuter">
                                                                      {option.isfree ==
                                                                      true ? (
                                                                        <img
                                                                          src={
                                                                            freeTag
                                                                          }
                                                                        />
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                      {option.iscomplementory ? (
                                                                        <img
                                                                          src={
                                                                            compTag
                                                                          }
                                                                        />
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfMonday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfTuesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfWednesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfThursday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfFriday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSaturday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSunday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Breakfast"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      For {selectedMenuType}{" "}
                                                      menu item not added yet,
                                                      please add menu item
                                                    </>
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </Typography>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  </div>

                                  {/* <h3 className="itemHeadingSt">
                                    Regular Menu Items
                                  </h3> */}
                                  {/* <DataTable columns={columns} data={data} /> */}
                                  {/* <div className="productDetails groceryTableMain forTxCenterAlignTable">
                                    <table width="100%">
                                      <thead>
                                        <tr>
                                          <th>Item Names</th>
                                          <th>Monday</th>
                                          <th>Tuesday</th>
                                          <th>Wednesday</th>
                                          <th>Thursday</th>
                                          <th>Friday</th>
                                          <th>Saturday</th>
                                          <th>Sunday</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {menuItemList ? (
                                          <>
                                            {menuItemList.map(
                                              (option, index) => (
                                                <>
                                                  {!option.category_type[0].includes(
                                                    "Special"
                                                  ) && option.status == true ? (
                                                    <tr>
                                                      <td className="itemNameTbl">
                                                        <div className="todayMenuTx">
                                                          {option.menu_name}
                                                          <div className="freeImgOuter">
                                                            {option.isfree ==
                                                            true ? (
                                                              <img
                                                                src={freeTag}
                                                              />
                                                            ) : (
                                                              <></>
                                                            )}
                                                            {option.iscomplementory ? (
                                                              <img
                                                                src={compTag}
                                                              />
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfMonday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`mon` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Monday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`mon` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Monday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfTuesday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`tues` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Tuesday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`tues` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Tuesday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfWednesday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`wed` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Wednesday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`wed` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Wednesday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfThursday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`thur` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Thursday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`thur` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Thursday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfFriday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`fri` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Friday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`fri` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Friday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfSaturday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`sat` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Saturday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`sat` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Saturday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfSunday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={
                                                              `sunday` + index
                                                            }
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Sunday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={
                                                              `sunday` + index
                                                            }
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Sunday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>
                                                    </tr>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              )
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            For {selectedMenuType} menu item not
                                            added yet, please add menu item
                                          </>
                                        )}
                                      </tbody>
                                    </table>
                                  </div> */}
                                </Grid>

                                {/* <Grid item xs={12} md={12}>
                                  <h3 className="itemHeadingSt">
                                    Special Menu Items
                                  </h3>
                                  <DataTable columns={columns} data={data} />
                                  <div className="productDetails groceryTableMain forTxCenterAlignTable">
                                    <table width="100%">
                                      <thead>
                                        <tr>
                                          <th>Item Names</th>
                                          <th>Monday</th>
                                          <th>Tuesday</th>
                                          <th>Wednesday</th>
                                          <th>Thursday</th>
                                          <th>Friday</th>
                                          <th>Saturday</th>
                                          <th>Sunday</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {menuItemList ? (
                                          <>
                                            {menuItemList.map(
                                              (option, index) => (
                                                <>
                                                  {option.category_type[0].includes(
                                                    "Special"
                                                  ) && option.status == true ? (
                                                    <tr>
                                                      <td className="itemNameTbl">
                                                        <div className="todayMenuTx">
                                                          {option.menu_name}
                                                          <div className="freeImgOuter">
                                                            {option.isfree ==
                                                            true ? (
                                                              <img
                                                                src={freeTag}
                                                              />
                                                            ) : (
                                                              <></>
                                                            )}
                                                            {option.iscomplementory ? (
                                                              <img
                                                                src={compTag}
                                                              />
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfMonday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`mon` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Monday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`mon` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Monday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfTuesday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`tues` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Tuesday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`tues` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Tuesday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfWednesday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`wed` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Wednesday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`wed` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Wednesday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfThursday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`thur` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Thursday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`thur` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Thursday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfFriday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`fri` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Friday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`fri` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Friday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfSaturday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`sat` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Saturday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`sat` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Saturday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfSunday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={
                                                              `sunday` + index
                                                            }
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Sunday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={
                                                              `sunday` + index
                                                            }
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Sunday",
                                                                option.menu_id,
                                                                "Breakfast"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>
                                                    </tr>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              )
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            For {selectedMenuType} menu item not
                                            added yet, please add menu item
                                          </>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </Grid> */}
                                {/* <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  style={{ textAlign: "center" }}
                                >
                                  <Button className="modalSmallBtSt">
                                    Save Items
                                  </Button>
                                </Grid> */}

                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  style={{ textAlign: "center" }}
                                >
                                  <Button
                                    className="modalSmallBtSt"
                                    onClick={() => saveMealMenuList()}
                                  >
                                    Save Items
                                  </Button>
                                </Grid>
                              </Grid>
                            </div>
                          </CustomTabPanel>

                          <CustomTabPanel value={Weekly} index={1}>
                            <div className="modalTabsContent checkColor2">
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <div className="settingInr weeklyAccordion">
                                    <div className="accordingMain">
                                      {/* <Accordion
                                        expanded={expanded === "panel1"}
                                        onChange={handleChange("panel1")}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                        >
                                          <h1 className="settheading">
                                            Free Menu Items{" "}
                                            <img src={freeTag} />
                                          </h1>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Typography>
                                            <div className="myNewSer">
                                              <input
                                                type="text"
                                                className="prodSearchInpt"
                                                placeholder="Search"
                                              />
                                            </div>
                                            <div className="productDetails groceryTableMain forTxCenterAlignTable">
                                              <table width="100%">
                                                <thead>
                                                  <tr>
                                                    <th>Item Names</th>
                                                    <th>Monday</th>
                                                    <th>Tuesday</th>
                                                    <th>Wednesday</th>
                                                    <th>Thursday</th>
                                                    <th>Friday</th>
                                                    <th>Saturday</th>
                                                    <th>Sunday</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {menuItemList ? (
                                                    <>
                                                      {menuItemList.map(
                                                        (option, index) => (
                                                          <>
                                                            {!option.category_type[0].includes(
                                                              "Special"
                                                            ) &&
                                                            option.status == true && option.isfree==true? (
                                                              <tr>
                                                                <td className="itemNameTbl">
                                                                  <div className="todayMenuTx">
                                                                    {
                                                                      option.menu_name
                                                                    }
                                                                  
                                                                  </div>
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfMonday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfTuesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfWednesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfThursday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfFriday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSaturday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSunday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      For {selectedMenuType}{" "}
                                                      menu item not added yet,
                                                      please add menu item
                                                    </>
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </Typography>
                                        </AccordionDetails>
                                      </Accordion> */}
                                      <Accordion
                                        expanded={expanded === "panel2"}
                                        onChange={handleChange(
                                          "panel2",
                                          "Lunch"
                                        )}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel2bh-content"
                                          id="panel2bh-header"
                                        >
                                          <h1 className="settheading">
                                            Regular Menu Items{" "}
                                            <LocalDiningIcon
                                              style={{ color: "#c82d33" }}
                                            />
                                          </h1>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Typography>
                                            <div className="myNewSer">
                                              <input
                                                type="text"
                                                className="prodSearchInpt"
                                                placeholder="Search"
                                                id="search"
                                                onChange={(e) =>
                                                  getMenuListByType(
                                                    "Lunch",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="productDetails groceryTableMain forTxCenterAlignTable">
                                              <table width="100%">
                                                <thead>
                                                  <tr>
                                                    <th>Item Names</th>
                                                    <th>Monday</th>
                                                    <th>Tuesday</th>
                                                    <th>Wednesday</th>
                                                    <th>Thursday</th>
                                                    <th>Friday</th>
                                                    <th>Saturday</th>
                                                    <th>Sunday</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {menuItemList ? (
                                                    <>
                                                      {menuItemList.map(
                                                        (option, index) => (
                                                          <>
                                                            {!option.category_type[0].includes(
                                                              "Special"
                                                            ) &&
                                                            option.status ==
                                                              true &&
                                                            option.isfree ==
                                                              false ? (
                                                              <tr>
                                                                <td className="itemNameTbl">
                                                                  <div className="todayMenuTx">
                                                                    {
                                                                      option.menu_name
                                                                    }
                                                                  </div>
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfMonday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfTuesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfWednesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfThursday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfFriday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSaturday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSunday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      For {selectedMenuType}{" "}
                                                      menu item not added yet,
                                                      please add menu item
                                                    </>
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </Typography>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        expanded={expanded === "panel3"}
                                        onChange={handleChange(
                                          "panel3",
                                          "Lunch"
                                        )}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel3bh-content"
                                          id="panel3bh-header"
                                        >
                                          <h1 className="settheading">
                                            Special Menu Items{" "}
                                            <DiningIcon
                                              style={{ color: "#d5570a" }}
                                            />
                                          </h1>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Typography>
                                            <div className="myNewSer">
                                              <input
                                                type="text"
                                                className="prodSearchInpt"
                                                placeholder="Search"
                                                id="search"
                                                onChange={(e) =>
                                                  getMenuListByType(
                                                    "Lunch",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="productDetails groceryTableMain forTxCenterAlignTable">
                                              <table width="100%">
                                                <thead>
                                                  <tr>
                                                    <th>Item Names</th>
                                                    <th>Monday</th>
                                                    <th>Tuesday</th>
                                                    <th>Wednesday</th>
                                                    <th>Thursday</th>
                                                    <th>Friday</th>
                                                    <th>Saturday</th>
                                                    <th>Sunday</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {menuItemList ? (
                                                    <>
                                                      {menuItemList.map(
                                                        (option, index) => (
                                                          <>
                                                            {option.category_type[0].includes(
                                                              "Special"
                                                            ) &&
                                                            option.status ==
                                                              true ? (
                                                              <tr>
                                                                <td className="itemNameTbl">
                                                                  {
                                                                    option.menu_name
                                                                  }
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfMonday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfTuesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfWednesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfThursday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfFriday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSaturday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSunday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Lunch"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      For {selectedMenuType}{" "}
                                                      menu item not added yet,
                                                      please add menu item
                                                    </>
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </Typography>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  </div>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  style={{ textAlign: "center" }}
                                >
                                  <Button
                                    className="modalSmallBtSt"
                                    onClick={() => saveMealMenuList()}
                                  >
                                    Save Items
                                  </Button>
                                </Grid>
                              </Grid>
                            </div>
                          </CustomTabPanel>

                          <CustomTabPanel value={Weekly} index={2}>
                            <div className="modalTabsContent checkColor3">
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <div className="settingInr weeklyAccordion">
                                    <div className="accordingMain">
                                      {/* <Accordion
                                        expanded={expanded === "panel1"}
                                        onChange={handleChange("panel1")}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                        >
                                          <h1 className="settheading">
                                            Free Menu Items{" "}
                                            <img src={freeTag} />
                                          </h1>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Typography>
                                            <div className="myNewSer">
                                              <input
                                                type="text"
                                                className="prodSearchInpt"
                                                placeholder="Search"
                                              />
                                            </div>
                                            <div className="productDetails groceryTableMain forTxCenterAlignTable">
                                              <table width="100%">
                                                <thead>
                                                  <tr>
                                                    <th>Item Names</th>
                                                    <th>Monday</th>
                                                    <th>Tuesday</th>
                                                    <th>Wednesday</th>
                                                    <th>Thursday</th>
                                                    <th>Friday</th>
                                                    <th>Saturday</th>
                                                    <th>Sunday</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {menuItemList ? (
                                                    <>
                                                      {menuItemList.map(
                                                        (option, index) => (
                                                          <>
                                                            {!option.category_type[0].includes(
                                                              "Special"
                                                            ) &&
                                                            option.status ==
                                                              true ? (
                                                              <tr>
                                                                <td className="itemNameTbl">
                                                                  <div className="todayMenuTx">
                                                                    {
                                                                      option.menu_name
                                                                    }
                                                                    
                                                                  </div>
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfMonday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfTuesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfWednesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfThursday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfFriday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSaturday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSunday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      For {selectedMenuType}{" "}
                                                      menu item not added yet,
                                                      please add menu item
                                                    </>
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </Typography>
                                        </AccordionDetails>
                                      </Accordion> */}
                                      <Accordion
                                        expanded={expanded === "panel2"}
                                        onChange={handleChange(
                                          "panel2",
                                          "Dinner"
                                        )}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel2bh-content"
                                          id="panel2bh-header"
                                        >
                                          <h1 className="settheading">
                                            Regular Menu Items{" "}
                                            <LocalDiningIcon
                                              style={{ color: "#c82d33" }}
                                            />
                                          </h1>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Typography>
                                            <div className="myNewSer">
                                              <input
                                                type="text"
                                                className="prodSearchInpt"
                                                placeholder="Search"
                                                id="search"
                                                onChange={(e) =>
                                                  getMenuListByType(
                                                    "Dinner",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="productDetails groceryTableMain forTxCenterAlignTable">
                                              <table width="100%">
                                                <thead>
                                                  <tr>
                                                    <th>Item Names</th>
                                                    <th>Monday</th>
                                                    <th>Tuesday</th>
                                                    <th>Wednesday</th>
                                                    <th>Thursday</th>
                                                    <th>Friday</th>
                                                    <th>Saturday</th>
                                                    <th>Sunday</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {menuItemList ? (
                                                    <>
                                                      {menuItemList.map(
                                                        (option, index) => (
                                                          <>
                                                            {!option.category_type[0].includes(
                                                              "Special"
                                                            ) &&
                                                            option.status ==
                                                              true ? (
                                                              <tr>
                                                                <td className="itemNameTbl">
                                                                  <div className="todayMenuTx">
                                                                    {
                                                                      option.menu_name
                                                                    }
                                                                    {/* <div className="freeImgOuter">
                                                            <img
                                                              src={freeTag}
                                                            />
                                                          </div> */}
                                                                  </div>
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfMonday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfTuesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfWednesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfThursday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfFriday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSaturday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSunday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      For {selectedMenuType}{" "}
                                                      menu item not added yet,
                                                      please add menu item
                                                    </>
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </Typography>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        expanded={expanded === "panel3"}
                                        onChange={handleChange(
                                          "panel3",
                                          "Dinner"
                                        )}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel3bh-content"
                                          id="panel3bh-header"
                                        >
                                          <h1 className="settheading">
                                            Special Menu Items{" "}
                                            <DiningIcon
                                              style={{ color: "#d5570a" }}
                                            />
                                          </h1>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Typography>
                                            <div className="myNewSer">
                                              <input
                                                type="text"
                                                className="prodSearchInpt"
                                                placeholder="Search"
                                                id="search"
                                                onChange={(e) =>
                                                  getMenuListByType(
                                                    "Dinner",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="productDetails groceryTableMain forTxCenterAlignTable">
                                              <table width="100%">
                                                <thead>
                                                  <tr>
                                                    <th>Item Names</th>
                                                    <th>Monday</th>
                                                    <th>Tuesday</th>
                                                    <th>Wednesday</th>
                                                    <th>Thursday</th>
                                                    <th>Friday</th>
                                                    <th>Saturday</th>
                                                    <th>Sunday</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {menuItemList ? (
                                                    <>
                                                      {menuItemList.map(
                                                        (option, index) => (
                                                          <>
                                                            {option.category_type[0].includes(
                                                              "Special"
                                                            ) &&
                                                            option.status ==
                                                              true ? (
                                                              <tr>
                                                                <td className="itemNameTbl">
                                                                  {
                                                                    option.menu_name
                                                                  }
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfMonday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `mon` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Monday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfTuesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `tues` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Tuesday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfWednesday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `wed` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Wednesday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfThursday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `thur` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Thursday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfFriday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `fri` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Friday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSaturday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sat` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Saturday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>

                                                                <td>
                                                                  {selectedMenuOfSunday.menu_item.includes(
                                                                    option.menu_id
                                                                  ) ? (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      checked
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <Checkbox
                                                                      {...labelCheck}
                                                                      id={
                                                                        `sunday` +
                                                                        index
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeWeekDayCheckBox(
                                                                          e
                                                                            .target
                                                                            .checked,
                                                                          index,
                                                                          "Sunday",
                                                                          option.menu_id,
                                                                          "Dinner"
                                                                        )
                                                                      }
                                                                    />
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      For {selectedMenuType}{" "}
                                                      menu item not added yet,
                                                      please add menu item
                                                    </>
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </Typography>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  </div>

                                  {/* <h3 className="itemHeadingSt">
                                    Regular Menu Items
                                  </h3>
                                  <DataTable columns={columns} data={data} />
                                  <div className="productDetails groceryTableMain forTxCenterAlignTable">
                                    <table width="100%">
                                      <thead>
                                        <tr>
                                          <th>Item Names</th>
                                          <th>Monday</th>
                                          <th>Tuesday</th>
                                          <th>Wednesday</th>
                                          <th>Thursday</th>
                                          <th>Friday</th>
                                          <th>Saturday</th>
                                          <th>Sunday</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {menuItemList ? (
                                          <>
                                            {menuItemList.map(
                                              (option, index) => (
                                                <>
                                                  {!option.category_type[0].includes(
                                                    "Special"
                                                  ) && option.status == true ? (
                                                    <tr>
                                                      <td className="itemNameTbl">
                                                        <div className="todayMenuTx">
                                                          {option.menu_name}
                                                          <div className="freeImgOuter">
                                                            <img
                                                              src={freeTag}
                                                            />
                                                          </div>
                                                        </div>
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfMonday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`mon` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Monday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`mon` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Monday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfTuesday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`tues` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Tuesday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`tues` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Tuesday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfWednesday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`wed` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Wednesday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`wed` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Wednesday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfThursday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`thur` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Thursday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`thur` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Thursday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfFriday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`fri` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Friday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`fri` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Friday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfSaturday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`sat` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Saturday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`sat` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Saturday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfSunday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={
                                                              `sunday` + index
                                                            }
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Sunday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={
                                                              `sunday` + index
                                                            }
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Sunday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>
                                                    </tr>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              )
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            For {selectedMenuType} menu item not
                                            added yet, please add menu item
                                          </>
                                        )}
                                      </tbody>
                                    </table>
                                  </div> */}
                                </Grid>

                                {/* <Grid item xs={12} md={12}>
                                  <h3 className="itemHeadingSt">
                                    Special Menu Items
                                  </h3>
                                  <DataTable columns={columns} data={data} />
                                  <div className="productDetails groceryTableMain forTxCenterAlignTable">
                                    <table width="100%">
                                      <thead>
                                        <tr>
                                          <th>Item Names</th>
                                          <th>Monday</th>
                                          <th>Tuesday</th>
                                          <th>Wednesday</th>
                                          <th>Thursday</th>
                                          <th>Friday</th>
                                          <th>Saturday</th>
                                          <th>Sunday</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {menuItemList ? (
                                          <>
                                            {menuItemList.map(
                                              (option, index) => (
                                                <>
                                                  {option.category_type[0].includes(
                                                    "Special"
                                                  ) && option.status == true ? (
                                                    <tr>
                                                      <td className="itemNameTbl">
                                                        {option.menu_name}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfMonday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`mon` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Monday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`mon` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Monday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfTuesday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`tues` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Tuesday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`tues` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Tuesday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfWednesday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`wed` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Wednesday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`wed` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Wednesday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfThursday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`thur` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Thursday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`thur` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Thursday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfFriday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`fri` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Friday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`fri` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Friday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfSaturday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`sat` + index}
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Saturday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={`sat` + index}
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Saturday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>

                                                      <td>
                                                        {selectedMenuOfSunday.menu_item.includes(
                                                          option.menu_id
                                                        ) ? (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={
                                                              `sunday` + index
                                                            }
                                                            checked
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Sunday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            {...labelCheck}
                                                            id={
                                                              `sunday` + index
                                                            }
                                                            onChange={(e) =>
                                                              handleChangeWeekDayCheckBox(
                                                                e.target
                                                                  .checked,
                                                                index,
                                                                "Sunday",
                                                                option.menu_id,
                                                                "Dinner"
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>
                                                    </tr>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              )
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            For {selectedMenuType} menu item not
                                            added yet, please add menu item
                                          </>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </Grid> */}
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  style={{ textAlign: "center" }}
                                >
                                  <Button
                                    className="modalSmallBtSt"
                                    onClick={() => saveMealMenuList()}
                                  >
                                    Save Items
                                  </Button>
                                </Grid>
                              </Grid>
                            </div>
                          </CustomTabPanel>
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default CreateMealMenu;
