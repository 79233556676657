import React, { useState, useEffect, useRef } from "react";
import StarIcon from "@mui/icons-material/Star";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { PieChart } from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts/LineChart";
import InsertPageBreakIcon from "@mui/icons-material/InsertPageBreak";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import avtarPic from "../../../assets/images/Profile-Picture.jpg";
import avtarPic2 from "../../../assets/images/profile.jpg";
import avtarPic3 from "../../../assets/images/avtarPic.png";
import Tooltip from "@mui/material/Tooltip";
import deletIco from "../../../assets/images/delete-icon.svg";
import eye from "../../../assets/images/eye.svg";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
// End

import AOS from "aos";
import "aos/dist/aos.css";

// Pie Graph
const data = [
  { id: 0, value: 40, label: "Spend In Star Cafe" },
  { id: 1, value: 60, label: "Spend In Star Market" },
];
// End

function EmployeesReportTab() {
  useEffect(() => {
    AOS.init();
  }, []);

  // OpenGr
  const [OpenGr, setOpenOpenGr] = React.useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleClickOpenGr = (scrollType) => () => {
    setOpenOpenGr(true);
    setScroll(scrollType);
  };

  const closeOpenGr = () => {
    setOpenOpenGr(false);
  };

  const descriptionElementRef = useRef(null);
  // End

  return (
    <>
      <div className="starCafeTabInner emploRepoTab">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="repoFilterMain">
              <Button
                className="repoFilterBt activeRepoFilterBt"
                data-aos="fade-down"
                data-aos-delay="100"
                data-aos-anchor-placement="center-bottom"
              >
                Hourly
              </Button>
              <Button
                className="repoFilterBt"
                data-aos="fade-down"
                data-aos-delay="200"
                data-aos-anchor-placement="center-bottom"
              >
                Daily
              </Button>
              <Button
                className="repoFilterBt"
                data-aos="fade-down"
                data-aos-delay="300"
                data-aos-anchor-placement="center-bottom"
              >
                Week Day
              </Button>
              <Button
                className="repoFilterBt"
                data-aos="fade-down"
                data-aos-delay="400"
                data-aos-anchor-placement="center-bottom"
              >
                Weekly
              </Button>
              <Button
                className="repoFilterBt"
                data-aos="fade-down"
                data-aos-delay="500"
                data-aos-anchor-placement="center-bottom"
              >
                Monthly
              </Button>
              <Button
                className="repoFilterBt"
                data-aos="fade-down"
                data-aos-delay="600"
                data-aos-anchor-placement="center-bottom"
              >
                Yearly
              </Button>
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div className="selectDateRangeMain">
              <div className="selectDateRangeInr">
                <div>
                  <label>Select Date Range</label>
                </div>
                <div className="dateRangReport">
                  <div>
                    <input type="Date" className="prodSearchInpt" />
                  </div>
                  <b>To</b>
                  <div>
                    <input type="Date" className="prodSearchInpt" />
                  </div>
                </div>
                <div>
                  <Button className="repoFilterBt updateDataB">
                    Update Data
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={12} md={12}>
                      <div
                        className="settingDevider"
                        style={{ margin: 0 }}
                      ></div>
                    </Grid> */}
          <Grid item xs={12} md={12}>
            <h1 className="repoSubHeading">Additional Filters</h1>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className="additionalFiltterMain">
              <select className="prodSearchInpt">
                <option>All Department</option>
                <option>HR Department</option>
                <option>Software Department</option>
              </select>

              {/* <select className="prodSearchInpt">
                <option>All Service</option>
                <option>Star Market</option>
                <option>Star Cafe</option>
                <option>Barber Shop</option>
                <option>Sport</option>
              </select> */}

              {/* <select className="prodSearchInpt">
                <option>Order Status</option>
                <option>New</option>
                <option>preparing</option>
                <option>Ready For Pickup</option>
              </select> */}
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div className="reportChartsMain">
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <div className="repoChartContDiv">
                    <div
                      className="repoChartCont chartRepoBlack"
                      data-aos="fade-down"
                      data-aos-delay="300"
                      data-aos-anchor-placement="center-bottom"
                    >
                      <b>2500</b>
                      <p>Total Employees</p>
                    </div>
                    <div
                      className="repoChartCont chartRepoGreen"
                      data-aos="fade-down"
                      data-aos-delay="500"
                      data-aos-anchor-placement="center-bottom"
                    >
                      <b>
                        {/* <StarIcon />  */}
                        86
                      </b>
                      <p>On Leaves</p>
                    </div>
                    <div
                      className="repoChartCont chartRepoBlue"
                      data-aos="fade-down"
                      data-aos-delay="700"
                      data-aos-anchor-placement="center-bottom"
                    >
                      <b>86</b>
                      <p>Warning Issued</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={10}>
                  <div
                    className="departChartMain"
                    data-aos="fade-down"
                    data-aos-delay="300"
                    data-aos-anchor-placement="center-bottom"
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <h1 className="orderGrapHeading">Leaves Graph</h1>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="lineRepoChart">
                          <LineChart
                            xAxis={[
                              {
                                data: [1, 2, 3, 5, 8, 10, 12, 15, 16],
                              },
                            ]}
                            series={[
                              {
                                data: [2, 5.5, 2, 8.5, 1.5, 5],
                                valueFormatter: (value) =>
                                  value == null ? "NaN" : value.toString(),
                              },
                              {
                                data: [
                                  null,
                                  null,
                                  null,
                                  null,
                                  5.5,
                                  2,
                                  8.5,
                                  1.5,
                                  5,
                                ],
                              },
                              {
                                data: [7, 8, 5, 4, null, null, 2, 5.5, 1],
                                valueFormatter: (value) =>
                                  value == null ? "?" : value.toString(),
                              },
                            ]}
                            height={249}
                            margin={{ top: 10, bottom: 20 }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div className="reportSearchMain">
              <input
                type="text"
                className="prodSearchInpt"
                placeholder="Search"
              />
              <div className="hoverForDrop">
                <Button className="repoBtn">
                  <InsertPageBreakIcon /> Export Report
                </Button>
                <div className="btDropHover">
                  <p>Export as .xslx</p>
                  <p>Export as .doc</p>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div
              className="productDetails groceryTableMain"
              data-aos="fade-down"
              data-aos-delay="100"
              data-aos-anchor-placement="center-bottom"
            >
              <table width="100%">
                <thead>
                  <tr>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Emp Code
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>

                    <th>
                      <div className="smalShortingOuter finaShort">
                        Employee Name
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>

                    <th>
                      <div className="smalShortingOuter finaShort">
                        Department
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>

                    <th>
                      <div className="smalShortingOuter finaShort">
                        Reporting To
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>

                    <th>
                      <div className="smalShortingOuter finaShort">
                        Star Allowance
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>

                    <th>
                      <div className="smalShortingOuter finaShort">
                        Balanced Stars
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>

                    <th>
                      <div className="smalShortingOuter finaShort">
                        Leave Status
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>

                    <th>
                      <div className="smalShortingOuter finaShort">
                        Actions
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>E 1001</td>
                    <td>
                      <div
                        className="hovContTxt forEmployeePreview"
                        style={{ color: "#C82D33", fontWeight: 600 }}
                      >
                        Theresa Webb
                        <div className="hovContDiv">
                          <div className="emploRepoDetMain">
                            <div className="emploRepoDetPic">
                              <img src={avtarPic} />
                            </div>
                            <div className="emploRepoDetTx">
                              <h1>Theresa Webb</h1>
                              <p>theresaa.web@gmail.com</p>
                              <p>Sr Manager</p>
                              <p>Management Department</p>
                            </div>
                            <div className="reportingToEmpl">
                              <h1>E 1021</h1>
                              <p>
                                Reporting To :<b> Snehal Amodkar</b>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>Adminstration</td>
                    <td>John Doe</td>
                    <td>500</td>
                    <td>500</td>
                    <td>-</td>
                    <td>
                      <div className="actionBtnOut">
                        <Tooltip
                          title="View Report"
                          arrow
                          placement="top"
                          onClick={handleClickOpenGr("body")}
                        >
                          <IconButton>
                            <img src={eye} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>E 1002</td>
                    <td>
                      <div
                        className="hovContTxt forEmployeePreview"
                        style={{ color: "#C82D33", fontWeight: 600 }}
                      >
                        Arlene McCoy
                        <div className="hovContDiv">
                          <div className="emploRepoDetMain">
                            <div className="emploRepoDetPic">
                              <img src={avtarPic2} />
                            </div>
                            <div className="emploRepoDetTx">
                              <h1>Arlene McCoy</h1>
                              <p>theresaa.web@gmail.com</p>
                              <p>Sr Manager</p>
                              <p>Management Department</p>
                            </div>
                            <div className="reportingToEmpl">
                              <h1>E 1021</h1>
                              <p>
                                Reporting To :<b> Snehal Amodkar</b>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>Adminstration</td>
                    <td>John Doe</td>
                    <td>500</td>
                    <td>500</td>
                    <td>
                      <div style={{ color: "#C82D33" }}>On Leave</div>
                    </td>
                    <td>
                      <div className="actionBtnOut">
                        <Tooltip
                          title="View Report"
                          arrow
                          placement="top"
                          onClick={handleClickOpenGr("body")}
                        >
                          <IconButton>
                            <img src={eye} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>E 1001</td>
                    <td>
                      <div
                        className="hovContTxt forEmployeePreview"
                        style={{ color: "#C82D33", fontWeight: 600 }}
                      >
                        Theresa Webb
                        <div className="hovContDiv">
                          <div className="emploRepoDetMain">
                            <div className="emploRepoDetPic">
                              <img src={avtarPic3} />
                            </div>
                            <div className="emploRepoDetTx">
                              <h1>Theresa Webb</h1>
                              <p>theresaa.web@gmail.com</p>
                              <p>Sr Manager</p>
                              <p>Management Department</p>
                            </div>
                            <div className="reportingToEmpl">
                              <h1>E 1021</h1>
                              <p>
                                Reporting To :<b>Snehal Amodkar</b>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>Adminstration</td>
                    <td>John Doe</td>
                    <td>500</td>
                    <td>500</td>
                    <td>-</td>
                    <td>
                      <div className="actionBtnOut">
                        <Tooltip
                          title="View Report"
                          arrow
                          placement="top"
                          onClick={handleClickOpenGr("body")}
                        >
                          <IconButton>
                            <img src={eye} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Grid>

          <Grid item xs={12} md={12} className="paginationOuterMain">
            <Pagination count={10} variant="outlined" shape="rounded" />
          </Grid>
        </Grid>
      </div>

      <div>
        <Dialog
          open={OpenGr}
          // onClose={closeDeductProdct}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Report
            <CloseIcon className="modalCloseBt" onClick={closeOpenGr} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="hovContDiv">
                        {/* <CloseIcon
                            className="hovClos"
                            
                          /> */}
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <h1 className="orderGrapHeading forFle">
                              Stars Spendings <b>Theresa Webb</b>
                            </h1>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <div className="hovDivRangDat">
                              <div class="dateRangReport">
                                <div>
                                  <input type="Date" class="prodSearchInpt" />
                                </div>
                                <b>To</b>
                                <div>
                                  <input type="Date" class="prodSearchInpt" />
                                </div>
                              </div>
                              <div>
                                <select className="forGrapfDropDown">
                                  <option>Daily</option>
                                  <option>Weekly</option>
                                  <option>Monthly</option>
                                  <option>Yearly</option>
                                </select>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <LineChart
                              xAxis={[
                                { data: [1, 2, 3, 5, 8, 10, 12, 15, 16] },
                              ]}
                              series={[
                                {
                                  data: [2, 5.5, 2, 8.5, 1.5, 5],
                                  valueFormatter: (value) =>
                                    value == null ? "NaN" : value.toString(),
                                },
                                {
                                  data: [
                                    null,
                                    null,
                                    null,
                                    null,
                                    5.5,
                                    2,
                                    8.5,
                                    1.5,
                                    5,
                                  ],
                                },
                                {
                                  data: [7, 8, 5, 4, null, null, 2, 5.5, 1],
                                  valueFormatter: (value) =>
                                    value == null ? "?" : value.toString(),
                                },
                              ]}
                              height={200}
                              margin={{ top: 10, bottom: 20 }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default EmployeesReportTab;
