import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

// import "./styles.css";

const imageData = [
  {
    label: "Image 1",
    alt: "image1",
    url: require("../../../assets/images/productIm1.png"),
  },
  {
    label: "Image 2",
    alt: "image2",
    url: require("../../../assets/images/productIm1.png"),
  },
  {
    label: "Image 3",
    alt: "image3",
    url: require("../../../assets/images/productIm1.png"),
  },
  {
    label: "Image 4",
    alt: "image4",
    url: require("../../../assets/images/productIm1.png"),
  },
];

const renderSlides = imageData.map((image) => (
  <div key={image.alt}>
    <img src={image.url} alt={image.alt} />
    <p className="legend">{image.label}</p>
  </div>
));

function ProductCarousel() {
  const [currentIndex, setCurrentIndex] = useState();
  function handleChange(index) {
    setCurrentIndex(index);
  }

  return (
    <div className="App">
      <Carousel
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}
        selectedItem={imageData[currentIndex]}
        onChange={handleChange}
        className="carousel-container"
      >
        {renderSlides}
      </Carousel>
    </div>
  );
}

export default ProductCarousel;
