import React, { useEffect } from "react";
import cup from "../../assets/images/cup.svg";
import ordernotification from "../../assets/images/ordernotification.svg";
import rightArrow from "../../assets/images/rightArrow.svg";
import mealMenu from "../../assets/images/mealMenu.svg";
import lunch from "../../assets/images/lunch.svg";
import dinner from "../../assets/images/dinner.svg";
import { Button, Grid } from "@mui/material";
import starMarket from "../../assets/images/starMarket.svg";
import starCafe from "../../assets/images/starCafe.svg";
import barberShop from "../../assets/images/barberShop.svg";
import Sports from "../../assets/images/Sports.svg";
import warning from "../../assets/images/warning-.svg";
import bills from "../../assets/images/bills.svg";
import BottomTabsPage from "../../../MobileScreens/componentsMobile/BottomTabsPage/BottomTabsPage";
import { useNavigate } from "react-router-dom";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import MobileHeader from "../../componentsMobile/MobileHeader/MobileHeader";
import moment from "moment";
import ArticleIcon from '@mui/icons-material/Article';
import IconButton from '@mui/material/IconButton';

function DashboardPage() {

  const navigate = useNavigate();
  const [name, setName] = React.useState("");
  const [star, setStar] = React.useState(0);
  const [cafeTiming, setCafeTiming] = React.useState([]);
  const [orderList, setOrderList] = React.useState([]);
  const [isOrdered, setIsOrdered] = React.useState(false);
  const [isOrderedlun, setIsOrderedlun] = React.useState(false);
  const [isOrdereddin, setIsOrdereddin] = React.useState(false);
  const [isBetweenPickupBrk, setIsBetweenPickupBrk] = React.useState(false);
  const [isBetweenPickupLun, setIsBetweenPickupLun] = React.useState(false);
  const [isBetweenPickupDinn, setIsBetweenPickupDinn] = React.useState(false);

  const [BreakfastCafetime, setBreakfastCafetime] = React.useState("");
  const [Lunchtime, setLunchtime] = React.useState("");
  const [dinnertime, setDinnertime] = React.useState("");


  const date = new Date();

  const currentHour = date.getHours();
  const isBetween3And9PM = currentHour >= 15 && currentHour < 21;

  async function getCafeTiming() {
    console.log("getCafeTiming")
    await makeGetRequest("starcafe/getcafetiming")
      .then((response) => {
        if (response.data) {
          if (response.data) {
            setCafeTiming(response.data.data);
            checkServiceTimeFlags(response.data.data);
            localStorage.setItem(
              "cafetiming",
              JSON.stringify(response.data.data)
            );
          } else {
            setCafeTiming([]);
          }
        }
      })
      .catch((err) => {
        setCafeTiming([]);
      });
  }

  function checkServiceTimeFlags(apiResponse) {

    // Get current time
    const currentTime = new Date();
    const formattedTime = currentTime.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });

    // Loop through the array to check the conditions
    for (const service of apiResponse) {

      const { service_type, type, starttime, endtime } = service;
      // Ensure the type is "Pickup"
      if (type === "Order") {

        if (service_type === "Breakfast" && formattedTime >= starttime && formattedTime <= endtime) {
          //isBreakfast = true;
          console.log("Breakfast")
          setIsBetweenPickupBrk(true)
          setBreakfastCafetime(starttime + " to " + endtime);
        } else { setBreakfastCafetime(starttime + " to " + endtime); }

        // Check for Lunch
        if (service_type === "Lunch" && formattedTime >= starttime && formattedTime <= endtime) {
          //isLunch = true;
          console.log("Lunch")
          setIsBetweenPickupLun(true);
          setLunchtime(starttime + " to " + endtime);
        } else { setLunchtime(starttime + " to " + endtime); }

        // Check for Dinner
        if (service_type === "Dinner" && formattedTime >= starttime && formattedTime <= endtime) {
          console.log("Dinner")
          setIsBetweenPickupDinn(true);
          setDinnertime(starttime + " to " + endtime);
        } else { setDinnertime(starttime + " to " + endtime); }
      }
    }
    // Return an object with the flags
    // return {
    //   isBreakfast,
    //   isLunch,
    //   isDinner
    // };
  }

  async function getEmployeeActiveOrder() {

    var userid = parseInt(localStorage.getItem("userId"));
    let arr = [];
    await makeGetRequest(
      "starcafe/getorderemployee/null/Readyforpickup/" + userid + "/0/null/null"
    )
      .then((response) => {
        if (response.data.data[0]) {
          arr.push(response.data.data[0]);
          setOrderList(arr);
        } else {
          setOrderList([]);
        }
      })
      .catch((err) => {
        setOrderList([]);
      });
  }

  async function goToScreen(path) {
    if (path === "Breakfast") {
      localStorage.setItem("servicetype", "Breakfast");
      navigate("/BreakfastOrder");
    } else if (path === "Lunch") {
      localStorage.setItem("servicetype", "Lunch");
      navigate("/Lunch");
    } else if (path === "Dinner") {
      localStorage.setItem("servicetype", "Dinner");
      navigate("/Dinner");
    } else if (path === "Category") {
      navigate("/Category");
    } else if (path === "Starcafe") {
      localStorage.setItem("servicetype", "Starcafe");
      navigate("/StarCafeHome");
    }
  }

  async function navigatetoStarcafe() {
    localStorage.setItem("servicetype", "Starcafe");
    navigate("/StarCafeHome");
  }

  async function navigatetoStarmarket() {
    localStorage.setItem("servicetype", "Starmarket");
    navigate("/StarMarketHome");
  }

  async function gettodayorderBRK() {

    let id = localStorage.getItem("userId");
    await makeGetRequest(
      "starcafe/getorderbydateemployeeid/" +
      id +
      "/" +
      moment().format("YYYY-MM-DD") +
      "/Breakfast"
    )
      .then((response) => {
        if (response.data) {
          if (response.data.data.length > 0) {
            setIsOrdered(false);
          } else {
            setIsOrdered(true);
          }
        } else {
          setIsOrdered(false);
        }
      })
      .catch((err) => { });
  };

  async function gettodayorderLUN() {

    let id = localStorage.getItem("userId");
    await makeGetRequest(
      "starcafe/getorderbydateemployeeid/" +
      id +
      "/" +
      moment().format("YYYY-MM-DD") +
      "/Lunch"
    )
      .then((response) => {
        if (response.data) {
          if (response.data.data.length > 0) {
            setIsOrderedlun(false);
          } else {
            setIsOrderedlun(true);
          }
        } else {
          setIsOrderedlun(false);
        }
      })
      .catch((err) => { });
  };

  async function gettodayorderDIN() {

    let id = localStorage.getItem("userId");
    await makeGetRequest(
      "starcafe/getorderbydateemployeeid/" +
      id +
      "/" +
      moment().format("YYYY-MM-DD") +
      "/Dinner"
    )
      .then((response) => {
        if (response.data) {
          if (response.data.data.length > 0) {
            setIsOrdereddin(false);
          } else {
            setIsOrdereddin(true);
          }
        } else {
          setIsOrdereddin(false);
        }
      })
      .catch((err) => { });
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await Promise.all([
          getCafeTiming(),
          getEmployeeActiveOrder(),
          gettodayorderBRK(),
          gettodayorderLUN(),
          gettodayorderDIN(),
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAllData();
  }, []);

  return (
    <>
      <div className="mobileCssMain">
        <div className="dashMain forBottomTabOnly">
          <MobileHeader />
          {orderList.length > 0 ? (
            <>
              {/* Order Display */}
              {orderList.map((item, index) => (
                <div className="container">
                  <div className="orderStatusDiv">
                    <div className="ordIcoDiv">
                      <img src={cup} />
                    </div>
                    <label>Your Star Cafe</label>
                    <h2
                      onClick={() =>
                        navigate("/ReadyOrderDetails", {
                          state: { id: item.order_id },
                        })
                      }
                    >
                      Order # {item.order_id}
                    </h2>
                    <h3>{item.orderstatus}</h3>
                    <img src={ordernotification} className="deliveryBoyImg" />
                  </div>
                </div>
              ))}
            </>
          ) : null}

          <div className="wrapper">
            <div className="notifiDiv">
              <h1 className="mainHEadingOrder">
                <b>Order Your Meal </b> {/* <span>Now Accepting Orders</span> */}
              </h1>

              {cafeTiming.length > 0 ? (
                <>
                  {/* Breakfast ordering */}
                  {cafeTiming.map((cafetiming, index) => (
                    <>
                      {cafetiming.service_type == "Breakfast" &&
                        cafetiming.type === "Order" ? (
                        <>
                          {isOrdered ?
                            <>
                              {isBetweenPickupBrk ?
                                < div
                                  className="orderNotiDiv"
                                  onClick={() => goToScreen("Breakfast")}
                                >
                                  <div class="ordIcoDiv">
                                    <img src={mealMenu} />
                                  </div>
                                  <div>
                                    <h1>
                                      Time{" "}
                                      {moment(cafetiming.starttime, 'HH:mm').format('hh:mm A') + " " + moment(cafetiming.endtime, 'HH:mm').format('hh:mm A')}
                                    </h1>
                                    <h2>Breakfast Booking For Tomorrow</h2>
                                  </div>
                                  <IconButton className="TouchableIconBtn">
                                    <img src={rightArrow} />
                                  </IconButton>
                                </div> :
                                < div
                                  className="orderNotiDiv"
                                //onClick={() => goToScreen("Breakfast")}
                                >
                                  <div class="ordIcoDiv">
                                    <img src={mealMenu} />
                                  </div>
                                  <div>
                                    {/* <h1>
                                      Time{" "}
                                      {moment(cafetiming.starttime, 'HH:mm').format('hh:mm A') + " " + moment(cafetiming.endtime, 'HH:mm').format('hh:mm A')}
                                    </h1> */}
                                    <h2>Breakfast Booking For Tomorrow</h2>
                                    <p className="placedOrdertx">Order's Will be accepeted between {moment(cafetiming.starttime, 'HH:mm').format('hh:mm A') + " to " + moment(cafetiming.endtime, 'HH:mm').format('hh:mm A')}</p>
                                  </div>
                                </div>
                              }

                            </>
                            :
                            <div
                              className="orderNotiDiv"
                            //onClick={() => goToScreen("Breakfast")}
                            >
                              <div class="ordIcoDiv">
                                <img src={mealMenu} />
                              </div>
                              <div>
                                {/* <h1>
                                  Time{" "}
                                  {moment(cafetiming.starttime, 'HH:mm').format('hh:mm A') + " " + moment(cafetiming.endtime, 'HH:mm').format('hh:mm A')}
                                </h1> */}
                                <h2>Breakfast Booking For Tomorrow</h2>
                                <p className="placedOrdertx">Order Placed for the day</p>
                              </div>
                            </div>
                          }
                        </>
                      ) : null}
                    </>
                  ))}

                  {/* Lucnh ordering */}
                  {cafeTiming.map((cafetiming, index) => (
                    <>
                      {cafetiming.service_type == "Lunch" &&
                        cafetiming.type === "Order" ? (
                        <>
                          {isOrderedlun ?
                            <>
                              {isBetweenPickupLun ?
                                <div className="">
                                  {/* <h3 className="orderMealStTx">Order your meal</h3> */}
                                  <div
                                    className="orderNotiDiv heightIncr"
                                    onClick={() => goToScreen("Lunch")}
                                  >
                                    <div class="ordIcoDiv">
                                      <img src={lunch} />
                                    </div>
                                    <div>
                                      {/* <h1>
                                        Time{" "}
                                        {moment(cafetiming.starttime, 'HH:mm').format('hh:mm A') + " " + moment(cafetiming.endtime, 'HH:mm').format('hh:mm A')}
                                      </h1> */}
                                      <h2>Order Lunch For Tomorrow </h2>
                                    </div>
                                    <IconButton className="TouchableIconBtn">
                                      <img src={rightArrow} />
                                    </IconButton>
                                  </div>
                                </div> :
                                <div className="">
                                  {/* <h3 className="orderMealStTx">Order your meal</h3> */}
                                  <div
                                    className="orderNotiDiv heightIncr"
                                  //onClick={() => goToScreen("Lunch")}
                                  >
                                    <div class="ordIcoDiv">
                                      <img src={lunch} />
                                    </div>
                                    <div>
                                      {/* <h1>
                                        Time{" "}
                                        {moment(cafetiming.starttime, 'HH:mm').format('hh:mm A') + " " + moment(cafetiming.endtime, 'HH:mm').format('hh:mm A')}
                                      </h1> */}
                                      <h2>Order Lunch For Tomorrow </h2>
                                      <p className="placedOrdertx">Order's Will be accepeted between {moment(cafetiming.starttime, 'HH:mm').format('hh:mm A') + " to " + moment(cafetiming.endtime, 'HH:mm').format('hh:mm A')}</p>
                                    </div>
                                  </div>
                                </div>
                              }
                            </>
                            :
                            <div className="">
                              {/* <h3 className="orderMealStTx">Order your meal</h3> */}
                              <div
                                className="orderNotiDiv heightIncr"
                              //onClick={() => goToScreen("Lunch")}
                              >
                                <div class="ordIcoDiv">
                                  <img src={lunch} />
                                </div>
                                <div>
                                  {/* <h1>
                                    Time{" "}
                                    {moment(cafetiming.starttime, 'HH:mm').format('hh:mm A') + " " + moment(cafetiming.endtime, 'HH:mm').format('hh:mm A')}
                                  </h1> */}
                                  <h2>Order Lunch For Tomorrow </h2>
                                  <p className="placedOrdertx">Order Placed for the day</p>
                                </div>
                                {/* <IconButton className="TouchableIconBtn">
                                  <img src={rightArrow} />
                                </IconButton> */}

                              </div>
                            </div>
                          }
                        </>
                      ) : null}
                    </>
                  ))}

                  {/* Dinner Ordering */}
                  {cafeTiming.map((cafetiming, index) => (
                    <>
                      {cafetiming.service_type == "Dinner" &&
                        cafetiming.type === "Order" ? (
                        <>
                          {isOrdereddin ?
                            <>
                              {isBetweenPickupDinn ?
                                <div
                                  className="orderNotiDiv heightIncr"
                                  style={{ marginBottom: 0 }}
                                  onClick={() => goToScreen("Dinner")}
                                >
                                  <div class="ordIcoDiv">
                                    <img src={dinner} />
                                  </div>
                                  <div>
                                    {/* <h1>
                                      Time{" "}
                                      {moment(cafetiming.starttime, 'HH:mm').format('hh:mm A') + " " + moment(cafetiming.endtime, 'HH:mm').format('hh:mm A')}
                                    </h1> */}
                                    <h2>Order Dinner For Tomorrow </h2>
                                  </div>
                                  <IconButton className="TouchableIconBtn">
                                    <img src={rightArrow} />
                                  </IconButton>
                                </div> :
                                <div
                                  className="orderNotiDiv heightIncr"
                                  style={{ marginBottom: 0 }}
                                //onClick={() => goToScreen("Dinner")}
                                >
                                  <div class="ordIcoDiv">
                                    <img src={dinner} />
                                  </div>
                                  <div>
                                    {/* <h1>
                                      Time{" "}
                                      {moment(cafetiming.starttime, 'HH:mm').format('hh:mm A') + " " + moment(cafetiming.endtime, 'HH:mm').format('hh:mm A')}
                                    </h1> */}
                                    <h2>Order Dinner For Tomorrow </h2>
                                    <p className="placedOrdertx">Order's Will be accepeted between {moment(dinnertime, 'HH:mm').format('hh:mm A') + " to " + moment(cafetiming.endtime, 'HH:mm').format('hh:mm A')}</p>
                                  </div>
                                </div>
                              }
                            </>
                            :
                            <div
                              className="orderNotiDiv heightIncr"
                              style={{ marginBottom: 0 }}
                            //onClick={() => goToScreen("Dinner")}
                            >
                              <div class="ordIcoDiv">
                                <img src={dinner} />
                              </div>
                              <div>
                                <h1>
                                  Time{" "}
                                  {moment(cafetiming.starttime, 'HH:mm').format('hh:mm A') + " " + moment(cafetiming.endtime, 'HH:mm').format('hh:mm A')}
                                </h1>
                                <p className="placedOrdertx">Order Placed for the day</p>
                              </div>

                            </div>
                          }
                        </>
                      ) : null}
                    </>
                  ))}

                </>
              ) : (
                <>
                  {isOrdered ?
                    <>
                      {isBetween3And9PM ?
                        <div
                          className="orderNotiDiv"
                          onClick={() => goToScreen("Breakfast")}
                        >
                          <div class="ordIcoDiv">
                            <img src={mealMenu} />
                          </div>
                          <div>
                            <h1>Time:- For Timing Please Contact Admin</h1>
                            <h2>Breakfast Booking For Tomorrow</h2>
                          </div>
                          <IconButton className="TouchableIconBtn">
                            <img src={rightArrow} />
                          </IconButton>
                        </div> :
                        <div
                          className="orderNotiDiv"
                        //onClick={() => goToScreen("Breakfast")}
                        >
                          <div class="ordIcoDiv">
                            <img src={mealMenu} />
                          </div>
                          <div>
                            <h1>Time:- For Timing Please Contact Admin</h1>
                            <h2>Breakfast Booking For Tomorrow</h2>
                            <p className="placedOrdertx">For Order booking timing please contact admin</p>
                          </div>
                        </div>
                      }
                    </>
                    :
                    <div
                      className="orderNotiDiv"
                    // onClick={() => goToScreen("Breakfast")}
                    >
                      <div class="ordIcoDiv">
                        <img src={mealMenu} />
                      </div>
                      <div>
                        <h1>Time:- For Timing Please Contact Admin</h1>
                        <p className="placedOrdertx">Order Placed for the day</p>                      </div>
                      {/* <IconButton className="TouchableIconBtn">
                        <img src={rightArrow} />
                      </IconButton> */}
                    </div>
                  }

                  {isOrderedlun ?
                    <>
                      {isBetween3And9PM ?
                        <div className="">
                          <div
                            className="orderNotiDiv heightIncr"
                            onClick={() => goToScreen("Lunch")}
                          >
                            <div class="ordIcoDiv">
                              <img src={lunch} />
                            </div>
                            <div>
                              <h1>Time :- For Timing Please Contact Admin</h1>
                              <h2>Order Lunch For Tomorrow </h2>
                            </div>
                            <IconButton className="TouchableIconBtn">
                              <img src={rightArrow} />
                            </IconButton>
                          </div>
                        </div> :
                        <div className="">
                          <div
                            className="orderNotiDiv heightIncr"
                          //onClick={() => goToScreen("Lunch")}
                          >
                            <div class="ordIcoDiv">
                              <img src={lunch} />
                            </div>
                            <div>
                              <h1>Time :- For Timing Please Contact Admin</h1>
                              <h2>Order Lunch For Tomorrow </h2>
                              <p className="placedOrdertx">For Order booking timing please contact admin</p>
                            </div>
                          </div>
                        </div>
                      }
                    </>
                    :
                    <div className="">
                      <div
                        className="orderNotiDiv heightIncr"
                        onClick={() => goToScreen("Lunch")}
                      >
                        <div class="ordIcoDiv">
                          <img src={lunch} />
                        </div>
                        <div>
                          <h1>Time :- For Timing Please Contact Admin</h1>
                          <p className="placedOrdertx">Order Placed for the day</p>
                        </div>
                      </div>
                    </div>
                  }

                  {isOrdereddin ?
                    <>
                      {isBetween3And9PM ?
                        <div
                          className="orderNotiDiv heightIncr"
                          style={{ marginBottom: 0 }}
                          onClick={() => goToScreen("Dinner")}
                        >
                          <div class="ordIcoDiv">
                            <img src={dinner} />
                          </div>
                          <div>
                            <h1>Time :- For Timing Please Contact Admin</h1>
                            <h2>Order Dinner For Tomorrow</h2>
                          </div>
                          <IconButton className="TouchableIconBtn">
                            <img src={rightArrow} />
                          </IconButton>
                        </div> :
                        <div
                          className="orderNotiDiv heightIncr"
                          style={{ marginBottom: 0 }}
                        //onClick={() => goToScreen("Dinner")}
                        >
                          <div class="ordIcoDiv">
                            <img src={dinner} />
                          </div>
                          <div>
                            <h1>Time :- For Timing Please Contact Admin</h1>
                            <h2>Order Dinner For Tomorrow</h2>
                            <p className="placedOrdertx">For Order booking timing please contact admin</p>

                          </div>
                          {/* <IconButton className="TouchableIconBtn">
                          <img src={rightArrow} />
                        </IconButton> */}
                        </div>
                      }
                    </>
                    :
                    <div
                      className="orderNotiDiv heightIncr"
                      style={{ marginBottom: 0 }}
                      onClick={() => goToScreen("Dinner")}
                    >
                      <div class="ordIcoDiv">
                        <img src={dinner} />
                      </div>
                      <div>
                        <h1>Time :- For Timing Please Contact Admin</h1>
                        <p className="placedOrdertx">Order Placed for the day</p>
                      </div>
                    </div>
                  }
                </>
              )}

            </div>

            <div className="storesMain">
              <div className="container">
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <h2 className="storesHeading">Stores</h2>
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      onClick={() => navigatetoStarmarket()}
                      className="storInner"
                    >
                      <div>
                        <img src={starMarket} />
                      </div>
                      <b>Star Market</b>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      className="storInner"
                      onClick={() => navigatetoStarcafe()}
                    >
                      <div>
                        <img src={starCafe} />
                      </div>
                      <b>Star Cafe</b>
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <div className="storInner">
                      <div>
                        <img src={barberShop} />
                      </div>
                      <b>Barber Shop</b>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="storInner">
                      <div>
                        <img src={Sports} />
                      </div>
                      <b>Star Sports</b>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <h2 className="storesHeading">Others</h2>
                  </Grid>
                  {localStorage.getItem("is_cfo_warning") === 'true' ?
                    <>
                      <Grid item xs={6}>
                        <div
                          onClick={() => navigate("/CFOWarningList")}
                          className="storInner storWarn"
                        >
                          <div>
                            <img src={warning} />
                          </div>
                          <b>Warnings (CFO)</b>
                        </div>
                      </Grid>
                    </>
                    : null
                  }

                  <Grid item xs={6}>
                    <div className="storInner storWarn">
                      <div>
                        <img src={bills} />
                      </div>
                      <b>Bills</b>
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <div onClick={() => navigate("/SupportTicketsMobile")} className="storInner storWarn">
                      <div>
                        <ArticleIcon />
                      </div>
                      <b>Support Tickets</b>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>

          </div>
          <BottomTabsPage />
        </div >
      </div >
    </>
  );
}

export default DashboardPage;
