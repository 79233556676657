import React, { useState, useEffect, useRef } from "react";
import Left from "../../Left";
import Top from "../../Top";
import { Button, Grid, Box } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import { useNavigate } from "react-router-dom";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";

import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import StarIcon from "@mui/icons-material/Star";
import Pagination from "@mui/material/Pagination";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

// For According
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
//

import editIco from "../../../assets/images/edit-icon.svg";
import StorefrontIcon from "@mui/icons-material/Storefront";
import TableBarIcon from "@mui/icons-material/TableBar";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";

import { makePostRequest, makeGetRequest } from "../../../utils/utils";
import moment from "moment";

import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import deletIco from "../../../assets/images/delete-icon.svg";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import swal from "sweetalert";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import loaderImg from "../../../assets/images/logo.png";

// For Time Picker
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
// Time Picker End

// For Tab
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
// For Tab End

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// For Tab
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// For Tab End

function Settings() {
  const navigate = useNavigate();

  // For Tab
  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  // For Tab End

  // Modal Main
  const [openSetTime, setOpenSetTime] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [isEditTiming, setIsEditTiming] = useState(false);

  const handleClickSetTime = (scrollType, serviceType, Type) => () => {
    setOpenSetTime(true);
    setScroll(scrollType);
    setServiceType(serviceType);
    setType(Type);
  };

  const handleClickEditTime = (scrollType, serviceType, Type) => () => {
    setIsEditTiming(true);
    setOpenSetTime(true);
    setScroll(scrollType);
    setServiceType(serviceType);
    setType(Type);
  };

  const handleCloseSetTime = () => {
    setOpenSetTime(false);
    setServiceType("");
    setType("");
    setIsEditTiming(false);
    setToDate(dayjs(Date()));
    setFromDate(dayjs(Date()));
  };

  const descriptionElementRef = useRef(null);

  // End

  // PickUp Time
  const [openSetPickupTime, setOpenSetPickupTime] = useState(false);

  const handleClickSetPickupTime = (scrollType, serviceType, Type) => () => {
    setOpenSetPickupTime(true);
    setScroll(scrollType);
    setServiceType(serviceType);
    setType(Type);
  };

  const handleClickEditPickupTime = (scrollType, serviceType, Type) => () => {
    setOpenSetPickupTime(true);
    setScroll(scrollType);
    setServiceType(serviceType);
    setType(Type);
    setIsEditTiming(true);
  };

  const handleCloseSetPickupTime = () => {
    setOpenSetPickupTime(false);
    setIsEditTiming(false);
    setServiceType("");
    setType("");
    setToDate(dayjs(Date()));
    setFromDate(dayjs(Date()));
  };

  // End

  const [selected, setSelected] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [allowenceLevel, setAllowenceLevel] = useState("");
  const [allowenceLevelOptions, setAllowenceLevelOptions] = useState([]);
  const [allowenceLevelErrMsg, setAllowenceLevelErrMsg] = useState("");
  const [masterDollarAmount, setMasterDollarAmount] = useState(0);
  const [upload, setUpload] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [passwordFormValues, setPasswordFormValues] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [priceErrMsg, setPriceErrMsg] = React.useState("");
  const [allowance_in_dollar, setAllowenceInDollar] = React.useState(0);
  const [star_allowance, setStarAllowence] = React.useState(0);

  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const initialValues = {
    //User_name: "",
    old_password: "",
    password: "",
    cofirm_password: "",
  };

  const validationSchema = Yup.object({
    //User_name: Yup.string().required("Required"),
    old_password: Yup.string().required("Required"),
    password: Yup.string()
      .matches(
      /^(?=.*[0-9])(?=.*[- ?!@#$%^&*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9- ?!@#$%^&*\/\\]{8,30}$/,
      "Password should be minimum 8 character long and a combination of uppercase letters, lowercase letters, numbers, and symbols!"
    ).required("Required"),
    cofirm_password: Yup.string()
      .oneOf([Yup.ref("password")], "Password not matched")
      .required("Required"),
  });

  const setOrderTime = {
    set_from_time: "",
    set_to_time: "",
  };

  const setOrderTimeValidationSchema = Yup.object({
    set_from_time: Yup.string().required("Required"),
    set_to_time: Yup.string().required("Required"),
  });

  const setPickupTime = {
    set_from_time: "",
    set_to_time: "",
  };

  const setPickupTimeValidationSchema = Yup.object({
    set_from_time: Yup.string().required("Required"),
    set_to_time: Yup.string().required("Required"),
  });

  const handleChangeTime = (newValue) => {
    console.log("Selected Value");
    console.log(newValue);
  };

  function getMasterDollarAmount() {
    setShowLoader(true);
    makeGetRequest("employee/getmasterstarcount/")
      .then((response) => {
        if (response.data) {
          console.log(response);
          if (response.data.data.length > 0) {
            setMasterDollarAmount(response.data.data[0].dollar_amount);
            setUpload(!upload);
          } else {
            swal(
              "Set Exchange Stars",
              "First set master star rate with dollar",
              "warning",
              {
                timer: 4000,
                buttons: false,
              }
            );
            navigate("/Dashboard");
          }
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
        setShowLoader(false);
      });
  }

  function handleChangeStarAmount(value) {
    setPriceErrMsg("");
    setStarAllowence(value);

    let regEx = /^\d+$/;
    if (regEx.test(value)) {
      setAllowenceInDollar(value * masterDollarAmount);
    } else {
      if (value) setPriceErrMsg("Invalid Value");
      else {
        setPriceErrMsg("Invalid Value");
        setAllowenceInDollar(0);
      }
    }
  }

  // function handleChangeStarAmount(value) {
  //   setStarAllowence(value);
  //   setAllowenceInDollar(parseFloat(value / masterDollarAmount).toFixed(2));
  // }

  const addOrUpdateAllowenceLevel = (values) => {
    if (priceErrMsg == "") {
      if (isEditAllowenceLevel) {
        let inputData = {
          allowencelevel_id:formValues.allowencelevel_id,
          allowencelevel_name:allowenceLevel,
          star_allowance: parseInt(star_allowance),
          // allowance_in_dollar: allowance_in_dollar,
        };

        makePostRequest("employee/updateallowencelevel", inputData)
          .then((response) => {
            if (response.data) {
              //console.log(response)
              getAllowenceLevelList(null);
              setAllowenceLevel("");
              setAllowenceInDollar(0);
              setStarAllowence(0);
            }
            setIsEditAllowenceLevel(false);
            setFormValues(null);
          })
          .catch((err) => {
            console.log(err);
            //let errMsg = err.response.data.message;
            swal("Warning", err, "warning", {
              timer: 3000,
              buttons: false,
            });
          });
      } else {
        if (allowenceLevel != "") {
          let inputData = {
            allowencelevel_name: allowenceLevel,
            star_allowance: parseInt(star_allowance),
          };
          // allowance_in_dollar :  parseInt(allowance_in_dollar)}

          makePostRequest("employee/insertallowencelevel", inputData)
            .then((response) => {
              console.log(response);
              setAllowenceLevel("");
              setAllowenceInDollar(0);
              setStarAllowence(0);
              getAllowenceLevelList(null);
            })
            .catch((err) => {
              console.log(err);
              let errMsg = err.response.data.message;
            });
        } else setAllowenceLevelErrMsg("Required");
      }
    }
  };

  const getAllAllowenceLevelList = (searchString) => {
    console.log(searchString);
    setShowLoader(true);

    makeGetRequest("/employee/getallowencelevel/" + searchString)
      .then((response) => {
        console.log(response);
        if (response.data.data) {
          console.log("Data ", response.data.data);

          for (var i = 0; i < response.data.data.length; i++) {
            allowenceLevelOptions.push(response.data.data[i].allowencelevel_name);
          }

          setAllowenceLevelOptions(allowenceLevelOptions);
        }

        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const [allowenceList, setAllowenceList] = useState([]);
  const [sortBy, setSortBy] = useState("desc");
  const [pageNo, setPageNo] = useState(0);
  const [sortColumnName, setSortColumnName] = useState("created_at");

  const getAllowenceLevelList = (searchString) => {
    setShowLoader(true);
    makeGetRequest(
      "/employee/allowencelevelsearch/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        searchString +
        "/" +
        pageNo +
        "/" +
        10
    )
      .then((response) => {
        console.log(response);
        if (response.data.data) {
          console.log("Data ", response.data.data.res);
          setAllowenceList(response.data.data.res);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  // Delete Department
  const deleteAllowenceLevel = async (allowenceLevel_id) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Cafe Inventory Deleted!",
          text: "Cafe Inventory is successfully deleted!",
          icon: "success",
          timer: 3000,
        }).then(function () {
          let inputdata = {
            allowenceLevel_id: allowenceLevel_id,
            is_deleted: true,
          };
          //console.log("Input Data", inputdata);
          makePostRequest("employee/updateallowencelevel", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                getAllAllowenceLevelList(null);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        //swal("Cancelled", "Your Category is safe :)", "error");
      }
    });
  };
  // Delete allowenceLevel End

  //Edit Category Api start
  const [isEditAllowenceLevel, setIsEditAllowenceLevel] = useState(false);
  const editAllowenceLevel = (allowenceObj) => {
    console.log("AllowenceLevelData", allowenceObj);
    setFormValues(allowenceObj);
    setAllowenceInDollar(allowenceObj.star_allowance / masterDollarAmount);
    setStarAllowence(allowenceObj.star_allowance);
    setIsEditAllowenceLevel(true);
    setAllowenceLevel(allowenceObj.allowencelevel_name);
  };
  // Edit Category End

  // Sort Category Start

  function handleChangeSort() {
    //alert("In ASC")
    setSortColumnName("allowencelevel_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    //setUpload(!upload);
    getAllAllowenceLevelList(null);
  }
  // Sort Category End

  function getAllowenceLevelName(allowenceLevel) {
    console.log(allowenceLevel);
    setAllowenceLevel(allowenceLevel);
  }

  const updatePassword = (values) => {
    values.employee_code = localStorage.getItem("employeeCode");
    values.is_password_reset=true;
    console.log(values);
    makePostRequest("superadmin/changepassword", values)
      .then((response) => {
        if (response.data) {
          console.log(response.data.data);
        }
        setPasswordFormValues(null);
      })
      .catch((err) => {
        console.log(err);
        //let errMsg = err.response.data.message;
        /* swal("Warning", err, "warning", {
            timer: 3000,
            buttons: false,
          }); */
      });
  };

  // Get Cafe Timing Start
  const [cafeTiming, setCafeTiming] = useState([]);

  const getCafeTiming = () => {
    setShowLoader(true);
    // /getcafetiming
    makeGetRequest("starcafe/getcafetiming")
      .then((response) => {
        console.log(response);
        if (response.data.data) {
          console.log("Get Timing", response.data.data);
          setCafeTiming(response.data.data);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log("Error", err);
        setShowLoader(false);
      });
  };
  // Get Cafe Timing end
  // Order Timing Start
  const [fromDate, setFromDate] = useState(dayjs(Date()));
  const [toDate, setToDate] = useState(dayjs(Date()));
  const [serviceType, setServiceType] = useState("");
  const [type, setType] = useState("");

   console.log("From Date", fromDate.$d)
   console.log("To Date", toDate.$d);

  const handleSubmit = () => {
    if (isEditTiming) {
      const ToDate = moment(toDate.$d).format("LT");
      const FromDate = moment(fromDate.$d).format("LT");
      //const FromDate = moment(toDate.$d).format("LLLL");
      //const ToDate = moment(fromDate.$d).format("LLLL");
      
      let GetTimingObj = cafeTiming.find(
        (o) => o.service_type === serviceType && o.type === type
      );
      console.log(GetTimingObj);
      const formData = {
        cafetiming_id: GetTimingObj.cafetiming_id,
        starttime: FromDate,
        endtime: ToDate,
        type: type,
        service_type: serviceType,
      };
      makePostRequest("starcafe/updatecafetiming", formData)
        .then((response) => {
          if (response.data) {
            console.log(response.data.data);
          }
          getCafeTiming();
          handleCloseSetTime();
          handleCloseSetPickupTime();
          setToDate(dayjs(Date()));
          setFromDate(dayjs(Date()));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const ToDate = moment(toDate.$d).format("LT");
      const FromDate = moment(fromDate.$d).format("LT");
      console.log("service", serviceType + " " + type);
      const formData = {
        starttime: FromDate,
        endtime: ToDate,
        type: type,
        service_type: serviceType,
      };
      console.log("Form Data", formData);
      makePostRequest("starcafe/insertcafetiming", formData)
        .then((response) => {
          if (response.data) {
            console.log(response.data.data);
          }
          getCafeTiming();
          handleCloseSetTime();
          handleCloseSetPickupTime();
          setFromDate(dayjs(Date()));
          setToDate(dayjs(Date()));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  // Order Timing End
  useEffect(() => {
    localStorage.setItem('selectedModule','Settings');
    getMasterDollarAmount();
    getAllAllowenceLevelList(null);
    getAllowenceLevelList(null);
    getCafeTiming();
  }, [isEditAllowenceLevel, isEditTiming]);

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1 className="forBackArrowAlign">
                <WestIcon onClick={() => navigate("/")} />
                Settings
              </h1>
              {/* <Button className="pageTopMainBt">Add Employee</Button> */}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="settingMainDv">
              <div className="settingInr">
                <div className="accordingMain">
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <h3 class="settheading">
                        <LockOpenIcon /> Change Password
                      </h3>
                    </AccordionSummary>

                    <AccordionDetails>
                      <div className="allownceFormOutMain">
                        <Formik
                          initialValues={passwordFormValues || initialValues}
                          validationSchema={validationSchema}
                          onSubmit={updatePassword}
                        >
                          {(formik) => (
                            <Form>
                              <Grid container spacing={3}>
                                {/* <Grid item xs={12} md={6}>
                                  <FormikControl
                                    control="input"
                                    type="text"
                                    label="Username"
                                    placeholder="Username"
                                    labelClass="setingLbl"
                                    name="email_id"
                                    className="setingInpt"
                                  />
                                </Grid> */}
                                <Grid item xs={12} md={6}>
                                <div className="forPassEye">
                                  <FormikControl
                                    control="input"
                                    type={showOldPassword ? "text" : "password"}
                                    label="Old Password"
                                    placeholder="Old Password"
                                    labelClass="setingLbl"
                                    name="old_password"
                                    className="setingInpt"
                                  />
                                  {showOldPassword == true ? (
                                      <RemoveRedEyeIcon
                                        onClick={() => setShowOldPassword(false)}
                                      />
                                    ) : (
                                      <VisibilityOffIcon
                                        onClick={() => setShowOldPassword(true)}
                                      />
                                    )}
                                </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <div className="forPassEye">
                                  <FormikControl
                                    control="input"
                                    type={showPassword ? "text" : "password"}
                                    label="New Password"
                                    placeholder="New Password"
                                    labelClass="setingLbl"
                                    name="password"
                                    className="setingInpt"
                                  />
                                  {showPassword == true ? (
                                      <RemoveRedEyeIcon
                                        onClick={() => setShowPassword(false)}
                                      />
                                    ) : (
                                      <VisibilityOffIcon
                                        onClick={() => setShowPassword(true)}
                                      />
                                    )}
                                </div>  
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <div className="forPassEye">
                                  <FormikControl
                                    control="input"
                                    type={showConfirmPassword ? "text" : "password"}
                                    label="Cofirm Password"
                                    placeholder="Cofirm Password"
                                    labelClass="setingLbl"
                                    name="cofirm_password"
                                    className="setingInpt"
                                  />
                                {showConfirmPassword == true ? (
                                    <RemoveRedEyeIcon
                                      onClick={() => setShowConfirmPassword(false)}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      onClick={() => setShowConfirmPassword(true)}
                                    />
                                )}
                                </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <Button
                                    className="signInBt setiUpdateBt"
                                    type="submit"
                                  >
                                    Update
                                  </Button>
                                </Grid>
                              </Grid>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <h3 class="settheading">
                        <CurrencyRupeeIcon /> Set Allowance
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="allownceFormOutMain">
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12}>
                            <label className="setingLbl">Allowence Level</label>
                            <div className="autocompleteMain">
                              <Autocomplete
                                // disablePortal
                                options={allowenceLevelOptions}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                onChange={(e) =>
                                  getAllAllowenceLevelList(e.target.value)
                                }
                                onBlur={(e) =>
                                  getAllowenceLevelName(e.target.value)
                                }
                                value={allowenceLevel}
                              />
                              {allowenceLevelErrMsg}
                            </div>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <label className="setingLbl">
                              {" "}
                              Enter Allowance In Star{" "}
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Allowance In Star"
                              value={star_allowance}
                              className="setingInpt"
                              onChange={(e) =>
                                handleChangeStarAmount(e.target.value)
                              }
                            />
                            <span style={{ color: "red", fontSize: 14 }}>
                              {priceErrMsg}
                            </span>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <label className="setingLbl">
                              {" "}
                              Enter Allowance in INR (₹)
                            </label>

                            <input
                              type="text"
                              label="Enter Allowance In Rupees"
                              placeholder="Enter Allowance in INR (₹)"
                              value={allowance_in_dollar}
                              className="setingInpt"
                              // onChange={(e) =>
                              //   handleChangeDollarAmount(e.target.value)
                              // }
                              disabled

                            />
                            
                          </Grid>

                          

                          <Grid item xs={12} md={12}>
                            <Button
                              className="signInBt setiUpdateBt"
                              onClick={() => addOrUpdateAllowenceLevel()}
                            >
                              {isEditAllowenceLevel ? (
                                <span>Update</span>
                              ) : (
                                <span>Save</span>
                              )}
                            </Button>
                          </Grid>
                          {/* <SettingsSuggestIcon className="setiIcoDi" /> */}
                        </Grid>
                        {/* </Form>
                          )}
                        </Formik> */}
                      </div>

                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                          <div className="visitorSearch">
                            <div className="prodFilter">
                              <input
                                className="prodSearchInpt"
                                placeholder="Search"
                                onChange={(e) =>
                                  getAllowenceLevelList(e.target.value)
                                }
                              />
                            </div>
                            <div className="sortingIcoOut">
                              <ToggleButton
                                value="check"
                                selected={selected}
                                onChange={() => handleChangeSort()}
                              >
                                <ImportExportIcon
                                  className="visitorFilterIco"
                                  aria-haspopup="true"
                                />
                              </ToggleButton>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <div className="productDetails groceryTableMain">
                            <table width="100%" style={{ textAlign: "left" }}>
                              <thead>
                                <tr>
                                  <th>Allowence Level</th>
                                  <th>Allowance In Star</th>
                                  <th>Allowance in INR (₹)</th>
                                  <th>Manage</th>
                                </tr>
                              </thead>
                              <tbody>
                                {allowenceList.map((allowenceObj) => (
                                  <tr>
                                    <td>{allowenceObj.allowencelevel_name}</td>
                                    <td>
                                      <b className="listMainStar">
                                        <StarIcon /> {allowenceObj.star_allowance}
                                      </b>
                                    </td>
                                    <td>
                                      {" "}
                                      <b className="listMainStar">
                                       ₹ {parseFloat(allowenceObj.star_allowance *
                                          masterDollarAmount).toFixed(2)}
                                      </b>
                                    </td>
                                    <td>
                                      <Tooltip
                                        title="Edit"
                                        arrow
                                        placement="top"
                                      >
                                        <IconButton
                                          onClick={() =>
                                            editAllowenceLevel(allowenceObj)
                                          }
                                        >
                                          <img src={editIco} />
                                        </IconButton>
                                      </Tooltip>

                                      <Tooltip
                                        title="Delete"
                                        arrow
                                        placement="top"
                                      >
                                        <IconButton
                                          onClick={() =>
                                            deleteAllowenceLevel(
                                              allowenceObj.allowencelevel_id
                                            )
                                          }
                                        >
                                          <img src={deletIco} />
                                        </IconButton>
                                      </Tooltip>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          className="paginationOuterMain"
                        >
                          <Pagination
                            count={10}
                            variant="outlined"
                            shape="rounded"
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <h3 class="settheading">
                        <StorefrontIcon /> Star Market
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="allownceFormOutMain">
                        <div className="underConstruction">
                          <div className="workers">
                            <div className="left_hand"></div>
                            <div className="right_hand"></div>
                          </div>
                          <h3 class="title">
                            Our Website Is Under Construction
                          </h3>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <h3 class="settheading">
                        <TableBarIcon /> Star Cafe
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="starMarkerSettiAcor">
                        <div className="settingStarCafeMain">
                          <Box sx={{ width: "100%" }}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <Tabs
                                value={value}
                                onChange={handleChangeTab}
                                aria-label="basic tabs example"
                              >
                                <Tab
                                  label="Manage Order Timing"
                                  {...a11yProps(0)}
                                />
                                <Tab
                                  label="Manage Pickup Timing"
                                  {...a11yProps(1)}
                                />
                              </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                              <div className="settingStarCafeInner">
                                <Grid container spacing={3}>
                                  <Grid item xs={12} md={4}>
                                    <div className="orderTimingsMain">
                                      <div className="orderTimingsTop">
                                        <h2 className="breakfastTimeColor">
                                          Breakfast Time
                                        </h2>
                                        {cafeTiming.find(
                                          (o) =>
                                            o.service_type === "Breakfast" &&
                                            o.type === "Order"
                                        ) ? null : (
                                          <Tooltip
                                            title="Set Time"
                                            arrow
                                            placement="top"
                                          >
                                            <MoreTimeIcon
                                              className="addTimeBtn"
                                              onClick={handleClickSetTime(
                                                "body",
                                                "Breakfast",
                                                "Order"
                                              )}
                                            />
                                          </Tooltip>
                                        )}
                                      </div>

                                      <div className="orderTimingsCont">
                                        <div style={{ width: "100%" }}>
                                          {/* <div>No Time Added</div> */}
                                          {cafeTiming.map((data, index) => (
                                            <>
                                              {data.service_type ===
                                                "Breakfast" &&
                                              data.type === "Order" ? (
                                                <>
                                                  <h4>
                                                  {/* moment(data.starttime).format("hh:mm A") */}
                                                    {data.starttime} To{" "}
                                                    {data.endtime}
                                                  </h4>
                                                </>
                                              ) : null}
                                            </>
                                          ))}
                                          <p>Order Time</p>
                                        </div>
                                        {cafeTiming.find(
                                          (o) =>
                                            o.service_type === "Breakfast" &&
                                            o.type === "Order"
                                        ) ? (
                                          <Tooltip
                                            title="Edit Time"
                                            arrow
                                            placement="top"
                                          >
                                            <img
                                              src={editIco}
                                              style={{ cursor: "pointer" }}
                                              onClick={handleClickEditTime(
                                                "body",
                                                "Breakfast",
                                                "Order",
                                              )}
                                            />
                                          </Tooltip>
                                        ) : null }
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <div className="orderTimingsMain">
                                      <div className="orderTimingsTop">
                                        <h2 className="lunchTimeColor">
                                          Lunch Time
                                        </h2>
                                        {cafeTiming.find(
                                          (o) =>
                                            o.service_type === "Lunch" &&
                                            o.type === "Order"
                                        ) ? null : (
                                          <Tooltip
                                            title="Set Time"
                                            arrow
                                            placement="top"
                                          >
                                            <MoreTimeIcon
                                              className="addTimeBtn"
                                              onClick={handleClickSetTime(
                                                "body",
                                                "Lunch",
                                                "Order"
                                              )}
                                            />
                                          </Tooltip>
                                        )}
                                      </div>
                                      <div className="orderTimingsCont">
                                        <div style={{ width: "100%" }}>
                                          {cafeTiming.map((data, index) => (
                                            <>
                                              {data.service_type == "Lunch" &&
                                              data.type === "Order" ? (
                                                <>
                                                  <h4>
                                                    {data.starttime} To{" "}
                                                    {data.endtime}
                                                  </h4>
                                                </>
                                              ) : null}
                                            </>
                                          ))}
                                          <p>Order Time</p>
                                        </div>
                                        {cafeTiming.find(
                                          (o) =>
                                            o.service_type === "Lunch" &&
                                            o.type === "Order"
                                        ) ? (
                                          <Tooltip
                                            title="Edit Time"
                                            arrow
                                            placement="top"
                                          >
                                            <img
                                              src={editIco}
                                              onClick={handleClickEditTime(
                                                "body",
                                                "Lunch",
                                                "Order"
                                              )}
                                              style={{ cursor: "pointer" }}
                                            />
                                          </Tooltip>
                                        ) : null
                                        }
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <div className="orderTimingsMain">
                                      <div className="orderTimingsTop">
                                        <h2 className="dinnerTimeColor">
                                          Dinner Time
                                        </h2>
                                        {cafeTiming.find(
                                          (o) =>
                                            o.service_type === "Dinner" &&
                                            o.type === "Order"
                                        ) ? null : (
                                          <Tooltip
                                            title="Set Time"
                                            arrow
                                            placement="top"
                                          >
                                            <MoreTimeIcon
                                              className="addTimeBtn"
                                              onClick={handleClickSetTime(
                                                "body",
                                                "Dinner",
                                                "Order"
                                              )}
                                            />
                                          </Tooltip>
                                        )}
                                      </div>
                                      <div className="orderTimingsCont">
                                        <div style={{ width: "100%" }}>
                                          {cafeTiming.map((data, index) => (
                                            <>
                                              {data.service_type === "Dinner" &&
                                              data.type === "Order" ? (
                                                <>
                                                  <h4>
                                                    {data.starttime} To{" "}
                                                    {data.endtime}
                                                  </h4>
                                                </>
                                              ) : null}
                                            </>
                                          ))}
                                          <p>Order Time</p>
                                        </div>
                                        {cafeTiming.find(
                                          (o) =>
                                            o.service_type === "Dinner" &&
                                            o.type === "Order"
                                        ) ? (
                                          <Tooltip
                                            title="Edit Time"
                                            arrow
                                            placement="top"
                                          >
                                            <img
                                              src={editIco}
                                              onClick={handleClickEditTime(
                                                "body",
                                                "Dinner",
                                                "Order"
                                              )}
                                              style={{ cursor: "pointer" }}
                                            />
                                          </Tooltip>
                                        ) : null
                                        }
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                              <div className="settingStarCafeInner">
                                <Grid container spacing={3}>
                                  <Grid item xs={12} md={4}>
                                    <div className="orderTimingsMain">
                                      <div className="orderTimingsTop">
                                        <h2 className="breakfastTimeColor">
                                          Breakfast Time
                                        </h2>
                                        {cafeTiming.find(
                                          (o) =>
                                            o.service_type === "Breakfast" &&
                                            o.type === "Pickup"
                                        ) ? null : (
                                          <Tooltip
                                            title="Set Time"
                                            arrow
                                            placement="top"
                                          >
                                            <MoreTimeIcon
                                              className="addTimeBtn"
                                              onClick={handleClickSetPickupTime(
                                                "body",
                                                "Breakfast",
                                                "Pickup"
                                              )}
                                            />
                                          </Tooltip>
                                        )}
                                      </div>
                                      <div className="orderTimingsCont">
                                        <div style={{ width: "100%" }}>
                                          {/* <div>No Time Added</div> */}
                                          {cafeTiming.map((data, index) => (
                                            <>
                                              {data.service_type ===
                                                "Breakfast" &&
                                              data.type === "Pickup" ? (
                                                <>
                                                  <h4>
                                                    {data.starttime} To{" "}
                                                    {data.endtime}
                                                  </h4>
                                                </>
                                              ) : null}
                                            </>
                                          ))}
                                          <p>Pickup Time</p>
                                        </div>
                                        {cafeTiming.find(
                                          (o) =>
                                            o.service_type === "Breakfast" &&
                                            o.type === "Pickup"
                                        ) ? (
                                          <Tooltip
                                            title="Edit Time"
                                            arrow
                                            placement="top"
                                          >
                                            <img
                                              src={editIco}
                                              style={{ cursor: "pointer" }}
                                              onClick={handleClickEditPickupTime(
                                                "body",
                                                "Breakfast",
                                                "Pickup"
                                              )}
                                            />
                                          </Tooltip>
                                        ) : null}
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <div className="orderTimingsMain">
                                      <div className="orderTimingsTop">
                                        <h2 className="lunchTimeColor">
                                          Lunch Time
                                        </h2>
                                        {cafeTiming.find(
                                          (o) =>
                                            o.service_type === "Lunch" &&
                                            o.type === "Pickup"
                                        ) ? null : (
                                          <Tooltip
                                            title="Set Time"
                                            arrow
                                            placement="top"
                                          >
                                            <MoreTimeIcon
                                              className="addTimeBtn"
                                              onClick={handleClickSetPickupTime(
                                                "body",
                                                "Lunch",
                                                "Pickup"
                                              )}
                                            />
                                          </Tooltip>
                                        )}
                                      </div>
                                      <div className="orderTimingsCont">
                                        <div style={{ width: "100%" }}>
                                          {cafeTiming.map((data, index) => (
                                            <>
                                              {data.service_type === "Lunch" &&
                                              data.type === "Pickup" ? (
                                                <>
                                                  <h4>
                                                    {data.starttime} To{" "}
                                                    {data.endtime}
                                                  </h4>
                                                </>
                                              ) : null}
                                            </>
                                          ))}
                                          <p>Pickup Time</p>
                                        </div>
                                        {cafeTiming.find(
                                          (o) =>
                                            o.service_type === "Lunch" &&
                                            o.type === "Pickup"
                                        ) ? (
                                          <Tooltip
                                            title="Edit Time"
                                            arrow
                                            placement="top"
                                          >
                                            <img
                                              src={editIco}
                                              onClick={handleClickEditPickupTime(
                                                "body",
                                                "Lunch",
                                                "Pickup"
                                              )}
                                              style={{ cursor: "pointer" }}
                                            />
                                          </Tooltip>
                                        ) : null}
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <div className="orderTimingsMain">
                                      <div className="orderTimingsTop">
                                        <h2 className="dinnerTimeColor">
                                          Dinner Time
                                        </h2>
                                        {cafeTiming.find(
                                          (o) =>
                                            o.service_type === "Dinner" &&
                                            o.type === "Pickup"
                                        ) ? null : (
                                          <Tooltip
                                            title="Set Time"
                                            arrow
                                            placement="top"
                                          >
                                            <MoreTimeIcon
                                              className="addTimeBtn"
                                              onClick={handleClickSetPickupTime(
                                                "body",
                                                "Dinner",
                                                "Pickup"
                                              )}
                                            />
                                          </Tooltip>
                                        )}
                                      </div>
                                      <div className="orderTimingsCont">
                                        <div style={{ width: "100%" }}>
                                          {cafeTiming.map((data, index) => (
                                            <>
                                              {data.service_type === "Dinner" &&
                                              data.type === "Pickup" ? (
                                                <>
                                                  <h4>
                                                    {data.starttime} To{" "}
                                                    {data.endtime}
                                                  </h4>
                                                </>
                                              ) : null}
                                            </>
                                          ))}
                                          <p>Pickup Time</p>
                                        </div>
                                        {cafeTiming.find(
                                          (o) =>
                                            o.service_type === "Dinner" &&
                                            o.type === "Pickup"
                                        ) ? (
                                          <Tooltip
                                            title="Edit Time"
                                            arrow
                                            placement="top"
                                          >
                                            <img
                                              src={editIco}
                                              onClick={handleClickEditPickupTime(
                                                "body",
                                                "Dinner",
                                                "Pickup"
                                              )}
                                              style={{ cursor: "pointer" }}
                                            />
                                          </Tooltip>
                                        ) : null}
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </CustomTabPanel>
                          </Box>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <h3 class="settheading">
                        <ContentCutIcon /> Barber Shop
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="allownceFormOutMain">
                        <div className="underConstruction">
                          <div className="workers">
                            <div className="left_hand"></div>
                            <div className="right_hand"></div>
                          </div>
                          <h3 class="title">
                            Our Website Is Under Construction
                          </h3>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={expanded === "panel6"}
                    onChange={handleChange("panel6")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <h3 class="settheading">
                        <SportsFootballIcon /> Star Sports
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="allownceFormOutMain">
                        <div className="underConstruction">
                          <div className="workers">
                            <div className="left_hand"></div>
                            <div className="right_hand"></div>
                          </div>
                          <h3 class="title">
                            Our Website Is Under Construction
                          </h3>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        {/* Set Time */}
        <div>
          <Dialog
            open={openSetTime}
            // onClose={handleCloseSetTime}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            className="modalMain smallModal"
          >
            <DialogTitle id="scroll-dialog-title">
              {isEditTiming ? <>Edit</> : <>Set</>} {serviceType} Order Time
              <CloseIcon
                className="modalCloseBt"
                onClick={handleCloseSetTime}
              />
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                <div className="forModalSpace">
                  <div className="modalSinlInputDiv">
                    <Formik
                      initialValues={formValues || setOrderTime}
                      validationSchema={setOrderTimeValidationSchema}
                      enableReinitialize
                    >
                      {(formik) => (
                        <Form>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                              <div className="starCafeTimeMain">
                                <label className="pageLabel">
                                  Select From Time
                                </label>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={["MobileTimePicker"]}
                                  >
                                    <DemoItem>
                                      <MobileTimePicker
                                        defaultValue={fromDate}
                                        name="set_from_time"
                                        format="hh:mm a"
                                        onChange={(date) => setFromDate(date)}
                                      />
                                    </DemoItem>
                                  </DemoContainer>
                                </LocalizationProvider>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <div className="starCafeTimeMain">
                                <label className="pageLabel">
                                  Select To Time
                                </label>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={["MobileTimePicker"]}
                                  >
                                    <DemoItem>
                                      <MobileTimePicker
                                        defaultValue={toDate}
                                        name="set_to_time"
                                        format="hh:mm a"        
                                        onChange={(date) => setToDate(date)}
                                        // minTime={fromDate.set('hours', fromDate.$H+1)}
                                        // maxTime={fromDate.set('hours', fromDate.$H+5)}
                                      />
                                    </DemoItem>
                                  </DemoContainer>
                                </LocalizationProvider>
                              </div>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={12}
                              style={{ textAlign: "center" }}
                            >
                              <span /* onClick={handleCloseSetTime}*/>
                                <Button
                                  className="modalBtSmallWidth"
                                  type="submit"
                                  onClick={() => handleSubmit()}
                                >
                                  {isEditTiming ? <>edit</> : <>Save</>}
                                </Button>
                              </span>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>

        {/* Set Pickup Time */}
        <div>
          <Dialog
            open={openSetPickupTime}
            // onClose={handleCloseSetPickupTime}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            className="modalMain smallModal"
          >
            <DialogTitle id="scroll-dialog-title">
              {isEditTiming ? <>Edit</> : <>Set</>} {serviceType} Pickup Time
              <CloseIcon
                className="modalCloseBt"
                onClick={handleCloseSetPickupTime}
              />
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                <div className="forModalSpace">
                  <div className="modalSinlInputDiv">
                    <Formik
                      initialValues={formValues || setPickupTime}
                      validationSchema={setPickupTimeValidationSchema}
                      enableReinitialize
                    >
                      {(formik) => (
                        <Form>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                              <div className="starCafeTimeMain">
                                <label className="pageLabel">
                                  Select From Time
                                </label>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={["MobileTimePicker"]}
                                  >
                                    <DemoItem>
                                      <MobileTimePicker
                                        defaultValue={fromDate}
                                        name="set_from_time"
                                        format="hh:mm a"
                                        onChange={(date) => setFromDate(date)}
                                      />
                                    </DemoItem>
                                  </DemoContainer>
                                </LocalizationProvider>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <div className="starCafeTimeMain">
                                <label className="pageLabel">
                                  Select To Time
                                </label>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={["MobileTimePicker"]}
                                  >
                                    <DemoItem>
                                      <MobileTimePicker
                                        defaultValue={toDate}
                                        name="set_to_time"
                                        format="hh:mm a"
                                        onChange={(date) => setToDate(date)}
                                        // minTime={fromDate.set('hours', fromDate.$H+1)}
                                        // maxTime={fromDate.set('hours', fromDate.$H+5)}
                                      />
                                    </DemoItem>
                                  </DemoContainer>
                                </LocalizationProvider>
                              </div>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={12}
                              style={{ textAlign: "center" }}
                            >
                              <span>
                                <Button
                                  className="modalBtSmallWidth"
                                  type="submit"
                                  onClick={() => handleSubmit()}
                                >
                                  {isEditTiming ? <>Edit</> : <>Save</>}
                                </Button>
                              </span>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Settings;
