import React, { useContext } from "react";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { NotificationContext } from "./NotificationContext";
import { useNavigate } from "react-router-dom";
import dashLogo from "../../MobileScreens/assets/images/dashLogo.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton } from "@mui/material";
// import { NotificationContext } from "./NotificationContext";

function NotificationIcon() {
  const navigate = useNavigate();

  const { notifications, notificationCount, removeNotification } =
    useContext(NotificationContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  let company_name = localStorage.getItem("company_name");
  let logo = localStorage.getItem("company_logo");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (index) => {
    removeNotification(index);
  };

  return (
    // notificationCount > 0 &&
    <>
      <div className="notiFixedPatch"></div>
      <div className="notificationOuter">
        <div className="dashLogo">
          <div className="dashLogoOut">
            {logo != "" ? <img src={logo} /> : null}
          </div>
          <span>{company_name}</span>
        </div>

        <div className="notificationInner">
          <IconButton className="TouchableIconBtn">
            {" "}
            <NotificationsOutlinedIcon
              className="notiIco"
              // onClick={() => navigate("/Notifications")}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            />
          </IconButton>

          <p
          // onClick={() => navigate("/Notifications")}
          >
            {notificationCount}
          </p>

          <div className="">
            <Menu
              className="mobNotiFicMain"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {notifications.length > 0 &&
                notifications.map((item, index) => (
                  <MenuItem onClick={() => handleNotificationClick(index)}>
                    {item.body}
                  </MenuItem>
                ))}
              {/* <MenuItem onClick={handleClose}>
                Sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua.
              </MenuItem>
              <MenuItem onClick={handleClose}>
                Ut enim ad minim veniam.
              </MenuItem>
              <MenuItem onClick={handleClose}>
                Eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </MenuItem>
              <MenuItem onClick={handleClose}>
                sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua.
              </MenuItem> */}
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationIcon;
