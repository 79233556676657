import React, { useEffect } from "react";
import { Button, Grid, colors } from "@mui/material";
import { useNavigate } from "react-router-dom";
import loginLogo from "../../../assets/images/logo.png";
import loginLeftImg from "../../../assets/images/loginLeftImg.png";
// import { fetchToken, generateToken } from "../../../firebase.js";
import { messaging } from "../../../firebase.js";
import { getToken } from "firebase/messaging";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import swal from "sweetalert";
import { makePostRequest } from "../../../utils/utils";
import loaderImg from "../../../assets/images/logo.png";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";



function Login() {

  const navigate = useNavigate();

  const [loginErrMsg, setLoginErrMsg] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const [upload, setUpload] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [fcmToken, setFcmToken] = React.useState();

  const initialValues = {
    employee_code: "",
    password: "",
    // selectOption: "",
  };

  const validationSchema = Yup.object({
    employee_code: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const loginFun = async (values) => {
    setShowLoader(true);
    setLoginErrMsg("");

    // const fcmtoken = await generateToken();
    //           console.log("fcm token===>" + fcmtoken);
    
    makePostRequest("superadmin/superadminlogin", values)
      .then((response) => {
        if (response.data.data) {
          console.log(response);
            console.log(response.data.data);
            sessionStorage.setItem("accessToken", response.data.data.token_id);
            localStorage.setItem("employeeCode",response.data.data.employee_code);
            sessionStorage.setItem("schema", response.data.data.schemaname);

            if (response.data.data.is_password_reset == false) {
              navigate("/ResetPasswordAdmin");
            } else {
              localStorage.setItem("logInType", 'web');
              localStorage.setItem("userType", response.data.data.user_type);
              localStorage.setItem("userId", response.data.data.employee_id);
              localStorage.setItem("username", response.data.data.first_name+' '+response.data.data.last_name);
              localStorage.setItem("companyId", response.data.data.company_id);
              localStorage.setItem("adminProfilePic",response.data.data.profile_pic);
              localStorage.setItem("companyLogo",response.data.data.company_logo);
              localStorage.setItem("accessRights",JSON.stringify(response.data.data.access_rights));

                         
              if (response.data.data.user_type != "SuperAdmin") {
              makePostRequest(
                "employee/updateemployeestatus",
                {
                  employee_id: response.data.data.employee_id,
                  fcmtoken: fcmToken,
                },
                response.data.data.schemaname
              ).catch((err) => {
                console.log(err);
                setShowLoader(false);
              });
            }

              setUpload(!upload);
              if (response.data.data.user_type == "SuperAdmin") {
                navigate("/SuperAdminDashboard");
                setShowLoader(false);
              } else {
                sessionStorage.setItem("companyName", response.data.data.company_name);
                
                setTimeout(() => {
                  
                  if(response.data.data.access_rights==null)
                      navigate("/Help");
                  else if(response.data.data.access_rights.find(temp => temp.moduleName=='Dashboard'))
                      navigate("/Dashboard");
                  else if(response.data.data.access_rights.find(temp => temp.moduleName=='Employee'))
                    navigate("/Employees");
                  else if(response.data.data.access_rights.find(temp => temp.moduleName=='Orders'))
                    navigate("/Orders");
                  else if(response.data.data.access_rights.find(temp => temp.moduleName=='Inventory'))
                    navigate("/ServicesConfiguration");

                  
                  setShowLoader(false);
                }, 2000);
               
              }
            }
          }
          
        // }
      })
      .catch((err) => {
        setLoginErrMsg("Invalid Username/Password");
        setShowLoader(false);
      });
  };

React.useEffect(() => {
  sessionStorage.clear();
  localStorage.clear();

  const requestPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Notification permission granted.");

        // Get FCM token with the correct messaging instance
        const token = await getToken(messaging, {
          vapidKey: "BDHVWE6Iraow3JQbntfYYckfxjpHbNpNsf2vE_aKr_ze6BZkpWinyI5JOpUOvH-wl2Vu52W0-Gwr6uaCCZ-I_FQ",
        });

        if (token) {
          console.log("FCM Token:", token);
          setFcmToken(token);
          // Send this token to your server
        } else {
          console.log("No registration token available.");
        }
      } else {
        console.log("Notification permission denied.");
      }
    } catch (error) {
      console.error("Error in getting notification permission:", error);
    }
  };

  requestPermission();
}, []);

return (
  <>
    <div className="loginMainBody">
      <div className="loginLeft">
        <div className="loginInnerMain">
          <img src={loginLogo} className="loginLogoSt" />
          <h1 className="logoHeading">Star Labels</h1>
          <div className="loginHeadiCont">
            <h4>Login To Your Account</h4>
            <p>Member Login Area.</p>
          </div>
          {loginErrMsg ? (
            <div>
              <span className="logError">{loginErrMsg}</span>
            </div>
          ) : (
            <></>
          )}
          <div className="loginInpOuter">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={loginFun}
            >
              {(formik) => (
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <FormikControl
                        control="input"
                        type="text"
                        label="Employee Code"
                        placeholder="Employee Code"
                        labelClass="loginLabel"
                        name="employee_code"
                        className="loginInput"
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <div className="forPassEye">
                        <FormikControl
                          control="input"
                          type={showPassword ? "text" : "password"}
                          label="Password"
                          id="txtPassword"
                          labelClass="loginLabel"
                          placeholder="Password"
                          name="password"
                          className="loginInput"
                        />
                        {showPassword == true ? (
                          <RemoveRedEyeIcon
                            onClick={() => setShowPassword(false)}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={() => setShowPassword(true)}
                          />
                        )}
                      </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <p
                        className="forgotPasTex"
                        onClick={() => navigate("/ForgotPass")}
                      >
                        Forgot password?
                      </p>
                    </Grid>

                    {/* <Button
                        type="submit"
                          className="signInBt"
                          // onClick={() => navigate("/Dashboard")}
                          
                        >
                          Sign In
                        </Button> */}

                    <Grid item xs={12} md={12}>
                      <Button
                        type="submit"
                        className="signInBt"
                      // onClick={() => navigate("/Dashboard")}
                      >
                        Sign In
                      </Button>
                    </Grid>
                    {/* <Grid item xs={12} md={12}>
                    <p className="donttHavAc">
                      Don't have an account?{" "}
                      <b style={{ color: "#2592d2", cursor: "pointer" }}>
                        Request Here
                      </b>
                    </p>
                  </Grid> */}
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="loginRight">
        <img src={loginLeftImg} className="loginRightImg" />
      </div>
      {/* <Button onClick={() => navigate("/Home")}>Go to Home</Button> */}
    </div>

    {showLoader ? (
      <div className="newLoaderMain">
        <div class="preloader">
          <img src={loaderImg} style={{ width: 75 }} />
          {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
          <div class="preloader__text">
            <p class="preloader__msg preloader__msg--last">Loading...</p>
          </div>
        </div>
      </div>
    ) : (
      <></>
    )}
  </>
);
      }

export default Login;
