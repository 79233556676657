import React, { useEffect } from "react";
import StarIcon from "@mui/icons-material/Star";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { PieChart } from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts/LineChart";
import InsertPageBreakIcon from "@mui/icons-material/InsertPageBreak";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
import deletIco from "../../../assets/images/delete-icon.svg";
import eye from "../../../assets/images/eye.svg";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ImportExportIcon from "@mui/icons-material/ImportExport";

import AOS from "aos";
import "aos/dist/aos.css";

// Pie Graph
const data = [
  { id: 0, value: 40, label: "Spend In Star Cafe" },
  { id: 1, value: 60, label: "Spend In Star Market" },
];
// End

function ReportStarAllowanceTab() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="starCafeTabInner starAlloMain">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="repoFilterMain">
              <Button
                className="repoFilterBt activeRepoFilterBt"
                data-aos="fade-down"
                data-aos-delay="100"
                data-aos-anchor-placement="center-bottom"
              >
                Hourly
              </Button>
              <Button
                className="repoFilterBt"
                data-aos="fade-down"
                data-aos-delay="200"
                data-aos-anchor-placement="center-bottom"
              >
                Daily
              </Button>
              <Button
                className="repoFilterBt"
                data-aos="fade-down"
                data-aos-delay="300"
                data-aos-anchor-placement="center-bottom"
              >
                Week Day
              </Button>
              <Button
                className="repoFilterBt"
                data-aos="fade-down"
                data-aos-delay="400"
                data-aos-anchor-placement="center-bottom"
              >
                Weekly
              </Button>
              <Button
                className="repoFilterBt"
                data-aos="fade-down"
                data-aos-delay="500"
                data-aos-anchor-placement="center-bottom"
              >
                Monthly
              </Button>
              <Button
                className="repoFilterBt"
                data-aos="fade-down"
                data-aos-delay="600"
                data-aos-anchor-placement="center-bottom"
              >
                Yearly
              </Button>
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div className="selectDateRangeMain">
              <div className="selectDateRangeInr">
                <div>
                  <label>Select Date Range</label>
                </div>
                <div className="dateRangReport">
                  <div>
                    <input type="Date" className="prodSearchInpt" />
                  </div>
                  <b>To</b>
                  <div>
                    <input type="Date" className="prodSearchInpt" />
                  </div>
                </div>
                <div>
                  <Button className="repoFilterBt updateDataB">
                    Update Data
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={12} md={12}>
                      <div
                        className="settingDevider"
                        style={{ margin: 0 }}
                      ></div>
                    </Grid> */}
          <Grid item xs={12} md={12}>
            <h1 className="repoSubHeading">Additional Filters</h1>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className="additionalFiltterMain">
              <select className="prodSearchInpt">
                <option>All Department</option>
                <option>HR Department</option>
                <option>Software Department</option>
              </select>

              <select className="prodSearchInpt">
                <option>All Service</option>
                <option>Star Market</option>
                <option>Star Cafe</option>
                <option>Barber Shop</option>
                <option>Sport</option>
              </select>

              {/* <select className="prodSearchInpt">
                <option>Order Status</option>
                <option>New</option>
                <option>preparing</option>
                <option>Ready For Pickup</option>
              </select> */}
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div className="reportChartsMain">
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <div className="repoChartContDiv">
                    <div
                      className="repoChartCont chartRepoBlack"
                      data-aos="fade-down"
                      data-aos-delay="300"
                      data-aos-anchor-placement="center-bottom"
                    >
                      <b>500</b>
                      <p>Total Orders</p>
                    </div>
                    <div
                      className="repoChartCont chartRepoGreen"
                      data-aos="fade-down"
                      data-aos-delay="500"
                      data-aos-anchor-placement="center-bottom"
                    >
                      <b>
                        {/* <StarIcon />  */}
                        100
                      </b>
                      <p>Reload Stars</p>
                    </div>
                    <div
                      className="repoChartCont chartRepoBlue"
                      data-aos="fade-down"
                      data-aos-delay="700"
                      data-aos-anchor-placement="center-bottom"
                    >
                      <b>100</b>
                      <p>Stars Spend </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div
                    className="repoOrdersGraphMain"
                    data-aos="fade-up"
                    data-aos-delay="300"
                    data-aos-anchor-placement="center-bottom"
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <h1 className="orderGrapHeading">Stars Spend In</h1>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="repoGraIndec forGrapfDropDownOut">
                          <span>
                            <select className="forGrapfDropDown">
                              <option>Monthly</option>
                            </select>
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="orderPieGr">
                          <PieChart
                            series={[
                              {
                                data,
                                highlightScope: {
                                  faded: "global",
                                  highlighted: "item",
                                },
                                faded: {
                                  innerRadius: 30,
                                  additionalRadius: -30,
                                  color: "gray",
                                },
                              },
                            ]}
                            width={250}
                            height={140}
                            margin={{
                              top: 0,
                              bottom: 0,
                              left: 30,
                              right: 30,
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="repoOrderGrpDetai blackDetai">
                          <em></em>
                          <p>60%</p>
                          <span>Spend In Star Market</span>
                        </div>
                        <div className="repoOrderGrpDetai yellowDetai">
                          <em></em>
                          <p>40%</p>
                          <span>Spend In Star Cafe</span>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} md={7}>
                  <div
                    className="departChartMain"
                    data-aos="fade-down"
                    data-aos-delay="300"
                    data-aos-anchor-placement="center-bottom"
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <h1 className="orderGrapHeading">
                          Star Purchased <span>Star Spend</span>
                        </h1>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="lineRepoChart">
                          <LineChart
                            xAxis={[
                              {
                                data: [1, 2, 3, 5, 8, 10, 12, 15, 16],
                              },
                            ]}
                            series={[
                              {
                                data: [2, 5.5, 2, 8.5, 1.5, 5],
                                valueFormatter: (value) =>
                                  value == null ? "NaN" : value.toString(),
                              },
                              {
                                data: [
                                  null,
                                  null,
                                  null,
                                  null,
                                  5.5,
                                  2,
                                  8.5,
                                  1.5,
                                  5,
                                ],
                              },
                              {
                                data: [7, 8, 5, 4, null, null, 2, 5.5, 1],
                                valueFormatter: (value) =>
                                  value == null ? "?" : value.toString(),
                              },
                            ]}
                            height={249}
                            margin={{ top: 10, bottom: 20 }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div className="reportSearchMain">
              <input
                type="text"
                className="prodSearchInpt"
                placeholder="Search"
              />
              <div className="hoverForDrop">
                <Button className="repoBtn">
                  <InsertPageBreakIcon /> Export Report
                </Button>
                <div className="btDropHover">
                  <p>Export as .xslx</p>
                  <p>Export as .doc</p>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div
              className="productDetails groceryTableMain"
              data-aos="fade-down"
              data-aos-delay="100"
              data-aos-anchor-placement="center-bottom"
            >
              <table width="100%">
                <thead>
                  <tr>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Date
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>

                    <th>
                      <div className="smalShortingOuter finaShort">
                        Employee Name
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>

                    <th>
                      <div className="smalShortingOuter finaShort">
                        Department Name
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>

                    <th>
                      <div className="smalShortingOuter finaShort">
                        Allowance
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>

                    <th>
                      <div className="smalShortingOuter finaShort">
                        Reload Stars
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>

                    <th>
                      <div className="smalShortingOuter finaShort">
                        Stars Spend
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>

                    <th>
                      <div className="smalShortingOuter finaShort">
                        Actions
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>10/6/13</td>
                    <td>Brooklyn Simmons</td>
                    <td>IT</td>
                    <td>500</td>
                    <td>200</td>
                    <td>200</td>
                    <td>
                      <div className="actionBtnOut">
                        <Tooltip title="View" arrow placement="top">
                          <IconButton>
                            <img src={eye} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>3/4/16</td>
                    <td>Annette Black</td>
                    <td>Human Resources</td>
                    <td>500</td>
                    <td>200</td>
                    <td>200</td>
                    <td>
                      <div className="actionBtnOut">
                        <Tooltip title="View" arrow placement="top">
                          <IconButton>
                            <img src={eye} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>7/27/13</td>
                    <td>Eleanor Pena</td>
                    <td>Manning</td>
                    <td>500</td>
                    <td>200</td>
                    <td>200</td>
                    <td>
                      <div className="actionBtnOut">
                        <Tooltip title="View" arrow placement="top">
                          <IconButton>
                            <img src={eye} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Grid>

          <Grid item xs={12} md={12} className="paginationOuterMain">
            <Pagination count={10} variant="outlined" shape="rounded" />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ReportStarAllowanceTab;
