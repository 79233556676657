import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import TopHeader from "../SyatemComponents/TopHeader";
import Clock from "../assets/images/Clock.svg";
import profImg from "../assets/images/profImg.png";
import { Button } from '@mui/material';
import outIcon from '../assets/images/outIcon.svg'
import inIcon from '../assets/images/inIcon.svg'
import { useNavigate } from 'react-router-dom';

function EmpClockInClockOut() {

    const navigate = useNavigate();
    const [empcode, setEmpcode] = useState('');

    return (
        <>
            <div className="mobileCssMain">
                <div className="wrapper">
                    <TopHeader />
                    <div className='container'>
                        <div className="clockContentMain">
                            <div className="clockImg">
                                <img src={Clock} />
                            </div>
                        </div>
                        <div className='empCodeOuter'>
                            <h5>Employee Code</h5>
                            <div className='clockInputOuter'>
                                <OtpInput
                                    value={empcode}
                                    onChange={setEmpcode}
                                    numInputs={5}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} />}
                                />
                            </div>
                            <div className='profileBoxOuter'>
                                <div className='profImgOuter' >
                                    <img src={profImg} />
                                </div>
                            </div>
                            <div className='clockBtns'>
                               <Button 
                               onClick={() => navigate("/ClockInThankYou")}
                               variant="contained" endIcon={<img src={inIcon} />}>
                                    Clock In
                                </Button>
                                <Button
                                onClick={() => navigate("/ClockOutReasons")}
                                variant="contained" endIcon={<img src={outIcon} />}>
                                    Clock Out
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmpClockInClockOut;
