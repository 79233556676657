import React, { useState } from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import editIco from "../../../assets/images/edit-icon.svg";
import deletIco from "../../../assets/images/delete-icon.svg";
import StarIcon from "@mui/icons-material/Star";
import WestIcon from "@mui/icons-material/West";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import lockimg from "../../../assets/images/lock-outline.svg";
import productImg from "../../../assets/images/productImg.svg";
import ProductSlider from "./ProductSlider";
import ProductChart from "./ProductChart";
import Switch from "@mui/material/Switch";
import ProductCarousel from "./ProductCarousel";
import Surprise from "../../../assets/images/Surprise.svg";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import UpgradeIcon from "@mui/icons-material/Upgrade";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import loaderImg from "../../../assets/images/logo.png";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import StarCafeTabs from "./StarCafeTabs";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import moment from "moment";
import swal from "sweetalert";

// End

function ProductDetailsStarMarket() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [starMarketObj, setStarMarketObj] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [images, setImages] = useState([]);
  //getkitchensubcategorylistByid/:subcategoryid
  // get Kitchen Supplies Data Start
  const getStarMarketData = async () => {
    console.log("vj" + id);
    setShowLoader(true);
    await makeGetRequest("starmarket/getproductlistByid/" + id)
      .then((response) => {
        if (response.data) {
          console.log("StarMarket Data", response.data.data);
          setStarMarketObj(response.data.data[0]);
          setImages(response.data.data[0].product_pic);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  // get Kitchen Supplies Data End
  // Status Kitchen Supplies Start
  const handleStatusChangeStarMarket = (event, product_id) => {
    let inputdata = {
      product_id: product_id,
      status: event.target.checked,
    };
    console.log("Status", inputdata);

    makePostRequest("starmarket/updateproduct", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          getStarMarketData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Status Kitchen Supplies end
  // Delete Kitchen Supplies Start
  const deleteStarMarket = async (product_id) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Category Deleted!",
          text: "Category is successfully deleted!",
          icon: "success",
          timer: 3000,
        }).then(function () {
          let inputdata = {
            product_id: product_id,
            is_deleted: true,
          };
          //console.log("Input Data", inputdata);
          makePostRequest("starmarket/updateproduct", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                navigate("/ServicesConfiguration");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        //swal("Cancelled", "Your Category is safe :)", "error");
      }
    });
  };
  // Delete Kitchen Supplies End

  const [currentIndex, setCurrentIndex] = useState();
  function handleChange(index) {
    setCurrentIndex(index);
  }

  const label = { inputProps: { "aria-label": "Switch demo" } };

  // Modal Main
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);

  const renderSlides = images.map((image, index) => (
    <div key={index}>
      <img src={image} alt="img" />
      {/* <p className="legend">{image.label}</p> */}
    </div>
  ));

  // Modal Main
  const [open3, setOpen3] = React.useState(false);

  const handleClickOpen3 = (scrollType) => () => {
    setOpen3(true);
    setScroll(scrollType);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  // End

  // Modal Main
  const [open4, setOpen4] = React.useState(false);

  const handleClickOpen4 = (scrollType) => () => {
    setOpen4(true);
    setScroll(scrollType);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  React.useEffect(() => {
    getStarMarketData();
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (open3) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (open4) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open4, open, open3]);
  // End

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1 className="forBackArrowAlign">
                <WestIcon onClick={() => navigate("/ServicesConfiguration")} />
                Product Details
              </h1>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="productDetailsTop">
              <div className="productDetailsHeading">
                {starMarketObj ? (
                  <div className="productDetailsHeadLeft">
                    <h1>
                      {starMarketObj.product_name}
                      <span>
                        <b>+8%</b> from Yesterday
                      </span>
                    </h1>
                    <h4 className="productId">
                      Product ID #{starMarketObj.product_id}
                    </h4>
                  </div>
                ) : null}
                <div className="productDetailsHeadRight">
                  {starMarketObj ? (
                    <div className="actionBtnOut">
                      
                      <Switch
                        {...label}
                        checked={starMarketObj.status}
                        className="swicthMain"
                        onChange={(event) =>
                          handleStatusChangeStarMarket(
                            event,
                            starMarketObj.product_id
                          )
                        }
                      />

                      <Tooltip title="Delete" arrow placement="top">
                        <IconButton
                          onClick={() =>
                            deleteStarMarket(starMarketObj.product_id)
                          }
                        >
                          <img src={deletIco} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
              </div>
              

              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <div className="productImgDiv">
                      <Carousel
                        showArrows={true}
                        autoPlay={true}
                        infiniteLoop={true}
                        selectedItem={images[currentIndex]}
                        onChange={handleChange}
                        className="carousel-container"
                      >
                        {renderSlides}
                      </Carousel>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="salesOt">
                      <p>Sales</p>
                      <select>
                        <option>Months</option>
                      </select>
                    </div>
                    <div className="chartAmountMain">
                      <b>4512</b>
                      <p>
                      ₹ 1524.42{" "}
                        <span>
                          46% <UpgradeIcon />
                        </span>
                      </p>
                    </div>
                    <ProductChart />
                    {/* Chart Here... */}
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="settingDevider"></div>
                  </Grid>
                  {starMarketObj ? (
                    <>
                      <Grid item xs={12} md={6}>
                        <div className="proDetailasCont">
                          <div className="proDetailasContDiv">
                            <span>
                              <p>Last Date Added</p>
                              <b>
                                :{" "}
                                {moment(starMarketObj.created_at).format(
                                  "DD/MM/YYYY"
                                )}
                              </b>
                            </span>

                            <span>
                              <p>Weight per item</p>
                              <b>
                                : {starMarketObj.wt_per_item}{" "}
                                {starMarketObj.item_unit}
                              </b>
                            </span>

                            <span>
                              <p>Product In Stock</p>
                              <b>: {starMarketObj.current_quantity}</b>
                            </span>

                            <span>
                              <p>Low Stock Limit</p>
                              <b>: {starMarketObj.low_stock_limit}</b>
                            </span>
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <div className="proDetailasCont">
                          <div className="proDetailasContDiv">
                            {/* <span>
                              <p>Expiry Date</p>
                              <b>
                                :
                                {moment(starMarketObj.expiry_date).format(
                                  "DD/MM/YYYY"
                                )}
                              </b>
                            </span> */}

                            <span>
                              <p>Purchase Price in INR (₹)</p>
                              <b>: ₹ {starMarketObj.price}</b>
                            </span>

                            <span>
                              <p>Price In Stars</p>
                              <b className="listMainStar">
                                :
                                <StarIcon /> {starMarketObj.price_in_stars}
                              </b>
                            </span>
                          </div>
                        </div>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </div>
            </div>
          </Grid>

        </Grid>
      </div>

 
      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default ProductDetailsStarMarket;
