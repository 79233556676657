import React, { useState, useEffect, useRef } from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import StarIcon from "@mui/icons-material/Star";
import Pagination from "@mui/material/Pagination";
import { makePostRequest, makeGetRequest } from "../../../utils/utils";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
// End

// For Tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// End

// For Date Picker
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import swal from "sweetalert";

// End

import moment from "moment";

// For Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// End

function EmployeeOrderScan() {
  const navigate = useNavigate();

  // For Tabs
  const [value, setValue] = React.useState(0);

  const handleChangeTb = (event, newValue) => {
    setValue(newValue);
  };
  // End

  const [selected, setSelected] = React.useState(false);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [showLoader, setShowLoader] = React.useState(false);
  const [employeeList, setEmployeeList] = React.useState([]);
  const [upload, setUpload] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("desc");
  const [pageNo, setPageNo] = React.useState(1);
  const [searchString, setSearchSting] = React.useState(null);
  const [sortColumnName, setSortColumnName] = React.useState("created_at");
  const [orderType, setOrderType] = React.useState(null);

  const [offsetvalue, setoffsetvalue] = React.useState(0);
  const [itemsPerPage] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState(0);
  const [employeeObj, setEmployeeObj] = React.useState({});

  const [breakfastPickupStartTime, setBreakfastPickupStartTime] =
    React.useState();
  const [breakfastPickupEndTime, setBreakfastPickupEndTime] = React.useState();
  const [lunchPickupStartTime, setLunchPickupStartTime] = React.useState();
  const [lunchPickupEndTime, setLunchPickupEndTime] = React.useState();
  const [dinnerPickupStartTime, setDinnerPickupStartTime] = React.useState();
  const [dinnerPickupEndTime, setDinnerPickupEndTime] = React.useState();

  async function GetCafeTiming() {
    await makeGetRequest("starcafe/getcafetiming/" + null + "/Pickup")
      .then((res) => {
        if (res.data.data) {
          // setShowLoader(true);
          console.log(res.data.data);
          for (var i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].service_type == "Breakfast") {
              setBreakfastPickupStartTime(res.data.data[i].starttime);
              setBreakfastPickupEndTime(res.data.data[i].endtime);
            } else if (res.data.data[i].service_type == "Lunch") {
              setLunchPickupStartTime(res.data.data[i].starttime);
              setLunchPickupEndTime(res.data.data[i].endtime);
            } else if (res.data.data[i].service_type == "Dinner") {
              setDinnerPickupStartTime(res.data.data[i].starttime);
              setDinnerPickupEndTime(res.data.data[i].endtime);
            }
          }
          setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  }

  async function handleChangeSearch(searchString) {
    setSearchSting(searchString);
    await getAllLocalEmployeeOrderList(
      searchString,
      orderType,
      fromDate,
      toDate
    );
  }

  async function getAllLocalEmployeeOrderList(
    searchString,
    orderType,
    fromDate,
    toDate
  ) {
    if (!searchString) searchString = null;
    setOrderType(orderType);
    await makeGetRequest(
      "starcafe/getlocalemployeeorder/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        searchString +
        "/" +
        offsetvalue +
        "/" +
        itemsPerPage +
        "/" +
        orderType +
        "/" +
        fromDate +
        "/" +
        toDate
    )
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.length > 0) {
            setEmployeeList(response.data.data);
            setTotalItems(response.data.data.totalCount);
            console.log(response.data.data);
          } else setEmployeeList([]);
          setUpload(!upload);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  const handlePageChange = (e, page) => {
    let offsetvalue = (page - 1) * itemsPerPage;
    setoffsetvalue(offsetvalue);
    setPageNo(page);
  };

  function handleChangeSortBy() {
    // alert("In ASC")
    setSortColumnName("first_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getAllLocalEmployeeOrderList(searchString, orderType, fromDate, toDate);
  }

  const [open3, setOpen3] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const descriptionElementRef = React.useRef(null);

  async function sendNotification(title, message, employee_id) {
    let inputData = {
      title: title,
      message: message,
      resident_id: employee_id,
    };

    makePostRequest("employee/notificationtoadmin", inputData)
      .then((response) => {})
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  }

  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  async function handleChangeFromDate(e) {
    console.log(e);
    console.log(moment(e).utc().format("YYYY-MM-DD"));
    setFromDate(moment(e).utc().format("YYYY-MM-DD"));
  }

  async function handleChangeFromDate(e) {
    console.log(e);
    console.log(e.format("YYYY-MM-DD"));
    setFromDate(e.format("YYYY-MM-DD"));
    await getAllLocalEmployeeOrderList(
      searchString,
      orderType,
      e.format("YYYY-MM-DD"),
      moment().utc().format("YYYY-MM-DD")
    );
  }

  // async function getEmployeesListOnLoad() {
  //   setShowLoader(true);
  //   await getAllEmployeeList(null);
  //   setShowLoader(false);
  // }

  const [timer, setTimer] = React.useState();
  const timeoutVal = 10;
  const inputRef = useRef(null);

  function handleKeyUp(e) {
    window.clearTimeout(timer); // prevent errant multiple timeouts from being generated
    setTimer(
      window.setTimeout(() => {
        console.log(e.target.value);
        if (e.target.value != "") handleScan(e.target.value);
      }, timeoutVal)
    );
  }

  function handleKeyPress(e) {
    window.clearTimeout(timer);
    console.log("Typing...");
  }

  function setFocus() {
    inputRef.current.focus();
  }

  const [timeFormat, setTimeFormat] = React.useState("hh:mm:ss");
  const handleScan = async (employeeId) => {
    document.getElementById("scanText").value = "";

    await GetEmployeeDetails(employeeId);
    setOpenAddDamageCost(true);
    setScroll("body");
    // setOpenScan(true);
    setScroll("body");
  };

  const[todaysOrders,setTodaysOrders]=React.useState([]);
  async function GetEmployeeDetails(employeeId) {
  
    await makeGetRequest("starcafe/getlocalemporderbyid/" + employeeId+'/'+moment().utc().format("YYYY-MM-DD"))
      .then((response) => {
        if (response.data.data) {
          console.log(response.data.data);
          setTodaysOrders(response.data.data)
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
        setShowLoader(false);
      });

   await makeGetRequest("employee/getemployee/" + employeeId)
      .then((response) => {
        if (response.data) {
          console.log(response.data.data[0]);
          if (response.data.data[0].department_id) {
            setEmployeeObj(response.data.data[0]);
          } else {
            swal(
              "Update basic profile",
              "Please update basic profile info",
              "warning",
              { timer: 4000, buttons: false }
            );
            navigate("/EditEmployee/" + response.data.data[0].employee_id);
          }
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
        setShowLoader(false);
      });
  }

  async function InsertLocalEmployeeOrder(ordertype) {
    let isScanOrder = false;
    console.log(ordertype);
    console.log(breakfastPickupStartTime);
    console.log(breakfastPickupEndTime);
    console.log(moment());
    if (
      ordertype == "Breakfast" &&
      moment().isBetween(
        moment(breakfastPickupStartTime, timeFormat),
        moment(breakfastPickupEndTime, timeFormat)
      )
    ) {
      console.log("In If");
      isScanOrder = true;
    } else if (
      ordertype == "Lunch" &&
      moment().isBetween(lunchPickupStartTime, lunchPickupEndTime)
    ) {
      isScanOrder = true;
    } else if (
      ordertype == "Dinner" &&
      moment().isBetween(dinnerPickupStartTime, dinnerPickupEndTime)
    ) {
      isScanOrder = true;
    }

    if (isScanOrder == true) {
      // categoriesList.find((o) => o.isoncafepriority === true);
      let inputData = {
        employee_id: employeeObj.employee_id,
        order_type: ordertype,
        pickup_time: moment(),
      };

      await makePostRequest("starcafe/insertlocalemployeeorder", inputData)
        .then((response) => {
          if (response.data.data) {
            console.log("Data ", response.data.data);
            setOpenAddDamageCost(false);
            setFocus();
          }
          setShowLoader(false);
        })
        .catch((err) => {
          setShowLoader(false);
        });
    } else {
      swal("Not in a give time range");
    }
  }

  React.useEffect(() => {
    localStorage.setItem("selectedModule", "Employees");
    GetCafeTiming();
    getAllLocalEmployeeOrderList(null, orderType, null, null);
    // getEmployeesListOnLoad();
  }, [pageNo]);

  // Employee Order Scan Details
  const [AddDamageCost, setOpenAddDamageCost] = React.useState(false);

  const handleClickOpenScanner = (scrollType) => () => {
    setOpenAddDamageCost(true);
    setScroll(scrollType);
  };

  const closeAddDamageCost = () => {
    setOpenAddDamageCost(false);
  };
  // End

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1>Employees List</h1>
              <div
                className="openScanOuter"
                style={{ textAlign: "center", position: "relative" }}
              >
                <input
                  type="text"
                  id="scanText"
                  ref={inputRef}
                  autoFocus
                  onKeyUp={handleKeyUp}
                  onChange={handleKeyPress}
                  // className="qrscantextbox"
                  // onKeyUp={handleKeyUp}
                  // on
                />
                {/* <input  type="text" id="scanText" value={scanTextVal} autoFocus/> */}
              </div>
              <div className="employeeFilter">
                <div className="scanEmplSearch">
                  <div className="pulsOut" onClick={() => setFocus()}>
                    <Tooltip title="Scan QRCode" arrow placement="top">
                      <Button className="pulsingBt">
                        <QrCodeScannerIcon /> Scan Order
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="starCafeTabMain EmployeeOrderScanForTab">
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChangeTb}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label="All"
                      {...a11yProps(0)}
                      onClick={() =>
                        getAllLocalEmployeeOrderList(
                          searchString,
                          null,
                          null,
                          null
                        )
                      }
                    />
                    <Tab
                      label="Breakfast"
                      {...a11yProps(1)}
                      onClick={() =>
                        getAllLocalEmployeeOrderList(
                          searchString,
                          "Breakfast",
                          null,
                          null
                        )
                      }
                    />
                    <Tab
                      label="Lunch"
                      {...a11yProps(2)}
                      onClick={() =>
                        getAllLocalEmployeeOrderList(
                          searchString,
                          "Lunch",
                          null,
                          null
                        )
                      }
                    />
                    <Tab
                      label="Dinner"
                      {...a11yProps(3)}
                      onClick={() =>
                        getAllLocalEmployeeOrderList(
                          searchString,
                          "Dinner",
                          null,
                          null
                        )
                      }
                    />
                  </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0}>
                  <div className="starCafeTabInner orderSumTbOnly ordEmploScanMain ">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <div className="visitorSearch scanEmplSearch">
                          <div className="prodFilter">
                            <input
                              className="prodSearchInpt"
                              placeholder="Search"
                              onChange={(e) =>
                                handleChangeSearch(e.target.value)
                              }
                            />
                          </div>
                          <div className="sortingIcoOut">
                            <ToggleButton
                              value="check"
                              selected={selected}
                              onChange={() => {
                                handleChangeSortBy();
                              }}
                            >
                              <ImportExportIcon
                                className="visitorFilterIco"
                                aria-haspopup="true"
                                // onChange={()=>handleChangeSortBy()}
                              />
                            </ToggleButton>
                          </div>
                          <div className="fromDToDMain">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  onChange={(e) => handleChangeFromDate(e)}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            <b>To</b>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="serviceistOuter">
                          {employeeList ? (
                            <>
                              {employeeList.map((empObj) => (
                                <div className="mainListDiv">
                                  <div className="mainListCont">
                                    <h1
                                      onClick={() =>
                                        navigate(
                                          "/EmployeesDetails/" +
                                            empObj.employee_id
                                        )
                                      }
                                    >
                                      <Tooltip
                                        title="Employee Full name here"
                                        arrow
                                        placement="top"
                                      >
                                        {empObj.first_name} {empObj.last_name}
                                      </Tooltip>
                                    </h1>
                                    <p>{empObj.email_id}</p>
                                    <span className="mainListContact">
                                      {empObj.contact_no}
                                    </span>
                                  </div>

                                  <div className="mainListCont mainListInrCont">
                                    <label>Employee Code</label>
                                    <span className="mainListSubTx">
                                      {empObj.employee_code}
                                    </span>
                                  </div>

                                  <div className="mainListCont mainListInrCont">
                                    <label>Order type</label>
                                    <span className="mainListSubTx">
                                     {empObj.order_type}
                                    </span>
                                  </div>

                                  <div className="mainListCont mainListInrCont">
                                    <label>Meal Pickup Date & Time</label>
                                    <span className="mainListSubTx">
                                      {moment(empObj.pickup_time).format(
                                        "DD/MM/YYYY hh:mm a"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className="paginationOuterMain"
                      >
                        <Pagination
                          count={Math.ceil(totalItems / itemsPerPage)}
                          page={pageNo}
                          onChange={handlePageChange}
                          variant="outlined"
                          shape="rounded"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                  <div className="starCafeTabInner orderSumTbOnly ordEmploScanMain lunchFtColr">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <div className="visitorSearch scanEmplSearch">
                          <div className="prodFilter">
                            <input
                              className="prodSearchInpt"
                              placeholder="Search"
                              onChange={(e) =>
                                handleChangeSearch(e.target.value)
                              }
                            />
                          </div>
                          <div className="sortingIcoOut">
                            <ToggleButton
                              value="check"
                              selected={selected}
                              onChange={() => {
                                handleChangeSortBy();
                              }}
                            >
                              <ImportExportIcon
                                className="visitorFilterIco"
                                aria-haspopup="true"
                                // onChange={()=>handleChangeSortBy()}
                              />
                            </ToggleButton>
                          </div>
                          <div className="fromDToDMain">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker />
                              </DemoContainer>
                            </LocalizationProvider>
                            <b>To</b>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="serviceistOuter">
                          {employeeList ? (
                            <>
                              {employeeList.map((empObj) => (
                                <div className="mainListDiv">
                                  <div className="mainListCont">
                                    <h1
                                      onClick={() =>
                                        navigate(
                                          "/EmployeesDetails/" +
                                            empObj.employee_id
                                        )
                                      }
                                    >
                                      <Tooltip
                                        title="Employee Full name here"
                                        arrow
                                        placement="top"
                                      >
                                        {empObj.first_name} {empObj.last_name}
                                      </Tooltip>
                                    </h1>
                                    <p>{empObj.email_id}</p>
                                    <span className="mainListContact">
                                      {empObj.contact_no}
                                    </span>
                                  </div>

                                  <div className="mainListCont mainListInrCont">
                                    <label>Employee Code</label>
                                    <span className="mainListSubTx">
                                      {empObj.employee_code}
                                    </span>
                                  </div>

                                  <div className="mainListCont mainListInrCont">
                                    <label>Order type</label>
                                    <span className="mainListSubTx">
                                    {empObj.order_type}
                                    </span>
                                  </div>

                                  <div className="mainListCont mainListInrCont">
                                    <label>Meal Pickup Date & Time</label>
                                    <span className="mainListSubTx">
                                      {moment(empObj.pickup_time).format(
                                        "DD/MM/YYYY hh:mm a"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className="paginationOuterMain"
                      >
                        <Pagination
                          count={Math.ceil(totalItems / itemsPerPage)}
                          page={pageNo}
                          onChange={handlePageChange}
                          variant="outlined"
                          shape="rounded"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={2}>
                  <div className="starCafeTabInner orderSumTbOnly ordEmploScanMain dinnerFtColr">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <div className="visitorSearch scanEmplSearch">
                          <div className="prodFilter">
                            <input
                              className="prodSearchInpt"
                              placeholder="Search"
                              onChange={(e) =>
                                handleChangeSearch(e.target.value)
                              }
                            />
                          </div>
                          <div className="sortingIcoOut">
                            <ToggleButton
                              value="check"
                              selected={selected}
                              onChange={() => {
                                handleChangeSortBy();
                              }}
                            >
                              <ImportExportIcon
                                className="visitorFilterIco"
                                aria-haspopup="true"
                                // onChange={()=>handleChangeSortBy()}
                              />
                            </ToggleButton>
                          </div>
                          <div className="fromDToDMain">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker />
                              </DemoContainer>
                            </LocalizationProvider>
                            <b>To</b>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="serviceistOuter">
                          {employeeList ? (
                            <>
                              {employeeList.map((empObj) => (
                                <div className="mainListDiv">
                                  <div className="mainListCont">
                                    <h1
                                      onClick={() =>
                                        navigate(
                                          "/EmployeesDetails/" +
                                            empObj.employee_id
                                        )
                                      }
                                    >
                                      <Tooltip
                                        title="Employee Full name here"
                                        arrow
                                        placement="top"
                                      >
                                        {empObj.first_name} {empObj.last_name}
                                      </Tooltip>
                                    </h1>
                                    <p>{empObj.email_id}</p>
                                    <span className="mainListContact">
                                      {empObj.contact_no}
                                    </span>
                                  </div>

                                  <div className="mainListCont mainListInrCont">
                                    <label>Employee Code</label>
                                    <span className="mainListSubTx">
                                      {empObj.employee_code}
                                    </span>
                                  </div>

                                  <div className="mainListCont mainListInrCont">
                                    <label>Order type</label>
                                    <span className="mainListSubTx">
                                    {empObj.order_type}
                                    </span>
                                  </div>

                                  <div className="mainListCont mainListInrCont">
                                    <label>Meal Pickup Date & Time</label>
                                    <span className="mainListSubTx">
                                      {moment(empObj.pickup_time).format(
                                        "DD/MM/YYYY hh:mm a"
                                      )}
                                    </span>
                                  </div>

                                  {/* <div className="mainListCont mainListInrCont">
                        <div className="actionBtnOut">
                          <Tooltip
                            title="Reload Star"
                            arrow
                            placement="top"
                            onClick={handleClickOpen3("body", empObj)}
                          >
                            <img src={exchaneBlack} className="reLIm" />
                          </Tooltip>
                          {JSON.parse(
                            localStorage.getItem("accessRights") != null
                          ) ? (
                            <>
                              {JSON.parse(
                                localStorage.getItem("accessRights")
                              ).map((module) => (
                                <>
                                  {module.moduleName ==
                                    "EmployeeModification" &&
                                  module.status == true ? (
                                    <>
                                      <Switch
                                        {...label}
                                        checked={empObj.status}
                                        className="swicthMain"
                                        onChange={(event) =>
                                          handleChange(
                                            event,
                                            empObj.employee_id
                                          )
                                        }
                                      />

                                      <Tooltip
                                        title="Edit"
                                        arrow
                                        placement="top"
                                      >
                                    
                                        <IconButton
                                          onClick={() =>
                                            editEmployee(empObj.employee_id)
                                          }
                                        >
                                          <img src={editIco} />
                                        </IconButton>
                                      </Tooltip>

                                      <Tooltip
                                        title="Delete"
                                        arrow
                                        placement="top"
                                      >
                                        <IconButton
                                          onClick={() =>
                                            deleteEmployee(empObj.employee_id)
                                          }
                                        >
                                          <img src={deletIco} />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div> */}
                                </div>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className="paginationOuterMain"
                      >
                        <Pagination
                          count={Math.ceil(totalItems / itemsPerPage)}
                          page={pageNo}
                          onChange={handlePageChange}
                          variant="outlined"
                          shape="rounded"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={3}>
                  <div className="starCafeTabInner orderSumTbOnly ordEmploScanMain breakFtColr">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <div className="visitorSearch scanEmplSearch">
                          <div className="prodFilter">
                            <input
                              className="prodSearchInpt"
                              placeholder="Search"
                              onChange={(e) =>
                                handleChangeSearch(e.target.value)
                              }
                            />
                          </div>
                          <div className="sortingIcoOut">
                            <ToggleButton
                              value="check"
                              selected={selected}
                              onChange={() => {
                                handleChangeSortBy();
                              }}
                            >
                              <ImportExportIcon
                                className="visitorFilterIco"
                                aria-haspopup="true"
                                // onChange={()=>handleChangeSortBy()}
                              />
                            </ToggleButton>
                          </div>
                          <div className="fromDToDMain">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  onChange={(e) => handleChangeFromDate(e)}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            <b>To</b>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="serviceistOuter">
                          {employeeList ? (
                            <>
                              {employeeList.map((empObj) => (
                                <div className="mainListDiv">
                                  <div className="mainListCont">
                                    <h1
                                      onClick={() =>
                                        navigate(
                                          "/EmployeesDetails/" +
                                            empObj.employee_id
                                        )
                                      }
                                    >
                                      <Tooltip
                                        title="Employee Full name here"
                                        arrow
                                        placement="top"
                                      >
                                        {empObj.first_name} {empObj.last_name}
                                      </Tooltip>
                                    </h1>
                                    <p>{empObj.email_id}</p>
                                    <span className="mainListContact">
                                      {empObj.contact_no}
                                    </span>
                                  </div>

                                  <div className="mainListCont mainListInrCont">
                                    <label>Employee Code</label>
                                    <span className="mainListSubTx">
                                      {empObj.employee_code}
                                    </span>
                                  </div>

                                  <div className="mainListCont mainListInrCont">
                                    <label>Order type</label>
                                    <span className="mainListSubTx">
                                    {empObj.order_type}
                                    </span>
                                  </div>

                                  <div className="mainListCont mainListInrCont">
                                    <label>Meal Pickup Date & Time</label>
                                    <span className="mainListSubTx">
                                      {moment(empObj.pickup_time).format(
                                        "DD/MM/YYYY hh:mm a"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className="paginationOuterMain"
                      >
                        <Pagination
                          count={Math.ceil(totalItems / itemsPerPage)}
                          page={pageNo}
                          onChange={handlePageChange}
                          variant="outlined"
                          shape="rounded"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </CustomTabPanel>
              </Box>
            </div>
          </Grid>
        </Grid>
      </div>

      {/* Employee Order Scan Details*/}
      <div>
        <Dialog
          open={AddDamageCost}
          // onClose={closeCartonOrSingle}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Employee Details
            <CloseIcon className="modalCloseBt" onClick={closeAddDamageCost} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv modEmploDetails">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <h1 class="basicHeading">Basic Details</h1>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="mainListCont mainListInrCont">
                        <label>First Name</label>
                        <span className="mainListSubTx">
                          <span>{employeeObj.first_name}</span>
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="mainListCont mainListInrCont">
                        <label>Last Name</label>
                        <span className="mainListSubTx">
                          <span>{employeeObj.last_name}</span>
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="mainListCont mainListInrCont">
                        <label>Email Id</label>
                        <span className="mainListSubTx">
                          <span>{employeeObj.email_id}</span>
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="mainListCont mainListInrCont">
                        <label>Mobile Number</label>
                        <span className="mainListSubTx">
                          <span>{employeeObj.contact_no}</span>
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <hr className="devider" />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <h1 class="basicHeading">Employment Details</h1>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="mainListCont mainListInrCont">
                        <label>Designation</label>
                        <span className="mainListSubTx">
                          <span>{employeeObj.designation_name}</span>
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="mainListCont mainListInrCont">
                        <label>Department</label>
                        <span className="mainListSubTx">
                          <span>{employeeObj.department_name}</span>
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="mainListCont mainListInrCont">
                        <label>Date of Joinging</label>
                        <span className="mainListSubTx">
                          <span>
                            {moment(employeeObj.date_of_joining).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="mainListCont mainListInrCont">
                        <label>Passport Number</label>
                        <span className="mainListSubTx">
                          <span>{employeeObj.passport_no}</span>
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="mainListCont mainListInrCont">
                        <label>Visa Number</label>
                        <span className="mainListSubTx">
                          <span>{employeeObj.visa_no}</span>
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="mainListCont mainListInrCont">
                        <label>Employee Code</label>
                        <span className="mainListSubTx">
                          <span>{employeeObj.employee_code}</span>
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="mainListCont mainListInrCont">
                        <label>Fixed Salary (INR)</label>
                        <span className="mainListSubTx">
                          <span>*****</span>
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="mainListCont mainListInrCont">
                        <label>Balance Salary (INR)</label>
                        <span className="mainListSubTx">
                          <span>*****</span>
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <span className="lineBtns">
                        {employeeObj.access_rights ? (
                          <>
                            {employeeObj.access_rights.find(
                              (o) =>
                                o.moduleName === "Breakfast" && o.status == true
                            ) ? (
                              todaysOrders.find( (o) =>o.order_type === "Breakfast")?<></>:
                              <Button
                                className="modalBtSmallWidth activeBreakScan"
                                onClick={() =>
                                  InsertLocalEmployeeOrder("Breakfast")
                                }
                              >
                                Breakfast
                              </Button>
                            ) : (
                              <></>
                            )}
                            {employeeObj.access_rights.find(
                              (o) =>
                                o.moduleName === "Lunch" && o.status == true
                            ) ? (
                              todaysOrders.find( (o) =>o.order_type === "Lunch")?
                              <></>:
                              <Button
                                className="modalBtSmallWidth activeLunchScan"
                                onClick={() =>
                                  InsertLocalEmployeeOrder("Lunch")
                                }
                              >
                                Lunch
                              </Button> 
                            ) : (
                              <></>
                            )}
                            {employeeObj.access_rights.find(
                              (o) =>
                                o.moduleName === "Dinner" && o.status == true
                            ) ? (
                              todaysOrders.find( (o) =>o.order_type === "Dinner")?
                              <></>:
                              <Button
                                className="modalBtSmallWidth activeDinnerScan"
                                onClick={() =>
                                  InsertLocalEmployeeOrder("Dinner")
                                }
                              >
                                Dinner
                              </Button>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <>No Meal Configured</>
                        )}
                      </span>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default EmployeeOrderScan;
