import React from 'react'
import TopHeader from '../SyatemComponents/TopHeader'
import Clock from '../assets/images/Clock.svg'

function ClockInThankYou() {
    return (
        <>
            <div className="mobileCssMain">
                <div className="wrapper">
                    <TopHeader />
                    <div className="container">
                        <div className="thankOuter">
                            <div className="clockImg">
                                <img src={Clock} />
                            </div>
                            <h6>Thank you</h6>
                            <h2>Employee Name</h2>
                            <p>You have successfully clocked in at</p>
                            <h3>09:00am</h3>
                            <h4>Have a great day at work!</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClockInThankYou