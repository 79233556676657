import React from "react";
import Tooltip from "@mui/material/Tooltip";

function TextError(props) {
  return (
    <Tooltip
      title="value must be greater than low stock limit"
      arrow
      placement="top"
    >
      <div className="errorSt">{props.children}</div>
    </Tooltip>
  );
}

export default TextError;
