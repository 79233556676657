import React, { useEffect } from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { makePostRequest, makeGetRequest } from "../../../utils/utils";
import { CompareArrows } from "@mui/icons-material";
import noLogo from "../../../assets/images/noLogo.jpg";
import editIco from "../../../assets/images/edit-icon.svg";
import loaderImg from "../../../assets/images/logo.png";

function SuperAdminDashboard() {
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = React.useState(false);
  const [companyList, setCompanyList] = React.useState([]);
  const [upload, setUpload] = React.useState(false);

  async function getAllCompanyList() {
    setShowLoader(true);
    await makeGetRequest("/superadmin/getcompanylist")
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.length > 0) 
              setCompanyList(response.data.data);
          else setCompanyList([]);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  React.useEffect(() => {
    getAllCompanyList();
  }, []);

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1></h1>
              {companyList.length > 0 ? (
                <div className="employeeFilter">
                  <Button
                    className="pageTopMainBt"
                    onClick={() => navigate("/AddCompanyAdmin")}
                  >
                    Add Company Admin
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div
              className="companyDiv addCompanyDiv"
              onClick={() => navigate("/AddCompany")}
            >
              <div className="companyAddIco">
                <AddIcon />
              </div>
              <h1>Add Company</h1>
            </div>

            {companyList ? (
              <>
                {companyList.map((option) => (
                  <div className="companyDiv">
                    <div className="compLogImg">
                      {option.company_logo ? (
                        <img src={option.company_logo} />
                      ) : (
                        <img src={noLogo} />
                      )}
                    </div>
                    <h1>{option.company_name}</h1>

                    <img
                      src={editIco}
                      className="comEdiIco"
                      onClick={() =>
                        navigate("/EditCompany/" + option.company_id)
                      }
                    />
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </div>

      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default SuperAdminDashboard;
