import React from 'react'
import ClockInLogo from "../assets/images/ClockInLogo.png";


function TopHeader() {
  return (
    <>
      <div className="mobileCssMain">
          <div className="wrapper">
            <div className="topheaderMain">
                <div>
                <img src={ClockInLogo} />
                </div>
            </div>
          </div>
      </div>
    </>
  )
}

export default TopHeader