
import { Button, Grid, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../components/FormikControl";
import swal from "sweetalert";
import { makePostRequest } from "../../utils/utils";
import LockResetIcon from "@mui/icons-material/LockReset";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";


function ResetNewPassword() {

  const navigate = useNavigate();
  const [showLoader, setShowLoader] = React.useState(false);
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const initialValues = {
    email_id: "",
    new_password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object({

    email_id: Yup.string().required("Required"),
    new_password: Yup.string()
      .matches(
        /^(?=.*[0-9])(?=.*[- ?!@#$%^&*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9- ?!@#$%^&*\/\\]{8,30}$/,
        "Password should be minimum 8 character long and a combination of uppercase letters, lowercase letters, numbers, and symbols!"
      ).required("Required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Password not matched")
      .required("Required"),
  });

  const changePassword = (values) => {

    if (values.new_password == values.confirm_password) {

      let inputData = {
        email_id: values.email_id,
        password: values.confirm_password,
      };

      makePostRequest("employee/resetPassword", inputData)
        .then((response) => {
          if (response.data.data) {
            console.log(response.data.data);
            swal(
              "Password changed successfully",
              "Please login again",
              "success",
              {
                buttons: false,
                timer: 3000,
              }
            );
            localStorage.removeItem("forgotUserId");
            navigate("/LoginMobile");
            setShowLoader(false);
          }
        })
        .catch((err) => {
          swal({
            title: "Error",
            text: err.response.data.message,
            icon: "warning",
          });
          setShowLoader(false);
        });
    } else {
      // alert(
      //   "Password and Confirm Password Does not Match."
      // );
      swal({
        title: "Error",
        text: "Password and Confirm Password Does not Match.",
        icon: "warning",
        buttons: ["Ok"],
      });
    }
  };

  return (
    <>
      <div className="mobileCssMain">
        <div className="container">
          <div className="wrapper">
            <div className="loginMain">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <h1 className="resrPas">
                    <LockResetIcon /> Reset Your Password
                  </h1>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="loginCont">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={changePassword}
                    >
                      {(formik) => (
                        <Form>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              <FormikControl
                                control="input"
                                type="email_id"
                                label="Email Id"
                                labelClass="loginLable"
                                name="email_id"
                                className="loginInput"
                                placeholder="Email"
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className="forPassEye posiMins eyeWithError">
                                <FormikControl
                                  control="input"
                                  type={showPassword ? "text" : "password"}
                                  label="New Password"
                                  labelClass="loginLable"
                                  name="new_password"
                                  className="loginInput"
                                  placeholder="New Password"
                                />
                                {showPassword == true ? (
                                  <RemoveRedEyeIcon
                                    onClick={() => setShowPassword(false)}
                                  />
                                ) : (
                                  <VisibilityOffIcon
                                    onClick={() => setShowPassword(true)}
                                  />
                                )}
                              </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className="forPassEye posiMins eyeWithError">
                                <FormikControl
                                  control="input"
                                  type={showConfirmPassword ? "text" : "password"}
                                  label="Confirm Password"
                                  labelClass="loginLable"
                                  name="confirm_password"
                                  className="loginInput"
                                  placeholder="Confirm Password"
                                />
                                {showConfirmPassword == true ? (
                                  <RemoveRedEyeIcon
                                    onClick={() => setShowConfirmPassword(false)}
                                  />
                                ) : (
                                  <VisibilityOffIcon
                                    onClick={() => setShowConfirmPassword(true)}
                                  />
                                )}
                              </div>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={12}
                              style={{ textAlign: "centers" }}
                            >
                              <Button className="loginBt" type="submit">
                                Reset Password
                              </Button>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={12}
                              style={{ textAlign: "center" }}
                            >
                              <p className="donttHavAc">
                                <b onClick={() => navigate("/LoginMobile")}>
                                  Login Here
                                </b>
                              </p>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetNewPassword;
