import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarLogoSmall from "../assets/images/StarLogoSmall.svg";
import QRCodeComponent from "../componentsMobile/QRCodeComponent";
import { makeGetRequest } from "../../utils/utils";
import moment from "moment";
import IconButton from '@mui/material/IconButton';

function ReadyOrderDetails() {

  const navigate = useNavigate();

  const location = useLocation();
  const { id } = location.state || {};
  const [items, setItems] = useState([]);
  const [qrdata, setQrdata] = useState([]);
  const [productList, setProductList] = useState([]);

  async function getOrder() {

    let arr = [];

    await makeGetRequest("starcafe/getorder/" + id)
      .then((response) => {
        if (response.data.data) {
          setItems(response.data.data);
          setQrdata(response.data.data[0].toString());
          if (response.data.data[0].orderdetails.length > 0) {
            for (
              let i = 0;
              i < response.data.data[0].orderdetails.length;
              i++
            ) {
              arr.push(JSON.parse(response.data.data[0].orderdetails[i]));
            }
            setProductList(arr);
          } else {
            setProductList([]);
          }
        } else {
          setItems([]);
          setProductList([]);
        }
      })
      .catch((err) => {
        setItems([]);
        setProductList([]);
      });
  }

  useEffect(() => {
    getOrder();
  }, []);

  return (
    <>
      <div className="mobileCssMain">
        <div className="container">
          <div className="wrapper">
            <div className="fixedBottomPadding">
              <div className="headerData">
                <Button onClick={() => navigate("/DashboardPage")}>
                <IconButton className="TouchableIconBtn"> <ArrowBackIosIcon />
                </IconButton>
                </Button>
                <div className="logo">
                  <img src={StarLogoSmall} />
                </div>
                <h5>Star Labels</h5>
              </div>

              {items.map((item, index) => (
                <div className="ActiveOrderDetailsData">
                  <div className="orderDetailsBox">
                    <div className="orderSummary orderSummaryOrderDetails">
                      <h4 className="newUserNm">
                        <b>{item.first_name + " " + item.last_name}</b>
                      </h4>
                      <div className="orderSummaryDetailsTop">
                        <div className="OrderIDData">
                          <h4>Order #{item.order_id}</h4>
                          <p>{moment(item.updated_at).format(
                            "YYYY-MM-DD HH:mm"
                          )}</p>
                        </div>
                        <div className="pickUp pickUpOrder">
                          <p>Pickup Location</p>
                          <h4>Star Cafe</h4>
                        </div>
                      </div>
                      <h4>Order Details</h4>
                      <div className="summaryData">
                        <div className="summaryItem">
                          <h6>Order ID</h6>
                          <h3>#{item.order_id}</h3>
                        </div>
                        <div className="summaryItem">
                          <h6>Total Item</h6>
                          <h3>{item.orderdetails.length}</h3>
                        </div>
                        <div className="summaryItem">
                          <h6>Stars Spent</h6>
                          <h3 className="blue">{item.totalstarspend}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="orderTiming orderDetailsPickupSlots">
                    <h4>Pickup Slot</h4>
                    <h2>6pm to 9pm</h2>
                  </div>
                </div>
              ))}

              <div className="OrderDetailsQRCode">
                <h3>Scan At Pickup Location</h3>
                {qrdata ? <QRCodeComponent data={JSON.stringify({orderId:qrdata,serviceType:'Starcafe'})} /> : null}
              </div>

              <div>
                {productList.map((item, index) => (
                  <div className="orderNotiDiv specialMenu">
                    <div>
                      <div className="orderDetailsProd">
                        {item.menu_name !== undefined ? (
                          <h3>{item.menu_name}</h3>
                        ) : (
                          <h3>{item.category_name}</h3>
                        )}
                        <p>
                          {item.weight}
                          {item.weight_unit}
                        </p>
                      </div>
                      {item.menu_id !== undefined ? (
                        <p>Product Id #{item.menu_id}</p>
                      ) : (
                        <p>Product Id #{item.subcategory_id}</p>
                      )}

                      <div className="starPrice">
                        <StarIcon className="starIcon" />
                        {item.price_in_stars !== undefined ? (
                          <h5> {item.price_in_stars} </h5>
                        ) : (
                          <h5> {item.Star_price} </h5>
                        )}
                      </div>
                    </div>
                    <div className="prodQuantity">
                      <p>
                        <p className="qty">{item.count}</p>Quantity
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              {/* <div className="orderNotiDiv specialMenu">
                <div>
                  <div className="orderDetailsProd">
                    <h3>Biscuits</h3>
                    <p>150Grams</p>
                  </div>
                  <p>Product Id #1001</p>
                  <div className="starPrice">
                    <StarIcon className="starIcon" />
                    <h5> 20 </h5>
                  </div>
                </div>
                <div className="prodQuantity">
                  <p>
                    <p className="qty">2</p>Quantity
                  </p>
                </div>
              </div>
              <div className="orderNotiDiv specialMenu">
                <div>
                  <div className="orderDetailsProd">
                    <h3>Mini Jam</h3>
                    <p>150Grams</p>
                  </div>
                  <p>Product Id #1001</p>
                  <div className="starPrice">
                    <StarIcon className="starIcon" />
                    <h5> 20 </h5>
                  </div>
                </div>
                <div className="prodQuantity">
                  <p>
                    <p className="qty">2</p>Quantity
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReadyOrderDetails;
