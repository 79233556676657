// import React from "react";
import React, { useState, useEffect } from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import tabImg1 from "../../../assets/images/tabImg1.svg";
import tabImg2 from "../../../assets/images/tabImg2.svg";
import tabImg3 from "../../../assets/images/tabImg3.svg";
import tabImg4 from "../../../assets/images/tabImg4.svg";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import editIco from "../../../assets/images/edit-icon.svg";
import deletIco from "../../../assets/images/delete-icon.svg";
import StarIcon from "@mui/icons-material/Star";
import lockimg from "../../../assets/images/lock-outline.svg";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";
import Surprise from "../../../assets/images/Surprise.svg";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import productImg from "../../../assets/images/productImg.svg";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Pagination from "@mui/material/Pagination";
import ImportExportIcon from "@mui/icons-material/ImportExport";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";

// Formik and Api Call
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import {
  makePostRequest,
  makeGetRequest,
  uploadFileRequest,
} from "../../../utils/utils";
import swal from "sweetalert";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import StarCafeTabs from "./StarCafeTabs";
// End

import FormControl from "@mui/material/FormControl";

// For Multi Select
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
// For Multi Select End

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ServicesConfiguration() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open9 = Boolean(anchorEl);

  
  const [subcategoryData, setSubcategoryData] = useState([]);
  const [duplicateFoodErrMsg, setDuplicateFoodErrMsg] = React.useState("");
  const [priceErrMsg, setPriceErrMsg] = React.useState("");
  const [categoriesId, setCategoriesId] = React.useState([]);
  const [price, setPrice] = useState(0);
  const [Star_price, setStarPrice] = useState(0);
  const [masterDollarAmount, setMasterDollarAmount] = React.useState(0);

  const [upload, setUpload] = useState(false);

  const [categoryListErrMsg, setCategoryListErrMsg] = useState("");
  const [categoryUnitErrMsg, setCategoryUnitErrMsg] = useState("");
  const [formValuesSUbCategory, setFormValuesSUbCategory] = useState(null);
  const [isEditSubCategory, setIsEditSubCategory] = useState(false);
  const [productData, setProductData] = useState([]);
  const [sortColumnName, setSortColumnName] = useState("created_at");
  const [imageErrMsg, setImageErrMsg] = React.useState("");
  const [uploadImg, setUploadImg] = React.useState([]);
  const [selected, setSelected] = React.useState(false);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [scrolltopdata, setscrolltopdata] = useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const [formValues, setFormValues] = React.useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [categoriesDropDown, setCategoriesDropDown] = useState([]);
  const [isEditCategory, setIsEditCategory] = useState(false);
  const [searchString, setSearchString] = useState(null);
  const [sortColumnNameCategory, setSortColumnNameCategory] = useState("created_at");
  const [sortBy, setSortBy] = React.useState("desc");
  const [pageNo, setPageNo] = React.useState(0);
  const [duplicateErrMsg, setDuplicateErrMsg] = React.useState("");
  const [priority, setPriority] = useState(false);



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose9 = () => {
    setAnchorEl(null);
  };

  const initialValuesAddProd = {
    product_name: "",
    // current_quantity: "",
    new_quantity: 0,
    wt_per_item: 0,
    price: 0,
    price_in_stars: 0,
    low_stock_limit: 0,
  };

  const validationSchemaAddProd = Yup.object({
    product_name: Yup.string().trim("White spaces not allow").strict(true).required("Required"),
    low_stock_limit: Yup.number().typeError("Invalid Number").required("Required"),
    new_quantity: Yup.number().typeError("Invalid Number").moreThan(Yup.ref("low_stock_limit"),"value must be greater than low stock limit").required("Required"),
    wt_per_item: Yup.number().typeError("Invalid Number").required("Required"),
    price: Yup.number().typeError("Invalid Number").required("Required"),
    price_in_stars: Yup.number().typeError("Invalid Number") .required("Required"),
  });

  const addOrUpdateProduct = async (values, { resetForm }) => {

    console.log(values);
    setCategoryListErrMsg("");
    setCategoryUnitErrMsg("");
    setDuplicateFoodErrMsg("");

    if (alignment) {
      if (priceErrMsg == "") {
        if (categoriesId) {
          if (isEditSubCategory) {
            let updateCategory = true;
            let dupRes = subcategoryData.find(
              (o) =>
                o.product_name.toLowerCase() ===
                values.product_name.toLowerCase()
            );

            if (dupRes) {
              if (values.product_id == dupRes.product_id) {
                updateCategory = true;
              } else updateCategory = false;
            } else updateCategory = true;

            if (updateCategory) {
             
              delete values.category_names;

              values.price_in_stars = parseInt(Star_price);
              values.product_pic = uploadImg;
              values.current_quantity = parseInt(values.new_quantity);
              values.price = parseInt(price);
              values.wt_per_item = parseInt(values.wt_per_item);
              values.new_quantity = parseInt(values.new_quantity);
              values.low_stock_limit = parseInt(values.low_stock_limit);
              values.item_unit = alignment;

              values.category_id = categoriesId;

            await makePostRequest("starmarket/updateproduct", values)
                .then((response) => {
                  if (response.data.data) {
                    console.log(response.data);
                    getProductList(null);

                    setIsEditSubCategory(false);
                    setAlignment("");
                    CloseAdProdCafeInventory();
                    setOpenAdProdCafeInventory(false);
                    setUploadImg([]);
                    setDuplicateFoodErrMsg("");
                    resetForm();
                  }
                })
                .catch((err) => {
                  console.log(err);
                  let errMsg = err.response.data.message;
                  if (err.response.status == 409) {
                    setDuplicateFoodErrMsg("Duplicate name");
                  }
                });
            } else setDuplicateFoodErrMsg("Duplicate name");
          } else {
            values.product_pic = uploadImg;
            values.category_id = categoriesId;
            values.current_quantity = parseInt(values.new_quantity);
            values.new_quantity = parseInt(values.new_quantity);
            values.wt_per_item = parseInt(values.wt_per_item);
            values.item_unit = alignment;

            values.price = parseInt(price);
            values.price_in_stars = parseInt(Star_price);
            values.low_stock_limit = parseInt(values.low_stock_limit);

            values.status = true;
             console.log(values);
         
          await  makePostRequest("starmarket/insertproduct", values)
              .then((response) => {
                if (response.data.data) {

                setAlignment("");
                CloseAdProdCafeInventory();
                setOpenAdProdCafeInventory(false);
                setUploadImg([]);
                setDuplicateFoodErrMsg("");
                resetForm();
                }
                
              })
              .catch((err) => {
                let errMsg = err.response.data.message;
                if (err.response.status == 409) {
                  setDuplicateFoodErrMsg("Duplicate name");
                }
              });
          }
        } else setCategoryListErrMsg("Please select product category");
      }
    } else {
      setCategoryUnitErrMsg("Please select weight unit");
    }
  };

  // Modal Main
  const [AdProdCafeInventory, setOpenAdProdCafeInventory] = useState(false);

  const handleClickOpenAdProdCafeInventory = (scrollType) => () => {
    setOpenAdProdCafeInventory(true);
    setScroll(scrollType);
  };

  const CloseAdProdCafeInventory = () => {
    setFormValuesSUbCategory(null);
    setIsEditSubCategory(false);
    setAlignment("");
    setAlignmentCategory("");
    setUploadImg([]);
    setDuplicateFoodErrMsg("");
    setCategoriesId([]);
    setPrice(0);
    setStarPrice(0);
    setOpenAdProdCafeInventory(false);


  };

  // const descriptionElementRef = React.useRef(null);

  const getProductList = async (searchStr) => {
    if (!searchStr) searchStr = null;
    // setCategoriesDropDown([]);
   await makeGetRequest(
      "starmarket/getproductsearch/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        searchStr +
        "/" +
        pageNo
    )
      .then((response) => {
        if (response.data) {
          setProductData(response.data.data);
          // setMenuObj(response.data.data);
          setSubcategoryData(response.data.data);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const deleteProduct = async (product_id) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
      timer: 3000,
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Product Deleted!",
          text: "Product is successfully deleted!",
          icon: "success",
          timer: 3000,
        }).then(function () {
          let inputdata = {
            product_id: product_id,
            is_deleted: true,
          };
          makePostRequest("starmarket/updateproduct", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                getProductList();
              }
            })
            .catch((err) => {
            });
        });
      } else {
        //swal("Cancelled", "Your Category is safe :)", "error");
      }
    });
  };

  // For Multi Select
  //  const [categoriesName, setCategoriesName] = React.useState([]);

  const handleChangeCategory = (event) => {
    const {
      target: { value },
    } = event;

    setCategoriesId(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setCategoryListErrMsg("");
    //  alert("value"+value);
  };
  // Multi Select End

  // For Multi Select
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

 
   const handleProductStatusChange = (event, product_id) => {
    let inputdata = {
      product_id: product_id,
      status: event.target.checked,
    };
    makePostRequest("starmarket/updateproduct", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          getProductList(null);
        }
      })
      .catch((err) => {
      });
  };

  // Edit SubCategory List Start
  const editSubCategory = (data) => {
    setFormValuesSUbCategory(data);
    setIsEditSubCategory(true);
    setOpenAdProdCafeInventory(true);
    setAlignment(data.item_unit);
    setPrice(data.price);
    setStarPrice(data.price_in_stars);
    setCategoriesId(data.category_id);
    setUploadImg(data.product_pic);

    // //handleClickOpenAdProdCafeInventory("body");
  };
  // Edit SubCategory List end

  const cancelSubCategory = () => {
    setFormValuesSUbCategory(null);
    setIsEditSubCategory(false);
    CloseAdProdCafeInventory();
  };

  const [AddInventory, setOpenAddInventory] = useState(false);
  const handleClickOpenAddInventory = (scrollType) => () => {
    setOpenAddInventory(true);
    setScroll(scrollType);
  };

  const closeInventory = () => {
    setOpenAddInventory(false);
  };

  function handleChangeSortBy() {
    //alert("In ASC")
    setSortColumnName("product_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    //setUpload(!upload);
    getProductList(null);
  }

 
  const initialValues = {
    category_name: "",
  };

  const validationSchema = Yup.object({
    category_name: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required"),
  });

  // const handleCheckValue = (value) => {
  //   setCategory_name(value);
  //   setUpload(!upload);
  // };
  // Check Duplicate Value End

  // Image Upload Start
  async function uploadImageOrFile(fileData) {
    var fileUrl = "";
    try {
      setShowLoader(true);
      const formData = new FormData();
      formData.append("image", fileData);
      await uploadFileRequest("superadmin/upload", formData).then(
        (response) => {
          fileUrl = response.data.imagepath;
        }
      );
      setShowLoader(false);
    } catch (error) {
    }
    return fileUrl;
  }

  async function validateImage(fileData) {
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i))
      return true;
    else return false;
  }

  async function uploadProfilePic(e) {
    setImageErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];
    let validateRes = false;
    if (await validateImage(file)) {
      var imageUrl = await uploadImageOrFile(file);
      setUploadImg([...uploadImg, imageUrl]);
    } else {
      setImageErrMsg("Invalid file format");
    }
    setShowLoader(false);
  }
  // Image Upload End

  // Delete Image Start
  const deleteImg = (index) => {
    setShowLoader(true);
    //const index = profilePic.indexOf(id);
    uploadImg.splice(index, 1);
    setUploadImg(uploadImg);
    setUpload(!upload);
    setShowLoader(false);
  };
  // Delete Image End

  const [DeductVeggies, setOpenDeductVeggies] = useState(false);

  const [isDeductVeggies, setIsDeductVeggies] = useState(null);

  const handleClickOpenDeductVeggies = (scrollType, isDeduct) => {
    setOpenDeductVeggies(true);
    setScroll(scrollType);
    setIsDeductVeggies(isDeduct);
  };

  const closeDeductVeggies = () => {
    setOpenDeductVeggies(false);
  };

  // Check Quantity Start
  const checkQuantity = (value, quantity, index) => {
    const regex = /^[0-9\b]+$/;
    document.getElementById("span" + index).innerHTML = "";
    if (isDeductVeggies == true) {
      if (regex.test(value) && quantity >= value) {
        document.getElementById(index).innerHTML = quantity - value;
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    } else {
      if (regex.test(value)) {
        document.getElementById(index).innerHTML =
          parseInt(quantity) + parseInt(value);
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    }
  };
  // Check Quantity End

  const deductVeggies = async (value, id, quantity, index) => {
    var regex = /^[0-9]+$/;
    document.getElementById("span" + index).innerHTML = "";
    if (value == "") {
      document.getElementById(index).innerHTML = quantity;
    } else {
      if (regex.test(value) && quantity >= value) {
        await swal({
          title: "Are you sure?",
          text: "Do you really want to move given quantity to kitchen?",
          icon: "warning",
          buttons: ["No", "Yes"],
        }).then(function (isConfirm) {
          if (isConfirm) {
            setShowLoader(true);
            let inputData = {
              product_id: id,
            };
            inputData.current_quantity = parseInt(
              document.getElementById(index).innerHTML
            );

            makePostRequest("starmarket/updateproduct", inputData)
              .then((response) => {
                if (response.data.data) {
                  swal(
                    "Success",
                    "Quantity moved to kitchen successfully",
                    "success",
                    {
                      timer: 3000,
                      buttons: false,
                    }
                  );
                  getProductList(null);
                }
              })
              .catch((err) => {
                setShowLoader(false);
              });
            setShowLoader(false);
          }
        });
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    }
  };

  // Add Quantity Start
  const addVeggies = async (value, id, quantity, index) => {
    var regex = /^[0-9]+$/;
    document.getElementById("span" + index).innerHTML = "";
    if (value == "") {
      document.getElementById(index).innerHTML = quantity;
    } else {
      if (regex.test(value)) {
        await swal({
          title: "Are you sure?",
          text: "Do you really want to Add given quantity to kitchen?",
          icon: "warning",
          buttons: ["No", "Yes"],
        }).then(function (isConfirm) {
          if (isConfirm) {
            setShowLoader(true);
            let inputData = {
              product_id: id,
            };
            inputData.current_quantity = parseInt(
              document.getElementById(index).innerHTML
            );

            makePostRequest("starmarket/updateproduct", inputData)
              .then((response) => {
                if (response.data.data) {
                  swal(
                    "Success",
                    "Quantity Add to kitchen successfully",
                    "success",
                    {
                      timer: 3000,
                      buttons: false,
                    }
                  );
                  getProductList(null);
                }
              })
              .catch((err) => {
                setShowLoader(false);
              });
            setShowLoader(false);
          }
        });
      } else {
        document.getElementById("span" + index).innerHTML =
          "Please Enter Correct Value";
      }
    }
  };
  // Add Quantity End

  const categoryMap = categoryData && categoryData.reduce((map, category) => {

    map[category.category_id] = category.category_name;
    return map;
  }, {});

  function handleChangeDollarAmount(value) {
    setPriceErrMsg("");
    setStarPrice(value);
    let regEx = /^\d+$/;
    if (regEx.test(value)) {
      setPrice(value * masterDollarAmount);
    } else {
      if (value) 
          setPriceErrMsg("Invalid Value");
      else {
        setPriceErrMsg("Invalid Value");
        setPrice(0);

      }
    }
  }

  function getMasterDollarAmount() {
    setShowLoader(true);
    makeGetRequest("employee/getmasterstarcount/")
      .then((response) => {
        if (response.data) {
          if (response.data.data[0] == undefined) {
            swal(
              "Set Exchange Stars",
              "First set master star rate with rupees",
              "warning",
              {
                timer: 4000,
                buttons: false,
              }
            );
            navigate("/Dashboard");
          } else {
            setMasterDollarAmount(response.data.data[0].dollar_amount);
            setUpload(!upload);
          }
        }
        setShowLoader(false);
      })
      .catch((err) => {
        let errMsg = err.response.data.message;
        setShowLoader(false);
      });
  }

  // Add / Edit Category Api Start
  const addOrUpdateCategory = async (values, { resetForm }) => {
    let updateCategory = true;
    let dupRes = categoryData.find(
      (o) =>
        o.category_name.toLowerCase() === values.category_name.toLowerCase()
    );

    if (isEditCategory) {
      if (dupRes) {
        if (values.category_id == dupRes.category_id) {
          updateCategory = true;
        } else {
          updateCategory = false;
        }
      } else {
        updateCategory = true;
      }

      if (updateCategory) {
        makePostRequest("starmarket/updatecategory", values)
          .then((response) => {
            if (response.data) {
              getCategoryList(null);
              // getCategoryDropDown(null);
            }
            setIsEditCategory(false);
            values.category_name = "";
            values.category_id = "";
            setDuplicateErrMsg("");
          })
          .catch((err) => {
            let errMsg = err.response.data.message;
          });
      } else setDuplicateErrMsg("Duplicate Value");
    } else {
      if (dupRes) {
        setDuplicateErrMsg("Duplicate Value");
      } else {
        values.status = true;
        makePostRequest("starmarket/insertcategory", values)
          .then((response) => {
            if (response.data) {
              // alert("aaa");
              getCategoryList(null);
              // getCategoryDropDown(null);
            }
            values.category_name = "";
            resetForm();
          })
          .catch((err) => {
            let errMsg = err.response.data.message;
            swal("Warning", err.data.message, "warning", {
              timer: 3000,
              buttons: false,
            });
          });
        setDuplicateErrMsg("");
      }
    }
  };
  // Add / Edit Category Api End

  //Edit Category Api start
  const editCategory = (categoryObj) => {
    setFormValues(categoryObj);
    setIsEditCategory(true);
  };

  // Edit Category End

  const cancelCategory = () => {
    setFormValues(null);
    setIsEditCategory(false);
    setPriority(false);
    setDuplicateErrMsg("");
  };

  // Status Category Start
  const handleStatusChange = (event, category_id) => {
    // alert("aaaa");
    let inputdata = {
      category_id: category_id,
      status: event.target.checked,
    };
    makePostRequest("starmarket/updatecategory", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          getCategoryList(null);
          // getCategoryDropDown(null);
        }
      })
      .catch((err) => {
      });
  };
  // Status Category end

  // Delete Category Api Start
  const deleteCategory = async (category_id) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Category Deleted!",
          text: "Category is successfully deleted!",
          icon: "success",
          timer: 3000,
        }).then(function () {
          let inputdata = {
            category_id: category_id,
            is_deleted: true,
          };
          makePostRequest("starmarket/updatecategory", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                cancelCategory();
                getCategoryList(null);
                
              }
            })
            .catch((err) => {
            });
        });
      } else {
        //swal("Cancelled", "Your Category is safe :)", "error");
      }
    });
  };
  // Delete Category Api End

  // get Category Api Start
  const getCategoryList = async (SearchStr) => {
    if (!SearchStr) SearchStr = null;
    setShowLoader(true);
    setCategoriesDropDown([]);

    makeGetRequest(
      "starmarket/categorysearch/" +
        sortColumnNameCategory +
        "/" +
        sortBy +
        "/" +
        SearchStr +
        "/" +
        pageNo +
        "/null"
    )
      .then((response) => {
        if (response.data) {
          setCategoryData(response.data.data);

          // alert("bbbb");
          let categoryObj;
          categoriesDropDown.length = 0;
          categoriesDropDown.push({ key: "Select Category", value: "" });
          if (categoryData && categoryData.length > 0) {
            for (let i = 0; i < categoryData.length; i++) {
              categoryObj = { key: "", value: "" };
              categoryObj.key = categoryData[i].category_name;
              categoryObj.value = categoryData[i].category_id;
              categoriesDropDown.push(categoryObj);
            }
            setCategoriesDropDown(categoriesDropDown);
          } else setCategoriesDropDown(categoriesDropDown);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };
  // get Category Api End

  // Sort Category Start
  function handleChangeSortByCategory() {
    //alert("In ASC")
    setSortColumnNameCategory("category_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    //setUpload(!upload);
    getCategoryList(null);
    // getCategoryDropDown(null);
  }
  // Sort Category End

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Modal Main
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setFormValues(null);
    setIsEditCategory(false);
    setPriority(false);
    setDuplicateErrMsg("");
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  // End

  // Modal Main
  const [open3, setOpen3] = React.useState(false);

  const handleClickOpen3 = (scrollType) => () => {
    setOpen3(true);
    setScroll(scrollType);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };
  // End

  // Modal Main
  const [open4, setOpen4] = React.useState(false);

  const handleClickOpen4 = (scrollType) => () => {
    setOpen4(true);
    setScroll(scrollType);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };
  // End

  // Edit Category
  const [openEditCategory, setOpenEditCategory] = React.useState(false);

  const handleClickOpenEditCategory = (scrollType) => () => {
    setOpenEditCategory(true);
    setScroll(scrollType);
  };

  const handleCloseEditCategory = () => {
    setOpenEditCategory(false);
  };

  // End

  
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const [alignment, setAlignment] = React.useState("");

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [alignmentCategory, setAlignmentCategory] = React.useState("Daily");

  const handleAlignmentCategory = (event, newAlignment) => {
    setAlignmentCategory(newAlignment);
  };

  const categoryIcoClick = () => {
    var element = document.getElementById("slideCateId");
    element.classList.toggle("slideCateSt");
  };

 
  useEffect(() => {
    localStorage.setItem('selectedModule','ServiceProvider');
    getCategoryList(null);
    getProductList(null);
    getMasterDollarAmount();
    // getCategoryDropDown(null);
    // getCategoryDropDown();

    if (AdProdCafeInventory) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    window.addEventListener("scroll", () => {
      if (window.scrollY < 15) {
        setscrolltopdata("");
      } else {
        setscrolltopdata("scrolled");
      }
      if (open3) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
      if (open4) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
      if (DeductVeggies) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }

      if (openEditCategory) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
    });
  }, [
    AdProdCafeInventory,
    DeductVeggies,
    open,
    open3,
    open4,
    openEditCategory,
  ]);

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <div className={`header-wrapper ${scrolltopdata}`}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <div className="pageMainHeading positionHeading">
                <h1>Services</h1>
                {/* <Button className="pageTopMainBt">Add Employee</Button> */}
              </div>
            </Grid>
          </Grid>

          <div className="servicesTabMain">
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="servicesTabInnerMain"
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="Item One"
                    {...a11yProps(0)}
                    component={() => (
                      <Button className="tabImg" onClick={() => setValue(0)}>
                        <div className="tabImgInr">
                          <img src={tabImg1} />
                        </div>
                        <p>Star Market</p>
                      </Button>
                    )}
                  />
                  <Tab
                    label="Item Two"
                    {...a11yProps(1)}
                    component={() => (
                      <Button className="tabImg" onClick={() => setValue(1)}>
                        <div className="tabImgInr">
                          <img src={tabImg2} />
                        </div>
                        <p>Star Cafe</p>
                      </Button>
                    )}
                  />
                  <Tab
                    label="Item Three"
                    {...a11yProps(2)}
                    component={() => (
                      <Button className="tabImg" onClick={() => setValue(2)}>
                        <div className="tabImgInr">
                          <img src={tabImg3} />
                        </div>
                        <p>Barber Shop</p>
                      </Button>
                    )}
                  />
                  <Tab
                    label="Item Four"
                    {...a11yProps(3)}
                    component={() => (
                      <Button className="tabImg" onClick={() => setValue(3)}>
                        <div className="tabImgInr">
                          <img src={tabImg4} />
                        </div>
                        <p>Sports</p>
                      </Button>
                    )}
                  />
                </Tabs>
              </Box>

              <CustomTabPanel
                value={value}
                index={0}
                className="tabContentMain"
              >
                <div className="tabContDiv">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div className="pageMainHeading">
                        <h1>Product Inventory</h1>
                      </div>
                    </Grid>
                  </Grid>
                  <div className="serviceSearchBar">
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={4}>
                        <div className="listingSearchAndFilterMain">
                          <div className="prodSearchDiv">
                            <input
                              type="text"
                              className="prodSearchInpt"
                              placeholder="Search"
                              onChange={(e) => getProductList(e.target.value)}
                            />
                            <div></div>
                          </div>
                          <div className="sortingIcoOut">
                            <ToggleButton
                              value="check"
                              selected={selected}
                              onChange={() => {
                                handleChangeSortBy();
                              }}
                            >
                              <ImportExportIcon
                                className="visitorFilterIco"
                                aria-haspopup="true"
                              />
                            </ToggleButton>
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} lg={8} className="addImpotBtOuter">
                        <Button
                          className="pageTopMainBt"
                          onClick={handleClickOpen("body")}
                        >
                          <AddIcon /> Manage Categories
                        </Button>

                        <Button
                          className="pageTopMainBt"
                          onClick={() =>
                            handleClickOpenDeductVeggies("body", false)
                          }
                        >
                          <AddIcon /> Update Stock
                        </Button>

                        <Button
                          className="pageTopMainBt"
                          onClick={handleClickOpenAdProdCafeInventory("body")}
                        >
                          <AddIcon /> Add Product
                        </Button>
                        <Button
                          className="pageTopMainBt blueBt"
                          component="label"
                          variant="contained"
                        >
                          <PostAddIcon /> &nbsp; Import Excel
                          <VisuallyHiddenInput type="file" />
                        </Button>
                      </Grid>

                      {productData.map((data) => (
                        <Grid item xs={12} md={12}>
                          <div className="serviceistOuter">
                            <div className="mainListDiv" key={data.product_id}>
                              <div className="mainListCont">
                                <h1
                                  onClick={() =>
                                    navigate(
                                      "/ProductDetailsStarMarket/" +
                                        data.product_id
                                    )
                                  }
                                >
                                  {data.product_name}
                                </h1>
                                <p>ID #{data.product_id}</p>
                              </div>

                              <div className="mainListCont mainListInrCont">
                                <div className="smalShortingOuter">
                                  <label>Category</label>
                                  <ToggleButton
                                    value="check"
                                    selected={selected}
                                    onChange={() => {
                                      setSelected(!selected);
                                    }}
                                    className="smalSho"
                                  >
                                    <ImportExportIcon
                                      className="smalShorting"
                                      aria-haspopup="true"
                                    />
                                  </ToggleButton>
                                </div>
                                <span className="mainListSubTx">
                                  {" "}
                                  {data.category_names}
                                </span>
                              </div>

                              <div className="mainListCont mainListInrCont">
                                <div className="smalShortingOuter">
                                  <label>In Stock</label>
                                  <ToggleButton
                                    value="check"
                                    selected={selected}
                                    onChange={() => {
                                      setSelected(!selected);
                                    }}
                                    className="smalSho"
                                  >
                                    <ImportExportIcon
                                      className="smalShorting"
                                      aria-haspopup="true"
                                    />
                                  </ToggleButton>
                                </div>
                                <span className="mainListSubTx">
                                  {data.current_quantity}
                                </span>
                              </div>

                              <div className="mainListCont mainListInrCont">
                                <div className="smalShortingOuter">
                                  <label>Weight</label>
                                  <ToggleButton
                                    value="check"
                                    selected={selected}
                                    onChange={() => {
                                      setSelected(!selected);
                                    }}
                                    className="smalSho"
                                  >
                                    <ImportExportIcon
                                      className="smalShorting"
                                      aria-haspopup="true"
                                    />
                                  </ToggleButton>
                                </div>
                                <span className="mainListSubTx">
                                  {" "}
                                  {data.wt_per_item}
                                </span>
                              </div>

                              <div className="mainListCont mainListInrCont">
                                <div className="smalShortingOuter">
                                  <label>Low Stock Limit</label>
                                  <ToggleButton
                                    value="check"
                                    selected={selected}
                                    onChange={() => {
                                      setSelected(!selected);
                                    }}
                                    className="smalSho"
                                  >
                                    <ImportExportIcon
                                      className="smalShorting"
                                      aria-haspopup="true"
                                    />
                                  </ToggleButton>
                                </div>
                                <span className="mainListSubTx">
                                  {" "}
                                  {data.low_stock_limit}
                                </span>
                              </div>

                              <div className="mainListCont mainListInrCont">
                                <div className="smalShortingOuter">
                                  <label>Price Per Item</label>
                                  <ToggleButton
                                    value="check"
                                    selected={selected}
                                    onChange={() => {
                                      setSelected(!selected);
                                    }}
                                    className="smalSho"
                                  >
                                    <ImportExportIcon
                                      className="smalShorting"
                                      aria-haspopup="true"
                                    />
                                  </ToggleButton>
                                </div>
                                <span className="mainListSubTx">
                                  ₹ {data.price}
                                </span>
                              </div>

                              <div className="mainListCont mainListInrCont">
                                <div className="actionBtnOut">
                                  <Tooltip title="Edit" arrow placement="top">
                                    <IconButton
                                      onClick={() =>
                                        editSubCategory(data, "body")
                                      }
                                    >
                                      {/* // onClick={editSubCategory(data, "body")} */}

                                      <img src={editIco} />
                                    </IconButton>
                                  </Tooltip>

                                  <Switch
                                    {...label}
                                    checked={data.status}
                                    className="swicthMain"
                                    onChange={(event) =>
                                      handleProductStatusChange(
                                        event,
                                        data.product_id
                                      )
                                    }
                                  />

                                  <Tooltip title="Delete" arrow placement="top">
                                    <IconButton
                                      onClick={() =>
                                        deleteProduct(data.product_id)
                                      }
                                    >
                                      <img src={deletIco} />
                                    </IconButton>
                                  </Tooltip>

                                  {/* <Tooltip title="Delete" arrow placement="top">
                                    <IconButton
                                     onClick={() =>
                                      deleteCategory(data.category_id)
                                    }
                                    >
                                      <img src={deletIco} />
                                    </IconButton>
                                  </Tooltip> */}
                                </div>
                              </div>
                            </div>

                            {/* <div className="mainListDiv">
                            <div className="mainListCont">
                              <h1 onClick={() => navigate("/ProductDetails")}>
                                Banana Chips
                              </h1>
                              <p>ID #M122645</p>
                            </div>

                            <div className="mainListCont mainListInrCont">
                              <div className="smalShortingOuter">
                                <label>Category</label>
                                <ToggleButton
                                  value="check"
                                  selected={selected}
                                  onChange={() => {
                                    setSelected(!selected);
                                  }}
                                  className="smalSho"
                                >
                                  <ImportExportIcon
                                    className="smalShorting"
                                    aria-haspopup="true"
                                  />
                                </ToggleButton>
                              </div>
                              <span className="mainListSubTx">Groceries</span>
                            </div>

                            <div className="mainListCont mainListInrCont">
                              <div className="smalShortingOuter">
                                <label>In Stock</label>
                                <ToggleButton
                                  value="check"
                                  selected={selected}
                                  onChange={() => {
                                    setSelected(!selected);
                                  }}
                                  className="smalSho"
                                >
                                  <ImportExportIcon
                                    className="smalShorting"
                                    aria-haspopup="true"
                                  />
                                </ToggleButton>
                              </div>
                              <span className="mainListSubTx">2450</span>
                            </div>

                            <div className="mainListCont mainListInrCont">
                              <div className="smalShortingOuter">
                                <label>Quantity Sold</label>
                                <ToggleButton
                                  value="check"
                                  selected={selected}
                                  onChange={() => {
                                    setSelected(!selected);
                                  }}
                                  className="smalSho"
                                >
                                  <ImportExportIcon
                                    className="smalShorting"
                                    aria-haspopup="true"
                                  />
                                </ToggleButton>
                              </div>
                              <span className="mainListSubTx">21543</span>
                            </div>

                            <div className="mainListCont mainListInrCont">
                              <div className="smalShortingOuter">
                                <label>Weight</label>
                                <ToggleButton
                                  value="check"
                                  selected={selected}
                                  onChange={() => {
                                    setSelected(!selected);
                                  }}
                                  className="smalSho"
                                >
                                  <ImportExportIcon
                                    className="smalShorting"
                                    aria-haspopup="true"
                                  />
                                </ToggleButton>
                              </div>
                              <span className="mainListSubTx">25Gram</span>
                            </div>

                            <div className="mainListCont mainListInrCont">
                              <div className="smalShortingOuter">
                                <label>Low Stock Limit</label>
                                <ToggleButton
                                  value="check"
                                  selected={selected}
                                  onChange={() => {
                                    setSelected(!selected);
                                  }}
                                  className="smalSho"
                                >
                                  <ImportExportIcon
                                    className="smalShorting"
                                    aria-haspopup="true"
                                  />
                                </ToggleButton>
                              </div>
                              <span className="mainListSubTx">10</span>
                            </div>

                            <div className="mainListCont mainListInrCont">
                              <div className="smalShortingOuter">
                                <label>Price Per Item</label>
                                <ToggleButton
                                  value="check"
                                  selected={selected}
                                  onChange={() => {
                                    setSelected(!selected);
                                  }}
                                  className="smalSho"
                                >
                                  <ImportExportIcon
                                    className="smalShorting"
                                    aria-haspopup="true"
                                  />
                                </ToggleButton>
                              </div>
                              <span className="mainListSubTx">$15</span>
                            </div>

                            <div className="mainListCont mainListInrCont">
                              <div className="actionBtnOut">
                                <Tooltip title="Edit" arrow placement="top">
                                  <IconButton
                                    onClick={handleClickOpen4("body")}
                                  >
                                    <img src={editIco} />
                                  </IconButton>
                                </Tooltip>

                                <Switch
                                  {...label}
                                  defaultChecked
                                  className="swicthMain"
                                />

                                <Tooltip title="Delete" arrow placement="top">
                                  <IconButton>
                                    <img src={deletIco} />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          </div> */}
                            {/* <div className="mainListDiv">
                            <div className="mainListCont">
                              <h1 onClick={() => navigate("/ProductDetails")}>
                                Banana Chips
                              </h1>
                              <p>ID #M122645</p>
                            </div>

                            <div className="mainListCont mainListInrCont">
                              <div className="smalShortingOuter">
                                <label>Category</label>
                                <ToggleButton
                                  value="check"
                                  selected={selected}
                                  onChange={() => {
                                    setSelected(!selected);
                                  }}
                                  className="smalSho"
                                >
                                  <ImportExportIcon
                                    className="smalShorting"
                                    aria-haspopup="true"
                                  />
                                </ToggleButton>
                              </div>
                              <span className="mainListSubTx">Groceries</span>
                            </div>

                            <div className="mainListCont mainListInrCont">
                              <div className="smalShortingOuter">
                                <label>In Stock</label>
                                <ToggleButton
                                  value="check"
                                  selected={selected}
                                  onChange={() => {
                                    setSelected(!selected);
                                  }}
                                  className="smalSho"
                                >
                                  <ImportExportIcon
                                    className="smalShorting"
                                    aria-haspopup="true"
                                  />
                                </ToggleButton>
                              </div>
                              <span className="mainListSubTx">2450</span>
                            </div>

                            <div className="mainListCont mainListInrCont">
                              <div className="smalShortingOuter">
                                <label>Quantity Sold</label>
                                <ToggleButton
                                  value="check"
                                  selected={selected}
                                  onChange={() => {
                                    setSelected(!selected);
                                  }}
                                  className="smalSho"
                                >
                                  <ImportExportIcon
                                    className="smalShorting"
                                    aria-haspopup="true"
                                  />
                                </ToggleButton>
                              </div>
                              <span className="mainListSubTx">21543</span>
                            </div>

                            <div className="mainListCont mainListInrCont">
                              <div className="smalShortingOuter">
                                <label>Weight</label>
                                <ToggleButton
                                  value="check"
                                  selected={selected}
                                  onChange={() => {
                                    setSelected(!selected);
                                  }}
                                  className="smalSho"
                                >
                                  <ImportExportIcon
                                    className="smalShorting"
                                    aria-haspopup="true"
                                  />
                                </ToggleButton>
                              </div>
                              <span className="mainListSubTx">25Gram</span>
                            </div>

                            <div className="mainListCont mainListInrCont">
                              <div className="smalShortingOuter">
                                <label>Low Stock Limit</label>
                                <ToggleButton
                                  value="check"
                                  selected={selected}
                                  onChange={() => {
                                    setSelected(!selected);
                                  }}
                                  className="smalSho"
                                >
                                  <ImportExportIcon
                                    className="smalShorting"
                                    aria-haspopup="true"
                                  />
                                </ToggleButton>
                              </div>
                              <span className="mainListSubTx">10</span>
                            </div>

                            <div className="mainListCont mainListInrCont">
                              <div className="smalShortingOuter">
                                <label>Price Per Item</label>
                                <ToggleButton
                                  value="check"
                                  selected={selected}
                                  onChange={() => {
                                    setSelected(!selected);
                                  }}
                                  className="smalSho"
                                >
                                  <ImportExportIcon
                                    className="smalShorting"
                                    aria-haspopup="true"
                                  />
                                </ToggleButton>
                              </div>
                              <span className="mainListSubTx">$15</span>
                            </div>

                            <div className="mainListCont mainListInrCont">
                              <div className="actionBtnOut">
                                <Tooltip title="Edit" arrow placement="top">
                                  <IconButton
                                    onClick={handleClickOpen4("body")}
                                  >
                                    <img src={editIco} />
                                  </IconButton>
                                </Tooltip>

                                <Switch
                                  {...label}
                                  defaultChecked
                                  className="swicthMain"
                                />

                                <Tooltip title="Delete" arrow placement="top">
                                  <IconButton>
                                    <img src={deletIco} />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          </div> */}
                          </div>
                        </Grid>
                      ))}
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className="paginationOuterMain"
                      >
                        <Pagination
                          count={10}
                          variant="outlined"
                          shape="rounded"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </CustomTabPanel>

              <CustomTabPanel
                value={value}
                index={1}
                className="tabContentMain"
              >
                <div className="tabContDiv">
                  <div className="serviceSearchBar">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <StarCafeTabs />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </CustomTabPanel>

              <CustomTabPanel value={value} index={2}>
                Item Three
              </CustomTabPanel>

              <CustomTabPanel value={value} index={3}>
                Item Three
              </CustomTabPanel>
            </Box>
          </div>
        </div>
      </div>

      <div>
        <Dialog
          open={DeductVeggies}
          // onClose={closeDeductVeggies}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            {isDeductVeggies ? (
              <>Move Product from Kitchen</>
            ) : (
              <>Add Product to Kitchen</>
            )}
            <CloseIcon className="modalCloseBt" onClick={closeDeductVeggies} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="visitorSearch">
                        <div className="prodFilter">
                          <input
                            type="text"
                            className="prodSearchInpt"
                            placeholder="Search"
                            onChange={(e) => getProductList(e.target.value)}
                          />
                        </div>
                        <div className="sortingIcoOut">
                          <ToggleButton
                            value="check"
                            selected={selected}
                            onChange={() => {
                              handleChangeSortBy();
                            }}
                          >
                            <ImportExportIcon
                              className="visitorFilterIco"
                              aria-haspopup="true"
                            />
                          </ToggleButton>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="productDetails groceryTableMain">
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>Product Name</th>
                              {/* <th>Brand</th> */}
                              <th>Quantity In Stock</th>
                              <th>Purchase Price (₹)</th>
                              {isDeductVeggies ? (
                                <th>Quantity To Deduct</th>
                              ) : (
                                <th>Quantity To Add</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {productData.map((data, index) => (
                              <tr>
                                <td>{data.product_name}</td>
                                {/* <td>Tea Brand</td> */}
                                <td id={index}>{data.current_quantity}</td>
                                <td>₹ {data.price}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="qutyInput"
                                    placeholder="Quantity"
                                    onChange={(e) =>
                                      checkQuantity(
                                        e.target.value,
                                        data.current_quantity,
                                        index
                                      )
                                    }
                                    onBlur={(e) =>
                                      isDeductVeggies
                                        ? deductVeggies(
                                            e.target.value,
                                            data.product_id,
                                            data.current_quantity,
                                            index
                                          )
                                        : addVeggies(
                                            e.target.value,
                                            data.product_id,
                                            data.current_quantity,
                                            index
                                          )
                                    }
                                  />
                                  <div style={{ color: "red" }}>
                                    <span id={`span` + index}></span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} md={12} className="paginationOuterMain">
                      <Pagination
                        count={10}
                        variant="outlined"
                        shape="rounded"
                      />
                    </Grid> */}
                    {/* <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <Button className="modalBtSmallWidth">Move Item</Button>
                    </Grid> */}
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Manage Category */}
      <div>
        <Dialog
          open={open}
          // onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain veryBigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Manage Category
            <CloseIcon className="modalCloseBt" onClick={handleClose} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Formik
                        initialValues={formValues || initialValues}
                        validationSchema={validationSchema}
                        onSubmit={addOrUpdateCategory}
                        enableReinitialize
                      >
                        {(formik) => (
                          <Form>
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={12}>
                                <FormikControl
                                  control="input"
                                  type="text"
                                  label="Enter Category Name"
                                  placeholder="Category Name"
                                  labelClass="pageLabel"
                                  name="category_name"
                                  isRequired="true"
                                  className="prodSearchInpt"
                                  // onChange={(e) => handleCheckValue(e.target.value)}
                                />
                                <span style={{ color: "red", fontSize: 14 }}>
                                  {duplicateErrMsg}
                                </span>
                                {/* <label className="pageLabel">Enter Category Name</label>
                      
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Category Name"
                      /> */}
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                style={{ textAlign: "right" }}
                              >
                                {isEditCategory ? (
                                  <Button
                                    className="pageTopMainBt pageTopMainCancelBt "
                                    onClick={() => cancelCategory()}
                                  >
                                    <>Cancel</>
                                  </Button>
                                ) : (
                                  <></>
                                )}
                                <Button className="pageTopMainBt" type="submit">
                                  {isEditCategory ? (
                                    <>Update Category</>
                                  ) : (
                                    <>Add Category</>
                                  )}
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="visitorSearch">
                        <div className="prodFilter">
                          <input
                            className="prodSearchInpt"
                            placeholder="Search"
                            onChange={(e) => getCategoryList(e.target.value)}
                          />
                        </div>
                        <div className="sortingIcoOut">
                          <ToggleButton
                            value="check"
                            selected={selected}
                            onChange={() => {
                              handleChangeSortByCategory();
                            }}
                          >
                            <ImportExportIcon
                              className="visitorFilterIco"
                              aria-haspopup="true"
                            />
                          </ToggleButton>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="productDetails groceryTableMain verticleScroll">
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>
                                Category Name{" "}
                                {categoryData && categoryData.length > 0 ? (
                                  <>
                                    <em
                                      style={{ color: "#878787", fontSize: 12 }}
                                    >
                                      (Total {categoryData.length} Categories)
                                    </em>
                                  </>
                                ) : null}
                              </th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          {categoryData && categoryData.length > 0 ? (
                            <>
                              {categoryData.map((data) => (
                                <tbody key={data.category_id}>
                                  <tr>
                                    <td>{data.category_name}</td>

                                    <td>
                                      <div className="actionBtnOut">
                                        <Tooltip
                                          title="Edit"
                                          arrow
                                          placement="top"
                                        >
                                          <IconButton
                                            // onClick={() => navigate("/")}
                                            onClick={() => editCategory(data)}
                                          >
                                            <img src={editIco} />
                                          </IconButton>
                                        </Tooltip>

                                        <Switch
                                          {...label}
                                          checked={data.status}
                                          className="swicthMain"
                                          onChange={(event) =>
                                            handleStatusChange(
                                              event,
                                              data.category_id
                                            )
                                          }
                                        />

                                        <Tooltip
                                          title="Delete"
                                          arrow
                                          placement="top"
                                        >
                                          <IconButton
                                            onClick={() =>
                                              deleteCategory(data.category_id)
                                            }
                                          >
                                            <img src={deletIco} />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </td>
                                  </tr>

                                  {/* <tr>
                              <td>Category Two</td>
                              <td>
                                <div className="actionBtnOut">
                                  <Tooltip title="Edit" arrow placement="top">
                                    <IconButton
                                    // onClick={() => navigate("/")}
                                    >
                                      <img src={editIco} />
                                    </IconButton>
                                  </Tooltip>

                                  <Switch
                                    {...label}
                                    defaultChecked
                                    className="swicthMain"
                                  />

                                  <Tooltip title="Delete" arrow placement="top">
                                    <IconButton>
                                      <img src={deletIco} />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </td>
                            </tr> */}
                                </tbody>
                              ))}
                            </>
                          ) : null}
                        </table>
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} md={12} className="paginationOuterMain">
                      <Pagination
                        count={10}
                        variant="outlined"
                        shape="rounded"
                      />
                    </Grid> */}
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Add Product */}
      <div>
        <Dialog
          open={AdProdCafeInventory}
          // onClose={CloseAdProdCafeInventory}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            {isEditSubCategory ? <>Edit Product</> : <>Add Product</>}

            <CloseIcon
              className="modalCloseBt"
              onClick={CloseAdProdCafeInventory}
            />
          </DialogTitle>

          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Formik
                    initialValues={ formValuesSUbCategory || initialValuesAddProd }
                    validationSchema={validationSchemaAddProd}
                    onSubmit={addOrUpdateProduct}
                    enableReinitialize
                  >
                    {(formik) => (
                      <Form>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12}>
                            <div className="addRemoveImagesBox">
                              {uploadImg ? (
                                <>
                                  {uploadImg.map((img, index) => (
                                    <div className="imageAddedBox" key={index}>
                                      <img src={img} />
                                      <div className="cloIm">
                                        <CloseIcon
                                          onClick={() => deleteImg(index)}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : null}

                              {uploadImg.length < 4 ? (
                                <div className="uploadImBox">
                                  <h1 className="">Upload Image</h1>
                                  <Button
                                    className="uploadImBt"
                                    component="label"
                                    variant="contained"
                                    startIcon={<CloudUploadIcon />}
                                  >
                                    Upload
                                    <VisuallyHiddenInput
                                      type="file"
                                      onChange={(event) =>
                                        uploadProfilePic(event)
                                      }
                                    />
                                  </Button>
                                  <span>or drag file in here</span>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Product Name"
                              placeholder="Product Name"
                              labelClass="reggistraLabel"
                              name="product_name"
                              className="prodSearchInpt"
                              isRequired="true"
                            />
                            <span className="errorSt">
                              {duplicateFoodErrMsg}
                            </span>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <div className="muliSelectMain">
                              <label className="pageLabel">
                                Product Categories
                              </label>
                              <FormControl>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  value={categoriesId}
                                  onChange={handleChangeCategory}
                                  input={<OutlinedInput />}
                                  renderValue={(selected) =>
                                    selected
                                      .map((id) => categoryMap[id])
                                      .join(", ")
                                  }
                                  MenuProps={MenuProps}
                                >
                                  {categoryData.map((category) => (
                                    <MenuItem
                                      key={category.category_name}
                                      name={category.category_name}
                                      value={category.category_id}
                                    >
                                      <Checkbox
                                        // checked={categoriesId.indexOf(category.category_id) > -1}
                                        checked={
                                          categoriesId.includes(
                                            category.category_id
                                          )
                                            ? 1
                                            : 0
                                        }
                                      />
                                      <ListItemText
                                        primary={category.category_name}
                                      />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <span className="errorSt">
                                {categoryListErrMsg}
                              </span>
                            </div>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter New Quantity"
                              placeholder="New Quantity"
                              labelClass="reggistraLabel"
                              name="new_quantity"
                              className="prodSearchInpt"
                              isRequired="true"
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Weight Per Item"
                              placeholder="Weight"
                              labelClass="reggistraLabel"
                              name="wt_per_item"
                              className="prodSearchInpt"
                              isRequired="true"
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Low Stock Limit"
                              placeholder="Low Stock Limit"
                              labelClass="reggistraLabel"
                              name="low_stock_limit"
                              className="prodSearchInpt"
                              isRequired="true"
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <label className="pageLabel">
                              Select Weight Unit
                              <span className="requiredStar">*</span>
                            </label>
                            <div className="unitDivMain">
                              <div className="detailsTableFilterSec">
                                <ToggleButtonGroup
                                  value={alignment}
                                  exclusive
                                  onChange={handleAlignment}
                                  className="detailsTableFilterSecActionInr"
                                >
                                  <ToggleButton
                                    value="Grams"
                                    className="filterBtn"
                                  >
                                    Grams
                                  </ToggleButton>
                                  <ToggleButton
                                    value="KiloGrams"
                                    className="filterBtn"
                                  >
                                    Kilo Grams
                                  </ToggleButton>
                                  <ToggleButton
                                    value="Liter"
                                    className="filterBtn"
                                  >
                                    Liter
                                  </ToggleButton>
                                  <ToggleButton
                                    value="Milliliter"
                                    className="filterBtn"
                                  >
                                    Milliliter
                                  </ToggleButton>
                                  <ToggleButton
                                    value="Tonne"
                                    className="filterBtn"
                                  >
                                    Tonne
                                  </ToggleButton>

                                  <ToggleButton
                                    value="Ounces"
                                    className="filterBtn"
                                  >
                                    Ounces
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </div>
                            </div>
                            <span style={{ color: "red" }}>
                              {categoryUnitErrMsg}
                            </span>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <label className="pageLabel">
                              Select Category
                              <span className="requiredStar">*</span>
                            </label>
                            <div className="unitDivMain">
                              <div className="detailsTableFilterSec">
                                <ToggleButtonGroup
                                  value={alignmentCategory}
                                  exclusive
                                  onChange={handleAlignmentCategory}
                                  className="detailsTableFilterSecActionInr"
                                >
                                  <ToggleButton
                                    value="Daily"
                                    className="filterBtn"
                                  >
                                    Daily
                                  </ToggleButton>
                                  <ToggleButton
                                    value="Weekly"
                                    className="filterBtn"
                                  >
                                    Weekly
                                  </ToggleButton>
                                  <ToggleButton
                                    value="Monthly"
                                    className="filterBtn"
                                  >
                                    Monthly
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </div>
                            </div>
                          </Grid>

                          <Grid item xs={12} md={6}>
                          <label className="pageLabel">
                              Price In Stars (*)
                            </label>
                            <input
                              type="text"
                              placeholder="Price In Stars"
                              value={Star_price}
                              className="prodSearchInpt"
                              onChange={(e) =>
                                handleChangeDollarAmount(e.target.value)
                              }                              
                            />
                            
                            <span style={{ color: "red", fontSize: 14 }}>
                              {priceErrMsg}
                            </span>
                          </Grid>
                          
                          <Grid item xs={12} md={6}>
                          <label className="pageLabel">
                              Enter Price in INR (₹)
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Price in INR (₹)"
                              value={price}
                              className="prodSearchInpt"                             
                              disabled
                            />
                          </Grid>

                          {/* <Grid item xs={12} md={6}>
                          
                          
                              control="input"
                              type="text"
                              label="Enter Price"
                              placeholder="Price In Dollars"
                              labelClass="reggistraLabel"
                              name="price"
                              className="prodSearchInpt"
                              isRequired="true"
                            />
                          </Grid> */}
                          {/* <Grid item xs={12} md={6}> */}
                          {/* <label className="pageLabel">Price In Stars</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Price In Stars" */}
                          {/* <FormikControl
                              control="input"
                              type="text"
                              label="Price In Stars"
                              placeholder="Price In Stars"
                              labelClass="reggistraLabel"
                              name="price_in_stars"
                              className="prodSearchInpt"
                              isRequired="true"
                            />
                          </Grid> */}
                          <Grid item xs={12} md={12} className="lineBtns">
                            {isEditSubCategory ? (
                              <Button
                                className="cancelEditBtn"
                                onClick={() => cancelSubCategory()}
                              >
                                <>Cancel</>
                              </Button>
                            ) : (
                              <></>
                            )}
                            <Button className="modalBtSmallWidth" type="submit"    >
                              {isEditSubCategory ? (
                                <>Update Product</>
                              ) : (
                                <>Add Product</>
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {showLoader ? (
        <div className="loaderMain">
          <svg
            className="tea"
            width="37"
            height="48"
            viewbox="0 0 37 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.0819 17H3.02508C1.91076 17 1.01376 17.9059 1.0485 19.0197C1.15761 22.5177 1.49703 29.7374 2.5 34C4.07125 40.6778 7.18553 44.8868 8.44856 46.3845C8.79051 46.79 9.29799 47 9.82843 47H20.0218C20.639 47 21.2193 46.7159 21.5659 46.2052C22.6765 44.5687 25.2312 40.4282 27.5 34C28.9757 29.8188 29.084 22.4043 29.0441 18.9156C29.0319 17.8436 28.1539 17 27.0819 17Z"
              stroke="var(--secondary)"
              stroke-width="2"
            ></path>
            <path
              d="M29 23.5C29 23.5 34.5 20.5 35.5 25.4999C36.0986 28.4926 34.2033 31.5383 32 32.8713C29.4555 34.4108 28 34 28 34"
              stroke="var(--secondary)"
              stroke-width="2"
            ></path>
            <path
              id="teabag"
              fill="var(--secondary)"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16 25V17H14V25H12C10.3431 25 9 26.3431 9 28V34C9 35.6569 10.3431 37 12 37H18C19.6569 37 21 35.6569 21 34V28C21 26.3431 19.6569 25 18 25H16ZM11 28C11 27.4477 11.4477 27 12 27H18C18.5523 27 19 27.4477 19 28V34C19 34.5523 18.5523 35 18 35H12C11.4477 35 11 34.5523 11 34V28Z"
            ></path>
            <path
              id="steamL"
              d="M17 1C17 1 17 4.5 14 6.5C11 8.5 11 12 11 12"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke="var(--secondary)"
            ></path>
            <path
              id="steamR"
              d="M21 6C21 6 21 8.22727 19 9.5C17 10.7727 17 13 17 13"
              stroke="var(--secondary)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          <p>Loading . . .</p>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default ServicesConfiguration;
