import React, { Component } from "react";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import orangejuice from "../../assets/images/orangejuice.svg";

function ImageSlider({ images }) {
  console.log('images')
  console.log(images)
  return (

    <div className="mobileCssMain">
      <Carousel>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Slide ${index}`} />
            <p className="legend">{`Legend ${index + 1}`}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default ImageSlider;
