import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import freeTag from "../../../src/assets/images/freeTag.svg";
import StarIcon from "@mui/icons-material/Star";

// Initial items
const initialItems = [
  { id: "1", content: "Item 1" },
  { id: "2", content: "Item 2" },
  { id: "3", content: "Item 3" },
  { id: "4", content: "Item 4" },
  { id: "5", content: "Item 5" },
];

const SwipeDemo = () => {
  const [todaysMenuBreakfast, setItems] = useState(initialItems);

  // Function to handle the drag end event
  const onDragEnd = (result) => {
    console.log(result)
    const { destination, source } = result;

    if (!destination) {
      return; // If dropped outside the list
    }

    // Reorder the list based on the drag action
    const reorderedItems = Array.from(todaysMenuBreakfast);
    const [removed] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, removed);

    setItems(reorderedItems); // Update the state with reordered items
  };

  return (
    <div style={{ padding: 50 }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <div
              className="sortable-list"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {todaysMenuBreakfast.map((menu, index) => (
                <Draggable key={menu.id} draggableId={menu.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className={`sortable-item ${
                        snapshot.isDragging ? "dragging" : ""
                      }`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <p className="menuOutLine">
                        <div className="todayMenuTx">
                          <b>Tea & Roti</b> Product ID #132
                          <div className="freeImgOuter">
                            <img src={freeTag} />
                          </div>
                        </div>
                        <span className="mealStar">
                          <StarIcon /> 5
                        </span>
                      </p>

                      {menu.content}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default SwipeDemo;
