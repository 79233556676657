import React, { useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { PieChart } from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts/LineChart";
import InsertPageBreakIcon from "@mui/icons-material/InsertPageBreak";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
import deletIco from "../../../assets/images/delete-icon.svg";
import eye from "../../../assets/images/eye.svg";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import loaderImg from "../../../assets/images/logo.png";

import AOS from "aos";
import "aos/dist/aos.css";
import {
  makeGetRequest,
  makePostRequest,
} from "../../../utils/utils";
import moment from "moment";
// Pie Graph
const data = [
  { id: 0, value: 20, label: "Completed" },
  { id: 1, value: 20, label: "Ready For Pickup" },
  { id: 2, value: 60, label: "New" },
];
// End

function ReportOrderTab() {


  const [orderType, setOrderType] = React.useState(null);
  // const[serviceType,setServiceType]=React.useState(null);
  const [orderStatus, setOrderStatus] = React.useState(null);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);

  const [upload, setUpload] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);

  const [departmentList, setDepartmentList] = React.useState([]);
  const [ordersList, setOrdersList] = React.useState([]);
  const [totalOrdersCount, setTotalOrdersCount] = React.useState(0);
  const [totalRevenueInStars, setTotalRevenueInStars] = React.useState(0);
  const [selectedDepartmentId, setSelectedDepartmentId] = React.useState(null);
  const [selectedDepartmentName, setSelectedDepartmentName] = React.useState(null);
  const [filteredOrdersList, setFilteredOrdersList] = React.useState([]);
  const [pieChartData, setPieChartData] = React.useState([]);
  const [LinearChartData, setLinearChartData] = React.useState([]);
  const [orderDateList, setOrderDateList] = React.useState([]);
  // const [List, setOrderDateList] = React.useState([]);

  async function handleChangeFromDate(newDate) {
    console.log(newDate)
    setFromDate(newDate)
    getOrderReportCount(newDate, toDate, selectedDepartmentId, orderStatus, orderType)
    getPieChartData(newDate, toDate, selectedDepartmentId, orderType, orderStatus);
    getLinearChartData(newDate, toDate, selectedDepartmentId, orderStatus, orderType)

    // getOrderReport(newDate,toDate,selectedDepartmentId,orderType,orderStatus);
  }

  async function handleChangeToDate(date) {
    setToDate(date)
    getOrderReportCount(fromDate, date, selectedDepartmentId, orderStatus, orderType)
    getPieChartData(fromDate, date, selectedDepartmentId, orderType);
    getLinearChartData(fromDate, date, selectedDepartmentId, orderStatus, orderType)

  }

  async function handleChangeDepartment(e) {
    setSelectedDepartmentId(e.target.value);
    let idx = e.target.selectedIndex;
    let dataset = e.target.options[idx].dataset;
    setSelectedDepartmentName(dataset.isd);
    getOrderReportCount(fromDate, toDate, e.target.value, orderStatus, orderType)
    // getOrderReport(fromDate,toDate,e.target.value,orderType,orderStatus);
  }

  async function handleChangeService(e) {
    setOrderType(e.target.value);
    getOrderReportCount(fromDate, toDate, selectedDepartmentId, orderStatus, e.target.value)
    // getOrderReport(fromDate,toDate,selectedDepartmentId,e.target.value,orderStatus);
  }

  async function handleChangeOrderStatus(e) {
    setOrderStatus(e.target.value);
    getOrderReportCount(fromDate, toDate, selectedDepartmentId, e.target.value, orderType)

    // getOrderReport(fromDate,toDate,selectedDepartmentId,orderType,e.target.value);
  }

  async function handleChangeOrderStatus(e) {
    setOrderStatus(e.target.value);
    getOrderReportCount(fromDate, toDate, selectedDepartmentId, e.target.value, orderType)

    // getOrderReport(fromDate,toDate,selectedDepartmentId,orderType,e.target.value);
  }

  const getAllDepartmentList = async () => {
    setShowLoader(true);

    await makeGetRequest("/employee/getdepartment/null")
      .then((response) => {
        if (response.data.data) {
          setDepartmentList(response.data.data);
          // departmentDropDown.length = 0;
          // for (var i = 0; i < response.data.data.length; i++) {
          //   departmentDropDown.push(response.data.data[i].department_name);
          // }
          // setDepartmentDropDown(departmentDropDown);
        }

        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  async function getOrderReport(fromDate, toDate, department, ordertype, orderstatus) {
    if (fromDate == null)
      fromDate = moment().format('YYYY-MM-DD');

    if (toDate == null)
      toDate = moment().format('YYYY-MM-DD');
    setOrdersList([]);
    setShowLoader(true);
    await makeGetRequest("starcafe/getorderreport/" + ordertype + "/" + orderstatus + "/" + fromDate + "/" + toDate + "/" + department)
      .then((response) => {
        if (response.data.data) {
          console.log("Order Data");
          console.log(response.data.data);
          setOrdersList(response.data.data.orderData);
          setTotalOrdersCount(response.data.data.orderData.length)
          setTotalRevenueInStars(parseInt(response.data.data.totalStarSpendCafe) + parseInt(response.data.data.totalStarSpendMarket))
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        // let errMsg = err.response.data.message;
        setShowLoader(false);
      });
  }

  async function getPieChartData(fromDate, toDate, department, ordertype, orderstatus) {
    if (fromDate == null)
      fromDate = moment().format('YYYY-MM-DD');

    if (toDate == null)
      toDate = moment().format('YYYY-MM-DD');
    setOrdersList([]);
    setShowLoader(true);
    await makeGetRequest("starcafe/getpiechartdata/" + ordertype + "/" + orderstatus + "/" + fromDate + "/" + toDate + "/" + department)
      .then((response) => {
        if (response.data.data) {
          console.log("Order Data");
          console.log(response.data.data);
          setPieChartData(response.data.data.orderData)
          // { id: 0, value: totalRevenueInStars, label: 'series A' }
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        // let errMsg = err.response.data.message;
        setShowLoader(false);
      });
  }

  async function getLinearChartData(fromDate, toDate, department, ordertype, orderstatus) {
    if (fromDate == null)
      fromDate = moment().format('YYYY-MM-DD');

    if (toDate == null)
      toDate = moment().format('YYYY-MM-DD');
    setOrdersList([]);
    setShowLoader(true);
    await makeGetRequest("starcafe/getlinearchartdata/" + ordertype + "/" + orderstatus + "/" + fromDate + "/" + toDate + "/" + department)
      .then((response) => {
        if (response.data.data) {
          console.log("Order Data");
          console.log(response.data.data);
          let result = response.data.data.orderData.map(({ orderdate }) => orderdate)
          console.log(result)
          setOrderDateList(result)
          setLinearChartData(response.data.data.orderData)
          // { id: 0, value: totalRevenueInStars, label: 'series A' }
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        // let errMsg = err.response.data.message;
        setShowLoader(false);
      });
  }


  async function getOrderReportCount(fromDate, toDate, department, orderstatus, ordertype) {
    if (fromDate == null)
      fromDate = moment().format('YYYY-MM-DD');

    if (toDate == null)
      toDate = moment().format('YYYY-MM-DD');
    setFilteredOrdersList([]);
    setShowLoader(true);
    await makeGetRequest("starcafe/getorderreportcount/" + fromDate + "/" + toDate + "/" + department + "/" + orderstatus + "/" + ordertype)
      .then((response) => {
        if (response.data.data) {
          console.log("Order Data");
          console.log(response.data.data);
          setFilteredOrdersList(response.data.data.orderData);
          setTotalOrdersCount(response.data.data.orderData.length)
          setTotalRevenueInStars(parseInt(response.data.data.totalStarSpendCafe))
          //+parseInt(response.data.data.totalStarSpendMarket)
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        // let errMsg = err.response.data.message;
        setShowLoader(false);
      });
  }

  useEffect(() => {
    AOS.init();
    getAllDepartmentList();
    getOrderReportCount(fromDate, toDate, selectedDepartmentId, orderStatus, orderType)
    getPieChartData(fromDate, toDate, selectedDepartmentId, orderType, orderStatus)
    getLinearChartData(fromDate, toDate, selectedDepartmentId, orderType, orderStatus)

    // getOrderReport(fromDate,toDate,selectedDepartmentId,orderType,orderStatus);
  }, []);

  return (
    <>
      <div className="starCafeTabInner">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="repoFilterMain">
              <Button
                className="repoFilterBt activeRepoFilterBt"
                data-aos="fade-down"
                data-aos-delay="100"
                data-aos-anchor-placement="center-bottom"
              >
                Hourly
              </Button>
              <Button
                className="repoFilterBt"
                data-aos="fade-down"
                data-aos-delay="200"
                data-aos-anchor-placement="center-bottom"
              >
                Daily
              </Button>
              <Button
                className="repoFilterBt"
                data-aos="fade-down"
                data-aos-delay="300"
                data-aos-anchor-placement="center-bottom"
              >
                Week Day
              </Button>
              <Button
                className="repoFilterBt"
                data-aos="fade-down"
                data-aos-delay="400"
                data-aos-anchor-placement="center-bottom"
              >
                Weekly
              </Button>
              <Button
                className="repoFilterBt"
                data-aos="fade-down"
                data-aos-delay="500"
                data-aos-anchor-placement="center-bottom"
              >
                Monthly
              </Button>
              <Button
                className="repoFilterBt"
                data-aos="fade-down"
                data-aos-delay="600"
                data-aos-anchor-placement="center-bottom"
              >
                Yearly
              </Button>
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div className="selectDateRangeMain">
              <div className="selectDateRangeInr">
                <div>
                  <label>Select Date Range</label>
                </div>
                <div className="dateRangReport">
                  <div>
                    <input type="Date" className="prodSearchInpt" onChange={(e) => handleChangeFromDate(e.target.value)} />
                  </div>
                  <b>To</b>
                  <div>
                    <input type="Date" className="prodSearchInpt" onChange={(e) => handleChangeToDate(e.target.value)} />
                  </div>
                </div>
                <div>
                  <Button className="repoFilterBt updateDataB">
                    Update Data
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={12} md={12}>
                      <div
                        className="settingDevider"
                        style={{ margin: 0 }}
                      ></div>
                    </Grid> */}
          <Grid item xs={12} md={12}>
            <h1 className="repoSubHeading">Additional Filters</h1>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className="additionalFiltterMain">
              <select className="prodSearchInpt" onChange={(e) => handleChangeDepartment(e)}
                value={selectedDepartmentId} >

                <option value="null">All Department</option>
                {departmentList.map((departmentObj) => (
                  <option key={departmentObj.department_id}
                    value={departmentObj.department_id}
                    data-isd={departmentObj.department_name}>{departmentObj.department_name}</option>
                ))}
              </select>

              <select className="prodSearchInpt" onChange={(e) => handleChangeService(e)}
                value={orderType}>
                <option value="null">All Service</option>
                <option value="Starmarket">Star Market</option>
                <option value="Starcafe">Star Cafe</option>
                <option value="Barbershop">Barber Shop</option>
                <option value="sport">Sport</option>
              </select>

              <select className="prodSearchInpt" onChange={(e) => handleChangeOrderStatus(e)}
                value={orderStatus}>
                <option value="null">Order Status</option>
                <option value="Active">New</option>
                <option value="Completed">Completed</option>
                <option value="NotPickedUp">NotPickedUp</option>
                <option value="Refunded">Refunded</option>
              </select>
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div className="reportChartsMain">
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <div className="repoChartContDiv">
                    <div
                      className="repoChartCont chartRepoBlack"
                      data-aos="fade-down"
                      data-aos-delay="300"
                      data-aos-anchor-placement="center-bottom"
                    >
                      <b>{totalOrdersCount}</b>
                      <p>Total Orders</p>
                    </div>
                    <div
                      className="repoChartCont chartRepoGreen"
                      data-aos="fade-down"
                      data-aos-delay="500"
                      data-aos-anchor-placement="center-bottom"
                    >
                      <b>
                        <StarIcon /> {totalRevenueInStars}
                      </b>
                      <p>Revenue In Stars</p>
                    </div>
                    <div
                      className="repoChartCont chartRepoBlue"
                      data-aos="fade-down"
                      data-aos-delay="700"
                      data-aos-anchor-placement="center-bottom"
                    >
                      <b>Rs.{parseInt(totalRevenueInStars) * parseInt(localStorage.getItem('masterdollarvalue'))}</b>
                      <p>Revenue In Rs</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div
                    className="repoOrdersGraphMain"
                    data-aos="fade-up"
                    data-aos-delay="300"
                    data-aos-anchor-placement="center-bottom"
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <h1 className="orderGrapHeading">Orders Graph</h1>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="repoGraIndec">
                          <span className="activeRepoOrder">
                            <TaskAltIcon /> All
                          </span>
                          <span>
                            <TaskAltIcon /> Star Market
                          </span>
                          <span>
                            <TaskAltIcon /> Star Café
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="orderPieGr">
                          {/* <PieChart
                            series={[
                              {
                                data,
                                highlightScope: {
                                  faded: "global",
                                  highlighted: "item",
                                },
                                faded: {
                                  innerRadius: 30,
                                  additionalRadius: -30,
                                  color: "gray",
                                },
                              },
                            ]}
                            width={250}
                            height={140}
                            margin={{
                              top: 0,
                              bottom: 0,
                              left: 30,
                              right: 30,
                            }}
                          /> */}

                          <PieChart
                            series={[
                              {
                                data: pieChartData,
                              },
                            ]}
                            width={400}
                            height={200}
                          />

                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="repoOrderGrpDetai blackDetai">
                          <em></em>
                          <p>60</p>
                          <span>New</span>
                        </div>
                        <div className="repoOrderGrpDetai yellowDetai">
                          <em></em>
                          <p>20</p>
                          <span>Ready For Pickup</span>
                        </div>
                        <div className="repoOrderGrpDetai greenDetai">
                          <em></em>
                          <p>20</p>
                          <span>Completed</span>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} md={7}>
                  <div
                    className="departChartMain"
                    data-aos="fade-down"
                    data-aos-delay="300"
                    data-aos-anchor-placement="center-bottom"
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <h1 className="orderGrapHeading">
                          Orders By All Departments
                        </h1>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="lineRepoChart">

                        <LineChart
                            xAxis={[
                              {
                                data: [1, 2, 3, 5, 8, 10, 12, 15, 16],
                              },
                            ]}
                            yAxis={[
                              {
                                data: [1, 2, 3, 5, 8, 10, 12, 15, 16],
                              },
                            ]}
                            series={[
                              {
                                data: [2, 5.5, 2, 8.5, 1.5, 5],
                                valueFormatter: (value) =>
                                  value == null ? "NaN" : value.toString(),
                              },
                              // {
                              //   data: [
                              //     null,
                              //     null,
                              //     null,
                              //     null,
                              //     5.5,
                              //     2,
                              //     8.5,
                              //     1.5,
                              //     5,
                              //   ],
                              // },
                              // {
                              //   data: [7, 8, 5, 4, null, null, 2, 5.5, 1],
                              //   valueFormatter: (value) =>
                              //     value == null ? "?" : value.toString(),
                              // },
                            ]}
                            height={249}
                            margin={{ top: 10, bottom: 20 }}
                          />

                          {/* <LineChart
                            xAxis={[
                              {
                                data: [1, 2, 3, 5, 8, 10, 12, 15, 16],
                              },
                            ]}
                            series={[
                              {
                                data: [2, 5.5, 2, 8.5, 1.5, 5],
                                valueFormatter: (value) =>
                                  value == null ? "NaN" : value.toString(),
                              },
                              {
                                data: [
                                  null,
                                  null,
                                  null,
                                  null,
                                  5.5,
                                  2,
                                  8.5,
                                  1.5,
                                  5,
                                ],
                              },
                              {
                                data: [7, 8, 5, 4, null, null, 2, 5.5, 1],
                                valueFormatter: (value) =>
                                  value == null ? "?" : value.toString(),
                              },
                            ]}
                            height={249}
                            margin={{ top: 10, bottom: 20 }}
                          /> */}

                         </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div className="reportSearchMain">
              <input
                type="text"
                className="prodSearchInpt"
                placeholder="Search"
              />
              <div className="hoverForDrop">
                <Button className="repoBtn">
                  <InsertPageBreakIcon /> Export Report
                </Button>
                <div className="btDropHover">
                  <p>Export as .xslx</p>
                  <p>Export as .doc</p>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div
              className="productDetails groceryTableMain"
              data-aos="fade-down"
              data-aos-delay="100"
              data-aos-anchor-placement="center-bottom"
            >
              <table width="100%">
                <thead>
                  <tr>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Date
                        {/* <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton> */}
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Order Count
                        {/* <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton> */}
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Service Type
                        {/* <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton> */}
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Department
                        {/* <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton> */}
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Status
                        {/* <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton> */}
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Revenue (Rs)
                        {/* <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton> */}
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Revenue in (*)
                        {/* <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton> */}
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Total Item Sold
                        {/* <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton> */}
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Avg Order Price
                        {/* <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton> */}
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Details
                      </div>
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {filteredOrdersList.map((filteredOrderObj) => (
                    <tr>
                      <td>{moment(filteredOrderObj.orderdate).format('DD/MM/YYYY')}</td>
                      <td># {filteredOrderObj.count}</td>
                      <td>Star Cafe</td>
                      <td>{selectedDepartmentName ? <>{selectedDepartmentName}</> : <>-</>}</td>
                      <td>{filteredOrderObj.orderstatus}</td>
                      <td>{parseInt(localStorage.getItem('masterdollarvalue')) * filteredOrderObj.sum}</td>
                      <td>{filteredOrderObj.sum}</td>
                      <td>-</td>
                      <td>-</td>
                      <td>Show Details</td>
                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div
              className="productDetails groceryTableMain"
              data-aos="fade-down"
              data-aos-delay="100"
              data-aos-anchor-placement="center-bottom"
            >
              <table width="100%">
                <thead>
                  <tr>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Date
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Order ID
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Ordered By
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Service
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Sub Type
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Items
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Status
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Star Price
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Actions
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ordersList.map((orderObj) => (
                    <tr>
                      {orderObj.ordertype == "Starmarket" ?
                        <td>{moment(orderObj.created_at).format('DD/MM/YYYY')}</td> :
                        <td>{moment(orderObj.orderdate).format('DD/MM/YYYY')}</td>}
                      <td># {orderObj.order_id}</td>
                      <td>{orderObj.first_name} {orderObj.last_name}</td>
                      <td>{orderObj.ordertype == "Starmarket" ? <>Star Market</> : <>Star Cafe</>}</td>
                      <td>{orderObj.ordertype}</td>
                      <td>
                        <b style={{ color: "#c82d33" }}>{orderObj.orderdetails.length}</b>
                      </td>
                      <td className={orderObj.orderstatus == 'Completed' ? 'forCompletedS' : orderObj.orderstatus == 'Refunded' ? 'forReadyFP' : orderObj.orderstatus == 'NotPickedUp' ? 'NotPickedUpColor' : ""} >{orderObj.orderstatus == 'Active' ? <>New</> : orderObj.orderstatus}</td>
                      <td>
                        <div className="withStar">
                          <StarIcon /> {orderObj.totalstarspend}
                        </div>
                      </td>
                      <td>
                        <div className="actionBtnOut">
                          <Tooltip title="View" arrow placement="top">
                            <IconButton>
                              <img src={eye} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
          </Grid>

          <Grid item xs={12} md={12} className="paginationOuterMain">
            <Pagination count={10} variant="outlined" shape="rounded" />
          </Grid>
        </Grid>
      </div>


      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />

            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default ReportOrderTab;
