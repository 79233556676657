import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

function AutoComplete(props) {
  const { label, name, options, labelClass,isRequired, ...rest } = props;
  return (
    <div className="form-control">
      <label htmlFor={name} className={labelClass}>
        {label}
        {isRequired == "true" ? <span className="requiredStar">*</span> : <></>}

      </label>
      <Field as="Autocomplete" id={name} name={name} {...rest}>
        {options.map((option) => {
          return (
            // {option.value+'|||'+option.key} this functionality is created  to get both key and value at the same time we have to spit value everytime by '|||'
            <option key={option.value} value={option.value+'|||'+option.key} data-isd={option.key}>
              {option.key}
            </option>
          );
        })}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default AutoComplete;
