import React from "react";
import { Button, Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarLogoSmall from "../assets/images/StarLogoSmall.svg";
import { useNavigate } from "react-router-dom";
import cart_box from "../assets/images/cart_box.svg";
import IconButton from '@mui/material/IconButton';

function EmptyCart() {
  const navigate = useNavigate();

  return (
    <>
      <div className="mobileCssMain">
        <div className="container">
          <div className="wrapper">
            <div className="headerData">
              <Button>
              <IconButton className="TouchableIconBtn"> <ArrowBackIosIcon />
              </IconButton>
              </Button>
              {/* <Button onClick={goBack} className="loginBt"> 
                  <i className="fa fa-arrow-left"></i>
              </Button> */}
              <div className="logo">
                <img src={StarLogoSmall} />
              </div>
              <h5>Star Labels</h5>
            </div>
            <div className="screenTitle">
              <h2>My Cart</h2>
            </div>
            <div className="emptyCartData">
              <div className="boxImg">
                <img src={cart_box} />
              </div>
              <h3>Your Cart Is Empty</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              </p>

              <div className="cartBtnOuter">
                <Button
                  className="loginBt secondaryBtn"
                  onClick={() => navigate("/OrderSuccess")}
                >
                  Go To Product List
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmptyCart;
