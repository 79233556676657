import React from "react";
import QRCode from "react-qr-code";

function QRCodeComponent({ data }) {

  console.log("QR cdata")
  console.log(data)

  return (
    <div className="mobileCssMain">
      <div>
        {data != null ?
          <QRCode
            value={data.toString()}
            size={200}
            bgColor="#FFFFFF"
            fgColor="#000000"
            level="L"
            includeMargin={true}
          /> :
          null
        }
      </div>
    </div>
  );
}

export default QRCodeComponent;
