import { Button, Grid } from '@mui/material'
import React, { useState } from 'react'
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from 'react-router-dom';
import StarLogoSmall from "../../assets/images/StarLogoSmall.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../../components/FormikControl";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { makePostRequest } from '../../../utils/utils';
import IconButton from "@mui/material/IconButton";

const Modal = ({ isOpen, onClose, children }) => {

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <div className="modal-content">{children}</div>
            </div>
        </div>
    );
};

const ModalApprove = ({ isOpen1, onClose, children }) => {

    if (!isOpen1) return null;
    return (
        <div className="modal-overlay">
            <div className="modal">
                <div className="modal-content">{children}</div>
            </div>
        </div>
    );
};

const sampleLeave = {
    employee_id: 0,
    leave_history_id: 0,
    status: "",
    comments: ""
};

const notificationobj = {
    title: "",
    message: "",
    employee_id: "",
};

const initialValues = {
    description: "",
};

const validationSchema = Yup.object({
    description: Yup.string().required("Required"),
});



function ManagerEmpProfLeaveDetails() {

    const location = useLocation();
    const { data } = location.state || {};

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    // approve modal 
    const [isOpen1, setIsOpen1] = useState(false);

    const openModal1 = () => {
        setIsOpen1(true);
    };

    const closeModal1 = () => {
        setIsOpen1(false);
    };

    const navigate = useNavigate();

    async function Updateleave(status, data) {

        sampleLeave.employee_id = parseInt(data.employee_id);
        sampleLeave.leave_history_id = parseInt(data.leave_history_id);
        sampleLeave.status = status;
        console.log(data)
        makePostRequest("employee/updateemployeeleave", sampleLeave)
            .then((response) => {
                if (response.data) {
                    console.log("changes");
                    if (data.employee_id != null) {
                        notificationobj.resident_id = parseInt(data.employee_id);
                        notificationobj.title = "Leave Status";
                        notificationobj.message =
                            data.first_name + " " + data.last_name + " " +
                            " Your Leave Request Has Been Approved";
                        console.log(notificationobj);
                        makePostRequest("employee/notificationtoadmin", notificationobj)
                            .then((response) => {
                                console.log("Notification Placed");
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                    setIsOpen1(false);
                    navigate(-1);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const onSubmit = (values) => {

        sampleLeave.comments = values.description;
        sampleLeave.employee_id = parseInt(data.employee_id);
        sampleLeave.leave_history_id = parseInt(data.leave_history_id);
        sampleLeave.status = "Rejected";
        console.log(data)
        makePostRequest("employee/updateemployeeleave", sampleLeave)
            .then((response) => {
                if (response.data) {
                    if (data.employee_id != null) {
                        notificationobj.resident_id = parseInt(data.employee_id);
                        notificationobj.title = "Leave Status";
                        notificationobj.message =
                            data.first_name + " " + data.last_name + " " +
                            " Your Leave Request Has Been Rejected";
                        console.log(notificationobj);
                        makePostRequest("employee/notificationtoadmin", notificationobj)
                            .then((response) => {
                                console.log("Notification Placed");
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                    setIsOpen1(false);
                    navigate(-1);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <div className="mobileCssMain">
                <div className="container">
                    <div className="wrapper">
                        <div className='managerMain'>
                            <div className="headerData">
                                <Button onClick={() => navigate(-1)}>
                                <IconButton className="TouchableIconBtn"> <ArrowBackIosIcon />
                                </IconButton>
                                </Button>
                                <div className="logo">
                                    <img src={StarLogoSmall} />
                                </div>
                                <h5>Star Labels</h5>
                            </div>
                            <div className="screenTitle">
                                <h2>Leave Details</h2>
                            </div>
                            <div className="warnCard warnDetail">
                                <div className='empLeavedetail'>
                                    <div>
                                        <h2>{data.first_name + " " + data.last_name}</h2>
                                        <p>Applied On {moment(data.created_at).format('YYYY-MM-DD')
                                        }</p>
                                    </div>
                                    <p className='leaveStatus'>Status <span>{data.status}</span></p>
                                </div>
                                <div className="warnDetailInfo">
                                    <p>{data.leave_discription
                                    }</p>
                                </div>
                            </div>
                            {data.status == "Pending" ?

                                <div className='empLeaveButtons'>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Button onClick={openModal} className="loginBt leaveRedBtn">
                                                Reject
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button onClick={openModal1} className="loginBt leaveGreenBtn" >
                                                Approve
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                                : null
                            }
                        </div>
                    </div>


                    {/* reject leave modal start */}
                    <Modal isOpen={isOpen}>
                        <div className="modal-footer">
                            <CloseOutlinedIcon className="closeBtn" onClick={closeModal} />
                        </div>
                        <div className="leaveAppForm empLeaveHeading">
                            <h3>Do you want to Reject this leave request?</h3>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                                enableReinitialize
                            >
                                {(formik) => (
                                    <Form>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <FormikControl
                                                    control="textarea"
                                                    type="textarea"
                                                    label="Enter Comment"
                                                    labelClass="loginLable"
                                                    name="description"
                                                    className="loginInput leaveComment"
                                                />
                                            </Grid>

                                        </Grid>
                                        <div className='empLeaveButtons'>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <Button onClick={closeModal} className="loginBt leaveCancelBtn">
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button type='submit' className="loginBt leaveGreenBtn" >
                                                        Reject
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Modal>
                    {/* reject leave modal end */}

                    {/* approve leave modal start */}
                    <ModalApprove isOpen1={isOpen1}>
                        <div className="modal-footer">
                            <CloseOutlinedIcon className="closeBtn" onClick={closeModal1} />
                        </div>
                        <div className="leaveAppForm empLeaveHeading">
                            <h3>Do you want to Approve this leave request?</h3>
                            <div className='empLeaveButtons'>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Button onClick={closeModal1} className="loginBt leaveCancelBtn">
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button onClick={() => Updateleave("Approved", data)} type='submit' className="loginBt leaveGreenBtn" >
                                            Approve
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </ModalApprove>
                    {/* approve leave modal end */}


                </div>
            </div>
        </>
    )
}

export default ManagerEmpProfLeaveDetails