import React from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import AddEditEmployee from "./AddEditEmployee";

function EditEmployee() {
  const navigate = useNavigate();
  const { empId } = useParams();
  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1 className="forBackArrowAlign">
                <WestIcon onClick={() => {localStorage.getItem('selectedusertype')=='LocalEmployee'? navigate("/LocalEmployees"): navigate("/Employees")}} />
                {localStorage.getItem('selectedusertype')=='LocalEmployee'? <>Update Local Employee</>:<>Update Employee</>}
              </h1>
              {/* <Button className="pageTopMainBt">Add Employee</Button> */}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <AddEditEmployee name={empId} />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default EditEmployee;
