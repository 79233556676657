import React, { useState } from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import editIco from "../../../assets/images/edit-icon.svg";
import deletIco from "../../../assets/images/delete-icon.svg";
import StarIcon from "@mui/icons-material/Star";
import WestIcon from "@mui/icons-material/West";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import lockimg from "../../../assets/images/lock-outline.svg";
import productImg from "../../../assets/images/productImg.svg";
import ProductSlider from "./ProductSlider";
import ProductChart from "./ProductChart";
import Switch from "@mui/material/Switch";
import ProductCarousel from "./ProductCarousel";
import Surprise from "../../../assets/images/Surprise.svg";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import {
  makeGetRequest,
  makePostRequest,
  uploadFileRequest,
} from "../../../utils/utils";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import swal from "sweetalert";
// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import StarCafeTabs from "./StarCafeTabs";
// End
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";

import { Upload } from "@mui/icons-material";

function VegetableProduuctDetails() {
  const navigate = useNavigate();
  const { vegitable_id } = useParams();
  const [veggiesObj, setVeggiesObj] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [alignment, setAlignment] = useState("Grams");
  const [uploadImg, setUploadImg] = React.useState([]);
  const [currentIndex, setCurrentIndex] = useState();
  const [vegitableImage, setVegitableImage] = useState([]);
  const [vegitableErrMsg, setVegitableErrMsg] = useState("");
  const [upload, setUpload] = useState(false);
  const [openEditVegetable, setOpenEditVegetable] = useState(false);
  const [imageErrMsg, setImageErrMsg] = React.useState("");
  const [veggiesImage, setVeggiesImage] = useState([]);
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const imageData = [
    {
      label: "Image 1",
      alt: "image1",
      url: require("../../../assets/images/veggiesProduuct.png"),
    },
    {
      label: "Image 2",
      alt: "image2",
      url: require("../../../assets/images/veggiesProduuct.png"),
    },
    {
      label: "Image 3",
      alt: "image3",
      url: require("../../../assets/images/veggiesProduuct.png"),
    },
    {
      label: "Image 4",
      alt: "image4",
      url: require("../../../assets/images/veggiesProduuct.png"),
    },
  ];

  const renderSlides = veggiesImage.map((image, index) => (
    <div key={index}>
      <img src={image} alt="img" />
      {/* <p className="legend">{image.label}</p> */}
    </div>
  ));

  function handleChange(index) {
    setCurrentIndex(index);
  }

  const label = { inputProps: { "aria-label": "Switch demo" } };

  // Modal Main
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);

  // End

  // Modal Main
  const [open3, setOpen3] = React.useState(false);

  const handleClickOpen3 = (scrollType) => () => {
    setOpen3(true);
    setScroll(scrollType);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  // End

  // Modal Main
  const [open4, setOpen4] = React.useState(false);

  const handleClickOpen4 = (scrollType) => () => {
    setOpen4(true);
    setScroll(scrollType);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const handleCloseEditVegetable = () => {
    setOpenEditVegetable(false);
  };

  // Delete Image Start
  const deleteImg = (vegitable_id) => {
    setShowLoader(true);
    vegitableImage.splice(vegitable_id, 1);
    setVegitableImage(vegitableImage);
    setShowLoader(true);
  };

  // Delete Image End

  // upload image start
  async function uploadImageOrFile(fileData) {
    var fileUrl = "";
    try {
      setShowLoader(true);
      const formData = new FormData();
      formData.append("image", fileData);

      await uploadFileRequest("superadmin/upload", formData).then(
        (response) => {
          console.log(response);
          fileUrl = response.data.imagepath;
        }
      );
      setShowLoader(false);
    } catch (error) {
      console.log(error);
    }
    return fileUrl;
  }

  async function validateImage(fileData) {
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i))
      return true;
    else return false;
  }

  async function uploadVegitableImage(e) {
    setVegitableErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];
    let validateRes = false;
    if (await validateImage(file)) {
      var imageUrl = await uploadImageOrFile(file);
      setVegitableImage([...vegitableImage, imageUrl]);
      console.log("img arr", uploadImg);
    } else {
      setVegitableErrMsg("Invalid file format");
    }
    setShowLoader(false);
  }

  // upload image end

  // get veggies Start
  const getVegitable = () => {
    console.log("success", vegitable_id);
    setShowLoader(true);
    makeGetRequest("starcafe/getveggiesbyid/" + vegitable_id)
      .then((response) => {
        if (response.data) {
          console.log("vegitable Data", response.data.data);
          setVeggiesObj(response.data.data[0]);
          setVeggiesImage(response.data.data[0].vegitable_image);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  // get veggies Data End

  // update vegetable api start
  const updateVeggies = (values) => {
    console.log(values);
    makePostRequest("starcafe/updateveggies", values)
      .then((response) => {
        if (response.data) {
          console.log(response);
          console.log("Success");
          handleCloseEditVegetable(true);
        }
        getVegitable(null);
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
        swal("Veggies", err.data.message, "Veggies", {
          timer: 3000,
          buttons: false,
        });
      });
  };
  // update vegetable api end

  // edit vagetable start
  const handleClickEditVegetable = (veggiesObj, scrollType) => {
    console.log("data", veggiesObj);
    setFormValues(veggiesObj);
    setOpenEditVegetable(true);
    setScroll(scrollType);
    setUpload(!Upload);
    setVegitableImage(veggiesObj.vegitable_image);
    setVegitableErrMsg("");
  };
  // edit vegetable end

  // Status vegetable Start
  const handleStatusChangeVegetable = (event, vegitable_id) => {
    let inputdata = {
      vegitable_id: vegitable_id,
      status: event.target.checked,
    };
    console.log("Status", inputdata);

    makePostRequest("starcafe/updateveggies", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          getVegitable();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Status vegetable end

  // Delete Vegetable Start
  const deleteVegitable = async (vegitable_id) => {
    await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: "warning",
      buttons: ["No", "Yes"],
     
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Vegitable Deleted!",
          text: "Vegitable is successfully deleted!",
          icon: "success",
          timer: 3000,
        }).then(function () {
          let inputdata = {
            vegitable_id: vegitable_id,
            is_deleted: true,
          };
          //console.log("Input Data", inputdata);
          makePostRequest("starcafe/updateveggies", inputdata)
            .then((response) => {
              if (response.data.data !== undefined) {
                navigate("/ServicesConfiguration");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        //swal("Cancelled", "Your Category is safe :)", "error");
      }
    });
  };
  // Delete Vegetable End

  React.useEffect(() => {
    getVegitable();
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (open3) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (open4) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open4, open, open3]);
  // End

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const initialValues = {
    // Add Vegetable
    vegitable_name: "",
    price_per_kg: "",
    total_qty: "",
    total_price: "",
    low_stock_limit: "",
    wt_unit: "",
  };

  const validationSchema = Yup.object({
    // Add Vegetable
    vegitable_name: Yup.string().required("Required"),
    price_per_kg: Yup.string().required("Required"),
    total_qty: Yup.string().required("Required"),
    total_price: Yup.string().required("Required"),
    low_stock_limit: Yup.string().required("Required"),
    wt_unit: Yup.string().required("Required"),
  });

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1 className="forBackArrowAlign">
                <WestIcon onClick={() => navigate("/ServicesConfiguration")} />
                Vegetable Details
              </h1>
              {/* <Button className="pageTopMainBt">Demo Button</Button> */}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="productDetailsTop">
              <div className="productDetailsHeading">
                {veggiesObj ? (
                  <div className="productDetailsHeadLeft">
                    <h1>
                      {veggiesObj.vegitable_name}
                      <span>
                        <b>+8%</b> from Yesterday
                      </span>
                    </h1>
                    <h4 className="productId">
                      Product ID #{veggiesObj.vegitable_id}
                    </h4>
                  </div>
                ) : null}

                <div className="productDetailsHeadRight">
                  {veggiesObj ? (
                    <div className="actionBtnOut">
                      {/* <Tooltip title="Edit" arrow placement="top">
                      <IconButton onClick={ () => handleClickEditVegetable(veggiesObj,"body")} >
                        <img src={editIco} />
                      </IconButton>
                    </Tooltip> */}

                      <Switch
                        {...label}
                        checked={veggiesObj.status}
                        className="swicthMain"
                        onChange={(event) =>
                          handleStatusChangeVegetable(
                            event,
                            veggiesObj.vegitable_id
                          )
                        }
                      />

                      <Tooltip title="Delete" arrow placement="top">
                        <IconButton
                          onClick={() =>
                            deleteVegitable(veggiesObj.vegitable_id)
                          }
                        >
                          <img src={deletIco} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
              </div>
              {/* <div>
                <div className="mainListDiv">
                  <div className="mainListCont mainListInrCont">
                    <label>Last Added Date</label>
                    <span className="mainListSubTx">06/04/2023</span>
                  </div>

                  <div className="mainListCont mainListInrCont">
                    <label>Category</label>
                    <span className="mainListSubTx">Groceries</span>
                  </div>

                  <div className="mainListCont mainListInrCont">
                    <label>Available Quantity</label>
                    <span className="mainListSubTx">
                      <b>500</b>
                    </span>
                  </div>

                  <div className="mainListCont mainListInrCont">
                    <label>Sold Quantity</label>
                    <span className="mainListSubTx">12000</span>
                  </div>

                  <div className="mainListCont mainListInrCont">
                    <label>Price</label>
                    <span className="mainListSubTx">$15/Item</span>
                  </div>

                  <div className="mainListCont mainListInrCont">
                    <label>Price In Stars</label>
                    <span className="mainListSubTx">
                      <b className="listMainStar">
                        <StarIcon /> 150
                      </b>
                    </span>
                  </div>
                </div>
              </div> */}

              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <div className="productImgDiv">
                      <Carousel
                        showArrows={true}
                        autoPlay={true}
                        infiniteLoop={true}
                        selectedItem={imageData[currentIndex]}
                        onChange={handleChange}
                        className="carousel-container"
                      >
                        {renderSlides}
                      </Carousel>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="salesOt">
                      <p>Sales</p>
                      <select>
                        <option>Months</option>
                      </select>
                    </div>
                    <div className="chartAmountMain">
                      <b>4512</b>
                      <p>
                      ₹ 1524.42{" "}
                        <span>
                          46% <UpgradeIcon />
                        </span>
                      </p>
                    </div>
                    <ProductChart />
                    {/* Chart Here... */}
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="settingDevider"></div>
                  </Grid>
                  {veggiesObj ? (
                    <>
                      <Grid item xs={12} md={6}>
                        <div className="proDetailasCont">
                          <div className="proDetailasContDiv">
                            <span>
                              <p>Last Date Added</p>
                              <b>
                                :{" "}
                                {moment(veggiesObj.created_at).format(
                                  "DD/MM/YYYY"
                                )}
                              </b>
                            </span>

                            <span>
                              <p>Quantity In Kg</p>
                              <b>
                                : {veggiesObj.total_qty} {veggiesObj.wt_unit}
                              </b>
                            </span>

                            <span>
                              <p>Veggies In Stock</p>
                              <b>: {veggiesObj.total_qty}</b>
                            </span>

                            <span>
                              <p>Low Stock Limit</p>
                              <b>: {veggiesObj.low_stock_limit}</b>
                            </span>
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <div className="proDetailasCont">
                          <div className="proDetailasContDiv">
                            <span>
                              <p>Price Per Kg</p>
                              <b>: {veggiesObj.price_per_kg}</b>
                            </span>

                            <span>
                              <p>Total Price in INR (₹)</p>
                              <b>: ₹ {veggiesObj.total_price}</b>
                            </span>

                            <span>
                              <p>Price In Stars</p>
                              <b className="listMainStar">
                                :
                                <StarIcon /> {veggiesObj.total_price/parseInt(localStorage.getItem("masterdollarvalue"))}
                              </b>
                            </span>
                          </div>
                        </div>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </div>
            </div>
          </Grid>

          {/* <Grid item xs={12} md={12}>
            <div className="pageMainHeading">
              <h1>Other Trending Products</h1>
            </div>
            <div className="productSliderOuter">
              <ProductSlider />
            </div>
          </Grid> */}
        </Grid>
      </div>

      {/* Add Category */}
      <div>
        <Dialog
          open={open}
          // onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain smallModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Category
            <CloseIcon className="modalCloseBt" onClick={handleClose} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <label className="pageLabel">Enter Category Name</label>
                      <input
                        type="text"
                        className="prodSearchInpt"
                        placeholder="Category Name"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Button
                        className="modalBtFullWidth"
                        onClick={handleClose}
                      >
                        Add Category
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Edit Product */}
      <div>
        <Dialog
          open={openEditVegetable}
          // onClose={handleCloseEditVegetable}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Edit Vegetable
            <CloseIcon
              className="modalCloseBt"
              onClick={handleCloseEditVegetable}
            />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationSchema}
                    onSubmit={updateVeggies}
                    enableReinitialize
                  >
                    {(formik) => (
                      <Form>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12}>
                            <div className="addRemoveImagesBox">
                              {vegitableImage ? (
                                <>
                                  {vegitableImage.map((img, index) => (
                                    <div className="imageAddedBox" key={index}>
                                      <img src={img} />
                                      <div className="cloIm">
                                        <CloseIcon
                                          onClick={() => deleteImg(index)}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : null}
                              {vegitableImage.length < 4 ? (
                                <div className="uploadImBox">
                                  <h1 className="">Upload Image</h1>
                                  <Button
                                    className="uploadImBt"
                                    component="label"
                                    variant="contained"
                                    startIcon={<CloudUploadIcon />}
                                  >
                                    Upload
                                    <VisuallyHiddenInput
                                      type="file"
                                      onChange={(event) =>
                                        uploadVegitableImage(event)
                                      }
                                    />
                                  </Button>
                                  <span>or drag file in here</span>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              placeholder="Vegetable Name"
                              label="Enter Vegetable Name"
                              labelClass="pageLabel"
                              name="vegitable_name"
                              isRequired="true"
                              className="prodSearchInpt"
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Low Stock Limit"
                              placeholder="Stock Limit"
                              labelClass="pageLabel"
                              name="low_stock_limit"
                              isRequired="true"
                              className="prodSearchInpt"
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Price Per Kg"
                              placeholder="Price"
                              labelClass="pageLabel"
                              name="price_per_kg"
                              isRequired="true"
                              className="prodSearchInpt"
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Total Price in INR (₹)"
                              placeholder="Price in INR"
                              labelClass="pageLabel"
                              name="total_price"
                              isRequired="true"
                              className="prodSearchInpt"
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Weight/Quantity"
                              placeholder="Weight/Quantity"
                              labelClass="pageLabel"
                              name="total_qty"
                              isRequired="true"
                              className="prodSearchInpt"
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <label className="pageLabel">
                              Select Weight Unit
                            </label>
                            <div className="unitDivMain">
                              <div className="detailsTableFilterSec">
                                <ToggleButtonGroup
                                  value={alignment}
                                  exclusive
                                  onChange={handleAlignment}
                                  className="detailsTableFilterSecActionInr"
                                >
                                  <ToggleButton
                                    value="Grams"
                                    className="filterBtn"
                                  >
                                    Grams
                                  </ToggleButton>
                                  <ToggleButton
                                    value="KiloGrams"
                                    className="filterBtn"
                                  >
                                    Kilo Grams
                                  </ToggleButton>
                                  <ToggleButton
                                    value="Quantity"
                                    className="filterBtn"
                                  >
                                    Quantity
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </div>
                            </div>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ textAlign: "center" }}
                          >
                            <span>
                              <Button
                                type="submit"
                                className="modalBtSmallWidth"
                              >
                                Update Vegetable
                              </Button>
                            </span>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Added Successfull */}
      <div>
        <Dialog
          open={open3}
          // onClose={handleClose3}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Add Product
            <CloseIcon className="modalCloseBt" onClick={handleClose3} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div className="modalSuccessDiv">
                        <img src={Surprise} />
                        <div className="modalCenterContent">
                          <p>
                            <b>Product Name</b>
                            <span> is added successfully</span>
                          </p>
                          <p>
                            Lorem ipsum dolor sit amet consectetur. Commodo amet
                            sed sed non nisl lacus cursus penatibus faucibus.
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      <Button className="modalBtOutline">Add Product</Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default VegetableProduuctDetails;
