import React, { useState, useEffect, useRef } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarLogoSmall from "../../assets/images/StarLogoSmall.svg";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import LabelIcon from "@mui/icons-material/Label";
import { makeGetRequest } from "../../../utils/utils";
import moment from "moment";
import avtarPic from "../../../MobileScreens/assets/images/Profile-Picture.jpg";
import editIco from "../../../assets/images/edit-icon.svg";
import IconButton from "@mui/material/IconButton";
import SegmentIcon from '@mui/icons-material/Segment';
import swal from "sweetalert";
// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// End

// For Tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// End

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../../../src/components/FormikControl";
import { makePostRequest } from "../../../utils/utils";

// Tabs Main
function CustomTabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// End
const emergencydetails = {
  employee_id: 0,
  emergency_address_1: "",
  emergency_address_2: "",
  emergency_contact_1: "",
  emergency_contact_2: "",
};

const familydetails = {
  employee_id: 0,
  family_info: [],
};

function ProfileDetailsMobile() {

  // const [formValues, setFormValues] = React.useState(null);

  const [updateemergency, setUpdateemegency] = React.useState(0);
  const [updatefamilyinfo, setUpdatefamilyinfo] = React.useState(0);

  const initialValues = {
    mobile_number: "",
    enter_address1: "",
  };

  const validationSchema = Yup.object({
    mobile_number: Yup.string().required("Required"),
    enter_address1: Yup.string().required("Required"),
  });
  const [updateFamilyInitials, setUpdateFamilyInitials] = useState(null);

  const initialValues2 = {
    name: "",
    contact_no: "",
    relation: "",
    address: "",
    bith_date: "",
    age: ""
  };

  const validationSchema2 = Yup.object({
    name: Yup.string().required("Required"),
    contact_no: Yup.string().required("Required"),
    bith_date: Yup.string().required("Required"),
    age: Yup.string().required("Required"),
    relation: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {

    emergencydetails.employee_id = parseInt(localStorage.getItem("userId"));
    if (updateemergency == 1) {
      emergencydetails.emergency_address_1 = values.enter_address1;
      emergencydetails.emergency_contact_1 = values.mobile_number;
    } else if (updateemergency == 2) {
      emergencydetails.emergency_address_2 = values.enter_address1;
      emergencydetails.emergency_contact_2 = values.mobile_number;
    }
    makePostRequest("employee/updateemployeestatus", emergencydetails)
      .then((response) => {
        if (response.data) {
          swal("Success", "Information updated successfully", "success", {
            timer: 1000,
            buttons: false,
          });
          setOpenEditEmergency(false);
          navigate(-1)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmitfamilyinfo = (familyinfovalues) => {

    familydetails.employee_id = parseInt(localStorage.getItem("userId"));
    employeeObj.family_info[updatefamilyinfo] = familyinfovalues;
    familydetails.family_info = employeeObj.family_info;
    console.log(familydetails)
    makePostRequest("employee/updateemployeestatus", familydetails)
      .then((response) => {
        if (response.data) {
          console.log("changes");

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // For Tabs
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // End

  // Edit Emergency Contact
  const [EditEmergency, setOpenEditEmergency] = React.useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleClickEditEmergency = (scrollType, type) => () => {
    setUpdateemegency(type)
    setOpenEditEmergency(true);
    setScroll(scrollType);
  };

  const closeEditEmergency = () => {
    setOpenEditEmergency(false);
  };

  const descriptionElementRef = useRef(null);
  // End

  // Edit Family
  const [FamilyEdit, setOpenFamilyEdit] = React.useState(false);

  const handleClickFamilyEdit = (scrollType, index, familyInfo) => () => {

    setUpdatefamilyinfo(index)
    setOpenFamilyEdit(true);
    setScroll(scrollType);
    setUpdateFamilyInitials(familyInfo);
  };

  const closeFamilyEdit = () => {
    setOpenFamilyEdit(false);
  };

  // End

  const navigate = useNavigate();
  const [employeeObj, setEmployeeObj] = React.useState({});

  const getProfileDetails = async () => {
    await makeGetRequest(
      "employee/getemployee/" + localStorage.getItem("userId")
    )
      .then((response) => {
        if (response.data.data[0]) {
          console.log(response.data.data[0])
          setEmployeeObj(response.data.data[0]);
        } else {
          setEmployeeObj([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  return (
    <>
      <div className="mobileCssMain">
        <div className="fixedNewProDetailsBgMain"></div>
        <div className="screenTitle newProDetailsBgMain">
          <div className="sideProBar">
            <Button onClick={() => navigate("/Profile")}>
              <IconButton className="TouchableIconBtn"> <ArrowBackIosIcon />
              </IconButton>
            </Button>
            <h2>Personal Details</h2>
          </div>
          <div className="newProDetailsPr">
            <div className="newProDetailsProDivOut">
              <div className="newProDetailsProDiv">
                {employeeObj.profile_pic != null ? <img src={employeeObj.profile_pic} /> :
                  <img src={avtarPic} />}
              </div>
              {/* <IconButton className="newProEdiBBt">
                <img src={editIco} />
                <input type="file" />
              </IconButton> */}
            </div>
            <h1>{employeeObj.first_name + " " + employeeObj.last_name}</h1>
            <p>{employeeObj.designation_name}</p>
          </div>
        </div>
        <div className="container">
          <div className="wrapper">
            <div className="myProfileNewTbsMain">
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="Basic Information" {...a11yProps(0)} />
                    <Tab label="Emergency Contacts" {...a11yProps(1)} />
                    <Tab label="Family Information" {...a11yProps(2)} />
                  </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0}>
                  <div className="myProfileNewTbsIner">
                    <div className="profileData">
                      <div className="personalDetails">
                        <h3>Personal Details</h3>

                        <div className="profileRow">
                          <div className="rowIcons">
                            <SegmentIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Name</p>
                            <p>{employeeObj.first_name + " " + employeeObj.last_name}</p>
                          </div>
                        </div>

                        <div className="profileRow">
                          <div className="rowIcons">
                            <EmailIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Email Id</p>
                            <p>{employeeObj.email_id}</p>
                          </div>
                        </div>

                        <div className="profileRow">
                          <div className="rowIcons">
                            <LocalPhoneIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Mobile Number</p>
                            <p>{employeeObj.contact_no}</p>
                          </div>
                        </div>

                        <div className="profileRow">
                          <div className="rowIcons">
                            <LabelIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Position</p>
                            <p>{employeeObj.designation_name}</p>
                          </div>
                        </div>

                        <div className="profileRow">
                          <div className="rowIcons">
                            <CalendarMonthIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Date Of Birth</p>
                            <p>{moment(employeeObj.birth_date).format(
                              "DD/MM/YYYY"
                            )}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profileData">
                      <div className="personalDetails">
                        <h3>Employment Details</h3>

                        <div className="profileRow profileEmp">
                          <div className="rowInfo">
                            <p>Designation</p>
                            <p>{employeeObj.designation_name}</p>
                          </div>
                          <div className="rowInfo">
                            <p>Department</p>
                            <p>{employeeObj.department_name}</p>
                          </div>
                        </div>

                        <div className="profileRow profileEmp">
                          <div className="rowInfo">
                            <p>Employee Code</p>
                            <p>{employeeObj.employee_code}</p>
                          </div>
                          <div className="rowInfo">
                            <p>Date of Joining</p>
                            <p>
                              {moment(employeeObj.date_of_joining).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="profileRow profileEmp">
                          <div className="rowInfo">
                            <p>Monthly Star Allowance</p>
                            <p>{employeeObj.monthly_allowance}</p>
                          </div>
                          <div className="rowInfo">
                            <p>Contract Terms (Years)</p>
                            <p>{employeeObj.contract_years}</p>
                          </div>
                        </div>

                        <div className="profileRow profileEmp">
                          {/* <div className="rowInfo">
                    <p>Visa</p>
                    <p>Visa ··8877 · £19.95</p>
                  </div> */}
                          <div className="rowInfo">
                            <p>Passport Number</p>
                            {employeeObj.passport_no ? (
                              <p>{employeeObj.passport_no}</p>
                            ) : (
                              <p>---</p>
                            )}
                          </div>
                          <div className="rowInfo">
                            <p>Passport Expiry Date</p>
                            <p>{moment(employeeObj.paspport_expiry_date).format(
                              "DD/MM/YYYY"
                            )}</p>
                          </div>
                        </div>

                        <div className="profileRow profileEmp">
                          <div className="rowInfo">
                            <p>Visa Number</p>
                            <p>{employeeObj.visa_no}</p>
                          </div>
                          <div className="rowInfo">
                            <p>Visa Expiry Date</p>
                            <p>{moment(employeeObj.visa_expiry_date).format(
                              "DD/MM/YYYY"
                            )}</p>
                          </div>
                        </div>

                        <div className="profileRow profileEmp">
                          <div className="rowInfo">
                            <p>Fixed Salary</p>
                            {employeeObj.fixed_salary ? (
                              <p>{employeeObj.fixed_salary}</p>
                            ) : (
                              <p>---</p>
                            )}
                          </div>
                          <div className="rowInfo">
                            <p>Reporting To</p>
                            <p>{employeeObj.reporting_to_first_name + " " + employeeObj.reporting_to_last_name
                            }</p>
                          </div>
                        </div>

                        <div className="profileRow profileEmp">
                          <div className="rowInfo">
                            <p>Balance Salary</p>
                            {employeeObj.balance_salary ? (
                              <p>{employeeObj.balance_salary}</p>
                            ) : (
                              <p>---</p>
                            )}
                          </div>
                          <div className="rowInfo">
                            <p>Travel Eligibility</p>
                            <p>{employeeObj.travel_eligibility
                            }</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                  <div className="myProfileNewTbsIner">
                    <div className="profileData">
                      <div className="personalDetails">
                        <h3>Emergency Contact 1</h3>
                        <div className="profileRow">
                          <div className="rowIcons">
                            <LocalPhoneIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Mobile Number</p>
                            <p>{employeeObj.emergency_contact_1
                            }</p>
                          </div>
                        </div>
                        <div className="profileRow">
                          <div className="rowIcons">
                            <EmailIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Address</p>
                            <p>{employeeObj.emergency_address_1}</p>
                          </div>
                        </div>
                      </div>
                      <img
                        src={editIco}
                        className="newProEdiIco"
                        onClick={handleClickEditEmergency("body", 1)}
                      />
                    </div>

                    <div className="profileData">
                      <div className="personalDetails">
                        <h3>Emergency Contact 2</h3>
                        <div className="profileRow">
                          <div className="rowIcons">
                            <LocalPhoneIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Mobile Number</p>
                            <p>{employeeObj.emergency_contact_2
                            }</p>
                          </div>
                        </div>
                        <div className="profileRow">
                          <div className="rowIcons">
                            <EmailIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Address</p>
                            <p>{employeeObj.emergency_address_2
                            }</p>
                          </div>
                        </div>
                      </div>
                      <img
                        src={editIco}
                        className="newProEdiIco"
                        onClick={handleClickEditEmergency("body", 2)}
                      />
                    </div>
                  </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={2}>
                  <div className="myProfileNewTbsIner">
                    {employeeObj.family_info ?
                      <>
                        {employeeObj.family_info.map((item, index) => (
                          <div className="profileData">
                            <div className="personalDetails">
                              <div className="profileRow">
                                <div className="rowIcons">
                                  <SegmentIcon />
                                </div>
                                <div className="rowInfo">
                                  <p>Name</p>
                                  <p>{item.name}</p>
                                </div>
                              </div>
                              <div className="profileRow">
                                <div className="rowIcons">
                                  <LocalPhoneIcon />
                                </div>
                                <div className="rowInfo">
                                  <p>Mobile Number</p>
                                  <p>{item.contact_no
                                  }</p>
                                </div>
                              </div>
                              <div className="profileRow">
                                <div className="rowIcons">
                                  <CalendarMonthIcon />
                                </div>
                                <div className="rowInfo">
                                  <p>Date Of Birth</p>
                                  <p>{moment(employeeObj.bith_date).format(
                                    "DD/MM/YYYY"
                                  )}</p>
                                </div>
                              </div>
                              <div className="profileRow">
                                <div className="rowIcons">
                                  <EmailIcon />
                                </div>
                                <div className="rowInfo">
                                  <p>Age</p>
                                  <p>{item.age
                                  } yrs</p>
                                </div>
                              </div>
                              <div className="profileRow">
                                <div className="rowIcons">
                                  <EmailIcon />
                                </div>
                                <div className="rowInfo">
                                  <p>Relation</p>
                                  <p>{item.relation
                                  }</p>
                                </div>
                              </div>
                              <div className="profileRow">
                                <div className="rowIcons">
                                  <EmailIcon />
                                </div>
                                <div className="rowInfo">
                                  <p>Address</p>
                                  <p>{item.address
                                  }</p>
                                </div>
                              </div>
                            </div>
                            <img
                              src={editIco}
                              className="newProEdiIco"
                              onClick={handleClickFamilyEdit("body", index, item)}
                            />
                          </div>
                        ))}
                      </>
                      : null}

                    {/* <div className="profileData">
                      <div className="personalDetails">
                        <div className="profileRow">
                          <div className="rowIcons">
                            <EmailIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Name</p>
                            <p>Leslie Alexander</p>
                          </div>
                        </div>
                        <div className="profileRow">
                          <div className="rowIcons">
                            <EmailIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Mobile Number</p>
                            <p>+1-123-456-7890</p>
                          </div>
                        </div>
                        <div className="profileRow">
                          <div className="rowIcons">
                            <EmailIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Date Of Birth</p>
                            <p>24/06/1987</p>
                          </div>
                        </div>
                        <div className="profileRow">
                          <div className="rowIcons">
                            <EmailIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Age</p>
                            <p>32yrs</p>
                          </div>
                        </div>
                        <div className="profileRow">
                          <div className="rowIcons">
                            <EmailIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Relation</p>
                            <p>Wife</p>
                          </div>
                        </div>
                        <div className="profileRow">
                          <div className="rowIcons">
                            <EmailIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Address</p>
                            <p>Lorem ipsum dummy text lorem ipsum dummy text</p>
                          </div>
                        </div>
                      </div>
                      <img
                        src={editIco}
                        className="newProEdiIco"
                        onClick={handleClickFamilyEdit("body")}
                      />
                    </div> */}

                    {/* <div className="profileData">
                      <div className="personalDetails">
                        <div className="profileRow">
                          <div className="rowIcons">
                            <EmailIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Name</p>
                            <p>Leslie Alexander</p>
                          </div>
                        </div>
                        <div className="profileRow">
                          <div className="rowIcons">
                            <EmailIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Mobile Number</p>
                            <p>+1-123-456-7890</p>
                          </div>
                        </div>
                        <div className="profileRow">
                          <div className="rowIcons">
                            <EmailIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Date Of Birth</p>
                            <p>24/06/1987</p>
                          </div>
                        </div>
                        <div className="profileRow">
                          <div className="rowIcons">
                            <EmailIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Age</p>
                            <p>32yrs</p>
                          </div>
                        </div>
                        <div className="profileRow">
                          <div className="rowIcons">
                            <EmailIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Relation</p>
                            <p>Wife</p>
                          </div>
                        </div>
                        <div className="profileRow">
                          <div className="rowIcons">
                            <EmailIcon />
                          </div>
                          <div className="rowInfo">
                            <p>Address</p>
                            <p>Lorem ipsum dummy text lorem ipsum dummy text</p>
                          </div>
                        </div>
                      </div>
                      <img
                        src={editIco}
                        className="newProEdiIco"
                        onClick={handleClickFamilyEdit("body")}
                      />
                    </div> */}

                  </div>
                </CustomTabPanel>
              </Box>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Emergency Contact */}
      <div>
        <Dialog
          open={EditEmergency}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain mobModalMain"
        >
          <DialogTitle id="scroll-dialog-title">
            Update Emergency Contact
            <CloseIcon className="modalCloseBt" onClick={closeEditEmergency} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
            // tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {(formik) => (
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Mobile Number"
                              placeholder="Enter Mobile Number"
                              labelClass="pageLabel"
                              name="mobile_number"
                              isRequired="true"
                              className="prodSearchInpt"
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <FormikControl
                              control="textarea"
                              type="text"
                              label="Enter Address"
                              placeholder="Type Here"
                              labelClass="pageLabel"
                              name="enter_address1"
                              isRequired="true"
                              className="modalTexArea"
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <input type="submit" className="modBBt" value="Update" />
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Edit Family */}
      <div>
        <Dialog
          open={FamilyEdit}
          // onClose={closeDeductProdct}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain mobModalMain"
        >
          <DialogTitle id="scroll-dialog-title">
            Update Family Information
            <CloseIcon className="modalCloseBt" onClick={closeFamilyEdit} />
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <Formik
                    initialValues={updateFamilyInitials || initialValues2}
                    validationSchema={validationSchema2}
                    onSubmit={onSubmitfamilyinfo}
                    enableReinitialize
                  >
                    {(formik) => (
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Name"
                              placeholder="Enter Name"
                              labelClass="pageLabel"
                              name="name"
                              isRequired="true"
                              className="prodSearchInpt"
                            // value={employeeObj.family_info[updatefamilyinfo].name
                            // }
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Mobile Number"
                              placeholder="Mobile Number"
                              labelClass="pageLabel"
                              name="contact_no"
                              isRequired="true"
                              className="prodSearchInpt"
                            // value={employeeObj.family_info[updatefamilyinfo].contact_no
                            // }
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="date"
                              type="date"
                              label="Date Of Birth"
                              placeholder="Date Of Birth"
                              labelClass="pageLabel"
                              name="bith_date"
                              isRequired="true"
                              className="prodSearchInpt"
                            // value={moment(employeeObj.family_info[updatefamilyinfo].bith_date).format("YYYY-MM-DD")}

                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Age"
                              placeholder="Age"
                              labelClass="pageLabel"
                              name="age"
                              isRequired="true"
                              className="prodSearchInpt"
                            // value={employeeObj.family_info[updatefamilyinfo].age
                            // }
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <FormikControl
                              control="input"
                              type="text"
                              label="Enter Relation"
                              placeholder="Relation"
                              labelClass="pageLabel"
                              name="relation"
                              isRequired="true"
                              className="prodSearchInpt"
                            // value={employeeObj.family_info[updatefamilyinfo].relation
                            // }
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <FormikControl
                              control="textarea"
                              type="text"
                              label="Enter Address"
                              placeholder="Type Here"
                              labelClass="pageLabel"
                              name="address"
                              isRequired="true"
                              className="modalTexArea"
                            // value={employeeObj.family_info[updatefamilyinfo].address
                            // }
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <Button type="submit" className="modBBt" >Update</Button>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default ProfileDetailsMobile;
