import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Grid } from "@mui/material";
import warning from "../../../MobileScreens/assets/images/warning-.svg";
import leaveIcon from "../../../MobileScreens/assets/images/leaveIcon.svg";
import timesheetIcon from "../../../MobileScreens/assets/images/timesheetIcon.svg";
import TuneIcon from "@mui/icons-material/Tune";
import MobileHeader from '../../componentsMobile/MobileHeader/MobileHeader';
import BottomTabsPage from '../../componentsMobile/BottomTabsPage/BottomTabsPage';
import { makeGetRequest } from '../../../utils/utils';
import moment from "moment";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";



function CustomTabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}


function ManagerTab() {

    const [value, setValue] = React.useState(0);

    // const [isEmployeeDirectory, setIsEmployeeDirectory] = useState(false);
    // let is_employee_directory = localStorage.getItem( "is_employee_directory");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate();
    const [reportingemployeelist, setReportingemployeelist] = React.useState([]);
    const [flagsearch, setflagsearch] = useState(false);
    const [filteredDataSource, setFilteredDataSource] = useState([]);

    const [allemployeelist, setAllemployeelist] = React.useState([]);
    const [flagsearchAll, setflagsearchAll] = useState(false);
    const [filteredDataSourceAll, setFilteredDataSourceAll] = useState([]);

    const searchData = (e) => {

        let text = e.target.value
        if (text) {
            setflagsearch(true);
            const newData = reportingemployeelist.filter((item) => {
                const itemData = item.first_name.toUpperCase();
                const textData = text.toUpperCase();
                return itemData.startsWith(textData);
            });
            
            if (newData.length > 0) {
                console.log(newData)
                setFilteredDataSource(newData.sort(sortbyascename));
                //setSearch(text);
            } else {
                const newDataname = reportingemployeelist.filter((item) => {
                    const itemData = item.last_name.toUpperCase();
                    const textData = text.toUpperCase();
                    return itemData.startsWith(textData);
                });
                if (newDataname.length > 0) {
                    setFilteredDataSource(newDataname.sort(sortbyascename));
                    //setSearch(text);
                } else {
                    setFilteredDataSource([]);
                    //setSearch(text);
                }
            }

        } else {
            setflagsearch(false);
            //setSearch(text);
        }
    };

    const searchDataAll = (e) => {

        let text = e.target.value

        if (text) {
            setflagsearchAll(true);
            const newData = allemployeelist.filter((item) => {
                const itemData = item.first_name.toUpperCase();
                const textData = text.toUpperCase();
                return itemData.startsWith(textData);
            });

            if (newData.length > 0) {
                console.log(newData)
                setFilteredDataSourceAll(newData.sort(sortbyascename));
                //setSearch(text);
            } else {
                const newDataname = allemployeelist.filter((item) => {
                    const itemData = item.last_name.toUpperCase();
                    const textData = text.toUpperCase();
                    return itemData.startsWith(textData);
                });
                if (newDataname.length > 0) {
                    setFilteredDataSourceAll(newDataname.sort(sortbyascename));
                    //setSearch(text);
                } else {
                    setFilteredDataSourceAll([]);
                    //setSearch(text);
                }
            }
        } else {
            setflagsearchAll(false);
            //setSearch(text);
        }
    };

    function sortbyascename(a, b) {
        if (a.first_name < b.first_name) {
            return -1;
        }
        if (a.first_name > b.first_name) {
            return 1;
        }
        if (a.last_name < b.last_name) {
            return -1;
        }
        if (a.last_name > b.last_name) {
            return 1;
        }
        return 0;
    };

    async function getReportingEmployeeList() {

        var userid = parseInt(localStorage.getItem("userId"));
        let arr = [];
        await makeGetRequest(
            "employee/GetReportingEmployeeList/" + userid
        )
            .then((response) => {
                if (response.data.data) {
                    arr.push(response.data.data);
                    console.log("list data")
                    console.log(arr)
                    setReportingemployeelist(response.data.data);
                } else {
                    setReportingemployeelist([]);
                }
            })
            .catch((err) => {
                setReportingemployeelist([]);
            });
    };

    async function getAllEmployeeList() {

        await makeGetRequest(
            "employee/getemployeelist"
        )
            .then((response) => {
                if (response.data.data) {
                    setAllemployeelist(response.data.data);
                } else {
                    setAllemployeelist([]);
                }
            })
            .catch((err) => {
                setAllemployeelist([]);
            });
    };

    useEffect(() => {
        getReportingEmployeeList();
        const value = localStorage.getItem("is_employee_directory");
        console.log("value" + value)
        if (value === 'true') {
            getAllEmployeeList();
        }
    }, []);

    return (
        <>
            <div className="mobileCssMain">
                <div className="dashMain forBottomTabOnly">
                    <MobileHeader />
                    <div className="wrapper">
                        <div className="profileTabs managerTopTabs ">
                            <Box sx={{ width: "100%" }}>

                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <Tabs
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"
                                        className="profileTopTab"
                                        value={value}
                                        onChange={handleChange}
                                    >
                                        <Tab label="My Reportings" className="toptabActive" {...a11yProps(0)}
                                        />
                                        <Tab label="All Employees" {...a11yProps(1)} />
                                    </Tabs>
                                </Box>

                                <CustomTabPanel value={value} index={0}>
                                    <div className='managerMain'>
                                        <div className="leaveFilter empList">
                                            <h3>Employee List</h3>
                                        </div>
                                        <div className="leaveFilter ">
                                            <div className="newSearchOuter leaveListSearch ">
                                                <input
                                                    type="text"
                                                    placeholder="Search by employee name"
                                                    className="managerListSearch"
                                                    onChange={searchData}
                                                />
                                            </div>
                                            {/* <TuneIcon className="leaverFilterIcon" /> */}
                                        </div>
                                        {flagsearch ?
                                            <>
                                                {filteredDataSource ? (
                                                    <>
                                                        {filteredDataSource.map((item, index) => (
                                                            <div className="leaveDataCard" onClick={() => navigate('/ManagerEmpProfDetails', {
                                                                state: { id: item.employee_id },
                                                            })}>
                                                                <div className="leaveDataCard-left empListData">
                                                                    <h4  >{item.first_name + " " + item.last_name}</h4>
                                                                    <p>Designation {item.designation_name}</p>
                                                                </div>
                                                                <div className="leaveDataCard-right empListDataright">
                                                                    <h4>E-{item.employee_code}</h4>
                                                                    <p>DOJ {moment(item.date_of_joining).format('YYYY-MM-DD')
                                                                    }</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </>
                                            :
                                            <>
                                                {reportingemployeelist.length > 0 ? (
                                                    <>
                                                        {reportingemployeelist.map((item, index) => (
                                                            <div className="leaveDataCard" onClick={() => navigate('/ManagerEmpProfDetails', {
                                                                state: { id: item.employee_id },
                                                            })}>
                                                                <div className="leaveDataCard-left empListData">
                                                                    <h4  >{item.first_name + " " + item.last_name}</h4>
                                                                    <p>Designation {item.designation_name}</p>
                                                                </div>
                                                                <div className="leaveDataCard-right empListDataright">
                                                                    <h4>E-{item.employee_code}</h4>
                                                                    <p>DOJ {moment(item.date_of_joining).format('YYYY-MM-DD')
                                                                    }</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>) : null}
                                            </>
                                        }

                                    </div>
                                </CustomTabPanel>

                                {/* All employee listing */}
                                <CustomTabPanel value={value} index={1}>
                                    <div className='managerMain'>
                                        <div className="leaveFilter empList">
                                            <h3>Employee List</h3>
                                        </div>
                                        <div className="leaveFilter ">
                                            <div className="newSearchOuter leaveListSearch ">
                                                <input
                                                    type="text"
                                                    placeholder="Search by employee name"
                                                    className="managerListSearch"
                                                    onChange={searchDataAll}
                                                />
                                            </div>
                                            {/* <TuneIcon className="leaverFilterIcon" /> */}
                                        </div>
                                        {flagsearchAll ?
                                            <>
                                                {filteredDataSourceAll ? (
                                                    <>
                                                        {filteredDataSourceAll.map((item, index) => (
                                                            <div className="leaveDataCard" onClick={() => navigate('/ManagerEmpProfDetails', {
                                                                state: { id: item.employee_id },
                                                            })} >
                                                                <div className="leaveDataCard-left empListData">
                                                                    <h4 >{item.first_name + " " + item.last_name}</h4>
                                                                    <p>Designation {item.designation_name}</p>
                                                                </div>
                                                                <div className="leaveDataCard-right empListDataright">
                                                                    <h4>E-{item.employee_code}</h4>
                                                                    <p>DOJ {moment(item.date_of_joining).format('YYYY-MM-DD')
                                                                    }</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </> : <>
                                                {allemployeelist.length > 0 ? (
                                                    <>
                                                        {allemployeelist.map((item, index) => (
                                                            <div className="leaveDataCard" onClick={() => navigate('/ManagerEmpProfDetails', {
                                                                state: { id: item.employee_id },
                                                            })} >
                                                                <div className="leaveDataCard-left empListData">
                                                                    <h4 >{item.first_name + " " + item.last_name}</h4>
                                                                    <p>Designation {item.designation_name}</p>
                                                                </div>
                                                                <div className="leaveDataCard-right empListDataright">
                                                                    <h4>E-{item.employee_code}</h4>
                                                                    <p>DOJ {moment(item.date_of_joining).format('YYYY-MM-DD')} </p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>) : null}
                                            </>
                                        }
                                    </div>
                                </CustomTabPanel>
                            </Box>
                        </div>
                    </div>
                    {/* </div> */}
                    <BottomTabsPage />
                </div>
            </div>
        </>
    )
}

export default ManagerTab