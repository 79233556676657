import React, { useEffect } from "react";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import dashLogo from "../../assets/images/dashLogo.svg";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import NotificationIcon from "../NotificationIcon";

function MobileHeader() {
  const navigate = useNavigate();

  const [name, setName] = React.useState("");
  const [star, setStar] = React.useState(0);
  const [cafeTiming, setCafeTiming] = React.useState([]);
  const [orderList, setOrderList] = React.useState([]);

  async function getEmployeeStar() {

    var userid = parseInt(localStorage.getItem("userId"));
    setName(localStorage.getItem("username"));
    await makeGetRequest("employee/getstarcount/" + userid)
      .then((response) => {
        if (response.data.data) {
          if (response.data.data) {
            localStorage.setItem("starcount", response.data.data.total_stars);
            setStar(response.data.data.total_stars);
          } else {
            localStorage.setItem("starcount", 0);
            setStar(0);
          }
        }
      })
      .catch((err) => {
        setStar(0);
      });
  }

  async function getCafeTiming() {
    await makeGetRequest("starcafe/getcafetiming")
      .then((response) => {
        if (response.data) {
          if (response.data) {
            setCafeTiming(response.data.data);
          } else {
            setCafeTiming([]);
          }
        }
      })
      .catch((err) => {
        setCafeTiming([]);
      });
  }

  async function getEmployeeActiveOrder() {
    var userid = parseInt(localStorage.getItem("userId"));
    let arr = [];
    await makeGetRequest(
      "starcafe/getorderemployee/null/Active/" + userid + "/0/null/null"
    )
      .then((response) => {
        if (response.data) {
          arr.push(response.data.data[0]);
          setOrderList(arr);
        } else {
          setOrderList([]);
        }
      })
      .catch((err) => {
        setOrderList([]);
      });
  }

  useEffect(() => {
    getEmployeeStar();
    getCafeTiming();
    getEmployeeActiveOrder();
  }, []);

  return (
    <>
      <div className="fixedHeaderPacth"></div>

      <div className="fixedHeader">
        <div className="dashTop">
          <div></div>
          {/* <div className="dashLogo">
            <div className="dashLogoOut">
              <img src={dashLogo} />
            </div>
            <span>Star Labels</span>
          </div> */}
          <div>
            {/* <NotificationsOutlinedIcon onClick={() => navigate("/Notifications")} /> */}
            <NotificationIcon />
          </div>
        </div>
        <div className="availStarMain">
          <div className="container">
            <div className="availStar">
              <h4>{name}</h4>
              <h1>
                Available Stars{" "}
                <b>
                  <StarOutlinedIcon />
                  {star}
                </b>
              </h1>
            </div>
          </div>
        </div>
        <div className="dedctedWraper">
          <div className="dedctedMain">
            {/* <h3>
              <b>
                <StarOutlinedIcon /> 120
              </b>
              deducted, for wasting food
            </h3> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileHeader;
