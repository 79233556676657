
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import cartImg from "../assets/images/cartImg.svg";
import QRCodeComponent from "../../MobileScreens/componentsMobile/QRCodeComponent";
import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarLogoSmall from "../assets/images/StarLogoSmall.svg";
import { makeGetRequest, makePostRequest } from "../../utils/utils";
import IconButton from "@mui/material/IconButton";

function OrderSuccessMarket({ route }) {

  const navigate = useNavigate();

  const location = useLocation();
  const { data, Pickup, type } = location.state || {};
  const [userName, setUserName] = useState(0);
  const [timing, setTiming] = useState();

  async function getdata() {

    let id = localStorage.getItem("userId");

    await makeGetRequest("starmarket/getorder/" + data)
      .then((response) => {
        if (response.data) {
          if (response.data.data.length > 0) {
            if (response.data.data[0].orderstatus == "Completed") {
              navigate("/DashboardPage");
            }
            console.log(response.data.data[0].orderstatus)
          }
        } else {
        }
      })
      .catch((err) => { });
  };

  useEffect(() => {

    setUserName(localStorage.getItem("username"));
    setTiming(localStorage.getItem("cafetiming"));

    // Set up the interval to call the API every 5 seconds
    const interval = setInterval(() => {
      getdata();
    }, 5000); // 5000ms = 5s

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);

  }, []);

  return (
    <>
      <div className="mobileCssMain">
        <div className="container">
          <div className="wrapper">
            <div className="screenTitle">
              <Button onClick={() => navigate(-1)}>
                <IconButton className="TouchableIconBtn">
                  {" "}
                  <ArrowBackIosIcon />
                </IconButton>
              </Button>
              {/* <h2>{Pickup}</h2> */}
              <h2>Star Market</h2>
            </div>
            <div className="fixedBottomPadding">
              <h2 className="ordSucHed">Order Placed Successfully </h2>

              {/* <div className="orderTiming setTimeSt">
                <h4>{type} Time</h4>
                <h2> 5:00am to 7:00am </h2>
              </div> */}

              <div className="summaryContent">
                <div className="cartImgOuter">
                  <img src={cartImg} />
                </div>
                <h1 className="usernamest">{userName}</h1>
                <h1>Star Market Order # {data} </h1>
                <p>
                  Thank you for your order. Please pick your order at star
                  market. You will receive a notification once your order is
                  ready for pickup. <br></br>
                  <span className="spanbold">
                    Scan the QR code give below At Counter
                  </span>
                </p>
                <div className="qrOuter">
                  {data ? <QRCodeComponent data={JSON.stringify({orderId:data,serviceType:'Starmarket'})} /> : null}
                </div>
                <h1 className="tnkTx"> "Thank You For Your Order" </h1>
                {/* <Button
                  className="loginBt"
                  onClick={() => navigate("/StarMarketHome")}
                >
                  Continue Shopping
                </Button> */}
                <Button
                  className="secondaryBtn"
                  onClick={() => navigate("/DashboardPage")}
                >
                  Back To Home
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderSuccessMarket;
