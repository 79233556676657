import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import all from "../../assets/images/all.svg";
import veggies from "../../assets/images/veggies.svg";
import nonveg from "../../assets/images/nonveg.svg";
import canned from "../../assets/images/canned.svg";
import household from "../../assets/images/household.svg";
import snacks from "../../assets/images/snacks.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarLogoSmall from "../../assets/images/StarLogoSmall.svg";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import teaProd from "../../assets/images/teaProd.svg";
import coffee from "../../assets/images/coffee.svg";
import roll from "../../assets/images/teaProd.svg";
import sandwich from "../../assets/images/sandwich.svg";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";

import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import BallotRoundedIcon from "@mui/icons-material/BallotRounded";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import SoupKitchenIcon from "@mui/icons-material/SoupKitchen";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
import productimg_placeholder from "../../assets/images/productimg_placeholder.svg";

import IconButton from "@mui/material/IconButton";

import { useCart } from "../../Screens/CartContext"; //"../Screens/CartContext";

import AddIcon from "@mui/icons-material/Add";

import {
  ORDER_TYPE_BREAKFAST,
  ORDER_TYPE_LUNCH,
  ORDER_TYPE_DINNER,
  ORDER_TYPE_CAFE,
} from "../../../constants/orderTypes";

import {
  canAddItemToCart,
  canAddSingleItemToCart,
} from "../../../utils/canAddItemToCart";

import SearchComponent from "../Search";
import RemoveIcon from "@mui/icons-material/Remove";
// import AddIcon from "@mui/icons-material/Add";

function StarCafeHome() {

  const {
    cart,
    addToCart,
    reduceCount,
    addCategoryProduct,
    reduceCategoryCount,
    removeFromCart,
  } = useCart();

  const [addedItems, setAddedItems] = useState({});
  const [count, setCount] = useState(0);
  const [items, setItems] = useState([]);
  const [category, setCategory] = useState([]);
  const [name, setName] = useState("");
  const [star, setStar] = React.useState(0);
  const [alignment, setAlignment] = React.useState("All");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);

  const navigate = useNavigate();

  const clickAddBt = (id, item, index) => {
    if (canAddSingleItemToCart(cart, item)) {
      setItems((prevItems) =>
        items.map((item) =>
          item.subcategory_id === id
            ? { ...item, class: "addedCount" }
            : { ...item, class: "" }
        )
      );
      const updatedItem = { ...item, class: "addedCount" };
      addCategoryProduct(updatedItem);
      var element = document.getElementById("addAndOuntMain" + index);
      element.classList.toggle("addedCount");
    } else {
      alert(
        "Cannot add breakfast, lunch, or dinner items together in the same order."
      );
    }
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  async function getItemList() {
    await makeGetRequest("starcafe/getsubcategorylist")
      .then((response) => {
        if (response.data.data) {
          const updatedData = response.data.data
            .filter((item) => item.type !== 'Product' || (item.type === 'Product' && item.current_quantity > 0))
            .map((item) => ({
              ...item,
              count: 1,
              class: "",
              storetype: ORDER_TYPE_CAFE,
            }));
          setItems(updatedData);
        } else {
          setItems([]);
        }
      })
      .catch((err) => {
        setItems([]);
      });
  }

  async function getItemListById(Id) {

    setSelectedCategoryId(Id);
    await makeGetRequest("starcafe/getsubcategorylistBycategoryid/" + Id)
      .then((response) => {
        if (response.data.data) {
          const updatedData = response.data.data
            .filter((item) => item.type !== 'Product' || (item.type === 'Product' && item.current_quantity > 0))
            .map((item) => ({
              ...item,
              count: 1,
              class: "",
              storetype: ORDER_TYPE_CAFE,
            }));

          setItems(updatedData);
        } else {
          setItems([]);
        }
      })
      .catch((err) => {
        setItems([]);
      });
  }

  async function getCategoryList() {
    await makeGetRequest("starcafe/getcategorylist/null/true")
      .then((response) => {
        if (response.data.data) {
          setCategory(response.data.data);
        } else {
          setCategory([]);
        }
      })
      .catch((err) => {
        setCategory([]);
      });
  }

  const increment = (id, item) => {
    // console.log(item)
    // setItems((prevItems) =>
    //   items.map((item) =>
    //     item.subcategory_id === id ? { ...item, count: item.count + 1 } : item
    //   )
    // );
    // addCategoryProduct(item);
    setItems((prevItems) =>
      prevItems.map((item) => {
        if (item.subcategory_id === id && (item.count < item.current_quantity || item.type !== 'Product')) {
          const updatedItem = { ...item, count: item.count + 1 };
          addCategoryProduct(updatedItem);
          return updatedItem;
        }
        return item;
      })
    );
  };

  const decrement = (id, item) => {

    setItems((prevItems) =>
      // items.map((item) =>
      //   item.subcategory_id === id ? { ...item, count: item.count - 1 } : item
      // )
      displayItems.map((item) => {
        if (item.subcategory_id === id) {
          const newCount = Math.max(item.count - 1, 0);
          const updatedItem = {
            ...item,
            count: newCount === 0 ? 1 : newCount,
            class: "",
          };
          reduceCategoryCount(item);
          if (newCount === 0) {
            removeFromCart(item.subcategory_id);
          }
          console.log(updatedItem)
          return updatedItem;
        }
        return item;
      })
    );
    //reduceCategoryCount(item);
  };

  const handleSearch = (results) => {
    if (results.length > 0) {
      setItems(results);
    } else {
      getItemList();
    }
  };

  const totals =
    cart && cart.length > 0
      ? cart.reduce(
        (sums, item) => {
          sums.total_star_price += (item.Star_price || 0) * item.count;
          sums.total_price_in_stars +=
            (item.price_in_stars || 0) * item.count;
          return sums;
        },
        { total_star_price: 0, total_price_in_stars: 0 }
      )
      : { total_star_price: 0, total_price_in_stars: 0 };

  const { total_star_price, total_price_in_stars } = totals;

  const handleButtonClickAll = () => {
    setSelectedCategoryId(0);
    getItemList();
  };

  const handleButtonClick = (categoryId) => {
    setSelectedCategoryId(categoryId);
    getItemListById(categoryId);
  };

  const naviagte = async () => {
    //localStorage.setItem("servicetype", "Starcafe");
    navigate("/OrderSummary", {
      state: { Fromlocation: "Star Cafe", type: "Star Cafe" },
    });
  };

  useEffect(() => {
    setName(localStorage.getItem("username"));
    setStar(localStorage.getItem("starcount"));
    getCategoryList();
    getItemList();
  }, []);

  const mergeItems = (items, cart) => {
    const cartMap = new Map(cart.map((item) => [item.subcategory_id, item]));
    const mergedItems = items.map((item) =>
      cartMap.has(item.subcategory_id) ? cartMap.get(item.subcategory_id) : item
    );
    return mergedItems;
  };

  const displayItems = mergeItems(items, cart);

  return (
    <>
      <div className="mobileCssMain">
        <div className="forBottomTabOnly">
          <div className="container">
            <div className="headerDataIcons">
              <div className="screenTitle">
                <Button onClick={() => navigate(-1)}>
                  <IconButton className="TouchableIconBtn"> <ArrowBackIosIcon />
                  </IconButton>
                </Button>
                <h2>Star Cafe</h2>
              </div>

              <div className="topheaderIcons headerGreyIcon"></div>
            </div>
          </div>

          <div className="availStarMain topHeaderTypetwo">
            <div className="container">
              <div className="availStar ">
                <h4>{name}</h4>
                <h1>
                  Available Stars{" "}
                  <b>
                    <StarOutlinedIcon /> {star}
                  </b>
                </h1>
              </div>

              <div className="newSearchOuter newSearchTopSpace">
                <SearchComponent onSearch={handleSearch} category_id={selectedCategoryId} />
              </div>
            </div>
          </div>

          <div className="container">
            <div className="wrapper">
              <div className="screenTitleOuter">
                <div className="screenTitle">
                  <h2>Star Cafe</h2>
                  {/* <p>Find By Category</p> */}
                </div>
              </div>

              <div className="myToggleMain newMyToggleMain">
                <div className="horiCategory">
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                  >
                    {/* className="Mui-selected" */}
                    <ToggleButton
                      value="All"
                      onClick={() => handleButtonClickAll()}
                    >
                      <div className="categoryMain categoryHori">
                        <div>
                          <BallotRoundedIcon />
                        </div>
                        <b>All</b>
                      </div>
                    </ToggleButton>
                    {category.map((item, index) => (
                      <ToggleButton
                        key={index}
                        value="Meat"
                        selected={selectedCategoryId === item.category_id}
                        onClick={() => handleButtonClick(item.category_id)}
                        className={
                          selectedCategoryId === item.category_id
                            ? "Mui-selected"
                            : ""
                        }
                      >
                        <div className={"categoryMain categoryHori"}>
                          <div>
                            <RoomServiceIcon />
                          </div>
                          <b>{item.category_name}</b>
                        </div>
                      </ToggleButton>
                    ))};
                  </ToggleButtonGroup>
                </div>
              </div>

              <div className="marketProductsOuter">
                <Grid container spacing={1}>
                  {displayItems.map((item, index) => (
                    <Grid item xs={6}>
                      <div key={index} className="productOuter newProductOuter">
                        <div
                          className="productImg"
                          onClick={() =>
                            navigate("/CafeProductDetails", {
                              state: { Id: item.subcategory_id },
                            })
                          }
                        >
                          {/* <img src={productimg_placeholder} /> */}
                          {item.item_image ? (
                            <img src={item.item_image[0]} />
                          ) : (
                            <img src={productimg_placeholder} />
                          )}
                        </div>
                        <p>Product Id {item.subcategory_id}</p>
                        <h3>{item.product_name}</h3>

                        <div className="productBoxtBottom">
                          <div className="prodBottom">
                            <div className="starPrice">
                              <StarIcon className="starIcon" />
                              <b>{item.Star_price}</b>
                              {/* <h5> ${item.price} </h5> */}
                            </div>

                            <div
                              key={index}
                              className={"countWithAddBtMain " + item.class}
                              //id="addAndOuntMain"
                              id={`addAndOuntMain` + index}
                            >
                              <div className="newCountSt">
                                <Button
                                  className="beforeAddBt"
                                  onClick={() =>
                                    clickAddBt(item.subcategory_id, item, index)
                                  }
                                >
                                  <AddIcon />
                                </Button>

                                <div className="outerIncdec">
                                  <Button
                                    onClick={() =>
                                      decrement(item.subcategory_id, item)
                                    }
                                    className="IncDrcBtn"
                                  >
                                    <RemoveIcon />
                                  </Button>

                                  <b className="countValue countValue2">
                                    {item.count}
                                  </b>

                                  <Button
                                    onClick={() =>
                                      increment(item.subcategory_id, item)
                                    }
                                    className="IncDrcBtn"
                                  >
                                    <AddIcon />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>

              <div className="fixedBtnOuter">
                <Button className="loginBt" onClick={() => naviagte()}>
                  <p style={{ marginRight: "60px" }}>
                    {cart.length} Item |{" "}
                    {total_star_price + total_price_in_stars} Stars
                  </p>
                  <p>View Cart</p>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StarCafeHome;
