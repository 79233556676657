import React, { useContext } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarLogoSmall from "../assets/images/StarLogoSmall.svg";
import CloseIcon from "@mui/icons-material/Close";
import { NotificationContext } from '../componentsMobile/NotificationContext';
import IconButton from '@mui/material/IconButton';

function Notifications() {

    const navigate = useNavigate();
    const { notifications, notificationCount, removeNotification } = useContext(NotificationContext);

    const handleNotificationClick = (index) => {
        removeNotification(index);
    };

    async function checkNavigation() {

        let userId = localStorage.getItem("userId");
        console.log(userId);
        if (userId) { navigate("/DashboardPage") }
        else { navigate("/LoginMobile") }
    }

    return (
        <div className="mobileCssMain">
            <div className="container">
                <div className="wrapper">
                    <div className="fixedBottomPadding">
                        <div className="headerData">
                            <Button onClick={() => checkNavigation()}>
                            <IconButton className="TouchableIconBtn"> <ArrowBackIosIcon />
                            </IconButton>
                            </Button>
                            <div className="logo">
                                <img src={StarLogoSmall} />
                            </div>
                            <h5>Star Labels</h5>
                        </div>
                        <div className="screenTitle">
                            <h2>Notifications</h2>
                        </div>
                        <div className='notiContent'>
                            <div className='notiText unreadNoti'>
                                <div className='notiTitle'>Warning</div>
                                <div className='notiLeftContent'>
                                    <span className='spanNoti'>New</span>
                                    <p> Your have {notificationCount} new notification</p>

                                </div>
                            </div>

                            {notifications.length > 0 &&
                                notifications.map((item, index) => (
                                    <div className='notiText' key={index} onClick={() => handleNotificationClick(index)}>
                                        <div className='notiTitle'>{item.title}</div>
                                        <div className='notiLeftContent'>
                                            <p>{item.body}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Notifications